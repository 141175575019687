import { Details } from "async-lifecycle-saga/dist/models";
import { OptionsObject, SnackbarProvider } from "notistack";
import React, { ReactNode, memo } from "react";

import useStyles from "./Snackbar.styles";

interface SnackbarProps {
  children: ReactNode;
  maxSnack: number;
}
const StyledProvider = memo(
  ({ children, maxSnack }: SnackbarProps): JSX.Element => {
    const classes = useStyles();
    return (
      <SnackbarProvider maxSnack={maxSnack} classes={classes}>
        {children}
      </SnackbarProvider>
    );
  }
);

const withSnackbarProvider = (
  element: React.ReactElement,
  maxSnack = 4
): JSX.Element => (
  <StyledProvider maxSnack={maxSnack}>{element}</StyledProvider>
);

export const showError =
  (
    enqueueSnackbar: (
      message: string | React.ReactNode,
      options?: OptionsObject
    ) => OptionsObject["key"] | null
  ): ((error: Error | string) => void) =>
  (error: Error | string): void => {
    enqueueSnackbar(error, { variant: "error" });
  };

export const showAsyncFail =
  (
    enqueueSnackbar: (
      message: string | React.ReactNode,
      options?: OptionsObject
    ) => OptionsObject["key"] | null
  ): ((details: Details | Error) => void) =>
  (_: Details | Error): void => {
    enqueueSnackbar("Fout", { variant: "error" });
  };

export default withSnackbarProvider;
