import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { LoginPageEvents } from "../../../business/authentication/models";
import { logOutReasonSessionStorageKey } from "../../../business/models";
import { organizationPrivacyStatementExistsCell } from "../../../business/redux/saga/organization";
import { useTranslationStrict } from "../../../globalization/i18n";
import ContentCard from "../../layout/ContentCard";
import PrivacyStatementButton from "../../organization/PrivacyStatementButton";
import useStyles from "../../pages/authentication/LoginPage.styles";

const MijnHRPlazaLoginFormCard = (props: LoginPageEvents) => {
  const { onLogin } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loginName, setLoginName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [
    organizationPrivacyStatementExists,
    setOrganizationPrivacyStatementExists,
  ] = useState(false);

  const handleLoginNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setLoginName(event.currentTarget.value);
    },
    [setLoginName]
  );

  const handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setPassword(event.currentTarget.value);
    },
    [setPassword]
  );

  const handleLogin = useCallback(
    (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      onLogin(loginName, password);
    },
    [onLogin, loginName, password]
  );

  useEffect(() => {
    dispatch(
      organizationPrivacyStatementExistsCell.require({
        onFail: (details) => {
          setOrganizationPrivacyStatementExists(details.status === 302);
        },
      })
    );

    return () => {
      sessionStorage.removeItem(logOutReasonSessionStorageKey);
    };
  }, [dispatch]);

  return (
    <ContentCard>
      <form method="dialog" onSubmit={handleLogin}>
        <Grid container direction="column" spacing={2}>
          <Grid item classes={{ item: classes.gridItemLoginField }}>
            <FormControl>
              <TextField
                name="loginName"
                label={t("Common:LoginName")}
                value={loginName}
                onChange={handleLoginNameChange}
              />
            </FormControl>
          </Grid>
          <Grid item classes={{ item: classes.gridItemLoginField }}>
            <FormControl>
              <TextField
                name="password"
                label={t("Common:Password")}
                value={password}
                onChange={handlePasswordChange}
                type="password"
              />
            </FormControl>
          </Grid>
          <Grid item classes={{ item: classes.gridItemLoginField }}>
            <Button type="submit" variant="contained" color="primary">
              {t("Common:LogOn")}
            </Button>
          </Grid>
          <Grid item classes={{ item: classes.gridItemReset }}>
            <Typography variant="body2">
              <Link to="/reset">{t("User:LoginProblems")}</Link>
            </Typography>
          </Grid>
          {organizationPrivacyStatementExists && (
            <Grid item classes={{ item: classes.gridItemReset }}>
              <PrivacyStatementButton />
            </Grid>
          )}
        </Grid>
      </form>
    </ContentCard>
  );
};

export default MijnHRPlazaLoginFormCard;
