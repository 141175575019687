import { put } from "@redux-saga/core/effects";
import { createActor, createForm, createSingle } from "async-lifecycle-saga";
import { AsyncAction, AsyncValue } from "async-lifecycle-saga/dist/models";
import { takeEvery } from "redux-saga/effects";

import {
  FileResponse,
  asyncDownloadPost,
  asyncGet,
  asyncPatch,
  asyncPost,
} from "../../../api";
import {
  DataDefinitionModel,
  EmployeeDataModel,
  MutationsOverviewDataRow,
  VariableDataFilter,
  VariableDataRow,
} from "../../../models";
import { StoreModel } from "../models";
import {
  EmployeeDataModelJson,
  toPersonalEmployeeDataModel,
} from "../personal/api";
import { datacockpitMutationsOverviewRequire } from "./api";
import {
  EmployeeDataAuthorizationRequest,
  EmployeeDataContext,
  EmployeeDataFilter,
  EmployeeDataMergedModel,
  EmployeeDataPatch,
  employeeDataContext,
} from "./models";

export const datacockpitDataDefinitionsCell = createSingle({
  path: ["datacockpit", "dataDefinitions"],
  require: {
    api: () => asyncGet<DataDefinitionModel[]>("/api/datacockpit/definitions"),
  },
});

export const datacockpitEmployeeDataFetchCell = createActor<
  void,
  EmployeeDataModel[],
  StoreModel,
  EmployeeDataFilter
>({
  path: ["datacockpit", "employeeData", "fetch"],
  api: (_, { employeeId, referenceDate }) => {
    let url = `/api/datacockpit/data/${employeeId}`;
    if (referenceDate) {
      url = `${url}?referenceDate=${new Date(referenceDate).toISOString()}`;
    }
    return asyncGet<EmployeeDataModelJson[]>(url).then(
      toPersonalEmployeeDataModel
    );
  },
  context: (store) => store.datacockpit.employeeData.filter,
});

const datacockpitEmployeeDataFilterSetEvent =
  "DATACOCKPIT_EMPLOYEEDATA_FILTER_SET";
export const datacockpitEmployeeDataFilterCell = {
  events: {
    set: datacockpitEmployeeDataFilterSetEvent,
  },
  set: (
    filter: EmployeeDataFilter
  ): AsyncAction<EmployeeDataFilter, void, AsyncValue<void>> => ({
    type: datacockpitEmployeeDataFilterSetEvent,
    payload: filter,
  }),
  sagas: [
    takeEvery(
      datacockpitEmployeeDataFilterSetEvent,
      function* (
        action: AsyncAction<EmployeeDataFilter, void, AsyncValue<void>>
      ) {
        if (!action.payload) {
          return;
        }
        const { employeeId } = action.payload;
        if (employeeId == null) {
          yield put({ type: datacockpitEmployeeDataFetchCell.events.clear });
        } else {
          yield put(datacockpitEmployeeDataFetchCell.require());
        }
      }
    ),
  ],
};

const datacockpitEmployeeDataEditPatchEvent =
  "DATACOCKPIT_EMPLOYEEDATA_EDIT_PATCH";
const datacockpitEmployeeDataEditSetEvent = "DATACOCKPIT_EMPLOYEEDATA_EDIT_SET";
export const datacockpitEmployeeDataEditCell = {
  events: {
    set: datacockpitEmployeeDataEditSetEvent,
    patch: datacockpitEmployeeDataEditPatchEvent,
  },
  set: (
    employeeData: EmployeeDataMergedModel[] | null
  ): AsyncAction<EmployeeDataMergedModel[] | null, void, AsyncValue<void>> => ({
    type: datacockpitEmployeeDataEditSetEvent,
    payload: employeeData,
  }),
  patch: (employeeDataPatch: EmployeeDataPatch) => ({
    type: datacockpitEmployeeDataEditPatchEvent,
    payload: employeeDataPatch,
  }),
};

export const datacockpitEmployeeDataPatchCell = createActor<
  void,
  EmployeeDataMergedModel[],
  StoreModel,
  EmployeeDataContext
>({
  path: ["datacockpit", "employeeData", "patch"],
  api: (_, context) =>
    asyncPatch(`/api/datacockpit/data/${context.employeeId}`, context),
  context: employeeDataContext,
});

const oneDay = 1000 * 60 * 60 * 24;

export const datacockpitVariableDataTableFetchCell = createForm<
  VariableDataFilter,
  VariableDataRow[]
>({
  path: ["datacockpit", "variableData", "table"],
  api: (filter) => asyncPost("/api/datacockpit/table", filter),
  lifetime: oneDay,
});

export const datacockpitVariableDataExportActionCell = createActor<
  VariableDataFilter,
  FileResponse
>({
  path: ["datacockpit", "variableData", "exportAction"],
  api: (filter) => asyncDownloadPost("/api/datacockpit/export", filter),
});

export const datacockpitMutationsOverviewCell = createSingle<
  MutationsOverviewDataRow[],
  StoreModel
>({
  path: ["datacockpit", "mutations", "overview"],
  require: {
    api: datacockpitMutationsOverviewRequire,
  },
  lifetime: 5_000,
});

export const datacockpitMutationsRejectCell = createActor<
  EmployeeDataAuthorizationRequest,
  never
>({
  path: ["datacockpit", "mutations", "reject"],
  api: (request) => asyncPost("/api/datacockpit/mutations/reject", request),
  invalidate: [datacockpitMutationsOverviewCell.events],
});

export const datacockpitMutationsApproveCell = createActor<
  EmployeeDataAuthorizationRequest,
  never
>({
  path: ["datacockpit", "mutations", "approve"],
  api: (request) => asyncPost("/api/datacockpit/mutations/approve", request),
  invalidate: [datacockpitMutationsOverviewCell.events],
});
