import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { Field, Form, Formik, FormikActions } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  NavigationNodeModel,
  useNavigationNodeValidationSchema,
} from "../../../business/models";
import { adminNavigationNodeAddChildCell } from "../../../business/redux/saga/admin/navigation/cells";
import { reflexoHistory } from "../../../business/router";
import { useTranslationStrict } from "../../../globalization/i18n";

interface ContentPageCreatorProps {
  parentId: number;
  parentRoute: string;
}

const ContentPageCreator = (
  props: ContentPageCreatorProps
): React.ReactElement => {
  const { parentId, parentRoute } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    (
      { title }: NavigationNodeModel,
      { resetForm, setSubmitting }: FormikActions<NavigationNodeModel>
    ): void => {
      resetForm();
      setSubmitting(true);
      dispatch(
        adminNavigationNodeAddChildCell.require(
          {
            parentId,
            id: 0,
            isRootNode: false,
            title,
            text: "",
            route: parentRoute,
            level: 0,
          },
          {
            onFail: (details: Details) => {
              enqueueSnackbar(
                `${t("Admin:NavigationNodeAddChildError")} (${details.detail})`,
                { variant: "error" }
              );
              setSubmitting(false);
            },
            onSuccess: ({
              body: { route: newRoute, title: newTitle },
            }: Success<NavigationNodeModel>) => {
              enqueueSnackbar(
                `${t("Admin:NavigationNodeAddChildSuccess")} (${newTitle})`,
                { variant: "success" }
              );
              setSubmitting(false);
              if (newRoute) {
                reflexoHistory.push(newRoute);
              }
            },
          }
        )
      );
    },
    [dispatch, enqueueSnackbar, parentId, parentRoute, t]
  );

  const { validationSchema } = useNavigationNodeValidationSchema();

  const initialValues: NavigationNodeModel = Object.freeze<NavigationNodeModel>(
    {
      title: "",
      route: "",
      text: " ",
      level: 0,
      contractClientName: "x",
      contractClientEmail: "x@x.nl",
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({
        handleChange,
        isSubmitting,
        isValid,
        values: { title },
      }): JSX.Element => (
        <Form>
          <Card>
            <CardHeader title={t("Admin:NavigationNodeAddHeader")} />
            <CardContent>
              <Grid container spacing={8}>
                <Grid item>
                  <FormControl>
                    <FormLabel>{t("Admin:NavigationNodeTitle")}</FormLabel>
                    <Field
                      component={TextField}
                      id="title"
                      name="title"
                      placeholder="Abcde 1"
                      value={title}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button type="submit" disabled={!isValid || isSubmitting}>
                {t("Common:Add")}
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    />
  );
};

export default memo(ContentPageCreator);
