import { combineReducers } from "redux";

import {
  debitCardCell,
  personalAddPboCell,
  personalDataCell,
  personalDefinitionsCell,
  personalDownloadsCell,
  personalUploadTypesCell,
} from "./cells";
import {
  personalChoicesByLoginNameCell,
  personalChoicesEntityCell,
  personalChoicesUploadCell,
  personalChoicesUpsertCell,
} from "./choices/cells";
import { personalDownloadRemoveCell } from "./download/cells";
import wlzReducer from "./wlz/reducer";

export default combineReducers({
  addPbo: personalAddPboCell.reducer,
  choices: combineReducers({
    byLoginName: personalChoicesByLoginNameCell.reducer,
    entity: personalChoicesEntityCell.reducer,
    upload: personalChoicesUploadCell.reducer,
    upsert: personalChoicesUpsertCell.reducer,
  }),
  data: personalDataCell.reducer,
  debitCard: debitCardCell.reducer,
  definitions: personalDefinitionsCell.reducer,
  download: combineReducers({
    remove: personalDownloadRemoveCell.reducer,
  }),
  downloads: personalDownloadsCell.reducer,
  uploadTypes: personalUploadTypesCell.reducer,
  wlz: wlzReducer,
});
