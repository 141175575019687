import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import QRCode from "qrcode";
import React, { memo, useCallback, useEffect, useState } from "react";
import speakeasy from "speakeasy";

import googleAuthenticatorLogoUrl from "../../../assets/GoogleAuthenticator.png";
import microsoftAuthenticatorLogoUrl from "../../../assets/MicrosoftAuthenticator.png";
import { useSession } from "../../../business/redux/saga/admin/hooks";
import { useTwoFactor } from "../../../business/redux/saga/authentication/twoFactor/hooks";
import { useTranslationStrict } from "../../../globalization/i18n";
import AuthenticationTwoFactorConfirmDeactivateDialog from "./AuthenticationTwoFactorConfirmDeactivateDialog";
import AuthenticationTwoFactorCreateSecret from "./AuthenticationTwoFactorCreateSecret";
import useStyles from "./AuthenticationTwoFactorSettings.styles";

const GoogleAuthenticatorSettings = () => {
  const { avatar, dialog, root } = useStyles();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { addSecret, settings, disable, enable, validate } = useTwoFactor();
  const {
    session: { applicationName, user: { loginName } = { loginName: "" } },
  } = useSession();

  const [isGAEnabled, setGAIsEnabled] = useState<boolean>(false);
  const [secretDataUrl, setSecretDataUrl] = useState<string | undefined>(
    undefined
  );
  const [secretText, setSecretText] = useState<string | undefined>(undefined);
  const isShowSecret = Boolean(secretDataUrl && !isGAEnabled);

  const [isConfirmDeactivateDialogOpen, setIsConfirmDeactivateDialogOpen] =
    useState<boolean>(false);
  const handleConfirmDeactivateDialogCancel = useCallback(() => {
    setIsConfirmDeactivateDialogOpen(false);
  }, []);
  const handleConfirmDeactivateDialogConfirm = useCallback(() => {
    disable((() => {})(), {
      onFail: (details) => {
        enqueueSnackbar(details.title, { variant: "error" });
      },
      onSuccess: ({ body: { isEnabled } }): void => {
        setGAIsEnabled(isEnabled === undefined ? true : (isEnabled as boolean));
        setIsConfirmDeactivateDialogOpen(false);
      },
    });
  }, [disable, enqueueSnackbar]);

  const [tokenToVerify, setTokenToVerify] = useState<string>("");
  const handleTokenToVerifyChanged = useCallback(
    (tokenToVerifyValue: string): void => {
      setTokenToVerify(tokenToVerifyValue);
    },
    [setTokenToVerify]
  );
  const [tokenToVerifyIsValid, setTokenToVerifyIsValid] =
    useState<boolean>(false);

  const generateSecret = useCallback((): void => {
    const secret = speakeasy.generateSecret({
      issuer: "Reflexo",
      length: 20,
      name: `${applicationName} (${loginName})`,
    });
    addSecret(
      { value: secret.base32 },
      {
        onFail: (details): void => {
          enqueueSnackbar(details.title, { variant: "error" });
        },
        onSuccess: ({ body: { status: addSecretStatus } }): void => {
          if (addSecretStatus === "success") {
            QRCode.toDataURL(
              secret.otpauth_url as string,
              (error: Error | null | undefined, imageData: string): void => {
                if (error) {
                  enqueueSnackbar(error, { variant: "error" });
                  return;
                }
                setSecretDataUrl(imageData);
                setSecretText(secret.base32);
              }
            );
          }
        },
      }
    );
  }, [addSecret, applicationName, enqueueSnackbar, loginName]);

  const handleCancelClick = useCallback((): void => {
    setSecretDataUrl(undefined);
    setSecretText(undefined);
    setGAIsEnabled(false);
    setTokenToVerify("");
    setTokenToVerifyIsValid(false);
  }, []);

  const handleVerify = useCallback(() => {
    validate(
      { token: tokenToVerify },
      {
        onFail: (details) => {
          enqueueSnackbar(details.title, { variant: "error" });
        },
        onSuccess: ({ body: { result } }) => {
          if (result === "invalid") {
            setTokenToVerifyIsValid(true);
          }
          if (result === "valid") {
            enable((() => {})(), {
              onFail: (details) => {
                enqueueSnackbar(
                  `${t("User:TwoFactorAuthenticationEnableFailed")} (${
                    details.title
                  })`,
                  { variant: "error" }
                );
              },
              onSuccess: ({ body: { isEnabled } }) => {
                if (isEnabled === true) {
                  setGAIsEnabled(true);
                  setSecretDataUrl(undefined);
                  setSecretText(undefined);
                  setTokenToVerify("");
                }
              },
            });
          }
        },
      }
    );
  }, [enable, enqueueSnackbar, t, tokenToVerify, validate]);

  const handleActivatedClicked = useCallback((): void => {
    if (!isGAEnabled) {
      setSecretDataUrl(undefined);
      setSecretText(undefined);
      generateSecret();
    } else {
      setIsConfirmDeactivateDialogOpen(true);
    }
  }, [generateSecret, isGAEnabled]);

  useEffect((): void => {
    settings((() => {})(), {
      onFail: (details): void => {
        enqueueSnackbar(
          `${t("User:TwoFactorAuthenticationSettingsFailed")} (${
            details.title
          })`,
          { variant: "error" }
        );
      },
      onSuccess: ({ body: { isEnabled } }): void => {
        if (isEnabled === true) {
          setGAIsEnabled(true);
          setSecretDataUrl(undefined);
          setSecretText(undefined);
        }
      },
    });
  }, [enqueueSnackbar, settings, t]);

  return (
    <>
      <Card classes={{ root }}>
        <CardHeader
          avatar={
            <>
              <Avatar src={googleAuthenticatorLogoUrl} />
              <Avatar src={microsoftAuthenticatorLogoUrl} />
            </>
          }
          classes={{ avatar }}
          title={t("User:TwoFactorAuthenticationTitle")}
          titleTypographyProps={{ variant: "h5" }}
        />
        <CardContent>
          <Typography component="p">
            {t("User:TwoFactorAuthenticationGoogleAuthenticatorSettings")}
          </Typography>
          <FormControl style={{ marginTop: 15 }}>
            {isGAEnabled && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleActivatedClicked}
              >
                {t("Common:Deactivate")}
              </Button>
            )}
            {!isGAEnabled && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleActivatedClicked}
              >
                {t("Common:Activate")}
              </Button>
            )}
          </FormControl>
        </CardContent>
      </Card>
      <Dialog classes={{ paper: dialog }} open={isShowSecret}>
        <DialogTitle>{t("User:TwoFactorAuthenticationTitle")}</DialogTitle>
        <DialogContent>
          <AuthenticationTwoFactorCreateSecret
            onSubmit={handleVerify}
            onTokenToVerifyChanged={handleTokenToVerifyChanged}
            secretDataUrl={secretDataUrl}
            secretText={secretText}
            tokenToVerify={tokenToVerify}
            tokenToVerifyIsValid={tokenToVerifyIsValid}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelClick}
          >
            {t("Common:Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <AuthenticationTwoFactorConfirmDeactivateDialog
        open={isConfirmDeactivateDialogOpen}
        onCancel={handleConfirmDeactivateDialogCancel}
        onConfirm={handleConfirmDeactivateDialogConfirm}
      />
    </>
  );
};

export default memo(GoogleAuthenticatorSettings);
