import { FormControl, FormLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserModel } from "../../../business/models";
import { adminUsersListCell } from "../../../business/redux/saga/admin/users/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import Loading from "../../ux/Loading";

interface EmployeeSelectProps {
  onSelect: (employeeSelected: UserModel | null) => void;
  disabled?: boolean;
  employeeId?: number | null;
}

const EmployeeSelect = (props: EmployeeSelectProps) => {
  const { onSelect, disabled } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();

  const { employeeList = [], employeeListLoading } = useSelector(
    (store: StoreModel) => ({
      employeeList: store.admin.users.list.value,
      employeeListLoading: store.admin.users.list.status.loading,
    })
  );

  const handleEmployeeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const employeeSelectedId = event.target.value
      ? Number(event.target.value)
      : null;
    const employeeSelected =
      employeeList.find((employee) => employee.id === employeeSelectedId) ??
      null;
    onSelect(employeeSelected);
  };

  useEffect(() => {
    dispatch(adminUsersListCell.require());
  }, [dispatch]);

  return employeeListLoading ? (
    <Loading />
  ) : (
    <FormControl disabled={disabled}>
      <FormLabel>{t("Admin:User")}</FormLabel>
      <Select
        onChange={handleEmployeeChange}
        value={props.employeeId}
        disabled={disabled}
      >
        {employeeList.map((user) => (
          <MenuItem
            key={user.id}
            value={user.id}
            selected={user.id === props.employeeId}
          >
            {user.fullName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EmployeeSelect;
