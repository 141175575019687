import { Grid } from "@material-ui/core";
import { useSingle } from "async-lifecycle-hooks";
import React, { memo, useCallback, useMemo, useState } from "react";

import { SortOrder, UserModel, UserStatus } from "../../../business/models";
import { adminUsersListCell } from "../../../business/redux/saga/admin/users/cells";
import { UsersEditor, UsersFullTextFilter } from "../../admin/users";
import useStyles from "./AdminPage.styles";

const sortString =
  (sortOrder: SortOrder) =>
  (value1?: string, value2?: string): number => {
    if (!value1 || !value2) {
      return 0;
    }
    const first = sortOrder === "asc" ? value1 : value2;
    const second = sortOrder === "asc" ? value2 : value1;
    return first.localeCompare(second);
  };

const sortLoginName =
  (sortOrder: SortOrder) =>
  (
    { loginName: loginName1 }: UserModel,
    { loginName: loginName2 }: UserModel
  ): number =>
    sortString(sortOrder)(loginName1, loginName2);

const sortFullName =
  (sortOrder: SortOrder) =>
  (
    { fullName: fullName1 }: UserModel,
    { fullName: fullName2 }: UserModel
  ): number =>
    sortString(sortOrder)(fullName1, fullName2);

const sortEmail =
  (sortOrder: SortOrder) =>
  ({ email: email1 }: UserModel, { email: email2 }: UserModel): number =>
    sortString(sortOrder)(email1, email2);

const sortEmployeeType =
  (sortOrder: SortOrder) =>
  (
    { employeeType: employeeType1 }: UserModel,
    { employeeType: employeeType2 }: UserModel
  ): number =>
    sortString(sortOrder)(employeeType1, employeeType2);

const sortBoolean =
  (sortOrder: SortOrder) =>
  (value1?: boolean, value2?: boolean): number => {
    if (value1 === undefined || value2 === undefined) {
      return 0;
    }

    const first = sortOrder === "asc" ? value1 : value2;
    const second = sortOrder === "asc" ? value2 : value1;
    if (first && !second) {
      return 1;
    }
    if (!first && second) {
      return -1;
    }
    return 0;
  };

const sortEmployeeRole =
  (sortOrder: SortOrder) =>
  (
    { isEmployee: isEmployee1 }: UserModel,
    { isEmployee: isEmployee2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(isEmployee1, isEmployee2);

const sortOrganizationAdminRole =
  (sortOrder: SortOrder) =>
  (
    { isOrganizationAdmin: isOrganizationAdmin1 }: UserModel,
    { isOrganizationAdmin: isOrganizationAdmin2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(isOrganizationAdmin1, isOrganizationAdmin2);

const sortDatacockpitRole =
  (sortOrder: SortOrder) =>
  (
    { isDatacockpit: isDatacockpit1 }: UserModel,
    { isDatacockpit: isDatacockpit2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(isDatacockpit1, isDatacockpit2);

const sortPlatformAdminRole =
  (sortOrder: SortOrder) =>
  (
    { isPlatformAdmin: isPlatformAdmin1 }: UserModel,
    { isPlatformAdmin: isPlatformAdmin2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(isPlatformAdmin1, isPlatformAdmin2);

const sortApiAdminRole =
  (sortOrder: SortOrder) =>
  (
    { isApiAdmin: isApiAdmin1 }: UserModel,
    { isApiAdmin: isApiAdmin2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(isApiAdmin1, isApiAdmin2);

const sortApiAccessRole =
  (sortOrder: SortOrder) =>
  (
    { hasApiAccess: hasApiAccess1 }: UserModel,
    { hasApiAccess: hasApiAccess2 }: UserModel
  ): number =>
    sortBoolean(sortOrder)(hasApiAccess1, hasApiAccess2);

const statusToNumber: { [key in UserStatus]: number } = {
  [UserStatus.new]: 0,
  [UserStatus.invited]: 1,
  [UserStatus.active]: 2,
  [UserStatus.activeExternal]: 3,
};
const sortStatus =
  (sortOrder: SortOrder) =>
  ({ status: status1 }: UserModel, { status: status2 }: UserModel): number => {
    if (!status1 || !status2) {
      return 0;
    }
    const number1 = statusToNumber[status1];
    const number2 = statusToNumber[status2];
    return sortOrder === "asc" ? number1 - number2 : number2 - number1;
  };

const UsersPage = () => {
  const classes = useStyles();

  const users = useSingle(adminUsersListCell);

  const [fullTextFilterValue, setFullTextFilterValue] = useState<
    string | undefined
  >(undefined);
  const [sortIndex, setSortIndex] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");

  const handleSort = useCallback(
    (newSortIndex: number | null, newSortOrder: SortOrder) => {
      setSortIndex(newSortIndex);
      setSortOrder(newSortOrder);
    },
    []
  );

  const handleFullTextFilterChange = useCallback((newValue: string) => {
    setFullTextFilterValue(newValue);
  }, []);

  const usersFilteredAndSorted = useMemo((): UserModel[] => {
    if (!users) {
      return [];
    }

    let filteredUsers = [...(users.value || [])];
    if (fullTextFilterValue) {
      filteredUsers = filteredUsers.filter(
        (u) =>
          u.loginName
            .toLowerCase()
            .includes(fullTextFilterValue.toLowerCase()) ||
          u.fullName
            .toLowerCase()
            .includes(fullTextFilterValue.toLowerCase()) ||
          u.email?.toLowerCase().includes(fullTextFilterValue.toLowerCase())
      );
    }

    let sortFunction: (user1: UserModel, user2: UserModel) => number;
    switch (sortIndex) {
      case 0:
      default:
        sortFunction = sortLoginName(sortOrder);
        break;
      case 1:
        sortFunction = sortFullName(sortOrder);
        break;
      case 2:
        sortFunction = sortEmail(sortOrder);
        break;
      case 3:
        sortFunction = sortEmployeeType(sortOrder);
        break;
      case 4:
        sortFunction = sortEmployeeRole(sortOrder);
        break;
      case 5:
        sortFunction = sortOrganizationAdminRole(sortOrder);
        break;
      case 6:
        sortFunction = sortDatacockpitRole(sortOrder);
        break;
      case 7:
        sortFunction = sortPlatformAdminRole(sortOrder);
        break;
      case 8:
        sortFunction = sortApiAdminRole(sortOrder);
        break;
      case 9:
        sortFunction = sortApiAccessRole(sortOrder);
        break;
      case 10:
        sortFunction = sortStatus(sortOrder);
        break;
    }
    filteredUsers = filteredUsers.sort(sortFunction);

    return filteredUsers;
  }, [fullTextFilterValue, sortIndex, sortOrder, users]);

  return (
    <div className={classes.gridWideContent}>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <UsersFullTextFilter
            onChange={handleFullTextFilterChange}
            value={fullTextFilterValue}
          />
        </Grid>
        <Grid item>
          <UsersEditor onSort={handleSort} users={usersFilteredAndSorted} />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(UsersPage);
