import { AnyAction } from "redux";

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/no-empty-function
export const ignore = (): void => {};

export interface ConfirmTexts {
  ns?: string;
  title?: string;
  content?: string;
  abort?: string;
  cancel?: string;
  confirm?: string;
}

export interface ConfirmOptions {
  texts?: ConfirmTexts;
  onAbort?: () => void;
  onCancel?: () => void;
}

export interface Confirm extends ConfirmOptions {
  onConfirm: () => void;
}

export interface ConfirmContextValue {
  confirm(callbackAndOptions: Confirm): void;
  confirmAction(action: AnyAction, options?: ConfirmOptions): void;
}
