import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { Field, FieldProps, Form, Formik, FormikActions } from "formik";
import { Select, Switch, TextField } from "formik-material-ui";
import { DropzoneArea } from "material-ui-dropzone/dist";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { GenerateMarkdownPreview } from "react-mde/lib/definitions/types";
import { useDispatch, useSelector } from "react-redux";

import {
  AnnouncementFormValues,
  useAnnouncementValidationSchema,
} from "../../../business/models";
import { adminEmployeeTypesCell } from "../../../business/redux/saga/admin/employee/cells";
import { AnnouncementResponse } from "../../../business/redux/saga/announcements/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";

interface CreateAnnouncementDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (
    announcement: AnnouncementFormValues,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ) => void;
}

const CreateAnnouncementDialog = (props: CreateAnnouncementDialogProps) => {
  const { open, onCancel, onConfirm } = props;
  const [t] = useTranslationStrict();
  const { validationSchema } = useAnnouncementValidationSchema();

  const loading = useSelector(
    ({
      announcements: {
        add: {
          status: { loading },
        },
      },
    }: StoreModel) => loading
  );

  const handleCancel = useCallback((): void => {
    if (!loading) {
      onCancel();
    }
  }, [loading, onCancel]);

  const handleFormikSubmit = useCallback(
    (
      announcement: AnnouncementFormValues,
      { resetForm, setSubmitting }: FormikActions<AnnouncementFormValues>
    ): void => {
      setSubmitting(true);
      onConfirm(
        announcement,
        (): void => {
          setSubmitting(false);
        },
        (success: Success<AnnouncementResponse>): void => {
          if (success.body.status === "success") {
            resetForm();
          }
          setSubmitting(false);
        }
      );
    },
    [onConfirm]
  );

  const dispatch = useDispatch();
  useEffect((): void => {
    dispatch(adminEmployeeTypesCell.require());
  }, [dispatch]);
  const employeeTypes =
    useSelector(
      (store: StoreModel): string[] | undefined =>
        store.admin.employee.types.value
    ) || [];

  const { enqueueSnackbar } = useSnackbar();
  const handleDropRejected = (files: File[]): void => {
    files.forEach((file): void => {
      enqueueSnackbar(`Rejected ${file.name} | ${file.type} | ${file.size}`, {
        variant: "error",
      });
    });
  };

  const initialValues: AnnouncementFormValues =
    Object.freeze<AnnouncementFormValues>({
      title: "",
      text: "",
      link: "",
      roles: [],
      files: [],
      notifyUsers: false,
    });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const refs = {
    textarea: useRef<HTMLTextAreaElement>(null),
  };

  const handlePreview: GenerateMarkdownPreview = useCallback((markdown) => {
    return Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={validationSchema}
      render={({
        handleChange,
        setFieldValue,
        isSubmitting,
        isValid,
        resetForm,
        values: { title, link, roles, notifyUsers },
      }): JSX.Element => {
        return (
          <Dialog
            open={open}
            onClose={handleCancel}
            title={t("Admin:AnnouncementAddDialogTitle")}
          >
            <Form>
              <DialogTitle>{t("Admin:AnnouncementAddDialogTitle")}</DialogTitle>
              <DialogContent style={{ overflow: "hidden", width: "20vw" }}>
                <Grid container direction="column" spacing={8}>
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:AnnouncementTitle")}</FormLabel>
                      <Field
                        component={TextField}
                        id="title"
                        fullWidth
                        name="title"
                        onChange={handleChange}
                        value={title}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:AnnouncementText")}</FormLabel>
                      <Field id="text" fullWidth name="text" type="string">
                        {({
                          field: { value },
                          form: { setFieldValue },
                        }: FieldProps) => {
                          const handleChange = (value: string) => {
                            setFieldValue("text", value);
                          };

                          return (
                            <ReactMde
                              generateMarkdownPreview={handlePreview}
                              onChange={handleChange}
                              onTabChange={setSelectedTab}
                              refs={refs}
                              selectedTab={selectedTab}
                              value={value ?? ""}
                            />
                          );
                        }}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:AnnouncementLink")}</FormLabel>
                      <Field
                        component={TextField}
                        id="link"
                        fullWidth
                        name="link"
                        onChange={handleChange}
                        value={link}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {employeeTypes.length > 0 && (
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:Roles")}</FormLabel>
                      <Field
                        component={Select}
                        name="roles"
                        multiple
                        fullWidth
                        value={roles}
                        onChange={handleChange}
                      >
                        {employeeTypes.map((et) => (
                          <MenuItem key={et} value={et}>
                            {et}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <DropzoneArea
                    showAlerts={false}
                    acceptedFiles={[
                      "*/*",
                      "application/*",
                      "image/*",
                      "video/mp4",
                      "video/webm",
                    ]}
                    dropzoneText={t("Admin:File")}
                    filesLimit={1}
                    maxFileSize={33554432}
                    onChange={(files: File[]): void => {
                      setFieldValue("files", files);
                    }}
                    onDropRejected={handleDropRejected}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel>{t("Admin:AnnouncementNotify")}</FormLabel>
                    <Field
                      component={Switch}
                      id="notifyUsers"
                      fullWidth
                      name="notifyUsers"
                      onChange={handleChange}
                      value={notifyUsers}
                    />
                  </FormControl>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={(): void => {
                    resetForm();
                    handleCancel();
                  }}
                  type="button"
                  variant="outlined"
                >
                  {t("Common:Cancel")}
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {t("Common:Confirm")}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    />
  );
};

export default memo(CreateAnnouncementDialog);
