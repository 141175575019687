import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { memo } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

interface GoogleAuthenticatorConfirmDeactivateDialogProps {
  open: boolean;

  onCancel: () => void;
  onConfirm: () => void;
}

const AuthenticationTwoFactorConfirmDeactivateDialog = (
  props: GoogleAuthenticatorConfirmDeactivateDialogProps
) => {
  const { open, onCancel, onConfirm } = props;
  const [t] = useTranslationStrict();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {t(
          "User:TwoFactorAuthenticationGoogleAuthenticatorDeactivateConfirmDialogTitle"
        )}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t(
            "User:TwoFactorAuthenticationGoogleAuthenticatorDeactivateConfirmDialogContent"
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t("Common:Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t("Common:Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AuthenticationTwoFactorConfirmDeactivateDialog);
