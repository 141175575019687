import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone/dist";
import React, { memo, useCallback } from "react";

import { ChoiceData } from "../../business/alacarte";
import { getChoicePeriodMode } from "../../business/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { formatEuro, formatHours } from "../../utils/NumberUtils";
import ContentCard from "../layout/ContentCard";
import ChoicePayrollingView from "./choicePayrollingView";
import ChoiceRepetitionView from "./choiceRepetitionView";

interface ConfirmOneProps {
  choice: ChoiceData;
  updateChoice(choice: ChoiceData): void;
}

const pdf = ["application/pdf"];

const showHours = (choice: ChoiceData): string => {
  const {
    dataDefinition: { unitAfter = "" },
  } = choice;
  if (!choice.hourValue || unitAfter.indexOf("uur") < 0) {
    return "";
  }

  const currentHours = formatHours(choice.currentValue / choice.hourValue);
  return `${currentHours} uur = `;
};

const ConfirmOne = memo(({ choice, updateChoice }: ConfirmOneProps) => {
  const [t] = useTranslationStrict();
  const handleChange = useCallback(
    ([file]: File[]): void => {
      updateChoice({
        ...choice,
        uploads: file
          ? [
              {
                id: 0,
                contentType: pdf[0],
                documentName: file.name,
                dataDefinitionId: choice.id,
                file,
              },
            ]
          : [],
      });
    },
    [choice, updateChoice]
  );
  const handleClearUpload = useCallback(() => {
    updateChoice({
      ...choice,
      uploads: [],
    });
  }, [choice, updateChoice]);

  return (
    <Grid item xs={12} key={choice.id}>
      <ContentCard
        title={choice.definition.label}
        info={choice.definition.help}
        subtitle={showHours(choice) + formatEuro(choice.currentValue)}
      >
        <Grid container>
          <Grid item xs={choice.repeating ? 6 : 12}>
            <ChoicePayrollingView choice={choice} />
          </Grid>
          {choice.repeating && (
            <Grid item xs={6}>
              <ChoiceRepetitionView
                startMonth={choice.startMonth}
                endMonth={choice.endMonth}
                periodMode={getChoicePeriodMode(choice.definition.interval)}
                viewMode="Grid"
              />
            </Grid>
          )}
          {choice.definition.upload && (
            <Grid item xs={12}>
              {choice.uploads.length === 0 && (
                <DropzoneArea
                  acceptedFiles={pdf}
                  dropzoneText={t("Alacarte:AttachmentPdf")}
                  filesLimit={1}
                  maxFileSize={10000000}
                  onChange={handleChange}
                />
              )}
              {choice.uploads.length > 0 && (
                <FormLabel>
                  {t("Alacarte:Attachment")}: {choice.uploads[0].documentName}{" "}
                  <Button color="default" onClick={handleClearUpload}>
                    {t("Alacarte:Change")}
                  </Button>
                </FormLabel>
              )}
            </Grid>
          )}
        </Grid>
      </ContentCard>
    </Grid>
  );
});

interface ConfirmProps {
  closeDialog: () => void;
  dialogOpen: boolean;
  goals: ChoiceData[];
  updateChoice(choice: ChoiceData): void;
  confirmChoices?: () => void;
}

export default memo<ConfirmProps>(
  ({ dialogOpen, closeDialog, goals, confirmChoices, updateChoice }) => {
    const [t] = useTranslationStrict();
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
        scroll="paper"
        onBackdropClick={closeDialog}
      >
        <DialogTitle>
          {t("Alacarte:ChoicesMade")} ({goals.length})
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {goals.map(
              (c): JSX.Element => (
                <ConfirmOne key={c.id} choice={c} updateChoice={updateChoice} />
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t("Alacarte:Cancel")}</Button>
          <Button
            disabled={!confirmChoices}
            onClick={confirmChoices}
            color="primary"
            variant="contained"
          >
            {t("Alacarte:Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
