import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Add, FileCopy, Replay } from "@material-ui/icons";
import { asyncIsValid } from "async-lifecycle-saga";
import { Form, Formik, FormikActions } from "formik";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";

import { OrganizationModel } from "../../../business/models";
import { StoreModel } from "../../../business/redux/saga/models";
import {
  superOrganizationsCopy,
  superOrganizationsListCell,
  superOrganizationsSwitchCell,
} from "../../../business/redux/saga/super/organizations/cells";
import { Prefix } from "../../../business/redux/saga/super/organizations/models";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import { showAsyncFail, showError } from "../../../utils/SnackbarUtils";
import useStyles from "../admin/AdminPage.styles";

const AddOrganizationForm = memo(() => {
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (payload: Prefix, { resetForm, setSubmitting }: FormikActions<Prefix>) => {
      dispatch(
        superOrganizationsListCell.create(payload, {
          onFail: () => {
            enqueueSnackbar(t("Common:Error"));
            setSubmitting(false);
          },
          onSuccess: () => {
            resetForm();
            setSubmitting(false);
          },
        })
      );
    },
    [dispatch, enqueueSnackbar, t]
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prefix: yup
          .string()
          .required(t("Forms:Required"))
          .matches(/^[a-z0-9]{3,20}$/, t("Admin:PrefixInvalid")),
      }),
    [t]
  );
  return (
    <Formik
      initialValues={{ prefix: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({
        handleChange,
        isSubmitting,
        isValid,
        values: { prefix },
      }): JSX.Element => (
        <Form>
          <ListItem>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>
              <TextField
                label={t("Admin:Prefix")}
                value={prefix}
                name="prefix"
                onChange={handleChange}
              />
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t("Common:Add")}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </Form>
      )}
    />
  );
});

const OrganizationsPage = memo<{}>(() => {
  const { busy, organizations } = useSelector((store: StoreModel) => ({
    busy: store.super.organizations.copy.status.loading,
    organizations: store.super.organizations.list,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!(asyncIsValid(organizations) || organizations.status.loading)) {
      dispatch({ type: superOrganizationsListCell.events.require });
    }
  }, [dispatch, organizations]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const onSwitch = (prefix: string): void => {
    dispatch(
      superOrganizationsSwitchCell.require(
        { prefix },
        {
          onFail: () => showError(enqueueSnackbar),
          onSuccess: ({ body: { jwt } }) => {
            if (!jwt) {
              return;
            }

            history.push("/");
          },
        }
      )
    );
  };
  const { confirmAction } = useConfirm();
  const onCopy = (id: number): void =>
    confirmAction(
      superOrganizationsCopy.require(id, {
        onFail: showAsyncFail(enqueueSnackbar),
      }),
      {
        texts: {
          ns: "Admin",
          content: "ContentCopyDeleteConfirm",
        },
      }
    );

  const sorted = useMemo((): OrganizationModel[] => {
    if (!organizations.value) {
      return [];
    }
    const array = Array.from(organizations.value);
    array.sort((a, b): number => (a.prefix < b.prefix ? -1 : 1));
    return array;
  }, [organizations.value]);

  if (!organizations.value) {
    return (
      <div className={classes.gridWideContent}>
        <Card style={{ padding: 24 }}>
          <CardContent>
            <CircularProgress />;
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className={classes.gridWideContent}>
      <Card style={{ padding: 24 }}>
        <CardContent>
          <List>
            {sorted.map(
              ({ id = "0", name, prefix }): JSX.Element => (
                <ListItem key={id}>
                  <ListItemIcon title="Switch">
                    <IconButton
                      color="primary"
                      disabled={busy}
                      onClick={(): void => onSwitch(prefix)}
                    >
                      <Replay />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={name} secondary={prefix} />
                  <ListItemSecondaryAction>
                    <IconButton
                      color="secondary"
                      disabled={busy}
                      onClick={(): void => onCopy(Number(id))}
                    >
                      <FileCopy />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
            <AddOrganizationForm />
          </List>
        </CardContent>
      </Card>
    </div>
  );
});

export default OrganizationsPage;
