import { createActor } from "async-lifecycle-saga";
import { takeEvery } from "redux-saga/effects";

import { asyncGet } from "../../../api";
import { AuthenticationResponseModel } from "../authentication/models";
import { EmployeeSwitchRequest } from "./models";
import {
  superOrganizationsCopy,
  superOrganizationsListCell,
  superOrganizationsSwitchCell,
} from "./organizations/cells";
import {
  superOrganizationsCopySuccess,
  superOrganizationsSwitchSuccess,
} from "./organizations/sagas";
import { superImpersonateSuccess } from "./sagas";

export const superImpersonateCell = createActor<
  EmployeeSwitchRequest,
  AuthenticationResponseModel
>({
  path: ["super", "impersonate"],
  api: ({ employeeId }) => asyncGet(`/api/user/impersonate/${employeeId}`),
});

export const superSagas = [
  ...superImpersonateCell.sagas,
  takeEvery(superImpersonateCell.events.success, superImpersonateSuccess),
  ...superOrganizationsCopy.sagas,
  takeEvery(
    superOrganizationsCopy.events.success,
    superOrganizationsCopySuccess
  ),
  ...superOrganizationsListCell.sagas,
  ...superOrganizationsSwitchCell.sagas,
  takeEvery(
    superOrganizationsSwitchCell.events.success,
    superOrganizationsSwitchSuccess
  ),
];
