import {
  AcUnit,
  AccessAlarm,
  AccessAlarms,
  AccessTime,
  Accessibility,
  AccessibilityNew,
  Accessible,
  AccessibleForward,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  Adb,
  Add,
  AddAPhoto,
  AddAlarm,
  AddAlert,
  AddBox,
  AddCircle,
  AddComment,
  AddLocation,
  AddPhotoAlternate,
  AddShoppingCart,
  AddToHomeScreen,
  AddToPhotos,
  AddToQueue,
  Adjust,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatIndividualSuite,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomReduced,
  AirlineSeatReclineExtra,
  AirlineSeatReclineNormal,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Album,
  AllInbox,
  AllInclusive,
  AllOut,
  AlternateEmail,
  Android,
  Announcement,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  ArtTrack,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  Atm,
  AttachFile,
  AttachMoney,
  Attachment,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  Ballot,
  BarChart,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryStd,
  BatteryUnknown,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  BluetoothAudio,
  BluetoothConnected,
  BluetoothDisabled,
  BluetoothSearching,
  BlurCircular,
  BlurLinear,
  BlurOff,
  BlurOn,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderColor,
  BorderHorizontal,
  BorderInner,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderStyle,
  BorderTop,
  BorderVertical,
  BrandingWatermark,
  Brightness1,
  Brightness2,
  Brightness3,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  BrightnessAuto,
  BrightnessHigh,
  BrightnessLow,
  BrightnessMedium,
  BrokenImage,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  BurstMode,
  Business,
  BusinessCenter,
  Cached,
  Cake,
  CalendarToday,
  CalendarViewDay,
  Call,
  CallEnd,
  CallMade,
  CallMerge,
  CallMissed,
  CallMissedOutgoing,
  CallReceived,
  CallSplit,
  CallToAction,
  Camera,
  CameraAlt,
  CameraEnhance,
  CameraFront,
  CameraRear,
  CameraRoll,
  Cancel,
  CancelPresentation,
  CardGiftcard,
  CardMembership,
  CardTravel,
  Casino,
  Cast,
  CastConnected,
  CastForEducation,
  Category,
  CellWifi,
  CenterFocusStrong,
  CenterFocusWeak,
  ChangeHistory,
  Chat,
  ChatBubble,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  ChildFriendly,
  ChromeReaderMode,
  Class,
  Clear,
  ClearAll,
  Close,
  ClosedCaption,
  Cloud,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOff,
  CloudQueue,
  CloudUpload,
  Code,
  Collections,
  CollectionsBookmark,
  ColorLens,
  Colorize,
  Comment,
  Commute,
  Compare,
  CompareArrows,
  CompassCalibration,
  Computer,
  ConfirmationNumber,
  ContactMail,
  ContactPhone,
  ContactSupport,
  Contacts,
  ControlCamera,
  ControlPoint,
  ControlPointDuplicate,
  Copyright,
  Create,
  CreateNewFolder,
  CreditCard,
  Crop,
  Crop169,
  Crop32,
  Crop54,
  Crop75,
  CropDin,
  CropFree,
  CropLandscape,
  CropOriginal,
  CropPortrait,
  CropRotate,
  CropSquare,
  Dashboard,
  DataUsage,
  DateRange,
  Dehaze,
  Delete,
  DeleteForever,
  DeleteSweep,
  DepartureBoard,
  Description,
  DesktopAccessDisabled,
  DesktopMac,
  DesktopWindows,
  Details,
  DeveloperBoard,
  DeveloperMode,
  DeviceHub,
  DeviceUnknown,
  Devices,
  DevicesOther,
  DialerSip,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsTransit,
  DirectionsWalk,
  DiscFull,
  Dns,
  Dock,
  Domain,
  DomainDisabled,
  Done,
  DoneAll,
  DonutLarge,
  DonutSmall,
  Drafts,
  DragHandle,
  DragIndicator,
  DriveEta,
  Duo,
  Dvr,
  Edit,
  EditAttributes,
  EditLocation,
  Eject,
  Email,
  EnhancedEncryption,
  Equalizer,
  Error,
  EuroSymbol,
  EvStation,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  EventSeat,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explicit,
  Explore,
  ExploreOff,
  Exposure,
  ExposureNeg1,
  ExposureNeg2,
  ExposurePlus1,
  ExposurePlus2,
  ExposureZero,
  Extension,
  Face,
  FastForward,
  FastRewind,
  Fastfood,
  Favorite,
  FavoriteBorder,
  FeaturedPlayList,
  FeaturedVideo,
  Feedback,
  FiberDvr,
  FiberManualRecord,
  FiberNew,
  FiberPin,
  FiberSmartRecord,
  FileCopy,
  Filter,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Filter9Plus,
  FilterBAndW,
  FilterCenterFocus,
  FilterDrama,
  FilterFrames,
  FilterHdr,
  FilterList,
  FilterNone,
  FilterTiltShift,
  FilterVintage,
  FindInPage,
  FindReplace,
  Fingerprint,
  FirstPage,
  FitnessCenter,
  Flag,
  Flare,
  FlashAuto,
  FlashOff,
  FlashOn,
  Flight,
  FlightLand,
  FlightTakeoff,
  Flip,
  FlipToBack,
  FlipToFront,
  Folder,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FontDownload,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorFill,
  FormatColorReset,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatLineSpacing,
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl,
  FormatPaint,
  FormatQuote,
  FormatShapes,
  FormatSize,
  FormatStrikethrough,
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  FormatUnderlined,
  Forum,
  Forward,
  Forward10,
  Forward30,
  Forward5,
  FourK,
  FreeBreakfast,
  Fullscreen,
  FullscreenExit,
  Functions,
  GTranslate,
  Gamepad,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GolfCourse,
  GpsFixed,
  GpsNotFixed,
  GpsOff,
  Grade,
  Gradient,
  Grain,
  GraphicEq,
  GridOff,
  GridOn,
  Group,
  GroupAdd,
  GroupWork,
  Hd,
  HdrOff,
  HdrOn,
  HdrStrong,
  HdrWeak,
  Headset,
  HeadsetMic,
  Healing,
  Hearing,
  Help,
  HighQuality,
  Highlight,
  HighlightOff,
  History,
  Home,
  HorizontalSplit,
  HotTub,
  Hotel,
  HourglassEmpty,
  HourglassFull,
  HowToReg,
  HowToVote,
  Http,
  Https,
  Image,
  ImageAspectRatio,
  ImageSearch,
  ImportContacts,
  ImportExport,
  ImportantDevices,
  Inbox,
  IndeterminateCheckBox,
  Info,
  Input,
  InsertChart,
  InsertComment,
  InsertDriveFile,
  InsertEmoticon,
  InsertInvitation,
  InsertLink,
  InsertPhoto,
  InvertColors,
  InvertColorsOff,
  Iso,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardBackspace,
  KeyboardCapslock,
  KeyboardHide,
  KeyboardReturn,
  KeyboardTab,
  KeyboardVoice,
  Kitchen,
  Label,
  LabelImportant,
  LabelOff,
  Landscape,
  Language,
  Laptop,
  LaptopChromebook,
  LaptopMac,
  LaptopWindows,
  LastPage,
  Launch,
  Layers,
  LayersClear,
  LeakAdd,
  LeakRemove,
  Lens,
  LibraryAdd,
  LibraryBooks,
  LibraryMusic,
  LineStyle,
  LineWeight,
  LinearScale,
  Link,
  LinkOff,
  LinkedCamera,
  List,
  ListAlt,
  LiveHelp,
  LiveTv,
  LocalActivity,
  LocalAirport,
  LocalAtm,
  LocalBar,
  LocalCafe,
  LocalCarWash,
  LocalConvenienceStore,
  LocalDining,
  LocalDrink,
  LocalFlorist,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalHotel,
  LocalLaundryService,
  LocalLibrary,
  LocalMall,
  LocalMovies,
  LocalOffer,
  LocalParking,
  LocalPharmacy,
  LocalPhone,
  LocalPizza,
  LocalPlay,
  LocalPostOffice,
  LocalPrintshop,
  LocalSee,
  LocalShipping,
  LocalTaxi,
  LocationCity,
  LocationDisabled,
  LocationOff,
  LocationOn,
  LocationSearching,
  Lock,
  LockOpen,
  Looks,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  LooksOne,
  LooksTwo,
  Loop,
  Loupe,
  LowPriority,
  Loyalty,
  Mail,
  Map,
  Markunread,
  MarkunreadMailbox,
  Maximize,
  MeetingRoom,
  Memory,
  Menu,
  MergeType,
  Message,
  Mic,
  MicNone,
  MicOff,
  Minimize,
  MissedVideoCall,
  Mms,
  MobileFriendly,
  MobileOff,
  MobileScreenShare,
  ModeComment,
  MonetizationOn,
  Money,
  MoneyOff,
  MonochromePhotos,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Motorcycle,
  Mouse,
  MoveToInbox,
  Movie,
  MovieCreation,
  MovieFilter,
  MultilineChart,
  MusicNote,
  MusicOff,
  MusicVideo,
  MyLocation,
  Nature,
  NaturePeople,
  NavigateBefore,
  NavigateNext,
  Navigation,
  NearMe,
  NetworkCell,
  NetworkCheck,
  NetworkLocked,
  NetworkWifi,
  NewReleases,
  NextWeek,
  Nfc,
  NoEncryption,
  NoMeetingRoom,
  NoSim,
  NotInterested,
  NotListedLocation,
  Note,
  NoteAdd,
  Notes,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotificationsNone,
  NotificationsOff,
  NotificationsPaused,
  OfflineBolt,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInBrowser,
  OpenInNew,
  OpenWith,
  OutlinedFlag,
  Pages,
  Pageview,
  Palette,
  PanTool,
  Panorama,
  PanoramaFishEye,
  PanoramaHorizontal,
  PanoramaVertical,
  PanoramaWideAngle,
  PartyMode,
  Pause,
  PauseCircleFilled,
  PausePresentation,
  Payment,
  People,
  PermCameraMic,
  PermContactCalendar,
  PermDataSetting,
  PermDeviceInformation,
  PermIdentity,
  PermMedia,
  PermPhoneMsg,
  PermScanWifi,
  Person,
  PersonAdd,
  PersonAddDisabled,
  PersonPin,
  PersonPinCircle,
  PersonalVideo,
  Pets,
  Phone,
  PhoneAndroid,
  PhoneBluetoothSpeaker,
  PhoneCallback,
  PhoneForwarded,
  PhoneInTalk,
  PhoneIphone,
  PhoneLocked,
  PhoneMissed,
  PhonePaused,
  Phonelink,
  PhonelinkErase,
  PhonelinkLock,
  PhonelinkOff,
  PhonelinkRing,
  PhonelinkSetup,
  Photo,
  PhotoAlbum,
  PhotoCamera,
  PhotoFilter,
  PhotoLibrary,
  PhotoSizeSelectActual,
  PhotoSizeSelectLarge,
  PhotoSizeSelectSmall,
  PictureAsPdf,
  PictureInPicture,
  PictureInPictureAlt,
  PieChart,
  PinDrop,
  Place,
  PlayArrow,
  PlayCircleFilled,
  PlayCircleFilledWhite,
  PlayForWork,
  PlaylistAdd,
  PlaylistAddCheck,
  PlaylistPlay,
  PlusOne,
  Poll,
  Polymer,
  Pool,
  PortableWifiOff,
  Portrait,
  Power,
  PowerInput,
  PowerOff,
  PowerSettingsNew,
  PregnantWoman,
  PresentToAll,
  Print,
  PrintDisabled,
  PriorityHigh,
  Public,
  Publish,
  QueryBuilder,
  QuestionAnswer,
  Queue,
  QueueMusic,
  QueuePlayNext,
  Radio,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RateReview,
  Receipt,
  RecentActors,
  RecordVoiceOver,
  Redeem,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveFromQueue,
  RemoveRedEye,
  RemoveShoppingCart,
  Reorder,
  Repeat,
  RepeatOne,
  Replay,
  Replay10,
  Replay30,
  Replay5,
  Reply,
  ReplyAll,
  Report,
  ReportOff,
  ReportProblem,
  Restaurant,
  RestaurantMenu,
  Restore,
  RestoreFromTrash,
  RestorePage,
  RingVolume,
  Room,
  RoomService,
  Rotate90DegreesCcw,
  RotateLeft,
  RotateRight,
  RoundedCorner,
  Router,
  Rowing,
  RssFeed,
  RvHookup,
  Satellite,
  Save,
  SaveAlt,
  Scanner,
  ScatterPlot,
  Schedule,
  School,
  Score,
  ScreenLockLandscape,
  ScreenLockPortrait,
  ScreenLockRotation,
  ScreenRotation,
  ScreenShare,
  SdCard,
  SdStorage,
  Search,
  Security,
  SelectAll,
  Send,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  Settings,
  SettingsApplications,
  SettingsBackupRestore,
  SettingsBluetooth,
  SettingsBrightness,
  SettingsCell,
  SettingsEthernet,
  SettingsInputAntenna,
  SettingsInputComponent,
  SettingsInputComposite,
  SettingsInputHdmi,
  SettingsInputSvideo,
  SettingsOverscan,
  SettingsPhone,
  SettingsPower,
  SettingsRemote,
  SettingsSystemDaydream,
  SettingsVoice,
  Share,
  Shop,
  ShopTwo,
  ShoppingBasket,
  ShoppingCart,
  ShortText,
  ShowChart,
  Shuffle,
  ShutterSpeed,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet3Bar,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularNoSim,
  SignalCellularNull,
  SignalCellularOff,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi1BarLock,
  SignalWifi2Bar,
  SignalWifi2BarLock,
  SignalWifi3Bar,
  SignalWifi3BarLock,
  SignalWifi4Bar,
  SignalWifi4BarLock,
  SignalWifiOff,
  SimCard,
  SkipNext,
  SkipPrevious,
  Slideshow,
  SlowMotionVideo,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Sms,
  SmsFailed,
  Snooze,
  Sort,
  SortByAlpha,
  Spa,
  SpaceBar,
  Speaker,
  SpeakerGroup,
  SpeakerNotes,
  SpeakerNotesOff,
  SpeakerPhone,
  Spellcheck,
  Star,
  StarBorder,
  StarHalf,
  StarRate,
  Stars,
  StayCurrentLandscape,
  StayCurrentPortrait,
  StayPrimaryLandscape,
  StayPrimaryPortrait,
  Stop,
  StopScreenShare,
  Storage,
  Store,
  StoreMallDirectory,
  Straighten,
  Streetview,
  StrikethroughS,
  Style,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Subject,
  Subscriptions,
  Subtitles,
  Subway,
  SupervisedUserCircle,
  SupervisorAccount,
  SurroundSound,
  SwapCalls,
  SwapHoriz,
  SwapHorizontalCircle,
  SwapVert,
  SwapVerticalCircle,
  SwitchCamera,
  SwitchVideo,
  Sync,
  SyncDisabled,
  SyncProblem,
  SystemUpdate,
  Tab,
  TabUnselected,
  TableChart,
  Tablet,
  TabletAndroid,
  TabletMac,
  TagFaces,
  TapAndPlay,
  Terrain,
  TextFields,
  TextFormat,
  TextRotateUp,
  TextRotateVertical,
  TextRotationDown,
  TextRotationNone,
  Textsms,
  Texture,
  Theaters,
  ThreeDRotation,
  ThreeSixty,
  ThumbDown,
  ThumbDownAlt,
  ThumbUp,
  ThumbUpAlt,
  ThumbsUpDown,
  TimeToLeave,
  Timelapse,
  Timeline,
  Timer,
  Timer10,
  Timer3,
  TimerOff,
  Title,
  Toc,
  Today,
  ToggleOff,
  ToggleOn,
  Toll,
  Tonality,
  TouchApp,
  Toys,
  TrackChanges,
  Traffic,
  Train,
  Tram,
  TransferWithinAStation,
  Transform,
  TransitEnterexit,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  TripOrigin,
  Tune,
  TurnedIn,
  TurnedInNot,
  Tv,
  TvOff,
  Unarchive,
  Undo,
  UnfoldLess,
  UnfoldMore,
  Unsubscribe,
  Update,
  Usb,
  VerifiedUser,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  VerticalSplit,
  Vibration,
  VideoCall,
  VideoLabel,
  VideoLibrary,
  Videocam,
  VideocamOff,
  VideogameAsset,
  ViewAgenda,
  ViewArray,
  ViewCarousel,
  ViewColumn,
  ViewComfy,
  ViewCompact,
  ViewDay,
  ViewHeadline,
  ViewList,
  ViewModule,
  ViewQuilt,
  ViewStream,
  ViewWeek,
  Vignette,
  Visibility,
  VisibilityOff,
  VoiceChat,
  VoiceOverOff,
  Voicemail,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  VpnLock,
  Wallpaper,
  Warning,
  Watch,
  WatchLater,
  Waves,
  WbAuto,
  WbCloudy,
  WbIncandescent,
  WbIridescent,
  WbSunny,
  Wc,
  Web,
  WebAsset,
  Weekend,
  Whatshot,
  WhereToVote,
  Widgets,
  Wifi,
  WifiLock,
  WifiOff,
  WifiTethering,
  Work,
  WorkOff,
  WrapText,
  YoutubeSearchedFor,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
} from "@material-ui/icons";
import React, { ComponentType } from "react";

export const materialIcons: { [key: string]: ComponentType } = {
  AccessAlarm,
  AccessAlarms,
  Accessibility,
  AccessibilityNew,
  Accessible,
  AccessibleForward,
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AcUnit,
  Adb,
  Add,
  AddAlarm,
  AddAlert,
  AddAPhoto,
  AddBox,
  AddCircle,
  AddComment,
  AddLocation,
  AddPhotoAlternate,
  AddShoppingCart,
  AddToHomeScreen,
  AddToPhotos,
  AddToQueue,
  Adjust,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatIndividualSuite,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomReduced,
  AirlineSeatReclineExtra,
  AirlineSeatReclineNormal,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Album,
  AllInbox,
  AllInclusive,
  AllOut,
  AlternateEmail,
  Android,
  Announcement,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  ArtTrack,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  Atm,
  AttachFile,
  Attachment,
  AttachMoney,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  Ballot,
  BarChart,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryStd,
  BatteryUnknown,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  BluetoothAudio,
  BluetoothConnected,
  BluetoothDisabled,
  BluetoothSearching,
  BlurCircular,
  BlurLinear,
  BlurOff,
  BlurOn,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderColor,
  BorderHorizontal,
  BorderInner,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderStyle,
  BorderTop,
  BorderVertical,
  BrandingWatermark,
  Brightness1,
  Brightness2,
  Brightness3,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  BrightnessAuto,
  BrightnessHigh,
  BrightnessLow,
  BrightnessMedium,
  BrokenImage,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  BurstMode,
  Business,
  BusinessCenter,
  Cached,
  Cake,
  CalendarToday,
  CalendarViewDay,
  Call,
  CallEnd,
  CallMade,
  CallMerge,
  CallMissed,
  CallMissedOutgoing,
  CallReceived,
  CallSplit,
  CallToAction,
  Camera,
  CameraAlt,
  CameraEnhance,
  CameraFront,
  CameraRear,
  CameraRoll,
  Cancel,
  CancelPresentation,
  CardGiftcard,
  CardMembership,
  CardTravel,
  Casino,
  Cast,
  CastConnected,
  CastForEducation,
  Category,
  CellWifi,
  CenterFocusStrong,
  CenterFocusWeak,
  ChangeHistory,
  Chat,
  ChatBubble,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  ChildFriendly,
  ChromeReaderMode,
  Class,
  Clear,
  ClearAll,
  Close,
  ClosedCaption,
  Cloud,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOff,
  CloudQueue,
  CloudUpload,
  Code,
  Collections,
  CollectionsBookmark,
  Colorize,
  ColorLens,
  Comment,
  Commute,
  Compare,
  CompareArrows,
  CompassCalibration,
  Computer,
  ConfirmationNumber,
  ContactMail,
  ContactPhone,
  Contacts,
  ContactSupport,
  ControlCamera,
  ControlPoint,
  ControlPointDuplicate,
  Copyright,
  Create,
  CreateNewFolder,
  CreditCard,
  Crop,
  Crop169,
  Crop32,
  Crop54,
  Crop75,
  CropDin,
  CropFree,
  CropLandscape,
  CropOriginal,
  CropPortrait,
  CropRotate,
  CropSquare,
  Dashboard,
  DataUsage,
  DateRange,
  Dehaze,
  Delete,
  DeleteForever,
  DeleteSweep,
  DepartureBoard,
  Description,
  DesktopAccessDisabled,
  DesktopMac,
  DesktopWindows,
  Details,
  DeveloperBoard,
  DeveloperMode,
  DeviceHub,
  Devices,
  DevicesOther,
  DeviceUnknown,
  DialerSip,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsTransit,
  DirectionsWalk,
  DiscFull,
  Dns,
  Dock,
  Domain,
  DomainDisabled,
  Done,
  DoneAll,
  DonutLarge,
  DonutSmall,
  Drafts,
  DragHandle,
  DragIndicator,
  DriveEta,
  Duo,
  Dvr,
  Edit,
  EditAttributes,
  EditLocation,
  Eject,
  Email,
  EnhancedEncryption,
  Equalizer,
  Error,
  EuroSymbol,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  EventSeat,
  EvStation,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explicit,
  Explore,
  ExploreOff,
  Exposure,
  ExposureNeg1,
  ExposureNeg2,
  ExposurePlus1,
  ExposurePlus2,
  ExposureZero,
  Extension,
  Face,
  Fastfood,
  FastForward,
  FastRewind,
  Favorite,
  FavoriteBorder,
  FeaturedPlayList,
  FeaturedVideo,
  Feedback,
  FiberDvr,
  FiberManualRecord,
  FiberNew,
  FiberPin,
  FiberSmartRecord,
  FileCopy,
  Filter,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Filter9Plus,
  FilterBAndW,
  FilterCenterFocus,
  FilterDrama,
  FilterFrames,
  FilterHdr,
  FilterList,
  FilterNone,
  FilterTiltShift,
  FilterVintage,
  FindInPage,
  FindReplace,
  Fingerprint,
  FirstPage,
  FitnessCenter,
  Flag,
  Flare,
  FlashAuto,
  FlashOff,
  FlashOn,
  Flight,
  FlightLand,
  FlightTakeoff,
  Flip,
  FlipToBack,
  FlipToFront,
  Folder,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FontDownload,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorFill,
  FormatColorReset,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatLineSpacing,
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl,
  FormatPaint,
  FormatQuote,
  FormatShapes,
  FormatSize,
  FormatStrikethrough,
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  FormatUnderlined,
  Forum,
  Forward,
  Forward10,
  Forward30,
  Forward5,
  FourK,
  FreeBreakfast,
  Fullscreen,
  FullscreenExit,
  Functions,
  Gamepad,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GolfCourse,
  GpsFixed,
  GpsNotFixed,
  GpsOff,
  Grade,
  Gradient,
  Grain,
  GraphicEq,
  GridOff,
  GridOn,
  Group,
  GroupAdd,
  GroupWork,
  GTranslate,
  Hd,
  HdrOff,
  HdrOn,
  HdrStrong,
  HdrWeak,
  Headset,
  HeadsetMic,
  Healing,
  Hearing,
  Help,
  Highlight,
  HighlightOff,
  HighQuality,
  History,
  Home,
  HorizontalSplit,
  Hotel,
  HotTub,
  HourglassEmpty,
  HourglassFull,
  HowToReg,
  HowToVote,
  Http,
  Https,
  Image,
  ImageAspectRatio,
  ImageSearch,
  ImportantDevices,
  ImportContacts,
  ImportExport,
  Inbox,
  IndeterminateCheckBox,
  Info,
  Input,
  InsertChart,
  InsertComment,
  InsertDriveFile,
  InsertEmoticon,
  InsertInvitation,
  InsertLink,
  InsertPhoto,
  InvertColors,
  InvertColorsOff,
  Iso,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardBackspace,
  KeyboardCapslock,
  KeyboardHide,
  KeyboardReturn,
  KeyboardTab,
  KeyboardVoice,
  Kitchen,
  Label,
  LabelImportant,
  LabelOff,
  Landscape,
  Language,
  Laptop,
  LaptopChromebook,
  LaptopMac,
  LaptopWindows,
  LastPage,
  Launch,
  Layers,
  LayersClear,
  LeakAdd,
  LeakRemove,
  Lens,
  LibraryAdd,
  LibraryBooks,
  LibraryMusic,
  LinearScale,
  LineStyle,
  LineWeight,
  Link,
  LinkedCamera,
  LinkOff,
  List,
  ListAlt,
  LiveHelp,
  LiveTv,
  LocalActivity,
  LocalAirport,
  LocalAtm,
  LocalBar,
  LocalCafe,
  LocalCarWash,
  LocalConvenienceStore,
  LocalDining,
  LocalDrink,
  LocalFlorist,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalHotel,
  LocalLaundryService,
  LocalLibrary,
  LocalMall,
  LocalMovies,
  LocalOffer,
  LocalParking,
  LocalPharmacy,
  LocalPhone,
  LocalPizza,
  LocalPlay,
  LocalPostOffice,
  LocalPrintshop,
  LocalSee,
  LocalShipping,
  LocalTaxi,
  LocationCity,
  LocationDisabled,
  LocationOff,
  LocationOn,
  LocationSearching,
  Lock,
  LockOpen,
  Looks,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  LooksOne,
  LooksTwo,
  Loop,
  Loupe,
  LowPriority,
  Loyalty,
  Mail,
  Map,
  Markunread,
  MarkunreadMailbox,
  Maximize,
  MeetingRoom,
  Memory,
  Menu,
  MergeType,
  Message,
  Mic,
  MicNone,
  MicOff,
  Minimize,
  MissedVideoCall,
  Mms,
  MobileFriendly,
  MobileOff,
  MobileScreenShare,
  ModeComment,
  MonetizationOn,
  Money,
  MoneyOff,
  MonochromePhotos,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Motorcycle,
  Mouse,
  MoveToInbox,
  Movie,
  MovieCreation,
  MovieFilter,
  MultilineChart,
  MusicNote,
  MusicOff,
  MusicVideo,
  MyLocation,
  Nature,
  NaturePeople,
  NavigateBefore,
  NavigateNext,
  Navigation,
  NearMe,
  NetworkCell,
  NetworkCheck,
  NetworkLocked,
  NetworkWifi,
  NewReleases,
  NextWeek,
  Nfc,
  NoEncryption,
  NoMeetingRoom,
  NoSim,
  Note,
  NoteAdd,
  Notes,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotificationsNone,
  NotificationsOff,
  NotificationsPaused,
  NotInterested,
  NotListedLocation,
  OfflineBolt,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInBrowser,
  OpenInNew,
  OpenWith,
  OutlinedFlag,
  Pages,
  Pageview,
  Palette,
  Panorama,
  PanoramaFishEye,
  PanoramaHorizontal,
  PanoramaVertical,
  PanoramaWideAngle,
  PanTool,
  PartyMode,
  Pause,
  PauseCircleFilled,
  PausePresentation,
  Payment,
  People,
  PermCameraMic,
  PermContactCalendar,
  PermDataSetting,
  PermDeviceInformation,
  PermIdentity,
  PermMedia,
  PermPhoneMsg,
  PermScanWifi,
  Person,
  PersonAdd,
  PersonAddDisabled,
  PersonalVideo,
  PersonPin,
  PersonPinCircle,
  Pets,
  Phone,
  PhoneAndroid,
  PhoneBluetoothSpeaker,
  PhoneCallback,
  PhoneForwarded,
  PhoneInTalk,
  PhoneIphone,
  Phonelink,
  PhonelinkErase,
  PhonelinkLock,
  PhonelinkOff,
  PhonelinkRing,
  PhonelinkSetup,
  PhoneLocked,
  PhoneMissed,
  PhonePaused,
  Photo,
  PhotoAlbum,
  PhotoCamera,
  PhotoFilter,
  PhotoLibrary,
  PhotoSizeSelectActual,
  PhotoSizeSelectLarge,
  PhotoSizeSelectSmall,
  PictureAsPdf,
  PictureInPicture,
  PictureInPictureAlt,
  PieChart,
  PinDrop,
  Place,
  PlayArrow,
  PlayCircleFilled,
  PlayCircleFilledWhite,
  PlayForWork,
  PlaylistAdd,
  PlaylistAddCheck,
  PlaylistPlay,
  PlusOne,
  Poll,
  Polymer,
  Pool,
  PortableWifiOff,
  Portrait,
  Power,
  PowerInput,
  PowerOff,
  PowerSettingsNew,
  PregnantWoman,
  PresentToAll,
  Print,
  PrintDisabled,
  PriorityHigh,
  Public,
  Publish,
  QueryBuilder,
  QuestionAnswer,
  Queue,
  QueueMusic,
  QueuePlayNext,
  Radio,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RateReview,
  Receipt,
  RecentActors,
  RecordVoiceOver,
  Redeem,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveFromQueue,
  RemoveRedEye,
  RemoveShoppingCart,
  Reorder,
  Repeat,
  RepeatOne,
  Replay,
  Replay10,
  Replay30,
  Replay5,
  Reply,
  ReplyAll,
  Report,
  ReportOff,
  ReportProblem,
  Restaurant,
  RestaurantMenu,
  Restore,
  RestoreFromTrash,
  RestorePage,
  RingVolume,
  Room,
  RoomService,
  Rotate90DegreesCcw,
  RotateLeft,
  RotateRight,
  RoundedCorner,
  Router,
  Rowing,
  RssFeed,
  RvHookup,
  Satellite,
  Save,
  SaveAlt,
  Scanner,
  ScatterPlot,
  Schedule,
  School,
  Score,
  ScreenLockLandscape,
  ScreenLockPortrait,
  ScreenLockRotation,
  ScreenRotation,
  ScreenShare,
  SdCard,
  SdStorage,
  Search,
  Security,
  SelectAll,
  Send,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  Settings,
  SettingsApplications,
  SettingsBackupRestore,
  SettingsBluetooth,
  SettingsBrightness,
  SettingsCell,
  SettingsEthernet,
  SettingsInputAntenna,
  SettingsInputComponent,
  SettingsInputComposite,
  SettingsInputHdmi,
  SettingsInputSvideo,
  SettingsOverscan,
  SettingsPhone,
  SettingsPower,
  SettingsRemote,
  SettingsSystemDaydream,
  SettingsVoice,
  Share,
  Shop,
  ShoppingBasket,
  ShoppingCart,
  ShopTwo,
  ShortText,
  ShowChart,
  Shuffle,
  ShutterSpeed,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet3Bar,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularNoSim,
  SignalCellularNull,
  SignalCellularOff,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi1BarLock,
  SignalWifi2Bar,
  SignalWifi2BarLock,
  SignalWifi3Bar,
  SignalWifi3BarLock,
  SignalWifi4Bar,
  SignalWifi4BarLock,
  SignalWifiOff,
  SimCard,
  SkipNext,
  SkipPrevious,
  Slideshow,
  SlowMotionVideo,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Sms,
  SmsFailed,
  Snooze,
  Sort,
  SortByAlpha,
  Spa,
  SpaceBar,
  Speaker,
  SpeakerGroup,
  SpeakerNotes,
  SpeakerNotesOff,
  SpeakerPhone,
  Spellcheck,
  Star,
  StarBorder,
  StarHalf,
  StarRate,
  Stars,
  StayCurrentLandscape,
  StayCurrentPortrait,
  StayPrimaryLandscape,
  StayPrimaryPortrait,
  Stop,
  StopScreenShare,
  Storage,
  Store,
  StoreMallDirectory,
  Straighten,
  Streetview,
  StrikethroughS,
  Style,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Subject,
  Subscriptions,
  Subtitles,
  Subway,
  SupervisedUserCircle,
  SupervisorAccount,
  SurroundSound,
  SwapCalls,
  SwapHoriz,
  SwapHorizontalCircle,
  SwapVert,
  SwapVerticalCircle,
  SwitchCamera,
  SwitchVideo,
  Sync,
  SyncDisabled,
  SyncProblem,
  SystemUpdate,
  Tab,
  TableChart,
  Tablet,
  TabletAndroid,
  TabletMac,
  TabUnselected,
  TagFaces,
  TapAndPlay,
  Terrain,
  TextFields,
  TextFormat,
  TextRotateUp,
  TextRotateVertical,
  TextRotationDown,
  TextRotationNone,
  Textsms,
  Texture,
  Theaters,
  ThreeDRotation,
  ThreeSixty,
  ThumbDown,
  ThumbDownAlt,
  ThumbsUpDown,
  ThumbUp,
  ThumbUpAlt,
  Timelapse,
  Timeline,
  Timer,
  Timer10,
  Timer3,
  TimerOff,
  TimeToLeave,
  Title,
  Toc,
  Today,
  ToggleOff,
  ToggleOn,
  Toll,
  Tonality,
  TouchApp,
  Toys,
  TrackChanges,
  Traffic,
  Train,
  Tram,
  TransferWithinAStation,
  Transform,
  TransitEnterexit,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  TripOrigin,
  Tune,
  TurnedIn,
  TurnedInNot,
  Tv,
  TvOff,
  Unarchive,
  Undo,
  UnfoldLess,
  UnfoldMore,
  Unsubscribe,
  Update,
  Usb,
  VerifiedUser,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  VerticalSplit,
  Vibration,
  VideoCall,
  Videocam,
  VideocamOff,
  VideogameAsset,
  VideoLabel,
  VideoLibrary,
  ViewAgenda,
  ViewArray,
  ViewCarousel,
  ViewColumn,
  ViewComfy,
  ViewCompact,
  ViewDay,
  ViewHeadline,
  ViewList,
  ViewModule,
  ViewQuilt,
  ViewStream,
  ViewWeek,
  Vignette,
  Visibility,
  VisibilityOff,
  VoiceChat,
  Voicemail,
  VoiceOverOff,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  VpnLock,
  Wallpaper,
  Warning,
  Watch,
  WatchLater,
  Waves,
  WbAuto,
  WbCloudy,
  WbIncandescent,
  WbIridescent,
  WbSunny,
  Wc,
  Web,
  WebAsset,
  Weekend,
  Whatshot,
  WhereToVote,
  Widgets,
  Wifi,
  WifiLock,
  WifiOff,
  WifiTethering,
  Work,
  WorkOff,
  WrapText,
  YoutubeSearchedFor,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
};

const materialIcon = (icon = "ChevronRight"): JSX.Element => {
  if (!(icon in materialIcons)) {
    return <ChevronRight />;
  }

  const Icon = materialIcons[icon];
  return <Icon />;
};

export default materialIcon;
