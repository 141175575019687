import React, {
  CSSProperties,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children: ReactNode;
}

const Modal = memo(({ children }: ModalProps): JSX.Element => {
  const div = useMemo(
    () => document.body.appendChild(document.createElement("div")),
    []
  );
  useEffect(() => {
    return (): void => {
      document.body.removeChild(div);
    };
  }, [div]);
  return createPortal(children, div);
});

interface BlownupImageProps {
  alt: string;
  src: string;
  onClose(): void;
}

const blowupLayoverStyle: CSSProperties = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(255, 255,255, 0.3)",
  top: 0,
  left: 0,
  zIndex: 1200,
};
const blownupImgStyle: CSSProperties = {
  margin: "auto",
  maxWidth: "95vw",
  maxHeight: "95vh",
};
const BlownupImage = memo(
  ({ alt, src, onClose }: BlownupImageProps): JSX.Element => (
    <Modal>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div style={blowupLayoverStyle} onClick={onClose}>
        <img alt={alt} src={src} style={blownupImgStyle} />
      </div>
    </Modal>
  )
);

export interface BlowupImageProps {
  alt: string;
  src: string;
  className?: string;
}

const origImgStyle: CSSProperties = { cursor: "pointer" };
const BlowupImage = memo(
  ({ alt, src, className }: BlowupImageProps): JSX.Element => {
    const img = useRef<HTMLImageElement>(null);
    const [open, setOpen] = useState(false);
    const toggleOpen = useCallback((): void => {
      setOpen(!open);
    }, [open]);
    return (
      <>
        <img
          ref={img}
          alt={alt}
          src={src}
          style={origImgStyle}
          className={className}
          onClick={toggleOpen}
        />
        {open && <BlownupImage alt={alt} src={src} onClose={toggleOpen} />}
      </>
    );
  }
);

export default BlowupImage;
