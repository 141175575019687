import { AsyncResponse, toAsyncResponse } from "async-lifecycle-saga";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import i18next from "i18next";
import jwtDecode from "jwt-decode";

import DateDifference from "../utils/DateUtils";
import { getFileNameFromContentDisposition } from "../utils/FileUtils";
import { getRefreshToken } from "../utils/SecurityUtils";
import { getReflexoStore } from "./redux/reflexoStore";
import {
  authenticationCell,
  authenticationRefresh,
  authenticationRefreshCell,
} from "./redux/saga/authentication/cells";
import { AuthenticationResponseModel } from "./redux/saga/authentication/models";

const fetchConfig: RequestInit = {
  credentials: "same-origin",
};

const jsonHeaders: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const formHeaders: HeadersInit = {
  Accept: "application/json",
};

export interface JsonResponse<T> {
  exception?: string;
  payload?: T;
}

type HttpMethod = "get" | "post" | "patch" | "put" | "delete";

const fetchWithToken = (
  method: HttpMethod,
  pathAndQuery: string,
  body?: string,
  token?: string
): Promise<Response> => {
  const requestInit: RequestInit = {
    headers: token
      ? {
          ...jsonHeaders,
          "Accept-Language": i18next.language,
          Authorization: `Bearer ${token}`,
        }
      : { ...jsonHeaders, "Accept-Language": i18next.language },
    method,
    body,
    ...fetchConfig,
  };
  return fetch(pathAndQuery, requestInit);
};

const jwtTokenExpires = (accessToken: string): Date => {
  const { exp } = jwtDecode<{ exp: number }>(accessToken);
  return new Date(exp * 1000);
};

const getToken = (): Promise<string> => {
  const { jwt } = getReflexoStore().getState().session;
  if (
    jwt?.accessToken &&
    DateDifference.minutes(new Date(), jwtTokenExpires(jwt.accessToken)) > 2
  ) {
    return Promise.resolve(jwt.accessToken);
  }

  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    getReflexoStore().dispatch(authenticationCell.unauthorized());
    return Promise.reject(new Error("Not authenticated"));
  }

  return authenticationRefresh({ refreshToken }).then(
    (response: AsyncResponse<AuthenticationResponseModel>) => {
      if (!(response as Success<AuthenticationResponseModel>).body?.jwt) {
        getReflexoStore().dispatch(authenticationCell.unauthorized());
        return Promise.reject(response.title);
      }

      getReflexoStore().dispatch({
        type: authenticationRefreshCell.events.success,
        result: response,
      });

      return Promise.resolve(
        (response as Success<AuthenticationResponseModel>).body.jwt!.accessToken
      );
    }
  );
};

export const asyncDownloadGet = async (
  pathAndQuery: string,
  anonymous: boolean = false
): Promise<AsyncResponse<FileResponse>> => {
  let headers: HeadersInit | undefined = undefined;
  if (!anonymous) {
    const token = await getToken();
    headers = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  const initOptions: RequestInit = {
    headers,
    method: "get",
    ...fetchConfig,
  };

  const response = await fetch(pathAndQuery, initOptions);

  if (response.status === 200) {
    const name =
      getFileNameFromContentDisposition(
        response.headers.get("Content-Disposition")
      ) || "file";
    const contentType =
      response.headers.get("Content-Type") || "application/octet-stream";
    const data = await response.blob();
    const payload = {
      name,
      contentType,
      data,
    };
    return { body: payload, status: response.status } as Success<FileResponse>;
  }
  return {
    status: response.status,
    title: `Response status ${response.status}`,
  } as Details;
};

export const asyncDownloadPost = async <TRequest extends object>(
  pathAndQuery: string,
  request: TRequest
): Promise<AsyncResponse<FileResponse>> => {
  const token = await getToken();
  const initOptions: RequestInit = {
    headers: token
      ? {
          ...jsonHeaders,
          "Accept-Language": i18next.language,
          Authorization: `Bearer ${token}`,
        }
      : { ...jsonHeaders, "Accept-Language": i18next.language },
    body: JSON.stringify(request),
    method: "post",
    ...fetchConfig,
  };

  const response = await fetch(pathAndQuery, initOptions);

  if (response.status === 200) {
    const name =
      getFileNameFromContentDisposition(
        response.headers.get("Content-Disposition")
      ) || "file";
    const contentType =
      response.headers.get("Content-Type") || "application/octet-stream";
    const data = await response.blob();
    const payload = {
      name,
      contentType,
      data,
    };
    return { body: payload, status: response.status } as Success<FileResponse>;
  }
  return {
    status: response.status,
    title: `Response status ${response.status}`,
  } as Details;
};

const asyncFetch = <T>(
  method: HttpMethod,
  pathAndQuery: string,
  body?: string,
  anonymous: boolean = false
): Promise<AsyncResponse<T>> =>
  (anonymous
    ? fetchWithToken(method, pathAndQuery, body)
    : getToken().then((token) =>
        fetchWithToken(method, pathAndQuery, body, token)
      )
  ).then((response) => toAsyncResponse(response));

export const asyncDelete = <T = undefined>(
  pathAndQuery: string
): Promise<AsyncResponse<T>> => asyncFetch("delete", pathAndQuery);

export const asyncGet = <T>(
  pathAndQuery: string,
  anonymous: boolean = false
): Promise<AsyncResponse<T>> =>
  asyncFetch("get", pathAndQuery, undefined, anonymous);

export const asyncPatch = <TResponse, TBody>(
  pathAndQuery: string,
  body: TBody,
  anonymous: boolean = false
): Promise<AsyncResponse<TResponse>> =>
  asyncFetch("patch", pathAndQuery, JSON.stringify(body), anonymous);

export const asyncPost = <T = undefined>(
  pathAndQuery: string,
  body: object,
  anonymous: boolean = false
): Promise<AsyncResponse<T>> =>
  asyncFetch("post", pathAndQuery, JSON.stringify(body), anonymous);

export const asyncPut = <T = undefined>(
  pathAndQuery: string,
  body: object,
  anonymous: boolean = false
): Promise<AsyncResponse<T>> =>
  asyncFetch("put", pathAndQuery, JSON.stringify(body), anonymous);

export const asyncUpload = async <T>(
  pathAndQuery: string,
  body: FormData
): Promise<AsyncResponse<T>> => {
  const token = await getToken();
  const initOptions: RequestInit = {
    headers: { ...formHeaders, Authorization: `Bearer ${token}` },
    method: "post",
    body,
    ...fetchConfig,
  };
  return fetch(pathAndQuery, initOptions).then(toAsyncResponse);
};

export interface TotlResponse {
  link: string;
}

export interface FileResponse {
  contentType: string;
  name: string;
  data: Blob;
}

export const cryptOrgplan = (
  id: number,
  code?: string
): Promise<{ [name: string]: string }> =>
  getToken()
    .then((token) =>
      fetchWithToken(
        "get",
        `/api/dms/orgplan/${id}?code=${code}`,
        undefined,
        token
      )
    )
    .then((response) => response.json());
