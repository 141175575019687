import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import * as React from "react";
import { memo, useCallback, useMemo, useState } from "react";

import googleAuthenticatorLogoUrl from "../../../assets/GoogleAuthenticator.png";
import { useAuthentication } from "../../../business/redux/saga/authentication/hooks";
import { AuthenticationResponseModel } from "../../../business/redux/saga/authentication/models";
import { useTranslationStrict } from "../../../globalization/i18n";

export interface GoogleAuthenticatorValidatorProps {
  onInvalid?: () => void;
  onValid: () => void;
}

const AuthenticationTwoFactorValidator = (
  props: GoogleAuthenticatorValidatorProps
) => {
  const { onInvalid, onValid } = props;
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { possession } = useAuthentication();

  const [vToken, setVToken] = useState<string>("");
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  const handleVTokenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setVToken(event.currentTarget.value);
    },
    []
  );

  const validateFail = useCallback(
    (details: Details) => {
      enqueueSnackbar(
        `${t("User:TwoFactorAuthenticationValidateFailed")} (${details.title})`,
        { variant: "error" }
      );
    },
    [enqueueSnackbar, t]
  );

  const validateSuccess = useCallback(
    ({ body: { status } }: Success<AuthenticationResponseModel>): void => {
      switch (status) {
        case "denied":
          setIsTokenValid(false);
          if (onInvalid !== undefined) {
            onInvalid();
          }
          break;
        case "authenticated":
          setIsTokenValid(true);
          onValid();
          break;
        default:
          setIsTokenValid(false);
          break;
      }
    },
    [onInvalid, onValid]
  );

  const handleValidateClick = useCallback(
    async (e): Promise<void> => {
      e.preventDefault();
      possession(
        { totpValue: vToken },
        { onFail: validateFail, onSuccess: validateSuccess }
      );
    },
    [possession, vToken, validateFail, validateSuccess]
  );

  const GAAvatar = useMemo(
    (): React.ReactElement => (
      <Avatar
        src={googleAuthenticatorLogoUrl}
        alt={t("User:TwoFactorAuthenticationTitle")}
        title={t("User:TwoFactorAuthenticationTitle")}
      />
    ),
    [t]
  );

  return (
    <form onSubmit={handleValidateClick} method="dialog">
      <Card>
        <CardHeader
          avatar={GAAvatar}
          title={t("User:TwoFactorAuthenticationTitle")}
        />
        <CardContent>
          <Typography component="p">
            {t("User:TwoFactorAuthenticationGoogleAuthenticatorInstructions")}
          </Typography>
          <FormControl>
            <TextField
              autoFocus
              error={isTokenValid !== null && !isTokenValid}
              helperText={
                isTokenValid !== null &&
                !isTokenValid &&
                t("User:TwoFactorAuthenticationTokenInvalidErrorMessage")
              }
              label="Token"
              onChange={handleVTokenChange}
              placeholder="012345"
              value={vToken}
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained" color="primary">
            {t("User:Verification")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default memo(AuthenticationTwoFactorValidator);
