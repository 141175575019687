import {
  Button,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { DragEventHandler, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { isSpecialRoute } from "../../business/admin/navigation";
import { adminNavigationNodePruneCell } from "../../business/redux/saga/admin/navigation/cells";
import { reflexoHistory } from "../../business/router";
import AppBarDropArea from "../admin/navigation/appBar/AppBarDropArea";
import { useSnackbarErrorMessage } from "../ux";
import useStyles from "./AppBarMenuItem.styles";
import AppBarMenuItemOption, {
  AppBarMenuItemOptionProps,
} from "./AppBarMenuItemOption";

export interface AppBarMenuItemProps {
  caption: string | JSX.Element;
  draggable?: boolean;
  nodeId: number;
  route: string;
  options?: AppBarMenuItemOptionProps[];
}

const AppBarMenuItem = (props: AppBarMenuItemProps) => {
  const {
    caption: mainCaption,
    draggable,
    nodeId,
    route: mainRoute,
    options,
  } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [indexLoading, setIndexLoading] = useState<number | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleMenuItemClick = useCallback(
    (route: string) => {
      setMenuAnchorEl(null);
      reflexoHistory.push(route);
    },
    [setMenuAnchorEl]
  );

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleDragStart: (childId: number) => DragEventHandler = useCallback(
    (childId) => (event) => {
      event.dataTransfer.setData("text", childId.toString());
    },
    []
  );

  const handleDrag: DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (childId: number, index: number) => {
      setIndexLoading(index);
      dispatch(
        adminNavigationNodePruneCell.require(
          {
            parentIsRoot: false,
            parentId: nodeId,
            childId,
            index,
          },
          {
            onFail: (details) => {
              setIndexLoading(null);
              showErrorMessageFromDetails(
                t("Fout bij verplaatsen pagina", { ns: "Admin" })
              )(details);
            },
            onSuccess: () => {
              setIndexLoading(null);
            },
          }
        )
      );
    },
    [dispatch, nodeId, showErrorMessageFromDetails, t]
  );

  return (
    <>
      <Button
        key={mainRoute}
        className={classes.button}
        draggable={draggable}
        onClick={handleClick}
        onDragStart={handleDragStart(nodeId)}
        onDrag={handleDrag}
      >
        {mainCaption} <ArrowDropDown />
      </Button>
      <Popover
        className={classes.menu}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        open={menuOpen}
      >
        <List>
          {[
            ...(isSpecialRoute(mainRoute)
              ? []
              : [
                  <ListItem
                    key={mainRoute}
                    className={classes.menuItem}
                    onClick={(): void => handleMenuItemClick(mainRoute)}
                    selected={window.location.pathname === mainRoute}
                  >
                    <Typography className={classes.menuItemLink}>
                      {mainCaption}
                    </Typography>
                  </ListItem>,
                  <Divider key="divider" />,
                  <>
                    {draggable && (
                      <AppBarDropArea
                        key="first"
                        index={0}
                        loading={indexLoading === 0}
                        onDrop={handleDrop}
                        variant="sub"
                      />
                    )}
                  </>,
                ]),
            ...(options || []).map(
              (
                {
                  caption: itemCaption,
                  nodeId: subNodeId,
                  options,
                  route,
                }: AppBarMenuItemProps,
                level1Index
              ): React.ReactElement => (
                <>
                  <AppBarMenuItemOption
                    caption={itemCaption}
                    draggable={draggable}
                    key={route}
                    level={1}
                    nodeId={subNodeId}
                    onClick={handleMenuItemClick}
                    options={options}
                    route={route}
                  />
                  {draggable && (
                    <AppBarDropArea
                      key={`drop-${level1Index}`}
                      index={level1Index + 1}
                      loading={indexLoading === level1Index + 1}
                      onDrop={handleDrop}
                      variant="sub"
                    />
                  )}
                </>
              )
            ),
          ]}
        </List>
      </Popover>
    </>
  );
};

export default memo(AppBarMenuItem);
