import { createActor } from "async-lifecycle-saga";

import { asyncGet, asyncPost, asyncPut } from "../../../api";
import { SsoToken } from "../authentication/models";
import {
  GenerateApiRefreshTokenResponse,
  UserPasswordResetInput,
  UserPasswordResetRequestInput,
  UserPasswordResetRequestResult,
  UserPasswordResetResult,
  UserPasswordResetVerifyInput,
  UserPasswordResetVerifyResult,
} from "./models";

export const daywizeTokenCell = createActor<string, SsoToken>({
  path: ["user", "daywizeToken"],
  api: (targetUrl) => asyncGet(`/api/user/daywizeToken?targetUrl=${targetUrl}`),
  onRefresh: () => "clear",
});

export const userPasswordResetCell = createActor<
  UserPasswordResetInput,
  UserPasswordResetResult
>({
  path: ["user", "password", "reset"],
  api: (request) => asyncPut("/api/user/reset", request, true),
});

export const userPasswordResetRequestCell = createActor<
  UserPasswordResetRequestInput,
  UserPasswordResetRequestResult
>({
  path: ["user", "password", "resetRequest"],
  api: (request) => asyncPost("/api/user/reset", request, true),
});

export const userPasswordResetVerifyCell = createActor<
  UserPasswordResetVerifyInput,
  UserPasswordResetVerifyResult
>({
  path: ["user", "password", "resetVerify"],
  api: (request) =>
    asyncPost("/api/user/verifyPasswordResetToken", request, true),
});
export const userGenerateTokenCell = createActor<
  number,
  GenerateApiRefreshTokenResponse
>({
  path: ["user", "generateToken"],
  api: (employeeId) => asyncPost("/api/user/refreshtoken", { employeeId }),
});
