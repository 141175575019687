import { combineReducers } from "redux";

import {
  personalWlzAdviceCaseReportDownloadCell,
  personalWlzAdviceCaseUpsertCell,
  personalWlzAdviceCasesCell,
} from "./cells";

const wlzReducer = combineReducers({
  adviceCaseReportDownload: personalWlzAdviceCaseReportDownloadCell.reducer,
  adviceCases: personalWlzAdviceCasesCell.reducer,
  adviceCaseUpsert: personalWlzAdviceCaseUpsertCell.reducer,
});

export default wlzReducer;
