import { flow, map, sortBy } from "lodash/fp";

import { TFunctionStrict } from "../../../globalization/i18n";

export enum UploadTypeId {
  contractsExport = -7,
}
export type UploadCategory = "scratch" | "admin" | "personal";

export interface UploadTypeModel {
  id: number | UploadTypeId;
  name: string;
  category: UploadCategory;
  perYear: boolean;
  perPeriod: boolean;
}

export interface UploadStatusModel {
  id: number;
  uploadDateTime: Date;
  uploadEffectiveDate: Date;
  checkDateTime?: Date;
  processDateTime?: Date;
  uploadTypeDto: UploadTypeModel;
  checkWarningLevel: "clean" | "minor" | "major" | "blocker";
  checkOutput?: string;
  contentType: string;
  documentName: string;
  busy?: boolean;
  assigned?: number;
  dangling?: number;
}

export const translateAndSortUploadTypes = (
  uploadTypes: UploadTypeModel[],
  t: TFunctionStrict
): UploadTypeModel[] =>
  flow(
    map((uploadType: UploadTypeModel) => ({
      ...uploadType,
      name: t(`UploadType:${uploadType.name.replace(":", ";")}`),
    })),
    sortBy(({ name }: UploadTypeModel) => name)
  )(uploadTypes);
