import React, { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

import { sessionStoragePreserveKey } from "../../index";

const LinkPreserveSession = (
  props: DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    localStorage.setItem(
      sessionStoragePreserveKey,
      JSON.stringify(sessionStorage)
    );
    window.open(props.href || "", "_blank", "noopener,noreferrer");
  };

  return (
    <a {...props} onClick={handleClick}>
      {props.children}
    </a>
  );
};

export default LinkPreserveSession;
