import { AsyncResponse, AsyncSingleValue } from "async-lifecycle-saga";
import { AsyncAction, Success } from "async-lifecycle-saga/dist/models";

import { ProfileModel, Role, SessionModel, UserModel } from "../../../models";
import { appCell } from "../app/cells";
import {
  authenticationCell,
  authenticationKnowledgeCell,
  authenticationLogOutCell,
  authenticationPossessionCell,
  authenticationRefreshCell,
} from "../authentication/cells";
import { AuthenticationResponseModel } from "../authentication/models";
import { authenticationTwoFactorSetupFinishCell } from "../authentication/twoFactor/cells";
import { profileCell } from "../profile/cells";
import { superImpersonateCell } from "../super/cells";
import { superOrganizationsSwitchCell } from "../super/organizations/cells";
import { sessionRoleUpdateCell } from "./cells";

const userHasRole = (user: UserModel, appRole: Role) => {
  switch (appRole) {
    case "employee":
      return user.isEmployee;
    case "organizationAdmin":
      return user.isOrganizationAdmin;
    case "datacockpit":
      return user.isDatacockpit;
    case "platformAdmin":
      return user.isPlatformAdmin;
    default:
      return false;
  }
};

export const emptySession: SessionModel = Object.freeze<SessionModel>({
  applicationName: "",
  appRole: "employee",
});

const getSession = (
  response: AsyncResponse<AuthenticationResponseModel> | undefined,
  appRole: Role
): SessionModel => {
  if (!(response as Success<AuthenticationResponseModel>)?.body) {
    return emptySession;
  }

  const {
    body: { applicationName, employee, googleAuthenticator, jwt },
  } = response as Success<AuthenticationResponseModel>;
  return {
    applicationName,
    appRole:
      employee && userHasRole(employee, appRole)
        ? appRole
        : "employee" /* always keep selected role if user has role, otherwise reset */,
    googleAuthenticator,
    jwt,
    user: employee,
  };
};

const sessionReducer = (
  state: SessionModel = emptySession,
  {
    type,
    payload,
    result,
  }: AsyncAction<
    unknown,
    AuthenticationResponseModel,
    AsyncSingleValue<AuthenticationResponseModel>
  >
): SessionModel => {
  switch (type) {
    case appCell.events.clear:
    case authenticationCell.events.error:
    case authenticationCell.events.unauthorized:
    case authenticationKnowledgeCell.events.error:
    case authenticationPossessionCell.events.error:
    case authenticationRefreshCell.events.error:
    case authenticationLogOutCell.events.success:
    case authenticationLogOutCell.events.error:
      return emptySession;
    case authenticationCell.events.authenticated:
    case authenticationCell.events.twoFactorRequired:
    case authenticationKnowledgeCell.events.success:
    case authenticationTwoFactorSetupFinishCell.events.success:
    case superImpersonateCell.events.success:
    case superOrganizationsSwitchCell.events.success:
      return {
        ...state,
        ...getSession(
          result,
          state.appRole
        ) /* always keep selected role if user has role, otherwise reset */,
      };
    case profileCell.events.success:
      const { body: profile } = result as Success<ProfileModel>;
      return {
        ...state,
        profile,
      };
    case sessionRoleUpdateCell.events.require:
      return { ...state, appRole: payload as Role };
    default:
      return state;
  }
};

export default sessionReducer;
