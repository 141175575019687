import { Grid } from "@material-ui/core";
import { startOfDay } from "date-fns";
import { DateType } from "material-ui-pickers/constants/prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DataDefinitionGroup, UserModel } from "../../../business/models";
import {
  datacockpitEmployeeDataEditCell,
  datacockpitEmployeeDataFilterCell,
} from "../../../business/redux/saga/datacockpit/cells";
import { useEmployeeData } from "../../../business/redux/saga/datacockpit/hooks";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import EmployeeSelect from "../../admin/employees/EmployeeSelect";
import DataDefinitionGroupSelect from "../../input/DataDefinitionGroupSelect";
import DateInput from "../../input/DateInput";
import Loading from "../../ux/Loading";
import { EmployeeDataEditActions, EmployeeDataViewActions } from "./";

const EmployeeDataFilter = () => {
  // Hooks
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();

  // Data
  const { dataDefinitionsLoading } = useSelector((store: StoreModel) => {
    const {
      status: { loading: dataDefinitionsLoading },
      value: dataDefinitions,
    } = store.datacockpit.dataDefinitions;
    return {
      dataDefinitions,
      dataDefinitionsLoading,
    };
  });
  const { edit, filter } = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData
  );
  const employeeData = useEmployeeData();

  // Event handlers
  const toggleEditEmployee = () => {
    dispatch(datacockpitEmployeeDataEditCell.set(edit ? null : employeeData));
  };

  const onEmployeeSelected = (employeeSelected: UserModel | null) => {
    dispatch(
      datacockpitEmployeeDataFilterCell.set({
        ...filter,
        employeeId: employeeSelected?.id ?? null,
      })
    );
  };

  const onGroupSelected = (groupSelected: DataDefinitionGroup) => {
    dispatch(
      datacockpitEmployeeDataFilterCell.set({
        ...filter,
        group: groupSelected,
      })
    );
  };

  const onReferenceDateSelected = (dateSelected: DateType) => {
    dispatch(
      datacockpitEmployeeDataFilterCell.set({
        ...filter,
        referenceDate: dateSelected
          ? new Date(dateSelected as string | number)
          : startOfDay(new Date()),
      })
    );
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        {dataDefinitionsLoading && <Loading />}
        {!dataDefinitionsLoading && (
          <EmployeeSelect
            onSelect={onEmployeeSelected}
            disabled={Boolean(edit)}
            employeeId={filter.employeeId}
          />
        )}
      </Grid>
      <Grid item>
        {filter.employeeId && (
          <DataDefinitionGroupSelect
            onSelect={onGroupSelected}
            groupSelected={filter.group}
            disabled={Boolean(edit)}
          />
        )}
      </Grid>
      <Grid item>
        {filter.employeeId && (
          <DateInput
            label={t("Datacockpit:Peildatum")}
            onChange={onReferenceDateSelected}
            value={filter.referenceDate || new Date()}
            disabled={Boolean(edit)}
          />
        )}
      </Grid>
      <Grid item style={{ flex: "1 1 0" }} />
      {/* Spacer */}
      {filter.employeeId && filter.group !== DataDefinitionGroup.Geen && (
        <Grid item>
          {edit ? (
            <EmployeeDataEditActions onCancel={toggleEditEmployee} />
          ) : (
            <EmployeeDataViewActions onEdit={toggleEditEmployee} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default EmployeeDataFilter;
