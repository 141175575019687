import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from "@material-ui/core";
import { Field, Form, Formik, FormikActions } from "formik";
import { Switch, TextField } from "formik-material-ui";
import { DatePicker } from "material-ui-pickers";
import { Ref } from "react";
import * as Yup from "yup";

import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";

interface Props {
  formRef: Ref<Formik<WlzAdviceCase>>;
  initialValues: WlzAdviceCase;
  validationSchema: Yup.ObjectSchema<WlzAdviceCase>;

  onSubmit(
    values: WlzAdviceCase,
    formikActions: FormikActions<WlzAdviceCase>
  ): void;
}

const minDate = new Date("2022-01-01");
const today = new Date();
const maxDate = new Date(today.getFullYear(), 11, 31);

const WlzAdviceCaseForm = (props: Props) => {
  const { formRef, initialValues, onSubmit, validationSchema } = props;
  const [t] = useTranslationStrict();

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({
        handleChange,
        isSubmitting,
        setFieldValue,
        values: { title, yearCalculation, couple, note, archive },
      }) => {
        return (
          <Form>
            <Card>
              <CardHeader title={t("Wlz:Advies Casus")} />
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Field
                      disabled={isSubmitting}
                      fullWidth
                      label={t("Wlz:Titel")}
                      component={TextField}
                      name="title"
                      onChange={handleChange}
                      placeholder={t("Wlz:Titel")}
                      value={title}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Wlz:Jaar berekening")}</FormLabel>
                      <DatePicker
                        disabled={isSubmitting}
                        value={yearCalculation ?? today}
                        onChange={(date) =>
                          setFieldValue("yearCalculation", date)
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        openTo="year"
                        views={["year"]}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Field
                          disabled
                          component={Switch}
                          fullWidth
                          name="couple"
                          disableRipple
                          onChange={handleChange}
                          value={couple}
                        />
                      }
                      label={t("Wlz:Paar")}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      disabled={isSubmitting}
                      label={t("Wlz:Notitie")}
                      component={TextField}
                      fullWidth
                      multiline
                      name="note"
                      onChange={handleChange}
                      placeholder={t("Wlz:Notitie")}
                      rows={5}
                      value={note}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Field
                          disabled={isSubmitting}
                          component={Switch}
                          fullWidth
                          name="archive"
                          disableRipple
                          onChange={handleChange}
                          value={archive}
                        />
                      }
                      label={t("Wlz:Archief")}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        );
      }}
    />
  );
};

export default WlzAdviceCaseForm;
