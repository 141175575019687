import React from "react";

import { VariablesOverview } from "../../datacockpit/table";
import useStyles from "../admin/AdminPage.styles";

const ExportPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <VariablesOverview isExport />
    </div>
  );
};

export default ExportPage;
