import { makeStyles } from "@material-ui/core";

import { mobileScreen, typographyBold } from "../../styling/Common";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing() * 3,
  },
  bold: typographyBold(),
  menuIcon: { color: theme.palette.common.black },
  menuIconWarning: { color: theme.palette.primary.main },
  menuSecondaryAction: { right: "0px !important" },
  menuText: { ...typographyBold(), fontSize: "1rem !important" },
  subMenuText: { fontSize: "1rem !important" },
  noFlex: {
    display: "inherit !important",
    paddingRight: "5px !important",
  },
  ...mobileScreen({}),
}));

export default useStyles;
