import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { SyncOutlined as BuildIcon } from "@material-ui/icons";
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";

import { ChoiceData } from "../../business/alacarte";
import { useTranslationStrict } from "../../globalization/i18n";
import { linkRenderer } from "../../utils/markdown";
import {
  formatEuro,
  formatHours,
  parseInputNumber,
} from "../../utils/NumberUtils";
import ContentCard from "../layout/ContentCard";
import useStyles from "./choice.styles";
import ChoiceRepetition from "./choiceRepetition";

interface ChoiceProps {
  choice: ChoiceData;
  updateChoice?: (updatedChoice: ChoiceData) => void;
  setAutoValue: (choice: ChoiceData) => void;
  disableDirectly: boolean;
}

const Choice: FunctionComponent<ChoiceProps> = ({
  choice,
  updateChoice,
  setAutoValue,
  disableDirectly,
}: ChoiceProps) => {
  const [t] = useTranslationStrict();
  const maxHours = choice.hourValue
    ? choice.maxValue / choice.hourValue
    : undefined;
  const currentHours = choice.hourValue
    ? Math.round((choice.currentValue / choice.hourValue) * 100) / 100
    : 0;
  const [currentHoursString, setHoursString] = useState(
    formatHours(currentHours)
  );
  useEffect(() => {
    const reformattedHours = formatHours(parseInputNumber(currentHoursString));
    const formattedCurrent = formatHours(currentHours);
    if (reformattedHours !== formattedCurrent) {
      setHoursString(formattedCurrent);
    }
  }, [currentHours, currentHoursString]);

  const onValueChange = (event: ChangeEvent): void => {
    const rawValue = (event.target as HTMLInputElement).value || "0";
    const parsedValue = parseInputNumber(rawValue);
    const currentValue = Math.min(parsedValue, choice.maxValue);
    const stringValue =
      parsedValue === currentValue ? rawValue : formatEuro(currentValue);
    if (updateChoice) {
      updateChoice({ ...choice, currentValue, stringValue });
    }
  };

  const onValueBlur = (_event: ChangeEvent): void => {
    const stringValue = formatEuro(choice.currentValue);
    if (updateChoice && stringValue !== choice.stringValue) {
      updateChoice({ ...choice, stringValue });
    }
  };

  const onSliderChange = (event: ChangeEvent): void => {
    const rawValue = (event.target as HTMLInputElement).value || "0";
    const currentValue = Math.min(choice.maxValue, Number(rawValue));
    const stringValue = formatEuro(currentValue);
    if (updateChoice) {
      updateChoice({ ...choice, currentValue, stringValue });
    }
  };

  const onHoursChange = (event: ChangeEvent): void => {
    if (!choice.hourValue) return;
    const rawHours = (event.target as HTMLInputElement).value || "0";
    setHoursString(rawHours);
    const hours = parseInputNumber(rawHours);
    const value = hours * choice.hourValue;
    const currentValue = Math.min(choice.maxValue, value);
    const stringValue = formatEuro(currentValue);
    if (updateChoice) {
      updateChoice({ ...choice, currentValue, stringValue });
    }
  };

  const onHoursBlur = (_event: ChangeEvent): void => {
    setHoursString(formatHours(currentHours));
  };

  const classes = useStyles();

  return (
    <ContentCard
      title={choice.definition.label}
      info={
        choice.definition.help && (
          <ReactMarkdown components={{ a: linkRenderer }}>
            {choice.definition.help}
          </ReactMarkdown>
        )
      }
    >
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={6}>
          <InputLabel>
            {t("Alacarte:Available", {
              interval:
                choice.definition.interval &&
                ` / ${t(
                  `Alacarte:Interval_abbreviation_${choice.definition.interval}`
                )}`,
            })}
          </InputLabel>

          <Input
            fullWidth
            disableUnderline
            disabled
            value={formatEuro(choice.maxValue)}
            inputProps={{ step: 0.01, style: { textAlign: "right" } }}
            startAdornment={
              <InputAdornment position="start">
                {t("Alacarte:Amount")}
              </InputAdornment>
            }
          />
          <br />
          {maxHours !== undefined &&
            choice.dataDefinition.unitAfter &&
            choice.dataDefinition.unitAfter.indexOf("uur") >= 0 && (
              <Input
                fullWidth
                disableUnderline
                disabled={!choice.definition.userInput}
                onChange={(event) => {
                  return updateChoice?.({
                    ...choice,
                    maxValue:
                      parseInputNumber(event.target.value) * choice.hourValue,
                  });
                }}
                classes={
                  choice.definition.userInput
                    ? {
                        root: classes.choiceInputRoot,
                        input: classes.choiceInput,
                      }
                    : undefined
                }
                value={formatHours(maxHours)}
                inputProps={{ step: 0.01, style: { textAlign: "right" } }}
                startAdornment={
                  <InputAdornment position="start">
                    {t("Alacarte:Hours")}
                  </InputAdornment>
                }
              />
            )}
        </Grid>
        <Grid item xs={6} className={classes.chosen}>
          <InputLabel>{t("Alacarte:Chosen")}</InputLabel>
          <Input
            fullWidth
            disableUnderline
            value={choice.stringValue || formatEuro(choice.currentValue)}
            onChange={onValueChange}
            onBlur={onValueBlur}
            classes={{
              root: classes.choiceInputRoot,
              input: classes.choiceInput,
            }}
            inputProps={{
              step: 0.01,
              min: 0,
              max: choice.maxValue + 11,
            }}
            startAdornment={
              <InputAdornment position="start">
                {t("Alacarte:Amount")}
              </InputAdornment>
            }
          />
          <br />
          {currentHours !== undefined &&
            choice.dataDefinition.unitAfter &&
            choice.dataDefinition.unitAfter.indexOf("uur") >= 0 && (
              <Input
                fullWidth
                disableUnderline
                value={currentHoursString}
                onChange={onHoursChange}
                onBlur={onHoursBlur}
                classes={{
                  root: classes.choiceInputRoot,
                  input: classes.choiceInput,
                }}
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: maxHours,
                }}
                startAdornment={
                  <InputAdornment position="start">
                    {t("Alacarte:Hours")}
                  </InputAdornment>
                }
              />
            )}
        </Grid>
      </Grid>
      {choice.repeating &&
        updateChoice &&
        choice.dataDefinition.choiceRole === "destination" && (
          <Box marginTop={2}>
            <ChoiceRepetition
              choice={choice}
              disabled={!updateChoice || !choice.currentValue}
              disableDirectly={disableDirectly}
              onConfirm={(startMonth, endMonth) =>
                updateChoice({ ...choice, startMonth, endMonth })
              }
            />
          </Box>
        )}
      <Grid
        style={{
          background: "#f3f3f3",
          margin: -16,
          marginTop: 16,
          padding: 4,
          width: "calc(100% + 32px)",
        }}
        container
        wrap="nowrap"
      >
        <Grid item xs style={{ display: "flex", alignItems: "center" }}>
          <input
            onChange={onSliderChange}
            value={choice.currentValue}
            min={0}
            max={maxHours ? maxHours * choice.hourValue : choice.maxValue}
            type="range"
            step={0.01}
            className={classes.range}
          />
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={(): void => setAutoValue(choice)}
          >
            <BuildIcon />
          </IconButton>
        </Grid>
      </Grid>
      {/* </FormGroup> */}
    </ContentCard>
  );
};

export default memo(Choice);
