import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { organizationCell } from "../../../business/redux/saga/organization";
import { useTranslationStrict } from "../../../globalization/i18n";

interface NmbrsPasswordResetCardProps {
  nmbrsDomain: string;
}

const NmbrsPasswordResetCard = ({
  nmbrsDomain,
}: NmbrsPasswordResetCardProps) => {
  const [t] = useTranslationStrict();
  const history = useHistory();

  const applicationName = useSelector(organizationCell.select).value
    ?.applicationName;

  const handleCancel = useCallback(() => {
    history.push("/login");
  }, [history]);

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleCancel}>
            <ClearIcon />
          </IconButton>
        }
        title={t("User:NmbrsPasswordResetTitle")}
      />
      <CardContent>
        <Typography paragraph>
          {t("User:NmbrsPasswordResetNmbrsLogin")}
        </Typography>
        <Typography paragraph>
          <Trans
            t={t}
            i18nKey="User:NmbrsPasswordResetNmbrsLink"
            components={[
              <Link href={`https://${nmbrsDomain}.nmbrs.nl/`} target="_blank">
                Nmbrs
              </Link>,
            ]}
          />
        </Typography>
        <Typography paragraph>
          {t("User:NmbrsPasswordResetNmbrsApplication", { applicationName })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NmbrsPasswordResetCard;
