import { IconButton, Tooltip } from "@material-ui/core";
import { SwapVerticalCircle as SwapVerticalCircleIcon } from "@material-ui/icons";
import React, { useCallback, useContext } from "react";

import { useTranslationStrict } from "../../globalization/i18n";
import { NavigationMenuContext, routeAllOpen } from "./navigationMenu";

const AccordionMenuExpandAllButton = () => {
  const [t] = useTranslationStrict();
  const { routeOpen, setRouteOpen } = useContext(NavigationMenuContext);

  const handleClick = useCallback(() => {
    setRouteOpen(routeOpen === routeAllOpen ? "" : routeAllOpen);
  }, [routeOpen, setRouteOpen]);

  return (
    <Tooltip
      title={
        routeOpen === routeAllOpen
          ? t("Navigation:Alles dichtklappen")
          : t("Navigation:Alles openklappen")
      }
      placement="right"
    >
      <IconButton onClick={handleClick}>
        <SwapVerticalCircleIcon color="action" />
      </IconButton>
    </Tooltip>
  );
};

export default AccordionMenuExpandAllButton;
