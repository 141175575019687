import { createActor, createForm, createSingle } from "async-lifecycle-saga";
import { Success } from "async-lifecycle-saga/dist/models";

import { asyncGet, asyncPost } from "../../../../api";
import { ChoiceSetModel, ChoiceUploadModel } from "../../../../models";
import { StoreModel } from "../../models";
import { personalChoiceUploadPoster } from "./api";
import { UpsertPayload } from "./models";

export const personalChoicesByLoginNameCell = createForm<
  string,
  ChoiceSetModel,
  StoreModel
>({
  path: ["personal", "choices", "byLoginName"],
  api: (loginName: string) =>
    asyncGet<ChoiceSetModel>(
      `/api/choice/employee/${encodeURIComponent(loginName)}`
    ).then((response) => {
      const body = (response as Success<ChoiceSetModel>).body;
      return body
        ? { ...response, body: { ...body, endDate: new Date(body.endDate) } }
        : response;
    }),
});

export const personalChoicesEntityCell = createSingle<
  ChoiceSetModel,
  StoreModel
>({
  path: ["personal", "choices", "entity"],
  require: {
    api: () =>
      asyncGet<ChoiceSetModel>("/api/choice/employee").then((response) => {
        const body = (response as Success<ChoiceSetModel>).body;
        return body
          ? { ...response, body: { ...body, endDate: new Date(body.endDate) } }
          : response;
      }),
  },
});

export const personalChoicesUploadCell = createActor<
  Omit<ChoiceUploadModel, "file"> & { file: File },
  undefined
>({
  path: ["personal", "choices", "upload"],
  api: ({ dataDefinitionId, file }) =>
    personalChoiceUploadPoster(dataDefinitionId, file),
});

export const personalChoicesUpsertCell = createActor<UpsertPayload, undefined>({
  path: ["personal", "choices", "upsert"],
  api: ({
    choices,
    navigationNodeId,
    optimal,
    submitRequest,
    totalGiftValue,
  }) => {
    const benefits =
      optimal || totalGiftValue
        ? {
            forNetto: optimal?.source().salary() ?? 0,
            forBruto: 0,
            forFinal: optimal?.salaryFinalCharge() ?? 0,
            forFree: 0,
            employeeBenefit: optimal?.employeeBenefit() ?? 0,
            employerBenefit: optimal?.employerBenefit() ?? 0,
          }
        : {};
    return asyncPost(
      `/api/choice/employee/${navigationNodeId}?submitRequest=${submitRequest}`,
      {
        values: choices,
        ...benefits,
      }
    );
  },
  invalidate: [personalChoicesEntityCell.events],
});
