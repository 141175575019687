import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import React, {
  Component,
  ErrorInfo,
  ReactNode,
  memo,
  useCallback,
} from "react";
import { connect } from "react-redux";

import { UserModel } from "./business/models";
import { StoreModel } from "./business/redux/saga/models";
import { reflexoHistory } from "./business/router";
import { useTranslationStrict } from "./globalization/i18n";

// Application Insights
const reactPlugin = new ReactPlugin();

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  instrumentationKey?: string;
  user?: UserModel;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const ErrorDialog = memo(() => {
  const [t] = useTranslationStrict();
  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">
        {t("Common:ErrorDialogTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>{t("Common:ErrorDialogText")}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRefresh} color="primary" autoFocus>
          {t("Common:ErrorDialogButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private readonly appInsights?: ApplicationInsights;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    const { instrumentationKey } = this.props;

    if (instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: reflexoHistory },
          },
          isBrowserLinkTrackingEnabled: true,
          enableAutoRouteTracking: true,
          isCookieUseDisabled: true,
          disableFetchTracking: false,
        },
      });

      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((envelope) => {
        if (envelope && envelope.tags) {
          const { user } = this.props;
          const pathname = window.location.pathname.replace(/^\/+/, "");

          /* eslint-disable no-param-reassign,dot-notation */
          if (user) {
            envelope.tags["ai.user.authUserId"] = user.id;
            envelope.tags["ai.user.accountId"] = user.id;
          }

          envelope.tags["ai.cloud.role"] = "Reflexo React Client";
          envelope.tags[
            "ai.operation.name"
          ] = `${window.document.title} - ${pathname}`;
          /* eslint-enable */
        }
      });
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true });
    if (this.appInsights) {
      this.appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    }
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorDialog />;
    }

    return children || null;
  }
}

const ErrorBoundaryWithTracking = withAITracking(reactPlugin, ErrorBoundary);

export default connect(({ session: { user } }: StoreModel) => ({
  user,
}))(ErrorBoundaryWithTracking);
