import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { adminEmployersListSpecialCell } from "../../../business/redux/saga/admin/employers/cells";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";

interface EmployerDeleteButtonProps {
  id: number;
}

const EmployerDeleteButton = (props: EmployerDeleteButtonProps) => {
  const { id } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const { confirmAction } = useConfirm();

  const handleClick = () => {
    confirmAction(
      adminEmployersListSpecialCell.delete(id, {
        onSuccess: () => {
          dispatch({ type: adminEmployersListSpecialCell.events.invalidate });
          dispatch(adminEmployersListSpecialCell.require());
        },
      })
    );
  };

  return <Button onClick={handleClick}>{t("Common:Delete")}</Button>;
};

export default EmployerDeleteButton;
