import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { UserModel, UserStatus, emptyUser } from "../../../business/models";
import { adminUsersAddCell } from "../../../business/redux/saga/admin/users/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { setPartialStateValue } from "../../../utils/ValueConverters";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";
import { EmployeeTypeInput } from "./";

interface Props {
  showPlatformAdmin: boolean;
  showApiAdmin: boolean;
}

const UsersEditorTableRowNewUser = memo(
  ({ showPlatformAdmin, showApiAdmin }: Props) => {
    const dispatch = useDispatch();
    const [t] = useTranslationStrict();
    const { enqueueSnackbar } = useSnackbar();
    const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

    const [newUser, setNewUser] = useState<UserModel>({ ...emptyUser });

    const clearNewUser = () => {
      setNewUser({ ...emptyUser });
    };

    const handleSaveClick = useCallback(() => {
      dispatch({
        type: adminUsersAddCell.events.require,
        payload: { ...newUser, id: 0, status: UserStatus.new },
        onFail: showErrorMessageFromDetails(t("Admin:AddUserError")),
        onSuccess: (successAdd: Success<UserModel>) => {
          enqueueSnackbar(
            t("Admin:AddUserSuccess") + ` (${successAdd.body.loginName})`,
            { variant: "success" }
          );
          clearNewUser();
        },
      });
    }, [dispatch, enqueueSnackbar, newUser, showErrorMessageFromDetails, t]);

    return (
      <TableRow>
        <TableCell>
          <TextField
            onChange={setPartialStateValue(setNewUser, newUser, "loginName")}
            value={newUser.loginName}
          />
        </TableCell>
        <TableCell>
          <TextField
            onChange={setPartialStateValue(setNewUser, newUser, "fullName")}
            value={newUser.fullName}
          />
        </TableCell>
        <TableCell>
          <TextField
            onChange={setPartialStateValue(setNewUser, newUser, "email")}
            value={newUser.email}
          />
        </TableCell>
        <TableCell>
          <EmployeeTypeInput
            onEdit={(u) => setNewUser(u)}
            userEdited={newUser}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={newUser.isEmployee}
            onChange={(_, isEmployee) => {
              setNewUser({ ...newUser, isEmployee });
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={newUser.isOrganizationAdmin}
            onChange={(_, isOrganizationAdmin) => {
              setNewUser({ ...newUser, isOrganizationAdmin });
            }}
          />
        </TableCell>
        {showPlatformAdmin && (
          <TableCell>
            <Checkbox
              checked={newUser.isDatacockpit}
              onChange={(_, isDatacockpit) => {
                setNewUser({ ...newUser, isDatacockpit });
              }}
            />
          </TableCell>
        )}
        {showPlatformAdmin && (
          <TableCell>
            <Checkbox
              checked={newUser.isPlatformAdmin}
              onChange={(_, isPlatformAdmin) => {
                setNewUser({ ...newUser, isPlatformAdmin });
              }}
            />
          </TableCell>
        )}
        {showApiAdmin && (
          <TableCell>
            <Checkbox
              checked={newUser.isApiAdmin}
              onChange={(_, isApiAdmin) => {
                setNewUser({ ...newUser, isApiAdmin });
              }}
            />
          </TableCell>
        )}
        {showApiAdmin && (
          <TableCell>
            <Checkbox
              checked={newUser.hasApiAccess}
              onChange={(_, hasApiAccess) => {
                setNewUser({ ...newUser, hasApiAccess });
              }}
            />
          </TableCell>
        )}
        <TableCell />
        <TableCell>
          <IconButton onClick={handleSaveClick} title={t("Common:Add")}>
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
);

export default UsersEditorTableRowNewUser;
