import { Details, Success } from "async-lifecycle-saga/dist/models";
import { useDispatch } from "react-redux";

import { AnnouncementModel } from "../../../models";
import {
  announcementsAddCell,
  announcementsListPagedCell,
  announcementsRetireCell,
} from "./cells";
import { AnnouncementResponse } from "./models";

export interface AnnouncementsHook {
  add: (
    announcement: AnnouncementModel & { notifyUsers: boolean },
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ) => void;

  getPaged: (
    pageNumber: number,
    pageSize?: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ) => void;

  retire: (
    announcementId: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useAnnouncements = (): AnnouncementsHook => {
  const dispatch = useDispatch();

  const add = (
    {
      notifyUsers,
      ...announcement
    }: AnnouncementModel & { notifyUsers: boolean },
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ): void => {
    dispatch(
      announcementsAddCell.require(
        { ...announcement, notifyUsers },
        {
          onFail: failCallback,
          onSuccess: successCallback,
        }
      )
    );
  };

  const getPaged = (
    pageNumber: number,
    pageSize?: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ): void => {
    dispatch(
      announcementsListPagedCell.require(
        { pageNumber, pageSize },
        {
          onFail: failCallback,
          onSuccess: successCallback,
        }
      )
    );
  };

  const retire = (
    announcementId: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AnnouncementResponse>) => void
  ): void => {
    dispatch(
      announcementsRetireCell.require(announcementId, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  return {
    add,
    getPaged,
    retire,
  };
};
