import { AsyncResponse } from "async-lifecycle-saga";

import { asyncUpload } from "../../../api";

export const asyncAddFile = (
  id: number,
  file: File
): Promise<AsyncResponse<{}>> => {
  const formData = new FormData();
  formData.append("file", file);
  return asyncUpload(`/api/announcement/upload/${id}`, formData);
};
