import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Delete, KeyboardArrowDown } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";

import { TotlResponse } from "../../business/api";
import { AnnouncementModel } from "../../business/models";
import { dmsDocumentDownloadCell } from "../../business/redux/saga/dms/cells";
import { useTranslationStrict } from "../../globalization/i18n";
import { renderDateTime } from "../../utils/DateUtils";
import DocumentListItem from "../cms/DocumentListItem";
import BlowupImage from "../ux/BlowupImage";
import useStyles from "./Announcement.styles";

interface ImageProps {
  announcement: AnnouncementModel;
}

const AnnouncementMedia = memo(
  ({
    announcement: { id, documentName, title, contentType = "" },
  }: ImageProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [totl, setTotl] = useState<TotlResponse | undefined>(undefined);
    useEffect(() => {
      dispatch(
        dmsDocumentDownloadCell.require(
          { id: id!, path: "announcement/download" },
          {
            onSuccess: ({ body }: Success<TotlResponse>) => setTotl(body),
          }
        )
      );
    }, [id, dispatch, setTotl]);

    if (!totl || !totl.link) {
      return null;
    }

    switch (0) {
      case contentType.indexOf("video/"):
        return (
          <video controls className={classes.image}>
            <source src={totl.link} type={contentType} />
          </video>
        );
      case contentType.indexOf("image/"):
        return (
          <BlowupImage alt={title} src={totl.link} className={classes.image} />
        );
      default:
        return (
          <List>
            <DocumentListItem
              document={{ documentName: documentName!, contentType }}
              enableEdit={false}
              onClick={() => {
                window.open(totl.link, "_blank");
              }}
            />
          </List>
        );
    }
  }
);

interface Props {
  announcement: AnnouncementModel;
  open: boolean;
  onClick: (id: number) => void;
  onRetire?: (announcement: AnnouncementModel) => void;
}

const Announcement = memo(
  ({ announcement, open, onClick, onRetire }: Props): JSX.Element => {
    const classes = useStyles();
    const [t, { language }] = useTranslationStrict();
    const text = useRef<HTMLDivElement>(null);
    const handleClick = useCallback((): void => {
      if (announcement.id) {
        onClick(announcement.id);
      }
    }, [announcement.id, onClick]);
    const handleRetire = useCallback((): void => {
      if (onRetire) {
        onRetire(announcement);
      }
    }, [announcement, onRetire]);

    const width = text.current
      ? Math.max(
          200,
          text.current.parentElement!.parentElement!.parentElement!
            .offsetWidth - 48
        )
      : 560;

    const renderDate = (date: Date): string => renderDateTime(date, language);
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText
            primary={<div ref={text}>{announcement.title}</div>}
            secondary={renderDate(announcement.validFrom)}
          />
          <ListItemIcon>
            <KeyboardArrowDown />
          </ListItemIcon>
          {onRetire && (
            <ListItemSecondaryAction>
              <Tooltip title={t("Common:Delete") as string}>
                <IconButton onClick={handleRetire}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Paper className={classes.content}>
            <ReactMarkdown>{announcement.text}</ReactMarkdown>
            {announcement.documentName && (
              <AnnouncementMedia announcement={announcement} />
            )}
            {announcement.link && (
              <iframe
                title={announcement.title}
                width={width}
                height={(315 * width) / 560}
                src={`https://www.youtube-nocookie.com/embed/${announcement.link}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </Paper>
        </Collapse>
      </>
    );
  }
);

export default Announcement;
