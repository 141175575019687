import { createActor } from "async-lifecycle-saga";

import { asyncDelete } from "../../../../api";
import { personalDownloadsCell } from "../cells";

export const personalDownloadRemoveCell = createActor<number, undefined>({
  path: ["personal", "download", "remove"],
  api: (id) => asyncDelete(`/api/download/${id}`),
  invalidate: [personalDownloadsCell.events],
});
