import { Grid } from "@material-ui/core";
import React from "react";

import { MutationsOverviewAuthorizationButton } from "./index";

interface Props {
  effectiveDate: Date;
}

const MutationsOverviewActions = (props: Props) => {
  const { effectiveDate } = props;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <MutationsOverviewAuthorizationButton
          effectiveDate={effectiveDate}
          mode="Approve"
        />
      </Grid>
      <Grid item>
        <MutationsOverviewAuthorizationButton
          effectiveDate={effectiveDate}
          mode="Reject"
        />
      </Grid>
    </Grid>
  );
};

export default MutationsOverviewActions;
