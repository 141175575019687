import { Grid, Typography } from "@material-ui/core";

const WlzTextFieldDisplay = (props: { name: string; value: string }) => {
  const { name, value } = props;

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item style={{ whiteSpace: "nowrap" }}>
        <Typography variant="caption">{name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default WlzTextFieldDisplay;
