import { Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { important } from "../styling/Common";

interface CssColor {
  color: string;
  backgroundColor: string;
}
const importantColor = ({
  color,
  backgroundColor,
}: CssColor): CSSProperties => ({
  color: important(color),
  backgroundColor: important(backgroundColor),
});

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    variantError: importantColor({
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    }),
    variantInfo: importantColor({
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    }),
    variantSuccess: importantColor({
      color: theme.palette.getContrastText(green["600"]),
      backgroundColor: green["600"],
    }),
  })
);

export default useStyles;
