import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

import { StoreModel } from "../../business/redux/saga/models";

interface AuthenticatedProps {
  denyBehavior: "RedirectToLogin" | "ShowEmpty";
}

const Authenticated = (props: PropsWithChildren<AuthenticatedProps>) => {
  const { denyBehavior, children } = props;

  const jwt = useSelector((store: StoreModel) => store.session.jwt);
  const hasRefreshTokenInStore = jwt !== undefined;

  switch (true) {
    case hasRefreshTokenInStore:
      return <>{children}</>;

    case !hasRefreshTokenInStore && denyBehavior === "RedirectToLogin":
      return <Redirect to="/login" />;

    default:
      // Either forceLogout or denyBehavior === "ShowEmpty"
      return <></>;
  }
};

export default Authenticated;
