import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    currencyUnit: {
      borderBottom: "none",
      textAlign: "right",
      width: "40%",
    },
    currencyAmount: {
      borderBottom: "none",
      textAlign: "right",
      width: "60%",
    },
    gridBreadCrumbs: {
      gridArea: "1 / 1 / span 1 / span 2",
    },
    gridWideContent: {
      gridArea: "auto / auto / span 1 / span 3",
    },
    evenColumn: {
      verticalAlign: "top",
      width: "30%",
      "& > a": {
        color: "white !important",
      },
    },
    rightColumn: {
      width: "70%",
      verticalAlign: "top",
      textAlign: "right",
      "& > dl": {
        textAlign: "left",
        display: "inline-block",
      },
    },
    right: {
      textAlign: "right",
    },
    total: {
      fontWeight: "bolder",
      "& td": {
        fontSize: "0.8125rem",
      },
    },
    expanded: {
      backgroundColor: theme.palette.primary.main,
      "& > td": {
        color: "white",
      },
    },
    chosenList: {
      position: "relative",
      "& > dt": {
        display: "inline-block",
        width: "9em",
      },
      "& > dd": {
        display: "inline-block",
        marginLeft: "1ex",
        width: "calc(100% - 10em)",
      },
      "& a": {
        color: "white !important",
      },
    },
    card: {
      "&:nth-child(n + 1)": {
        marginTop: 16,
      },
    },
    employerSelect: {
      maxWidth: 500,
      minWidth: 300,
    },
    menuIcon: { color: theme.palette.common.black },
  })
);

export default useStyles;
