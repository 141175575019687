import { AsyncResponse } from "async-lifecycle-saga";

import { asyncUpload } from "../../../../api";

export const adminUploadAddFormPoster = (
  employeeId: number | undefined,
  uploadTypeId: number,
  effectiveDate: Date,
  file: File
): Promise<AsyncResponse<{}>> => {
  const formData = new FormData();

  formData.append("uploadTypeId", uploadTypeId.toString());
  formData.append("effectiveDate", effectiveDate.toISOString());
  formData.append("file", file);

  return asyncUpload(`/api/upload/upload/${employeeId || ""}`, formData);
};
