import { ComponentType } from "react";
import { GetProps, Matching, connect } from "react-redux";
import { createSelector } from "reselect";

import {
  OrganizationModel,
  SessionModel,
  emptyOrganization,
} from "../business/models";
import { AdminUploadStoreModel } from "../business/redux/saga/admin/upload/models";
import { StoreModel } from "../business/redux/saga/models";
import { BasePageData } from "../components/pages/BasePage";

export interface ConnectData {
  basePage: BasePageData;
  session: SessionModel;
  upload?: AdminUploadStoreModel;
}

const basePageSelector = createSelector(
  [
    (state: StoreModel): OrganizationModel =>
      state.organization.get.value || emptyOrganization,
    (state: StoreModel): SessionModel => state.session,
  ],
  (organization: OrganizationModel, session: SessionModel): BasePageData => ({
    ribbon: {
      applicationName: organization.applicationName,
      logoTitle: organization.name,
      logoImageUrl: organization.logoUrl,
      profileInfo: {
        profile: session ? session.user : undefined,
        selectedRole: session.appRole,
      },
    },
    footer: {
      copyrightHolder: window.reflexoOptions?.copyrightHolder || "Reflexo",
      copyrightYears: new Date().getFullYear(),
    },
  })
);

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const reduxConnectApp = <
  C extends ComponentType<Matching<ConnectData, GetProps<C>>>
>(
  component: C
) =>
  connect<ConnectData, {}, {}, StoreModel>(
    (state: StoreModel): ConnectData => {
      const { session } = state;
      return {
        session,
        basePage: basePageSelector(state),
      };
    },
    () => ({})
  )(component);

export default reduxConnectApp;
