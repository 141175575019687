import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { memo } from "react";
import ReactMarkdown from "react-markdown";

import { UploadStatusModel } from "../../../business/admin/upload/models";

interface ReportDialogProps {
  upload: UploadStatusModel;
  onClose(): void;
}
const ReportDialog = memo(
  ({ upload, onClose }: ReportDialogProps): JSX.Element => (
    <Dialog open onClose={onClose}>
      <DialogTitle>{upload.documentName}</DialogTitle>
      <DialogContent>
        <ReactMarkdown>{upload.checkOutput ?? ""}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  )
);

export default ReportDialog;
