import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

import { MutationsOverviewDataRow } from "../../../business/datacockpit/mutations/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { renderDate } from "../../../utils/DateUtils";
import Loading from "../../ux/Loading";
import { MutationsOverviewActions } from "./";

interface Props {
  rows: MutationsOverviewDataRow[];
}

const MutationsOverviewTable = (props: Props) => {
  const { rows } = props;
  const [t] = useTranslationStrict();

  return (
    <Card>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="effectiveDate">
                {t("Datacockpit:Peildatum")}
              </TableCell>
              <TableCell key="employeeCount">
                {t("Datacockpit:Medewerkers")}
              </TableCell>
              <TableCell key="mutationsCount">
                {t("Datacockpit:Wijzigingen")}
              </TableCell>
              <TableCell key="actions" style={{ width: "16em" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r) => (
              <TableRow key={r.effectiveDate.toISOString()}>
                <TableCell key="effectiveDate">
                  {renderDate(r.effectiveDate)}
                </TableCell>
                <TableCell key="employeeCount">{r.employeeCount}</TableCell>
                <TableCell key="mutationsCount">{r.mutationsCount}</TableCell>
                <TableCell key="actions">
                  {r.isProcessing ? (
                    <Loading />
                  ) : (
                    <MutationsOverviewActions effectiveDate={r.effectiveDate} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default MutationsOverviewTable;
