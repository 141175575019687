import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, ChevronRight, Person } from "@material-ui/icons";
import classNames from "classnames";
import React, { MouseEventHandler, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Role, UserModel } from "../../business/models";
import { authenticationLogOutCell } from "../../business/redux/saga/authentication/cells";
import { microsoft365LogoutUriCell } from "../../business/redux/saga/authentication/microsoft365/cells";
import { organizationCell } from "../../business/redux/saga/organization";
import { sessionRoleUpdateCell } from "../../business/redux/saga/session/cells";
import { useTranslationStrict } from "../../globalization/i18n";
import { clearTokens } from "../../utils/SecurityUtils";
import LanguageSelector from "../ux/LanguageSelector";
import useStyles from "./ProfileInfo.styles";

export interface ProfileInfoData {
  profile?: UserModel;
  selectedRole: Role;
}

export type ProfileInfoProps = ProfileInfoData;

const ProfileInfo = (props: ProfileInfoProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const { profile, selectedRole } = props;

  const [selectRolePopoverAnchorEl, setSelectRolePopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const selectRolePopoverOpen = Boolean(selectRolePopoverAnchorEl);
  const useMicrosoft365 = useSelector(organizationCell.select).value!
    .microsoft365Active;

  const handleLogOut = () => {
    clearTokens();
    dispatch({ type: authenticationLogOutCell.events.require });
    if (useMicrosoft365) {
      dispatch(
        microsoft365LogoutUriCell.require({
          onSuccess: ({ body: { uri } }) => {
            window.location.href = uri;
          },
        })
      );
    }
  };

  const handleSelectRoleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    setSelectRolePopoverAnchorEl(event.currentTarget);
  };

  const handleSelectRolePopoverClose = (): void => {
    setSelectRolePopoverAnchorEl(null);
  };

  const handleSelectedRoleChanged =
    (role: Role): MouseEventHandler =>
    () => {
      dispatch(sessionRoleUpdateCell.require(role));
      setSelectRolePopoverAnchorEl(null);
    };

  if (!profile) {
    return <div className={classes.root} />;
  }

  const applicationRoles: Role[] = [];
  if (profile.isEmployee) {
    applicationRoles.push("employee");
  }
  if (profile.isOrganizationAdmin) {
    applicationRoles.push("organizationAdmin");
  }
  if (profile.isDatacockpit) {
    applicationRoles.push("datacockpit");
  }
  if (profile.isPlatformAdmin) {
    applicationRoles.push("platformAdmin");
  }

  const hasPopover = profile && applicationRoles.length > 1;
  const adminOrSuper = selectedRole && selectedRole !== "employee";

  return (
    <div className={classes.root}>
      <Button
        color={adminOrSuper ? "primary" : undefined}
        variant={adminOrSuper ? "contained" : undefined}
        className={classNames({ [classes.buttonEmployee]: !adminOrSuper })}
        disabled={!hasPopover}
        onClick={handleSelectRoleClick}
      >
        <Typography
          variant="inherit"
          className={classNames(
            { [classes.profileLoginNameLabelEmployee]: !adminOrSuper },
            classes.profileLoginNameLabel
          )}
        >
          {t("Common:LoggedOnAs")}{" "}
        </Typography>
        <Typography variant="inherit" className={classes.profileLoginName}>
          {adminOrSuper ? t(`Common:${selectedRole}`) : profile.loginName}
        </Typography>
        {hasPopover && <ArrowDropDown />}
      </Button>
      {hasPopover && (
        <Popover
          anchorEl={selectRolePopoverAnchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          open={selectRolePopoverOpen}
          onClose={handleSelectRolePopoverClose}
        >
          <Paper classes={{ root: classes.paper }}>
            <FormControl>
              <FormLabel>{t("Common:ApplicationRole")}</FormLabel>
              <List>
                {applicationRoles.map(
                  (role: Role): React.ReactElement => (
                    <ListItem
                      key={role}
                      aria-selected={selectedRole === role}
                      button
                      defaultValue={role}
                      onClick={handleSelectedRoleChanged(role)}
                      selected={selectedRole === role}
                    >
                      {t(`Common:${role}`)}
                    </ListItem>
                  )
                )}
              </List>
            </FormControl>
          </Paper>
        </Popover>
      )}
      <Link to="/profile" style={{ textDecoration: "none" }}>
        <IconButton className={classes.button}>
          <Typography variant="button" className={classes.myDataLabel}>
            {t("Common:MyData")}
          </Typography>{" "}
          <Person />
        </IconButton>
      </Link>
      <IconButton className={classes.button} onClick={handleLogOut}>
        <Typography variant="button" className={classes.logoutLabel}>
          {t("Common:LogOut")}
        </Typography>{" "}
        <ChevronRight />
      </IconButton>
      <LanguageSelector />
    </div>
  );
};

export default memo(ProfileInfo);
