import { Grid } from "@material-ui/core";

import { WlzPerson } from "../../../../business/personal/wlz/models";
import { WlzAdviceCaseSummarySubjectView } from "./";

interface Props {
  subjects: [WlzPerson] | [WlzPerson, WlzPerson];
}

const WlzAdviceCaseSummarySubjectsTable = (props: Props) => {
  const { subjects } = props;
  const couple = subjects.length === 2;

  return (
    <>
      <Grid item>
        <WlzAdviceCaseSummarySubjectView person={subjects[0]} index={0} />
      </Grid>
      {couple && (
        <Grid item>
          <WlzAdviceCaseSummarySubjectView person={subjects[1]} index={1} />
        </Grid>
      )}
    </>
  );
};

export default WlzAdviceCaseSummarySubjectsTable;
