import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { Lightbulb } from "@mui/icons-material";
import { Form, Formik, FormikActions } from "formik";
import { Ref } from "react";
import * as Yup from "yup";

import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";
import WlzAdviceCaseSubjectFormSection from "./WlzAdviceCaseSubjectFormSection";

interface Props {
  formRef: Ref<Formik<WlzAdviceCase>>;
  initialValues: WlzAdviceCase;
  validationSchema: Yup.ObjectSchema<WlzAdviceCase>;

  onSubmit(
    values: WlzAdviceCase,
    formikActions: FormikActions<WlzAdviceCase>
  ): void;
}

const WlzAdviceCaseSubjectsForm = (props: Props) => {
  const { formRef, initialValues, onSubmit, validationSchema } = props;
  const [t] = useTranslationStrict();

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={(formikProps) => (
        <Form>
          <Card>
            <CardHeader title={t("Wlz:Personen")} />
            <CardContent>
              {formikProps.values.yearCalculation && (
                <Typography>
                  <span style={{ verticalAlign: "middle" }}>
                    <Lightbulb />
                  </span>
                  <span style={{ verticalAlign: "middle" }}>
                    {" "}
                    {t("Wlz:Vul bedragen in voor het gehele jaar")}{" "}
                    {formikProps.values.yearCalculation.getFullYear() - 2}
                  </span>
                </Typography>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <WlzAdviceCaseSubjectFormSection index={0} {...formikProps} />
                {formikProps.values.couple && (
                  <WlzAdviceCaseSubjectFormSection index={1} {...formikProps} />
                )}
              </Box>
            </CardContent>
          </Card>
        </Form>
      )}
    />
  );
};

export default WlzAdviceCaseSubjectsForm;
