import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { adminChoiceBenefitsCell } from "../../../business/redux/saga/admin/choices/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { renderDate } from "../../../utils/DateUtils";

interface Props {
  value?: number;

  onChange(value?: number): void;
}

const ChoicePeriodSelect = (props: Props) => {
  const { onChange, value } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();

  const periods =
    useSelector(({ admin }: StoreModel) => admin.choice.periods.value) || [];

  let handleChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      if (event.target.value === "[All]") {
        dispatch({ type: adminChoiceBenefitsCell.events.clear });
        dispatch(adminChoiceBenefitsCell.require(undefined));
        onChange(undefined);
        return;
      }
      const value = Number(event.target.value);
      dispatch({ type: adminChoiceBenefitsCell.events.clear });
      dispatch(adminChoiceBenefitsCell.require(value));
      onChange(value);
    },
    [dispatch, onChange]
  );

  useEffect(() => {
    dispatch(adminChoiceBenefitsCell.require(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <FormControl>
      <Select
        onChange={handleChange}
        value={value || "[All]"}
        style={{ width: 250, maxWidth: "80vw" }}
      >
        <MenuItem key="[All]" value="[All]">
          <i>{t("Admin:Alle deelnemers")}</i>
        </MenuItem>
        {periods.map(({ id, startDateTime }) => (
          <MenuItem key={id} value={id}>
            {renderDate(startDateTime)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{t("Admin:ChoicePeriodSelect")}</FormHelperText>
    </FormControl>
  );
};

export default ChoicePeriodSelect;
