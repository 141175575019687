import { Theme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

import { tabletScreen } from "../../../styling/Common";

const useStyles = makeStyles((theme: Theme) => ({
  ...tabletScreen({
    gridMainContent: {
      gridColumn: 2,
      gridRow: 2,
    },
  }),

  warning: {
    backgroundColor: red[100],
    padding: theme.spacing() * 2,
  },
}));

export default useStyles;
