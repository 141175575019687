import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import {
  DragEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { adminNavigationNodePruneCell } from "../../business/redux/saga/admin/navigation/cells";
import { StoreModel } from "../../business/redux/saga/models";
import { isAdminRole } from "../../utils/SecurityUtils";
import AdminNavigationContext from "../admin/navigation/AdminNavigationContext";
import { useSnackbarErrorMessage } from "../ux";
import {
  AccordionMenuContent,
  AccordionMenuSummary,
  NavigationMenuContext,
  routeAllOpen,
} from "./";
import AccordionDropArea from "./AccordionDropArea";
import useStyles from "./AccordionMenu.styles";

const navigationSelector = (store: StoreModel) => store.navigation;

const AccordionMenu = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const {
    menu: { value },
  } = useSelector(navigationSelector);

  const classes = useStyles();
  const { routeOpen, setRouteOpen } = useContext(NavigationMenuContext);

  const { appRole } = useSelector(({ session: { appRole } }: StoreModel) => ({
    appRole,
  }));
  const [indexLoading, setIndexLoading] = useState<number | null>(null);

  const handleChanged = useCallback(
    (route: string) => () => {
      setRouteOpen(routeOpen?.startsWith(route) ? "" : route);
    },
    [routeOpen, setRouteOpen]
  );

  const handleDragStart: (childId: number) => DragEventHandler = useCallback(
    (childId) => {
      return (event) => {
        event.dataTransfer.setData("text", childId.toString());
      };
    },
    []
  );

  const handleDrag: DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (childId: number, index: number) => {
      setIndexLoading(index);
      dispatch(
        adminNavigationNodePruneCell.require(
          {
            parentIsRoot: true,
            parentId: 1,
            childId,
            index,
          },
          {
            onFail: (details) => {
              setIndexLoading(null);
              showErrorMessageFromDetails(
                t("Fout bij verplaatsen pagina", { ns: "Admin" })
              )(details);
            },
            onSuccess: () => {
              setIndexLoading(null);
            },
          }
        )
      );
    },
    [dispatch, showErrorMessageFromDetails, t]
  );

  const { isEditing: isNavigationEditing } = useContext(AdminNavigationContext);
  const canDragMenuItems = useMemo(
    () => isAdminRole(appRole) && isNavigationEditing,
    [appRole, isNavigationEditing]
  );

  return (
    <>
      {canDragMenuItems && (
        <AccordionDropArea
          index={0}
          loading={indexLoading === 0}
          onDrop={handleDrop}
          size="large"
        />
      )}
      {value?.items.map((item, index) => {
        const expanded =
          (routeOpen?.startsWith(item.route) || routeOpen === routeAllOpen) &&
          ((item.children && item.children.length > 0) || canDragMenuItems);

        return (
          <>
            <Accordion
              key={index}
              expanded={expanded}
              onChange={handleChanged(item.route)}
            >
              <AccordionSummary
                draggable={canDragMenuItems}
                expandIcon={
                  (canDragMenuItems ||
                    (item.children && item.children.length > 0)) && (
                    <ExpandMoreIcon />
                  )
                }
                className={classes.bold}
                onDragStart={handleDragStart(item.id!)}
                onDrag={handleDrag}
              >
                <AccordionMenuSummary {...item} />
              </AccordionSummary>
              <AccordionDetails className={classes.noFlex}>
                <AccordionMenuContent {...item} />
              </AccordionDetails>
            </Accordion>
            {canDragMenuItems && (
              <AccordionDropArea
                index={index + 1}
                loading={indexLoading === index + 1}
                onDrop={handleDrop}
                size="large"
              />
            )}
          </>
        );
      })}
    </>
  );
};

export default AccordionMenu;
