import { Button } from "@material-ui/core";
import { useState } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";
import { WlzAdviceCaseDialog } from "./";

interface Props {
  adviceCaseId: number;
  navigationNodeId: number;
}

const WlzAdviceCaseEditButton = (props: Props) => {
  const [t] = useTranslationStrict();
  const { adviceCaseId, navigationNodeId } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleSubmitted = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>{t("Common:Edit")}</Button>
      {dialogOpen && (
        <WlzAdviceCaseDialog
          adviceCaseId={adviceCaseId}
          navigationNodeId={navigationNodeId}
          onSubmitted={handleSubmitted}
          open={dialogOpen}
        />
      )}
    </>
  );
};

export default WlzAdviceCaseEditButton;
