import { FormControl, FormLabel, MenuItem, Select } from "@material-ui/core";
import React, { memo } from "react";

import {
  DataDefinitionGroup,
  dataDefinitionGroups,
} from "../../business/models";
import { useTranslationStrict } from "../../globalization/i18n";

interface Props {
  onSelect: (group: DataDefinitionGroup) => void;
  groupSelected: DataDefinitionGroup | null;
  disabled?: boolean;
}

const DataDefinitionGroupSelect = (props: Props) => {
  const { onSelect, groupSelected, disabled } = props;
  const [t] = useTranslationStrict();

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    onSelect(event.target.value as DataDefinitionGroup);
  };

  return (
    <FormControl disabled={disabled}>
      <FormLabel>{t("Datacockpit:Group")}</FormLabel>
      <Select
        defaultValue={DataDefinitionGroup.Geen}
        onChange={handleChange}
        value={groupSelected}
        disabled={disabled}
      >
        {dataDefinitionGroups
          .filter((group) => group !== DataDefinitionGroup.Geen)
          .map((group) => (
            <MenuItem
              key={group}
              value={group}
              selected={groupSelected === group}
            >
              {group}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default memo(DataDefinitionGroupSelect);
