import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import FileSaver from "file-saver";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { emptyOrganization } from "../../../business/models";
import {
  adminSettingsPrivacyStatementDeleteCell,
  adminSettingsPrivacyStatementUploadCell,
} from "../../../business/redux/saga/admin/settings";
import {
  organizationCell,
  organizationPrivacyStatementDownloadCell,
  organizationPrivacyStatementExistsCell,
} from "../../../business/redux/saga/organization";

const PrivacyStatementCard = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const organization =
    useSelector(organizationCell.select).value || emptyOrganization;

  const [hasPrivacyStatement, setHasPrivacyStatement] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch(
      organizationPrivacyStatementExistsCell.require({
        onFail: (details) => {
          setHasPrivacyStatement(details.status === 302);
        },
      })
    );
  }, [dispatch]);

  const [privacyStatement, setPrivacyStatement] = useState<File | null>(null);
  const handlePrivacyStatementDropzoneAreaChange = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        setPrivacyStatement(files[0]);
      }
    },
    []
  );
  const handlePrivacyStatementUpload = useCallback(() => {
    if (!privacyStatement) {
      return;
    }

    const formData = new FormData();
    formData.append("privacyStatement", privacyStatement as File);
    dispatch(
      adminSettingsPrivacyStatementUploadCell.require(
        {
          organizationId: organization.id,
          formData,
        },
        {
          onSuccess: () => {
            window.location.reload(); // DropzoneArea cannot be reset, so reload the page
          },
        }
      )
    );
  }, [dispatch, organization.id, privacyStatement]);

  const handleDownload = useCallback(() => {
    dispatch(
      organizationPrivacyStatementDownloadCell.require(organization.id, {
        onSuccess: ({ body: { data } }) => {
          FileSaver.saveAs(data, "privacy-statement.pdf");
        },
      })
    );
  }, [dispatch, organization.id]);

  const handleDelete = useCallback(() => {
    dispatch(
      adminSettingsPrivacyStatementDeleteCell.require(organization.id, {
        onSuccess: () => {
          window.location.reload(); // DropzoneArea cannot be reset, so reload the page
        },
      })
    );
  }, [dispatch, organization.id]);

  return (
    <Card>
      <CardContent>
        <DropzoneArea
          acceptedFiles={["application/pdf"]}
          dropzoneText={t("Admin:Privacy statement")}
          filesLimit={1}
          onChange={handlePrivacyStatementDropzoneAreaChange}
          onDelete={() => setPrivacyStatement(null)}
          showAlerts={false}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={!privacyStatement}
          onClick={handlePrivacyStatementUpload}
        >
          <Trans i18nKey="Save" ns="Common">
            Save
          </Trans>
        </Button>
        {hasPrivacyStatement && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDownload}
            >
              <Trans i18nKey="Download" ns="Common">
                Download
              </Trans>
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDelete}
            >
              <Trans i18nKey="Delete" ns="Common">
                Delete
              </Trans>
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default PrivacyStatementCard;
