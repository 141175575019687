import { asyncUpload } from "../../../../api";

export const personalChoiceUploadPoster = (
  dataDefinitionId: number,
  file: File
) => {
  const formData = new FormData();

  formData.append("dataDefinitionId", dataDefinitionId.toString());
  formData.append("file", file);

  return asyncUpload<undefined>("/api/choice/employee/upload", formData);
};
