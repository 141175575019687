import { Button, Card, CardContent, Grid } from "@material-ui/core";
import FileSaver from "file-saver";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { adminInfoExportCell } from "../../../business/redux/saga/admin/choices/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import ChoicePeriodSelect from "./ChoicePeriodSelect";

interface UploadTypeFilterProps {
  exportEnabled: boolean;
  value?: number;

  onChange(value?: number): void;
}

const ChoicePeriodSelectCard = ({
  exportEnabled,
  onChange,
  value,
}: UploadTypeFilterProps) => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const handleExport = useCallback((): void => {
    dispatch(
      adminInfoExportCell.require(value, {
        onSuccess: ({ body: { data, name } }) => {
          FileSaver.saveAs(data, name);
        },
      })
    );
    onChange(value);
  }, [dispatch, onChange, value]);

  return (
    <Card elevation={2} raised style={{ width: "100%" }}>
      <CardContent>
        <Grid container direction="row" spacing={2} justify="space-between">
          <Grid item>
            <ChoicePeriodSelect onChange={onChange} value={value} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={!exportEnabled}
            >
              {t("Admin:ExportDetails")}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(ChoicePeriodSelectCard);
