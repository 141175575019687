import {
  AsyncAction,
  AsyncSingleValue,
  Success,
} from "async-lifecycle-saga/dist/models";
import { call, put } from "redux-saga/effects";

import { setRefreshToken } from "../../../../../utils/SecurityUtils";
import { announcementsListPagedCell } from "../../announcements/cells";
import { AuthenticationResponseModel } from "../../authentication/models";
import {
  navigationGetHistoryCell,
  navigationMenuCell,
  navigationTreeCell,
} from "../../navigation/cells";
import { organizationCell } from "../../organization/cells";
import { avatarCell } from "../../profile/avatar/cells";
import { profileCell } from "../../profile/cells";
import { OrganizationSwitchRequest } from "./models";

export function* superOrganizationsCopySuccess() {
  yield put(navigationMenuCell.require());
  yield put(navigationTreeCell.require());
  yield put(organizationCell.require());
}

export function* superOrganizationsSwitchSuccess({
  result,
}: AsyncAction<
  OrganizationSwitchRequest,
  AuthenticationResponseModel,
  AsyncSingleValue<AuthenticationResponseModel>
>) {
  const authenticationResponse = (
    result as Success<AuthenticationResponseModel>
  ).body;
  yield call(
    setRefreshToken,
    authenticationResponse.jwt!.refreshToken,
    authenticationResponse.jwt!.refreshTokenExpires
  );
  yield put(avatarCell.require());
  yield put(profileCell.require());
  yield put(announcementsListPagedCell.require({ pageNumber: 0 }));
  yield put(navigationGetHistoryCell.require(undefined));
  yield put(navigationMenuCell.require());
  yield put(navigationTreeCell.require());
  yield put(organizationCell.require());
}
