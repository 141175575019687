import { createActor, createSingle } from "async-lifecycle-saga";

import { asyncGet, asyncPost } from "../../../../api";
import { AuthenticationResponseModel } from "../models";
import { Microsoft365TokensRequest, Microsoft365UriResponse } from "./models";

export const microsoft365LoginUriCell = createSingle({
  path: ["authentication", "microsoft365", "loginUri"],
  require: {
    api: () =>
      asyncGet<Microsoft365UriResponse>(
        "/api/authentication/microsoft365/login",
        true
      ),
  },
});

export const microsoft365LogoutUriCell = createSingle({
  path: ["authentication", "microsoft365", "logoutUri"],
  require: {
    api: () =>
      asyncGet<Microsoft365UriResponse>(
        "/api/authentication/microsoft365/logout",
        true
      ),
  },
});

export const microsoft365TokensCell = createActor({
  path: ["authentication", "microsoft365", "tokens"],
  api: (code: string) =>
    asyncPost<AuthenticationResponseModel>(
      "/api/authentication/microsoft365/tokens",
      { code } as Microsoft365TokensRequest,
      true
    ),
});

export const authenticationMicrosoft365Sagas = [
  ...microsoft365LoginUriCell.sagas,
  ...microsoft365LogoutUriCell.sagas,
  ...microsoft365TokensCell.sagas,
];
