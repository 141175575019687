import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { tabletScreen } from "../../../styling/Common";

const useStyles = makeStyles(
  (): Record<string, CSSProperties> => ({
    ...tabletScreen({
      gridMainContent: {
        gridColumn: 2,
        gridRow: 2,
      },
    }),
  })
);

export default useStyles;
