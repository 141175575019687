import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import React, { useContext } from "react";

import { NavigationNodeModel } from "../../business/models";
import { reflexoHistory } from "../../business/router";
import materialIcon from "../ux/icons";
import useStyles from "./AccordionMenu.styles";
import { NavigationMenuContext } from "./navigationMenu";

const AccordionMenuSummary = (props: NavigationNodeModel) => {
  const { icon, id, route, title, warning } = props;
  const classes = useStyles();

  const { routeOpen, setRouteOpen } = useContext(NavigationMenuContext);

  return (
    <List dense disablePadding key={id}>
      <ListItem
        key={id || route}
        onClick={(event): void => {
          event.preventDefault();
          event.stopPropagation();
          reflexoHistory.push(route);
          if (!routeOpen?.startsWith(route)) {
            setRouteOpen("");
          }
        }}
      >
        <ListItemIcon
          classes={{
            root: warning ? classes.menuIconWarning : classes.menuIcon,
          }}
        >
          {warning ? <Warning /> : materialIcon(icon)}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            classes: { root: classes.menuText },
          }}
        />
      </ListItem>
    </List>
  );
};

export default AccordionMenuSummary;
