import { combineReducers } from "redux";

import { superImpersonateCell } from "./cells";
import {
  superOrganizationsCopy,
  superOrganizationsListCell,
  superOrganizationsSwitchCell,
} from "./organizations/cells";

export default combineReducers({
  impersonate: superImpersonateCell.reducer,
  organizations: combineReducers({
    copy: superOrganizationsCopy.reducer,
    list: superOrganizationsListCell.reducer,
    switch: superOrganizationsSwitchCell.reducer,
  }),
});
