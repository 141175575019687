import { AsyncResponse, createActor } from "async-lifecycle-saga";

import {
  GroeneWkrRegistration,
  GroeneWkrRequest,
  GroeneWkrResponse,
} from "../../../../../components/enienl/models";
import { asyncPost } from "../../../../api";

const calculate = (
  request: GroeneWkrRequest
): Promise<AsyncResponse<GroeneWkrResponse>> =>
  asyncPost<GroeneWkrResponse>("/api/enienl/admin/calculate", request);

const register = (
  registration: GroeneWkrRegistration
): Promise<AsyncResponse<void>> =>
  asyncPost("/api/enienl/admin/register", registration);

export const enienlCalculateCell = createActor({
  path: ["admin", "enienl", "calculate"],
  api: calculate,
});
export const enienlRegisterCell = createActor({
  path: ["admin", "enienl", "register"],
  api: register,
});
