import Card from "@material-ui/core/Card";
import React, { ReactNode } from "react";

export interface CardProps {
  children?: ReactNode;
}

const CardPage = React.memo(({ children }: CardProps): JSX.Element => {
  return (
    <Card
      style={{
        padding: 24,
        gridArea: "2 / 2 / span 1 / span 2",
        position: "relative",
      }}
    >
      {children}
    </Card>
  );
});

export default CardPage;
