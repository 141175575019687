import { ScopeRelation } from "async-lifecycle-saga";
import createSearch from "async-lifecycle-saga/dist/form/search";
import { Success } from "async-lifecycle-saga/dist/models";

import { asyncGet } from "../../../api";
import { SearchQueryModel, SearchResultModel } from "../../../models";

const nothingFound = (query: string): Promise<Success<SearchResultModel>> =>
  Promise.resolve({
    status: 200,
    body: {
      query,
      totalCount: 0,
      items: [],
    },
  });

export const searchCell = createSearch<SearchQueryModel, SearchResultModel>({
  path: ["search"],
  api: ({ query }) =>
    query.length > 2
      ? asyncGet(`/api/search?query=${encodeURIComponent(query)}`)
      : nothingFound(query),
  compare: (a, b) =>
    !a.query || !b.query
      ? ScopeRelation.unrelated
      : a.query === b.query
      ? ScopeRelation.equal
      : a.query.startsWith(b.query)
      ? ScopeRelation.narrower
      : b.query.startsWith(a.query)
      ? ScopeRelation.broader
      : ScopeRelation.unrelated,
});
