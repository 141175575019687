import { Paper, Popover, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    padding: theme.spacing() * 2,
    maxWidth: "30em",
  },
  infoPopover: {
    marginTop: theme.spacing() * 3,
  },
}));

interface Props {
  renderButton: (
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  ) => ReactNode;
  children: ReactNode;
}

const InfoBase = ({ children, renderButton }: Props): JSX.Element => {
  const classes = useStyles();

  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const infoPopoverOpen = Boolean(infoPopoverAnchorEl);

  const handleInfoClicked = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    setInfoPopoverAnchorEl(event.currentTarget);
  };

  const handleInfoCloses = (): void => {
    setInfoPopoverAnchorEl(null);
  };

  return (
    <>
      {renderButton(handleInfoClicked)}
      <Popover
        open={infoPopoverOpen}
        anchorEl={infoPopoverAnchorEl}
        onClose={handleInfoCloses}
        className={classes.infoPopover}
      >
        <Paper className={classes.info}>
          <Typography>{children}</Typography>
        </Paper>
      </Popover>
    </>
  );
};

export default InfoBase;
