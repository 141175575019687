import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import React, { ChangeEvent, memo, useCallback } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

interface UsersFullTextFilterProps {
  onChange: (newValue: string) => void;
  value?: string;
}

const UsersFullTextFilter = memo(
  ({ onChange, value }: UsersFullTextFilterProps) => {
    const [t] = useTranslationStrict();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
      },
      [onChange]
    );

    return (
      <Card elevation={2} raised>
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">
                {t("Admin:SearchUsersLabel")}
              </Typography>
            </Grid>
            <Grid
              alignItems="flex-end"
              container
              direction="row"
              item
              spacing={1}
            >
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <TextField fullWidth onChange={handleChange} value={value} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

export default UsersFullTextFilter;
