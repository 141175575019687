import { combineReducers } from "redux";

import {
  adminNavigationContentAddCell,
  adminNavigationContentDeleteCell,
  adminNavigationContentDocumentUploadCell,
  adminNavigationContentUpdateCell,
  adminNavigationNodeAddChildCell,
  adminNavigationNodeAddToRootCell,
  adminNavigationNodeDeleteCell,
  adminNavigationNodePruneCell,
  adminNavigationNodeUpdateCell,
} from "./cells";

const adminNavigationReducer = combineReducers({
  content: combineReducers({
    add: adminNavigationContentAddCell.reducer,
    delete: adminNavigationContentDeleteCell.reducer,
    update: adminNavigationContentUpdateCell.reducer,
    upload: adminNavigationContentDocumentUploadCell.reducer,
  }),
  navigationNode: combineReducers({
    addChild: adminNavigationNodeAddChildCell.reducer,
    addToRoot: adminNavigationNodeAddToRootCell.reducer,
    delete: adminNavigationNodeDeleteCell.reducer,
    prune: adminNavigationNodePruneCell.reducer,
    update: adminNavigationNodeUpdateCell.reducer,
  }),
});

export default adminNavigationReducer;
