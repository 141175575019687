import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { drop, filter, flatMap, flow, map, size, sum, take } from "lodash/fp";
import { useState } from "react";

import formatEmployeeData from "../../../business/formatEmployeeData";
import { DataDefinitionModel } from "../../../business/models";
import {
  DataTypeId,
  EmployeeDataModel,
  VariableDataRow,
} from "../../../business/personal/employeeData";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../globalization/i18n";
import { formatEuro } from "../../../utils/NumberUtils";
import Loading from "../../ux/Loading";
import PaginationTableRow from "../../ux/PaginationTableRow";

const getTextAlign = (dataTypeId: DataTypeId) => {
  switch (dataTypeId) {
    case DataTypeId.bedrag:
      return "right";
    default:
      return "inherit";
  }
};

const renderAggregation = (
  variable: DataDefinitionModel,
  data: VariableDataRow[],
  t: TFunctionStrict
) => {
  const dataView = flow(
    flatMap((dr: VariableDataRow) => dr.data.map((ed) => ed)),
    filter((ed) => ed.dataDefinitionId === variable.id)
  )(data);

  switch (variable.dataType.id) {
    case DataTypeId.bedrag:
      const amountAccumulation = flow(
        filter((d: EmployeeDataModel) => !d.isEmpty),
        map((d) => d.numeric),
        sum
      )(dataView);
      return formatEuro(amountAccumulation);
    case DataTypeId.geslacht:
      const geslacht = flow(
        filter((d: EmployeeDataModel) => !d.isEmpty),
        map((d): number => (d.boolean === true ? 1 : 0))
      )(dataView);
      if (geslacht.length === 0) {
        return t("Datacockpit:M % / V %", { m: 0, v: 0 });
      }
      const menCount = sum(geslacht);
      const menPercentage = (menCount / geslacht.length) * 100;
      return t("Datacockpit:M % / V %", {
        m: menPercentage,
        v: 100 - menPercentage,
      });
    default:
      const count = flow(
        filter((d: EmployeeDataModel) => !d.isEmpty),
        size
      )(dataView);
      return t("Datacockpit:Aantal ingevuld", {
        count,
      });
  }
};

const useStyles = makeStyles(() => ({
  columnFirst: {
    borderRight: "1px solid rgb(224, 224, 224)",
  },
  content: {
    marginTop: "2em",
  },
}));

interface Props {
  variables: DataDefinitionModel[];
  data: VariableDataRow[];
  hideRecords?: boolean;
  loading: boolean;
}

const VariablesOverviewTable = (props: Props) => {
  const { variables, data, hideRecords, loading } = props;
  const classes = useStyles();
  const [t, { language }] = useTranslationStrict();

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const handleItemsPerPageSelected = (itemsPerPageNew: number) => {
    setSelectedPage(1);
    setItemsPerPage(itemsPerPageNew);
  };

  // Check hideRecords because it makes no sense calculating the current page when the data isn't shown anyway.
  const dataPaged =
    (!hideRecords &&
      flow(
        drop(itemsPerPage * (selectedPage - 1)),
        take(itemsPerPage)
      )(data)) ||
    [];

  switch (true) {
    case loading:
      return (
        <Card classes={{ root: classes.content }}>
          <CardContent>
            <Loading />
          </CardContent>
        </Card>
      );
    case data.length === 0:
      return (
        <Card classes={{ root: classes.content }}>
          <CardContent>
            <em>
              {t("Datacockpit:Geen gegevens beschikbaar met dit filter.")}
            </em>
          </CardContent>
        </Card>
      );
    default:
      return (
        <Table classes={{ root: classes.content }}>
          <TableHead>
            <PaginationTableRow
              colSpan={variables.length}
              itemsCount={data.length}
              itemsPerPage={itemsPerPage}
              onItemsPerPageSelected={handleItemsPerPageSelected}
              onPageSelected={(page) => setSelectedPage(page)}
              selectedPage={selectedPage}
            />
            <TableRow>
              {variables.map((v) => (
                <TableCell>
                  {t(`Variables:${v.code}`)}
                  <br />
                  <code>{v.code}</code>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {variables.map((v) => (
                <TableCell
                  style={{
                    backgroundColor: "#eeeeee",
                    textAlign: getTextAlign(v.dataType.id),
                  }}
                >
                  {renderAggregation(v, data, t)}
                </TableCell>
              ))}
            </TableRow>
            {!hideRecords &&
              dataPaged.length > 0 &&
              !loading &&
              dataPaged
                .filter((dr) => dr.data.length > 0)
                .map((dr) => (
                  <TableRow>
                    {variables.map((v) => (
                      <TableCell
                        style={{ textAlign: getTextAlign(v.dataType.id) }}
                      >
                        {formatEmployeeData(
                          v,
                          dr.data.find((d) => d.dataDefinitionId === v.id)!,
                          t,
                          language
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      );
  }
};

export default VariablesOverviewTable;
