import { put } from "@redux-saga/core/effects";
import { createSingle } from "async-lifecycle-saga";

import { StoreModel } from "../models";
import { profileGetter } from "./api";
import { avatarCell } from "./avatar/cells";

const profileSelector = ({
  profile: {
    profile: { value },
  },
}: StoreModel) => ({ status: { loading: false }, value });

export const profileCell = createSingle({
  path: ["PROFILE"],
  require: { api: profileGetter },
  select: profileSelector,
});

export function* profileSuccess(): Generator {
  yield put({ type: avatarCell.events.require });
}
