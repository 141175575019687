import { Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

import { useTranslationStrict } from "../../globalization/i18n";
import usePageWrap from "../../styling/PageWrap";
import useStyles from "./Footer.styles";

export interface FooterProps {
  copyrightHolder: string;
  copyrightYears: number;
}

const Footer = React.memo<FooterProps>(
  (props: FooterProps): React.ReactElement => {
    const [t] = useTranslationStrict();
    const { copyrightHolder, copyrightYears } = props;

    const footerClasses = useStyles();
    const pageWrapClasses = usePageWrap();

    return (
      <div className={classNames(footerClasses.root, pageWrapClasses.outer)}>
        <Typography variant="caption">
          © {copyrightHolder} {copyrightYears}.{" "}
          {t("Common:CopyrightAllRightsReserved")}{" "}
        </Typography>
      </div>
    );
  }
);

export default Footer;
