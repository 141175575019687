import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { tabletScreen } from "../../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    gridItemLoginField: {
      textAlign: "center",
    },
    gridItemReset: {
      textAlign: "center",
      "& a": {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    },
    paperRoot: {
      padding: theme.spacing() * 2,
    },
    ...tabletScreen({
      gridMainContent: {
        gridColumn: 2,
        gridRow: 2,
      },
    }),
  })
);

export default useStyles;
