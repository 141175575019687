import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { adminUsersListCell } from "../../../business/redux/saga/admin/users/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { superImpersonateCell } from "../../../business/redux/saga/super/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { showError } from "../../../utils/SnackbarUtils";
import Loading from "../../ux/Loading";
import useStyles from "../admin/AdminPage.styles";

const ImpersonationPage = memo((): JSX.Element => {
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { appRole, currentUser } = useSelector(
    ({ session: { appRole, user } }: StoreModel) => ({
      appRole,
      currentUser: user,
    })
  );
  const { users } = useSelector(
    ({
      admin: {
        users: {
          list: { value: users = [] },
        },
      },
    }: StoreModel) => ({
      users,
    })
  );

  useEffect((): void => {
    dispatch(adminUsersListCell.require());
  }, [dispatch]);

  const onUserChange = useCallback(
    (e) => {
      const employeeId = e.target.value ? Number(e.target.value) : undefined;
      if (employeeId) {
        dispatch(
          superImpersonateCell.require(
            { employeeId },
            {
              onFail: () => {
                showError(enqueueSnackbar);
              },
            }
          )
        );
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const present = users.length > 0;

  return (
    <div className={classes.gridWideContent}>
      <Card>
        <CardContent>
          {!present && <Loading />}
          {present && (
            <FormControl>
              <FormLabel>{t("Admin:User")}</FormLabel>
              <Select onChange={onUserChange}>
                {users
                  .filter(
                    (user) =>
                      appRole === "platformAdmin" ||
                      (!user.isPlatformAdmin &&
                        user.organizationId === currentUser?.organizationId)
                  )
                  .map(
                    (user): JSX.Element => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.fullName}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          )}
        </CardContent>
      </Card>
    </div>
  );
});

export default ImpersonationPage;
