import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CallSplit,
  DeleteForever as DeleteForeverIcon,
  Description,
} from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { cryptOrgplan } from "../../business/api";
import { NavigationSpecial } from "../../business/models";
import { StoreModel } from "../../business/redux/saga/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { getContentTypeName } from "../../utils/FileUtils";
import DocumentListItemInsertButton from "../admin/contentNodes/ContentNodeDocumentListItemInsertButton";
import useStyles from "./DocumentListItem.styles";
import OrgplanChoiceModal, { OrgplanChoice } from "./OrgplanChoiceModal";

interface DocumentListItemProps {
  document: {
    id?: number;
    documentName: string;
    contentType?: string;
    title?: string;
  };
  special?: NavigationSpecial;
  enableEdit: boolean;
  isEditing?: boolean;

  onClick(): void;

  onDelete?(): void;

  onInsert?(documentId: number, documentName: string): void;
}

const hiddenInput = (name: string, value: string): Element => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  return input;
};

const openViewer =
  (target: string) =>
  (body: { [name: string]: string }): void => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = `https://www.my-orgchart.com/viewer/viewer_ph.php`;
    form.target = target;

    const entries = Object.entries(body);
    for (let i = 0; i < entries.length; i += 1) {
      form.appendChild(hiddenInput(entries[i][0], entries[i][1]));
    }

    document.body.appendChild(form);
    form.submit();
  };

const openOrgplan = (id: number, code?: string): void => {
  const target = `${id}${code ?? ""}`;
  window.open(
    "https://www.my-orgchart.com/viewer/includes/images/my-o-busy.gif",
    target
  );
  cryptOrgplan(id, code).then(openViewer(target));
};

const DocumentListItem = ({
  document: { id, documentName, contentType, title },
  special,
  enableEdit,
  isEditing,
  onClick,
  onDelete,
  onInsert,
}: DocumentListItemProps) => {
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const orgplanChoices = useSelector((store: StoreModel): OrgplanChoice[] => {
    const definition = store.personal.definitions.value?.find(
      ({ code }) => code === "ORGPLAN_AFDELING"
    );
    return (
      store.personal.data.value?.find(
        ({ dataDefinitionId: id }) => id === definition?.id
      )?.text ?? ""
    )
      .split(/\s*;\s*/g)
      .filter((s) => s.indexOf("=") >= 0)
      .map((s) => {
        const eq = s.indexOf("=");
        const code = s.substring(0, eq).trim();
        const label = s.substring(eq + 1).trim();
        return { code, label };
      });
  });
  const [choiceOpen, setChoiceOpen] = useState(false);
  const toggleChoice = useCallback((): void => {
    setChoiceOpen(!choiceOpen);
  }, [choiceOpen]);
  const startOrgplan = useCallback(
    (code: string | undefined): void => {
      openOrgplan(id!, code);
    },
    [id]
  );

  const orgplan =
    !enableEdit && special === "orgplan" && contentType === "application/json";
  const handleClick = useCallback(
    (): void =>
      orgplan
        ? orgplanChoices.length > 1
          ? toggleChoice()
          : startOrgplan(orgplanChoices[0]?.code)
        : onClick(),
    [orgplan, orgplanChoices, toggleChoice, startOrgplan, onClick]
  );

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon classes={{ root: classes.menuIcon }}>
          {orgplan ? <CallSplit /> : <Description />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            classes: { root: classes.menuText },
          }}
          classes={{ root: classes.listItemText }}
        >
          {title || documentName}
        </ListItemText>
        <ListItemSecondaryAction
          classes={{ root: classes.listItemSecondaryAction }}
        >
          {!enableEdit && !orgplan && (
            <Typography component="span">
              {getContentTypeName(t, contentType || "unknown")}
            </Typography>
          )}
          {enableEdit &&
            isEditing &&
            contentType &&
            contentType.startsWith("image/") &&
            id &&
            onInsert && (
              <DocumentListItemInsertButton
                id={id}
                documentName={title || documentName}
                onInsert={onInsert}
              />
            )}
          {enableEdit && (
            <Tooltip title={t("Common:Delete")}>
              <IconButton onClick={onDelete} classes={{ root: classes.danger }}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {choiceOpen && (
        <OrgplanChoiceModal
          onClose={toggleChoice}
          startOrgplan={startOrgplan}
          choices={orgplanChoices}
        />
      )}
    </>
  );
};

export default DocumentListItem;
