import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  selectMenuItem: {
    alignItems: "flex-start",
    flexDirection: "column",
  },
  selectMenuItemHidden: {
    display: "none",
  },
}));

export default useStyles;
