import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  LinkContentNodeModel,
  emptyOrganization,
} from "../../../business/models";
import { useAuthentication } from "../../../business/redux/saga/authentication/hooks";
import { SsoToken } from "../../../business/redux/saga/authentication/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { daywizeTokenCell } from "../../../business/redux/saga/user/cells";
import {
  addQueryParam,
  getAuthority,
  getDomain,
  getHostname,
} from "../../../utils/LinkUtils";
import useStyles from "../../pages/ContentPage.styles";
import { ContentNodeActions } from "./";

const daywizeHostname = "daywize.mendixcloud.com";

interface Props {
  contentNode: LinkContentNodeModel;
  disabled?: boolean;
  isEditing: boolean;
  showShiftDown: boolean;
  showShiftUp: boolean;

  onDaywizeNotification(): void;

  onEdit(contentNode: LinkContentNodeModel): void;
}

const ContentNodeLinkListItem = (props: Props) => {
  const {
    contentNode,
    disabled = false,
    isEditing,
    onDaywizeNotification,
    onEdit,
    showShiftDown,
    showShiftUp,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sso } = useAuthentication();

  const { nmbrsDomain, daywizeClientCode } = useSelector(
    ({
      organization: {
        get: { value: { nmbrsDomain, daywizeClientCode } = emptyOrganization },
      },
    }: StoreModel) => ({
      nmbrsDomain,
      daywizeClientCode,
    })
  );

  return (
    <ListItem
      key={contentNode.id}
      button
      onClick={(): void => {
        if (
          nmbrsDomain &&
          getHostname(contentNode.link) === `${nmbrsDomain}.nmbrs.nl`
        ) {
          sso(undefined, {
            onSuccess: ({ body: { token } }: Success<SsoToken>): void => {
              window.open(
                addQueryParam(
                  `${getAuthority(
                    contentNode.link
                  )}/applications/common/externalactions.aspx?login=nmbrs`,
                  "ID",
                  token
                ),
                "_blank"
              );
            },
            onFail: (): void => {
              window.open(contentNode.link, "_blank");
            },
          });
          return;
        }
        if (
          daywizeClientCode &&
          getHostname(contentNode.link) === daywizeHostname
        ) {
          dispatch(
            daywizeTokenCell.require(contentNode.link, {
              onSuccess: ({ body: { token } }: Success<SsoToken>) => {
                if (token && token.length > 0 && token !== "No user found") {
                  window.open(
                    addQueryParam(
                      `${getAuthority(
                        contentNode.link
                      )}/link/${daywizeClientCode}_SSO`,
                      "param",
                      token
                    ),
                    "_blank"
                  );
                } else {
                  onDaywizeNotification();
                }
              },
              onFail: () => {
                onDaywizeNotification();
              },
            })
          );
          return;
        }

        window.open(contentNode.link, "_blank");
      }}
    >
      <ListItemIcon classes={{ root: classes.menuIcon }}>
        <OpenInNew />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          classes: { root: classes.menuText },
        }}
        classes={{ root: classes.listItemText }}
      >
        {contentNode.title}
      </ListItemText>
      <ListItemSecondaryAction
        classes={{ root: classes.listItemSecondaryAction }}
      >
        {!isEditing && (
          <Typography component="span" noWrap title={contentNode.link}>
            {getDomain(contentNode.link)}
          </Typography>
        )}
        {isEditing && (
          <ContentNodeActions
            contentNode={contentNode}
            disabled={disabled}
            onEdit={onEdit}
            showShiftDown={showShiftDown}
            showShiftUp={showShiftUp}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ContentNodeLinkListItem;
