import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { translateAndSortUploadTypes } from "../../../business/admin/upload/models";
import { adminUploadTypesCell } from "../../../business/redux/saga/admin/upload/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";

interface UploadTypeFilterProps {
  onChange: (uploadTypeId: number | null) => void;
  value: number | null;
}

const UploadTypeFilter = ({ onChange, value }: UploadTypeFilterProps) => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();

  const uploadTypes = useSelector(
    /**
     * Selects the known upload types from the Redux Store.
     * @param types The known upload types from the Redux Store.
     */
    ({
      admin: {
        upload: {
          types: { value = [] },
        },
      },
    }: StoreModel) => value
  );
  const uploadTypesSorted = useMemo(
    /**
     * Returns a sorted array of upload types.
     */
    () => translateAndSortUploadTypes(uploadTypes || [], t),
    [t, uploadTypes]
  );

  const handleChange = useCallback(
    /**
     * Handles the selection of an upload type.
     * @param event The change event that was fired by the `Select` for upload types.
     */
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      onChange(
        event.target.value === "none" ? null : Number(event.target.value)
      );
    },
    [onChange]
  );

  useCallback(
    /**
     * Handles a click on the "Reset" button.
     */
    () => {
      onChange(null);
    },
    [onChange]
  );

  useEffect(
    /**
     * Ensures that we load the known upload types into the Redux Store.
     */
    () => {
      if (!uploadTypes) {
        dispatch(adminUploadTypesCell.require());
      }
    },
    [dispatch, uploadTypes]
  );

  return (
    <Card elevation={2} raised>
      <CardContent>
        <Grid container direction="column" item>
          <Grid item>
            <Typography variant="caption">
              {t("Admin:FilterUploadType")}
            </Typography>
          </Grid>
          <Grid
            alignItems="flex-end"
            container
            direction="row"
            item
            spacing={1}
          >
            <Grid item>
              <Select
                onChange={handleChange}
                value={value ?? "none"}
                style={{ width: 250, maxWidth: "80vw" }}
              >
                <MenuItem key="placeholder" value="none">
                  <em>{t("Admin:SelectUploadType")}</em>
                </MenuItem>
                {uploadTypesSorted.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(UploadTypeFilter);
