import { createList } from "async-lifecycle-saga";

import { EmployerModel } from "../../../../models";
import { StoreModel } from "../../models";
import {
  employersCreate,
  employersDelete,
  employersList,
  employersUpdate,
} from "./api";

export const adminEmployersListCell = createList<
  EmployerModel,
  StoreModel,
  undefined,
  EmployerModel,
  EmployerModel,
  number
>({
  path: ["admin", "employers", "list"],
  create: { api: employersCreate },
  delete: { api: employersDelete },
  require: { api: employersList(false) },
  update: { api: employersUpdate },
});

export const adminEmployersListSpecialCell = createList<
  EmployerModel,
  StoreModel,
  undefined,
  EmployerModel,
  EmployerModel,
  number
>({
  path: ["admin", "employers", "listSpecial"],
  create: { api: employersCreate },
  delete: { api: employersDelete },
  require: { api: employersList(true) },
  update: { api: employersUpdate },
});
