import { Dialog } from "@material-ui/core";
import { FormikActions } from "formik";
import { useSnackbar } from "notistack";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { EmployerModel, ModuleOption } from "../../../business/models";
import { adminEmployersListSpecialCell } from "../../../business/redux/saga/admin/employers/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import EmployerForm from "./EmployerForm";

interface EmployerEditDialogProps {
  id: number;
  onSubmitted: () => void;
  open: boolean;
}

const EmployerEditDialog = ({
  id,
  onSubmitted,
  open,
}: EmployerEditDialogProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();

  const employer = useSelector(
    ({
      admin: {
        employers: {
          listSpecial: { value },
        },
      },
    }: StoreModel) => value?.find((e) => e.id === id)
  );

  const validationSchema = useMemo(() => {
    const requiredMessage = t("Forms:Required") as string;
    return Yup.object<EmployerModel>().shape<EmployerModel>({
      id: Yup.number(),
      organizationId: Yup.number(),
      code: Yup.string(),
      name: Yup.string().required(requiredMessage),
      government: Yup.bool().required(requiredMessage),
      isSmall: Yup.bool().required(requiredMessage),
      isSpecial: Yup.bool(),
      whkRate: Yup.number().required(requiredMessage),
      whkRateEmployee: Yup.number().required(requiredMessage),
      wkrBudget: Yup.number().required(requiredMessage),
      fulltimeHours: Yup.number().required(requiredMessage),
      benefitCalculation: Yup.mixed<ModuleOption>()
        .oneOf(Object.values(ModuleOption))
        .required(requiredMessage),
      benefitShared: Yup.number().required(requiredMessage),
      benefitSharedTaxExempt: Yup.number().required(requiredMessage),
      benefitSharedDispensated: Yup.number().required(requiredMessage),
      period: Yup.string(),
    });
  }, [t]);

  const handleSubmit = useCallback(
    (values: EmployerModel, formikActions: FormikActions<EmployerModel>) => {
      dispatch(
        adminEmployersListSpecialCell.update(values, {
          onFail: () => {
            enqueueSnackbar(t("Admin:EmployerUpdateFailed"), {
              variant: "error",
            });
            formikActions.setSubmitting(false);
          },
          onSuccess: () => {
            formikActions.setSubmitting(false);
            enqueueSnackbar(t("Admin:EmployerUpdateSucceeded"), {
              variant: "success",
            });
            onSubmitted();
          },
        })
      );
    },
    [dispatch, enqueueSnackbar, onSubmitted, t]
  );

  return employer ? (
    <Dialog fullWidth open={open}>
      <EmployerForm
        initialValues={employer}
        onCancel={onSubmitted}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </Dialog>
  ) : (
    <></>
  );
};

export default memo(EmployerEditDialog);
