import { Button, Grid, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
// eslint-disable-next-line import/no-unresolved
import { OptimalChoice } from "@twijg/loonheffing";
import { memo } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import { SalaryEffect, SummaryTotal, WkrLimit } from "../../business/alacarte";
import { ChoiceStatus, ModuleOption } from "../../business/models";
import { StoreModel } from "../../business/redux/saga/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { formatEuro, formatEuroPlus, toAmount } from "../../utils/NumberUtils";
import ContentCard from "../layout/ContentCard";
import Info from "../ux/Info";
import useStyles from "./summary.styles";

interface SummaryProps {
  choiceStatus: ChoiceStatus | undefined;
  total: SummaryTotal;
  differenceAlc: number;
  optimal?: OptimalChoice;
  salaryEffect?: SalaryEffect;
  wkrLimit: WkrLimit;
  nextStep: () => void;
  onCancel: () => void;
  benefitCalculation: ModuleOption;
  alacarteInfo?: string;
  monthlyHoursInEuros: number;
  annualHoursInEuros: number;
}

const Summary = ({
  choiceStatus,
  total,
  differenceAlc,
  optimal,
  salaryEffect,
  wkrLimit,
  nextStep,
  onCancel,
  benefitCalculation,
  alacarteInfo,
  monthlyHoursInEuros,
  annualHoursInEuros,
}: SummaryProps) => {
  const classes = useStyles();
  const [t] = useTranslationStrict();

  const employeeBenefitStored = useSelector(
    ({
      personal: {
        choices: {
          entity: { value },
        },
      },
    }: StoreModel) => (value ? value.employeeBenefit : 0)
  );

  const choicesBalanced = differenceAlc === 0;
  const employeeBenefit =
    choiceStatus === "initial"
      ? choicesBalanced &&
        benefitCalculation !== ModuleOption.off &&
        optimal &&
        toAmount(optimal.goal()) > 0
        ? toAmount((total.goals / optimal.goal()) * optimal.employeeBenefit())
        : 0
      : employeeBenefitStored;
  const hasBenefit = employeeBenefit > 0;
  const hasNegativeBenefit = employeeBenefit < 0;

  const disabledReason = ((): string | undefined => {
    switch (true) {
      case choiceStatus === undefined:
        return t("Alacarte:ChoicesClosed");
      case choiceStatus === "requested":
        return t("Alacarte:ChoicesRequested");
      case choiceStatus === "approved":
        return t("Alacarte:ChoicesApproved");
      case total.wkrGoals > wkrLimit.wkrAvailable:
        return t(
          wkrLimit.wkrChosen > 0
            ? "Alacarte:ChoicesWkrLeft"
            : "Alacarte:ChoicesWkrLimit",
          {
            chosen: formatEuro(wkrLimit.wkrChosen),
            max: formatEuro(wkrLimit.wkrYearMax),
            available: formatEuro(wkrLimit.wkrAvailable),
          }
        );
      case differenceAlc !== 0:
        return t("Alacarte:ChoicesUnbalanced");
      case total.goals <= 0:
        return t("Alacarte:ChoicesMissing");
      case optimal === undefined:
        return t("Alacarte:ChoicesNotCalculated");
      case hasNegativeBenefit:
        return t("Alacarte:ChoicesNegativeBenefit");
      default:
        return undefined;
    }
  })();

  return (
    <ContentCard
      classes={classes}
      elevation={24}
      raised
      title={t("Alacarte:Summary")}
    >
      <Grid container spacing={3}>
        <Grid item md={2}>
          <Typography variant="subtitle1">
            {hasBenefit ? t("Alacarte:TotalChoices") : t("Alacarte:Purposes")}
          </Typography>
          <Typography variant="h6" noWrap>
            {formatEuro(total.goals)}
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="subtitle1">
            {hasBenefit ? t("Alacarte:Net") : t("Alacarte:Sources")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            color={hasBenefit ? "primary" : undefined}
          >
            {formatEuro(
              hasBenefit ? total.goals - employeeBenefit : total.sources
            )}
          </Typography>
        </Grid>
        <Grid item md={4}>
          {!hasBenefit && differenceAlc !== 0 && (
            <>
              <Typography variant="subtitle1">
                {t("Alacarte:DifferenceAlc")}
              </Typography>
              <Typography variant="h6" noWrap color="error">
                {formatEuroPlus(differenceAlc)}
              </Typography>
            </>
          )}
          {hasBenefit ? (
            <>
              <Typography variant="subtitle1">
                {t("Alacarte:YourBenefit")}
              </Typography>
              <Typography variant="h6" noWrap color="primary">
                {formatEuro(employeeBenefit)}{" "}
                <Info>
                  <ReactMarkdown>
                    {alacarteInfo ?? t("Alacarte:BenefitDisclaimer")}
                  </ReactMarkdown>
                </Info>
              </Typography>
            </>
          ) : (
            choicesBalanced && (
              <>
                <Typography variant="subtitle1">
                  {t("Alacarte:DifferenceNone")}
                </Typography>
                <Typography variant="h6" noWrap color="primary">
                  <Check />
                </Typography>
              </>
            )
          )}
        </Grid>
        <Grid item md={4}>
          {choiceStatus !== "requested" && (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={disabledReason !== undefined}
              onClick={nextStep}
            >
              {t("Alacarte:NextStep")}
            </Button>
          )}
          {disabledReason && (
            <Typography>
              <em style={{ color: hasNegativeBenefit ? "red" : "inherit" }}>
                {disabledReason}
              </em>
            </Typography>
          )}
          {choiceStatus === "requested" && (
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              {t("Alacarte:Revert")}
            </Button>
          )}
        </Grid>
        {salaryEffect && (
          <Grid item xs={12}>
            {(salaryEffect.amountOnce > 0 ||
              salaryEffect.amountRepeating > 0) && (
              <Typography>
                {t("Alacarte:SalaryAlert")}:
                {salaryEffect.amountOnce > 0 && (
                  <>
                    {" "}
                    {formatEuro(salaryEffect.amountOnce)}{" "}
                    {t("Alacarte:SalaryOnce")}
                  </>
                )}
                {salaryEffect.amountRepeating > 0 && (
                  <>
                    {" "}
                    {formatEuro(salaryEffect.amountRepeating)}{" "}
                    {t("Alacarte:SalaryRepeating")}
                  </>
                )}
              </Typography>
            )}
            {(monthlyHoursInEuros > 0 || annualHoursInEuros > 0) && (
              <Typography>
                {t("Alacarte:HoursAlert")}:
                {annualHoursInEuros > 0 && (
                  <>
                    {" "}
                    {formatEuro(annualHoursInEuros)} {t("Alacarte:SalaryOnce")}
                  </>
                )}
                {monthlyHoursInEuros > 0 && (
                  <>
                    {" "}
                    {formatEuro(monthlyHoursInEuros)}{" "}
                    {t("Alacarte:SalaryRepeating")}
                  </>
                )}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </ContentCard>
  );
};

export default memo(Summary);
