import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {},
    icon: { color: theme.palette.primary.main },
  })
);

export default useStyles;
