import { useDispatch } from "react-redux";

import { HookDispatchFunction } from "../models";
import {
  userPasswordResetCell,
  userPasswordResetRequestCell,
  userPasswordResetVerifyCell,
} from "./cells";
import {
  UserPasswordResetInput,
  UserPasswordResetRequestInput,
  UserPasswordResetRequestResult,
  UserPasswordResetResult,
  UserPasswordResetVerifyInput,
  UserPasswordResetVerifyResult,
} from "./models";

type PasswordResetFunction = HookDispatchFunction<
  UserPasswordResetInput,
  UserPasswordResetResult
>;

type PasswordResetRequestFunction = HookDispatchFunction<
  UserPasswordResetRequestInput,
  UserPasswordResetRequestResult
>;

type VerifyPasswordResetTokenFunction = HookDispatchFunction<
  UserPasswordResetVerifyInput,
  UserPasswordResetVerifyResult
>;

export interface UseUserHook {
  resetPassword: PasswordResetFunction;
  resetPasswordRequest: PasswordResetRequestFunction;
  verifyPasswordResetToken: VerifyPasswordResetTokenFunction;
}

export const useUser = (): UseUserHook => {
  const dispatch = useDispatch();

  const resetPassword: PasswordResetFunction = (request, callbacks) => {
    dispatch(userPasswordResetCell.require(request, callbacks));
  };

  const resetPasswordRequest: PasswordResetRequestFunction = (
    request,
    callbacks
  ) => {
    dispatch(userPasswordResetRequestCell.require(request, callbacks));
  };

  const verifyPasswordResetToken: VerifyPasswordResetTokenFunction = (
    request,
    callbacks
  ) => {
    dispatch(userPasswordResetVerifyCell.require(request, callbacks));
  };

  return {
    resetPassword,
    resetPasswordRequest,
    verifyPasswordResetToken,
  };
};
