import { MenuItem } from "@material-ui/core";
import { Select, SelectProps } from "formik-material-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ChoiceExportFormatExtra } from "../../../business/models";

const ChoiceExportFormatSelectExtra = (props: SelectProps) => {
  const [t] = useTranslation("Admin");

  const formatLabels: { [key in ChoiceExportFormatExtra]: string } = useMemo(
    () => ({
      [ChoiceExportFormatExtra.none]: t("choiceExportFormatExtra.none"),
      [ChoiceExportFormatExtra.nmbrs]: t("choiceExportFormat.nmbrs"),
      [ChoiceExportFormatExtra.afas]: t("choiceExportFormat.afas"),
      [ChoiceExportFormatExtra.adp]: t("choiceExportFormat.adp"),
    }),
    [t]
  );

  return (
    <Select {...props}>
      {Object.values(ChoiceExportFormatExtra).map((format) => (
        <MenuItem key={format} value={format}>
          {formatLabels[format]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChoiceExportFormatSelectExtra;
