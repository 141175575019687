import { DependencyList, useEffect, useReducer } from "react";

interface State<T> {
  loading: boolean;
  error?: Error;
  value?: T;
}

type Action<T> = { value: T } | { error: Error };

// Inspired by https://dev.to/lukasmoellerch/a-hook-to-use-promise-results-2hfd
const useAsync = <T>(
  producer: () => Promise<T>,
  deps: DependencyList
): State<T> => {
  const reducer = (_: State<T>, action: Action<T>): State<T> => ({
    loading: false,
    ...action,
  });
  const [state, dispatch] = useReducer(reducer, { loading: true });
  useEffect(() => {
    let cancel = false;
    producer().then(
      (value) => cancel || dispatch({ value }),
      (error) =>
        cancel ||
        dispatch({
          error: error instanceof Error ? error : new Error(),
        })
    );
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return state;
};

export default useAsync;
