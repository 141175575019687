import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { JwtTokenModel } from "../../business/models";
import Authenticated from "../authentication/Authenticated";

interface TokenProps {
  jwt?: JwtTokenModel;
}

const PrivateRoute = ({
  render,
  ...rest
}: RouteProps & TokenProps): React.ReactElement => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Authenticated denyBehavior="RedirectToLogin">
          {render?.(props)}
        </Authenticated>
      )}
    />
  );
};

export default PrivateRoute;
