import { Details } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import { PropsWithChildren, memo, useEffect, useState } from "react";

import useNavigation from "../../business/redux/saga/navigation/useNavigation";
import { useTranslationStrict } from "../../globalization/i18n";
import AdminNavigationContext from "../admin/navigation/AdminNavigationContext";
import AppBar from "../layout/AppBar";
import Container from "../layout/Container";
import Footer, { FooterProps } from "../layout/Footer";
import Ribbon, { RibbonData } from "../layout/Ribbon";
import useStyles from "./BasePage.styles";

export interface BasePageData {
  ribbon: RibbonData;
  footer: FooterProps;
}

const BasePage = (props: PropsWithChildren<BasePageData>) => {
  const { ribbon, footer, children } = props;
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { currentNavigationNodeId, addToHistory } = useNavigation();

  const classes = useStyles();

  const [isNavigationEditing, setIsNavigationEditing] = useState(false);

  useEffect((): void => {
    if (currentNavigationNodeId === null) {
      return;
    }
    addToHistory(currentNavigationNodeId, (details: Details) => {
      enqueueSnackbar(
        `${t("Navigation:AddHistoryFailed")} (${
          details.detail || currentNavigationNodeId
        })`,
        {
          variant: "error",
        }
      );
    });
  }, [addToHistory, enqueueSnackbar, t, currentNavigationNodeId]);

  return (
    <AdminNavigationContext.Provider
      value={{
        isEditing: isNavigationEditing,
        setIsEditing: setIsNavigationEditing,
      }}
    >
      <Ribbon {...ribbon} />
      <AppBar />
      <Container>
        <div className={classes.root}>{children}</div>
      </Container>
      <Footer {...footer} />
    </AdminNavigationContext.Provider>
  );
};

export default memo(BasePage);
