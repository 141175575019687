import { AsyncSingleValue } from "async-lifecycle-saga";

import { UserModel } from "../../../../models";

export enum AdminUserDeleteStatus {
  failed = "failed",
  doesNotExist = "doesNotExist",
  succeeded = "succeeded",
}

export interface AdminUserDeleteRequestResult {
  status: AdminUserDeleteStatus;
}

export type AdminUsersInviteStatus = "apiError" | "emailNotSent" | "success";

export interface AdminUsersInviteRequestResult {
  status: AdminUsersInviteStatus;
}

export enum AdminUsersInviteAllNewStatus {
  failed = "failed",
  succeeded = "succeeded",
}

export interface AdminUsersInviteAllNewRequestResult {
  status: AdminUsersInviteAllNewStatus;
}

export interface AdminUsersStoreModel {
  add: AsyncSingleValue<UserModel>;
  delete: AsyncSingleValue<AdminUserDeleteRequestResult>;
  invite: AsyncSingleValue<AdminUsersInviteRequestResult>;
  inviteAllNew: AsyncSingleValue<AdminUsersInviteAllNewRequestResult>;
  list: AsyncSingleValue<UserModel[]>;
  listIncludeArchived: boolean;
  update: AsyncSingleValue<UserModel>;
}
