import {
  AsyncResponse,
  Details,
  Success,
} from "async-lifecycle-saga/dist/models";

import { asyncDownloadGet, asyncGet } from "../../../api";
import { OrganizationModel } from "../../../models";
import { OrganizationModelJson } from "./models";

const parseAppStyles = ({
  appStyles,
  ...organization
}: OrganizationModelJson): OrganizationModel => ({
  ...organization,
  appStyles: JSON.parse(appStyles),
});

export const organizationGetter = (anonymous: boolean) =>
  asyncGet<OrganizationModel>("/api/organization", anonymous).then(
    (response) =>
      (response.status === 200
        ? ({
            ...response,
            body: parseAppStyles(
              (response as Success<OrganizationModelJson>).body
            ),
          } as Success<OrganizationModel>)
        : (response as Details)) as AsyncResponse<OrganizationModel>
  );

export const organizationPrivacyStatementDownloadGetter = (
  organizationId: number
) =>
  asyncDownloadGet(
    `/api/organization/${organizationId}/privacy-statement`,
    true
  );
