import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { TwoFactorTokenModel } from "../../business/models";
import { StoreModel } from "../../business/redux/saga/models";

interface TokenProps {
  googleAuthenticator?: TwoFactorTokenModel;
}

const TwoFactorRoute = ({
  render,
  googleAuthenticator,
  ...rest
}: RouteProps & TokenProps): React.ReactElement => {
  return (
    <Route
      {...rest}
      render={
        googleAuthenticator
          ? render
          : (): React.ReactElement => (
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            )
      }
    />
  );
};

export default connect(
  ({ session: { googleAuthenticator } }: StoreModel): TokenProps => ({
    googleAuthenticator,
  })
)(TwoFactorRoute);
