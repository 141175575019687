import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useSingle } from "async-lifecycle-hooks";
import { AsyncSingleValue, asyncIsValid } from "async-lifecycle-saga";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  DebitCard,
  DebitCardResponse,
} from "../../business/personal/debitCard";
import { debitCardCell } from "../../business/redux/saga/personal/cells";
import { useTranslationStrict } from "../../globalization/i18n";

const DebitCardRequest = () => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const request = useCallback(() => {
    dispatch(debitCardCell.create({}));
  }, [dispatch]);

  return (
    <>
      <Typography variant="h6">
        {t("Alacarte:Je hebt nog geen Groene Kaart")}
      </Typography>
      <Button onClick={request} color="primary" autoFocus>
        {t("Alacarte:Groene Kaart activeren")}
      </Button>
    </>
  );
};

const DebitCardDisplay = ({ debitCard }: { debitCard: DebitCard }) => {
  const [t] = useTranslationStrict();
  const [showPin, setShowPin] = React.useState(false);

  return (
    <>
      <Typography variant="h6">
        {t("Alacarte:De gegevens van jouw Groene Kaart")}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell scope="row" variant="head">
              {t("Alacarte:Groene Kaart nummer")}
            </TableCell>
            <TableCell>{debitCard.number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row" variant="head">
              {t("Alacarte:Groene Kaart PIN code")}
            </TableCell>
            <TableCell>
              {showPin ? (
                debitCard.code
              ) : (
                <Button
                  onClick={() => setShowPin(true)}
                  variant="text"
                  color="secondary"
                >
                  {t("Alacarte:PIN code tonen")}
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const DebitCardSwitch = ({
  asyncValue,
}: {
  asyncValue: AsyncSingleValue<DebitCardResponse>;
}) => {
  switch (true) {
    case Boolean(asyncValue.value?.debitCard):
      return <DebitCardDisplay debitCard={asyncValue.value!.debitCard!} />;
    case asyncIsValid(asyncValue):
      return <DebitCardRequest />;
    default:
      return <CircularProgress />;
  }
};

const DebitCardView = () => {
  const debitCard = useSingle(debitCardCell);

  return (
    <section>
      <DebitCardSwitch asyncValue={debitCard} />
    </section>
  );
};

export default DebitCardView;
