export interface WlzAdviceCase {
  id?: number;
  employeeId: number;
  title: string;
  yearCalculation: Date;
  couple: boolean;
  note: string;
  archive: boolean;
  subjects: [WlzPerson] | [WlzPerson, WlzPerson];
  calculations: Record<string, number>;
}

export const wlzAdviceCaseDefault = (employeeId: number): WlzAdviceCase => ({
  employeeId,
  title: "",
  yearCalculation: new Date(),
  couple: true,
  note: "",
  archive: false,
  subjects: [wlzPersonDefault(0)],
  calculations: {},
});

export interface WlzPerson {
  id?: number;
  caseId: number;
  name: string;
  dateOfBirth: Date;
  aowBenefit: number;
  pension: number;
  profitEnterprise: number;
  incomeOther: number;
  wozValueResidence: number;
  mortgage: number;
  mortgageInterest: number;
  healthcareDeduction: number;
  giftsDeduction: number;
  savingsCredit: number;
  investments: number;
  wageTaxWithholding: number;
  dividendTaxWithheld: number;
  incomeTaxAssessmentProvisional: number;
  notes: string;
}

export const wlzPersonDefault = (caseId: number): WlzPerson => ({
  caseId,
  name: "",
  dateOfBirth: new Date(),
  aowBenefit: 0,
  pension: 0,
  profitEnterprise: 0,
  incomeOther: 0,
  wozValueResidence: 0,
  mortgage: 0,
  mortgageInterest: 0,
  healthcareDeduction: 0,
  giftsDeduction: 0,
  savingsCredit: 0,
  investments: 0,
  wageTaxWithholding: 0,
  dividendTaxWithheld: 0,
  incomeTaxAssessmentProvisional: 0,
  notes: "",
});
