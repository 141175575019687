import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { mobileScreen } from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    ...mobileScreen({
      gridBreadCrumbs: {
        "& *": {
          marginBottom: theme.spacing(),
          marginTop: theme.spacing(),
        },
      },
      gridProfileCard: {
        marginBottom: theme.spacing() * 2,
        marginTop: theme.spacing() * 2,
      },
    }),
    gridBreadCrumbs: {
      gridArea: "1 / 2 / span 1 / span 2",
    },
    gridNavigation: {
      gridArea: "1 / 1 / span 2 / span 1",
    },
  })
);

export default useStyles;
