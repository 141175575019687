import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { datacockpitEmployeeDataPatchCell } from "../../../business/redux/saga/datacockpit/cells";
import { useEmployeeData } from "../../../business/redux/saga/datacockpit/hooks";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  onCancel: () => void;
}

const EmployeeDataEditActions = (props: Props) => {
  const { onCancel } = props;

  // Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslationStrict();

  // Data
  const { changed, filter } = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData
  );
  const employeeData = useEmployeeData();

  // Event handlers
  const saveChanges = () => {
    dispatch(
      datacockpitEmployeeDataPatchCell.require(undefined, {
        onFail: () => {
          enqueueSnackbar(t("Datacockpit:Opslaan is mislukt."), {
            variant: "error",
          });
        },
        onSuccess: () => {
          enqueueSnackbar(t("Datacockpit:Aanpassingen zijn opgeslagen."), {
            variant: "success",
          });
        },
      })
    );
  };

  const disabled =
    !changed || employeeData.some((ed) => ed.invalid) || !filter.referenceDate;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={saveChanges}
        >
          {t("Common:Save")}
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={onCancel}>
          {t("Common:Cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmployeeDataEditActions;
