import { PropsWithChildren, memo, useState } from "react";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";

import {
  BreadCrumbNodeModel,
  NavigationRootModel,
} from "../../business/models";
import { useSession } from "../../business/redux/saga/admin/hooks";
import { useTranslationStrict } from "../../globalization/i18n";
import { RouterContext, selectMenuNavigation } from "../../utils/routes";
import { isAdminRole } from "../../utils/SecurityUtils";
import SideMenuEditor from "../admin/navigation/sideMenu/SideMenuEditor";
import { AccordionMenu, NavigationMenuContext } from "../navigation";
import BreadCrumbs, { BreadCrumbsProps } from "../navigation/BreadCrumbs";
import ProfileCard from "../profile/ProfileCard";
import BasePage, { BasePageData } from "./BasePage";
import useStyles from "./PrivatePage.styles";

export type PrivatePageProps = BasePageData & {
  breadCrumbs?: BreadCrumbsProps;
  menu?: NavigationRootModel;
};

const PrivatePage = (props: PropsWithChildren<PrivatePageProps>) => {
  const { menu, children, ...baseProps } = props;
  const [t] = useTranslationStrict();
  const {
    session: { appRole },
  } = useSession();

  const classes = useStyles();

  const menuItems = menu ? menu.items : [];
  const navigationItems = useSelector(selectMenuNavigation(t))?.items || [];

  const createCrumbs = (routeProps: RouteProps): BreadCrumbNodeModel[] => {
    const crumbs: BreadCrumbNodeModel[] = [{ url: "/", caption: "Dashboard" }];

    if (routeProps.location && routeProps.location.pathname === "/profile") {
      crumbs.push({ url: "/profile", caption: t("Common:MyData") });
    }

    const allItems = menuItems.concat(navigationItems);
    for (let i = 0; i < allItems.length; i += 1) {
      const main = allItems[i];
      if (routeProps.location && main.route === routeProps.location.pathname) {
        crumbs.push({ url: main.route, caption: main.title });
      }

      for (let j = 0; main.children && j < main.children.length; j += 1) {
        const sub = main.children[j];
        if (routeProps.location && sub.route === routeProps.location.pathname) {
          crumbs.push({ url: main.route, caption: main.title });
          crumbs.push({ url: sub.route, caption: sub.title });
        }

        for (let k = 0; sub.children && k < sub.children.length; k += 1) {
          const subsub = sub.children[k];
          if (
            routeProps.location &&
            subsub.route === routeProps.location.pathname
          ) {
            crumbs.push({ url: main.route, caption: main.title });
            crumbs.push({ url: sub.route, caption: sub.title });
            crumbs.push({ url: subsub.route, caption: subsub.title });
          }
        }
      }
    }

    return crumbs;
  };

  const [routeOpen, setRouteOpen] = useState<string | null>(null);

  return (
    <RouterContext.Consumer>
      {(routeProps): JSX.Element => (
        <BasePage {...baseProps}>
          <div className={classes.gridBreadCrumbs}>
            <BreadCrumbs crumbs={createCrumbs(routeProps)} />
          </div>
          {children}
          <div className={classes.gridNavigation}>
            <NavigationMenuContext.Provider value={{ routeOpen, setRouteOpen }}>
              <ProfileCard />
              <AccordionMenu />
            </NavigationMenuContext.Provider>
            {isAdminRole(appRole) && <SideMenuEditor />}
          </div>
        </BasePage>
      )}
    </RouterContext.Consumer>
  );
};

export default memo(PrivatePage);
