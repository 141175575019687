import { combineReducers } from "redux";

import {
  microsoft365LoginUriCell,
  microsoft365LogoutUriCell,
  microsoft365TokensCell,
} from "./cells";

export default combineReducers({
  loginUri: microsoft365LoginUriCell.reducer,
  logoutUri: microsoft365LogoutUriCell.reducer,
  tokens: microsoft365TokensCell.reducer,
});
