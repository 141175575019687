import { Button } from "@material-ui/core";
import { Success } from "async-lifecycle-saga/dist/models";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FileResponse } from "../../business/api";
import {
  organizationCell,
  organizationPrivacyStatementDownloadCell,
} from "../../business/redux/saga/organization";

const PrivacyStatementButton = () => {
  const dispatch = useDispatch();
  const organization = useSelector(organizationCell.select).value;

  const handleClick = useCallback(() => {
    if (organization?.id) {
      dispatch(
        organizationPrivacyStatementDownloadCell.require(organization.id, {
          onSuccess: ({ body: { data, name } }: Success<FileResponse>) => {
            dispatch({
              type: organizationPrivacyStatementDownloadCell.events.invalidate,
            });
            FileSaver.saveAs(data, name);
          },
        })
      );
    }
  }, [dispatch, organization?.id]);

  return (
    <Button
      disabled={!Boolean(organization?.id)}
      color="primary"
      type="button"
      variant="text"
      onClick={handleClick}
    >
      Privacy Statement
    </Button>
  );
};

export default PrivacyStatementButton;
