import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { dangerButton } from "../../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    dangerButton: {
      ...dangerButton(theme),
    },
  })
);

export default useStyles;
