import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    display: "inline-flex",
  },
  root: {
    gridColumn: 2,
    gridRow: 2,
    marginBottom: 2 * theme.spacing(),
    minWidth: 650,
    padding: 24,
  },
  paragraph: {
    marginBottom: 2 * theme.spacing(),
  },
}));

export default useStyles;
