import { IconButton, Theme, Tooltip } from "@material-ui/core";
import { DeleteForever as DeleteForeverIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";
import { ContentNodeDeleteConfirmDialog } from "./";

const useStyles = makeStyles((theme: Theme) => ({
  danger: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  contentNodeId: number;
  contentNodeTitle: string;
  disabled?: boolean;
}

const ContentNodeDeleteButton = (props: Props) => {
  const { contentNodeId, contentNodeTitle, disabled } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title={t("Common:Delete")}>
        <IconButton
          disabled={disabled}
          onClick={() => setConfirmDialogOpen(true)}
          classes={{ root: classes.danger }}
          size="small"
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      {confirmDialogOpen && (
        <ContentNodeDeleteConfirmDialog
          nodeId={contentNodeId}
          title={contentNodeTitle}
          open
          onClose={() => setConfirmDialogOpen(false)}
        />
      )}
    </>
  );
};

export default ContentNodeDeleteButton;
