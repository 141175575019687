import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { memo, useCallback, useEffect, useState } from "react";

import { formatPercentage, parsePercentage } from "../../utils/NumberUtils";

interface Props {
  name?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const useStyles = makeStyles({
  right: {
    fontWeight: "inherit",
    "& input": { textAlign: "right" },
  },
});

const percentageWith3Decimals = (value: number): number =>
  Math.max(0, Math.min(1, Math.round(1_000 * value) / 100_000));

const PercentageInput = memo(({ onChange, value, ...props }: Props) => {
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e): void => {
      const valueAsNumber = percentageWith3Decimals(
        parsePercentage(e.target.value)
      );
      onChange({
        ...e,
        target: {
          ...e.target,
          name: e.target.name,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: valueAsNumber as any as string,
          valueAsNumber,
        },
      });
    },
    [onChange]
  );
  const { right } = useStyles();
  const [localValue, updateLocal] = useState("");
  useEffect((): void => updateLocal(formatPercentage(100 * value)), [value]);
  const handleChange = useCallback(
    (e): void => updateLocal(e.target.value),
    []
  );
  return (
    <span style={{ display: "inline-block", whiteSpace: "nowrap" }}>
      <TextField
        classes={{ root: right }}
        value={localValue}
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span
        style={{
          display: "inline-block",
          width: "1em",
          height: 48,
          paddingTop: 20,
        }}
      >
        &#160;%
      </span>
    </span>
  );
});

export default PercentageInput;
