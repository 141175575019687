import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    borderStyle: "dashed",
    borderWidth: "1px",
    display: "inline-flex",
    height: "2em",
    lineHeight: "1.75",
    position: "relative",
    maxHeight: "2em",
    minWidth: "2em",
    top: "1em",
  },
  dropping: {
    backgroundColor: theme.palette.secondary.light,
    transition: "width 0.25s ease-in",
    width: "5em",
  },
  loading: {
    minHeight: "2.5em",
    padding: "0.5em",
  },
  main: {},
  open: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 0.8,
  },
  sub: {
    marginLeft: "1em",
    marginBottom: "1em",
    minWidth: "3em",
  },
}));
