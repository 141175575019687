import {
  Button,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  SaveAlt,
} from "@material-ui/icons";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import FileSaver from "file-saver";
import { useSnackbar } from "notistack";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { FileResponse } from "../../../business/api";
import { DataDefinitionModel, ModuleOption } from "../../../business/models";
import {
  adminChoiceDefinitionsListCell,
  adminChoiceEmployeeApproveCell,
  adminChoiceEmployeeExportMutationsCell,
  adminChoiceEmployeeListCell,
} from "../../../business/redux/saga/admin/choices/cells";
import { EmployeeChoiceSetModel } from "../../../business/redux/saga/admin/choices/models";
import { adminEmployersListCell } from "../../../business/redux/saga/admin/employers/cells";
import { dmsDocumentSaveCell } from "../../../business/redux/saga/dms/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { personalDefinitionsCell } from "../../../business/redux/saga/personal/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { formatEuro } from "../../../utils/NumberUtils";
import Info from "../../ux/Info";
import InfoBase from "../../ux/InfoBase";
import useStyles from "../admin/AdminPage.styles";

const employersAllValue = -1;

const EmptyRow = memo((): JSX.Element => {
  const [t] = useTranslationStrict();
  return (
    <TableRow>
      <TableCell colSpan={2}>{t("Alacarte:NoPendingChoices")}</TableCell>
    </TableRow>
  );
});

const iconStyle = { width: 14, height: 14 };
interface ApproveProps {
  dataDefinitions: DataDefinitionModel[];
  employeeChoiceSet: EmployeeChoiceSetModel;
  approve(id: number, approved: boolean): void;
  benefitCalculation: ModuleOption;
  benefitSharing: boolean;
}
const ApproveRow = memo(
  ({
    dataDefinitions,
    employeeChoiceSet,
    approve,
    benefitCalculation,
    benefitSharing,
  }: ApproveProps): JSX.Element => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleToggle = useCallback(
      (): void => setOpen(!open),
      [open, setOpen]
    );
    const handleOk = useCallback(
      (): void => approve(employeeChoiceSet.employee.id, true),
      [approve, employeeChoiceSet.employee.id]
    );
    const handleNok = useCallback(
      (): void => approve(employeeChoiceSet.employee.id, false),
      [approve, employeeChoiceSet.employee.id]
    );
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslationStrict();

    const downloadChoiceDocument = (): void => {
      dispatch(
        dmsDocumentSaveCell.require(
          `/api/choice/admin/download/${employeeChoiceSet.id}`,
          {
            onFail: (details: Details) => {
              enqueueSnackbar(details.detail || employeeChoiceSet.id, {
                variant: "error",
              });
            },
            onSuccess: ({ body: { data, name } }: Success<FileResponse>) => {
              FileSaver.saveAs(data, name);
            },
          }
        )
      );
    };

    return (
      <>
        <TableRow>
          <TableCell className={classes.evenColumn}>
            <IconButton onClick={handleToggle}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>{" "}
            {employeeChoiceSet.employee.fullName}
          </TableCell>
          <TableCell className={classes.rightColumn}>
            <Button variant="contained" color="default" onClick={handleNok}>
              {t("Alacarte:Reject")}
            </Button>{" "}
            <Button variant="contained" color="primary" onClick={handleOk}>
              {t("Alacarte:Approve")}
            </Button>
          </TableCell>
        </TableRow>
        {open && (
          <TableRow className={classes.expanded}>
            <TableCell className={classes.evenColumn}>
              <h2>{t("Alacarte:Purposes")}</h2>
              {employeeChoiceSet.choices
                .filter((choice): boolean => choice.amount > 0)
                .map((choice): JSX.Element | false => {
                  const dataDefinition = dataDefinitions.find(
                    ({ id }): boolean =>
                      id === choice.definition.dataDefinitionId
                  );
                  if (
                    !dataDefinition ||
                    dataDefinition.choiceRole !== "destination"
                  ) {
                    return false;
                  }

                  const fromString =
                    choice.definition.interval === "fourWeekly"
                      ? (choice.startMonth &&
                          t("Alacarte:SalaryPeriod", {
                            salaryPeriod: -choice.startMonth,
                          })) ||
                        t("Alacarte:directly")
                      : t(`Alacarte:Month${choice.startMonth || 0}`);
                  const toString =
                    choice.definition.interval === "fourWeekly"
                      ? (choice.endMonth &&
                          t("Alacarte:SalaryPeriod", {
                            salaryPeriod: -choice.endMonth,
                          })) ||
                        t("Alacarte:yearEnd")
                      : t(`Alacarte:Month${choice.endMonth || 0}`);
                  return (
                    <React.Fragment key={choice.definition.dataDefinitionId}>
                      <h3>{choice.definition.label}</h3>
                      <dl className={classes.chosenList}>
                        <dt>{t("Alacarte:Amount")}:</dt>
                        <dd>{formatEuro(choice.amount)}</dd>
                        <dt>{t("Alacarte:Payrolling")}:</dt>
                        <dd>
                          {t(
                            `Alacarte:${choice.repeating ? "Repeat" : "Once"}`
                          )}
                        </dd>
                        <dt>{t("Alacarte:FromDate")}:</dt>
                        <dd>{fromString}</dd>
                        <dt>{t("Alacarte:ToDate")}:</dt>
                        <dd>{toString}</dd>
                      </dl>
                    </React.Fragment>
                  );
                })}
              {benefitCalculation !== ModuleOption.off && (
                <>
                  <h2>{t("Alacarte:Benefit")}</h2>
                  <dl className={classes.chosenList}>
                    <dt>{t("Alacarte:Employee")}:</dt>
                    <dd>{formatEuro(employeeChoiceSet.employeeBenefit)}</dd>
                    <dt>
                      {t("Alacarte:Employer")}:
                      {benefitSharing &&
                        employeeChoiceSet.employerBenefit > 0 && (
                          <Info inverse>
                            <ReactMarkdown>
                              {t("Alacarte:BenefitSharing")}
                            </ReactMarkdown>
                          </Info>
                        )}
                    </dt>
                    <dd>{formatEuro(employeeChoiceSet.employerBenefit)}</dd>
                  </dl>
                </>
              )}
            </TableCell>
            <TableCell className={classes.evenColumn}>
              <h2>{t("Alacarte:Sources")}</h2>
              {employeeChoiceSet.choices
                .filter((choice): boolean => choice.amount > 0)
                .map((choice): JSX.Element | false => {
                  const dataDefinition = dataDefinitions.find(
                    ({ id }): boolean =>
                      id === choice.definition.dataDefinitionId
                  );
                  if (
                    !dataDefinition ||
                    dataDefinition.choiceRole !== "source"
                  ) {
                    return false;
                  }

                  return (
                    <React.Fragment key={choice.definition.dataDefinitionId}>
                      <h3>{choice.definition.label}</h3>
                      <dl className={classes.chosenList}>
                        <dt>{t("Alacarte:Amount")}:</dt>
                        <dd>{formatEuro(choice.amount)}</dd>
                      </dl>
                    </React.Fragment>
                  );
                })}
              <h2>{t("Alacarte:Overview")}</h2>
              <a
                href="."
                onClick={(e): void => {
                  e.preventDefault();
                  downloadChoiceDocument();
                }}
              >
                {t("Alacarte:Download")} <SaveAlt style={iconStyle} />
              </a>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }
);

const ALaCarteApprovePage = () => {
  const [t] = useTranslationStrict();
  const classes = useStyles();

  const dispatch = useDispatch();
  const employeeChoiceSets = useSelector(
    (store: StoreModel): EmployeeChoiceSetModel[] =>
      store.admin.choice.employee.list.value || []
  );
  const dataDefinitions = useSelector(
    (store: StoreModel): DataDefinitionModel[] =>
      store.personal.definitions.value || []
  );
  const employers = useSelector(
    (store: StoreModel) => store.admin.employers.list.value
  );
  const employersFiltered = useMemo(
    () => employers?.filter((e) => e.isSpecial),
    [employers]
  );
  useEffect((): void => {
    dispatch(adminChoiceDefinitionsListCell.require());
    dispatch(adminChoiceEmployeeListCell.require());
    dispatch(adminEmployersListCell.require());
    dispatch(personalDefinitionsCell.require());
  }, [dispatch]);

  const [employerSelectedId, setEmployerSelectedId] =
    useState<number>(employersAllValue);
  const employerSelected = useMemo(() => {
    if (!employersFiltered) {
      return undefined;
    }
    if (employerSelectedId === employersAllValue) {
      return undefined;
    }
    const employerSelected = employersFiltered.find(
      (e) => e.id === employerSelectedId
    );
    return employerSelected;
  }, [employersFiltered, employerSelectedId]);

  const handleApprove = useCallback(
    (id: number, approved: boolean) => {
      dispatch(
        adminChoiceEmployeeApproveCell.require({ employeeIds: [id], approved })
      );
    },
    [dispatch]
  );
  const handleApproveAll = useCallback(() => {
    const employeeIds = employeeChoiceSets.map(
      (employeeChoiceSet): number => employeeChoiceSet.employee.id
    );
    dispatch(
      adminChoiceEmployeeApproveCell.require({ employeeIds, approved: true })
    );
  }, [dispatch, employeeChoiceSets]);
  const handleEmployerChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setEmployerSelectedId(Number(e.target.value));
    },
    []
  );
  const handleExport = useCallback(() => {
    dispatch(
      adminChoiceEmployeeExportMutationsCell.require(
        employerSelected?.name || "",
        {
          onSuccess: ({ body: { data, name } }) => {
            FileSaver.saveAs(data, name);
          },
        }
      )
    );
  }, [dispatch, employerSelected]);

  return (
    <div className={classes.gridWideContent}>
      <Card>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.evenColumn}>
                  {t("Alacarte:Name")}
                </TableCell>
                <TableCell className={classes.rightColumn}>
                  {t("Alacarte:Name")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeChoiceSets.length === 0 && <EmptyRow />}
              {employeeChoiceSets.map((employeeChoiceSet) => (
                <ApproveRow
                  approve={handleApprove}
                  dataDefinitions={dataDefinitions}
                  employeeChoiceSet={employeeChoiceSet}
                  key={employeeChoiceSet.employee.id}
                  benefitCalculation={
                    employerSelected?.benefitCalculation || ModuleOption.default
                  }
                  benefitSharing={
                    (employerSelected?.benefitShared || 0) > 0 ||
                    (employerSelected?.benefitSharedDispensated || 0) > 0 ||
                    (employerSelected?.benefitSharedTaxExempt || 0) > 0
                  }
                />
              ))}
              <TableRow>
                <TableCell className={classes.evenColumn}>&nbsp;</TableCell>
                <TableCell className={classes.rightColumn}>
                  {(!employersFiltered || employersFiltered.length <= 1) && (
                    <InfoBase
                      renderButton={(onClick): ReactNode => (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={onClick}
                        >
                          {t("Alacarte:PayrollSync")}
                        </Button>
                      )}
                    >
                      <ReactMarkdown>
                        {t("Alacarte:PayrollSyncInfo")}
                      </ReactMarkdown>
                    </InfoBase>
                  )}
                  {employersFiltered && employersFiltered.length > 1 && (
                    <Select
                      className={classes.employerSelect}
                      defaultValue={employersAllValue}
                      onChange={handleEmployerChange}
                    >
                      <MenuItem value={employersAllValue}>
                        {t("Common:All")}
                      </MenuItem>
                      {employersFiltered?.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}{" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleExport}
                  >
                    {t("Alacarte:ExportMutations")}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApproveAll}
                    disabled={employeeChoiceSets.length === 0}
                  >
                    {t("Alacarte:ApproveAll")}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default memo(ALaCarteApprovePage);
