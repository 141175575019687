import { Card, CardContent } from "@material-ui/core";

import { ContractsExportFilter } from "../../../business/admin/contracts/models";
import ContractsExportButton from "../../admin/contracts/ContractsExportButton";
import ContractsExportTable from "../../admin/contracts/ContractsExportTable";
import useStyles from "./AdminPage.styles";

const ContractsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <Card style={{ padding: 24 }}>
        <ContractsExportTable />
        <CardContent style={{ textAlign: "right" }}>
          <ContractsExportButton
            request={{ filter: ContractsExportFilter.historical }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default ContractsPage;
