import { IconButton } from "@material-ui/core";
import {
  Add as AddIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { memo, useCallback, useContext, useState } from "react";

import { NavigationNodeModel } from "../../../../business/models";
import { useNavigation } from "../../../../business/redux/saga/admin/hooks";
import { useTranslationStrict } from "../../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../../ux/SnackbarErrorMessage";
import AdminNavigationContext from "../AdminNavigationContext";
import CreateRootNavigationDialog from "../CreateRootNavigationDialog";

const SideMenuEditor = () => {
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const { addNodeToRoot, getNavigation } = useNavigation();
  const {
    isEditing: isNavigationEditing,
    setIsEditing: setIsNavigationEditing,
  } = useContext(AdminNavigationContext);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleAddClick = useCallback((): void => {
    setCreateDialogOpen(true);
  }, [setCreateDialogOpen]);

  const handleEditClick = useCallback((): void => {
    setIsNavigationEditing(!isNavigationEditing);
  }, [isNavigationEditing, setIsNavigationEditing]);

  const handleCreateClose = useCallback((): void => {
    setCreateDialogOpen(false);
  }, [setCreateDialogOpen]);

  const handleCreateConfirm = useCallback(
    (node: NavigationNodeModel) => {
      addNodeToRoot(
        "aside",
        node,
        showErrorMessageFromDetails(t("Admin:NavigationNodeAddToRootError")),
        () => {
          handleCreateClose();
          enqueueSnackbar(t("Admin:NavigationNodeAddToRootSuccess"), {
            variant: "success",
          });
          getNavigation("aside");
        }
      );
    },
    [
      addNodeToRoot,
      enqueueSnackbar,
      getNavigation,
      handleCreateClose,
      showErrorMessageFromDetails,
      t,
    ]
  );

  return (
    <>
      <IconButton color="inherit" onClick={handleAddClick}>
        <AddIcon />
      </IconButton>
      <IconButton color="inherit" onClick={handleEditClick}>
        {isNavigationEditing ? <CheckIcon /> : <EditIcon />}
      </IconButton>
      <CreateRootNavigationDialog
        open={createDialogOpen}
        location="aside"
        onClose={handleCreateClose}
        onConfirm={handleCreateConfirm}
      />
    </>
  );
};

export default memo(SideMenuEditor);
