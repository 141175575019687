import { put, takeEvery } from "redux-saga/effects";

import { navigationMenuCell, navigationTreeCell } from "../../navigation/cells";
import {
  adminNavigationContentAddCell,
  adminNavigationContentDeleteCell,
  adminNavigationContentDocumentUploadCell,
  adminNavigationContentShiftDownCell,
  adminNavigationContentShiftUpCell,
  adminNavigationContentUpdateCell,
  adminNavigationNodeAddChildCell,
  adminNavigationNodeAddToRootCell,
  adminNavigationNodeDeleteCell,
  adminNavigationNodePruneCell,
  adminNavigationNodeUpdateCell,
} from "./cells";

const adminNavigationChangedEvents = [
  adminNavigationContentAddCell.events.success,
  adminNavigationContentDocumentUploadCell.events.success,
  adminNavigationContentDeleteCell.events.success,
  adminNavigationContentShiftDownCell.events.success,
  adminNavigationContentShiftUpCell.events.success,
  adminNavigationContentUpdateCell.events.success,
  adminNavigationNodeAddChildCell.events.success,
  adminNavigationNodeAddToRootCell.events.success,
  adminNavigationNodeDeleteCell.events.success,
  adminNavigationNodePruneCell.events.success,
  adminNavigationNodeUpdateCell.events.success,
];

const adminNavigationSagas = [
  ...adminNavigationContentAddCell.sagas,
  ...adminNavigationContentDeleteCell.sagas,
  ...adminNavigationContentDocumentUploadCell.sagas,
  ...adminNavigationContentShiftDownCell.sagas,
  ...adminNavigationContentShiftUpCell.sagas,
  ...adminNavigationContentUpdateCell.sagas,
  ...adminNavigationNodeAddChildCell.sagas,
  ...adminNavigationNodeAddToRootCell.sagas,
  ...adminNavigationNodeDeleteCell.sagas,
  ...adminNavigationNodePruneCell.sagas,
  ...adminNavigationNodeUpdateCell.sagas,
  takeEvery(adminNavigationChangedEvents, function* () {
    yield put({ type: navigationMenuCell.events.invalidate });
    yield put({ type: navigationTreeCell.events.invalidate });
    yield put(navigationMenuCell.require());
    yield put(navigationTreeCell.require());
  }),
];

export default adminNavigationSagas;
