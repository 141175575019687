import { Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { memo } from "react";
import { Link } from "react-router-dom";

import { BreadCrumbNodeModel } from "../../business/models";
import useStyles from "./BreadCrumbs.styles";

export interface BreadCrumbsProps {
  crumbs: BreadCrumbNodeModel[];
}

const BreadCrumbs = (props: BreadCrumbsProps) => {
  const { crumbs } = props;

  const classes = useStyles();

  return crumbs.length > 0 ? (
    <div className={classes.root}>
      {crumbs.map(({ url, caption }, i): (JSX.Element | false)[] => [
        i > 0 && (
          <Typography
            key={`arrow_${url}`}
            component="span"
            className={classes.arrow}
          >
            &nbsp;»&nbsp;
          </Typography>
        ),
        i < crumbs.length - 1 && (
          <Link
            key={`link_${url}`}
            to={url}
            className={
              i === 0
                ? classNames(classes.dashboard, classes.crumb)
                : classes.crumb
            }
          >
            {caption}
          </Link>
        ),
        i === crumbs.length - 1 && (
          <div key={`link_${url}`} className={classes.clear}>
            <Link to={url} className={classes.current}>
              <Typography component="h4" classes={{ root: classes.h4 }}>
                {caption}
              </Typography>
            </Link>
          </div>
        ),
      ])}
    </div>
  ) : null;
};

export default memo(BreadCrumbs);
