import { Card, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useTranslationStrict } from "../../globalization/i18n";
import useInterval from "../../utils/useInterval";

interface Props {
  intervalAction: () => void;
  intervalActionDelay: number;
  intervalProgressDelay: number;
}

const RefreshTimer = (props: Props) => {
  const { intervalAction, intervalActionDelay, intervalProgressDelay } = props;
  const [t] = useTranslationStrict();

  const [intervalActionLast, setIntervalActionLast] = useState<Date>(
    new Date()
  );
  const [progress, setProgress] = useState<number>(100);
  const intervalActionSymbol = useInterval(intervalActionDelay);
  useEffect(() => {
    if (intervalActionSymbol) {
      intervalAction();
      setIntervalActionLast(new Date());
    }
  }, [intervalAction, intervalActionSymbol]);

  const intervalProgressSymbol = useInterval(intervalProgressDelay);
  useEffect(() => {
    if (intervalProgressSymbol) {
      const percentageRemaining = Math.round(
        100 -
          ((new Date().getTime() - intervalActionLast.getTime()) /
            intervalActionDelay) *
            100
      );
      setProgress(percentageRemaining);
    }
    // eslint-disable-next-line
  }, [intervalProgressSymbol]);

  return (
    <Card
      style={{
        display: "flex",
        gap: "0.25em",
        marginBottom: "0.5em",
        padding: "0.5em",
        width: "11em",
      }}
    >
      <Typography style={{ margin: "auto 2em auto 0", textAlign: "center" }}>
        {t("Admin:Verversen...")}
      </Typography>
      <CircularProgress variant="determinate" value={Math.round(progress)} />
    </Card>
  );
};

export default RefreshTimer;
