import { Field, FieldProps } from "formik";
import React, {
  MutableRefObject,
  ReactNode,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactMde from "react-mde";

interface ContentPageTextMarkdownEditorFieldProps {
  fieldName: string;
  onPreview: (markdown: string) => Promise<ReactNode>;
  textCurrent: MutableRefObject<string>;
  textCursorPosition?: MutableRefObject<number>;
}

const ContentPageTextMarkdownEditorField = ({
  fieldName,
  onPreview,
  textCurrent,
  textCursorPosition,
}: ContentPageTextMarkdownEditorFieldProps) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const refs = {
    textarea: useRef<HTMLTextAreaElement>(null),
  };
  const textareaRef = refs.textarea;

  useEffect(() => {
    if (!textareaRef.current) {
      return;
    }

    const textarea = textareaRef.current;
    const update = () => {
      textCurrent.current = textarea.value;
      if (textCursorPosition) {
        textCursorPosition.current = textarea.selectionStart;
      }
    };
    update();

    textarea.addEventListener("keyup", update);
    textarea.addEventListener("mouseup", update);

    return () => {
      textarea.removeEventListener("keyup", update);
      textarea.removeEventListener("mouseup", update);
    };
  }, [textareaRef, textCurrent, textCursorPosition]);

  return (
    <Field id={fieldName} name={fieldName} type="string">
      {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
        const handleChange = (value: string) => {
          setFieldValue(fieldName, value);
        };

        return (
          <ReactMde
            generateMarkdownPreview={onPreview}
            onChange={handleChange}
            onTabChange={setSelectedTab}
            refs={refs}
            selectedTab={selectedTab}
            value={value ?? ""}
          />
        );
      }}
    </Field>
  );
};

export default memo(ContentPageTextMarkdownEditorField);
