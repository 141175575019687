import { combineReducers } from "redux";

import {
  announcementsAddCell,
  announcementsListPagedCell,
  announcementsRetireCell,
} from "./cells";

export default combineReducers({
  add: announcementsAddCell.reducer,
  listPaged: announcementsListPagedCell.reducer,
  retire: announcementsRetireCell.reducer,
});
