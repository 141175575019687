import { createActor, createForm } from "async-lifecycle-saga";

import { asyncDelete, asyncGet } from "../../../../api";
import { DownloadModel } from "../../../../models";

export const adminPeekDownloadsCell = createForm<number, DownloadModel[]>({
  path: ["admin", "peek", "downloads"],
  api: (employeeId) => asyncGet(`/api/download/peek/list/${employeeId}`),
});

export const adminPeekDownloadRemoveCell = createActor<number, undefined>({
  path: ["admin", "peek", "download", "remove"],
  api: (id) => asyncDelete(`/api/download/admin/${id}`),
  invalidate: [adminPeekDownloadsCell.events],
});
