import { put } from "@redux-saga/core/effects";
import { createActor, createSingle } from "async-lifecycle-saga";
import { takeEvery } from "redux-saga/effects";

import { FileResponse } from "../../../../api";
import { WlzAdviceCase } from "../../../../personal/wlz/models";
import { StoreModel } from "../../models";
import { personalDownloadsCell } from "../cells";
import {
  personalWlzAdviceCaseReportDownload,
  personalWlzAdviceCaseUpsertRequire,
  personalWlzAdviceCasesRequire,
} from "./api";
import { WlzAdviceCaseReportRequest } from "./models";

const personalWlzAdviceCasesCell = createSingle<WlzAdviceCase[], StoreModel>({
  path: ["personal", "wlz", "adviceCases"],
  require: {
    api: personalWlzAdviceCasesRequire,
  },
});

const personalWlzAdviceCaseUpsertCell = createActor<
  WlzAdviceCase,
  never,
  StoreModel
>({
  path: ["personal", "wlz", "adviceCaseUpsert"],
  api: personalWlzAdviceCaseUpsertRequire,
  invalidate: [personalWlzAdviceCasesCell.events],
});

const personalWlzAdviceCaseReportDownloadCell = createActor<
  WlzAdviceCaseReportRequest,
  FileResponse,
  StoreModel
>({
  path: ["personal", "wlz", "adviceCaseReportDownload"],
  api: personalWlzAdviceCaseReportDownload,
  invalidate: [personalDownloadsCell.events],
});

const personalWlzSagas = [
  ...personalWlzAdviceCasesCell.sagas,
  ...personalWlzAdviceCaseUpsertCell.sagas,
  takeEvery(personalWlzAdviceCaseUpsertCell.events.success, function* () {
    yield put(personalWlzAdviceCasesCell.require());
  }),
  ...personalWlzAdviceCaseReportDownloadCell.sagas,
];

export {
  personalWlzAdviceCasesCell,
  personalWlzAdviceCaseUpsertCell,
  personalWlzAdviceCaseReportDownloadCell,
  personalWlzSagas,
};
