import { Action, Store } from "redux";

import { StoreModel } from "./saga/models";

export type StoreType = Store<StoreModel, Action<string>> & {
  dispatch: {};
};

let reflexoStore: StoreType | undefined;

const notInitialized = (): never => {
  throw new Error("Store not initialized.");
};
export const getReflexoStore = (): StoreType =>
  reflexoStore || notInitialized();

export const setReflexoStore = (store: StoreType) => {
  reflexoStore = store;
};
