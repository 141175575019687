import { Success } from "async-lifecycle-saga/dist/models";

import { asyncDownloadPost, asyncGet, asyncPut } from "../../../../api";
import { WlzAdviceCase, WlzPerson } from "../../../../personal/wlz/models";
import { WlzAdviceCaseReportRequest } from "./models";

interface WlzPersonDto extends Omit<WlzPerson, "dateOfBirth"> {
  dateOfBirth: string;
}

const mapPerson = (person: WlzPersonDto): WlzPerson => ({
  ...person,
  dateOfBirth: new Date(person.dateOfBirth),
});

interface WlzAdviceCaseDto
  extends Omit<WlzAdviceCase, "yearCalculation" | "subjects"> {
  yearCalculation: string;
  subjects: WlzPersonDto[];
}

const mapAdviceCase = (adviceCase: WlzAdviceCaseDto): WlzAdviceCase => ({
  ...adviceCase,
  yearCalculation: new Date(adviceCase.yearCalculation),
  subjects: adviceCase.subjects.map(mapPerson) as
    | [WlzPerson]
    | [WlzPerson, WlzPerson],
});

export const personalWlzAdviceCasesRequire = () =>
  asyncGet<WlzAdviceCaseDto[]>(`/api/personal/wlz/cases`).then((response) => {
    if (response.status === 200) {
      const success = response as Success<WlzAdviceCaseDto[]>;
      return {
        ...success,
        body: success.body.map(mapAdviceCase),
      };
    } else {
      return response;
    }
  });

export const personalWlzAdviceCaseUpsertRequire = (adviceCase: WlzAdviceCase) =>
  asyncPut(`/api/personal/wlz/cases/case`, adviceCase);

export const personalWlzAdviceCaseReportDownload = ({
  adviceCase,
  navigationNodeId,
}: WlzAdviceCaseReportRequest) =>
  asyncDownloadPost(
    `/api/personal/wlz/cases/case/report/${navigationNodeId}`,
    adviceCase
  );
