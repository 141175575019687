import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import QRCode from "qrcode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import speakeasy from "speakeasy";

import googleAuthenticatorLogoUrl from "../../../assets/GoogleAuthenticator.png";
import microsoftAuthenticatorLogoUrl from "../../../assets/MicrosoftAuthenticator.png";
import { useSession } from "../../../business/redux/saga/admin/hooks";
import { useTwoFactor } from "../../../business/redux/saga/authentication/twoFactor/hooks";
import { useTranslationStrict } from "../../../globalization/i18n";
import {
  clearTokens,
  getGAKToken,
  setRefreshToken,
} from "../../../utils/SecurityUtils";
import AuthenticationTwoFactorCreateSecret from "./AuthenticationTwoFactorCreateSecret";
import useStyles from "./AuthenticationTwoFactorSetupCard.styles";

const emptyString = "";

const TwoFactorAuthenticationSetupCard = () => {
  /* Translation, Styling, UX, Navigation */
  const [t] = useTranslationStrict();
  const { avatar, paragraph, root } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  /* Redux Store */
  const {
    session: { applicationName, user: { loginName } = {} },
  } = useSession();

  // Two-Factor Authenticator
  const { setupFinish, validateClientSide } = useTwoFactor();

  /* Secret */
  const [secret, setSecret] = useState<speakeasy.GeneratedSecret | null>(null);
  const [secretDataUrl, setSecretDataUrl] = useState<string>("");
  const secretText = useMemo(() => (secret ? secret.base32 : null), [secret]);
  useEffect(() => {
    if (secret) {
      QRCode.toDataURL(secret.otpauth_url as string, (error, imageData) => {
        if (error) {
          enqueueSnackbar(error, { variant: "error" });
          return;
        }
        setSecretDataUrl(imageData);
      });
    }
  }, [enqueueSnackbar, secret]);
  const generateSecret = useCallback((): void => {
    const secret = speakeasy.generateSecret({
      issuer: "Reflexo",
      length: 20,
      name: `${applicationName} (${loginName})`,
    });
    setSecret(secret);
  }, [applicationName, loginName]);

  /* Verification */
  const [tokenToVerify, setTokenToVerify] = useState<string>("");
  const [tokenToVerifyIsValid, setTokenToVerifyIsValid] =
    useState<boolean>(true);
  const handleTokenToVerifyChanged = (tokenToVerifyValue: string) => {
    setTokenToVerify(tokenToVerifyValue);
  };
  const handleVerify = useCallback((): void => {
    if (validateClientSide(secret!.hex, tokenToVerify)) {
      const request = {
        knowledgeTokenValue: getGAKToken() || emptyString,
        loginName: loginName || emptyString,
        totpSecret: secret!.base32,
        totpValue: tokenToVerify,
      };
      setupFinish(request, {
        onFail: (details) => {
          enqueueSnackbar(details.title, { variant: "error" });
        },
        onSuccess: ({ body: { status, jwt } }) => {
          if (status === "authenticated") {
            clearTokens();
            setRefreshToken(jwt!.refreshToken, jwt!.refreshTokenExpires);
            window.location.reload();
            history.push("/");
          } else {
            enqueueSnackbar(
              t("Authentication:TwoFactorAuthenticatorSecretSetupFailed"),
              { variant: "error" }
            );
          }
        },
      });
    } else {
      setTokenToVerifyIsValid(false);
    }
  }, [
    enqueueSnackbar,
    history,
    loginName,
    secret,
    setupFinish,
    t,
    tokenToVerify,
    validateClientSide,
  ]);

  /* Bootstrap */
  useEffect(() => {
    generateSecret();
  }, [generateSecret]);

  return (
    <Card classes={{ root }}>
      <CardHeader
        avatar={
          <>
            <Avatar src={googleAuthenticatorLogoUrl} />
            <Avatar src={microsoftAuthenticatorLogoUrl} />
          </>
        }
        classes={{ avatar }}
        title={t("User:TwoFactorAuthenticationTitle")}
        titleTypographyProps={{ variant: "h5" }}
      />
      <CardContent>
        <Typography className={paragraph} component="p">
          {t("Authentication:TwoFactorAuthenticationSetupBodyParagraph1", {
            applicationName,
          })}
        </Typography>
        <Typography className={paragraph} component="p">
          {t("User:TwoFactorAuthenticationGoogleAuthenticatorSettings")}
        </Typography>
        <Typography className={paragraph} component="p">
          {t("Authentication:TwoFactorAuthenticationSetupBodyParagraph2")}
        </Typography>
        <AuthenticationTwoFactorCreateSecret
          onSubmit={handleVerify}
          onTokenToVerifyChanged={handleTokenToVerifyChanged}
          secretDataUrl={secretDataUrl || emptyString}
          secretText={secretText || emptyString}
          tokenToVerify={tokenToVerify}
          tokenToVerifyIsValid={tokenToVerifyIsValid}
        />
      </CardContent>
    </Card>
  );
};

export default TwoFactorAuthenticationSetupCard;
