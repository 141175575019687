import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { range } from "lodash/fp";
import React from "react";

import { ChoicePeriodMode } from "../../business/models";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../globalization/i18n";
import { fourWeekPeriodsCompleteNow } from "../../utils/DateUtils";

const getEndMonthStart = (
  selectionMode: ChoicePeriodMode,
  startMonth?: number
) => {
  switch (selectionMode) {
    case "salaryPeriod":
      return startMonth !== undefined && startMonth < 0
        ? startMonth - 1
        : -fourWeekPeriodsCompleteNow - 2;
    case "monthly":
    default:
      return startMonth !== undefined && startMonth > 0
        ? startMonth + 1
        : new Date().getMonth() + 2;
  }
};

const getItemsStart = (selectionMode: ChoicePeriodMode, t: TFunctionStrict) => {
  switch (selectionMode) {
    case "salaryPeriod":
      return range(1, 14).map((n) => (
        <MenuItem key={-n} value={-n}>
          {t(`Alacarte:SalaryPeriod`, { salaryPeriod: n })}
        </MenuItem>
      ));
    case "monthly":
    default:
      return range(1, 13).map((n) => (
        <MenuItem key={n} value={n}>
          {t(`Alacarte:Month${n}`)}
        </MenuItem>
      ));
  }
};

const getItemsEnd = (
  selectionMode: ChoicePeriodMode,
  t: TFunctionStrict,
  endMonthStart: number
) => {
  switch (selectionMode) {
    case "salaryPeriod":
      return range(-endMonthStart, 14).map((n) => (
        <MenuItem key={-n} value={-n}>
          {t("Alacarte:SalaryPeriod", { salaryPeriod: n })}
        </MenuItem>
      ));
    case "monthly":
    default:
      return range(endMonthStart, 13).map((n) => (
        <MenuItem key={n} value={n}>
          {t(`Alacarte:Month${n}`)}
        </MenuItem>
      ));
  }
};

export interface ChoiceRepetitionSelectProps {
  startMonth?: number;
  endMonth?: number;
  onSelect(startMonth?: number, endMonth?: number): void;
  selectionMode: ChoicePeriodMode;
  disableDirectly: boolean;
}

const ChoiceRepetitionSelect = (props: ChoiceRepetitionSelectProps) => {
  const [t] = useTranslationStrict();
  const { startMonth, endMonth, onSelect, selectionMode, disableDirectly } =
    props;

  const endMonthStart = getEndMonthStart(selectionMode, startMonth);

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <FormControl margin="dense">
          <FormLabel>{t("Alacarte:FromDate")}:</FormLabel>
          <RadioGroup
            name="choiceStart"
            value={
              (startMonth === undefined || startMonth === 0) && !disableDirectly
                ? "directly"
                : "periodically"
            }
            onChange={(_, value): void =>
              onSelect(value === "directly" ? 0 : 1, endMonth)
            }
          >
            <FormControlLabel
              style={{ marginBottom: -8 }}
              value="directly"
              control={<Radio />}
              label={t("Alacarte:Directly")}
              disabled={disableDirectly}
            />
            <FormControlLabel
              style={{ marginBottom: -8 }}
              value="periodically"
              control={<Radio />}
              label={
                <>
                  {t("Alacarte:From")}&nbsp;
                  <Select
                    value={startMonth || 0}
                    variant="filled"
                    autoWidth
                    onChange={(e): void =>
                      onSelect(Number(e.target.value), endMonth)
                    }
                  >
                    <MenuItem value={0}>---</MenuItem>
                    {getItemsStart(selectionMode, t)}
                  </Select>
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl margin="dense">
          <FormLabel>{t("Alacarte:ToDate")}:</FormLabel>
          <RadioGroup
            name="choiceEnd"
            value={endMonth === undefined ? "yearEnd" : "monthly"}
            onChange={(_, value): void =>
              onSelect(startMonth, value === "yearEnd" ? undefined : startMonth)
            }
          >
            <FormControlLabel
              style={{ marginBottom: -8 }}
              value="yearEnd"
              control={<Radio />}
              label={t("Alacarte:YearEnd")}
            />
            <FormControlLabel
              style={{ marginBottom: -8 }}
              value="monthly"
              control={<Radio />}
              label={
                <>
                  {t("Alacarte:Until")}&nbsp;
                  <Select
                    value={endMonth || 0}
                    variant="filled"
                    autoWidth
                    onChange={(e): void =>
                      onSelect(startMonth, Number(e.target.value))
                    }
                  >
                    <MenuItem value={0}>---</MenuItem>
                    {getItemsEnd(selectionMode, t, endMonthStart)}
                  </Select>
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ChoiceRepetitionSelect;
