import { useDispatch } from "react-redux";
import speakeasy from "speakeasy";

import { HookDispatchFunction } from "../../models";
import { AuthenticationResponseModel } from "../models";
import {
  authenticationTwoFactorAddSecretCell,
  authenticationTwoFactorDisableCell,
  authenticationTwoFactorEnableCell,
  authenticationTwoFactorSettingsCell,
  authenticationTwoFactorSetupFinishCell,
  authenticationTwoFactorValidateCell,
} from "./cells";
import {
  AuthenticationTwoFactorAddSecretRequest,
  AuthenticationTwoFactorAddSecretResponse,
  AuthenticationTwoFactorSetupFinishRequest,
  AuthenticationTwoFactorToggleResponse,
  AuthenticationTwoFactorValidateRequest,
  AuthenticationTwoFactorValidateResponse,
} from "./models";

type AddSecret = HookDispatchFunction<
  AuthenticationTwoFactorAddSecretRequest,
  AuthenticationTwoFactorAddSecretResponse
>;
type SetupFinish = HookDispatchFunction<
  AuthenticationTwoFactorSetupFinishRequest,
  AuthenticationResponseModel
>;
type Disable = HookDispatchFunction<
  void,
  AuthenticationTwoFactorToggleResponse
>;
type Enable = HookDispatchFunction<void, AuthenticationTwoFactorToggleResponse>;
type Settings = HookDispatchFunction<
  void,
  AuthenticationTwoFactorToggleResponse
>;
type Validate = HookDispatchFunction<
  AuthenticationTwoFactorValidateRequest,
  AuthenticationTwoFactorValidateResponse
>;
interface AuthenticationTwoFactorHook {
  addSecret: AddSecret;
  setupFinish: SetupFinish;
  disable: Disable;
  enable: Enable;
  settings: Settings;
  validate: Validate;
  validateClientSide: (secretHex: string, token: string) => boolean;
}

export const useTwoFactor = () => {
  const dispatch = useDispatch();

  const addSecret: AddSecret = (request, callbacks) => {
    dispatch(authenticationTwoFactorAddSecretCell.require(request, callbacks));
  };

  const setupFinish: SetupFinish = (request, callbacks) => {
    dispatch(
      authenticationTwoFactorSetupFinishCell.require(request, callbacks)
    );
  };

  const disable: Disable = (request, callbacks) => {
    dispatch(authenticationTwoFactorDisableCell.require(request, callbacks));
  };

  const enable: Enable = (request, callbacks) => {
    dispatch(authenticationTwoFactorEnableCell.require(request, callbacks));
  };

  const settings: Settings = (_, callbacks) => {
    dispatch(authenticationTwoFactorSettingsCell.require(callbacks));
  };

  const validate: Validate = (request, callbacks) => {
    dispatch(authenticationTwoFactorValidateCell.require(request, callbacks));
  };

  const validateClientSide = (secretHex: string, token: string): boolean =>
    speakeasy.totp.verify({ encoding: "hex", secret: secretHex, token });

  return {
    addSecret,
    setupFinish,
    disable,
    enable,
    settings,
    validate,
    validateClientSide,
  } as AuthenticationTwoFactorHook;
};
