import { combineReducers } from "redux";

import {
  dmsDocumentDownloadCell,
  dmsDocumentSaveCell,
  dmsDownloadCell,
} from "./cells";

export default combineReducers({
  document: combineReducers({
    download: dmsDocumentDownloadCell.reducer,
    save: dmsDocumentSaveCell.reducer,
  }),
  download: dmsDownloadCell.reducer,
});
