import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { ReactElement, memo, useCallback, useRef, useState } from "react";

import { UserModel } from "../../../business/models";
import {
  UserActionsMenuItem,
  UserArchiveMenuItem,
  UserDeleteMenuItem,
  UserEditSaveToggleButton,
} from "./index";

interface Props {
  isEditing: boolean;
  onEdit(): void;
  onSave(): void;
  user: UserModel;
}

const UsersEditorActions = (props: Props) => {
  const { isEditing, onEdit, onSave, user } = props;
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <ButtonGroup color="primary" ref={anchorRef} variant="contained">
        <UserEditSaveToggleButton
          isEditing={isEditing}
          onEdit={onEdit}
          onSave={onSave}
        />
        <Button
          color="primary"
          size="small"
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleOpen}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={isOpen}
        role={undefined}
        style={{ zIndex: 3000 }}
        transition
      >
        {({ TransitionProps, placement }): ReactElement => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <UserActionsMenuItem user={user} />
                  <UserArchiveMenuItem user={user} />
                  <UserDeleteMenuItem loginName={user.loginName} />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default memo(UsersEditorActions);
