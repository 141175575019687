import { Checkbox, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useMemo } from "react";

import {
  DataDefinitionGroup,
  DataDefinitionModel,
  dataDefinitionGroups,
} from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";

export interface VariableSelectProps {
  value: number[];
  onChange: (value: number[]) => void;
  dataDefinitions: DataDefinitionModel[];
  allowUnlimited?: boolean;
}

const useStyles = makeStyles({
  select: {
    width: 400,
  },
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1em 0 0",
  },
});

const VariableSelect = (props: VariableSelectProps) => {
  const { value, onChange, dataDefinitions, allowUnlimited } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const handleSelect = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const definitions = useMemo(
    () =>
      dataDefinitions
        .filter(({ group }) => group !== DataDefinitionGroup.Geen)
        .map((dd) => ({ ...dd, label: t(`Variables:${dd.code ?? ""}`) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [dataDefinitions, t]
  );

  return (
    <Select
      className={classes.select}
      multiple
      value={value}
      onChange={handleSelect}
      renderValue={(selected) =>
        t("Datacockpit:{{number}} variabele(n) geselecteerd.", {
          number: (selected as number[]).length,
        })
      }
    >
      {dataDefinitionGroups
        .filter((group) => group !== DataDefinitionGroup.Geen)
        .flatMap((group) => [
          <MenuItem key={`header-${group}`} disabled>
            <strong>{group}</strong>
          </MenuItem>,
          ...definitions
            .filter((dd) => dd.group === group)
            .map((dd) => {
              const checked = value.includes(dd.id);
              return (
                <MenuItem
                  key={`variableselect-${dd.id}`}
                  value={dd.id}
                  disabled={!allowUnlimited && !checked && value.length === 5}
                >
                  <Checkbox checked={value.includes(dd.id)} />
                  <div className={classes.label}>
                    <span>{dd.label}</span>
                    <code style={{ fontSize: "smaller" }}>{dd.code}</code>
                  </div>
                </MenuItem>
              );
            }),
        ])}
    </Select>
  );
};

export default VariableSelect;
