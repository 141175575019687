import { put } from "@redux-saga/core/effects";
import { takeEvery } from "redux-saga/effects";

import {
  datacockpitDataDefinitionsCell,
  datacockpitEmployeeDataEditCell,
  datacockpitEmployeeDataFetchCell,
  datacockpitEmployeeDataFilterCell,
  datacockpitEmployeeDataPatchCell,
  datacockpitMutationsApproveCell,
  datacockpitMutationsOverviewCell,
  datacockpitMutationsRejectCell,
  datacockpitVariableDataExportActionCell,
  datacockpitVariableDataTableFetchCell,
} from "./cells";
import { emptyEmployeeDataFilter } from "./models";

function* employeeDataPatchSuccess() {
  yield put(datacockpitEmployeeDataEditCell.set(null));
  yield put(datacockpitEmployeeDataFetchCell.require());
  yield put({ type: datacockpitMutationsOverviewCell.events.invalidate });
}

function* employeeDataAuthorizationSuccess() {
  yield put({ type: datacockpitEmployeeDataFetchCell.events.invalidate });
  yield put({ type: datacockpitVariableDataTableFetchCell.events.invalidate });
  yield put({ type: datacockpitMutationsOverviewCell.events.invalidate });
  yield put(datacockpitEmployeeDataFilterCell.set(emptyEmployeeDataFilter));
  yield put(datacockpitMutationsOverviewCell.require());
}

const datacockpitSagas = [
  ...datacockpitDataDefinitionsCell.sagas,
  ...datacockpitEmployeeDataFetchCell.sagas,
  ...datacockpitEmployeeDataFilterCell.sagas,
  ...datacockpitEmployeeDataPatchCell.sagas,
  takeEvery(
    datacockpitEmployeeDataPatchCell.events.success,
    employeeDataPatchSuccess
  ),
  ...datacockpitMutationsRejectCell.sagas,
  ...datacockpitMutationsApproveCell.sagas,
  takeEvery(
    [
      datacockpitMutationsRejectCell.events.success,
      datacockpitMutationsApproveCell.events.success,
    ],
    employeeDataAuthorizationSuccess
  ),
  ...datacockpitMutationsOverviewCell.sagas,
  ...datacockpitVariableDataExportActionCell.sagas,
  ...datacockpitVariableDataTableFetchCell.sagas,
];

export default datacockpitSagas;
