import { Card, CardContent } from "@material-ui/core";
import React from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

const MutationsOverviewEmpty = () => {
  const [t] = useTranslationStrict();
  return (
    <Card>
      <CardContent>
        <em>{t("Datacockpit:Geen gegevens beschikbaar.")}</em>
      </CardContent>
    </Card>
  );
};

export default MutationsOverviewEmpty;
