import { Button, PropTypes } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";

import {
  datacockpitMutationsApproveCell,
  datacockpitMutationsRejectCell,
} from "../../../business/redux/saga/datacockpit/cells";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";

type Mode = "Approve" | "Reject";

const getColor = (mode: Mode): PropTypes.Color => {
  switch (mode) {
    case "Approve":
      return "primary";
    case "Reject":
      return "secondary";
    default:
      return "inherit";
  }
};

const getLabel = (mode: Mode, t: TFunctionStrict) => {
  switch (mode) {
    case "Approve":
      return t("Datacockpit:Goedkeuren");
    case "Reject":
      return t("Datacockpit:Afkeuren");
    default:
      return "";
  }
};

interface Props {
  effectiveDate: Date;
  mode: Mode;
}

const MutationsOverviewAuthorizationButton = (props: Props) => {
  const { effectiveDate, mode } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const [t] = useTranslationStrict();

  const handleClick = () => {
    switch (mode) {
      case "Approve":
        dispatch(
          datacockpitMutationsApproveCell.require(
            { effectiveDate },
            {
              onFail: showErrorMessageFromDetails(
                t("Datacockpit:Verwerkingen goedkeuren mislukt.")
              ),
              onSuccess: () => {
                enqueueSnackbar(
                  t(
                    "Datacockpit:Verwerkingen goedkeuren aangevraagd. Even geduld alsjeblieft."
                  ),
                  {
                    variant: "success",
                  }
                );
              },
            }
          )
        );
        break;
      case "Reject":
        dispatch(
          datacockpitMutationsRejectCell.require(
            { effectiveDate },
            {
              onFail: showErrorMessageFromDetails(
                t("Datacockpit:Verwerkingen afkeuren mislukt.")
              ),
              onSuccess: () => {
                enqueueSnackbar(t("Datacockpit:Verwerkingen afgekeurd."), {
                  variant: "success",
                });
              },
            }
          )
        );
        break;
      default:
        throw new Error("Not supported");
    }
  };

  return (
    <Button color={getColor(mode)} onClick={handleClick}>
      {getLabel(mode, t)}
    </Button>
  );
};

export default MutationsOverviewAuthorizationButton;
