import {
  Card,
  CardContent,
  CardHeader,
  List,
  Typography,
} from "@material-ui/core";
import { Details, Some, Success } from "async-lifecycle-saga/dist/models";
import sortBy from "lodash/fp/sortBy";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UploadTypeModel } from "../../../business/admin/upload/models";
import { TotlResponse } from "../../../business/api";
import { DownloadModel, UserModel } from "../../../business/models";
import {
  adminPeekDownloadRemoveCell,
  adminPeekDownloadsCell,
} from "../../../business/redux/saga/admin/peek/cells";
import {
  adminUploadAddCell,
  adminUploadTypesCell,
} from "../../../business/redux/saga/admin/upload/cells";
import { adminUsersListCell } from "../../../business/redux/saga/admin/users/cells";
import { dmsDownloadCell } from "../../../business/redux/saga/dms/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import EmployeeSelect from "../../admin/employees/EmployeeSelect";
import UploadCreator from "../../admin/upload/UploadCreator";
import DocumentListItem from "../../cms/DocumentListItem";
import Loading from "../../ux/Loading";
import useStyles from "./AdminPage.styles";

const Downloads = memo(
  ({
    uploadType,
    downloads,
    employeeId,
  }: {
    uploadType: UploadTypeModel;
    downloads: DownloadModel[];
    employeeId: number;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [t] = useTranslationStrict();

    const handleDownloadClick = useCallback(
      (download: DownloadModel): (() => void) =>
        () => {
          dispatch(
            dmsDownloadCell.require(`/api/download/peek/${download.id}`, {
              onFail: (details: Details) => {
                enqueueSnackbar(details.detail || download.id, {
                  variant: "error",
                });
              },
              onSuccess: ({ body: { link } }: Success<TotlResponse>) => {
                try {
                  if (!window.open(link, "_blank")) {
                    window.location = link as unknown as Location;
                  }
                } catch (e) {
                  enqueueSnackbar(
                    (e as Error).message || (e as Some).toString(),
                    {
                      variant: "error",
                    }
                  );
                }
              },
            })
          );
        },
      [dispatch, enqueueSnackbar]
    );

    const { confirmAction } = useConfirm();
    const handleDelete = (id: number) =>
      confirmAction(
        adminPeekDownloadRemoveCell.require(id, {
          onSuccess: () => {
            dispatch(adminPeekDownloadsCell.require(employeeId));
          },
        })
      );

    return (
      <>
        <Typography variant="h6">
          {t(`UploadType:${uploadType.name.replace(":", ";")}`)}
        </Typography>
        <List>
          {downloads.map((d) => (
            <DocumentListItem
              key={d.id}
              document={d}
              enableEdit
              onClick={handleDownloadClick(d)}
              onDelete={(): void => handleDelete(d.id)}
            />
          ))}
        </List>
      </>
    );
  }
);

const PeekPage = () => {
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { downloads, usersList, uploadTypes } = useSelector(
    ({
      admin,
      admin: {
        upload: {
          types: { value: uploadTypes = [] },
        },
        users: {
          list: { value: usersList = [] },
        },
      },
    }: StoreModel) => ({
      downloads: admin.peek.downloads,
      usersList,
      uploadTypes,
    })
  );

  const [currentUser, setUser] = useState<UserModel | null>(null);

  useEffect((): void => {
    dispatch(adminUploadTypesCell.require());
    dispatch(adminUsersListCell.require());
  }, [dispatch]);

  const onUserChange = useCallback(
    (employeeSelected: UserModel | null): void => {
      setUser(employeeSelected);
      dispatch({ type: adminPeekDownloadsCell.events.clear });
      if (employeeSelected) {
        dispatch(adminPeekDownloadsCell.require(employeeSelected.id));
      }
    },
    [dispatch]
  );

  const present = usersList.length > 0 && Boolean(uploadTypes);

  return (
    <div className={classes.gridWideContent}>
      <Card>
        <CardContent>
          {!present && <Loading />}
          {present && <EmployeeSelect onSelect={onUserChange} />}
        </CardContent>
      </Card>
      {currentUser && (
        <Card classes={{ root: classes.card }}>
          <CardHeader>{currentUser.fullName}</CardHeader>
          <CardContent>
            {currentUser && downloads.value?.length === 0 && (
              <Typography variant="body1">
                {t("Common:NoData")} {t("Common:for")} {currentUser.fullName}
              </Typography>
            )}
            {currentUser && downloads.value && downloads.value.length > 0 && (
              <Typography variant="h5">
                {`${t("Admin:Documents")} ${t("Common:for")} ${
                  currentUser.fullName
                }`}
              </Typography>
            )}
            {sortBy(["name"], uploadTypes).map(
              (ut: UploadTypeModel): JSX.Element | undefined => {
                const downloadsOfType =
                  downloads.value?.filter((d) => d.uploadTypeId === ut.id) ||
                  [];
                return downloadsOfType.length > 0 ? (
                  <Downloads
                    key={ut.id}
                    uploadType={ut}
                    downloads={downloadsOfType}
                    employeeId={currentUser.id}
                  />
                ) : undefined;
              }
            )}
          </CardContent>
        </Card>
      )}
      {uploadTypes && currentUser && (
        <Card classes={{ root: classes.card }}>
          <CardHeader>Upload</CardHeader>
          <CardContent>
            <UploadCreator
              types={uploadTypes.filter(
                ({ category }): boolean => category === "personal"
              )}
              onAdd={(uploadTypeId, effectiveDate, file, onSuccess) => {
                dispatch(
                  adminUploadAddCell.require(
                    {
                      employeeId: currentUser.id,
                      uploadTypeId,
                      effectiveDate,
                      file,
                    },
                    {
                      onSuccess: () => {
                        dispatch(
                          adminPeekDownloadsCell.require(currentUser.id)
                        );
                        onSuccess();
                      },
                    }
                  )
                );
              }}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default memo(PeekPage);
