import { Card, CardActions, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, memo } from "react";

import Info from "../ux/Info";
import CardProps from "./CardProps";
import useStyles from "./ContentCard.styles";

interface ContentCardProps extends CardProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  classes?: Record<string, string>;
  elevation?: number;
  flat?: boolean;
  info?: React.ReactNode;
  raised?: boolean;
  square?: boolean;
  subtitle?: string;
  title?: string;
}

const ContentCard: FunctionComponent<ContentCardProps> = (
  props: ContentCardProps
) => {
  const {
    actions,
    children,
    classes: newClasses,
    elevation,
    flat,
    info,
    raised,
    square,
    subtitle,
    title,
  } = props;

  const classes = useStyles({ classes: newClasses });

  return children ? (
    <Card
      className={classes.root}
      elevation={flat ? 0 : elevation || 1}
      raised={raised}
      square={square}
      variant="elevation"
    >
      {(title || info) && (
        <Grid container spacing={8} direction="row">
          <Grid item xs>
            <Typography variant="h6" className={classes.h3}>
              {title}
            </Typography>

            {subtitle && (
              <Typography variant="subtitle1" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.info}>
            {info && <Info>{info}</Info>}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={8} direction="row">
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      {actions && (
        <CardActions classes={{ root: classes.actions }}>{actions}</CardActions>
      )}
    </Card>
  ) : null;
};

export default memo(ContentCard);
