import * as React from "react";
import { FunctionComponent, memo, useCallback } from "react";
import useReactRouter from "use-react-router";

import AuthenticationTwoFactorValidator from "../../authentication/twoFactor/AuthenticationTwoFactorValidator";
import useStyles from "./AuthenticationTwoFactorPage.styles";

const GoogleAuthenticatorPage: FunctionComponent = () => {
  const classes = useStyles();
  const { history } = useReactRouter();

  const handleValid = useCallback((): void => {
    history.push("/");
  }, [history]);

  return (
    <div className={classes.gridMainContent}>
      <AuthenticationTwoFactorValidator onValid={handleValid} />
    </div>
  );
};

export default memo(GoogleAuthenticatorPage);
