import { combineReducers } from "redux";

import {
  authenticationTwoFactorAddSecretCell,
  authenticationTwoFactorDisableCell,
  authenticationTwoFactorEnableCell,
  authenticationTwoFactorSettingsCell,
  authenticationTwoFactorSetupFinishCell,
  authenticationTwoFactorValidateCell,
} from "./cells";

export default combineReducers({
  addSecret: authenticationTwoFactorAddSecretCell.reducer,
  setupFinish: authenticationTwoFactorSetupFinishCell.reducer,
  disable: authenticationTwoFactorDisableCell.reducer,
  enable: authenticationTwoFactorEnableCell.reducer,
  settings: authenticationTwoFactorSettingsCell.reducer,
  validate: authenticationTwoFactorValidateCell.reducer,
});
