import { Suspense, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";

import { useAuthentication } from "./business/redux/saga/authentication/hooks";
import { StoreModel } from "./business/redux/saga/models";
import {
  navigationMenuCell,
  navigationTreeCell,
} from "./business/redux/saga/navigation/cells";
import { organizationCell } from "./business/redux/saga/organization/cells";
import AnnouncementsCard from "./components/communication/AnnouncementsCard";
import NavigationHistoryCard from "./components/navigation/NavigationHistoryCard";
import {
  BasePage,
  ContentPage,
  Dashboard,
  LoadingPage,
  NotFoundPage,
  PrivatePage,
} from "./components/pages";
import {
  AdminInfoPage,
  AdminPage,
  ContractsPage,
  PeekPage,
  SettingsPage,
  UploadsPage,
  UsersPage,
} from "./components/pages/admin";
import {
  ALaCarteAdminPage,
  ALaCarteApprovePage,
  ALaCartePage,
} from "./components/pages/alacarte";
import { LoginPage } from "./components/pages/authentication";
import {
  EmployeePage,
  ExportPage,
  MutationsPage,
  TablePage,
} from "./components/pages/datacockpit";
import { MyDataPage } from "./components/pages/personal";
import { ImpersonationPage, OrganizationsPage } from "./components/pages/super";
import {
  AuthenticationTwoFactorPage,
  AuthenticationTwoFactorSetupPage,
  CreatePasswordPage,
  ResetPage,
} from "./components/pages/user";
import { useLoginErrorMessages } from "./components/ux";
import IdleWarning from "./components/ux/IdleWarning";
import Loading from "./components/ux/Loading";
import PrivateRoute from "./components/ux/PrivateRoute";
import TwoFactorRoute from "./components/ux/TwoFactorRoute";
import reduxConnectApp, { ConnectData } from "./containers/App.connect";
import { useTranslationStrict } from "./globalization/i18n";
import { selectNodes } from "./utils/routes";
import { isAdminRole } from "./utils/SecurityUtils";

/* eslint-disable @typescript-eslint/no-explicit-any */
type AppProps = ConnectData & RouteComponentProps<any, {}, any>;
/* eslint-enable */

const App = (props: AppProps) => {
  const dispatch = useDispatch();
  const { showLoginError } = useLoginErrorMessages();

  const { basePage, session } = props;
  const [t] = useTranslationStrict();

  const { knowledge } = useAuthentication();

  const menu = useSelector(
    ({
      navigation: {
        menu: { value },
      },
    }: StoreModel) => value
  );
  const nodes = useSelector(selectNodes(t));

  const loadNavigationData = useCallback(() => {
    if (session.jwt) {
      dispatch(organizationCell.require());
      dispatch(navigationMenuCell.require());
      dispatch(navigationTreeCell.require());
    }
  }, [session.jwt, dispatch]);

  const navigationLoading = useSelector(
    (store: StoreModel) =>
      store.navigation.menu.status.loading ||
      store.navigation.tree.status.loading
  );

  const handleLogin = useCallback(
    async (loginName: string, password: string): Promise<void> => {
      await knowledge(
        {
          loginName,
          password,
        },
        {
          onFail: () => showLoginError(loginName),
        }
      );
    },
    [knowledge, showLoginError]
  );

  useEffect((): void => {
    if (session.jwt) {
      loadNavigationData();
    }
  }, [loadNavigationData, session.jwt]);

  const dashboardRouteRender = (): JSX.Element => {
    const cards = [[], [<NavigationHistoryCard />], [<AnnouncementsCard />]];
    return (
      <Suspense fallback={<Loading />}>
        <PrivatePage {...basePage} menu={menu}>
          <Dashboard cards={cards} />
        </PrivatePage>
      </Suspense>
    );
  };

  const alacarteAdminRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <ALaCarteAdminPage />
        </AdminPage>
      </Suspense>
    );
  };

  const adminContractsRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <ContractsPage />
        </AdminPage>
      </Suspense>
    );
  };

  const adminInfoRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <AdminInfoPage />
        </AdminPage>
      </Suspense>
    );
  };

  const datacockpitEmployeeRender = () => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <EmployeePage />
        </AdminPage>
      </Suspense>
    );
  };

  const datacockpitTableRender = () => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <TablePage />
        </AdminPage>
      </Suspense>
    );
  };

  const datacockpitExportRender = () => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <ExportPage />
        </AdminPage>
      </Suspense>
    );
  };

  const datacockpitMutationsRender = () => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <MutationsPage />
        </AdminPage>
      </Suspense>
    );
  };

  const alacarteApproveRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <ALaCarteApprovePage />
        </AdminPage>
      </Suspense>
    );
  };

  const myDataRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <PrivatePage {...basePage} menu={menu}>
          <MyDataPage />
        </PrivatePage>
      </Suspense>
    );
  };

  const contentPageRender = useCallback(
    ({ location }: RouteComponentProps) => {
      return (
        <Suspense fallback={<Loading />}>
          <PrivatePage {...basePage} menu={menu}>
            {(() => {
              const node = nodes.find(
                ({ route }) => route === location.pathname
              );
              if (node === undefined) {
                return navigationLoading ? <LoadingPage /> : <NotFoundPage />;
              }

              const alacarte =
                node.special === "alacarte" && !isAdminRole(session.appRole);
              return (
                <>
                  {alacarte && <ALaCartePage node={node} />}
                  {/* eslint-disable react/prop-types */}
                  {!alacarte && (
                    <ContentPage
                      enableEdit={isAdminRole(session.appRole) && node.id !== 0}
                      node={node}
                      pao={node.special === "pao"}
                    />
                  )}
                  {/* eslint-enable react/prop-types */}
                </>
              );
            })()}
          </PrivatePage>
        </Suspense>
      );
    },
    [basePage, menu, navigationLoading, nodes, session.appRole]
  );

  const adminPeekRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <PeekPage />
        </AdminPage>
      </Suspense>
    );
  };

  const adminUsersRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <UsersPage />
        </AdminPage>
      </Suspense>
    );
  };

  const superOrganizationsRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <OrganizationsPage />
        </AdminPage>
      </Suspense>
    );
  };

  const impersonateRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <ImpersonationPage />
        </AdminPage>
      </Suspense>
    );
  };

  const adminUploadsRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <UploadsPage />
        </AdminPage>
      </Suspense>
    );
  };

  const adminSettingsRouteRender = (): JSX.Element => {
    return (
      <Suspense fallback={<Loading />}>
        <AdminPage {...basePage}>
          <SettingsPage />
        </AdminPage>
      </Suspense>
    );
  };

  const twoFactorAuthRender = (): JSX.Element => (
    <BasePage {...basePage}>
      <AuthenticationTwoFactorPage />
    </BasePage>
  );

  const twoFactorAuthSetupRender = () => (
    <BasePage {...basePage}>
      <AuthenticationTwoFactorSetupPage />
    </BasePage>
  );

  const loginPageRouteRender = (): JSX.Element => (
    <BasePage {...basePage}>
      <LoginPage onLogin={handleLogin} />
    </BasePage>
  );

  const resetPageRender = (): JSX.Element => (
    <BasePage {...basePage}>
      <ResetPage />
    </BasePage>
  );

  const createPasswordPageRender = (): JSX.Element => (
    <BasePage {...basePage}>
      <CreatePasswordPage />
    </BasePage>
  );

  return (
    <>
      <Switch>
        <PrivateRoute path="/" exact render={dashboardRouteRender} />
        <PrivateRoute
          path="/admin"
          exact
          render={(): JSX.Element => <Redirect to="/admin/users" />}
        />
        <PrivateRoute
          path="/admin/alacarte"
          exact
          render={alacarteAdminRouteRender}
        />
        <PrivateRoute
          path="/admin/contracts"
          exact
          render={adminContractsRouteRender}
        />
        <PrivateRoute
          path="/admin/dossier"
          exact
          render={adminPeekRouteRender}
        />
        <PrivateRoute
          path="/admin/keuzes"
          exact
          render={alacarteApproveRouteRender}
        />
        <PrivateRoute
          path="/admin/uploads"
          exact
          render={adminUploadsRouteRender}
        />
        <PrivateRoute
          path="/admin/settings"
          exact
          render={adminSettingsRouteRender}
        />
        <PrivateRoute
          path="/admin/users"
          exact
          render={adminUsersRouteRender}
        />
        <PrivateRoute path="/admin/info" exact render={adminInfoRouteRender} />
        <PrivateRoute
          path="/datacockpit/employee"
          exact
          render={datacockpitEmployeeRender}
        />
        <PrivateRoute
          path="/datacockpit/table"
          exact
          render={datacockpitTableRender}
        />
        <PrivateRoute
          path="/datacockpit/export"
          exact
          render={datacockpitExportRender}
        />
        <PrivateRoute
          path="/datacockpit/mutations"
          exact
          render={datacockpitMutationsRender}
        />
        <PrivateRoute
          path="/super/organizations"
          exact
          render={superOrganizationsRouteRender}
        />
        <PrivateRoute
          path="/admin/impersonate"
          exact
          render={impersonateRouteRender}
        />
        <PrivateRoute path="/profile" exact render={myDataRender} />
        <TwoFactorRoute
          path="/twofactorauth"
          exact
          render={twoFactorAuthRender}
        />
        <TwoFactorRoute
          path="/twofactorauthsetup"
          exact
          render={twoFactorAuthSetupRender}
        />
        {session.jwt && <Redirect from="/login" to="/" />}
        {session.googleAuthenticator && (
          <Redirect from="/login" to="/twofactorauth" />
        )}
        {!session.jwt && !session.googleAuthenticator && (
          <Route path="/login" exact render={loginPageRouteRender} />
        )}
        <Route path="/reset" exact render={resetPageRender} />
        <Route
          path="/user/createpassword"
          exact
          render={createPasswordPageRender}
        />
        <PrivateRoute path="/" render={contentPageRender} />
      </Switch>
      <IdleWarning />
    </>
  );
};

export default reduxConnectApp(withRouter(memo(App)));
