import React, {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";

import ConfirmDialog from "./ConfirmDialog";
import { Confirm, ConfirmContextValue, ConfirmOptions, ignore } from "./core";

const defaultContextValue: ConfirmContextValue = {
  confirm: ignore,
  confirmAction: ignore,
};
export const ConfirmContext = createContext(defaultContextValue);

interface Props {
  children: ReactNode;
}

interface State {
  current?: Confirm;
}

const emptyState: State = {};

const ConfirmProvider = memo(({ children }: Props): JSX.Element => {
  const [{ current }, setState] = useState(emptyState);

  const onAbort = useCallback((): void => {
    if (current && current.onAbort) {
      current.onAbort();
    }

    setState(emptyState);
  }, [current, setState]);

  const onCancel = useCallback((): void => {
    if (current && current.onCancel) {
      current.onCancel();
    }

    setState(emptyState);
  }, [current, setState]);

  const onConfirm = useCallback((): void => {
    if (current && current) {
      current.onConfirm();
    }

    setState(emptyState);
  }, [current, setState]);

  const dispatch = useDispatch();
  const confirm = useCallback(
    (next: Confirm): void => setState({ current: next }),
    [setState]
  );
  const confirmAction = useCallback(
    (action: AnyAction, options: ConfirmOptions): void => {
      confirm({
        ...options,
        onConfirm: (): void => {
          dispatch(action);
        },
      });
    },
    [confirm, dispatch]
  );

  const value = useMemo(
    (): ConfirmContextValue => ({
      confirm,
      confirmAction,
    }),
    [confirm, confirmAction]
  );

  return (
    <ConfirmContext.Provider value={value}>
      <>
        {children}
        {current && (
          <ConfirmDialog
            {...current.texts}
            onAbort={current.onAbort && onAbort}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </>
    </ConfirmContext.Provider>
  );
});

export default ConfirmProvider;
