import { MenuItem, Select } from "@material-ui/core";
import { FieldProps } from "formik";
import * as React from "react";

import { materialIcons } from "../../ux/icons";

const FormikMaterialUIIconPicker: React.NamedExoticComponent<FieldProps> =
  React.memo<FieldProps>((props: FieldProps): React.ReactElement => {
    const {
      field: { name, onChange, value },
      form: { error, errors, isSubmitting, touched },
    } = props;
    return (
      <Select
        disabled={error || isSubmitting}
        inputProps={{ error: touched.name && errors.name }}
        name={name}
        onChange={onChange}
        value={value}
        style={{ marginTop: 0 }}
      >
        {materialIcons &&
          Object.entries(materialIcons).map(([key, icon]): JSX.Element => {
            const selected = value === key;
            const Icon = icon as React.ComponentType;
            return (
              <MenuItem key={key} button selected={selected} value={key}>
                <Icon />
                {String.fromCharCode(160)}
                {key}
              </MenuItem>
            );
          })}
      </Select>
    );
  });

export default FormikMaterialUIIconPicker;
