import { createActor, createSingle } from "async-lifecycle-saga";
import { AsyncSingleCell } from "async-lifecycle-saga/dist/single";

import { asyncGet, asyncPost } from "../../../api";
import { NavigationLocation, NavigationRootModel } from "../../../models";
import { StoreModel } from "../models";
import { NavigationHistoryEntry, NavigationHistoryResponse } from "./models";

export const navigationAddToHistoryCell = createActor<
  number,
  NavigationHistoryResponse
>({
  path: ["navigation", "addToHistory"],
  api: (navigationNodeId) =>
    asyncPost("/api/navigation/history", { navigationNodeId }),
});

export const navigationGetHistoryCell = createActor<
  number | undefined,
  NavigationHistoryEntry[]
>({
  path: ["navigation", "getHistory"],
  api: (number) =>
    asyncGet(
      number !== undefined
        ? `/api/navigation/history/${number}`
        : "/api/navigation/history"
    ),
});

const getNavigationUrl = (location: NavigationLocation) =>
  `/api/navigation/${location}`;

export const navigationMenuCell: AsyncSingleCell<
  NavigationRootModel,
  StoreModel
> = createSingle<NavigationRootModel, StoreModel>({
  path: ["navigation", "menu"],
  require: { api: () => asyncGet(getNavigationUrl("aside")) },
});

export const navigationTreeCell: AsyncSingleCell<
  NavigationRootModel,
  StoreModel
> = createSingle<NavigationRootModel, StoreModel>({
  path: ["navigation", "tree"],
  require: { api: () => asyncGet(getNavigationUrl("appbar")) },
});
