import { all, put, takeEvery } from "redux-saga/effects";

import {
  adminChoiceBenefitsCell,
  adminChoiceDefinitionUpshiftCell,
  adminChoiceDefinitionsDownshiftCell,
  adminChoiceDefinitionsListCell,
  adminChoiceDefinitionsUpsertCell,
  adminChoiceEmployeeApproveCell,
  adminChoiceEmployeeExportMutationsCell,
  adminChoiceEmployeeListCell,
  adminChoiceEmployeeResetCell,
  adminChoicePeriodsCell,
  adminChoiceSettingsEntityCell,
  adminInfoExportCell,
} from "./admin/choices/cells";
import {
  adminContractsExportCreateCell,
  adminContractsExportDeleteCell,
  adminContractsExportDownloadCell,
  adminContractsExportGetAllCell,
} from "./admin/contracts/cells";
import { adminEmployeeTypesCell } from "./admin/employee/cells";
import {
  adminEmployersListCell,
  adminEmployersListSpecialCell,
} from "./admin/employers/cells";
import { enienlCalculateCell, enienlRegisterCell } from "./admin/enienl/cells";
import adminNavigationSagas from "./admin/navigation/sagas";
import {
  adminPeekDownloadRemoveCell,
  adminPeekDownloadsCell,
} from "./admin/peek/cells";
import { adminSettingsLogoCell, adminSettingsSagas } from "./admin/settings";
import {
  adminUploadAddCell,
  adminUploadCheckCell,
  adminUploadDeleteCell,
  adminUploadListCell,
  adminUploadProcessCell,
  adminUploadTypesCell,
} from "./admin/upload/cells";
import {
  adminUsersAddCell,
  adminUsersChanged,
  adminUsersDeleteCell,
  adminUsersInviteAllNewCell,
  adminUsersInviteCell,
  adminUsersListCell,
  adminUsersListIncludeArchivedCell,
  adminUsersUpdateCell,
} from "./admin/users/cells";
import {
  announcementsAddCell,
  announcementsListPagedCell,
  announcementsRetireCell,
} from "./announcements/cells";
import { appCell } from "./app/cells";
import {
  authenticationSagas,
  authenticationSsoCell,
} from "./authentication/cells";
import { authenticationMicrosoft365Sagas } from "./authentication/microsoft365/cells";
import { authenticationTwoFactorSagas } from "./authentication/twoFactor/cells";
import datacockpitSagas from "./datacockpit/sagas";
import {
  dmsDocumentDownloadCell,
  dmsDocumentSaveCell,
  dmsDownloadCell,
} from "./dms/cells";
import { employerGetCell } from "./employer";
import {
  navigationAddToHistoryCell,
  navigationGetHistoryCell,
  navigationMenuCell,
  navigationTreeCell,
} from "./navigation/cells";
import { organizationCell, organizationSagas } from "./organization";
import {
  debitCardCell,
  personalAddPboCell,
  personalDataCell,
  personalDefinitionsCell,
  personalDownloadsCell,
  personalUploadTypesCell,
} from "./personal/cells";
import {
  personalChoicesByLoginNameCell,
  personalChoicesEntityCell,
  personalChoicesUploadCell,
  personalChoicesUpsertCell,
} from "./personal/choices/cells";
import { personalDownloadRemoveCell } from "./personal/download/cells";
import { personalWlzSagas } from "./personal/wlz/cells";
import { avatarCell } from "./profile/avatar/cells";
import { profileCell, profileSuccess } from "./profile/cells";
import { searchCell } from "./search/cells";
import { superSagas } from "./super/cells";
import {
  daywizeTokenCell,
  userGenerateTokenCell,
  userPasswordResetCell,
  userPasswordResetRequestCell,
  userPasswordResetVerifyCell,
} from "./user/cells";

const adminChoiceDefinitionsChangedEvents = [
  adminChoiceDefinitionsUpsertCell.events.success,
  adminChoiceDefinitionsDownshiftCell.events.success,
  adminChoiceDefinitionUpshiftCell.events.success,
];

const adminUsersChangedEvents = [
  adminUsersAddCell.events.success,
  adminUsersDeleteCell.events.success,
  adminUsersInviteAllNewCell.events.success,
  adminUsersUpdateCell.events.success,
];

const personalChoicesChangedEvents = [
  personalChoicesUploadCell.events.success,
  personalChoicesUpsertCell.events.success,
];

const personalDownloadsChangedEvents = [
  personalAddPboCell.events.success,
  personalDownloadRemoveCell.events.success,
];

export default function* sagas(): Generator {
  yield all([
    ...adminChoiceBenefitsCell.sagas,
    ...adminChoiceDefinitionsListCell.sagas,
    ...adminChoiceDefinitionsUpsertCell.sagas,
    takeEvery(adminChoiceDefinitionsChangedEvents, function* () {
      yield put(adminChoiceDefinitionsListCell.require());
      yield put(personalChoicesEntityCell.require());
    }),
    ...adminChoiceDefinitionsDownshiftCell.sagas,
    ...adminChoiceEmployeeApproveCell.sagas,
    takeEvery(adminChoiceEmployeeApproveCell.events.success, function* () {
      yield put(adminChoiceEmployeeListCell.require());
    }),
    ...adminChoiceEmployeeExportMutationsCell.sagas,
    ...adminChoiceEmployeeListCell.sagas,
    ...adminChoiceEmployeeResetCell.sagas,
    ...adminChoicePeriodsCell.sagas,
    ...adminChoiceSettingsEntityCell.sagas,
    ...adminChoiceDefinitionUpshiftCell.sagas,
    ...adminContractsExportCreateCell.sagas,
    ...adminContractsExportDeleteCell.sagas,
    ...adminContractsExportDownloadCell.sagas,
    ...adminContractsExportGetAllCell.sagas,
    ...adminEmployeeTypesCell.sagas,
    ...adminEmployersListCell.sagas,
    ...adminEmployersListSpecialCell.sagas,
    ...adminInfoExportCell.sagas,
    ...adminNavigationSagas,
    ...adminPeekDownloadsCell.sagas,
    ...adminPeekDownloadRemoveCell.sagas,
    ...adminSettingsSagas,
    takeEvery(adminSettingsLogoCell.events.success, function* () {
      yield put(organizationCell.require());
    }),
    ...adminUploadAddCell.sagas,
    ...adminUploadCheckCell.sagas,
    ...adminUploadDeleteCell.sagas,
    ...adminUploadListCell.sagas,
    ...adminUploadProcessCell.sagas,
    ...adminUploadTypesCell.sagas,
    ...adminUsersAddCell.sagas,
    ...adminUsersDeleteCell.sagas,
    ...adminUsersInviteCell.sagas,
    ...adminUsersInviteAllNewCell.sagas,
    ...adminUsersListCell.sagas,
    ...adminUsersListIncludeArchivedCell.sagas,
    ...adminUsersUpdateCell.sagas,
    takeEvery(adminUsersChangedEvents, adminUsersChanged),
    ...announcementsAddCell.sagas,
    ...announcementsListPagedCell.sagas,
    ...announcementsRetireCell.sagas,
    ...appCell.sagas,
    ...authenticationSagas,
    ...authenticationSsoCell.sagas,
    ...authenticationTwoFactorSagas,
    ...authenticationMicrosoft365Sagas,
    ...avatarCell.sagas,
    ...datacockpitSagas,
    ...daywizeTokenCell.sagas,
    ...debitCardCell.sagas,
    ...dmsDocumentDownloadCell.sagas,
    ...dmsDocumentSaveCell.sagas,
    ...dmsDownloadCell.sagas,
    ...employerGetCell.sagas,
    ...enienlCalculateCell.sagas,
    ...enienlRegisterCell.sagas,
    ...navigationAddToHistoryCell.sagas,
    ...navigationGetHistoryCell.sagas,
    ...navigationMenuCell.sagas,
    ...navigationTreeCell.sagas,
    ...organizationSagas,
    ...personalAddPboCell.sagas,
    ...personalChoicesByLoginNameCell.sagas,
    ...personalChoicesEntityCell.sagas,
    ...personalChoicesUploadCell.sagas,
    ...personalChoicesUpsertCell.sagas,
    takeEvery(personalChoicesChangedEvents, function* () {
      yield put(personalChoicesEntityCell.require());
    }),
    ...personalDataCell.sagas,
    ...personalDefinitionsCell.sagas,
    ...personalDownloadRemoveCell.sagas,
    ...personalDownloadsCell.sagas,
    takeEvery(personalDownloadsChangedEvents, function* () {
      yield put(personalDownloadsCell.require());
    }),
    ...personalUploadTypesCell.sagas,
    ...personalWlzSagas,
    ...profileCell.sagas,
    takeEvery(profileCell.events.success, profileSuccess),
    ...searchCell.sagas,
    ...superSagas,
    ...userPasswordResetCell.sagas,
    ...userPasswordResetRequestCell.sagas,
    ...userPasswordResetVerifyCell.sagas,
    ...userGenerateTokenCell.sagas,
  ]);
}
