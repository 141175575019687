import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import { enUS, nl } from "date-fns/locale";
import React, { memo } from "react";
import ReactMarkdown from "react-markdown";

import { ChoiceBenefitsModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { formatCurrency } from "../../../utils/NumberUtils";
import useStyles from "../../pages/admin/AdminPage.styles";
import Info from "../../ux/Info";

const ChoiceBenefitsTable = ({
  benefits: { details, total },
  benefitSharing,
  showChoicePeriod,
}: {
  benefits: ChoiceBenefitsModel;
  benefitSharing: boolean;
  showChoicePeriod?: boolean;
}) => {
  const [t, { language }] = useTranslationStrict();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">
        {t("Admin:ChoiceBenefitsAt")} {total.name}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Admin:ChoiceName")}</TableCell>
            {showChoicePeriod && (
              <TableCell>{t("Admin:Keuzeperiode")}</TableCell>
            )}
            <TableCell className={classes.right}>
              {t("Admin:ChoiceEmployeeBenefit")}
            </TableCell>
            <TableCell className={classes.right}>
              {t("Admin:ChoiceEmployerBenefit")}
              {benefitSharing && total.employerBenefit > 0 && (
                <Info>
                  <ReactMarkdown>{t("Alacarte:BenefitSharing")}</ReactMarkdown>
                </Info>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={i}>
              <TableCell>{d.name}</TableCell>
              {showChoicePeriod && (
                <TableCell>
                  {d.choicePeriodDate &&
                    format(new Date(d.choicePeriodDate), "PP", {
                      locale: language === "nl" ? nl : enUS,
                    })}
                </TableCell>
              )}
              <TableCell>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.currencyUnit}>€</TableCell>
                      <TableCell className={classes.currencyAmount}>
                        {formatCurrency(d.employeeBenefit)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.currencyUnit}>€</TableCell>
                      <TableCell className={classes.currencyAmount}>
                        {formatCurrency(d.employerBenefit)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter className={classes.total}>
          <TableRow>
            <TableCell colSpan={showChoicePeriod ? 2 : 1}>
              {t("Admin:ChoiceTotal")}
            </TableCell>
            <TableCell>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.currencyUnit}>€</TableCell>
                    <TableCell className={classes.currencyAmount}>
                      {formatCurrency(total.employeeBenefit)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.currencyUnit}>€</TableCell>
                    <TableCell className={classes.currencyAmount}>
                      {formatCurrency(total.employerBenefit)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default memo(ChoiceBenefitsTable);
