import { Typography } from "@material-ui/core";
import { Direction } from "@material-ui/core/styles";
import * as React from "react";

interface TabContainerProps {
  children?: React.ReactNode;
  dir?: Direction;
}

const TabContainer: React.NamedExoticComponent<TabContainerProps> =
  React.memo<TabContainerProps>(
    ({ children, dir }: TabContainerProps): React.ReactElement => {
      return (
        <Typography component="div" dir={dir}>
          {children}
        </Typography>
      );
    }
  );

export default TabContainer;
