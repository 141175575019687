import { MenuItem } from "@material-ui/core";
import { Select, SelectProps } from "formik-material-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ChoiceExportFormat,
  ChoiceExportFormatExtra,
} from "../../../business/models";

type ChoiceExportFormatSelectProps = SelectProps & {
  extra: ChoiceExportFormatExtra;
};

const ChoiceExportFormatSelect = (props: ChoiceExportFormatSelectProps) => {
  const {
    extra,
    field: { value },
  } = props;
  const [t] = useTranslation("Admin");

  const formatLabels: { [key in ChoiceExportFormat]: string } = useMemo(
    () => ({
      [ChoiceExportFormat.generic]: t("choiceExportFormat.generic"),
      [ChoiceExportFormat.nmbrs]: t("choiceExportFormat.nmbrs"),
      [ChoiceExportFormat.afas]: t("choiceExportFormat.afas"),
      [ChoiceExportFormat.adp]: t("choiceExportFormat.adp"),
    }),
    [t]
  );

  return (
    <Select {...props}>
      {Object.values(ChoiceExportFormat)
        .filter(
          (ef) =>
            ef === ChoiceExportFormat.generic ||
            (value !== ChoiceExportFormat.generic && ef === value) ||
            ef.toString() === extra.toString()
        )
        .map((format) => (
          <MenuItem key={format} value={format}>
            {formatLabels[format]}
          </MenuItem>
        ))}
    </Select>
  );
};

export default ChoiceExportFormatSelect;
