import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { typographyBold } from "../../styling/Common";

const useStyles = makeStyles((theme: Theme) => {
  const primaryHighlight = (
    theme.palette.primary as {} as {
      [index: number]: string;
    }
  )[400];
  const fontColor = theme.palette.getContrastText(primaryHighlight);

  return {
    root: {
      backgroundColor: primaryHighlight,
      color: fontColor,
      marginBottom: theme.spacing(),
      padding: theme.spacing() * 3,
    },
    grid: {},
    gridAvatar: {
      margin: "auto",
    },
    fullName: {
      ...typographyBold(),
      color: fontColor,
      fontSize: "1rem",
    },
    role: {
      color: fontColor,
      fontSize: "1rem",
    },
    department: {
      color: fontColor,
      fontSize: "1rem",
    },
    expandAllButton: {
      "& path": {
        fill: fontColor,
      },
      margin: "auto 0 0 0",
    },
  };
});

export default useStyles;
