import { MenuItem, Select } from "@material-ui/core";
import i18next from "i18next";
import React from "react";

const LanguageSelector = () => {
  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    i18next.changeLanguage(event.target.value as string);
  };

  return (
    <Select onChange={handleChange} value={i18next.language}>
      <MenuItem key="nl" title="Nederlands" value="nl">
        NL
      </MenuItem>
      <MenuItem key="en-US" title="English" value="en-US">
        EN
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
