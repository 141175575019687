import {
  CircularProgress,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DeleteForever as DeleteForeverIcon,
  Description,
} from "@material-ui/icons";
import { Details, Some, Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TotlResponse } from "../../business/api";
import { DownloadModel } from "../../business/models";
import { dmsDownloadCell } from "../../business/redux/saga/dms/cells";
import { StoreModel } from "../../business/redux/saga/models";
import {
  personalDownloadsCell,
  personalUploadTypesCell,
} from "../../business/redux/saga/personal/cells";
import { personalDownloadRemoveCell } from "../../business/redux/saga/personal/download/cells";
import { PersonalStoreModel } from "../../business/redux/saga/personal/models";
import useConfirm from "../../confirm";
import { useTranslationStrict } from "../../globalization/i18n";
import { getContentTypeName } from "../../utils/FileUtils";
import useStyles from "../pages/ContentPage.styles";

interface DownloadsListProps {
  uploadTypeId?: number;
}
const DownloadsList = ({ uploadTypeId }: DownloadsListProps) => {
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const {
    downloads: { value: downloads },
    uploadTypes: { value: uploadTypes },
  } = useSelector(({ personal }: StoreModel): PersonalStoreModel => personal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(personalDownloadsCell.require());
  }, [dispatch, uploadTypeId]);
  useEffect(() => {
    if (uploadTypes) {
      return;
    }
    dispatch(personalUploadTypesCell.require());
  }, [dispatch, uploadTypes]);
  const { enqueueSnackbar } = useSnackbar();

  const [currentYear, setYear] = useState<number | undefined>(
    new Date().getFullYear()
  );
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, [uploadTypeId]);

  const handleDownloadClick =
    (download: DownloadModel): (() => void) =>
    () => {
      dispatch(
        dmsDownloadCell.require(`/api/download/${download.id}`, {
          onFail: (details: Details) => {
            enqueueSnackbar(details.detail || download.id, {
              variant: "error",
            });
          },
          onSuccess: ({ body: { link } }: Success<TotlResponse>) => {
            try {
              if (!window.open(link, "_blank")) {
                window.location = link as unknown as Location;
              }
            } catch (e) {
              enqueueSnackbar((e as Error).message || (e as Some).toString(), {
                variant: "error",
              });
            }
          },
        })
      );
    };

  const { confirmAction } = useConfirm();
  const handleDelete = (id: number): void =>
    confirmAction(personalDownloadRemoveCell.require(id));

  if (!uploadTypeId) {
    return null;
  }

  if (!downloads || !uploadTypes) {
    return <CircularProgress />;
  }

  const typed = downloads.filter(
    (d): boolean => d.uploadTypeId === uploadTypeId
  );
  if (typed.length === 0) {
    return null;
  }

  const uploadType = uploadTypes.find((ut): boolean => ut.id === uploadTypeId);
  if (!uploadType) {
    return null;
  }

  let filterElement: JSX.Element | null = null;

  if (uploadType.perPeriod) {
    const years = Array.from(
      new Set(typed.map((d): number | undefined => d.year)).values()
    );
    years.sort();
    years.reverse();
    if (years.indexOf(currentYear) < 0) {
      setYear(years[0]);
    }
    if (years.length > 1) {
      filterElement = (
        <FormControl>
          <Select
            onChange={(e): void => {
              setYear(Number(e.target.value));
            }}
            value={currentYear}
          >
            {years.map(
              (year): JSX.Element => (
                <MenuItem key={year} value={year}>
                  {year || "-"}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      );
    }
  }

  const filtered = typed.filter(
    (d): boolean =>
      !uploadType.perPeriod || !currentYear || d.year === currentYear
  );
  return (
    <>
      <Typography variant="h6">
        <span className={classes.left}></span>
        <span className={classes.right}>{filterElement}</span>
      </Typography>
      <List className={classes.downloadList}>
        {filtered.map(
          (d): JSX.Element => (
            <ListItem key={d.id} button onClick={handleDownloadClick(d)}>
              <ListItemIcon classes={{ root: classes.menuIcon }}>
                <Description />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  classes: { root: classes.menuText },
                }}
                classes={{ root: classes.listItemText }}
              >
                {d.year}/{d.period} {d.documentName}
              </ListItemText>
              <ListItemSecondaryAction
                classes={{ root: classes.listItemSecondaryAction }}
              >
                {d.employeeOwned ? (
                  <Tooltip title={t("Common:Delete")}>
                    <IconButton
                      style={{ float: "right" }}
                      onClick={(): void => handleDelete(d.id)}
                      classes={{ root: classes.danger }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Typography component="span">
                    {getContentTypeName(t, d.contentType || "unknown")}
                  </Typography>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          )
        )}
      </List>
    </>
  );
};

export default DownloadsList;
