import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing() * 3,
  },
  image: {
    width: "100%",
  },
}));

export default useStyles;
