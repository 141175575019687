import { Grid, Typography } from "@material-ui/core";

import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";
import { WlzTextFieldDisplay, WlzYesNoFieldDisplay } from "./";

interface Props {
  adviceCase: WlzAdviceCase;
}

const WlzAdviceCaseSummaryCaseTable = (props: Props) => {
  const {
    adviceCase: { title, yearCalculation, couple, note },
  } = props;
  const [t] = useTranslationStrict();

  return (
    <Grid item>
      <Grid item>
        <Typography variant="h6">{t("Wlz:Advies Casus")}</Typography>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <WlzTextFieldDisplay name={t("Wlz:Titel")} value={title} />
        </Grid>
        <Grid item>
          <WlzTextFieldDisplay
            name={t("Wlz:Jaar berekening")}
            value={yearCalculation.getFullYear().toString()}
          />
        </Grid>
        <Grid item>
          <WlzYesNoFieldDisplay name={t("Wlz:Paar")} value={couple} />
        </Grid>
        <Grid item>
          <WlzTextFieldDisplay name={t("Wlz:Notitie")} value={note} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WlzAdviceCaseSummaryCaseTable;
