import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { ChoiceData } from "../../business/alacarte";
import { useTranslationStrict } from "../../globalization/i18n";

interface ChoiceRepeatViewProps {
  choice: ChoiceData;
}

const ChoicePayrollingView = ({ choice }: ChoiceRepeatViewProps) => {
  const { repeating = false } = choice;
  const [t] = useTranslationStrict();

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle2">{t("Alacarte:Payrolling")}</Typography>
      </Grid>
      <Grid item>
        <Typography>
          {repeating ? t("Alacarte:Repeat") : t("Alacarte:Once")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChoicePayrollingView;
