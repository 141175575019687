import classNames from "classnames";
import { DragEventHandler, useCallback, useState } from "react";

import { Loading } from "../../../ux";
import useStyles from "./AppBarDropArea.styles";

interface AppBarDropAreaProps {
  index: number;
  loading: boolean;
  variant: "main" | "sub";

  onDrop(childId: number, index: number): void;
}

const AppBarDropArea = (props: AppBarDropAreaProps) => {
  const { index, loading, onDrop, variant } = props;
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(true);
    },
    []
  );

  const handleDragLeave: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);
    },
    []
  );

  const handleDragOver: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
    },
    []
  );

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const childIdString = event.dataTransfer.getData("text");
      const childId = parseInt(childIdString);
      onDrop(childId, index);
      setIsDragging(false);
    },
    [index, onDrop]
  );

  return (
    <div
      className={classNames(classes.root, classes[variant], {
        [classes.dropping]: isDragging,
        [classes.loading]: loading,
        [classes.open]: !isDragging,
      })}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {loading && <Loading />}
    </div>
  );
};

export default AppBarDropArea;
