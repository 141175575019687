import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import React, { MutableRefObject, RefObject, useCallback } from "react";

import {
  ContentNodeModel,
  DocumentContentNodeModel,
  NavigationNodeFormValues,
} from "../../../business/models";
import {
  ContentNodeDeleteButton,
  ContentNodeEditButton,
  ContentNodeShiftButton,
} from "./";
import DocumentListItemInsertButton from "./ContentNodeDocumentListItemInsertButton";

const useStyles = makeStyles(() => ({
  actionItem: {
    width: "35px",
  },
}));

interface Props {
  contentNode: ContentNodeModel;
  disabled?: boolean;
  formRef?: RefObject<Formik<NavigationNodeFormValues>>;
  onEdit?(contentNode: ContentNodeModel): void;
  showShiftDown: boolean;
  showShiftUp: boolean;
  text?: string;
  textCurrent?: MutableRefObject<string>;
  textCursorPosition?: MutableRefObject<number>;
}

const ContentNodeActions = (props: Props) => {
  const {
    contentNode,
    contentNode: { id, title },
    disabled = false,
    formRef,
    onEdit,
    showShiftDown,
    showShiftUp,
    text,
    textCurrent,
    textCursorPosition,
  } = props;

  // If it is a document.
  const { contentType, documentName } = contentNode as DocumentContentNodeModel;

  const classes = useStyles();

  const handleInsert = useCallback(
    (documentId: number, documentName: string) => {
      const currentText = textCurrent?.current || text;
      const currentTextCursorPosition =
        textCursorPosition?.current || currentText?.length;
      const imageLink = `![${documentName}](/img/${documentId})`;
      if (textCursorPosition) {
        formRef?.current!.setFieldValue(
          "text",
          [
            currentText?.slice(0, currentTextCursorPosition),
            imageLink,
            currentText?.slice(currentTextCursorPosition),
          ].join("")
        );
      } else {
        formRef?.current!.setFieldValue("text", currentText + imageLink);
      }
    },
    [formRef, text, textCurrent, textCursorPosition]
  );

  return (
    <Grid container direction="row" spacing={0}>
      {contentType?.startsWith("image/") &&
        contentNode.id &&
        formRef &&
        text !== undefined &&
        textCurrent &&
        textCursorPosition && (
          <Grid item classes={{ root: classes.actionItem }}>
            <DocumentListItemInsertButton
              id={contentNode.id}
              documentName={title || documentName}
              onInsert={handleInsert}
            />
          </Grid>
        )}
      <Grid item classes={{ root: classes.actionItem }}>
        {showShiftUp && (
          <ContentNodeShiftButton
            contentNodeId={id || -1}
            disabled={disabled}
            mode="Up"
          />
        )}
      </Grid>
      <Grid item classes={{ root: classes.actionItem }}>
        {showShiftDown && (
          <ContentNodeShiftButton
            contentNodeId={id || -1}
            disabled={disabled}
            mode="Down"
          />
        )}
      </Grid>
      <Grid item classes={{ root: classes.actionItem }}>
        {onEdit && (
          <ContentNodeEditButton
            contentNode={contentNode}
            disabled={disabled}
            onEdit={onEdit}
          />
        )}
      </Grid>
      <Grid item classes={{ root: classes.actionItem }}>
        <ContentNodeDeleteButton
          contentNodeId={id || -1}
          contentNodeTitle={title}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default ContentNodeActions;
