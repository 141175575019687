import { IconButton, Tooltip } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";

import {
  adminNavigationContentShiftDownCell,
  adminNavigationContentShiftUpCell,
} from "../../../business/redux/saga/admin/navigation/content/cells";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";

export type ContentNodeShiftMode = "Up" | "Down";
const getTooltipTitle = (t: TFunctionStrict, mode: ContentNodeShiftMode) => {
  switch (mode) {
    case "Down":
      return t("Common:Downward");
    case "Up":
      return t("Common:Upward");
    default:
      throw new Error("Unsupported shift mode.");
  }
};

interface Props {
  contentNodeId: number;
  disabled?: boolean;
  mode: ContentNodeShiftMode;
}

const ContentNodeShiftButton = (props: Props) => {
  const { contentNodeId, disabled = false, mode } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

  const handleDown = () => {
    switch (mode) {
      case "Down":
        dispatch(
          adminNavigationContentShiftDownCell.require(
            {
              contentNodeId,
            },
            {
              onFail: showErrorMessageFromDetails(
                t("Admin:Item naar beneden schuiven mislukt.")
              ),
            }
          )
        );
        break;
      case "Up":
        dispatch(
          adminNavigationContentShiftUpCell.require(
            {
              contentNodeId,
            },
            {
              onFail: showErrorMessageFromDetails(
                t("Admin:Item naar boven schuiven mislukt.")
              ),
            }
          )
        );
    }
  };

  return (
    <Tooltip title={getTooltipTitle(t, mode)}>
      <IconButton disabled={disabled} onClick={handleDown} size="small">
        {mode === "Down" && <ArrowDownward />}
        {mode === "Up" && <ArrowUpward />}
      </IconButton>
    </Tooltip>
  );
};

export default ContentNodeShiftButton;
