import React, { FunctionComponent, memo, useCallback } from "react";
import { useSelector } from "react-redux";

import { emptyOrganization } from "../../../business/models";
import { StoreModel } from "../../../business/redux/saga/models";
import NmbrsPasswordResetCard from "../../../components/authentication/nmbrs/NmbrsPasswordResetCard";
import PasswordResetCard from "../../../components/authentication/PasswordResetCard";
import useStyles from "./ResetPage.styles";

const ResetPage: FunctionComponent = () => {
  const classes = useStyles();

  const nmbrsDomain = useSelector(
    ({
      organization: {
        get: { value: { nmbrsDomain } = emptyOrganization },
      },
    }: StoreModel) => nmbrsDomain
  );

  const renderPasswordResetCard = useCallback(() => {
    if (nmbrsDomain) {
      return <NmbrsPasswordResetCard nmbrsDomain={nmbrsDomain} />;
    }
    return <PasswordResetCard />;
  }, [nmbrsDomain]);

  return (
    <div className={classes.gridMainContent}>{renderPasswordResetCard()}</div>
  );
};

export default memo(ResetPage);
