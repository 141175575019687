import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    height: 450,
    margin: 5,
    width: 550,
  },
}));

export default useStyles;
