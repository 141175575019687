import { Button, Grid } from "@material-ui/core";
import React from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  onEdit: () => void;
}

const EmployeeDataViewActions = (props: Props) => {
  const { onEdit } = props;

  // Hooks
  const [t] = useTranslationStrict();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button onClick={onEdit}>{t("Common:Edit")}</Button>
      </Grid>
    </Grid>
  );
};

export default EmployeeDataViewActions;
