import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { typographyBold } from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    menuIcon: { color: theme.palette.common.black },
    listItemText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "70%",
    },
    listItemSecondaryAction: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "20%",
    },
    menuText: {
      ...typographyBold(),
      fontSize: "1rem !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    danger: {
      color: theme.palette.error.main,
    },
  })
);

export default useStyles;
