import { combineReducers } from "redux";

import adminReducer from "./saga/admin/reducer";
import announcementsReducer from "./saga/announcements/reducer";
import authenticationReducer from "./saga/authentication/reducer";
import datacockpitReducer from "./saga/datacockpit/reducer";
import dmsReducer from "./saga/dms/reducer";
import { employerReducer } from "./saga/employer";
import navigationReducer from "./saga/navigation/reducer";
import { organizationReducer } from "./saga/organization";
import personalReducer from "./saga/personal/reducer";
import profileReducer from "./saga/profile/reducer";
import { searchCell } from "./saga/search/cells";
import sessionReducer from "./saga/session/reducer";
import superReducer from "./saga/super/reducer";
import userReducer from "./saga/user/reducer";

export default combineReducers({
  admin: adminReducer,
  announcements: announcementsReducer,
  authentication: authenticationReducer,
  datacockpit: datacockpitReducer,
  dms: dmsReducer,
  employer: employerReducer,
  navigation: navigationReducer,
  organization: organizationReducer,
  personal: personalReducer,
  profile: profileReducer,
  search: searchCell.reducer,
  session: sessionReducer,
  super: superReducer,
  user: userReducer,
});
