import { Grid, Typography } from "@material-ui/core";
import { flow } from "lodash/fp";
import { useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import handlebars from "../../../business/handlebars";
import { NavigationNodeModel, employersEmpty } from "../../../business/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { renderDate } from "../../../utils/DateUtils";
import {
  internalImageReferenceRegex,
  populateImages,
  resolveImgLink,
} from "../../../utils/ImageUtils";
import { isRemoteLink } from "../../../utils/LinkUtils";
import useAsync from "../../../utils/useAsync";
import GreenChoice from "../../enienl/GreenChoice";
import useStyles from "../../pages/ContentPage.styles";
import DebitCardView from "../../personal/DebitCardView";
import { WlzAdviceView } from "../../personal/wlz";
import EmployerAddButton from "../employers/EmployerAddButton";
import EmployersTable from "../employers/EmployersTable";

interface Props {
  node: NavigationNodeModel;
}

const ContentPageContents = (props: Props) => {
  const {
    node: {
      id,
      alacarteInfo,
      contractAlert,
      contractStart,
      contractEnd,
      contractClientName,
      contractClientEmail,
      contractSupplierName,
      contractValue,
      special,
      text,
    },
  } = props;

  /* Hooks */
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();

  /* Selectors */
  const employersListSpecial = useSelector(
    (store: StoreModel) => store.admin.employers.listSpecial
  );
  const {
    definitions: { value: definitions },
    data: { value: data },
  } = useSelector((store: StoreModel) => store.personal);

  /* Images */
  const loadImageLinks = useCallback(async () => {
    let allIds = [...text.matchAll(internalImageReferenceRegex)].map((match) =>
      Number(match[2])
    );
    const pairs = await Promise.all(
      [...new Set(allIds)].map(resolveImgLink(dispatch))
    );
    return pairs.reduce<{
      [id_1: number]: string;
    }>((links, [id_2, link]) => ({ ...links, [id_2]: link }), {});
  }, [text, dispatch]);
  const { value: imageLinks } = useAsync(loadImageLinks, [loadImageLinks]);

  /* Format text */
  const nodeText = useMemo(
    (): string =>
      data && definitions && text && imageLinks
        ? flow(handlebars, (txt) => populateImages(txt, imageLinks))(
            text,
            data,
            definitions,
            t
          )
        : "",
    [data, definitions, text, t, imageLinks]
  );

  return (
    <>
      {nodeText && (
        <ReactMarkdown
          className={classes.markdown}
          linkTarget={(uri) => (isRemoteLink(uri) ? "_blank" : "_self")}
        >
          {nodeText}
        </ReactMarkdown>
      )}
      {special === "alacarte" && (
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h5">
              {t("Alacarte:'Your benefit' disclaimer")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <ReactMarkdown>
                {alacarteInfo ?? t("Alacarte:BenefitDisclaimer")}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      )}
      {special === "contract" && (
        <Grid container direction="row">
          <Grid item xs={12} classes={{ item: classes.propertiesHeading }}>
            <Typography variant="subtitle1">
              {t("Communication:ContractDates")}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractLifetime")}
            </Typography>
            <Typography variant="body1">
              {contractStart && renderDate(contractStart)}
              {" - "}
              {contractEnd && renderDate(contractEnd)}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractAlert")}
            </Typography>
            <Typography variant="body1">
              {contractAlert && renderDate(contractAlert)}
            </Typography>
          </Grid>
          <Grid item xs={12} classes={{ item: classes.propertiesHeading }}>
            <Typography variant="subtitle1">
              {t("Communication:ContractContact")}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractClientName")}
            </Typography>
            <Typography variant="body1">{contractClientName}</Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractClientEmail")}
            </Typography>
            <Typography variant="body1">
              <a href={`mailto:${contractClientEmail}`}>
                {contractClientEmail}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} classes={{ item: classes.propertiesHeading }}>
            <Typography variant="subtitle1">
              {t("Communication:ContractData")}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractSupplierName")}
            </Typography>
            <Typography variant="body1">
              {contractSupplierName || "-"}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption">
              {t("Communication:ContractValue")}
            </Typography>
            <Typography variant="body1">{contractValue || "-"}</Typography>
          </Grid>
        </Grid>
      )}
      {special === "employers" && (
        <>
          <EmployersTable
            employers={employersListSpecial.value || employersEmpty}
          />
          <EmployerAddButton />
        </>
      )}
      {special === "greenChoice" && (
        <GreenChoice employers={employersListSpecial.value || employersEmpty} />
      )}
      {id && special === "wlzAdvice" && <WlzAdviceView navigationNodeId={id} />}
      {id && special === "debitCard" && <DebitCardView />}
    </>
  );
};

export default ContentPageContents;
