import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Clear as ClearIcon, Email as EmailIcon } from "@material-ui/icons";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { useUser } from "../../business/redux/saga/user/hooks";
import { useTranslationStrict } from "../../globalization/i18n";
import { useSnackbarErrorMessage } from "../ux/SnackbarErrorMessage";
import useStyles from "./PasswordResetCard.styles";

interface ResetFormValues {
  email: string;
}

const initialFormValues: ResetFormValues = {
  email: "",
};

const PasswordResetCard = () => {
  const [t, i18next] = useTranslationStrict();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const { resetPasswordRequest } = useUser();
  const history = useHistory();
  const classes = useStyles();

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const ValidationSchema: Yup.ObjectSchema<
    Yup.Shape<ResetFormValues, ResetFormValues>
  > = useMemo(
    (): Yup.ObjectSchema<Yup.Shape<ResetFormValues, ResetFormValues>> =>
      Yup.object<ResetFormValues>().shape<ResetFormValues>({
        email: Yup.string()
          .email(t("Forms:EmailInvalid"))
          .required(t("Forms:Required")),
      }),
    [t]
  );

  const handleCancel = useCallback(() => {
    history.push("/login");
  }, [history]);

  const handleSubmit = useCallback(
    (
      { email }: ResetFormValues,
      actions: FormikActions<ResetFormValues>
    ): void => {
      resetPasswordRequest(
        { email, culture: i18next.language },
        {
          onFail: (details) => {
            showErrorMessageFromDetails(t("User:ResetPasswordFailed"))(details);
            actions.setSubmitting(false);
          },
          onSuccess: () => {
            setIsEmailSent(true);
            actions.setSubmitting(false);
          },
        }
      );
    },
    [i18next.language, resetPasswordRequest, showErrorMessageFromDetails, t]
  );

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleCancel}>
            <ClearIcon />
          </IconButton>
        }
        title={t("User:ResetHeader")}
      />
      <CardContent>{t("User:ResetMessage")}</CardContent>
      <CardActions style={{ padding: 16 }}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
          render={({
            isValid,
            isSubmitting,
            values: { email: currentEmail },
          }: FormikProps<ResetFormValues>): ReactElement => (
            <Form>
              <Grid container direction="column" spacing={10}>
                <Grid item>
                  <FormControl>
                    <Field
                      component={TextField}
                      type="email"
                      name="email"
                      label={t("User:Email")}
                      placeholder="email@domain.com"
                      value={currentEmail}
                    />
                  </FormControl>
                  <Collapse in={isEmailSent}>
                    <Paper
                      elevation={8}
                      classes={{ root: classes.emailSentMessage }}
                    >
                      <Grid container direction="row" spacing={8}>
                        <Grid item xs={2}>
                          <EmailIcon color="inherit" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>{t("User:EmailSentMessage")}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Collapse>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                    >
                      {t("User:ResetButton")}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        />
      </CardActions>
    </Card>
  );
};

export default PasswordResetCard;
