import {
  Fab,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { PlaylistAddCheck as PlaylistAddCheckIcon } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { SortOrder } from "../../../business/models";
import {
  adminUsersInviteAllNewCell,
  adminUsersListCell,
} from "../../../business/redux/saga/admin/users/cells";
import {
  AdminUsersInviteAllNewRequestResult,
  AdminUsersInviteAllNewStatus,
} from "../../../business/redux/saga/admin/users/models";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux";
import PaginationTableRow from "../../ux/PaginationTableRow";
import UsersEditorTableHeadSortableColumn, {
  SortableColumnVariant,
} from "./UsersEditorTableHeadSortableColumn";

interface Props {
  itemsPerPage: number;
  onItemsPerPageSelected(itemsPerPage: number): void;
  onPageSelected(page: number): void;
  onSort(sortIndex: number | null, sortOrder: SortOrder): void;
  selectedPage: number;
  showPlatformAdmin: boolean;
  showApiAdmin: boolean;
  sortIndex: number | null;
  sortOrder: SortOrder;
  usersCount: number;
}

const UsersEditorTableHead = (props: Props) => {
  const {
    itemsPerPage,
    onItemsPerPageSelected,
    onPageSelected,
    onSort,
    selectedPage,
    showPlatformAdmin,
    showApiAdmin,
    sortIndex,
    sortOrder,
    usersCount,
  } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessage, showErrorMessageFromDetails } =
    useSnackbarErrorMessage();

  const handleSort = useCallback(
    /**
     * Handles a click event on one of the sorting labels.
     */
    (index: number) => {
      onSort(index, sortOrder === "asc" ? "desc" : "asc");
    },
    [onSort, sortOrder]
  );

  const { confirm } = useConfirm();
  const handleInviteAllNew = useCallback(() => {
    confirm({
      texts: {
        title: t("Admin:InviteAllNewUsers"),
      },
      onConfirm: () => {
        dispatch(
          adminUsersInviteAllNewCell.require({
            onFail: showErrorMessageFromDetails(t("Admin:InviteAllNewFailed")),
            onSuccess: ({
              body: { status },
            }: Success<AdminUsersInviteAllNewRequestResult>) => {
              if (status === AdminUsersInviteAllNewStatus.succeeded) {
                dispatch(adminUsersListCell.require());
                enqueueSnackbar(t("Admin:InviteAllNewSuccess"), {
                  variant: "success",
                });
              } else {
                showErrorMessage({
                  title: t("Admin:InviteAllNewFailed"),
                  message: "",
                });
              }
            },
          })
        );
      },
    });
  }, [
    confirm,
    dispatch,
    enqueueSnackbar,
    showErrorMessage,
    showErrorMessageFromDetails,
    t,
  ]);

  return (
    <TableHead>
      <PaginationTableRow
        colSpan={10}
        itemsCount={usersCount}
        itemsPerPage={itemsPerPage}
        onItemsPerPageSelected={onItemsPerPageSelected}
        onPageSelected={onPageSelected}
        selectedPage={selectedPage}
      />
      <TableRow>
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 0}
          index={0}
          label={t("Admin:LoginName")}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 1}
          index={1}
          label={t("Admin:FullName")}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 2}
          index={2}
          label={t("Admin:Email")}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 3}
          index={3}
          label={t("Admin:EmployeeType")}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 4}
          index={4}
          label={t("Admin:RoleLetterEmployee")}
          onSort={handleSort}
          sortOrder={sortOrder}
          tooltipTitle={t("Admin:employee") as string}
          variant={SortableColumnVariant.role}
        />
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 5}
          index={5}
          label={t("Admin:RoleLetterOrganizationAdmin")}
          onSort={handleSort}
          sortOrder={sortOrder}
          tooltipTitle={t("Admin:organizationAdmin") as string}
          variant={SortableColumnVariant.role}
        />
        {showPlatformAdmin && (
          <UsersEditorTableHeadSortableColumn
            active={sortIndex === 6}
            index={6}
            label={t("Admin:RoleLetterDatacockpit")}
            onSort={handleSort}
            sortOrder={sortOrder}
            tooltipTitle={t("Admin:Datacockpit")}
            variant={SortableColumnVariant.role}
          />
        )}
        {showPlatformAdmin && (
          <UsersEditorTableHeadSortableColumn
            active={sortIndex === 7}
            index={7}
            label={t("Admin:RoleLetterPlatformAdmin")}
            onSort={handleSort}
            sortOrder={sortOrder}
            tooltipTitle={t("Admin:platformAdmin") as string}
            variant={SortableColumnVariant.role}
          />
        )}
        {showApiAdmin && (
          <UsersEditorTableHeadSortableColumn
            active={sortIndex === 8}
            index={8}
            label={t("Admin:RoleLetterApiAdmin")}
            onSort={handleSort}
            sortOrder={sortOrder}
            tooltipTitle={t("Admin:apiAdmin") as string}
            variant={SortableColumnVariant.role}
          />
        )}
        {showApiAdmin && (
          <UsersEditorTableHeadSortableColumn
            active={sortIndex === 9}
            index={9}
            label={t("Admin:RoleLetterApiAccess")}
            onSort={handleSort}
            sortOrder={sortOrder}
            tooltipTitle={t("Admin:apiAccess") as string}
            variant={SortableColumnVariant.role}
          />
        )}
        <UsersEditorTableHeadSortableColumn
          active={sortIndex === 10}
          index={10}
          label={t("Admin:UserStatus")}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
        <TableCell>
          <Grid aria-orientation="horizontal" container>
            <Grid item style={{ padding: 16, textTransform: "uppercase" }}>
              {t("Admin:Actions")}
            </Grid>
            <Grid item>
              <Tooltip title={t("Admin:InviteAllNewUsers")}>
                <Fab
                  aria-label="InviteAllNew"
                  color="primary"
                  onClick={handleInviteAllNew}
                  variant="extended"
                >
                  <PlaylistAddCheckIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default memo(UsersEditorTableHead);
