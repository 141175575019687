import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Delete, SaveAlt } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContractsExportFile } from "../../../business/admin/contracts/models";
import { FileResponse } from "../../../business/api";
import {
  adminContractsExportDeleteCell,
  adminContractsExportDownloadCell,
} from "../../../business/redux/saga/admin/contracts/cells";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import { renderDate } from "../../../utils/DateUtils";

interface ContractsExportTableRowProps {
  item: ContractsExportFile;
}

const ContractsExportTableRow = (props: ContractsExportTableRowProps) => {
  const { item } = props;
  const dispatch = useDispatch();
  const { confirmAction } = useConfirm();
  const [t, { language }] = useTranslationStrict();

  const {
    status: { loading: deleteLoading },
  } = useSelector(adminContractsExportDeleteCell.select);
  const {
    status: { loading: downloadLoading },
  } = useSelector(adminContractsExportDownloadCell.select);
  const busy = deleteLoading || downloadLoading;

  const onDownload = useCallback(() => {
    dispatch(
      adminContractsExportDownloadCell.require(item.id, {
        onSuccess: ({ body: { data, name } }: Success<FileResponse>) => {
          FileSaver.saveAs(data, name);
        },
      })
    );
  }, [dispatch, item.id]);

  const onDelete = useCallback(() => {
    confirmAction(adminContractsExportDeleteCell.require(item.id));
  }, [confirmAction, item.id]);

  return (
    <TableRow key={item.id}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{renderDate(item.date, language)}</TableCell>
      <TableCell>
        {busy && <CircularProgress />}
        {!busy && (
          <IconButton
            onClick={onDownload}
            type="button"
            color="secondary"
            title={t("Common:Download")}
          >
            <SaveAlt />
          </IconButton>
        )}
        {!busy && (
          <IconButton
            onClick={onDelete}
            type="button"
            color="secondary"
            title={t("Common:Delete")}
          >
            <Delete />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ContractsExportTableRow;
