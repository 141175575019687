import { createActor, createForm } from "async-lifecycle-saga";
import { Success } from "async-lifecycle-saga/dist/models";

import { asyncGet, asyncPost } from "../../../api";
import { AnnouncementModel } from "../../../models";
import { asyncAddFile } from "./api";
import { AnnouncementResponse } from "./models";

export const announcementsListPagedCell = createForm<
  { pageNumber: number; pageSize?: number },
  AnnouncementResponse
>({
  path: ["announcements", "listPaged"],
  api: ({ pageNumber, pageSize = 5 }) =>
    asyncGet(
      pageSize !== 5
        ? `/api/announcement/page/${pageNumber}?pageSize=${pageSize}`
        : `/api/announcement/page/${pageNumber}`
    ),
});

export const announcementsAddCell = createActor<
  AnnouncementModel & { notifyUsers: boolean },
  AnnouncementResponse
>({
  path: ["announcements", "add"],
  api: ({ notifyUsers, ...announcement }) =>
    asyncPost<AnnouncementResponse>(
      `/api/announcement/add/${notifyUsers}`,
      announcement
    ).then(async (response) => {
      if (
        !(
          announcement.files &&
          announcement.files[0] &&
          (response as Success<AnnouncementResponse>).body &&
          (response as Success<AnnouncementResponse>).body.id
        )
      ) {
        return response;
      }

      await asyncAddFile(
        (response as Success<AnnouncementResponse>).body.id!,
        announcement.files[0]
      );
      return response;
    }),
  invalidate: [announcementsListPagedCell.events],
  onRefresh: () => "clear",
});

export const announcementsRetireCell = createActor<
  number,
  AnnouncementResponse
>({
  path: ["announcements", "retire"],
  api: (announcementId) =>
    asyncPost(`/api/announcement/retire/${announcementId}`, {}),
  invalidate: [announcementsListPagedCell.events],
  onRefresh: () => "clear",
});
