import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { smallScreen } from "../../styling/Common";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
  },
  ...smallScreen({
    root: {
      display: "grid",
      gridAutoFlow: "dense",
      gridAutoRows: "auto",
      gridGap: `${4 * theme.spacing()}px 1.5vw`,
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "auto 1fr",
    },
  }),
}));

export default useStyles;
