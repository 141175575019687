import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

const daywizeHostname = "daywize.mendixcloud.com";

interface DaywizeSsoNotificationDialogProps {
  onClose(): void;
  open: boolean;
}

const DaywizeSsoNotificationDialog = ({
  onClose,
  open,
}: DaywizeSsoNotificationDialogProps) => {
  const [t] = useTranslationStrict();

  const onConfirm = useCallback(() => {
    window.open(`https://${daywizeHostname}/`, "_blank");
    onClose();
  }, [onClose]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t("User:DaywizeSsoLinkTitle")}</DialogTitle>
      <DialogContent>
        <Typography>{t("User:DaywizeSsoLinkContent")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t("Common:Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {t("Common:Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DaywizeSsoNotificationDialog;
