import {
  createActor,
  createForm,
  createList,
  createSingle,
} from "async-lifecycle-saga";
import { Success, createEvents } from "async-lifecycle-saga/dist/models";

import { fromDate, toDate } from "../../../../../utils/DateUtils";
import {
  FileResponse,
  asyncDownloadGet,
  asyncGet,
  asyncPost,
  asyncPut,
} from "../../../../api";
import { ChoiceBenefitsModel, ChoicePeriodModel } from "../../../../models";
import { StoreModel } from "../../models";
import {
  ApprovePayload,
  ChoiceDefinitionModel,
  ChoicePeriodDto,
  ChoiceSettingsModel,
  EmployeeChoiceSetModel,
} from "./models";

interface JsonSettingsModel {
  openFrom?: string;
  openTo?: string;
}

const fromJsonSettings = ({
  openFrom,
  openTo,
}: JsonSettingsModel): ChoiceSettingsModel => ({
  openFrom: toDate(openFrom),
  openTo: toDate(openTo),
});

const toJsonSettings = ({
  openFrom,
  openTo,
}: ChoiceSettingsModel): JsonSettingsModel => ({
  openFrom: fromDate(openFrom),
  openTo: fromDate(openTo),
});

export const adminChoiceBenefitsCell = createForm({
  path: ["admin", "choice", "benefits"],
  api: (id?: number) =>
    asyncGet<ChoiceBenefitsModel>(`/api/choice/admin/benefits/${id || ""}`),
});

export const adminChoiceDefinitionsListCell = createSingle<
  ChoiceDefinitionModel[],
  StoreModel
>({
  path: ["admin", "choice", "definitions", "list"],
  require: {
    api: () => asyncGet<ChoiceDefinitionModel[]>("/api/choice/definition"),
  },
});

export const adminChoiceEmployeeListCell = createList<
  EmployeeChoiceSetModel,
  StoreModel
>({
  path: ["admin", "choice", "employee", "list"],
  require: {
    api: () => asyncGet<EmployeeChoiceSetModel[]>("/api/choice/admin"),
  },
});

export const adminChoiceEmployeeResetCell = createActor<number, void>({
  path: ["admin", "choice", "employee", "reset"],
  api: (id: number) => asyncPut(`/api/choice/admin/reset/${id}`, {}),
});

export const adminChoiceEmployeeApproveCell = createActor<
  ApprovePayload,
  undefined
>({
  path: ["admin", "choice", "employee", "approve"],
  api: ({ approved, employeeIds }) =>
    asyncPost(`/api/choice/admin/approve/${approved}`, employeeIds),
  invalidate: [adminChoiceEmployeeListCell.events],
});

export const adminChoiceEmployeeExportMutationsCell = createActor<
  string,
  FileResponse
>({
  path: ["admin", "choice", "employee", "export"],
  api: (employerName) =>
    asyncDownloadGet(
      `/api/choice/admin/export${
        employerName ? `?employerName=${encodeURIComponent(employerName)}` : ""
      }`
    ),
});

export const adminChoicePeriodsCell = createList({
  path: ["admin", "choice", "periods"],
  require: {
    api: () =>
      asyncGet<ChoicePeriodDto[]>("/api/choice/admin/periods").then(
        (response) => {
          const success = response as Success<ChoicePeriodDto[]>;
          const dtos = success.body;
          return (
            dtos === undefined
              ? response
              : {
                  ...success,
                  body: dtos.map((period) => ({
                    id: period.id,
                    startDateTime: new Date(period.startDateTime),
                  })),
                }
          ) as Success<ChoicePeriodModel[]>;
        }
      ),
  },
});

export const adminChoiceSettingsEntityCell = createSingle<
  ChoiceSettingsModel,
  StoreModel,
  undefined,
  ChoiceSettingsModel
>({
  path: ["admin", "choice", "settings"],
  require: {
    api: () =>
      asyncGet<JsonSettingsModel>("/api/choice/definition/settings").then(
        (response) =>
          response.status === 200
            ? {
                ...response,
                body: fromJsonSettings(
                  (response as Success<JsonSettingsModel>).body
                ),
              }
            : response
      ),
  },
  update: {
    api: (settings) =>
      asyncPut("/api/choice/definition/settings", toJsonSettings(settings)),
  },
});

export const adminChoiceDefinitionsDownshiftCell = createActor({
  path: ["admin", "choice", "definitions", "downshift"],
  api: (definition: ChoiceDefinitionModel) =>
    asyncPost<ChoiceDefinitionModel>(
      `/api/choice/definition/downshift/${definition.dataDefinitionId}`,
      {}
    ),
  invalidate: [
    adminChoiceDefinitionsListCell.events,
    adminChoiceSettingsEntityCell.events,
    createEvents(["personal", "definitions"]),
  ],
});

export const adminChoiceDefinitionUpshiftCell = createActor({
  path: ["admin", "choice", "definitions", "upshift"],
  api: (definition: ChoiceDefinitionModel) =>
    asyncPost<ChoiceDefinitionModel>(
      `/api/choice/definition/upshift/${definition.dataDefinitionId}`,
      {}
    ),
  invalidate: [
    adminChoiceDefinitionsListCell.events,
    adminChoiceSettingsEntityCell.events,
    createEvents(["personal", "definition"]),
  ],
});

export const adminChoiceDefinitionsUpsertCell = createActor<
  ChoiceDefinitionModel[],
  {}
>({
  path: ["admin", "choice", "definitions", "upsert"],
  api: (definitions) => asyncPost("/api/choice/definition", definitions),
  invalidate: [
    adminChoiceDefinitionsListCell.events,
    adminChoiceSettingsEntityCell.events,
    createEvents(["personal", "definition"]),
  ],
});

export const adminInfoExportCell = createActor<
  number | undefined,
  FileResponse
>({
  path: ["admin", "info", "export"],
  api: (choicePeriodId) =>
    asyncDownloadGet(`/api/choice/admin/export/period/${choicePeriodId || ""}`),
});
