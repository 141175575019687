import { MenuItem } from "@material-ui/core";
import { DeleteForever as DeleteForeverIcon } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";

import { ChoiceSetModel } from "../../../business/models";
import { adminUsersDeleteCell } from "../../../business/redux/saga/admin/users/cells";
import {
  AdminUserDeleteRequestResult,
  AdminUserDeleteStatus,
} from "../../../business/redux/saga/admin/users/models";
import { personalChoicesByLoginNameCell } from "../../../business/redux/saga/personal/choices/cells";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux";

interface Props {
  loginName: string;
}

const UserDeleteMenuItem = (props: Props) => {
  const { loginName } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessage, showErrorMessageFromDetails } =
    useSnackbarErrorMessage();

  const { confirm } = useConfirm();

  const handleDeleteConfirm = () => {
    dispatch(
      adminUsersDeleteCell.require(loginName, {
        onFail: showErrorMessageFromDetails(t("Admin:DeleteUserError")),
        onSuccess: ({
          body: { status },
        }: Success<AdminUserDeleteRequestResult>) => {
          if (status === AdminUserDeleteStatus.succeeded) {
            enqueueSnackbar(t("Admin:DeleteUserSuccess"), {
              variant: "success",
            });
          } else {
            showErrorMessage({
              title: t("Admin:DeleteUserError"),
              message: "",
            });
          }
        },
      })
    );
  };
  const handleDeleteClick = () => {
    const confirmWithChoices = (choiceSet: ChoiceSetModel | undefined) => {
      const hasChoices = choiceSet && choiceSet.choiceStatus !== "initial";
      const content = hasChoices
        ? "UserDeleteConfirmMessageWithChoices"
        : "UserDeleteConfirmMessage";
      confirm({
        texts: {
          ns: "Admin",
          title: "Verwijderen",
          content,
          confirm: "Yes",
          cancel: "No",
        },
        onConfirm: handleDeleteConfirm,
        onCancel: () => {},
      });
    };

    dispatch(
      personalChoicesByLoginNameCell.require(loginName, {
        onFail: () => {
          confirmWithChoices(undefined);
        },
        onSuccess: ({ body }) => {
          confirmWithChoices(body);
        },
      })
    );
  };

  return (
    <MenuItem key="Delete" onClick={handleDeleteClick}>
      <DeleteForeverIcon style={{ color: "red" }} /> {t("Common:Delete")}
    </MenuItem>
  );
};

export default UserDeleteMenuItem;
