import i18next, { TOptions, i18n } from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import React, { Suspense, useMemo } from "react";
import {
  I18nextProvider,
  UseTranslationOptions,
  initReactI18next,
  useTranslation,
} from "react-i18next";

import Loading from "../components/ux/Loading";

export interface TFunctionStrict {
  <TInterpolationMap extends object>(
    key: string,
    options?: TOptions<TInterpolationMap> | string
  ): string;
}

export type UseTranslationResponseStrict = [TFunctionStrict, i18n, boolean];

export const useTranslationStrict = (
  ns?: string,
  options?: UseTranslationOptions
): UseTranslationResponseStrict => {
  const [t, i18n, ready] = useTranslation(ns, options);

  const tStrict: TFunctionStrict = useMemo(
    (): TFunctionStrict =>
      <TInterpolationMap extends object>(
        key: string,
        tOptions?: TOptions<TInterpolationMap> | string
      ): string =>
        t(key, tOptions) as string,
    [t]
  );

  return [tStrict, i18n, ready];
};

const detectionOptions = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],

  cookieMinutes: 10,
  cookieDomain: "",

  htmlTag: document.documentElement,
};

// noinspection JSIgnoredPromiseFromCall
i18next
  .use(LngDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: detectionOptions,
    supportedLngs: ["nl", "en-US", "en"],
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    ns: [
      "Admin",
      "Alacarte",
      "Authentication",
      "Common",
      "Communication",
      "ContentTypes",
      "Datacockpit",
      "Finances",
      "Forms",
      "MaterialIcons",
      "MyData",
      "Navigation",
      "Organization",
      "Profile",
      "UploadType",
      "User",
      "Variables",
      "Wlz",
    ],
    nsSeparator: ":",
    defaultNS: "Common",
  });

export const withI18nextProvider = (
  children: React.ReactElement | React.ReactElement[]
): React.ReactElement => (
  <Suspense fallback={<Loading />}>
    <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
  </Suspense>
);
