import { combineReducers } from "redux";

import { organizationPrivacyStatementUploadPoster } from "./api";
import {
  adminSettingsLogoCell,
  adminSettingsPrivacyStatementDeleteCell,
  adminSettingsPrivacyStatementUploadCell,
  adminSettingsUpdateCell,
} from "./cells";
import {
  AdminSettingsStoreModel,
  OrganizationPrivacyStatementUploadRequest,
} from "./models";

const adminSettingsReducer = combineReducers({
  logo: adminSettingsLogoCell.reducer,
  privacyStatementDelete: adminSettingsPrivacyStatementDeleteCell.reducer,
  privacyStatementUpload: adminSettingsPrivacyStatementUploadCell.reducer,
  update: adminSettingsUpdateCell.reducer,
});

const adminSettingsSagas = [
  ...adminSettingsLogoCell.sagas,
  ...adminSettingsPrivacyStatementDeleteCell.sagas,
  ...adminSettingsPrivacyStatementUploadCell.sagas,
  ...adminSettingsUpdateCell.sagas,
];

export type {
  AdminSettingsStoreModel,
  OrganizationPrivacyStatementUploadRequest,
};
export {
  adminSettingsLogoCell,
  adminSettingsPrivacyStatementDeleteCell,
  adminSettingsPrivacyStatementUploadCell,
  adminSettingsReducer,
  adminSettingsSagas,
  adminSettingsUpdateCell,
  organizationPrivacyStatementUploadPoster,
};
