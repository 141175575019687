import { AsyncResponse } from "async-lifecycle-saga";
import { Success } from "async-lifecycle-saga/dist/models";

import { asyncGet } from "../../../api";
import { MutationsOverviewDataRow } from "../../../datacockpit/mutations/models";

type MutationsOverviewDataRowJson = Omit<
  MutationsOverviewDataRow,
  "effectiveData"
> & {
  effectiveDate: string;
};

const toMutationsOverviewDataRow = (
  response: AsyncResponse<MutationsOverviewDataRowJson[]>
): AsyncResponse<MutationsOverviewDataRow[]> => {
  const body = (response as Success<MutationsOverviewDataRowJson[]>).body;
  return {
    ...response,
    body:
      body &&
      body.map((row) => ({
        ...row,
        effectiveDate: new Date(row.effectiveDate),
      })),
  };
};

export const datacockpitMutationsOverviewRequire = () =>
  asyncGet<MutationsOverviewDataRowJson[]>(
    "/api/datacockpit/mutations/overview"
  ).then(toMutationsOverviewDataRow);
