import { combineReducers } from "redux";

import {
  authenticationKnowledgeCell,
  authenticationLogOutCell,
  authenticationPossessionCell,
  authenticationRefreshCell,
  authenticationSsoCell,
} from "./cells";
import microsoft365Reducer from "./microsoft365/reducer";
import authenticationTwoFactorReducer from "./twoFactor/reducer";

export default combineReducers({
  knowledge: authenticationKnowledgeCell.reducer,
  possession: authenticationPossessionCell.reducer,
  refresh: authenticationRefreshCell.reducer,
  logout: authenticationLogOutCell.reducer,
  sso: authenticationSsoCell.reducer,
  twoFactor: authenticationTwoFactorReducer,
  microsoft365: microsoft365Reducer,
});
