import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    breakInside: "avoid",
    margin: `${theme.spacing()}px 0`,
    padding: theme.spacing() * 2,
  },
  h3: {},
  subtitle: {
    marginTop: "-0.3em",
    opacity: 0.75,
  },
  info: {
    padding: theme.spacing() * 2,
    textAlign: "right",
  },
  actions: {
    display: "inline",
  },
}));

export default useStyles;
