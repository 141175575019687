import "../index.css";

import iReact from "react";
import iReactDOM from "react-dom";
import { Provider } from "react-redux";

import iApp from "../App";
import iCreateReflexoStore from "../business/redux/store";
import * as iServiceWorker from "../serviceWorker";

export const React = iReact;
export const ReactDOM = iReactDOM;
export const App = iApp;
export const serviceWorker = iServiceWorker;
export const ReduxProvider = Provider;
export const createReflexoStore = iCreateReflexoStore;
