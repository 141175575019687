import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    borderStyle: "dashed",
    borderWidth: "1px",
    marginBottom: "1em",
    marginTop: "1em",
  },
  dropping: {
    backgroundColor: theme.palette.secondary.light,
    transition: "height 0.25s ease-in",
  },
  large: {
    height: "3em",
  },
  loading: {
    padding: "2em",
  },
  open: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 0.8,
  },
  small: {
    height: "2em",
  },
  smaller: {
    height: "1em",
  },
}));
