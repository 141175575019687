import { IconButton, Tooltip } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface RefreshTokenNotificationProps {
  token: string;
}

const RefreshTokenNotification = ({ token }: RefreshTokenNotificationProps) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(token);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "green",
        padding: "6px 16px",
        minWidth: "288px",
        alignItems: "center",
      }}
    >
      <span>Token generated</span>
      <Tooltip title="Copy to clipboard">
        <IconButton
          style={{ cursor: "pointer" }}
          onClick={handleCopyToClipboard}
        >
          <ContentCopyIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default RefreshTokenNotification;
