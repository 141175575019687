import { Role, SessionModel } from "../business/models";

const googleAuthenticatorKnowledgeTokenKey = "gaKnowledgeToken";
const jwtRefreshTokenKey = "jwtRefreshToken";
const jwtRefreshTokenSetKey = "jwtRefreshed";

export const getGAKToken = (): string | null => {
  return sessionStorage.getItem(googleAuthenticatorKnowledgeTokenKey);
};

export const setGAKToken = (gakToken: string): void => {
  sessionStorage.setItem(googleAuthenticatorKnowledgeTokenKey, gakToken);
};

export const getRefreshToken = (): string | null => {
  const expires = sessionStorage.getItem(jwtRefreshTokenSetKey);
  return expires && new Date(Number(expires)) > new Date()
    ? sessionStorage.getItem(jwtRefreshTokenKey)
    : null;
};

export const setRefreshToken = (
  refreshToken: string,
  expires: Date | string | number
): void => {
  sessionStorage.setItem(jwtRefreshTokenKey, refreshToken);
  sessionStorage.setItem(
    jwtRefreshTokenSetKey,
    new Date(expires).valueOf().toString()
  );
};

export const clearTokens = (): void => {
  sessionStorage.removeItem(googleAuthenticatorKnowledgeTokenKey);
  sessionStorage.removeItem(jwtRefreshTokenKey);
  sessionStorage.removeItem(jwtRefreshTokenSetKey);
};

export const isAdminRole = (role: Role): boolean =>
  role === "organizationAdmin";

export const hasSuperRole = (session: SessionModel): boolean =>
  session.user?.isPlatformAdmin ?? false;
