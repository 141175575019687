import { renderDate } from "../utils/DateUtils";
import { formatEuro } from "../utils/NumberUtils";
import styles from "./formatEmployeeData.module.css";
import { DataDefinitionModel, EmployeeDataModel } from "./models";

const formatData = (
  definition: DataDefinitionModel,
  employeeData: EmployeeDataModel,
  t: (text: string) => string,
  language: string
) => {
  switch (definition.dataType.name) {
    case "Boolean":
      return employeeData.boolean ? t("Common:Yes") : t("Common:No");
    case "Geslacht":
      return employeeData.boolean ? t("Common:Male") : t("Common:Female");
    case "Datum":
      return renderDate(employeeData.date, language);
    case "Aantal":
      return `${definition.unitBefore || ""} ${employeeData.numeric} ${
        definition.unitAfter
      }`.trim();
    case "Bedrag":
      return formatEuro(employeeData.numeric);
    case "Percentage":
      return `${100 * employeeData.numeric}%`;
    default:
      return employeeData.text || "";
  }
};

const formatEmployeeData = (
  definition: DataDefinitionModel,
  employeeData: EmployeeDataModel | undefined,
  t: (text: string) => string,
  language: string
) => {
  if (!employeeData) {
    return null;
  }

  const formatted = formatData(definition, employeeData, t, language);

  if (employeeData.isApproved && !employeeData.isEmpty) {
    return formatted;
  }

  return (
    <span className={styles.notApproved}>
      {employeeData.isEmpty ? t("Common:NoData") : formatted}
      <span className={styles.notApprovedMarker} />
    </span>
  );
};

export default formatEmployeeData;
