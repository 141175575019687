import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { useTranslationStrict } from "../../globalization/i18n";
import CardPage from "./CardPage";

export default React.memo<{}>((): JSX.Element => {
  const [t] = useTranslationStrict();
  return (
    <CardPage>
      <Typography variant="h6">{t("Common:Loading")}</Typography>
      <CircularProgress />
    </CardPage>
  );
});
