import { FormControl, FormLabel, Grid } from "@material-ui/core";
import { ChangeEvent } from "react";

import { WlzPerson } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";
import EuroInput from "../../../input/EuroInput";

interface CurrencyField {
  label: string;
  name: string;
}

interface Props {
  couple: boolean;
  fields: [CurrencyField, CurrencyField];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  index: number;
  subjects: WlzPerson[];
}

const WlzCurrencyPairInput = (props: Props) => {
  const { couple, fields, handleChange, index, subjects } = props;
  const [t] = useTranslationStrict();

  return couple ? (
    <>
      <Grid item>
        <FormControl>
          <FormLabel>{t(fields[0].label)}</FormLabel>
          <EuroInput
            value={subjects[index][fields[0].name as keyof WlzPerson] as number}
            name={`subjects[${index}].${fields[0].name}`}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <FormLabel>{t(fields[1].label)}</FormLabel>
          <EuroInput
            value={subjects[index][fields[1].name as keyof WlzPerson] as number}
            name={`subjects[${index}].${fields[1].name}`}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </>
  ) : (
    <Grid container item spacing={2}>
      <Grid item style={{ width: "50%" }}>
        <FormControl fullWidth>
          <FormLabel>{t(fields[0].label)}</FormLabel>
          <EuroInput
            value={subjects[index][fields[0].name as keyof WlzPerson] as number}
            name={`subjects[${index}].${fields[0].name}`}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid item style={{ width: "50%" }}>
        <FormControl fullWidth>
          <FormLabel>{t(fields[1].label)}</FormLabel>
          <EuroInput
            value={subjects[index][fields[1].name as keyof WlzPerson] as number}
            name={`subjects[${index}].${fields[1].name}`}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default WlzCurrencyPairInput;
