import React, { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

import LinkPreserveSession from "../components/ux/LinkPreserveSession";

const relativeUriRegex = new RegExp(
  "^(?!www\\.|(?:http|ftp)s?://|[A-Za-z]:\\|//).*"
);

const linkRenderer = (
  props: DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
) => {
  const { children, href, title } = props;

  const isRelative = relativeUriRegex.test(href || "");

  return isRelative ? (
    <LinkPreserveSession {...props} />
  ) : (
    <a href={href} title={title} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export { linkRenderer };
