import React, { useCallback } from "react";

import { MutationsOverview } from "../../datacockpit/mutations";
import { RefreshTimer } from "../../ux";
import useStyles from "../admin/AdminPage.styles";

const MutationsPage = () => {
  const classes = useStyles();

  const intervalActionDelay = useCallback(() => {}, []);

  return (
    <div className={classes.gridWideContent}>
      <RefreshTimer
        intervalAction={intervalActionDelay}
        intervalActionDelay={6000}
        intervalProgressDelay={100}
      />
      <MutationsOverview />
    </div>
  );
};

export default MutationsPage;
