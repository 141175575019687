import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { asyncIsValid } from "async-lifecycle-saga";
import { startOfDay } from "date-fns";
import { compact, flow, map } from "lodash/fp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VariableDataFilter } from "../../../business/personal/employeeData";
import { datacockpitVariableDataTableFetchCell } from "../../../business/redux/saga/datacockpit/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import {
  VariablesOverviewFilter,
  VariablesOverviewTable,
} from "../../datacockpit/table";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";

const initialValue: VariableDataFilter = {
  variableIds: [],
  referenceDate: startOfDay(new Date()),
};

/**
 * An overview of Employee Data Variables for Organization Administrators.
 * @constructor
 */
const VariablesOverview = () => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const [filter, setFilter] = useState(initialValue);
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

  const variables = useSelector((store: StoreModel) => {
    const dataDefinitions = store.datacockpit.dataDefinitions.value ?? [];
    return flow(
      map((vId) => dataDefinitions.find((dd) => dd.id === vId)),
      compact
    )(filter.variableIds);
  });
  const hasVariables = variables.length > 0;
  const table = useSelector(
    ({
      datacockpit: {
        variableData: { table },
      },
    }: StoreModel) => ({
      value: table.value ?? [],
      valid: asyncIsValid(table),
    })
  );

  const handleChange = (value: VariableDataFilter) => {
    setFilter(value);
    dispatch(
      value.variableIds.length > 0
        ? datacockpitVariableDataTableFetchCell.require(value, {
            onFail: showErrorMessageFromDetails(
              t("Datacockpit:Variabelen ophalen mislukt.")
            ),
          })
        : { type: datacockpitVariableDataTableFetchCell.events.clear }
    );
  };

  return (
    <section key="VariablesOverview">
      <Grid container direction="row" spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <VariablesOverviewFilter
                isExport={false}
                onChange={handleChange}
                value={filter}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">{t("Admin:Variabelen")}</Typography>
              <VariablesOverviewTable
                variables={variables}
                data={hasVariables ? table.value : []}
                hideRecords
                loading={hasVariables && !table.valid}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default VariablesOverview;
