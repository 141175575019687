import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { memo, useCallback, useEffect, useState } from "react";

import { formatEuro, parseInputNumber } from "../../utils/NumberUtils";

interface Props {
  name?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const useStyles = makeStyles({
  right: {
    fontWeight: "inherit",
    "& input": { textAlign: "right" },
  },
});

const euroWith2Decimals = (value: number): number =>
  Math.max(0, Math.round(100 * value) / 100);

const EuroInput = memo(({ onChange, value, ...props }: Props) => {
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e): void => {
      const valueAsNumber = euroWith2Decimals(parseInputNumber(e.target.value));
      onChange({
        ...e,
        target: {
          ...e.target,
          name: e.target.name,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: valueAsNumber as any as string,
          valueAsNumber,
        },
      });
    },
    [onChange]
  );
  const { right } = useStyles();
  const [localValue, updateLocal] = useState("");
  useEffect((): void => updateLocal(formatEuro(value)), [value]);
  const handleChange = useCallback(
    (e): void => updateLocal(e.target.value),
    []
  );
  return (
    <TextField
      classes={{ root: right }}
      value={localValue}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

export default EuroInput;
