import { Pagination as MuiPagination } from "@material-ui/lab";
import React, { useMemo } from "react";

interface Props {
  itemsCount: number;
  itemsPerPage: number;
  onPageSelected(page: number): void;
  selectedPage: number;
}

const Pagination = (props: Props) => {
  const { itemsCount, itemsPerPage, onPageSelected, selectedPage } = props;
  const pageCount = useMemo(
    () => Math.ceil(itemsCount / itemsPerPage) || 1,
    [itemsCount, itemsPerPage]
  );
  if (selectedPage < 0 || selectedPage > pageCount) {
    throw new Error("Invalid page");
  }

  const handleChange = (_: React.ChangeEvent<unknown>, page: number) => {
    onPageSelected(page);
  };

  return (
    <MuiPagination
      count={pageCount}
      onChange={handleChange}
      showFirstButton
      showLastButton
    />
  );
};

export default Pagination;
