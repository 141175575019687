import { AsyncSingleValue, createSingle } from "async-lifecycle-saga";
import { combineReducers } from "redux";

import { asyncGet } from "../../../api";
import { EmployerModel } from "../../../models";
import { StoreModel } from "../models";

export interface EmployerStoreModel {
  get: AsyncSingleValue<EmployerModel>;
}

export const employerGet = () => asyncGet("/api/employer");

export const employerGetCell = createSingle<EmployerModel, StoreModel>({
  path: ["employer", "get"],
  require: {
    api: employerGet,
  },
});

export const employerReducer = combineReducers({
  get: employerGetCell.reducer,
});
