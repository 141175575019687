import React, { Dispatch, SetStateAction } from "react";

export const identity = <T>(x: T): T => x;

export type ValueConverter<R, S> = (rawValue: R) => S;

type ExtendedChangeEventHandler<T> = (
  event: React.ChangeEvent<HTMLInputElement>,
  newValue?: T
) => void;

type ExtendedPartialChangeEventHandler<T, U extends PropType<T, keyof T>> = (
  event: React.ChangeEvent<HTMLInputElement>,
  newValue?: U
) => void;

export const setStateValue =
  <T extends unknown>(
    stateAction: Dispatch<SetStateAction<T>>,
    converter?: ValueConverter<string, T>
  ): ExtendedChangeEventHandler<T> =>
  (event: React.ChangeEvent<HTMLInputElement>, newValue?: T): void => {
    if (newValue !== undefined) {
      stateAction(newValue);
      return;
    }
    if (converter) {
      const convertedValue = converter(event.currentTarget.value);
      stateAction(convertedValue);
    } else {
      stateAction(event.currentTarget.value as T);
    }
  };

type PropType<TObject, TProperty extends keyof TObject> = TObject[TProperty];

export const setPartialStateValue =
  <T extends object, U extends PropType<T, keyof T>>(
    stateAction: Dispatch<SetStateAction<T>>,
    state: T,
    propertyName: keyof T,
    converter?: ValueConverter<string, U>
  ): ExtendedPartialChangeEventHandler<T, U> =>
  (event: React.ChangeEvent<HTMLInputElement>, newValue?: U): void => {
    if (newValue !== undefined) {
      stateAction({ ...state, [propertyName]: newValue });
      return;
    }
    if (converter) {
      const convertedValue = converter(event.currentTarget.value);
      stateAction({ ...state, [propertyName]: convertedValue });
    } else {
      stateAction({
        ...state,
        [propertyName]: event.currentTarget.value as unknown as U,
      });
    }
  };
