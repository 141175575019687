import { AsyncResponse } from "async-lifecycle-saga";
import { Details, Success } from "async-lifecycle-saga/dist/models";

import { minDate } from "../../../../utils/DateUtils";
import { EmployeeDataModel } from "../../../models";

export type EmployeeDataModelJson = Omit<EmployeeDataModel, "date"> & {
  date: string;
};

export const toPersonalEmployeeDataModel = (
  response: AsyncResponse<EmployeeDataModelJson[]>
): AsyncResponse<EmployeeDataModel[]> =>
  response.status === 200
    ? {
        ...response,
        body: (response as Success<EmployeeDataModelJson[]>).body.map(
          ({ date, ...rest }): EmployeeDataModel => ({
            ...rest,
            date: date.startsWith("0") ? minDate : new Date(date),
          })
        ),
      }
    : (response as Details);
