import { useSelector } from "react-redux";

import { StoreModel } from "../models";

export const useEmployeeData = () => {
  const { edit, filter, merged } = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData
  );

  return (
    edit ||
    merged
      .filter((ed) => ed.dataDefinition?.group === filter.group)
      .sort((ed1, ed2) =>
        ed1.dataDefinition!.code.localeCompare(ed2.dataDefinition!.code)
      ) ||
    []
  );
};
