import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { ChoicePeriodMode } from "../../business/models";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../globalization/i18n";

const getStartString = (
  t: TFunctionStrict,
  mode: ChoicePeriodMode,
  startMonth?: number
) => {
  switch (mode) {
    case "salaryPeriod":
      return startMonth
        ? `${t("Alacarte:from")} ${t("Alacarte:SalaryPeriod", {
            salaryPeriod: -startMonth,
          })}`
        : t("Alacarte:directly");
    case "monthly":
    default:
      return startMonth
        ? `${t("Alacarte:from")} ${t(`Alacarte:Month${startMonth}`)}`
        : t("Alacarte:directly");
  }
};

const getEndString = (
  t: TFunctionStrict,
  mode: ChoicePeriodMode,
  endMonth?: number
) => {
  switch (mode) {
    case "salaryPeriod":
      return endMonth
        ? t("Alacarte:SalaryPeriod", {
            salaryPeriod: -endMonth,
          })
        : t("Alacarte:yearEnd");
    case "monthly":
    default:
      return endMonth ? t(`Alacarte:Month${endMonth}`) : t("Alacarte:yearEnd");
  }
};

interface ChoiceRepetitionViewProps {
  startMonth?: number;
  endMonth?: number;
  periodMode: ChoicePeriodMode;
  viewMode: "Compact" | "Grid";
}

const ChoiceRepetitionView = (props: ChoiceRepetitionViewProps) => {
  const { startMonth, endMonth, periodMode, viewMode } = props;
  const [t] = useTranslationStrict();

  const startString = getStartString(t, periodMode, startMonth);
  const endString = getEndString(t, periodMode, endMonth);

  return viewMode === "Compact" ? (
    <Typography>
      {t("Alacarte:Period")}: {startString} {t("Alacarte:until")} {endString}
    </Typography>
  ) : (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle2">{t("Alacarte:Period")}</Typography>
      </Grid>
      <Grid item>
        <Typography>
          {startString} {t("Alacarte:until")} {endString}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChoiceRepetitionView;
