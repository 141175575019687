import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/styles";

const useMyDataPageStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default useMyDataPageStyles;
