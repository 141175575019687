import { IconButton, Tooltip } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";

import { ContentNodeModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  contentNode: ContentNodeModel;
  disabled?: boolean;
  onEdit(contentNode: ContentNodeModel): void;
}

const ContentNodeEditButton = (props: Props) => {
  const { contentNode, disabled, onEdit } = props;
  const [t] = useTranslationStrict();

  const handleClick = () => {
    onEdit({ ...contentNode });
  };

  return (
    <Tooltip title={t("Common:Edit")}>
      <IconButton disabled={disabled} onClick={handleClick} size="small">
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ContentNodeEditButton;
