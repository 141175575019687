import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSingle } from "async-lifecycle-hooks";
import { Trans } from "react-i18next";

import { adminContractsExportGetAllCell } from "../../../business/redux/saga/admin/contracts/cells";
import { Loading } from "../../ux";
import ContractsExportTableRow from "./ContractsExportTableRow";

const ContractsExportTable = () => {
  const {
    status: { loading },
    value,
  } = useSingle(adminContractsExportGetAllCell);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans ns="Admin" i18nKey="UploadName">
                Bestandsnaam
              </Trans>
            </TableCell>
            <TableCell>
              <Trans ns="Admin" i18nKey="UploadDate">
                Datum
              </Trans>
            </TableCell>
            <TableCell>
              <Trans ns="Admin" i18nKey="UploadActions">
                Acties
              </Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow key="loading">
              <TableCell key="loading" colSpan={3}>
                <Loading />
              </TableCell>
            </TableRow>
          )}
          {!loading && value?.length === 0 && (
            <TableRow key="empty">
              <TableCell key="empty" colSpan={3}>
                <Trans ns="Admin" i18nKey="ExportsNone">
                  Geen exports
                </Trans>
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            value?.map((item) => <ContractsExportTableRow item={item} />)}
        </TableBody>
      </Table>
    </>
  );
};

export default ContractsExportTable;
