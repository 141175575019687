import { Card, CardActions, CardContent, Tab, Tabs } from "@material-ui/core";
import { Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, memo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ContentNodeModel,
  DocumentContentNodeModel,
  LinkContentNodeModel,
} from "../../../business/models";
import { useNavigation } from "../../../business/redux/saga/admin/hooks";
import { adminNavigationContentDocumentUploadCell } from "../../../business/redux/saga/admin/navigation/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";
import { ContentNodeDocumentEditor, ContentNodeLinkEditor } from "./";

interface Props {
  parentId: number;
  contentNode?: ContentNodeModel;
  onCancel(): void;
  onSave(): void;
}

const ContentNodeEditor = (props: Props) => {
  const { parentId, contentNode, onCancel, onSave } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const { getNavigation, addContentNode, updateContentNode } = useNavigation();

  const [tab, setTab] = useState<number>(0);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
  };

  const handleSaveLink = (
    link: LinkContentNodeModel,
    onSuccess: () => void
  ) => {
    if (contentNode) {
      updateContentNode(
        { ...link, id: contentNode.id, position: contentNode.position },
        showErrorMessageFromDetails(t("Admin:Link opslaan mislukt.")),
        () => {
          onSuccess();
          onSave();
        }
      );
    } else {
      addContentNode(
        parentId,
        link,
        showErrorMessageFromDetails(t("Admin:ContentNodeAddError")),
        (result: Success<ContentNodeModel>) => {
          enqueueSnackbar(
            `${t("Admin:ContentNodeAddSuccess")} (${result.body.title})`,
            {
              variant: "success",
            }
          );
          getNavigation("appbar");
          getNavigation("aside");
          onSuccess();
          onSave();
        }
      );
    }
  };

  const acceptedFiles: string[] = [
    "application/json", // Json
    "application/msword", // Word
    "application/pdf", // PDF
    "application/vnd.ms-excel", // Excel
    "application/vnd.ms-powerpoint", // PowerPoint
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint (OpenXML)
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (OpenXML)
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (OpenXML)
    "application/xml", // XML
    "image/*",
    "text/*",
    "video/*",
    "",
  ];

  const handleSaveDocument = (
    document: DocumentContentNodeModel,
    file: File,
    onSuccess: () => void
  ) => {
    dispatch(
      adminNavigationContentDocumentUploadCell.require(
        {
          file,
          contentNodeDocument: document,
          parentId,
        },
        {
          onFail: showErrorMessageFromDetails(t("Admin:ContentNodeAddError")),
          onSuccess: (success) => {
            enqueueSnackbar(
              `${t("Admin:ContentNodeAddSuccess")} (${success.body.title})`,
              {
                variant: "success",
              }
            );
            getNavigation("appbar");
            getNavigation("aside");
            onSuccess();
            onSave();
          },
        }
      )
    );
  };

  const isDocument = Boolean(
    (contentNode as DocumentContentNodeModel)?.contentType
  );
  const isLink = Boolean((contentNode as LinkContentNodeModel)?.link);

  return (
    <Card>
      <CardActions>
        <Tabs value={tab} onChange={handleTabChange} color="primary">
          {(!contentNode || (contentNode && isDocument)) && (
            <Tab
              label={
                contentNode
                  ? t("Admin:Document bewerken")
                  : t("Admin:ContentNodeAddDocumentHeader")
              }
            />
          )}
          {(!contentNode || (contentNode && isLink)) && (
            <Tab
              label={
                contentNode
                  ? t("Admin:Link bewerken")
                  : t("Admin:ContentNodeAddLinkHeader")
              }
            />
          )}
        </Tabs>
      </CardActions>
      <CardContent>
        {((!contentNode && tab === 0) || isDocument) && (
          <ContentNodeDocumentEditor
            acceptedFiles={acceptedFiles}
            contentNode={contentNode as DocumentContentNodeModel}
            onCancel={onCancel}
            onSave={handleSaveDocument}
          />
        )}
        {((!contentNode && tab === 1) || isLink) && (
          <ContentNodeLinkEditor
            contentNode={contentNode as LinkContentNodeModel}
            onCancel={onCancel}
            onSave={handleSaveLink}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ContentNodeEditor);
