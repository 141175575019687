import { Typography } from "@material-ui/core";

import { useTranslationStrict } from "../../../globalization/i18n";

const Microsoft365NotConfigured = () => {
  const [t] = useTranslationStrict();

  return (
    <Typography variant="body1">
      {t("Authentication:Microsoft365NotConfiguredBody1")}
    </Typography>
  );
};

export default Microsoft365NotConfigured;
