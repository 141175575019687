import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { tabletScreen } from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> =>
    tabletScreen({
      root: {
        marginRight: theme.spacing(4),
      },
    })
);

export default useStyles;
