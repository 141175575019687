import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSingle } from "async-lifecycle-hooks";
import React from "react";

import { UserModel } from "../../../business/models";
import { adminEmployeeTypesCell } from "../../../business/redux/saga/admin/employee/cells";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  onEdit(userEdited: UserModel): void;
  userEdited: UserModel;
}

const EmployeeTypeInput = (props: Props) => {
  const { onEdit, userEdited } = props;
  const [t] = useTranslationStrict();
  const knownEmployeeTypes = useSingle(adminEmployeeTypesCell);

  return (
    <Autocomplete
      disableClearable
      freeSolo
      fullWidth
      options={knownEmployeeTypes.value || []}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={t("Admin:EmployeeType")}
          margin="normal"
          variant="outlined"
        />
      )}
      onChange={(event, newValue) => {
        onEdit({
          ...userEdited,
          employeeType: newValue ?? "",
        });
      }}
      onInputChange={(event, newValue) => {
        onEdit({
          ...userEdited,
          employeeType: newValue ?? "",
        });
      }}
      style={{ width: 200 }}
      value={userEdited.employeeType}
    />
  );
};

export default EmployeeTypeInput;
