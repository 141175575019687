import { createSingle } from "async-lifecycle-saga";

import { StoreModel } from "../../models";
import { avatarDownloadGetter, avatarUploadPoster } from "./api";

const avatarSelector = ({
  profile: {
    avatar: { value },
  },
}: StoreModel) => ({ status: { loading: false }, value });

export const avatarCell = createSingle({
  path: ["PROFILE", "AVATAR"],
  require: { api: avatarDownloadGetter },
  select: avatarSelector,
  update: { api: avatarUploadPoster },
});
