import { Tooltip } from "@material-ui/core";
import {
  AssignmentInd as AssignmentIndIcon,
  DataSaverOn as DataSaverOnIcon,
  Email as EmailIcon,
  NewReleases as NewReleasesIcon,
} from "@mui/icons-material";
import React, { CSSProperties, ReactElement, memo, useMemo } from "react";

import { UserStatus } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  status: UserStatus;
}

const UsersEditorStatus = ({ status }: Props) => {
  const [t] = useTranslationStrict();

  const style = useMemo((): CSSProperties => {
    switch (status) {
      case UserStatus.new:
        return { color: "gray" };
      case UserStatus.invited:
        return { color: "orange" };
      case UserStatus.active:
        return { color: "green" };
      case UserStatus.activeExternal:
        return { color: "blue" };
      default:
        return { color: "red" };
    }
  }, [status]);

  const icon = useMemo((): ReactElement => {
    switch (status) {
      case UserStatus.new:
        return <NewReleasesIcon style={style} />;
      case UserStatus.invited:
        return <EmailIcon style={style} />;
      case UserStatus.active:
        return <AssignmentIndIcon style={style} />;
      case UserStatus.activeExternal:
        return <DataSaverOnIcon style={style} />;
      default:
        return <></>;
    }
  }, [status, style]);

  return <Tooltip title={t(`Admin:UserStatus_${status}`)}>{icon}</Tooltip>;
};

export default memo(UsersEditorStatus);
