import { Divider, FormLabel, Switch } from "@material-ui/core";
import React from "react";

import { ChoiceSettingsModel } from "../../business/redux/saga/admin/choices/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { lastOfCurrentMonth } from "../../utils/DateUtils";
import DateInput from "../input/DateInput";

interface Props {
  choiceSettings: ChoiceSettingsModel;
  onChange(checked: boolean, openTo: Date): void;
}

const ChoiceSettings = ({ choiceSettings, onChange }: Props): JSX.Element => {
  const [t] = useTranslationStrict();
  const open = Boolean(choiceSettings.openFrom);
  const endDate = choiceSettings.openTo ?? lastOfCurrentMonth();
  return (
    <>
      <Switch
        checked={open}
        onChange={(_, checked): void => onChange(checked, endDate)}
      />
      <FormLabel>{t("Admin:EnableChoices")}</FormLabel>
      <Divider style={{ marginBottom: 16 }} />
      <DateInput
        label={t("Admin:ChoicesOpenTo")}
        helperText={t("Admin:ChoicesOpenToHelper")}
        onChange={(date): void =>
          onChange(
            open,
            date ? new Date(date as string | number | Date) : endDate
          )
        }
        value={endDate}
        minDate={new Date()}
        disabled={!open}
        clearable={false}
      />
    </>
  );
};

export default ChoiceSettings;
