import { combineReducers } from "redux";

import {
  daywizeTokenCell,
  userGenerateTokenCell,
  userPasswordResetCell,
  userPasswordResetRequestCell,
  userPasswordResetVerifyCell,
} from "./cells";

export default combineReducers({
  daywizeToken: daywizeTokenCell.reducer,
  generateToken: userGenerateTokenCell.reducer,
  password: combineReducers({
    reset: userPasswordResetCell.reducer,
    resetRequest: userPasswordResetRequestCell.reducer,
    resetVerify: userPasswordResetVerifyCell.reducer,
  }),
});
