import {
  Button,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {
  Add as AddIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { Formik } from "formik";
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import {
  ContentNodeModel,
  NavigationNodeFormValues,
  NavigationNodeModel,
} from "../../business/models";
import { adminEmployersListSpecialCell } from "../../business/redux/saga/admin/employers/cells";
import { StoreModel } from "../../business/redux/saga/models";
import {
  personalAddPboCell,
  personalDataCell,
  personalDefinitionsCell,
} from "../../business/redux/saga/personal/cells";
import { PersonalStoreModel } from "../../business/redux/saga/personal/models";
import { useTranslationStrict } from "../../globalization/i18n";
import {
  internalImageReferenceRegex,
  populateImages,
  resolveImgLink,
} from "../../utils/ImageUtils";
import { ContentNodeEditor } from "../admin/contentNodes";
import { ContentPageContents } from "../admin/contentPages";
import ContentPageCreator from "../admin/contentPages/ContentPageCreator";
import ContentPageEditor from "../admin/contentPages/ContentPageEditor";
import ContentPageItemsList from "../admin/contentPages/ContentPageItemsList";
import NavigationNodeDeleteConfirmDialog from "../admin/NavigationNodeDeleteConfirmDialog";
import DownloadsList from "../personal/DownloadsList";
import Loading from "../ux/Loading";
import useStyles from "./ContentPage.styles";

interface ContentPageProps {
  enableEdit: boolean;
  node: NavigationNodeModel;
  pao: boolean;
}

const ContentPage = (props: ContentPageProps) => {
  const { enableEdit, pao, node } = props;
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminEmployersListSpecialCell.require());
  }, [dispatch]);

  /* useState */
  const [isContentNodeAdding, setIsContentNodeAdding] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [pboBusy, setPboBusy] = useState(false);

  /* Delete navigation node */
  const [deleteNavigationNodeConfirmId, setDeleteNavigationNodeConfirmId] =
    useState(0);
  const deleteNavigationNodeConfirmOpen = Boolean(
    deleteNavigationNodeConfirmId
  );
  const handleDeleteNavigationNodeClick =
    (id: number): (() => void) =>
    (): void => {
      setDeleteNavigationNodeConfirmId(id);
    };
  const handleDeleteNavigationNodeClose = () => {
    setDeleteNavigationNodeConfirmId(0);
  };

  /* Edit content node */
  const [contentNodeEdit, setContentNodeEdit] = useState<
    ContentNodeModel | undefined
  >(undefined);
  const handleContentNodeEdit = (contentNodeToEdit: ContentNodeModel) => {
    setContentNodeEdit(contentNodeToEdit);
  };
  const handleContentNodeClose = () => {
    setIsContentNodeAdding(false);
    setContentNodeEdit(undefined);
  };

  // Reset state
  useEffect(() => {
    setDeleteNavigationNodeConfirmId(0);
    setIsContentNodeAdding(false);
    setContentNodeEdit(undefined);
    setIsEditing(false);
    setPboBusy(false);
  }, [node.id]);

  const {
    definitions: { value: definitions },
    data: { value: data },
  } = useSelector((store: StoreModel): PersonalStoreModel => store.personal);

  const needsDefinitions = !definitions;
  useEffect(() => {
    if (needsDefinitions) dispatch(personalDefinitionsCell.require());
  }, [needsDefinitions, dispatch]);

  const needsData = !data;
  useEffect(() => {
    if (needsData) dispatch(personalDataCell.require());
  }, [needsData, dispatch]);

  // Find images
  const loadImageLinks = useCallback(async () => {
    let allIds = [...node.text.matchAll(internalImageReferenceRegex)].map(
      (match) => Number(match[2])
    );
    const pairs = await Promise.all(
      [...new Set(allIds)].map(resolveImgLink(dispatch))
    );
    return pairs.reduce<{
      [id_1: number]: string;
    }>((links, [id_2, link]) => ({ ...links, [id_2]: link }), {});
  }, [dispatch, node.text]);

  const handlePreview = useCallback(
    async (markdown: string): Promise<ReactNode> => {
      const imageLinks = await loadImageLinks();
      const source = populateImages(markdown, imageLinks);
      return Promise.resolve(
        <ReactMarkdown className={classes.markdown}>{source}</ReactMarkdown>
      );
    },
    [classes.markdown, loadImageLinks]
  );

  // Track text cursor
  const formRef = useRef<Formik<NavigationNodeFormValues>>(null);
  const textCurrent = useRef<string>(null) as MutableRefObject<string>;
  const textCursorPosition = useRef<number>(null) as MutableRefObject<number>;

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, []);
  const handleChange = useCallback(() => {
    setIsEditing(false);
  }, []);
  const handlePbo = useCallback(() => {
    setPboBusy(true);
    dispatch(
      personalAddPboCell.require(node.id!, {
        onFail: () => setPboBusy(false),
        onSuccess: () => setPboBusy(false),
      })
    );
  }, [dispatch, node.id]);

  return (
    <div className={classes.gridWideContent}>
      <Card style={{ padding: 24 }}>
        {((enableEdit && !isEditing) || pao) && (
          <CardActions>
            {enableEdit && !isEditing && (
              <>
                <Tooltip title={t("Common:Edit")}>
                  <IconButton onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {pao && (
              <Button
                disabled={pboBusy}
                variant="contained"
                color="primary"
                onClick={handlePbo}
              >
                {t("Common:AddPbo")}
              </Button>
            )}
          </CardActions>
        )}
        <CardContent>
          <div style={{ padding: 16 }}>
            {(!enableEdit || !isEditing) && <ContentPageContents node={node} />}
            {enableEdit && isEditing && (
              <ContentPageEditor
                formRef={formRef}
                node={node}
                onCancel={handleCancel}
                onChange={handleChange}
                onPreview={handlePreview}
                textCurrent={textCurrent}
                textCursorPosition={textCursorPosition}
              />
            )}
          </div>
          {pboBusy ? (
            <Loading />
          ) : (
            <DownloadsList uploadTypeId={node.uploadTypeId} />
          )}
          <ContentPageItemsList
            disabled={Boolean(isContentNodeAdding || contentNodeEdit)}
            enableEdit={enableEdit}
            isEditingPage={isEditing}
            node={node}
            onDeleteNavigationNode={(id) => {
              setDeleteNavigationNodeConfirmId(id);
            }}
            onEditContentNode={handleContentNodeEdit}
            formRef={formRef}
            textCurrent={textCurrent}
            textCursorPosition={textCursorPosition}
          />
          {node.level < 2 && enableEdit && (
            <ContentPageCreator
              parentId={node.id || -999}
              parentRoute={node.route}
            />
          )}
          {enableEdit && (isContentNodeAdding || contentNodeEdit) && (
            <ContentNodeEditor
              contentNode={contentNodeEdit}
              parentId={node.id || -999}
              onCancel={handleContentNodeClose}
              onSave={handleContentNodeClose}
            />
          )}
        </CardContent>
        {enableEdit && (
          <CardActions>
            <Grid container direction="row">
              <Grid item xs={2}>
                {!isContentNodeAdding && (
                  <Tooltip title={t("Admin:ContentNodeAddTooltip")}>
                    <IconButton
                      onClick={(): void => {
                        setIsContentNodeAdding(true);
                        setContentNodeEdit(undefined);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={2}>
                <Tooltip title={t("Common:Delete")}>
                  <IconButton
                    style={{ float: "right" }}
                    onClick={handleDeleteNavigationNodeClick(node.id || -999)}
                    classes={{ root: classes.danger }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </CardActions>
        )}
      </Card>
      {deleteNavigationNodeConfirmId > 0 && enableEdit && (
        <NavigationNodeDeleteConfirmDialog
          nodeId={deleteNavigationNodeConfirmId}
          open={deleteNavigationNodeConfirmOpen}
          onClose={handleDeleteNavigationNodeClose}
        />
      )}
    </div>
  );
};

export default ContentPage;
