import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Paper,
  Typography,
} from "@material-ui/core";
import { Details } from "async-lifecycle-saga/dist/models";
import { DropzoneArea } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { StoreModel } from "../../business/redux/saga/models";
import { useProfile } from "../../business/redux/saga/profile/hooks";
import { useTranslationStrict } from "../../globalization/i18n";
import Loading from "../ux/Loading";
import useStyles from "./AvatarUploader.styles";

const AvatarUploader = () => {
  const { root } = useStyles();
  const { avatarUpload } = useProfile();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();

  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const acceptedFiles = useMemo((): string[] => ["image/*"], []);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);

  const { avatarLoading, avatarValue } = useSelector(
    ({
      profile: {
        avatar: {
          status: { loading: avatarLoading },
          value: avatarValue,
        },
      },
    }: StoreModel) => ({ avatarLoading, avatarValue })
  );

  const avatarUploadFinished = useMemo(
    () => !avatarLoading && avatarValue?.status === "success" && hasUploaded,
    [avatarLoading, avatarValue, hasUploaded]
  );

  const handleChange = useCallback((files: File[]) => {
    setCurrentFiles(files);
  }, []);
  const handleUpdateClick = useCallback(() => {
    if (currentFiles.length === 0) {
      return;
    }

    avatarUpload(
      currentFiles[0],
      (problemDetails: Details) => {
        enqueueSnackbar(
          `${t("Profile:AvatarUploadFailed")}${
            problemDetails.detail ? " (" + problemDetails.detail + ")" : ""
          }`,
          {
            variant: "error",
          }
        );
      },
      () => {
        setHasUploaded(true);
        enqueueSnackbar(t("Profile:AvatarUploadSuccess"), {
          variant: "success",
        });
      }
    );
  }, [avatarUpload, currentFiles, enqueueSnackbar, t]);

  return (
    <Card classes={{ root }}>
      <CardHeader title={t("Profile:Avatar")} />
      <CardContent>
        {avatarLoading && <Loading />}
        {!avatarUploadFinished && (
          <FormControl>
            <DropzoneArea
              acceptedFiles={acceptedFiles}
              dropzoneText={t("Profile:AvatarUploaderDropZoneText")}
              filesLimit={1}
              maxFileSize={33554432}
              onChange={handleChange}
            />
            <FormHelperText>{t("Profile:AvatarUploadHeader")}</FormHelperText>
          </FormControl>
        )}
        {avatarUploadFinished && (
          <Paper elevation={2} classes={{ root }}>
            <Typography classes={{ root }} component="p" variant="subtitle2">
              {t("Profile:AvatarUploadThankYou")}
            </Typography>
          </Paper>
        )}
      </CardContent>
      <CardActions>
        {!avatarUploadFinished && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateClick}
          >
            {t("Common:Update")}
          </Button>
        )}
        {avatarUploadFinished && (
          <Button
            variant="contained"
            color="secondary"
            onClick={(): void => {
              setHasUploaded(false);
            }}
          >
            {t("Profile:AvatarUploadAgain")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default memo(AvatarUploader);
