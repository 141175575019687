import { combineReducers } from "redux";

import {
  adminChoiceBenefitsCell,
  adminChoiceDefinitionsListCell,
  adminChoiceEmployeeExportMutationsCell,
  adminChoiceEmployeeListCell,
  adminChoiceEmployeeResetCell,
  adminChoicePeriodsCell,
  adminChoiceSettingsEntityCell,
  adminInfoExportCell,
} from "./choices/cells";
import {
  adminContractsExportCreateCell,
  adminContractsExportDeleteCell,
  adminContractsExportDownloadCell,
  adminContractsExportGetAllCell,
} from "./contracts/cells";
import { adminEmployeeTypesCell } from "./employee/cells";
import {
  adminEmployersListCell,
  adminEmployersListSpecialCell,
} from "./employers/cells";
import { enienlCalculateCell, enienlRegisterCell } from "./enienl/cells";
import adminNavigationReducer from "./navigation/reducer";
import {
  adminPeekDownloadRemoveCell,
  adminPeekDownloadsCell,
} from "./peek/cells";
import { adminSettingsReducer } from "./settings";
import {
  adminUploadAddCell,
  adminUploadCheckCell,
  adminUploadDeleteCell,
  adminUploadListCell,
  adminUploadProcessCell,
  adminUploadTypesCell,
} from "./upload/cells";
import {
  adminUsersAddCell,
  adminUsersDeleteCell,
  adminUsersInviteAllNewCell,
  adminUsersInviteCell,
  adminUsersListCell,
  adminUsersListIncludeArchivedCell,
  adminUsersUpdateCell,
} from "./users/cells";

export default combineReducers({
  choice: combineReducers({
    benefits: adminChoiceBenefitsCell.reducer,
    definitions: combineReducers({
      list: adminChoiceDefinitionsListCell.reducer,
    }),
    employee: combineReducers({
      export: adminChoiceEmployeeExportMutationsCell.reducer,
      list: adminChoiceEmployeeListCell.reducer,
      reset: adminChoiceEmployeeResetCell.reducer,
    }),
    periods: adminChoicePeriodsCell.reducer,
    settings: adminChoiceSettingsEntityCell.reducer,
  }),
  contracts: combineReducers({
    create: adminContractsExportCreateCell.reducer,
    delete: adminContractsExportDeleteCell.reducer,
    download: adminContractsExportDownloadCell.reducer,
    getAll: adminContractsExportGetAllCell.reducer,
  }),
  employee: combineReducers({
    types: adminEmployeeTypesCell.reducer,
  }),
  employers: combineReducers({
    list: adminEmployersListCell.reducer,
    listSpecial: adminEmployersListSpecialCell.reducer,
  }),
  enienl: combineReducers({
    calculate: enienlCalculateCell.reducer,
    register: enienlRegisterCell.reducer,
  }),
  info: combineReducers({
    export: adminInfoExportCell.reducer,
  }),
  navigation: adminNavigationReducer,
  peek: combineReducers({
    downloads: adminPeekDownloadsCell.reducer,
    download: combineReducers({
      remove: adminPeekDownloadRemoveCell.reducer,
    }),
  }),
  settings: adminSettingsReducer,
  upload: combineReducers({
    add: adminUploadAddCell.reducer,
    check: adminUploadCheckCell.reducer,
    delete: adminUploadDeleteCell.reducer,
    list: adminUploadListCell.reducer,
    process: adminUploadProcessCell.reducer,
    types: adminUploadTypesCell.reducer,
  }),
  users: combineReducers({
    add: adminUsersAddCell.reducer,
    delete: adminUsersDeleteCell.reducer,
    invite: adminUsersInviteCell.reducer,
    inviteAllNew: adminUsersInviteAllNewCell.reducer,
    list: adminUsersListCell.reducer,
    listIncludeArchived: adminUsersListIncludeArchivedCell.reducer,
    update: adminUsersUpdateCell.reducer,
  }),
});
