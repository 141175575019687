import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { WithSnackbarProps, withSnackbar } from "notistack";
import React, { ChangeEvent } from "react";

import { ChoiceInterval } from "../../business/models";
import {
  ChoiceDefinitionModel,
  DataChoiceDefinition,
} from "../../business/redux/saga/admin/choices/models";
import { useTranslationStrict } from "../../globalization/i18n";
import useStyles from "../pages/alacarte/ALaCarteAdminPage.styles";

interface ViewProps {
  dirty: boolean;
  onSelectIntervalChange(
    e: ChangeEvent<{ name?: string; value: unknown }>
  ): void;
  onShiftDown(): void;
  onShiftUp(): void;
  onUploadChange(_: ChangeEvent, checked: boolean): void;
  onValueChange(e: ChangeEvent): void;
  resetChoice(): void;
  removeChoice(message: string): void;
  saveChoice(message: string): void;
  shiftDownEnabled: boolean;
  shiftUpEnabled: boolean;
  updatedChoice: DataChoiceDefinition;
  userInputAllowed: boolean;
}

const ChoiceEditView = ({
  dirty,
  onSelectIntervalChange: onSelectChange,
  onShiftDown,
  onShiftUp,
  onUploadChange,
  onValueChange,
  removeChoice,
  resetChoice,
  saveChoice,
  shiftDownEnabled,
  shiftUpEnabled,
  updatedChoice,
}: ViewProps) => {
  const [t] = useTranslationStrict();
  const classes = useStyles();
  return (
    <Card className={classes.definitionCard}>
      <CardHeader
        action={
          <>
            {shiftUpEnabled && (
              <IconButton onClick={onShiftUp}>
                <ArrowUpwardIcon />
              </IconButton>
            )}
            {shiftDownEnabled && (
              <IconButton onClick={onShiftDown}>
                <ArrowDownwardIcon />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={2}
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              classes={{ root: classes.definitionCardEarningCodeLabel }}
            >
              {t("Alacarte:Earning code")}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              classes={{ root: classes.definitionCardEarningCodeTextField }}
              fullWidth
              value={updatedChoice.earningCode}
              title={updatedChoice.earningCode}
              margin="dense"
              name="earningCode"
              onChange={onValueChange}
            />
          </Grid>
        </Grid>
        <TextField
          className={classes.definitionCardCodeLabel}
          label={t("Alacarte:Code")}
          disabled
          fullWidth
          value={updatedChoice.dataDefinition.code}
          title={updatedChoice.dataDefinition.code}
          margin="dense"
          name="code"
        />
        <TextField
          label={t("Alacarte:Label")}
          fullWidth
          value={updatedChoice.label}
          margin="dense"
          name="label"
          onChange={onValueChange}
        />
        <TextField
          label={t("Alacarte:Help")}
          fullWidth
          value={updatedChoice.help}
          margin="dense"
          name="help"
          multiline
          onChange={onValueChange}
        />
        <FormControl margin="dense">
          <InputLabel id="intervalSelectLabel">
            {t("Alacarte:Interval")}
          </InputLabel>
          <Select
            label={t("Alacarte:Interval")}
            labelId="intervalSelectLabel"
            fullWidth
            value={updatedChoice.interval || ChoiceInterval.annually}
            margin="dense"
            name="period"
            onChange={onSelectChange}
          >
            {Object.values(ChoiceInterval).map((interval) => (
              <MenuItem key={interval} value={interval}>
                {t(`Alacarte:Interval_${interval}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {updatedChoice.dataDefinition.choiceRole === "destination" && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={updatedChoice.upload}
                  name="upload"
                  onChange={onUploadChange}
                />
              }
              label={t("Alacarte:AttachmentRequired")}
            />
          </FormGroup>
        )}
        {updatedChoice.dataDefinition.choiceRole === "source" &&
          updatedChoice.dataDefinition.unitAfter === "uur" && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updatedChoice.userInput}
                    name="userInput"
                    onChange={onValueChange}
                  />
                }
                label={t("Alacarte:UserInput")}
              />
            </FormGroup>
          )}
      </CardContent>

      <CardActions>
        <Grid container alignItems="flex-end">
          <Grid item xs>
            <Button disabled={!dirty} onClick={resetChoice}>
              {t("Alacarte:ChoiceReset")}
            </Button>
            <Button
              disabled={!dirty}
              color="primary"
              onClick={(): void => saveChoice(t("Alacarte:ChoiceSaved"))}
            >
              {t("Alacarte:ChoiceSave")}
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(): void => removeChoice(t("Alacarte:ChoiceRemoved"))}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

interface ChoiceProps extends WithSnackbarProps {
  choice: DataChoiceDefinition;
  removeChoice: () => void;
  shiftDown: (definition: ChoiceDefinitionModel) => void;
  shiftDownEnabled: boolean;
  shiftUp: (definition: ChoiceDefinitionModel) => void;
  shiftUpEnabled: boolean;
  updateChoice: (updatedChoice: ChoiceDefinitionModel) => void;
  userInputAllowed: boolean;
}

class ChoiceEdit extends React.Component<
  ChoiceProps,
  { dirty: boolean; updatedChoice: DataChoiceDefinition }
> {
  public constructor(props: ChoiceProps) {
    super(props);
    this.state = {
      dirty: false,
      updatedChoice: props.choice,
    };
  }

  private onShiftDown = (): void => {
    this.props.shiftDown(this.props.choice);
  };

  private onShiftUp = (): void => {
    this.props.shiftUp(this.props.choice);
  };

  private onValueChange = (e: ChangeEvent): void => {
    this.setState({ dirty: true });
    const target = e.target as HTMLInputElement;
    const { name } = target;
    const rawValue = target.value;
    const { updatedChoice } = this.state;
    if (!updatedChoice) return;

    let next = updatedChoice;
    switch (name) {
      case "earningCode":
        next = { ...next, earningCode: rawValue };
        break;
      case "label":
        next = { ...next, label: rawValue };
        break;
      case "help":
        next = { ...next, help: rawValue };
        break;
      case "userInput":
        next = { ...next, userInput: target.checked };
        break;
      default:
        throw new Error(`Invalid input ${name}.`);
    }

    this.setState({ updatedChoice: next });
  };

  private onSelectIntervalChange = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const { updatedChoice } = this.state;
    this.setState({
      updatedChoice: {
        ...updatedChoice,
        interval: e.target.value as ChoiceInterval,
      },
      dirty: true,
    });
  };

  private onUploadChange = (_: ChangeEvent, checked: boolean): void => {
    const { updatedChoice } = this.state;
    this.setState({
      updatedChoice: { ...updatedChoice, upload: checked },
      dirty: true,
    });
  };

  private saveChoice = (message: string): void => {
    const { updatedChoice } = this.state;
    const { enqueueSnackbar } = this.props;
    if (!updatedChoice) return;
    this.setState({ dirty: false });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateChoice(updatedChoice);
    enqueueSnackbar(message, { variant: "success" });
  };

  private resetChoice = (): void => {
    this.setState({ dirty: false });
    const { choice: updatedChoice } = this.props;
    this.setState({ updatedChoice });
  };

  private removeChoice = (message: string): void => {
    const { enqueueSnackbar } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.removeChoice();
    enqueueSnackbar(message, { variant: "success" });
  };

  public render(): JSX.Element {
    const { updatedChoice, dirty } = this.state;
    if (!updatedChoice) return <div />;

    return (
      <ChoiceEditView
        dirty={dirty}
        onSelectIntervalChange={this.onSelectIntervalChange}
        onShiftDown={this.onShiftDown}
        onShiftUp={this.onShiftUp}
        onUploadChange={this.onUploadChange}
        onValueChange={this.onValueChange}
        removeChoice={this.removeChoice}
        resetChoice={this.resetChoice}
        saveChoice={this.saveChoice}
        shiftDownEnabled={this.props.shiftDownEnabled}
        shiftUpEnabled={this.props.shiftUpEnabled}
        updatedChoice={updatedChoice}
        userInputAllowed={this.props.userInputAllowed}
      />
    );
  }
}

export default withSnackbar(ChoiceEdit);
