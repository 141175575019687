import { Button, Typography } from "@material-ui/core";
import { Edit as EditIcon, Save as SaveIcon } from "@material-ui/icons";
import React from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  isEditing: boolean;
  onEdit(): void;
  onSave(): void;
}

const UserEditSaveToggleButton = (props: Props) => {
  const { isEditing, onEdit, onSave, ...otherProps } = props;
  const [t] = useTranslationStrict();

  return (
    <Button
      {...otherProps}
      color={isEditing ? "secondary" : "primary"}
      onClick={isEditing ? onSave : onEdit}
    >
      {isEditing ? <SaveIcon /> : <EditIcon />}
      <Typography style={{ marginLeft: 5 }} variant="button">
        {isEditing ? t("Common:Save") : t("Common:Edit")}
      </Typography>
    </Button>
  );
};

export default UserEditSaveToggleButton;
