import { Button, Grid, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useSingle } from "async-lifecycle-hooks";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import FileSaver from "file-saver";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  UploadCategory,
  UploadStatusModel,
  UploadTypeModel,
} from "../../../business/admin/upload/models";
import { FileResponse } from "../../../business/api";
import {
  adminUploadAddCell,
  adminUploadCheckCell,
  adminUploadDeleteCell,
  adminUploadListCell,
  adminUploadProcessCell,
  adminUploadTypesCell,
} from "../../../business/redux/saga/admin/upload/cells";
import { dmsDocumentSaveCell } from "../../../business/redux/saga/dms/cells";
import useConfirm from "../../../confirm";
import { ignore } from "../../../confirm/core";
import { useTranslationStrict } from "../../../globalization/i18n";
import { showError } from "../../../utils/SnackbarUtils";
import UploadCreator from "../../admin/upload/UploadCreator";
import UploadTable from "../../admin/upload/UploadTable";
import UploadTypeFilter from "../../admin/upload/UploadTypeFilter";
import { RefreshTimer, useSnackbarErrorMessage } from "../../ux";
import useStyles from "./AdminPage.styles";

const noUploads: UploadStatusModel[] = [];
const noTypes: UploadTypeModel[] = [];
const UploadsPage = () => {
  const [t] = useTranslationStrict();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const [filterUploadTypeId, setFilterUploadTypeId] = useState<number | null>(
    null
  );
  const uploadTypes = useSingle(adminUploadTypesCell).value ?? noTypes;
  const uploadListPage = useSelector(adminUploadListCell.select);
  const [pageNumber, setPageNumber] = useState(0);
  const [uploadList, setUploadList] = useState(noUploads);
  const someBusy =
    uploadList.some(({ busy }): boolean => Boolean(busy)) ?? false;

  const dispatch = useDispatch();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { confirmAction } = useConfirm();
  const [collapse, setCollapse] = useState(true);

  const loadMore = useCallback(() => {
    const pageNumberNext = pageNumber + 1;
    setPageNumber(pageNumberNext);
    dispatch(
      adminUploadListCell.require(
        { pageNumber: pageNumberNext },
        {
          onSuccess: ({ body }) => {
            setUploadList([...uploadList, ...body.pageItems]);
          },
        }
      )
    );
  }, [dispatch, pageNumber, uploadList]);

  const reload = useCallback(
    (pageNumberLimit: number) => {
      dispatch(
        adminUploadListCell.require(
          { pageNumber: pageNumberLimit, includePreviousPages: true },
          {
            onSuccess: ({ body }) => {
              setUploadList(body.pageItems);
            },
          }
        )
      );
    },
    [dispatch]
  );

  const resetList = useCallback(() => {
    setPageNumber(1);
    setUploadList([]);
    dispatch(
      adminUploadListCell.require(
        { pageNumber: 1 },
        {
          onSuccess: ({ body }) => {
            setUploadList(body.pageItems);
          },
        }
      )
    );
  }, [dispatch]);

  const onDelete = (id: number) =>
    confirmAction(
      adminUploadDeleteCell.require(id, {
        onFail: () => showError(enqueueSnackbar),
        onSuccess: () => {
          resetList();
        },
      })
    );
  const onCheck = (id: number) => {
    dispatch(
      adminUploadCheckCell.require(id, {
        onFail: () => {
          showError(enqueueSnackbar);
        },
        onSuccess: () => {
          resetList();
        },
      })
    );
  };
  const { confirm } = useConfirm();
  const onProcess = (id: number, again: boolean, category: UploadCategory) => {
    const doit = (startChoicePeriod: boolean) => {
      dispatch(
        adminUploadProcessCell.require(
          {
            id,
            again,
            startChoicePeriod,
          },
          {
            onFail: showErrorMessageFromDetails(
              t("Admin:Verwerken aanvragen mislukt.")
            ),
            onSuccess: () => {
              enqueueSnackbar(
                t(
                  "Admin:Verwerken aangevraagd. Even geduld alsjeblieft. Zodra het bestand verwerkt is, wordt dit getoond."
                ),
                {
                  variant: "info",
                }
              );
              resetList();
            },
          }
        )
      );
    };
    if (category === "admin") {
      confirm({
        texts: {
          ns: "Admin",
          title: "UploadProcess",
          content: "UploadStartChoicePeriod",
          confirm: "Yes",
          cancel: "No",
        },
        onAbort: ignore,
        onConfirm: (): void => doit(true),
        onCancel: (): void => doit(false),
      });
    } else {
      doit(false);
    }
  };
  const onDownload = (id: number) => {
    dispatch(
      dmsDocumentSaveCell.require(`/api/upload/${id}`, {
        onFail: (details: Details) => {
          enqueueSnackbar(details.title, { variant: "error" });
        },
        onSuccess: ({ body: { data, name } }: Success<FileResponse>) => {
          FileSaver.saveAs(data, name);
        },
      })
    );
  };

  const handleUploadTypeChange = useCallback(
    /**
     * Handles the selection of an upload type filter.
     * @param id The identifier of the upload type to filter.
     */
    (id: number | null) => {
      setFilterUploadTypeId(id);
    },
    []
  );

  const filteredUploads = useMemo(
    /**
     * Returns a filtered array of uploads, depending on the filter(s).
     */
    () =>
      uploadList.filter(
        ({ uploadTypeDto: { id } }) =>
          filterUploadTypeId === null || filterUploadTypeId === id
      ) || [],
    [filterUploadTypeId, uploadList]
  );

  useEffect(() => {
    if (pageNumber === 0) {
      loadMore();
    }
  }, [loadMore, pageNumber]);

  const intervalAction = useCallback(() => {
    reload(pageNumber);
  }, [pageNumber, reload]);

  return (
    <div className={classes.gridWideContent}>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <UploadTypeFilter
            onChange={handleUploadTypeChange}
            value={filterUploadTypeId}
          />
        </Grid>
        <Grid item>
          <RefreshTimer
            intervalAction={intervalAction}
            intervalActionDelay={20000}
            intervalProgressDelay={100}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card style={{ padding: 24 }}>
            {uploadList.length > 0 && (
              <UploadTable
                uploads={
                  collapse && filteredUploads.length > 5
                    ? filteredUploads.slice(0, 5)
                    : filteredUploads
                }
                someBusy={someBusy}
                onCheck={onCheck}
                onDelete={onDelete}
                onDownload={onDownload}
                onProcess={onProcess}
              />
            )}
            {filteredUploads.length > 5 && (
              <IconButton onClick={() => setCollapse(!collapse)}>
                {collapse ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </IconButton>
            )}
            {!uploadListPage.value ||
              (uploadList.length < uploadListPage.value.total && (
                <Button onClick={loadMore}>{t("Admin:Meer laden...")}</Button>
              ))}
            <CardContent>
              {uploadTypes && uploadTypes.length > 0 && (
                <UploadCreator
                  types={uploadTypes}
                  onAdd={(uploadTypeId, effectiveDate, file, onSuccess) => {
                    dispatch(
                      adminUploadAddCell.require(
                        {
                          uploadTypeId,
                          effectiveDate,
                          file,
                        },
                        {
                          onSuccess: () => {
                            onSuccess();
                            resetList();
                          },
                        }
                      )
                    );
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(UploadsPage);
