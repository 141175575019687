import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnnouncementFormValues,
  AnnouncementModel,
} from "../../business/models";
import { useSession } from "../../business/redux/saga/admin/hooks";
import { announcementsListPagedCell } from "../../business/redux/saga/announcements/cells";
import { useAnnouncements } from "../../business/redux/saga/announcements/hooks";
import { AnnouncementResponse } from "../../business/redux/saga/announcements/models";
import { StoreModel } from "../../business/redux/saga/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { isAdminRole } from "../../utils/SecurityUtils";
import CreateAnnouncementDialog from "../admin/announcements/CreateAnnouncementDialog";
import ContentCard from "../layout/ContentCard";
import Loading from "../ux/Loading";
import { useSnackbarErrorMessage } from "../ux/SnackbarErrorMessage";
import Announcement from "./Announcement";

const AnnouncementsCard = () => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { showErrorMessage, showErrorMessageFromDetails } =
    useSnackbarErrorMessage();

  const [collapseOpenId, setCollapseOpenId] = useState<number | null>(null);

  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const {
    session: { appRole },
  } = useSession();
  const { add, getPaged, retire } = useAnnouncements();

  const [createAnnouncementIsOpen, setCreateAnnouncementIsOpen] =
    useState<boolean>(false);

  const isLoading = useSelector(
    ({
      announcements: {
        listPaged: {
          status: { loading },
        },
      },
    }: StoreModel) => loading
  );

  const loadNextPage = useCallback(
    (page?: number, afresh?: boolean) => {
      getPaged(
        page === undefined ? currentPage : page,
        undefined, // default page size (5)
        () => {},
        ({
          body: {
            status,
            announcements: newAnnouncements,
            totalCount: newTotalCount,
          },
        }: Success<AnnouncementResponse>) => {
          if (status === "failed") {
            return;
          }
          setCurrentPage(afresh ? 1 : currentPage + 1);
          setTotalCount(
            newTotalCount || (newAnnouncements && newAnnouncements.length) || 0
          );
          setAnnouncements(
            afresh
              ? newAnnouncements || []
              : [...announcements, ...(newAnnouncements || [])]
          );
        }
      );
    },
    [announcements, currentPage, getPaged]
  );

  const handleRetireClick = useCallback(
    ({ id, title }: AnnouncementModel): void => {
      if (!id) {
        return;
      }
      retire(
        id,
        showErrorMessageFromDetails(
          t("Admin:AnnouncementRetireFailed", { title })
        ),
        (success: Success<AnnouncementResponse>) => {
          if (success.body.status === "success") {
            loadNextPage(0, true);
          }
        }
      );
    },
    [loadNextPage, retire, showErrorMessageFromDetails, t]
  );

  useEffect((): void => {
    dispatch({ type: announcementsListPagedCell.events.invalidate });
    loadNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddClick = useCallback((): void => {
    setCreateAnnouncementIsOpen(true);
  }, []);

  const handleCreateCancel = useCallback((): void => {
    setCreateAnnouncementIsOpen(false);
  }, []);

  const handleCreateConfirm = useCallback(
    (
      announcement: AnnouncementFormValues,
      failCallback?: (details: Details) => void,
      successCallback?: (success: Success<AnnouncementResponse>) => void
    ): void => {
      add(
        announcement as unknown as AnnouncementModel & {
          notifyUsers: boolean;
        },
        (details) => {
          showErrorMessage({
            title: t("Admin:AnnouncementCreateFailedSnackbarTitle"),
            message: t("Admin:AnnouncementCreateFailedSnackbarMessage"),
          });
          if (failCallback) {
            failCallback(details);
          }
        },
        (success: Success<AnnouncementResponse>): void => {
          if (success.body.status === "success") {
            setCreateAnnouncementIsOpen(false);
            loadNextPage(0, true);
            if (successCallback) {
              successCallback(success);
            }
          }
        }
      );
    },
    [add, loadNextPage, showErrorMessage, t]
  );

  const handleListItemClick = useCallback(
    (id: number): void => {
      setCollapseOpenId(collapseOpenId === id ? null : id);
    },
    [collapseOpenId]
  );

  // This strips the arguments
  const handleNextPage = useCallback(
    (): void => loadNextPage(),
    [loadNextPage]
  );

  const actions = useMemo<React.ReactNode | undefined>(():
    | React.ReactNode
    | undefined => {
    if (!isAdminRole(appRole)) {
      return undefined;
    }

    return (
      <>
        <Tooltip title={t("Common:Add")}>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }, [appRole, handleAddClick, t]);

  return (
    <ContentCard
      title={t("Communication:Announcements") as string}
      info={t("Communication:AnnouncementsInfo") as string}
      actions={actions}
    >
      {!isLoading && announcements && announcements.length === 0 && (
        <Typography variant="caption">
          {t("Communication:AnnouncementsNone")}
        </Typography>
      )}
      {announcements && announcements.length > 0 && (
        <List>
          {announcements
            .filter(
              (announcement): boolean =>
                !announcement.adminOnly || isAdminRole(appRole)
            )
            .map(
              (announcement): React.ReactElement => (
                <Announcement
                  key={announcement.id}
                  announcement={announcement}
                  open={collapseOpenId === announcement.id}
                  onClick={handleListItemClick}
                  onRetire={
                    isAdminRole(appRole) ? handleRetireClick : undefined
                  }
                />
              )
            )}
          {announcements.length < totalCount && (
            <ListItem button onClick={handleNextPage}>
              <ListItemIcon>
                <MoreVertIcon />
              </ListItemIcon>
              <ListItemText primary={t("Common:LoadMore")} />
            </ListItem>
          )}
        </List>
      )}
      {isLoading && <Loading />}
      <CreateAnnouncementDialog
        open={createAnnouncementIsOpen}
        onCancel={handleCreateCancel}
        onConfirm={handleCreateConfirm}
      />
    </ContentCard>
  );
};

export default memo(AnnouncementsCard);
