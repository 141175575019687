import { createActor } from "async-lifecycle-saga";

import { asyncDelete, asyncPost, asyncPut } from "../../../../api";
import { NavigationLocation, NavigationNodeModel } from "../../../../models";
import { PruneRequest } from "./models";

export * from "./content/cells";

export const adminNavigationNodeAddChildCell = createActor<
  NavigationNodeModel & { parentId: number },
  NavigationNodeModel
>({
  path: ["admin", "navigation", "navigationNode", "addChild"],
  api: ({ parentId, ...childNode }) =>
    asyncPost(
      `/api/admin/navigation/add/${parentId}`,
      childNode as NavigationNodeModel
    ),
});

export const adminNavigationNodeAddToRootCell = createActor<
  { location: NavigationLocation; childNode: NavigationNodeModel },
  NavigationNodeModel
>({
  path: ["admin", "navigation", "navigationNode", "addToRoot"],
  api: ({ location, childNode }) =>
    asyncPost<NavigationNodeModel>(
      `/api/admin/navigation/root/${location}`,
      childNode
    ),
});

export const adminNavigationNodeDeleteCell = createActor<
  number,
  NavigationNodeModel
>({
  path: ["admin", "navigation", "navigationNode", "delete"],
  api: (id) => asyncDelete(`/api/admin/navigation/${id}`),
});

export const adminNavigationNodePruneCell = createActor<
  PruneRequest,
  NavigationNodeModel
>({
  path: ["admin", "navigation", "navigationNode", "prune"],
  api: (pruneRequest) => asyncPost("/api/admin/navigation/prune", pruneRequest),
});

export const adminNavigationNodeUpdateCell = createActor<
  NavigationNodeModel,
  NavigationNodeModel
>({
  path: ["admin", "navigation", "navigationNode", "update"],
  api: (node) => asyncPut<NavigationNodeModel>("/api/admin/navigation", node),
});
