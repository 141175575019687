import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes saveButtonEditing": {
    "0%": {
      backgroundColor: theme.palette.primary.main,
    },
    "50%": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
    "100%": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  saveButtonEditing: {
    animation: `$saveButtonEditing 2s ${theme.transitions.easing.easeInOut} infinite`,
  },
}));

export default useStyles;
