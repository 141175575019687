import { FormControl, FormLabel, Grid, Typography } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "material-ui-pickers";

import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";
import { WlzCurrencyPairInput } from "./";

interface Props extends FormikProps<WlzAdviceCase> {
  index: number;
}

const WlzAdviceCaseSubjectFormSection = (props: Props) => {
  const {
    handleChange,
    index,
    isSubmitting,
    setFieldValue,
    values: { couple, subjects },
  } = props;
  const [t] = useTranslationStrict();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{ width: couple ? "50%" : "100%" }}
    >
      <Grid item>
        <Typography variant="h6">
          {t("Wlz:" + (index === 0 ? "Client" : "Partner"))}
        </Typography>
      </Grid>
      <Grid item>
        <Field
          component={TextField}
          disabled={isSubmitting}
          fullWidth={!couple}
          label={t("Wlz:Naam")}
          name={`subjects[${index}].name`}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth={!couple}>
          <FormLabel>{t("Wlz:Geboortedatum")}</FormLabel>
          <DatePicker
            keyboard
            disabled={isSubmitting}
            format="dd-MM-yyyy"
            onChange={(date) =>
              setFieldValue(`subjects[${index}].dateOfBirth`, date)
            }
            value={subjects[index].dateOfBirth}
          />
        </FormControl>
      </Grid>
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:AOW uitkering", name: "aowBenefit" },
          { label: "Wlz:Pensioen", name: "pension" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:Winst uit onderneming", name: "profitEnterprise" },
          { label: "Wlz:Overig inkomen", name: "incomeOther" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:WOZ-waarde woning", name: "wozValueResidence" },
          { label: "Wlz:Hypotheek", name: "mortgage" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:Hypotheekrente", name: "mortgageInterest" },
          { label: "Wlz:Aftrek zorgkosten", name: "healthcareDeduction" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:Aftrek giften", name: "giftsDeduction" },
          { label: "Wlz:Spaartegoed", name: "savingsCredit" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          { label: "Wlz:Beleggingen", name: "investments" },
          { label: "Wlz:Inhouding loonheffing", name: "wageTaxWithholding" },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <WlzCurrencyPairInput
        couple={couple}
        fields={[
          {
            label: "Wlz:Ingeh. dividendbelasting",
            name: "dividendTaxWithheld",
          },
          {
            label: "Wlz:Voorlopige aanslag IB",
            name: "incomeTaxAssessmentProvisional",
          },
        ]}
        handleChange={handleChange}
        index={index}
        subjects={subjects}
      />
      <Grid item>
        <Field
          component={TextField}
          disabled={isSubmitting}
          fullWidth={!couple}
          label={t("Wlz:Notities")}
          multiline
          name={`subjects[${index}].notes`}
          rows={5}
        />
      </Grid>
    </Grid>
  );
};

export default WlzAdviceCaseSubjectFormSection;
