import { Box } from "@material-ui/core";
import React, { memo } from "react";

import ChoiceBenefits from "../../admin/info/ChoiceBenefits";
import VariablesOverview from "../../admin/info/VariablesOverview";
import useStyles from "./AdminPage.styles";

const AdminInfoPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <ChoiceBenefits />
      <Box style={{ margin: "2em 0em 2em 0em" }} />
      <VariablesOverview />
    </div>
  );
};

export default memo(AdminInfoPage);
