import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  field: {
    marginTop: theme.spacing(),
  },
}));

export default useStyles;
