import { Button } from "@material-ui/core";
import { useCallback } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ContractsExportCreateRequest } from "../../../business/admin/contracts/models";
import { adminContractsExportCreateCell } from "../../../business/redux/saga/admin/contracts/cells";
import { Loading } from "../../ux";

interface ContractsExportButtonProps {
  request: ContractsExportCreateRequest;
}

const ContractsExportButton = (props: ContractsExportButtonProps) => {
  const { request } = props;
  const dispatch = useDispatch();

  const {
    status: { loading },
  } = useSelector(adminContractsExportCreateCell.select);

  const handleClick = useCallback(() => {
    dispatch(adminContractsExportCreateCell.require(request));
  }, [dispatch, request]);

  return (
    <Button disabled={loading} onClick={handleClick}>
      {loading && <Loading />}
      {!loading && (
        <Trans ns="Admin" i18nKey="ContractsExportNew">
          Nieuwe export
        </Trans>
      )}
    </Button>
  );
};

export default ContractsExportButton;
