import { Input, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";

export type InputNumberType = "number" | "money" | "percentage";

export interface InputNumberProps {
  value?: number | null;
  mode: InputNumberType;
  onChange: (value: number | null) => void;
}

interface NumberFormatCustomProps extends InputNumberProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
}

const formatDefaults = {
  decimalSeparator: ",",
  thousandSeparator: ".",
  isNumericString: false,
};

const NumberFormatSimple = ({
  inputRef,
  onChange,
  ...other
}: NumberFormatCustomProps) => {
  const defaultProps = {
    ...other,
    ...formatDefaults,
    getInputRef: inputRef,
  };

  return (
    <NumberFormat
      {...defaultProps}
      onValueChange={(values) => {
        onChange(values.floatValue ?? null);
      }}
    />
  );
};

const NumberFormatMoney = ({
  inputRef,
  onChange,
  ...other
}: NumberFormatCustomProps) => {
  const defaultProps = {
    ...other,
    ...formatDefaults,
    getInputRef: inputRef,
  };

  return (
    <NumberFormat
      {...defaultProps}
      onValueChange={(values) => {
        onChange(values.floatValue ?? null);
      }}
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const NumberFormatPercentage = ({
  inputRef,
  mode,
  value,
  onChange,
  ...other
}: NumberFormatCustomProps) => {
  const defaultProps = {
    ...other,
    ...formatDefaults,
    getInputRef: inputRef,
  };

  return (
    <NumberFormat
      {...defaultProps}
      onValueChange={(values) => {
        onChange(values.floatValue ? values.floatValue / 100 : null);
      }}
      value={value ? value * 100 : null}
    />
  );
};

const useStyles = makeStyles({
  number: {
    "& input": {
      textAlign: "right",
    },
  },
});

const InputNumber = ({ mode, value, onChange }: InputNumberProps) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (numberValue) => {
      onChange(numberValue);
    },
    [onChange]
  );

  switch (mode) {
    case "number":
      return (
        <Input
          value={value}
          inputComponent={NumberFormatSimple as any}
          onChange={handleChange}
          className={classes.number}
        />
      );
    case "money":
      return (
        <Input
          value={value}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          inputComponent={NumberFormatMoney as any}
          onChange={handleChange}
          className={classes.number}
        />
      );
    case "percentage":
      return (
        <Input
          value={value}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          inputComponent={NumberFormatPercentage as any}
          onChange={handleChange}
          className={classes.number}
        />
      );
    default:
      return null;
  }
};

export default InputNumber;
