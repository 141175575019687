import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { OrganizationModel, SessionModel } from "../models";
import reducer from "./reducers";
import { StoreType, setReflexoStore } from "./reflexoStore";
import { authenticationRefreshCell } from "./saga/authentication/cells";
import sagas from "./saga/index";

function createReflexoStore(
  organization: OrganizationModel,
  session?: SessionModel
): StoreType {
  // Saga Middleware
  const sagaMiddleware = createSagaMiddleware();

  const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;
  const composeEnhancers =
    (process.env.NODE_ENV === "development" &&
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  // Redux store
  const store = createStore(
    reducer,
    {
      organization: {
        get: { status: { loading: false }, value: organization },
      },
      session,
    },
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  // Run Sagas
  sagaMiddleware.run(sagas);
  setReflexoStore(store);
  store.dispatch(
    authenticationRefreshCell.require({
      refreshToken: store.getState().session.jwt?.refreshToken || "",
    })
  );
  return store;
}

export default createReflexoStore;
