import { createActor, createSingle } from "async-lifecycle-saga";

import { FileResponse, asyncGet } from "../../../api";
import { OrganizationModel, emptyOrganization } from "../../../models";
import { StoreModel } from "../models";
import {
  organizationGetter,
  organizationPrivacyStatementDownloadGetter,
} from "./api";

export const organizationCell = createSingle<OrganizationModel, StoreModel>({
  path: ["organization", "get"],
  require: {
    api: () => organizationGetter(false),
  },
});

export const organizationPrivacyStatementDownloadCell = createActor<
  number,
  FileResponse
>({
  path: ["organization", "privacyStatementDownload"],
  api: (organizationId: number) =>
    organizationPrivacyStatementDownloadGetter(organizationId),
});

export const organizationPrivacyStatementExistsCell = createSingle<
  never,
  StoreModel,
  { organizationId: number }
>({
  path: ["organization", "privacyStatementExists"],
  require: {
    api: (_, { organizationId }) =>
      asyncGet<never>(
        `/api/organization/${organizationId}/privacy-statement/exists`,
        true
      ),
  },
  context: ({
    organization: {
      get: { value: { id } = emptyOrganization },
    },
  }: StoreModel): { organizationId: number } => ({ organizationId: id }),
});
