import { ReactNode, memo } from "react";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";

import {
  BreadCrumbNodeModel,
  NavigationNodeModel,
} from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { RouterContext, selectMenuNavigation } from "../../../utils/routes";
import BreadCrumbs, { BreadCrumbsProps } from "../../navigation/BreadCrumbs";
import BasePage, { BasePageData } from "../BasePage";
import useStyles from "./AdminPage.styles";

export type AdminPageProps = BasePageData & {
  breadCrumbs?: BreadCrumbsProps;
};

const AdminPage = (
  props: AdminPageProps & { children?: ReactNode }
): JSX.Element => {
  const [t] = useTranslationStrict();
  const caption = ({ id, title }: NavigationNodeModel): string =>
    id ? title : t(`Navigation:${title}`);
  const { children, ...baseProps } = props;

  const classes = useStyles();

  const navigationItems = useSelector(selectMenuNavigation(t))?.items || [];

  const createCrumbs = (routeProps: RouteProps): BreadCrumbNodeModel[] => {
    const crumbs: BreadCrumbNodeModel[] = [{ url: "/", caption: "Dashboard" }];

    const allItems = navigationItems;
    for (let i = 0; i < allItems.length; i += 1) {
      const main = allItems[i];
      if (routeProps.location && main.route === routeProps.location.pathname) {
        crumbs.push({ url: main.route, caption: caption(main) });
      }

      for (let j = 0; main.children && j < main.children.length; j += 1) {
        const sub = main.children[j];
        if (routeProps.location && sub.route === routeProps.location.pathname) {
          crumbs.push({ url: main.route, caption: caption(main) });
          crumbs.push({ url: sub.route, caption: caption(sub) });
        }
      }
    }

    return crumbs;
  };

  return (
    <RouterContext.Consumer>
      {(routeProps): JSX.Element => (
        <BasePage {...baseProps}>
          <div className={classes.gridBreadCrumbs}>
            <BreadCrumbs crumbs={createCrumbs(routeProps)} />
          </div>
          {children}
        </BasePage>
      )}
    </RouterContext.Consumer>
  );
};

export default memo(AdminPage);
