import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

import {
  heightBlueBackground,
  positionDisplacementBlueBackground,
} from "../../styling/Common";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100vw",
    minHeight: "calc(100vh - 200px)",
  },
  backgroundBlue: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.25);",
    position: "absolute",
    width: "100%",
    ...heightBlueBackground(),
    overflow: "hidden",
    zIndex: 1011,
  },
  content: {
    background: "transparent",
    gridColumn: 2,
    gridRow: 2,
    minWidth: 330,
    paddingTop: "2.8vh",
    ...positionDisplacementBlueBackground(),
    zIndex: 1012,

    "& > *": {
      zIndex: 1013,
    },
  },
}));

export default useStyles;
