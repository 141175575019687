import { History, createBrowserHistory } from "history";
import { BrowserRouter, BrowserRouterProps } from "react-router-dom";

export const reflexoHistory: History = createBrowserHistory();

export class ReflexoRouter extends BrowserRouter {
  public constructor(props: BrowserRouterProps) {
    super(props);
    this.history = reflexoHistory;
  }

  public history: History;
}
