import { AsyncResponse, createActor } from "async-lifecycle-saga";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { takeEvery } from "redux-saga/effects";

import { getRefreshToken } from "../../../../utils/SecurityUtils";
import { asyncPost } from "../../../api";
import { SessionModel } from "../../../models";
import { StoreModel } from "../models";
import {
  AuthenticationResponseModel,
  KnowledgeRequestModel,
  PossessionRequest,
  PossessionRequestModel,
  RefreshRequestModel,
  SsoToken,
} from "./models";
import {
  authenticationAuthenticated,
  authenticationError,
  authenticationKnowledgeSuccess,
  authenticationLogOut,
  authenticationRequireTwoFactor,
  authenticationSuccess,
  authenticationUnauthorized,
} from "./sagas";

export const authenticationRefresh = (
  refreshRequest: RefreshRequestModel
): Promise<AsyncResponse<AuthenticationResponseModel>> =>
  asyncPost<AuthenticationResponseModel>(
    "/api/user/refresh",
    refreshRequest,
    true
  );

export const authenticationKnowledgeCell = createActor<
  KnowledgeRequestModel,
  AuthenticationResponseModel
>({
  path: ["authentication", "knowledge"],
  api: (credentials) => asyncPost("/api/user/authenticate", credentials, true),
});

export const authenticationSsoCell = createActor<undefined, SsoToken>({
  path: ["authentication", "sso"],
  api: () =>
    asyncPost<SsoToken>("/api/user/ssotoken", {
      loginName: "",
      refreshToken: getRefreshToken(),
    }),
});

export const authenticationPossessionCell = createActor<
  PossessionRequestModel,
  AuthenticationResponseModel,
  StoreModel,
  SessionModel
>({
  path: ["authentication", "possession"],
  api: (request, session) =>
    asyncPost(
      "/api/authentication/googleAuthenticator/authenticate",
      {
        ...request,
        knowledgeTokenValue: session.googleAuthenticator?.knowledgeToken,
        loginName: session.user?.loginName,
      } as PossessionRequest,
      true
    ),
  context: ({ session }) => session,
});

export const authenticationRefreshCell = createActor<
  RefreshRequestModel,
  AuthenticationResponseModel
>({
  path: ["authentication", "refresh"],
  api: authenticationRefresh,
});

export const authenticationLogOutCell = createActor<
  {},
  AuthenticationResponseModel
>({
  path: ["authentication", "logout"],
  api: () => asyncPost<AuthenticationResponseModel>(`api/user/logOut`, {}),
});

export const authenticationErrorEvents = [
  authenticationKnowledgeCell.events.error,
  authenticationPossessionCell.events.error,
  authenticationRefreshCell.events.error,
];

export const authenticationSuccessEvents = [
  // Knowledge requires additional scrutiny. (e.g. 2FA required?)
  authenticationPossessionCell.events.success,
  authenticationRefreshCell.events.success,
];

const authenticated = "AUTHENTICATION_AUTHENTICATED";
const error = "AUTHENTICATION_ERROR";
const twoFactorRequired = "AUTHENTICATION_REQUIRE_TWOFACTOR";
const unauthorized = "AUTHENTICATION_UNAUTHORIZED";
export const authenticationCell = {
  events: {
    authenticated,
    error,
    twoFactorRequired,
    unauthorized,
  },
  authenticated: (result: Success<AuthenticationResponseModel>) => ({
    type: authenticationCell.events.authenticated,
    result,
  }),
  error: (result: Details) => ({
    type: authenticationCell.events.error,
    result,
  }),
  twoFactorRequired: () => ({
    type: authenticationCell.events.twoFactorRequired,
  }),
  unauthorized: () => ({ type: authenticationCell.events.unauthorized }),
  sagas: [
    takeEvery(authenticated, authenticationAuthenticated),
    takeEvery(authenticationSuccessEvents, authenticationSuccess),
    takeEvery(error, authenticationError),
    takeEvery(twoFactorRequired, authenticationRequireTwoFactor),
    takeEvery(unauthorized, authenticationUnauthorized),
  ],
};

export const authenticationSagas = [
  /* Knowledge */
  ...authenticationKnowledgeCell.sagas,
  takeEvery(
    authenticationKnowledgeCell.events.success,
    authenticationKnowledgeSuccess
  ),

  /* Possession */
  ...authenticationPossessionCell.sagas,

  /* Refresh */
  ...authenticationRefreshCell.sagas,

  /* Log out */
  ...authenticationLogOutCell.sagas,
  takeEvery(
    [
      authenticationLogOutCell.events.error,
      authenticationLogOutCell.events.success,
    ],
    authenticationLogOut
  ),

  /* General */
  ...authenticationCell.sagas,
];
