import { useSingle } from "async-lifecycle-hooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { datacockpitMutationsOverviewCell } from "../../../business/redux/saga/datacockpit/cells";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../globalization/i18n";
import Loading from "../../ux/Loading";
import {
  SnackbarErrorMessageShowFromDetailsFunction,
  useSnackbarErrorMessage,
} from "../../ux/SnackbarErrorMessage";
import { MutationsOverviewEmpty, MutationsOverviewTable } from "./";

const mutationsOverviewFailed = (
  t: TFunctionStrict,
  showErrorMessageFromDetails: SnackbarErrorMessageShowFromDetailsFunction
) => showErrorMessageFromDetails(t("Datacockpit:Verwerkingen laden mislukt."));

const MutationsOverview = () => {
  const dispatch = useDispatch();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const [t] = useTranslationStrict();
  const mutations = useSingle(datacockpitMutationsOverviewCell).value;

  useEffect(
    /**
     * Load mutations overview data from backend.
     */
    () => {
      dispatch(
        datacockpitMutationsOverviewCell.require({
          onFail: mutationsOverviewFailed(t, showErrorMessageFromDetails),
        })
      );
    }
  );

  switch (true) {
    case mutations === undefined:
      return <Loading />;
    case mutations!.length === 0:
      return <MutationsOverviewEmpty />;
    default:
      return <MutationsOverviewTable rows={mutations || []} />;
  }
};

export default MutationsOverview;
