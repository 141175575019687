import { FormControl, FormHelperText, FormLabel } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import { DateType } from "material-ui-pickers/constants/prop-types";
import { MaterialUiPickersDate } from "material-ui-pickers/typings/date";
import React from "react";

import { useTranslationStrict } from "../../globalization/i18n";

interface Props {
  label: string;
  onChange: (value: DateType) => void;
  value: DateType;
  minDate?: DateType;
  maxDate?: DateType;
  disabled?: boolean;
  required?: boolean;
  clearable?: boolean;
  helperText?: string;
}

const DateInput = (props: Props) => {
  const [t] = useTranslationStrict();
  const {
    label,
    onChange,
    value,
    disabled,
    helperText,
    required,
    clearable = !required,
    minDate,
    maxDate,
  } = props;

  const handleChange = (date: MaterialUiPickersDate) => {
    onChange(date);
  };

  return (
    <FormControl disabled={disabled} required={required}>
      <FormLabel>{label}</FormLabel>
      <DatePicker
        allowKeyboardControl
        clearable={clearable}
        format="dd-MM-yyyy"
        onChange={handleChange}
        style={{ marginTop: "16px" }}
        value={value}
        disabled={disabled}
        required={required}
        minDate={minDate}
        maxDate={maxDate}
        maxDateMessage={t("Common:Datum mag niet na de maximale datum zijn")}
        minDateMessage={t("Common:Datum mag niet voor de minimale datum zijn")}
        invalidDateMessage={t("Common:Ongeldige datum")}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default DateInput;
