import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import {
  WlzAdviceCaseSummaryCaseTable,
  WlzAdviceCaseSummarySubjectsTable,
} from "./index";

interface Props {
  adviceCase: WlzAdviceCase;
}

const WlzAdviceCaseSummary = (props: Props) => {
  const { adviceCase } = props;

  return (
    <div
      style={{
        gap: 16,
        display: "grid",
        gridTemplateColumns: `repeat(${
          1 + adviceCase.subjects.length
        }, minmax(0, 1fr))`,
      }}
    >
      <WlzAdviceCaseSummaryCaseTable adviceCase={adviceCase} />
      <WlzAdviceCaseSummarySubjectsTable subjects={adviceCase.subjects} />
    </div>
  );
};

export default WlzAdviceCaseSummary;
