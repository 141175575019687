import { createActor, createList } from "async-lifecycle-saga";

import { asyncGet, asyncPost } from "../../../../api";
import { OrganizationModel } from "../../../../models";
import {
  adminChoiceBenefitsCell,
  adminChoiceDefinitionsListCell,
  adminChoiceEmployeeListCell,
  adminChoicePeriodsCell,
} from "../../admin/choices/cells";
import { adminEmployeeTypesCell } from "../../admin/employee/cells";
import { adminUploadListCell } from "../../admin/upload/cells";
import { adminUsersListCell } from "../../admin/users/cells";
import { announcementsListPagedCell } from "../../announcements/cells";
import { AuthenticationResponseModel } from "../../authentication/models";
import { StoreModel } from "../../models";
import {
  navigationGetHistoryCell,
  navigationMenuCell,
  navigationTreeCell,
} from "../../navigation/cells";
import { organizationCell } from "../../organization/cells";
import { avatarCell } from "../../profile/avatar/cells";
import { profileCell } from "../../profile/cells";
import { OrganizationSwitchRequest, Prefix } from "./models";

export const superOrganizationsCopy = createActor<number, {}>({
  path: ["super", "organizations", "copy"],
  api: (id) => asyncPost(`/api/super/organization/copy/${id}`, {}),
  invalidate: [
    navigationMenuCell.events,
    navigationTreeCell.events,
    organizationCell.events,
  ],
});

export const superOrganizationsListCell = createList<
  OrganizationModel,
  StoreModel,
  never,
  never,
  Prefix
>({
  create: {
    api: ({ prefix }: Prefix) =>
      asyncPost(`/api/super/organization/add/${prefix}`, {}),
  },
  path: ["super", "organizations", "list"],
  require: {
    api: () => asyncGet("/api/super/organization"),
  },
  select: (store: StoreModel) => {
    const value =
      store.super.organizations.list.value?.map((org) => {
        if (!org.id) {
          throw new Error("Organization does not have an identifier.");
        }
        return {
          ...org,
          id: org.id,
        };
      }) || [];
    return {
      ...store.super.organizations.list,
      value,
    };
  },
});

export const superOrganizationsSwitchCell = createActor<
  OrganizationSwitchRequest,
  AuthenticationResponseModel
>({
  path: ["super", "organizations", "switch"],
  api: ({ prefix }) =>
    asyncGet<AuthenticationResponseModel>(`/api/user/switch/${prefix}`),
  invalidate: [
    adminChoiceBenefitsCell.events,
    adminChoiceDefinitionsListCell.events,
    adminChoiceEmployeeListCell.events,
    adminChoicePeriodsCell.events,
    adminEmployeeTypesCell.events,
    adminUploadListCell.events,
    adminUsersListCell.events,
    announcementsListPagedCell.events,
    avatarCell.events,
    navigationGetHistoryCell.events,
    navigationMenuCell.events,
    navigationTreeCell.events,
    organizationCell.events,
    profileCell.events,
  ],
});
