import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { buttonTextWhite } from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {},
    button: { ...buttonTextWhite(theme), textTransform: "uppercase" },
    menu: {
      marginTop: theme.spacing() * 6,
    },
    menuItem: {
      cursor: "pointer",
    },
    menuItemLink: {
      color: "rgba(0, 0, 0, 0.87)",
      textDecoration: "none",
      textTransform: "uppercase",
    },
    menuItemSecondaryAction: { right: "0px !important" },
  })
);

export default useStyles;
