import {
  AsyncSingleValue,
  AsyncSparseValue,
  RequestCallback,
} from "async-lifecycle-saga";

import { SearchResultModel, SessionModel } from "../../models";
import { AdminStoreModel } from "./admin/models";
import { AnnouncementsStoreModel } from "./announcements/models";
import { AuthenticationStoreModel } from "./authentication/models";
import { DatacockpitStoreModel } from "./datacockpit/models";
import { DmsStoreModel } from "./dms/models";
import { EmployerStoreModel } from "./employer";
import { NavigationStoreModel } from "./navigation/models";
import { OrganizationStoreModel } from "./organization";
import { PersonalStoreModel } from "./personal/models";
import { ProfileStoreModel } from "./profile/models";
import { SuperStoreModel } from "./super/models";
import { UserStoreModel } from "./user/models";

export type HookDispatchFunction<TRequest, TResponse> = (
  request: TRequest,
  callbacks: RequestCallback<TResponse>
) => void;

export const emptySingleValue = <T>() =>
  ({
    status: { loading: false },
  } as AsyncSingleValue<T>);

export const emptySparseValue = <T>() => ({} as AsyncSparseValue<T>);

export interface StoreModel {
  admin: AdminStoreModel;
  announcements: AnnouncementsStoreModel;
  authentication: AuthenticationStoreModel;
  datacockpit: DatacockpitStoreModel;
  dms: DmsStoreModel;
  employer: EmployerStoreModel;
  navigation: NavigationStoreModel;
  organization: OrganizationStoreModel;
  personal: PersonalStoreModel;
  profile: ProfileStoreModel;
  search: AsyncSingleValue<SearchResultModel>;
  session: SessionModel;
  super: SuperStoreModel;
  user: UserStoreModel;
}
