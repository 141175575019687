import {
  AppBar as AppBarUI,
  Badge,
  Button,
  Fade,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { memo, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { isSpecialRoute } from "../../business/admin/navigation";
import { NavigationNodeModel, NotificationModel } from "../../business/models";
import { adminNavigationNodePruneCell } from "../../business/redux/saga/admin/navigation/cells";
import { StoreModel } from "../../business/redux/saga/models";
import { useTranslationStrict } from "../../globalization/i18n";
import usePageWrap from "../../styling/PageWrap";
import { selectMenuNavigation } from "../../utils/routes";
import { isAdminRole } from "../../utils/SecurityUtils";
import AdminNavigationContext from "../admin/navigation/AdminNavigationContext";
import AppBarDropArea from "../admin/navigation/appBar/AppBarDropArea";
import AppBarEditor from "../admin/navigation/appBar/AppBarEditor";
import Authenticated from "../authentication/Authenticated";
import { useSnackbarErrorMessage } from "../ux";
import useStyles from "./AppBar.styles";
import AppBarMenuItem from "./AppBarMenuItem";
import { AppBarMenuItemOptionProps } from "./AppBarMenuItemOption";
import Search from "./Search";

const AppBar = () => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict("Navigation");
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const appBarClasses = useStyles();
  const pageWrapClasses = usePageWrap();
  const history = useHistory();

  const { isEditing: isNavigationEditing } = useContext(AdminNavigationContext);
  const hasSession = useSelector(
    ({ profile }: StoreModel) => !!(profile && profile.profile)
  );
  const enableEditing = useSelector(
    ({ session }: StoreModel) => !!session && isAdminRole(session.appRole)
  );
  const menuNavigation = useSelector(selectMenuNavigation(t));
  const notifications = useSelector(() => []);

  const menuVisible =
    menuNavigation !== undefined && menuNavigation.items.length > 0;

  const [indexLoading, setIndexLoading] = useState<number | null>(null);
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] =
    useState<HTMLElement | null>(null);
  const notificationsMenuOpen = Boolean(notificationsMenuAnchorEl);
  const handleNotificationsClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      setNotificationsMenuAnchorEl(event.currentTarget);
    },
    [setNotificationsMenuAnchorEl]
  );
  const handleNotificationsMenuClose = useCallback((): void => {
    setNotificationsMenuAnchorEl(null);
  }, [setNotificationsMenuAnchorEl]);
  const handleDrop = useCallback(
    (childId: number, index: number) => {
      setIndexLoading(index);
      dispatch(
        adminNavigationNodePruneCell.require(
          {
            parentIsRoot: true,
            parentId: 0,
            childId,
            index,
          },
          {
            onFail: (details) => {
              setIndexLoading(null);
              showErrorMessageFromDetails(
                t("Fout bij verplaatsen pagina", { ns: "Admin" })
              )(details);
            },
            onSuccess: () => {
              setIndexLoading(null);
            },
          }
        )
      );
    },
    [dispatch, showErrorMessageFromDetails, t]
  );

  const RenderNotifications = useCallback(
    () => (
      <>
        <IconButton
          onClick={handleNotificationsClick}
          disabled={notifications.length === 0}
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={notificationsMenuAnchorEl}
          className={appBarClasses.appBarNotificationsMenu}
          open={notificationsMenuOpen}
          onClose={handleNotificationsMenuClose}
          TransitionComponent={Fade}
        >
          {notifications.map(
            (notification: NotificationModel): React.ReactElement => (
              <MenuItem key={notification.message}>
                <ListItemText>{notification.message}</ListItemText>
              </MenuItem>
            )
          )}
        </Menu>
      </>
    ),
    [
      appBarClasses.appBarNotificationsMenu,
      handleNotificationsClick,
      handleNotificationsMenuClose,
      notifications,
      notificationsMenuAnchorEl,
      notificationsMenuOpen,
    ]
  );

  const mapAppBarMenuItemOption = useCallback(
    ({
      id,
      title: nodeTitle,
      children: nodeChildren,
      route: nodeRoute,
    }: NavigationNodeModel): AppBarMenuItemOptionProps => ({
      caption: t(nodeTitle),
      level: 1,
      nodeId: id!,
      onClick: () => {
        history.push(nodeRoute);
      },
      options: nodeChildren?.map(mapAppBarMenuItemOption),
      route: nodeRoute,
    }),
    [history, t]
  );

  return (
    <Authenticated denyBehavior="ShowEmpty">
      <div className={classNames(appBarClasses.root, pageWrapClasses.outer)}>
        <div className={pageWrapClasses.inner}>
          <AppBarUI
            position="static"
            color="primary"
            className={appBarClasses.appbar}
          >
            <Toolbar
              className={classNames(
                appBarClasses.toolbar,
                pageWrapClasses.inner
              )}
            >
              <div className={appBarClasses.dashboard}>
                {hasSession && (
                  <Link to="/">
                    <Button className={appBarClasses.button}>
                      {t("Dashboard", { ns: "Navigation" })}
                    </Button>
                  </Link>
                )}
              </div>
              <div className={appBarClasses.appBarLinks}>
                {menuVisible && enableEditing && isNavigationEditing && (
                  <AppBarDropArea
                    index={0}
                    loading={indexLoading === 0}
                    onDrop={handleDrop}
                    variant="main"
                  />
                )}
                {menuVisible &&
                  menuNavigation &&
                  menuNavigation.items.map(
                    (
                      {
                        id,
                        title: itemTitle,
                        route: itemRoute,
                        children,
                      }: NavigationNodeModel,
                      index
                    ): React.ReactElement => (
                      <>
                        <AppBarMenuItem
                          key={`menuItem${itemTitle}`}
                          caption={t(itemTitle)}
                          draggable={
                            enableEditing &&
                            !isSpecialRoute(itemRoute) &&
                            isNavigationEditing
                          }
                          nodeId={id!}
                          route={itemRoute}
                          options={children?.map(mapAppBarMenuItemOption)}
                        />
                        {enableEditing &&
                          isNavigationEditing &&
                          index < menuNavigation.items.length - 1 && (
                            <AppBarDropArea
                              index={index + 1}
                              loading={indexLoading === index + 1}
                              onDrop={handleDrop}
                              variant="main"
                            />
                          )}
                      </>
                    )
                  )}
                {enableEditing && <AppBarEditor />}
              </div>
              <div className={appBarClasses.notifications} />
              {!appBarClasses.toolbar && <RenderNotifications />}
              {hasSession && <Search />}
            </Toolbar>
          </AppBarUI>
        </div>
      </div>
    </Authenticated>
  );
};

export default memo(AppBar);
