import { AsyncResponse, createActor } from "async-lifecycle-saga";
import { createEvents } from "async-lifecycle-saga/dist/models";

import { asyncPut, asyncUpload } from "../../../../api";
import { OrganizationModel } from "../../../../models";
import { StoreModel } from "../../models";
import {
  organizationPrivacyStatementDeleter,
  organizationPrivacyStatementUploadPoster,
} from "./api";
import { OrganizationPrivacyStatementUploadRequest } from "./models";

const postLogo = (file: File): Promise<AsyncResponse<{}>> => {
  const formData = new FormData();
  formData.append("file", file);
  return asyncUpload("/api/admin/logo", formData);
};

export const adminSettingsLogoCell = createActor<File, {}>({
  path: ["admin", "settings", "logo"],
  api: (file) => postLogo(file),
  invalidate: [createEvents(["ORGANIZATION"])],
});

const putSettings = (patch: OrganizationModel): Promise<AsyncResponse<{}>> =>
  asyncPut("/api/admin/organization", {
    ...patch,
    appStyles: JSON.stringify(patch.appStyles),
  });

export const adminSettingsPrivacyStatementDeleteCell = createActor<
  number,
  StoreModel
>({
  path: ["admin", "settings", "privacyStatementDelete"],
  api: (organizationId) => organizationPrivacyStatementDeleter(organizationId),
});

export const adminSettingsPrivacyStatementUploadCell = createActor<
  OrganizationPrivacyStatementUploadRequest,
  StoreModel
>({
  path: ["admin", "settings", "privacyStatementUpload"],
  api: (request: OrganizationPrivacyStatementUploadRequest) =>
    organizationPrivacyStatementUploadPoster(
      request.organizationId,
      request.formData
    ),
});

export const adminSettingsUpdateCell = createActor<OrganizationModel, {}>({
  path: ["admin", "settings", "update"],
  api: (settings) => putSettings(settings),
});
