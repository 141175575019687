import React from "react";

import { VariablesOverview } from "../../datacockpit/table";
import useStyles from "../admin/AdminPage.styles";

const TablePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <VariablesOverview isExport={false} />
    </div>
  );
};

export default TablePage;
