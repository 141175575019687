import { Avatar, Card, Grid, Typography } from "@material-ui/core";
import { Face } from "@material-ui/icons";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { StoreModel } from "../../business/redux/saga/models";
import { AccordionMenuExpandAllButton } from "../navigation";
import Loading from "../ux/Loading";
import useStyles from "./ProfileCard.styles";

const ProfileCard = () => {
  const classes = useStyles();

  const { profileLoading, profileValue } = useSelector(
    ({
      profile: {
        profile: {
          status: { loading },
          value,
        },
      },
    }: StoreModel) => ({ profileLoading: loading, profileValue: value })
  );
  const { avatarLoading, avatarValue } = useSelector(
    ({
      profile: {
        avatar: {
          status: { loading },
          value,
        },
      },
    }: StoreModel) => ({ avatarLoading: loading, avatarValue: value })
  );
  const avatarComponent = useMemo(() => {
    if (profileLoading || avatarLoading) {
      return <Loading />;
    }
    const emptyAvatar = (
      <Avatar alt={profileValue?.fullName} style={{ height: 64, width: 64 }}>
        <Face style={{ height: 64, width: 64 }} />
      </Avatar>
    );
    if (!profileValue) {
      return emptyAvatar;
    }
    return avatarValue && avatarValue.status === "success" ? (
      <Avatar
        src={`data:image/png;base64,${avatarValue.data}`}
        alt={profileValue.fullName}
        style={{ height: 64, width: 64 }}
      />
    ) : (
      emptyAvatar
    );
  }, [avatarLoading, avatarValue, profileLoading, profileValue]);

  return profileValue ? (
    <Card className={classes.root}>
      <Grid container spacing={5} className={classes.grid}>
        <Grid item xs={3} className={classes.gridAvatar}>
          {avatarComponent}
        </Grid>
        <Grid container direction="column" item xs={9}>
          <Grid item>
            <Typography className={classes.fullName}>
              {profileValue.fullName}
            </Typography>
          </Grid>
          <Grid container direction="row" item spacing={0}>
            <Grid item xs={10}>
              <Typography className={classes.role}>
                {profileValue.role}
              </Typography>
              <Typography className={classes.department}>
                {profileValue.department}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.expandAllButton}>
              <AccordionMenuExpandAllButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  ) : null;
};

export default memo(ProfileCard);
