import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
} from "@material-ui/core";
import { useSingle } from "async-lifecycle-hooks";
import { startOfDay } from "date-fns";
import { DateType } from "material-ui-pickers/constants/prop-types";
import React, { useCallback } from "react";

import { VariableDataFilter } from "../../../business/personal/employeeData";
import { datacockpitDataDefinitionsCell } from "../../../business/redux/saga/datacockpit/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import DateInput from "../../input/DateInput";
import { VariableSelect, VariablesExportButton } from "./";
import VariablesSelectPreset from "./VariablesSelectPreset";

interface Props {
  isExport: boolean;
  onChange: (value: VariableDataFilter) => void;
  value: VariableDataFilter;
}

const VariablesOverviewFilter = (props: Props) => {
  const { isExport, onChange, value } = props;
  const [t] = useTranslationStrict();

  const dataDefinitions = useSingle(datacockpitDataDefinitionsCell);

  const handleSelect = useCallback(
    (newValue: number[]) => {
      const newFilter = { ...value, variableIds: newValue };
      onChange(newFilter);
    },
    [onChange, value]
  );

  const handleDateChange = useCallback(
    (dateSelected: DateType) => {
      onChange({
        ...value,
        referenceDate: dateSelected
          ? new Date(dateSelected as string | number)
          : startOfDay(new Date()),
      });
    },
    [onChange, value]
  );

  const handleSelectPreset = useCallback(
    (variableIds: number[]) => {
      onChange({ ...value, variableIds });
    },
    [onChange, value]
  );

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item>
        <FormControl>
          <FormLabel>{t("Datacockpit:Variabelen")}</FormLabel>
          <VariableSelect
            value={value.variableIds}
            onChange={handleSelect}
            dataDefinitions={dataDefinitions.value ?? []}
            allowUnlimited={isExport}
          />
          <FormHelperText>
            {t("Datacockpit:Selecteer de variabelen die je wilt exporteren.")}
          </FormHelperText>
        </FormControl>
      </Grid>
      {isExport && (
        <Grid item>
          <FormControl>
            <FormLabel>{t("Datacockpit:Voorkeuze")}</FormLabel>
            <VariablesSelectPreset onChange={handleSelectPreset} />
            <FormHelperText>
              {t("Datacockpit:Selecteer een set van variabelen.")}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item>
        <DateInput
          label={t("Datacockpit:Peildatum")}
          onChange={handleDateChange}
          value={value.referenceDate ?? startOfDay(new Date())}
          helperText={t("Datacockpit:Selecteer een peildatum.")}
          required
        />
      </Grid>
      {isExport && (
        <>
          <Grid item style={{ flex: "1 1 0" }} />
          <Grid item style={{ height: "75px" }}>
            <FormControl>
              <VariablesExportButton filter={value} />
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default VariablesOverviewFilter;
