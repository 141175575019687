import React, { memo } from "react";

import LogoCard from "../../../components/admin/settings/LogoCard";
import SettingsCard from "../../../components/admin/settings/SettingsCard";
import PrivacyStatementCard from "../../admin/settings/PrivacyStatementCard";
import useStyles from "./AdminPage.styles";

const SettingsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <SettingsCard />
      <PrivacyStatementCard />
      <LogoCard />
    </div>
  );
};

export default memo(SettingsPage);
