import { formatEuro } from "../utils/NumberUtils";
import { DataDefinitionModel, EmployeeDataModel } from "./models";

const missing = "-";
const render = (
  def: DataDefinitionModel,
  datum: EmployeeDataModel,
  t: (v: string) => string
): string => {
  switch (def.dataType.dataField) {
    case "boolean":
      return datum.boolean ? t("MyData:ja") : t("MyData:nee");
    case "date":
      return datum.date ? datum.date.toDateString() : missing;
    case "numeric":
      // eslint-disable-next-line no-nested-ternary
      return datum.numeric === undefined
        ? missing
        : def.dataType.name === "Bedrag"
        ? formatEuro(datum.numeric)
        : datum.numeric.toString();
    case "text":
      return datum.text || missing;
    default:
      throw new Error(`Unknown dataField ${def.dataType.dataField}`);
  }
};

const handlebars = (
  text: string | undefined,
  data: EmployeeDataModel[],
  defs: DataDefinitionModel[],
  t: (v: string) => string
): string => {
  if (!text) {
    return "";
  }

  const replace = (match: string, code: string): string => {
    const def = defs.find((d): boolean => d.code === code);
    if (!def) {
      return match;
    }

    const datum = data.find((d): boolean => d.dataDefinitionId === def.id);
    if (!datum) {
      return missing;
    }

    return render(def, datum, t);
  };

  return text.replace(/{([^{}]*)}/g, replace);
};

export default handlebars;
