import { Details, Success } from "async-lifecycle-saga/dist/models";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { normalizeRoute } from "../../../../utils/routes";
import {
  NavigationNodeModel,
  NavigationRootModel,
  emptyMenu,
  emptyNavigation,
} from "../../../models";
import { StoreModel } from "../models";
import { navigationAddToHistoryCell, navigationGetHistoryCell } from "./cells";
import { NavigationHistoryEntry, NavigationHistoryResponse } from "./models";

export interface NavigationHook {
  currentNavigationNodeId: number | null;

  getHistory: (
    number?: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationHistoryEntry[]>) => void
  ) => void;

  addToHistory: (
    navigationNodeId: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationHistoryResponse>) => void
  ) => void;
}

const getNavigationNodeIdFromNode = (
  path: string,
  navigationNode: NavigationNodeModel
): number | null => {
  if (navigationNode.route === path) {
    return navigationNode.id || null;
  }
  if (navigationNode.children && navigationNode.children.length > 0) {
    for (let i = 0; i < navigationNode.children.length; i += 1) {
      const id = getNavigationNodeIdFromNode(path, navigationNode.children[i]);
      if (id !== null) {
        return id;
      }
    }
  }
  return null;
};

const getNavigationNodeId = (
  path: string,
  navigation: NavigationRootModel
): number | null => {
  for (let i = 0; i < navigation.items.length; i += 1) {
    const id = getNavigationNodeIdFromNode(
      normalizeRoute(path),
      navigation.items[i]
    );
    if (id !== null) {
      return id;
    }
  }
  return null;
};

const useNavigation = (): NavigationHook => {
  const dispatch = useDispatch();

  const path = window.location.pathname;
  const menu = useSelector(
    ({
      navigation: {
        menu: { value },
      },
    }: StoreModel): NavigationRootModel => value || emptyMenu
  );
  const navigation = useSelector(
    ({
      navigation: {
        tree: { value },
      },
    }: StoreModel): NavigationRootModel => value || emptyNavigation
  );
  let currentNavigationNodeId = getNavigationNodeId(path, menu);
  if (currentNavigationNodeId == null) {
    currentNavigationNodeId = getNavigationNodeId(path, navigation);
  }

  const getHistory = useCallback(
    (
      number?: number,
      failCallback?: (details: Details) => void,
      successCallback?: (success: Success<NavigationHistoryEntry[]>) => void
    ) => {
      dispatch(
        navigationGetHistoryCell.require(number, {
          onFail: failCallback,
          onSuccess: successCallback,
        })
      );
    },
    [dispatch]
  );

  const addToHistory = useCallback(
    (
      navigationNodeId: number,
      failCallback?: (details: Details) => void,
      successCallback?: (success: Success<NavigationHistoryResponse>) => void
    ) => {
      dispatch(
        navigationAddToHistoryCell.require(navigationNodeId, {
          onFail: failCallback,
          onSuccess: successCallback,
        })
      );
    },
    [dispatch]
  );

  return {
    currentNavigationNodeId,
    getHistory,
    addToHistory,
  };
};

export default useNavigation;
