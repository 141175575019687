import { createActor } from "async-lifecycle-saga";

import { asyncDelete, asyncPost, asyncPut } from "../../../../../api";
import {
  ContentNodeModel,
  DocumentContentNodeModel,
} from "../../../../../models";
import { adminContentNodeDocumentFormPoster } from "../api";
import { ContentNodeShiftRequest } from "../models";

export const adminNavigationContentAddCell = createActor<
  { node: ContentNodeModel; parentId: number },
  ContentNodeModel
>({
  path: ["admin", "navigation", "content", "add"],
  api: ({ node, parentId }) =>
    asyncPost(`/api/admin/navigation/content/${parentId}`, node),
});

export const adminNavigationContentDeleteCell = createActor<
  number,
  ContentNodeModel
>({
  path: ["admin", "navigation", "content", "delete"],
  api: (id) => asyncDelete(`/api/admin/navigation/content/${id}`),
});

export const adminNavigationContentShiftDownCell = createActor<
  ContentNodeShiftRequest,
  never
>({
  path: ["admin", "navigation", "content", "shiftDown"],
  api: (request) =>
    asyncPost("/api/admin/navigation/content/shiftDown", request),
});

export const adminNavigationContentShiftUpCell = createActor<
  ContentNodeShiftRequest,
  never
>({
  path: ["admin", "navigation", "content", "shiftUp"],
  api: (request) => asyncPost("/api/admin/navigation/content/shiftUp", request),
});

export const adminNavigationContentUpdateCell = createActor<
  ContentNodeModel,
  never
>({
  path: ["admin", "navigation", "content", "update"],
  api: (request) => asyncPut("/api/admin/navigation/content/update", request),
});

export const adminNavigationContentDocumentUploadCell = createActor<
  {
    file: File;
    contentNodeDocument: DocumentContentNodeModel;
    parentId: number;
  },
  ContentNodeModel
>({
  path: ["admin", "navigation", "content", "upload"],
  api: ({ parentId, contentNodeDocument, file }) =>
    adminContentNodeDocumentFormPoster(parentId, contentNodeDocument, file),
});
