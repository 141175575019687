import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";
import EmployerAddDialog from "./EmployerAddDialog";

const EmployerAddButton = () => {
  const [t] = useTranslationStrict();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleSubmitted = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>{t("Common:Add")}</Button>
      <EmployerAddDialog onSubmitted={handleSubmitted} open={dialogOpen} />
    </>
  );
};

export default EmployerAddButton;
