import { AsyncSingleValue } from "async-lifecycle-saga";
import { Id } from "async-lifecycle-saga/dist/models";
import { startOfDay } from "date-fns";

import { FileResponse } from "../../../api";
import {
  DataDefinitionGroup,
  DataDefinitionModel,
  MutationsOverviewDataRow,
} from "../../../models";
import {
  EmployeeDataModel,
  VariableDataRow,
} from "../../../personal/employeeData";
import { StoreModel } from "../models";

export interface EmployeeDataMergedModel extends EmployeeDataModel {
  dataDefinition: DataDefinitionModel | undefined;
}

export interface EmployeeDataByEmployeeId extends Id<number> {
  data: EmployeeDataModel[];
}

export interface EmployeeDataFilter {
  employeeId: number | null;
  group: DataDefinitionGroup;
  referenceDate: Date;
}

export const emptyEmployeeDataFilter: EmployeeDataFilter = {
  employeeId: null,
  group: DataDefinitionGroup.Geen,
  referenceDate: startOfDay(new Date()),
};

export interface EmployeeDataPatch {
  dataDefinitionId: number;
  employeeData: EmployeeDataMergedModel;
}

export interface EmployeeDataContext {
  employeeId: number | null;
  employeeData: EmployeeDataMergedModel[] | null;
  referenceDate: Date;
}

export const employeeDataContext = (store: StoreModel) => ({
  employeeId: store.datacockpit.employeeData.filter.employeeId,
  employeeData: store.datacockpit.employeeData.edit,
  referenceDate: store.datacockpit.employeeData.filter.referenceDate,
});

export interface EmployeeDataAuthorizationRequest {
  effectiveDate: Date;
}

export interface GenerateTokenRequest {
  employeeId: number;
}

export interface GenerateTokenResponse {
  refreshToken: string;
}

export interface DatacockpitStoreModel {
  dataDefinitions: AsyncSingleValue<DataDefinitionModel[]>;
  employeeData: {
    fetch: AsyncSingleValue<EmployeeDataModel[]>;
    merged: EmployeeDataMergedModel[];
    changed: boolean;
    filter: EmployeeDataFilter;
    edit: EmployeeDataMergedModel[] | null;
    patch: AsyncSingleValue<EmployeeDataMergedModel[]>;
  };
  mutations: {
    overview: AsyncSingleValue<MutationsOverviewDataRow[]>;
    reject: AsyncSingleValue<never>;
    approve: AsyncSingleValue<never>;
  };
  variableData: {
    exportAction: AsyncSingleValue<FileResponse>;
    table: AsyncSingleValue<VariableDataRow[]>;
  };
}
