import { Button } from "@material-ui/core";
import { Success } from "async-lifecycle-saga/dist/models";
import FileSaver from "file-saver";
import { CSSProperties } from "react";
import { useDispatch } from "react-redux";

import { FileResponse } from "../../../business/api";
import { calculateWlz } from "../../../business/loonheffing";
import { WlzAdviceCase } from "../../../business/personal/wlz/models";
import {
  personalWlzAdviceCaseReportDownloadCell,
  personalWlzAdviceCasesCell,
} from "../../../business/redux/saga/personal/wlz/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux";

interface Props {
  adviceCase: WlzAdviceCase;
  navigationNodeId: number;
  style: CSSProperties;
}

const WlzAdviceCaseReportDownloadButton = (props: Props) => {
  const { adviceCase, navigationNodeId, style } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

  const calculations: Record<string, number> = calculateWlz(
    adviceCase.yearCalculation.getFullYear(),
    adviceCase.subjects[0],
    adviceCase.subjects[1]
  );

  const handleClick = () => {
    dispatch(
      personalWlzAdviceCaseReportDownloadCell.require(
        { adviceCase: { ...adviceCase, calculations }, navigationNodeId },
        {
          onFail: showErrorMessageFromDetails(t("Wlz:Downloaden mislukt")),
          onSuccess: ({ body: { data, name } }: Success<FileResponse>) => {
            dispatch({ type: personalWlzAdviceCasesCell.events.invalidate });
            FileSaver.saveAs(data, name);
          },
        }
      )
    );
  };

  return (
    <Button
      color="secondary"
      onClick={handleClick}
      style={style}
      variant="contained"
      disabled={!adviceCase.id}
    >
      {t("Wlz:Downloaden")}
    </Button>
  );
};

export default WlzAdviceCaseReportDownloadButton;
