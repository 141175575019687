import { useDispatch } from "react-redux";

import { HookDispatchFunction } from "../models";
import {
  authenticationKnowledgeCell,
  authenticationPossessionCell,
  authenticationRefreshCell,
  authenticationSsoCell,
} from "./cells";
import {
  AuthenticationResponseModel,
  KnowledgeRequestModel,
  PossessionRequestModel,
  RefreshRequestModel,
  SsoToken,
} from "./models";

type Knowledge = HookDispatchFunction<
  KnowledgeRequestModel,
  AuthenticationResponseModel
>;
type Possession = HookDispatchFunction<
  PossessionRequestModel,
  AuthenticationResponseModel
>;
type Refresh = HookDispatchFunction<
  RefreshRequestModel,
  AuthenticationResponseModel
>;
type Sso = HookDispatchFunction<undefined, SsoToken>;
export interface AuthenticationHook {
  knowledge: Knowledge;
  possession: Possession;
  refresh: Refresh;
  sso: Sso;
}

export const useAuthentication = (): AuthenticationHook => {
  const dispatch = useDispatch();

  const knowledge: Knowledge = (request, callback) =>
    dispatch(authenticationKnowledgeCell.require(request, callback));
  const possession: Possession = (request, callback) =>
    dispatch(authenticationPossessionCell.require(request, callback));
  const refresh: Refresh = (request, callback) =>
    dispatch(authenticationRefreshCell.require(request, callback));
  const sso: Sso = (request, callback) =>
    dispatch(authenticationSsoCell.require(request, callback));

  return { knowledge, possession, refresh, sso };
};
