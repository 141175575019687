import { Card, CardContent, Grid } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChoiceBenefitsModel } from "../../../business/models";
import { adminChoicePeriodsCell } from "../../../business/redux/saga/admin/choices/cells";
import { employerGetCell } from "../../../business/redux/saga/employer";
import { StoreModel } from "../../../business/redux/saga/models";
import { organizationCell } from "../../../business/redux/saga/organization";
import Loading from "../../ux/Loading";
import ChoiceBenefitsTable from "./ChoiceBenefitsTable";
import ChoicePeriodSelectCard from "./ChoicePeriodSelectCard";

const makeZeroBenefits = (name: string): ChoiceBenefitsModel => ({
  choicePeriodId: 0,
  details: [],
  total: {
    approved: true,
    employeeBenefit: 0,
    employerBenefit: 0,
    name,
  },
});

const ChoiceBenefits = () => {
  const [choicePeriodId, setChoicePeriodId] = useState<number | undefined>();

  const organization = useSelector(organizationCell.select).value;
  const employer = useSelector(employerGetCell.select).value;
  const zeroBenefits = useMemo(
    (): ChoiceBenefitsModel => makeZeroBenefits(organization?.name || ""),
    [organization?.name]
  );

  const periods = useSelector(
    ({ admin }: StoreModel) => admin.choice.periods.value
  );
  const {
    value: benefits,
    status: { loading },
  } = useSelector(({ admin }: StoreModel) => admin.choice.benefits);

  const dispatch = useDispatch();
  useEffect(
    /**
     * Fetch Choice Periods.
     */
    () => {
      dispatch(adminChoicePeriodsCell.require());
      dispatch(employerGetCell.require());
    },
    [dispatch]
  );

  const benefitSharing =
    (employer?.benefitShared || 0) > 0 ||
    (employer?.benefitSharedDispensated || 0) > 0 ||
    (employer?.benefitSharedTaxExempt || 0) > 0;

  const showChoicePeriod = !choicePeriodId;

  return (
    <section key="ChoiceBenefits">
      {periods && (
        <Grid container direction="row" spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <ChoicePeriodSelectCard
              onChange={setChoicePeriodId}
              value={choicePeriodId}
              exportEnabled={
                benefits !== undefined && benefits.details.length > 0
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid container direction="row" spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {periods === undefined || loading ? (
                <Loading />
              ) : (
                <ChoiceBenefitsTable
                  benefits={benefits ?? zeroBenefits}
                  benefitSharing={benefitSharing}
                  showChoicePeriod={showChoicePeriod}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ChoiceBenefits;
