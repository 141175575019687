import { TextField } from "@material-ui/core";
import React, { memo } from "react";

interface Props {
  name?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const ColorPicker = memo((props: Props) => (
  <span style={{ display: "inline-block", whiteSpace: "nowrap" }}>
    <TextField {...props} />
    <span
      style={{
        display: "inline-block",
        width: "1em",
        height: 48,
        backgroundColor: props.value,
      }}
    >
      &#160;
    </span>
  </span>
));

export default ColorPicker;
