import { Button } from "@material-ui/core";
import classNames from "classnames";
import React, { memo } from "react";
import { Link } from "react-router-dom";

import usePageWrap from "../../styling/PageWrap";
import ProfileInfo, { ProfileInfoData } from "../security/ProfileInfo";
import useStyles from "./Ribbon.styles";

export interface RibbonData {
  logoImageUrl: string;
  logoTitle: string;
  applicationName: string;
  profileInfo: ProfileInfoData;
}

const Ribbon = (props: RibbonData) => {
  const ribbonClasses = useStyles();
  const pageWrapClasses = usePageWrap();
  const { applicationName, profileInfo, logoImageUrl, logoTitle } = props;

  return (
    <div className={classNames(ribbonClasses.root, pageWrapClasses.outer)}>
      <div className={pageWrapClasses.inner}>
        <div className={ribbonClasses.application}>
          <Link to="/">
            <img
              src={logoImageUrl}
              alt={logoTitle}
              title={logoTitle}
              className={classNames(ribbonClasses.logo)}
            />
          </Link>
          <div className={ribbonClasses.applicationName}>
            <Button className={ribbonClasses.button} disabled>
              {applicationName}
            </Button>
          </div>
        </div>
        <div className={ribbonClasses.profileInfo}>
          <ProfileInfo {...profileInfo} />
        </div>
      </div>
    </div>
  );
};

export default memo(Ribbon);
