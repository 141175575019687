import { Card, Typography } from "@material-ui/core";

import { useTranslationStrict } from "../../globalization/i18n";
import useStyles from "./ContentPage.styles";

const NotFoundPage = () => {
  const [t] = useTranslationStrict();
  const classes = useStyles();

  return (
    <div className={classes.gridWideContent}>
      <Card style={{ padding: 24 }}>
        <Typography variant="caption">
          {t("Navigation:Pagina is helaas niet voor jou beschikbaar")}
        </Typography>
      </Card>
    </div>
  );
};

export default NotFoundPage;
