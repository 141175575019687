import { Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  emailSentMessage: {
    backgroundColor: green[100],
    margin: theme.spacing() * 2,
    padding: theme.spacing(),
  },
}));

export default useStyles;
