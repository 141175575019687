import {
  Card,
  CardContent,
  CircularProgress,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";

import { TwoFactorAuthenticationPolicy } from "../../../business/authentication/models";
import {
  DataDefinitionModel,
  EmployeeDataModel,
  emptyOrganization,
} from "../../../business/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { organizationCell } from "../../../business/redux/saga/organization";
import {
  personalDataCell,
  personalDefinitionsCell,
} from "../../../business/redux/saga/personal/cells";
import { PersonalStoreModel } from "../../../business/redux/saga/personal/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import AuthenticationTwoFactorSettings from "../../authentication/twoFactor/AuthenticationTwoFactorSettings";
import MyData from "../../personal/MyData";
import AvatarUploader from "../../profile/AvatarUploader";
import TabContainer from "../../ux/TabContainer";
import useContentPageStyles from "../ContentPage.styles";
import useMyDataPageStyles from "./MyDataPage.styles";

const personalKeys: string[] = [
  "WN_ROEPNAAM",
  "WN_TUSSENVOEGSELS",
  "WN_ACHTERNAAM",
  "WN_VOORLETTERS",
  "WN_GESLACHT",
  "WN_GEBOORTEDATUM",
  "WN_BURGERLIJKE_STATUS",
  "WN_STRAAT",
  "WN_STRAAT_POSTCODE_NL",
  "WN_WOONPLAATS",
  "WN_WOONLAND_ISO",
  "WN_TELEFOONNUMMER_VAST_PRIVE",
  "WN_TELEFOONNUMMER_MOBIEL_PRIVE",
  "WN_EMAIL_PRIVE",
];

const companyKeys: string[] = [
  "WERKGEVERSNAAM",
  "WERKGEVERSONDERDEEL",
  "WG_VESTIGINGSADRES",
  "WG_POSTCODE_VESTIGINGSADRES",
  "WG_VESTIGINGSPLAATS",
  "FUNCTIE",
  "AFDELING",
  "PERSONEELSNUMMER",
  "DATUM_IN_DIENST",
  "DATUM_UIT_DIENST",
  "PARTTIMEPERCENTAGE",
  "WN_TELEFOONNUMMER_VAST_ZAKELIJK",
  "WN_EMAIL_ZAKELIJK",
];

const MyDataPage = () => {
  const theme = useTheme<Theme>();
  const contentPageClasses = useContentPageStyles();
  const myDataPageClasses = useMyDataPageStyles();
  const [t] = useTranslationStrict();

  /* Tabs */
  const [tab, setTab] = useState<number>(0);
  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number): void => {
      setTab(newValue);
    },
    []
  );
  const handleSwipeChange = useCallback((newIndex: number): void => {
    setTab(newIndex);
  }, []);

  const twoFactorAuthenticationPolicy = (
    useSelector(organizationCell.select).value ?? emptyOrganization
  ).twoFactorAuthenticationPolicy;

  const dispatch = useDispatch();
  const {
    definitions: { value: definitions },
    data: { value: data },
  } = useSelector(({ personal }: StoreModel): PersonalStoreModel => personal);
  useEffect((): void => {
    if (data) {
      return;
    }
    dispatch(personalDataCell.require());
  }, [dispatch, data]);
  useEffect((): void => {
    if (definitions) {
      return;
    }
    dispatch(personalDefinitionsCell.require());
  }, [dispatch, definitions]);

  const ready = Boolean(data && definitions);

  return (
    <div className={contentPageClasses.gridWideContent}>
      <Tabs
        classes={{ root: myDataPageClasses.root }}
        indicatorColor="primary"
        onChange={handleTabChange}
        textColor="primary"
        value={tab}
        variant="scrollable"
      >
        <Tab label={t("MyData:PersonalProfile")} />
        <Tab label={t("MyData:CompanyProfile")} />
        <Tab color="secondary" label={t("MyData:Account")} />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tab}
        onChangeIndex={handleSwipeChange}
      >
        <TabContainer dir={theme.direction}>
          <Card style={{ padding: 24 }}>
            <CardContent>
              {!ready && <CircularProgress />}
              {ready && (
                <MyData
                  keys={personalKeys}
                  definitions={definitions as DataDefinitionModel[]}
                  data={data as EmployeeDataModel[]}
                />
              )}
            </CardContent>
          </Card>
        </TabContainer>
        <TabContainer>
          <Card style={{ padding: 24 }}>
            <CardContent>
              {!ready && <CircularProgress />}
              {ready && (
                <MyData
                  keys={companyKeys}
                  definitions={definitions as DataDefinitionModel[]}
                  data={data as EmployeeDataModel[]}
                />
              )}
            </CardContent>
          </Card>
        </TabContainer>
        <TabContainer dir={theme.direction}>
          {twoFactorAuthenticationPolicy !==
            TwoFactorAuthenticationPolicy.Disabled &&
            twoFactorAuthenticationPolicy !==
              TwoFactorAuthenticationPolicy.Enforced && (
              <AuthenticationTwoFactorSettings />
            )}
          <AvatarUploader />
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

export default memo(MyDataPage);
