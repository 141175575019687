import { Grid, Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ChoiceData,
  ChoiceTotal,
  WkrLimit,
  aggregateChoices,
  getChoiceValue,
  toChoiceData,
  toChoiceValueModel,
  toCurrentValue,
} from "../../../business/alacarte";
import {
  ChoiceInterval,
  ChoicePeriod,
  ChoiceSetModel,
  ModuleOption,
  NavigationNodeModel,
  emptyOrganization,
} from "../../../business/models";
import { getDataDefinition } from "../../../business/redux/saga/admin/choices/models";
import { employerGetCell } from "../../../business/redux/saga/employer";
import { StoreModel } from "../../../business/redux/saga/models";
import { personalDefinitionsCell } from "../../../business/redux/saga/personal/cells";
import {
  personalChoicesEntityCell,
  personalChoicesUploadCell,
  personalChoicesUpsertCell,
} from "../../../business/redux/saga/personal/choices/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { formatEuro } from "../../../utils/NumberUtils";
import Choice from "../../alacarte/choice";
import ConfirmDialog from "../../alacarte/confirmDialog";
import Summary from "../../alacarte/summary";

const emptyChoiceTotal: ChoiceTotal = {
  differenceAlc: 0,
  summaryTotal: { goals: 0, mixedPeriods: false, sources: 0, wkrGoals: 0 },
  choiceValues: [],
  totalGoalValue: 0,
  totalSourceValue: 0,
};

const ALaCartePage = ({
  node: { id: navigationNodeId = 0, alacarteInfo },
}: {
  node: NavigationNodeModel;
}) => {
  const [busy, setBusy] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [choices, setChoices] = useState<ChoiceData[]>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(employerGetCell.require());
    dispatch(personalChoicesEntityCell.require());
    dispatch(personalDefinitionsCell.require());
  }, [dispatch]);

  const {
    choices: {
      entity: { value: storedChoices },
    },
    definitions: { value: definitions },
    employer,
    organization = emptyOrganization,
  } = useSelector((store: StoreModel) => ({
    organization: store.organization.get.value,
    choices: store.personal.choices,
    definitions: store.personal.definitions,
    employer: store.employer.get.value,
  }));
  const endDate = useMemo(
    () =>
      organization.forcedFiscalYear
        ? new Date(organization.forcedFiscalYear, 11, 31) // last day of forced year
        : storedChoices?.endDate ?? new Date(),
    [organization.forcedFiscalYear, storedChoices?.endDate]
  );
  useEffect(() => {
    const choices = storedChoices?.choices ?? [];
    const salaryInterval = choices.some(
      (choice) => choice.definition.interval === ChoiceInterval.fourWeekly
    )
      ? ChoiceInterval.fourWeekly
      : ChoiceInterval.monthly;
    setChoices(
      choices.map(
        (choice): ChoiceData =>
          toChoiceData(
            choice,
            storedChoices?.amountPerHour || 0,
            getDataDefinition(
              definitions || [],
              choice.definition.dataDefinitionId
            ),
            storedChoices?.uploads || [],
            endDate,
            salaryInterval
          )
      )
    );
  }, [organization.forcedFiscalYear, definitions, endDate, storedChoices]);

  const { differenceAlc, summaryTotal, optimal, salaryEffect, choiceValues } =
    useMemo(
      (): ChoiceTotal =>
        aggregateChoices(
          organization,
          employer!,
          storedChoices || ({} as ChoiceSetModel),
          choices,
          endDate
        ) ?? emptyChoiceTotal,
      [organization, employer, storedChoices, choices, endDate]
    );

  const monthlyHoursInEuros = useMemo(() => {
    return choices
      .filter(
        (x) =>
          x.currentValue > 0 &&
          x.dataDefinition.unitAfter === "uur" &&
          x.definition.interval !== ChoiceInterval.annually
      )
      .reduce((acc, cur) => (acc += cur.currentValue), 0);
  }, [choices]);

  const annualHoursInEuros = useMemo(() => {
    return choices
      .filter(
        (x) =>
          x.currentValue > 0 &&
          x.dataDefinition.unitAfter === "uur" &&
          x.definition.interval === ChoiceInterval.annually
      )
      .reduce((acc, cur) => (acc += cur.currentValue), 0);
  }, [choices]);

  const getAutoValue = (cs: ChoiceData[], choice: ChoiceData): ChoiceData => {
    const getDiff = (c: ChoiceData): number => {
      const { differenceAlc: diff } =
        aggregateChoices(
          organization,
          employer!,
          storedChoices || ({} as ChoiceSetModel),
          [
            c,
            ...cs.filter(
              (cc) =>
                c.definition.dataDefinitionId !== cc.definition.dataDefinitionId
            ),
          ],
          endDate
        ) ?? emptyChoiceTotal;
      return diff;
    };

    let currentValue = choice.currentValue;
    for (let i = 0; i < 24; i += 1) {
      const diff = getDiff({ ...choice, currentValue });
      if (Math.abs(diff) < 0.005) {
        break;
      }

      currentValue = toCurrentValue(
        choice as ChoicePeriod,
        choice.definition,
        choice.dataDefinition,
        Math.max(
          0,
          choice.dataDefinition.choiceRole === "source"
            ? getChoiceValue(choice, currentValue, endDate) - diff
            : getChoiceValue(choice, currentValue, endDate) + diff
        ),
        endDate
      );
    }

    if (currentValue === choice.currentValue) {
      return choice;
    }

    if (currentValue < 0) {
      return {
        ...choice,
        currentValue: 0,
        stringValue: undefined,
      };
    }

    if (currentValue > choice.maxValue) {
      return {
        ...choice,
        currentValue: choice.maxValue,
        stringValue: undefined,
      };
    }

    return {
      ...choice,
      currentValue,
      stringValue: undefined,
    };
  };

  const updateChoice = (choice: ChoiceData): void => {
    let next = choices.map(
      (old): ChoiceData => (old.id === choice.id ? choice : old)
    );
    if (choice.dataDefinition.choiceRole !== "destination") {
      setChoices(next);
      return;
    }

    const alc: ChoiceData[] = [];
    const wkrGift: ChoiceData[] = [];
    const wkr: ChoiceData[] = [];
    choices
      .filter((c) => c.dataDefinition.choiceRole === "source")
      .forEach((c) => {
        switch (c.dataDefinition.choiceCategory) {
          case "none":
            alc.push(c);
            break;
          case "wkr":
            wkr.push(c);
            break;
          case "wkrGift":
            wkrGift.push(c);
            break;
          default:
            break;
        }
      });
    [alc, wkrGift, wkr].forEach((category): void => {
      if (category.length === 1) {
        const auto = getAutoValue(next, category[0]);
        next = next.map((old): ChoiceData => (old.id === auto.id ? auto : old));
      }
    });

    setChoices(next);
  };

  const setAutoValue = (choice: ChoiceData): void => {
    updateChoice(getAutoValue(choices, choice));
  };

  const generateChoiceElements = (
    filteredChoices: ChoiceData[]
  ): JSX.Element[] => {
    return filteredChoices.map(
      (i): JSX.Element => (
        <Grid item key={i.id}>
          <Choice
            updateChoice={updateChoice}
            setAutoValue={setAutoValue}
            choice={i}
            disableDirectly={Boolean(organization.forcedFiscalYear)}
          />
        </Grid>
      )
    );
  };

  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = useCallback(() => {
    setConfirmDialogOpen(false);
    setBusy(true);

    const promises: Promise<void>[] = [];

    for (let ci = 0; ci < choices.length; ci += 1) {
      const choice = choices[ci];
      for (let ui = 0; ui < choice.uploads.length; ui += 1) {
        const upload = choice.uploads[ui];
        if (upload.file) {
          promises.push(
            new Promise((resolve, reject) => {
              dispatch(
                personalChoicesUploadCell.require(
                  { ...upload, file: upload.file! },
                  {
                    onFail: () => reject(),
                    onSuccess: () => resolve(),
                  }
                )
              );
            })
          );
        }
      }
    }

    Promise.all(promises).then(
      () => {
        dispatch(
          personalChoicesUpsertCell.require(
            {
              choices: choiceValues,
              optimal,
              navigationNodeId,
              submitRequest: true,
            },
            {
              onFail: () => {
                enqueueSnackbar(t("Alacarte:SendChoicesFailed"), {
                  variant: "error",
                });
                setBusy(false);
              },
              onSuccess: () => {
                enqueueSnackbar(t("Alacarte:SendChoicesSuccess"), {
                  variant: "success",
                });
                setBusy(false);
              },
            }
          )
        );
      },
      () => {
        enqueueSnackbar(t("Alacarte:SendUploadFailed"), {
          variant: "error",
        });
        setBusy(false);
      }
    );
  }, [
    choices,
    choiceValues,
    dispatch,
    enqueueSnackbar,
    navigationNodeId,
    optimal,
    t,
  ]);

  const handleCancel = useCallback(() => {
    setConfirmDialogOpen(false);
    dispatch(
      personalChoicesUpsertCell.require(
        {
          choices: choices.map(toChoiceValueModel),
          navigationNodeId,
          submitRequest: false,
        },
        {
          onSuccess: () =>
            enqueueSnackbar(t("Alacarte:RevertChoicesSuccess"), {
              variant: "success",
            }),
        }
      )
    );
  }, [choices, dispatch, enqueueSnackbar, navigationNodeId, t]);

  return (
    <Grid container spacing={10} style={{ gridColumn: "span 2" }}>
      {/* The page */}
      {storedChoices && storedChoices.wkrAvailable >= 1 && (
        <Typography
          variant="subtitle1"
          style={{ marginLeft: 42, marginTop: 8, color: "white" }}
        >
          {t("Alacarte:WkrAvailable")} {formatEuro(storedChoices.wkrAvailable)}
          {storedChoices.wkrBudget >= 1 && (
            <>
              {" "}
              {t("Alacarte:OfWhich")} {formatEuro(storedChoices.wkrBudget)}{" "}
              {t("Alacarte:WkrBudget")}
            </>
          )}
          .
        </Typography>
      )}

      <Grid
        item
        container
        spacing={5}
        alignItems="flex-start"
        style={{ marginTop: -40 }}
      >
        {/* Wrapper for the two columns */}

        <Grid item container spacing={2} xs={6}>
          {/* Left column (goals) */}

          <Grid item>
            {/* Header for the left side */}
            <Typography variant="h5" style={{ color: "white" }}>
              {t("Alacarte:Purposes")}
            </Typography>
          </Grid>

          {/* Choices */}
          {generateChoiceElements(
            choices.filter(
              (i): boolean => i.dataDefinition.choiceRole === "destination"
            )
          )}
        </Grid>

        <Grid item container spacing={2} xs={6}>
          {/* Right column (sources) */}

          <Grid item>
            {/* Header for the right side */}
            <Typography variant="h5" style={{ color: "white" }}>
              {t("Alacarte:Sources")}
            </Typography>
          </Grid>

          {/* Choices */}
          {generateChoiceElements(
            choices.filter(
              (i): boolean => i.dataDefinition.choiceRole === "source"
            )
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs
        style={{ position: "sticky", bottom: 0, paddingBottom: 16 }}
      >
        {/* Summary at the bottom, along with the confirm dialog */}
        <Summary
          nextStep={() => {
            if (!busy) {
              setConfirmDialogOpen(true);
            }
          }}
          onCancel={handleCancel}
          total={summaryTotal}
          differenceAlc={differenceAlc}
          optimal={optimal}
          salaryEffect={salaryEffect}
          choiceStatus={storedChoices?.choiceStatus}
          wkrLimit={storedChoices as WkrLimit}
          benefitCalculation={
            employer?.benefitCalculation || ModuleOption.default
          }
          alacarteInfo={alacarteInfo}
          annualHoursInEuros={annualHoursInEuros}
          monthlyHoursInEuros={monthlyHoursInEuros}
        />

        <ConfirmDialog
          updateChoice={updateChoice}
          closeDialog={(): void => setConfirmDialogOpen(false)}
          dialogOpen={confirmDialogOpen}
          confirmChoices={
            choices.some(
              (c): boolean =>
                c.dataDefinition.choiceRole === "destination" &&
                c.currentValue > 0 &&
                ((c.repeating && c.startMonth === undefined) ||
                  (c.definition.upload && c.uploads.length === 0))
            )
              ? undefined
              : handleSubmit
          }
          goals={choices.filter(
            (c): boolean =>
              c.dataDefinition.choiceRole === "destination" &&
              c.currentValue > 0
          )}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ALaCartePage);
