import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { typographyBold } from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    gridWideContent: {
      gridArea: "auto / 2 / span 1 / span 2",
    },
    menuIcon: { color: theme.palette.common.black },
    listItem: {
      display: "inline-grid",
      gridTemplateColumns: "40px auto 175px",
    },
    listItemIcon: {
      color: theme.palette.common.black,
      gridColumn: 1,
    },
    listItemText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      gridColumn: 2,
    },
    listItemSecondaryAction: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      gridColumn: 3,
    },
    markdown: {
      "& img": {
        maxWidth: "100%",
      },
    },
    menuText: {
      ...typographyBold(),
      fontSize: "1rem !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    danger: {
      color: theme.palette.error.main,
    },
    left: {
      float: "left",
    },
    right: {
      float: "right",
    },
    downloadList: {
      clear: "both",
    },
    propertiesHeading: {
      marginTop: 16,

      "& > h6": {
        fontWeight: 500,
      },
    },
  })
);

export default useStyles;
