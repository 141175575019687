import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSingle } from "async-lifecycle-hooks";
import React, { useCallback } from "react";

import {
  Preset,
  presetValues,
} from "../../../business/datacockpit/presetValues";
import { datacockpitDataDefinitionsCell } from "../../../business/redux/saga/datacockpit/cells";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  onChange(variableIds: number[]): void;
}

const useStyles = makeStyles({
  select: {
    width: 225,
  },
});

const VariablesSelectPreset = (props: Props) => {
  const [t] = useTranslationStrict();
  const { onChange } = props;
  const classes = useStyles();
  const dataDefinitions = useSingle(datacockpitDataDefinitionsCell);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const selected = presetValues[value as Preset];
      const ddList = dataDefinitions.value ?? [];

      switch (selected) {
        case "All":
          onChange(ddList.map((d) => d.id));
          break;
        case "None":
          onChange([]);
          break;
        default:
          onChange(
            ddList.filter((dd) => selected.includes(dd.code)).map((dd) => dd.id)
          );
          break;
      }
    },
    [dataDefinitions, onChange]
  );

  return (
    <Select
      className={classes.select}
      value="ChooseSet"
      onChange={handleChange}
    >
      <MenuItem value="ChooseSet">{t(`Datacockpit:ChooseSet`)}</MenuItem>
      {Object.keys(presetValues).map((key) => {
        const value = key as Preset;
        return (
          <MenuItem key={key} value={value}>
            {t(`Datacockpit:${value}`)}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default VariablesSelectPreset;
