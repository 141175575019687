import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@material-ui/core";
import querystring from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import MicrosoftEntraIdLogo from "../../../assets/Microsoft_Entra_ID_color_icon.svg";
import { Microsoft365AuthenticationStatus } from "../../../business/authentication/models";
import { logOutReasonSessionStorageKey } from "../../../business/models";
import { appCell } from "../../../business/redux/saga/app/cells";
import { authenticationCell } from "../../../business/redux/saga/authentication/cells";
import { microsoft365TokensCell } from "../../../business/redux/saga/authentication/microsoft365/cells";
import { organizationPrivacyStatementExistsCell } from "../../../business/redux/saga/organization";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../globalization/i18n";
import { getRefreshToken } from "../../../utils/SecurityUtils";
import PrivacyStatementButton from "../../organization/PrivacyStatementButton";
import Loading from "../../ux/Loading";
import {
  Microsoft365DoesNotMatchMijnHRPlaza,
  Microsoft365Failed,
  Microsoft365LoginButton,
  Microsoft365NotConfigured,
} from "./";

const getTitle = (
  t: TFunctionStrict,
  status: Microsoft365AuthenticationStatus
) => {
  switch (status) {
    case "Welcome":
      return t("Authentication:Welkom");
    case "NotConfigured":
      return t("Authentication:Microsoft Entra ID niet geconfigureerd");
    case "Waiting":
      return t("Authentication:Inloggen...");
    case "MijnHRPlazaMismatch":
      return t("Authentication:Gebruiker niet gevonden");
    case "Failed":
      return t("Authentication:Inloggen mislukt");
    case "Success":
      return t("Authentication:Gelukt");
  }
};

const Microsoft365Card = () => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = querystring.parse(window.location.search);
  const [status, setStatus] =
    useState<Microsoft365AuthenticationStatus>("Welcome");
  const [
    organizationPrivacyStatementExists,
    setOrganizationPrivacyStatementExists,
  ] = useState(false);

  const title = getTitle(t, status);
  const refreshToken = getRefreshToken();

  useEffect(() => {
    dispatch(
      organizationPrivacyStatementExistsCell.require({
        onFail: (details) => {
          setOrganizationPrivacyStatementExists(details.status === 302);
        },
      })
    );

    if (typeof query.code !== "string" || refreshToken) {
      return;
    }

    setStatus("Waiting");
    dispatch(appCell.clear());
    dispatch(
      microsoft365TokensCell.require(query.code, {
        onFail: ({ status }) => {
          switch (status) {
            case 401:
              setStatus("Failed");
              break;
            case 403:
              setStatus("MijnHRPlazaMismatch");
              break;
            case 500:
            default:
              setStatus("Failed");
              break;
          }
        },
        onSuccess: (response) => {
          dispatch(authenticationCell.authenticated(response));
          history.push("/");
        },
      })
    );
    return () => {
      sessionStorage.removeItem(logOutReasonSessionStorageKey);
    };
  }, [dispatch, history, query.code, refreshToken]);

  if (refreshToken) {
    // If already logged in, reset location to home page.
    window.location.href = "/";
    return <></>;
  }

  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        width="140"
        image={MicrosoftEntraIdLogo}
        alt="Microsoft Entra ID"
        style={{ objectFit: "contain", padding: "5px 5px 5px 5px" }}
      />
      <CardHeader title={title} />
      <CardContent>
        {status === "Welcome" && <Microsoft365LoginButton />}
        {status === "NotConfigured" && <Microsoft365NotConfigured />}
        {status === "Waiting" && <Loading />}
        {status === "MijnHRPlazaMismatch" && (
          <Microsoft365DoesNotMatchMijnHRPlaza />
        )}
        {status === "Failed" && <Microsoft365Failed />}
      </CardContent>
      <CardActions>
        {organizationPrivacyStatementExists && <PrivacyStatementButton />}
      </CardActions>
    </Card>
  );
};

export default Microsoft365Card;
