import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import sortBy from "lodash/fp/sortBy";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChoiceInterval, DataDefinitionModel } from "../../../business/models";
import {
  adminChoiceDefinitionUpshiftCell,
  adminChoiceDefinitionsDownshiftCell,
  adminChoiceDefinitionsListCell,
  adminChoiceDefinitionsUpsertCell,
  adminChoiceSettingsEntityCell,
} from "../../../business/redux/saga/admin/choices/cells";
import {
  ChoiceDefinitionModel,
  DataChoiceDefinition,
  getDataDefinition,
  joinChoiceDataDefinitions,
} from "../../../business/redux/saga/admin/choices/models";
import { AdminStoreModel } from "../../../business/redux/saga/admin/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { personalDefinitionsCell } from "../../../business/redux/saga/personal/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import ChoiceEdit from "../../alacarte/choiceEdit";
import ChoiceSettings from "../../alacarte/choiceSettings";
import useStyles from "./ALaCarteAdminPage.styles";

const ALaCartePage = () => {
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const {
    choice: {
      definitions: {
        list: { value: choiceDefinitions = [] },
      },
      settings: { value: choiceSettings },
    },
  } = useSelector(({ admin }: StoreModel): AdminStoreModel => admin);
  useEffect((): void => {
    dispatch(adminChoiceDefinitionsListCell.require());
    dispatch(adminChoiceSettingsEntityCell.require());
    dispatch(personalDefinitionsCell.require());
  }, [dispatch]);
  const dataDefinitions = useSelector(
    (store: StoreModel): DataDefinitionModel[] =>
      store.personal.definitions.value || []
  );
  const dataChoiceDefinitions = useMemo(
    (): DataChoiceDefinition[] =>
      joinChoiceDataDefinitions(choiceDefinitions, dataDefinitions).filter(
        ({ enabled }): boolean => enabled
      ),
    [choiceDefinitions, dataDefinitions]
  );
  const sourceDefinitions = useMemo(
    (): DataDefinitionModel[] =>
      flow(
        filter(
          (ddm: DataDefinitionModel): boolean => ddm.choiceRole === "source"
        ),
        sortBy(({ code }: DataDefinitionModel): string =>
          t(`Variables:${code}`)
        )
      )(dataDefinitions) as DataDefinitionModel[],
    [dataDefinitions, t]
  );
  const destinationDefinitions = useMemo(
    (): DataDefinitionModel[] =>
      flow(
        filter(
          (ddm: DataDefinitionModel): boolean =>
            ddm.choiceRole === "destination"
        ),
        sortBy(({ code }: DataDefinitionModel): string =>
          t(`Variables:${code}`)
        )
      )(dataDefinitions) as DataDefinitionModel[],
    [dataDefinitions, t]
  );

  const handleSwitch = useCallback(
    (checked: boolean, openTo: Date): void => {
      const settings = {
        ...choiceSettings,
        openFrom: checked ? new Date() : undefined,
        openTo,
      };
      dispatch(adminChoiceSettingsEntityCell.update(settings));
    },
    [choiceSettings, dispatch]
  );

  const handleSave = useCallback(
    (choice: ChoiceDefinitionModel): void => {
      dispatch(adminChoiceDefinitionsUpsertCell.require([choice]));
    },
    [dispatch]
  );

  const [destinationCode, setDestinationCode] = useState(0);
  const handleDestinationCode = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) =>
      setDestinationCode(Number(event.target.value)),
    [setDestinationCode]
  );

  const [sourceCode, setSourceCode] = useState(0);
  const handleSourceCode = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) =>
      setSourceCode(Number(event.target.value)),
    [setSourceCode]
  );

  const newChoice = useCallback(
    (dataDefinitionId: number): DataChoiceDefinition => {
      const dataDefinition = getDataDefinition(
        dataDefinitions,
        dataDefinitionId
      );
      return {
        dataDefinitionId,
        dataDefinition,
        enabled: true,
        upload: false,
        label: t(`Variables:${dataDefinition.code}`),
        interval: ChoiceInterval.annually,
        help: "",
        position: choiceDefinitions.length,
        earningCode: "",
        userInput: false,
      };
    },
    [choiceDefinitions.length, dataDefinitions, t]
  );

  const handleAddPurpose = useCallback(
    (dataDefinitionId: number) => {
      handleSave(newChoice(dataDefinitionId));
    },
    [handleSave, newChoice]
  );

  const handleAddSource = useCallback(
    (dataDefinitionId: number) => {
      handleSave(newChoice(dataDefinitionId));
    },
    [handleSave, newChoice]
  );

  const handleShiftDown = useCallback(
    (definition: ChoiceDefinitionModel) => {
      dispatch({
        type: adminChoiceDefinitionsDownshiftCell.events.require,
        payload: definition,
        onSuccess: () => {
          dispatch(adminChoiceDefinitionsListCell.require());
        },
      });
    },
    [dispatch]
  );

  const handleShiftUp = useCallback(
    (definition: ChoiceDefinitionModel) => {
      dispatch({
        type: adminChoiceDefinitionUpshiftCell.events.require,
        payload: definition,
        onSuccess: () => {
          dispatch(adminChoiceDefinitionsListCell.require());
        },
      });
    },
    [dispatch]
  );

  const generateChoiceElements = (
    choices: DataChoiceDefinition[]
  ): JSX.Element[] => {
    return choices.map(
      (c, i): JSX.Element => (
        <Grid item key={c.dataDefinitionId}>
          <ChoiceEdit
            choice={c}
            removeChoice={(): void => handleSave({ ...c, enabled: false })}
            shiftDown={handleShiftDown}
            shiftDownEnabled={i < choices.length - 1}
            shiftUp={handleShiftUp}
            shiftUpEnabled={i > 0}
            updateChoice={handleSave}
            userInputAllowed={c.userInput}
          />
        </Grid>
      )
    );
  };

  return (
    <>
      <Grid container spacing={10} style={{ gridColumn: "span 2" }}>
        <Grid item container spacing={8} alignItems="flex-start">
          <Grid item container spacing={4} md={6}>
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                {t("Alacarte:Purposes")}
              </Typography>
            </Grid>

            {generateChoiceElements(
              dataChoiceDefinitions
                .filter(
                  ({ dataDefinition: { choiceRole } }): boolean =>
                    choiceRole === "destination"
                )
                .sort((cd1, cd2) => cd1.position - cd2.position)
            )}

            <Grid item>
              <Card className={classes.definitionCard}>
                <CardContent>
                  <Grid container item justifyContent="center">
                    <Select
                      fullWidth
                      onChange={handleDestinationCode}
                      name="destinationCode"
                      value={destinationCode}
                    >
                      <MenuItem value={0}>{t("Alacarte:NewPurpose")}</MenuItem>
                      {destinationDefinitions.map(({ id, code }) => (
                        <MenuItem value={id}>{t(`Variables:${code}`)}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <Button
                      fullWidth
                      onClick={() => handleAddPurpose(destinationCode)}
                      disabled={!destinationCode}
                    >
                      {t("Alacarte:AddPurpose")}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item container spacing={8} md={6}>
            <Grid item>
              <Typography variant="h5" style={{ color: "white" }}>
                {t("Alacarte:Sources")}
              </Typography>
            </Grid>

            {generateChoiceElements(
              dataChoiceDefinitions
                .filter(
                  ({ dataDefinition: { choiceRole } }): boolean =>
                    choiceRole === "source"
                )
                .sort((cd1, cd2) => cd1.position - cd2.position)
            )}

            <Grid item>
              <Card className={classes.definitionCard}>
                <CardContent>
                  <Grid container item justifyContent="center">
                    <Select
                      fullWidth
                      onChange={handleSourceCode}
                      name="sourceCode"
                      value={sourceCode}
                    >
                      <MenuItem value={0}>{t("Alacarte:NewSource")}</MenuItem>
                      {sourceDefinitions.map(({ id, code }) => (
                        <MenuItem value={id}>{t(`Variables:${code}`)}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <Button
                      fullWidth
                      onClick={() => handleAddSource(sourceCode)}
                      disabled={!sourceCode}
                    >
                      {t("Alacarte:AddSource")}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ gridRow: "span 10" }}>
        <Card>
          <CardContent>
            <ChoiceSettings
              choiceSettings={choiceSettings || {}}
              onChange={handleSwitch}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default memo(ALaCartePage);
