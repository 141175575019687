import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  definitionCard: {
    width: "380px",
  },
  definitionCardCodeLabel: {
    textOverflow: "ellipsis",
  },
  definitionCardEarningCodeLabel: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  definitionCardEarningCodeTextField: {
    border: "1px solid #d9d9d9",
    borderRadius: "8px",
    padding: "0.5em",
  },
}));

export default useStyles;
