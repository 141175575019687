import { AsyncSingleValue } from "async-lifecycle-saga";
import { AsyncAction, Success } from "async-lifecycle-saga/dist/models";
import { call } from "redux-saga/effects";

import { setRefreshToken } from "../../../../utils/SecurityUtils";
import { AuthenticationResponseModel } from "../authentication/models";
import { EmployeeSwitchRequest } from "./models";

export function* superImpersonateSuccess({
  result,
}: AsyncAction<
  EmployeeSwitchRequest,
  AuthenticationResponseModel,
  AsyncSingleValue<AuthenticationResponseModel>
>) {
  if (!(result as Success<AuthenticationResponseModel>).body.jwt) {
    return;
  }

  yield call(
    setRefreshToken,
    (result as Success<AuthenticationResponseModel>).body.jwt!.refreshToken,
    (result as Success<AuthenticationResponseModel>).body.jwt!
      .refreshTokenExpires
  );
  window.location.href = "/";
}
