import { AsyncResponse } from "async-lifecycle-saga";

import { asyncUpload } from "../../../../api";
import { ContentNodeModel, DocumentContentNodeModel } from "../../../../models";

export const adminContentNodeDocumentFormPoster = (
  parentId: number,
  contentNode: DocumentContentNodeModel,
  file: File
): Promise<AsyncResponse<ContentNodeModel>> => {
  const formData = new FormData();

  formData.append("title", contentNode.title);
  formData.append("documentName", contentNode.documentName);
  formData.append("file", file);

  return asyncUpload(
    `/api/admin/navigation/content/document/${parentId}`,
    formData
  );
};
