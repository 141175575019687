import { IconButton } from "@material-ui/core";
import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode, useCallback } from "react";

import InfoBase from "./InfoBase";

const useStyles = makeStyles({
  icon: {
    height: 24,
    width: 24,
  },
  iconButton: {
    padding: 5,
  },
});

interface Props {
  inverse?: boolean;
  children: ReactNode;
}

const Info = ({ children, inverse }: Props): JSX.Element => {
  const classes = useStyles();
  const renderButton = useCallback(
    (
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    ): ReactNode => (
      <IconButton
        color={inverse ? undefined : "primary"}
        style={{ color: inverse ? "white" : undefined }}
        onClick={onClick}
        className={classes.iconButton}
      >
        <InfoOutlinedIcon className={classes.icon} />
      </IconButton>
    ),
    [classes, inverse]
  );

  return <InfoBase renderButton={renderButton}>{children}</InfoBase>;
};

export default Info;
