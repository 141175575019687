import { FormHelperText, Input } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  ValidationResult,
  validateBsn,
  validateEmail,
  validateIban,
  validateLogin,
  validateZipcode,
} from "../../business/personal/employeeData";
import { useTranslationStrict } from "../../globalization/i18n";

export type InputTextType =
  | "text"
  | "bsn"
  | "email"
  | "login"
  | "zipcode"
  | "emaillist"
  | "iban";

export interface InputTextProps {
  value?: string | null;
  mode: InputTextType;
  onChange: (value: string | null, isValid: boolean) => void;
  required?: boolean;
}

const validationMapping: {
  [mode in InputTextType]: (value: string) => ValidationResult<string>;
} = {
  text: (value) => ({ valid: true, value }),
  bsn: validateBsn,
  email: validateEmail,
  emaillist: validateEmail,
  login: validateLogin,
  iban: validateIban,
  zipcode: validateZipcode,
};

const InputText = ({ mode, value, onChange, required }: InputTextProps) => {
  const [t] = useTranslationStrict();
  const [innerValue, setInnerValue] = useState(value ?? "");
  const [errorText, setErrorText] = useState<string | null>(null);

  useEffect(() => {
    setInnerValue(value ?? "");
  }, [value]);

  const isValid = useCallback(
    (value: string | null | undefined): boolean => {
      if (!value && required) {
        setErrorText(t("Common:InputText.Required"));
        return false;
      }

      if (value) {
        const result = validationMapping[mode](value);
        if (!result.valid && result.message) {
          setErrorText(t(`Common:${result.message}`));
          return false;
        }
      }

      setErrorText(null);
      return true;
    },
    [mode, required, t]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = event.target.value;
      setInnerValue(newValue ?? "");
      const valid = isValid(newValue);
      onChange(newValue ?? null, valid);
    },
    [isValid, onChange]
  );

  return (
    <>
      <Input
        value={innerValue}
        onChange={handleChange}
        error={errorText != null}
      />
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};

export default InputText;
