import { AsyncResponse } from "async-lifecycle-saga";

import { asyncGet, asyncUpload } from "../../../../api";
import { ProfilePictureResponse } from "../models";

export const avatarDownloadGetter = (): Promise<
  AsyncResponse<ProfilePictureResponse>
> => asyncGet<ProfilePictureResponse>(`/api/profile/avatar`);

export interface AvatarUploadRequest {
  formData: FormData;
}

export const avatarUploadPoster = ({
  formData,
}: AvatarUploadRequest): Promise<AsyncResponse<ProfilePictureResponse>> =>
  asyncUpload<ProfilePictureResponse>(`/api/profile/avatar`, formData);
