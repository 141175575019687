import { Success } from "async-lifecycle-saga/dist/models";
import { Dispatch } from "redux";

import { TotlResponse } from "../business/api";
import { dmsDocumentDownloadCell } from "../business/redux/saga/dms/cells";

export const internalImageReferenceRegex = /!\[([^\]]*)\]\(\/img\/([0-9]+)\)/g;

export const populateImages = (
  text: string,
  imageLinks?: { [id: number]: string }
) =>
  imageLinks
    ? text.replace(
        internalImageReferenceRegex,
        (_, imageLabel, documentIdString) => {
          const documentId = parseInt(documentIdString);
          const imageUrl = imageLinks[documentId];
          return `![${imageLabel}](${imageUrl})`;
        }
      )
    : text;

export const resolveImgLink =
  (dispatch: Dispatch<any>) =>
  (id: number): Promise<[number, string]> =>
    new Promise((resolve) =>
      dispatch(
        dmsDocumentDownloadCell.require(
          { id, path: "dms/document" },
          {
            onFail: () => resolve([id, "about:blank"]),
            onSuccess: ({ body: { link } }: Success<TotlResponse>) => {
              resolve([id, link]);
            },
          }
        )
      )
    );
