import { TOptions } from "i18next";
import React from "react";
import { RouteProps } from "react-router-dom";

import {
  ModuleOption,
  ModuleOptionMap,
  NavigationNodeModel,
  NavigationRootModel,
  OrganizationModel,
  Role,
  emptyOrganization,
} from "../business/models";
import { StoreModel } from "../business/redux/saga/models";
import { TFunctionStrict } from "../globalization/i18n";

export const RouterContext: React.Context<RouteProps> = React.createContext({});

export const appBarMenus = (
  role: Role,
  organization: OrganizationModel
): NavigationNodeModel[] => {
  return ((): NavigationNodeModel[] => {
    switch (role) {
      case "employee":
        return [];
      case "organizationAdmin":
        return [
          {
            id: 0,
            title: "Admin",
            text: "",
            level: 0,
            route: "/admin",
            isRootNode: true,
            children: [
              {
                id: 0,
                title: "Alacarte",
                text: "",
                level: 1,
                route: "/admin/alacarte",
                isRootNode: false,
                options: ["portal"],
              },
              {
                id: 0,
                title: "Contracts",
                text: "",
                level: 1,
                route: "/admin/contracts",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Inloggen als",
                text: "",
                level: 1,
                route: "/admin/impersonate",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Instellingen",
                text: "",
                level: 1,
                route: "/admin/settings",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Keuzes bewerken",
                text: "",
                level: 1,
                route: "/admin/keuzes",
                isRootNode: false,
                options: ["portal"],
              },
              {
                id: 0,
                title: "Management Info",
                text: "",
                level: 1,
                route: "/admin/info",
                isRootNode: false,
                options: ["portal"],
              },
              {
                id: 0,
                title: "Personeelsdossier",
                text: "",
                level: 1,
                route: "/admin/dossier",
                isRootNode: false,
                options: ["portal"],
              },
              {
                id: 0,
                title: "Uploads",
                text: "",
                level: 1,
                route: "/admin/uploads",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Users",
                text: "",
                level: 1,
                route: "/admin/users",
                isRootNode: false,
              },
            ],
          },
        ];
      case "datacockpit":
        return [
          {
            id: 0,
            title: "Datacockpit",
            text: "",
            level: 0,
            route: "/datacockpit",
            isRootNode: true,
            children: [
              {
                id: 0,
                title: "Per werknemer",
                text: "",
                level: 1,
                route: "/datacockpit/employee",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Tabel",
                text: "",
                level: 1,
                route: "/datacockpit/table",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Verwerkingen",
                text: "",
                level: 1,
                route: "/datacockpit/mutations",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Export",
                text: "",
                level: 1,
                route: "/datacockpit/export",
                isRootNode: false,
              },
            ],
          },
        ];
      case "platformAdmin":
        return [
          {
            id: 0,
            title: "Super",
            text: "",
            level: 0,
            route: "/super",
            isRootNode: true,
            children: [
              {
                id: 0,
                title: "Organizations",
                text: "",
                level: 1,
                route: "/super/organizations",
                isRootNode: false,
              },
              {
                id: 0,
                title: "Inloggen als",
                text: "",
                level: 1,
                route: "/admin/impersonate",
                isRootNode: false,
              },
            ],
          },
        ];
      default:
        throw new Error("Unreachable!");
    }
  })().map(({ children, ...rest }) => ({
    ...rest,
    children: children!.filter(
      ({ options }) =>
        options === undefined ||
        options.every(
          (option) =>
            (organization as unknown as ModuleOptionMap)[option] !==
            ModuleOption.off
        )
    ),
  }));
};

export const normalizeRoute = (route: string): string =>
  route.startsWith("/") ? route : `/${route}`;

const tOptions: TOptions = {
  ns: "Navigation",
};
export const selectMenuNavigation =
  (t: TFunctionStrict) =>
  ({
    navigation: {
      tree: { value: navigation },
    },
    organization: {
      get: { value: organization = emptyOrganization },
    },
    profile,
    session,
  }: StoreModel) =>
    navigation && session && session.appRole && profile.profile
      ? {
          ...navigation,
          items: navigation?.items.concat(
            appBarMenus(session.appRole, organization).map((m) => ({
              ...m,
              children: m.children?.sort((m1, m2) =>
                t(m1.title, tOptions).localeCompare(t(m2.title, tOptions))
              ),
            }))
          ),
        }
      : navigation;

export const selectNodes = (t: TFunctionStrict) => (store: StoreModel) => {
  const {
    navigation: {
      menu: { value: menu },
    },
  } = store;
  const menuNavigation = selectMenuNavigation(t)(store);

  const result: NavigationNodeModel[] = [];
  const addFlatNode = (node: NavigationNodeModel | undefined) => {
    if (!node) {
      return;
    }

    result.push(node);
    if (node.children) {
      node.children.forEach((child) => {
        addFlatNode(child);
      });
    }
  };
  const addFlat = (root: NavigationRootModel | undefined): void => {
    if (!root) {
      return;
    }

    result.push(...root.items);
    root.items.forEach(({ children }): void => {
      if (children) {
        const childrenValid = children.filter(({ id }): boolean => !!id);
        childrenValid.forEach((child) => {
          addFlatNode(child);
        });
      }
    });
  };

  addFlat(menuNavigation);
  addFlat(menu);
  return result;
};
