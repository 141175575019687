import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { DeleteForever as DeleteForeverIcon } from "@material-ui/icons";
import { Formik } from "formik";
import { MutableRefObject, RefObject, useState } from "react";

import {
  ContentNodeModel,
  DocumentContentNodeModel,
  LinkContentNodeModel,
  NavigationNodeFormValues,
  NavigationNodeModel,
} from "../../../business/models";
import { useSession } from "../../../business/redux/saga/admin/hooks";
import { reflexoHistory } from "../../../business/router";
import { useTranslationStrict } from "../../../globalization/i18n";
import { isAdminRole } from "../../../utils/SecurityUtils";
import DaywizeSsoNotificationDialog from "../../authentication/daywize/DaywizeSsoNotificationDialog";
import useStyles from "../../pages/ContentPage.styles";
import materialIcon from "../../ux/icons";
import {
  ContentNodeDocumentListItem,
  ContentNodeLinkListItem,
} from "../contentNodes";

const materialIconChevronRight = "ChevronRight";

interface Props {
  disabled?: boolean;
  enableEdit: boolean;
  isEditingPage: boolean;
  node: NavigationNodeModel;
  onDeleteNavigationNode(id: number): void;
  onEditContentNode(contentNode: ContentNodeModel): void;
  formRef: RefObject<Formik<NavigationNodeFormValues>>;
  textCurrent: MutableRefObject<string>;
  textCursorPosition: MutableRefObject<number>;
}

const ContentPageItemsList = (props: Props) => {
  const {
    disabled = false,
    enableEdit,
    isEditingPage,
    onDeleteNavigationNode,
    onEditContentNode,
    node,
    node: { children, level, contentNodes, special, text },
    formRef,
    textCurrent,
    textCursorPosition,
  } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();

  const {
    session: { appRole },
  } = useSession();
  const isOrganizationAdminSelected = isAdminRole(appRole);

  /* Daywize */
  const [isDaywizeSsoNotificationOpen, setIsDaywizeSsoNotificationOpen] =
    useState<boolean>(false);
  const handleDaywizeSsoNotificationClose = () => {
    setIsDaywizeSsoNotificationOpen(false);
  };

  return (
    <>
      <List>
        {level < 2 &&
          children &&
          children.map((childNode, index) => {
            const { id: childId, icon, title, route } = childNode;

            return (
              <ListItem
                key={childId}
                button
                onClick={(): void => {
                  reflexoHistory.push(route);
                }}
              >
                <ListItemIcon>
                  {materialIcon(icon || materialIconChevronRight)}
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }}>
                  {title}
                </ListItemText>
                {enableEdit && (
                  <ListItemSecondaryAction>
                    <Grid container style={{ width: "144px" }}>
                      <Grid item xs={12}>
                        <Tooltip title={t("Common:Delete")}>
                          <IconButton
                            onClick={() =>
                              onDeleteNavigationNode(childId || -999)
                            }
                            classes={{ root: classes.danger }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        {((enableEdit && isEditingPage) ||
          special === undefined ||
          special === "orgplan" ||
          special === "contract" ||
          special === "debitCard") && (
          <>
            {contentNodes &&
              contentNodes
                .sort((a, b) => a.position - b.position)
                .map((contentNode: ContentNodeModel) => {
                  const isDocument =
                    (contentNode as DocumentContentNodeModel).documentName !==
                    undefined;
                  const isImage =
                    (isDocument &&
                      (
                        contentNode as DocumentContentNodeModel
                      ).contentType?.includes("image")) ||
                    false;
                  const internalImageRegExp = new RegExp(
                    `!\\[[^\\]]*\\]\\(\\/img\\/${contentNode.id}\\)`
                  );
                  const showDocument =
                    isDocument &&
                    (isOrganizationAdminSelected ||
                      !isImage ||
                      !internalImageRegExp.test(text));
                  if (showDocument) {
                    return (
                      <ContentNodeDocumentListItem
                        key={contentNode.id}
                        contentNode={contentNode as DocumentContentNodeModel}
                        disabled={disabled}
                        isEditing={enableEdit && isEditingPage}
                        navigationNode={node}
                        formRef={formRef}
                        textCurrent={textCurrent}
                        textCursorPosition={textCursorPosition}
                        special={special}
                      />
                    );
                  }
                  if (
                    (contentNode as LinkContentNodeModel).link !== undefined
                  ) {
                    return (
                      <ContentNodeLinkListItem
                        key={contentNode.id}
                        contentNode={contentNode as LinkContentNodeModel}
                        disabled={disabled}
                        isEditing={enableEdit && isEditingPage}
                        onDaywizeNotification={() =>
                          setIsDaywizeSsoNotificationOpen(true)
                        }
                        onEdit={onEditContentNode}
                        showShiftDown={Boolean(
                          contentNodes &&
                            contentNode.position < contentNodes.length - 1
                        )}
                        showShiftUp={
                          Boolean(contentNodes) && contentNode.position > 0
                        }
                      />
                    );
                  }

                  return <></>;
                })}
          </>
        )}
      </List>
      <DaywizeSsoNotificationDialog
        onClose={handleDaywizeSsoNotificationClose}
        open={isDaywizeSsoNotificationOpen}
      />
    </>
  );
};

export default ContentPageItemsList;
