import { createActor, createSingle } from "async-lifecycle-saga";
import { Some } from "async-lifecycle-saga/dist/models";

import { UploadTypeModel } from "../../../admin/upload/models";
import { asyncGet, asyncPost, asyncPut } from "../../../api";
import { DataDefinitionModel, DownloadModel } from "../../../models";
import { DebitCardResponse } from "../../../personal/debitCard";
import { EmployeeDataModel } from "../../../personal/employeeData";
import { StoreModel } from "../models";
import { EmployeeDataModelJson, toPersonalEmployeeDataModel } from "./api";

export const personalDataCell = createSingle<EmployeeDataModel[], StoreModel>({
  path: ["personal", "data"],
  require: {
    api: () =>
      asyncGet<EmployeeDataModelJson[]>("/api/personaldata/data").then(
        toPersonalEmployeeDataModel
      ),
  },
});

export const personalDefinitionsCell = createSingle<
  DataDefinitionModel[],
  StoreModel
>({
  path: ["personal", "definitions"],
  require: {
    api: () => asyncGet("/api/personaldata/definitions"),
  },
});

export const personalDownloadsCell = createSingle<DownloadModel[], StoreModel>({
  path: ["personal", "downloads"],
  require: {
    api: () => asyncGet("/api/download/list"),
  },
});

export const personalAddPboCell = createActor<number, undefined>({
  path: ["personal", "addPbo"],
  api: (navigationNodeId) =>
    asyncPost("/api/personalData/pbo", { navigationNodeId }),
  invalidate: [personalDownloadsCell.events],
});

export const personalUploadTypesCell = createSingle<
  UploadTypeModel[],
  StoreModel
>({
  path: ["personal", "uploadTypes"],
  require: {
    api: () => asyncGet("/api/download/types"),
  },
});

export const debitCardCell = createSingle<
  DebitCardResponse,
  StoreModel,
  undefined,
  never,
  Some
>({
  path: ["personal", "debitCard"],
  require: {
    api: () => asyncGet("/api/personalData/debitCard"),
  },
  create: {
    api: (_: Some) => asyncPut("/api/personalData/debitCard", {}),
  },
});
