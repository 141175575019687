import { Details, Success } from "async-lifecycle-saga/dist/models";
import { useDispatch, useSelector } from "react-redux";

import { ProfileModel } from "../../../models";
import { StoreModel } from "../models";
import { avatarCell } from "./avatar/cells";
import { profileCell } from "./cells";
import { ProfilePictureResponse } from "./models";

export interface ProfileHook {
  profile?: ProfileModel;

  getProfile: (
    loginName: string,
    onFail?: (problemDetails: Details) => void,
    onSuccess?: (success: Success<ProfileModel>) => void
  ) => void;

  avatarUpload: (
    file: File,
    onFail?: (problemDetails: Details) => void,
    onSuccess?: (success: Success<ProfilePictureResponse>) => void
  ) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useProfile = (): ProfileHook => {
  const dispatch = useDispatch();

  const profile = useSelector<StoreModel, ProfileModel | undefined>(
    (state: StoreModel): ProfileModel | undefined => state.session.profile
  );

  const getProfile = (
    loginName: string,
    onFail?: (problemDetails: Details) => void,
    onSuccess?: (success: Success<ProfileModel>) => void
  ): void => {
    dispatch({
      type: profileCell.events.require,
      payload: { loginName },
      onFail,
      onSuccess,
    });
  };

  const avatarUpload = (
    file: File,
    onFail?: (problemDetails: Details) => void,
    onSuccess?: (success: Success<ProfilePictureResponse>) => void
  ): void => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch({
      type: avatarCell.events.update,
      payload: { formData },
      onFail,
      onSuccess,
    });
  };

  return {
    profile,
    getProfile,
    avatarUpload,
  };
};
