import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";
import { memo } from "react";

import { useNavigation } from "../../../business/redux/saga/admin/hooks";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";
import useStyles from "./ContentNodeDeleteConfirmDialog.styles";

interface ContentNodeDeleteConfirmDialogProps {
  nodeId: number;
  title: string;
  open: boolean;
  onClose: () => void;
}

const ContentNodeDeleteConfirmDialog = (
  props: ContentNodeDeleteConfirmDialogProps
) => {
  const { nodeId, title, open, onClose } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const { getNavigation, deleteContentNode } = useNavigation();

  const handleConfirm = () => {
    deleteContentNode(
      nodeId || -1,
      showErrorMessageFromDetails(t("Admin:ContentNodeDeleteError")),
      (success) => {
        enqueueSnackbar(
          `${t("Admin:ContentNodeDeleteSuccess")} (${success.body.title})`,
          { variant: "success" }
        );
        getNavigation("appbar");
        getNavigation("aside");
        onClose();
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmDelete-dialog-title"
      aria-describedby="confirmDelete-dialog-description"
    >
      <DialogTitle id="confirmDelete-dialog-title">
        {t("Admin:ContentNodeDeleteConfirmDialogTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmDelete-dialog-description">
          {t("Admin:ContentNodeDeleteConfirmDialogMessage")} ({title})
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} autoFocus>
          {t("Common:No")}
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          className={classes.dangerButton}
        >
          {t("Common:Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ContentNodeDeleteConfirmDialog);
