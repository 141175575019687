import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { addMonths, addYears } from "date-fns";
import { Field, Form, Formik, FormikActions } from "formik";
import { Select, TextField } from "formik-material-ui";
import { DatePicker } from "material-ui-pickers";
import { useSnackbar } from "notistack";
import React, {
  MutableRefObject,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { UploadTypeModel } from "../../../business/admin/upload/models";
import {
  ModuleOption,
  NavigationNodeFormValues,
  NavigationNodeModel,
  UserModel,
  useNavigationNodeValidationSchema,
} from "../../../business/models";
import { adminEmployeeTypesCell } from "../../../business/redux/saga/admin/employee/cells";
import { adminEmployersListSpecialCell } from "../../../business/redux/saga/admin/employers/cells";
import {
  useNavigation,
  useUploads,
} from "../../../business/redux/saga/admin/hooks";
import { StoreModel } from "../../../business/redux/saga/models";
import { organizationCell } from "../../../business/redux/saga/organization";
import { reflexoHistory } from "../../../business/router";
import { useTranslationStrict } from "../../../globalization/i18n";
import { toNoon } from "../../../utils/DateUtils";
import useContentStyles from "../contentStyles";
import FormikMaterialUIIconPicker from "../navigation/FormikMaterialUIIconPicker";
import useStyles from "./ContentPageEditor.styles";
import ContentPageTextMarkdownEditorField from "./ContentPageTextMarkdownEditorField";

interface ContentPageEditorProps {
  formRef: RefObject<Formik<NavigationNodeFormValues>>;
  node: NavigationNodeModel;
  onCancel(): void;
  onChange(node: NavigationNodeModel): void;
  onPreview(markdown: string): Promise<ReactNode>;
  textCurrent: MutableRefObject<string>;
  textCursorPosition: MutableRefObject<number>;
}

const allValue = "(all)";
const textFieldName = "text";
const alacarteInfoFieldName = "alacarteInfo";

const rolesAllSelected = (roles: string[] | undefined): boolean =>
  !roles ||
  !Array.isArray(roles) ||
  roles.length === 0 ||
  (roles.length === 1 && roles[0] === allValue);

const ContentPageEditor = (props: ContentPageEditorProps) => {
  const {
    formRef,
    node,
    node: { icon, isRootNode, roles },
    onCancel,
    onChange,
    onPreview,
    textCurrent,
    textCursorPosition,
  } = props;
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const contentClasses = useContentStyles();
  const { getNavigation, updateNavigationNode } = useNavigation();
  const { upload, getUploadTypes } = useUploads();

  const user = useSelector(
    (store: StoreModel): UserModel => store.session.user!
  );
  const organization = useSelector(organizationCell.select).value!;
  const contractManagementFree =
    organization.contractManagement !== ModuleOption.silver;
  const contractManagementFixed =
    !node.isRootNode && organization.portal === ModuleOption.off;

  const [initialValues, setInitialValues] = useState<NavigationNodeFormValues>({
    ...node,
    special: node.special ?? (contractManagementFixed ? "contract" : undefined),
    alacarteInfo: node.alacarteInfo,
    contractStart: node.contractStart ?? new Date(),
    contractEnd: node.contractEnd ?? addYears(new Date(), 1),
    contractAlert: node.contractAlert ?? addMonths(new Date(), 10),
    contractClientName: node.contractClientName ?? user.fullName,
    contractClientEmail: node.contractClientEmail ?? user.email,
    contractSupplierName: node.contractSupplierName ?? "",
    contractValue: node.contractValue ?? "",

    uploadTypeIdSelected: node.uploadTypeId || "none",
    roles: rolesAllSelected(roles)
      ? [allValue]
      : roles?.filter((role: string) => role !== allValue),
  });

  const [uploadTypes, setUploadTypes] = useState<UploadTypeModel[]>([]);
  const setUploadTypesSorted = useCallback((types: UploadTypeModel[]): void => {
    types.sort(
      (
        { name: left }: UploadTypeModel,
        { name: right }: UploadTypeModel
      ): number => (left < right ? -1 : 1)
    );
    setUploadTypes(types);
  }, []);
  useEffect((): void => {
    const onlyPersonalTypes = (uploadType: UploadTypeModel): boolean =>
      uploadType.category === "personal";
    if (!upload || !upload.types?.value || upload.types.value.length === 0) {
      getUploadTypes(
        (details: Details) => {
          enqueueSnackbar(details.detail, { variant: "error" });
        },
        (success: Success<UploadTypeModel[]>) => {
          setUploadTypesSorted(success.body.filter(onlyPersonalTypes));
        }
      );
    } else {
      const personalTypes = upload.types.value.filter(onlyPersonalTypes);
      if (personalTypes.length > uploadTypes.length) {
        setUploadTypesSorted(personalTypes);
      }
    }

    if (node.uploadTypeId && node.uploadTypeId !== initialValues.uploadTypeId) {
      setInitialValues({
        ...initialValues,
        uploadTypeId: node.uploadTypeId,
        uploadTypeIdSelected: node.uploadTypeId,
      });
    }
  }, [
    enqueueSnackbar,
    getUploadTypes,
    initialValues,
    node.uploadTypeId,
    setUploadTypesSorted,
    upload,
    uploadTypes,
  ]);

  const handleSubmit = useCallback(
    (
      {
        icon: updatedIcon,
        title: updatedTitle,
        text: updatedText,
        special: updatedSpecial,
        alacarteInfo: updatedAlacarteInfo,
        roles: updatedRoles,
        uploadTypeIdSelected: updatedUploadTypeIdSelected,
        ...update
      },
      { setSubmitting }: FormikActions<Readonly<NavigationNodeFormValues>>
    ): void => {
      const updatedNode: NavigationNodeModel = {
        ...node,
        ...update,
        icon: updatedIcon,
        title: updatedTitle,
        text: updatedText,
        special: updatedSpecial,
        alacarteInfo: updatedAlacarteInfo,
        roles: rolesAllSelected(updatedRoles)
          ? []
          : updatedRoles.filter((r: string) => r !== allValue),
        uploadTypeId:
          updatedUploadTypeIdSelected === "none"
            ? undefined
            : updatedUploadTypeIdSelected,
      };
      setSubmitting(true);
      updateNavigationNode(
        updatedNode,
        (details) => {
          enqueueSnackbar(
            `${t("Admin:NavigationNodeUpdateError")} (${details.detail})`,
            {
              variant: "error",
            }
          );
          setSubmitting(false);
        },
        (success) => {
          enqueueSnackbar(
            `${t("Admin:NavigationNodeUpdateSuccess")} (${success.body.title})`,
            {
              variant: "success",
            }
          );
          getNavigation("appbar");
          getNavigation("aside");
          setSubmitting(false);

          onChange(updatedNode);

          reflexoHistory.push(success.body.route);
        }
      );
    },
    [enqueueSnackbar, getNavigation, node, onChange, t, updateNavigationNode]
  );

  const handleCancel = useCallback(() => {
    formRef.current?.getFormikActions().resetForm();
    onCancel();
  }, [formRef, onCancel]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminEmployeeTypesCell.require());
    dispatch(adminEmployersListSpecialCell.require());
  }, [dispatch]);
  const employeeTypes =
    useSelector(
      (store: StoreModel): string[] | undefined =>
        store.admin.employee.types.value
    ) || [];

  const { validationSchema } = useNavigationNodeValidationSchema();
  const isTestEnv =
    organization.prefix === "bedrijf" ||
    organization.prefix.startsWith("reflexo") ||
    organization.prefix.startsWith("twijg");
  const hasGreenChoice = isTestEnv || organization.prefix === "groenewkr";
  const hasWlzAdvice =
    isTestEnv ||
    organization.prefix.startsWith("wlz") ||
    organization.prefix.endsWith("wlz");
  const hasDebitCard = true;

  const currentAlacarteInfo = useRef<string>(null) as MutableRefObject<string>;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={formRef}
      validationSchema={validationSchema}
      render={({
        isValid,
        isSubmitting,
        handleChange,
        setFieldValue,
        values: {
          icon: currentIcon,
          title: currentTitle,
          special: currentSpecial,
          roles: currentRoles,
          uploadTypeIdSelected: currentUploadTypeIdSelected,
          contractStart: currentContractStart,
          contractEnd: currentContractEnd,
          contractAlert: currentContractAlert,
          contractClientName: currentClientName,
          contractClientEmail: currentClientEmail,
          contractSupplierName: currentSupplierName,
          contractValue: currentValue,
          contractPrivate: currentPrivate,
        },
      }): JSX.Element => (
        <Form>
          <Card>
            <CardContent>
              <Grid container direction="column" spacing={8}>
                <Grid item container direction="row" spacing={8}>
                  {icon && (
                    <Grid item>
                      <FormControl>
                        <FormLabel>{t("Admin:NavigationNodeIcon")}</FormLabel>
                        <Field
                          component={FormikMaterialUIIconPicker}
                          name="icon"
                          value={currentIcon}
                          onChange={handleChange}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel>{t("Admin:NavigationNodeTitle")}</FormLabel>
                      <Field
                        component={TextField}
                        name="title"
                        placeholder="Abcde 1"
                        value={currentTitle}
                        onChange={handleChange}
                        className={classes.field}
                      />
                    </FormControl>
                  </Grid>
                  {!isRootNode && organization.portal !== ModuleOption.off && (
                    <Grid item>
                      <FormControl>
                        <FormLabel>
                          {t("Admin:NavigationNodeUploadType")}
                        </FormLabel>
                        <Field
                          component={Select}
                          name="uploadTypeIdSelected"
                          placeholder={t(
                            "Admin:NavigationNodeUploadTypeSelect"
                          )}
                          style={{ marginTop: 3 }}
                        >
                          <MenuItem
                            button
                            selected={currentUploadTypeIdSelected === "none"}
                            value="none"
                          >
                            <em>{t("Common:None")}</em>
                          </MenuItem>
                          {uploadTypes.map(
                            (
                              uploadType: UploadTypeModel
                            ): React.ReactElement => (
                              <MenuItem
                                key={uploadType.id}
                                button
                                selected={
                                  currentUploadTypeIdSelected === uploadType.id
                                }
                                value={uploadType.id}
                              >
                                <ListItemText
                                  primary={t(
                                    `UploadType:${uploadType.name.replace(
                                      ":",
                                      ";"
                                    )}`
                                  )}
                                />
                              </MenuItem>
                            )
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel>{t("Admin:NavigationNodeText")}</FormLabel>
                    <ContentPageTextMarkdownEditorField
                      fieldName={textFieldName}
                      onPreview={onPreview}
                      textCurrent={textCurrent}
                      textCursorPosition={textCursorPosition}
                    />
                  </FormControl>
                </Grid>
                {!isRootNode && (
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:NavigationNodeSpecial")}</FormLabel>
                      <Field
                        component={Select}
                        name="special"
                        fullWidth
                        value={currentSpecial}
                        onChange={handleChange}
                        disabled={contractManagementFixed}
                      >
                        <MenuItem>
                          {t("Admin:NavigationNodeSpecialNone")}
                        </MenuItem>
                        <MenuItem value="alacarte">
                          {t("Admin:NavigationNodeSpecialAlacarte")}
                        </MenuItem>
                        <MenuItem value="pao">
                          {t("Admin:NavigationNodeSpecialPao")}
                        </MenuItem>
                        <MenuItem value="orgplan">
                          {t("Admin:NavigationNodeSpecialOrgplan")}
                        </MenuItem>
                        <MenuItem value="contract">
                          {t("Admin:NavigationNodeSpecialContract")}
                        </MenuItem>
                        {hasGreenChoice && (
                          <MenuItem value="employers">
                            {t("Admin:NavigationNodeSpecialEmployers")}
                          </MenuItem>
                        )}
                        {hasGreenChoice && (
                          <MenuItem value="greenChoice">
                            {t("Admin:NavigationNodeSpecialGreenChoice")}
                          </MenuItem>
                        )}
                        {hasWlzAdvice && (
                          <MenuItem value="wlzAdvice">
                            {t("Admin:NavigationNodeSpecialWlzAdvice")}
                          </MenuItem>
                        )}
                        {hasDebitCard && (
                          <MenuItem value="debitCard">
                            {t("Admin:NavigationNodeSpecialDebitCard")}
                          </MenuItem>
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                {!isRootNode && currentSpecial === "alacarte" && (
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>
                        {t("Alacarte:'Your benefit' disclaimer")}
                      </FormLabel>
                      <ContentPageTextMarkdownEditorField
                        fieldName={alacarteInfoFieldName}
                        onPreview={onPreview}
                        textCurrent={currentAlacarteInfo}
                      />
                    </FormControl>
                  </Grid>
                )}
                {!isRootNode && currentSpecial === "contract" && (
                  <>
                    <Grid item>
                      {!contractManagementFree && (
                        <FormControlLabel
                          control={
                            <Switch
                              name="contractPrivate"
                              checked={!currentPrivate}
                              onChange={(_, checked): void =>
                                setFieldValue("contractPrivate", !checked)
                              }
                            />
                          }
                          label={t("Admin:ContractPrivate")}
                        />
                      )}
                    </Grid>
                    <Grid item container direction="row" spacing={8}>
                      <Grid item md={4}>
                        <FormControl>
                          <FormLabel>{t("Admin:ContractStart")}</FormLabel>
                          <DatePicker
                            name="contractStart"
                            value={currentContractStart}
                            onChange={(date): void =>
                              setFieldValue("contractStart", toNoon(date))
                            }
                            keyboard
                            format="dd-MM-yyyy"
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <FormControl>
                          <FormLabel>{t("Admin:ContractEnd")}</FormLabel>
                          <DatePicker
                            name="contractEnd"
                            value={currentContractEnd}
                            onChange={(date): void =>
                              setFieldValue("contractEnd", toNoon(date))
                            }
                            keyboard
                            format="dd-MM-yyyy"
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <FormControl>
                          <FormLabel>{t("Admin:ContractAlert")}</FormLabel>
                          <DatePicker
                            name="contractAlert"
                            value={currentContractAlert}
                            onChange={(date): void =>
                              setFieldValue("contractAlert", toNoon(date))
                            }
                            keyboard
                            format="dd-MM-yyyy"
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={8}>
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <FormLabel>{t("Admin:ContractClientName")}</FormLabel>
                          <Field
                            component={TextField}
                            name="contractClientName"
                            value={currentClientName}
                            onChange={handleChange}
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <FormLabel>
                            {t("Admin:ContractClientEmail")}
                          </FormLabel>
                          <Field
                            component={TextField}
                            name="contractClientEmail"
                            placeholder="john@plaza.eu"
                            value={currentClientEmail}
                            onChange={handleChange}
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={8}>
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <FormLabel>
                            {t("Admin:ContractSupplierName")}
                          </FormLabel>
                          <Field
                            component={TextField}
                            name="contractSupplierName"
                            value={currentSupplierName}
                            onChange={handleChange}
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <FormLabel>{t("Admin:ContractValue")}</FormLabel>
                          <Field
                            component={TextField}
                            name="contractValue"
                            placeholder="€ 5.000,-"
                            value={currentValue}
                            onChange={handleChange}
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
                {employeeTypes.length > 0 && (
                  <Grid item>
                    <FormControl fullWidth>
                      <FormLabel>{t("Admin:Roles")}</FormLabel>
                      <Field
                        component={Select}
                        name="roles"
                        multiple
                        fullWidth
                        value={
                          rolesAllSelected(currentRoles)
                            ? [allValue]
                            : currentRoles?.filter(
                                (r: string) => r !== allValue
                              )
                        }
                        onChange={handleChange}
                      >
                        {rolesAllSelected(currentRoles) && (
                          <MenuItem disabled value={allValue}>
                            {t("Admin:RolesAll")}
                          </MenuItem>
                        )}
                        {employeeTypes.map((et) => (
                          <MenuItem key={et} value={et}>
                            {et}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    autoFocus
                    type="submit"
                    className={
                      !isSubmitting && isValid
                        ? contentClasses.saveButtonEditing
                        : undefined
                    }
                    disabled={isSubmitting || !isValid}
                  >
                    {t("Common:Save")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    {t("Common:Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Form>
      )}
    />
  );
};

export default memo(ContentPageEditor);
