import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { flow, sortBy } from "lodash/fp";
import filter from "lodash/fp/filter";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DataDefinitionGroup,
  DataDefinitionModel,
  emptyDataDefinitions,
} from "../../../business/models";
import { datacockpitEmployeeDataEditCell } from "../../../business/redux/saga/datacockpit/cells";
import { EmployeeDataMergedModel } from "../../../business/redux/saga/datacockpit/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { minDate } from "../../../utils/DateUtils";
import Loading from "../../ux/Loading";
import useStyles from "./EmployeeDataAddTableRow.styles";

const pickDataDefinitions = (
  dataDefinitions: DataDefinitionModel[],
  group: DataDefinitionGroup,
  employeeData: EmployeeDataMergedModel[]
): DataDefinitionModel[] =>
  flow(
    filter(
      (dd: DataDefinitionModel) =>
        dd.group === group &&
        !employeeData.some((ed) => ed.dataDefinitionId === dd.id)
    ),
    sortBy((dd: DataDefinitionModel) => dd.code)
  )(dataDefinitions);

const EmployeeDataAddTableRow = () => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const classes = useStyles();

  const employeeDataEdit = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData.edit
  );
  const { group } = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData.filter
  );
  const { dataDefinitions, dataDefinitionsLoading } = useSelector(
    (store: StoreModel) => ({
      dataDefinitions: pickDataDefinitions(
        store.datacockpit.dataDefinitions.value || emptyDataDefinitions,
        group,
        employeeDataEdit || []
      ),
      dataDefinitionsLoading: store.datacockpit.dataDefinitions.status.loading,
    })
  );

  const [dataDefinitionSelected, setDataDefinitionSelected] = useState<
    number | ":None"
  >(":None");
  const handleDataDefinitionChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const selectedValue =
        event.target.value && event.target.value !== ":None"
          ? Number(event.target.value)
          : null;
      if (selectedValue) {
        dispatch(
          datacockpitEmployeeDataEditCell.patch({
            dataDefinitionId: selectedValue,
            employeeData: {
              dataDefinitionId: selectedValue,
              dataDefinition: dataDefinitions.find(
                (dd) => dd.id === selectedValue
              ),
              boolean: false,
              date: minDate,
              isEmpty: true,
              isApproved: false,
              numeric: 0,
            },
          })
        );
        setDataDefinitionSelected(":None");
      }
    },
    [dataDefinitions, dispatch]
  );

  if (!employeeDataEdit) {
    return <></>;
  }

  return dataDefinitionsLoading ? (
    <Loading />
  ) : (
    <TableRow>
      <TableCell colSpan={2}>
        {dataDefinitions.length === 0 && (
          <p>
            <em>
              {employeeDataEdit?.length === 0
                ? t("Datacockpit:Geen variabelen aanwezig in deze groep.")
                : t(
                    "Datacockpit:Alle variabelen in deze groep zijn al toegevoegd."
                  )}
            </em>
          </p>
        )}
        {dataDefinitions.length > 0 && (
          <FormControl>
            <FormLabel>{t("Datacockpit:Variabele toevoegen")}</FormLabel>
            <Select
              disabled={dataDefinitionsLoading}
              onChange={handleDataDefinitionChange}
              value={dataDefinitionSelected}
            >
              {dataDefinitions.map((dd) => (
                <MenuItem
                  key={dd.id}
                  classes={{ root: classes.selectMenuItem }}
                  value={dd.id}
                >
                  {t(`Variables:${dd.code || ""}`)}
                  <br />
                  <code style={{ fontSize: "smaller" }}>{dd.code}</code>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EmployeeDataAddTableRow;
