import {
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Warning,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  DragEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NavigationNodeModel } from "../../business/models";
import { adminNavigationNodePruneCell } from "../../business/redux/saga/admin/navigation/cells";
import { StoreModel } from "../../business/redux/saga/models";
import { reflexoHistory } from "../../business/router";
import { isAdminRole } from "../../utils/SecurityUtils";
import AdminNavigationContext from "../admin/navigation/AdminNavigationContext";
import { useSnackbarErrorMessage } from "../ux";
import { NavigationMenuContext, routeAllOpen } from "./";
import AccordionDropArea from "./AccordionDropArea";
import useStyles from "./AccordionMenu.styles";

const AccordionMenuContent = (props: NavigationNodeModel) => {
  const { id, children, route } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const classes = useStyles();
  const { routeOpen, setRouteOpen } = useContext(NavigationMenuContext);

  const { appRole } = useSelector(({ session: { appRole } }: StoreModel) => ({
    appRole,
  }));
  const [parentLoading, setParentLoading] = useState<number | null>(null);
  const [indexLoading, setIndexLoading] = useState<number | null>(null);

  const { isEditing: isNavigationEditing } = useContext(AdminNavigationContext);
  const canDragMenuItems = useMemo(
    () => isAdminRole(appRole) && isNavigationEditing,
    [appRole, isNavigationEditing]
  );

  const handleDragStart: (childId: number) => DragEventHandler = useCallback(
    (childId) => {
      return (event) => {
        event.dataTransfer.setData("text", childId.toString());
      };
    },
    []
  );

  const handleDrag: DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (parentId?: number) => (childId: number, index: number) => {
      if (parentId === childId) {
        enqueueSnackbar(
          t("Kan pagina niet onder zichzelf plaatsen.", { ns: "Admin" }),
          { variant: "warning" }
        );
        return;
      }

      setIndexLoading(index);
      setParentLoading(parentId || id!);
      dispatch(
        adminNavigationNodePruneCell.require(
          {
            parentIsRoot: false,
            parentId: parentId || id!,
            childId,
            index,
          },
          {
            onFail: (details) => {
              setIndexLoading(null);
              setParentLoading(null);
              showErrorMessageFromDetails(
                t("Fout bij verplaatsen pagina", { ns: "Admin" })
              )(details);
            },
            onSuccess: () => {
              setIndexLoading(null);
              setParentLoading(null);
            },
          }
        )
      );
    },
    [dispatch, enqueueSnackbar, id, showErrorMessageFromDetails, t]
  );

  return (
    <List dense disablePadding>
      {canDragMenuItems && (
        <ListItem key="top">
          <ListItemText>
            <AccordionDropArea
              index={0}
              loading={parentLoading === null && indexLoading === 0}
              onDrop={handleDrop(id!)}
              size="small"
            />
          </ListItemText>
        </ListItem>
      )}
      {children &&
        children.map(
          (
            {
              id: subId,
              children: subsub,
              warning: subWarning,
              title: subTitle,
              route: subRoute,
            },
            indexLevel1
          ) => (
            <>
              <ListItem
                key={subId || subRoute}
                button
                onClick={(): void => {
                  reflexoHistory.push(subRoute);
                  if (!routeOpen?.startsWith(subRoute)) {
                    setRouteOpen(route);
                  }
                }}
              >
                <ListItemIcon>
                  {subWarning ? <Warning /> : <ChevronRight />}
                </ListItemIcon>
                <ListItemText
                  draggable={canDragMenuItems}
                  onDragStart={handleDragStart(subId!)}
                  onDrag={handleDrag}
                  primary={subTitle}
                  primaryTypographyProps={{
                    classes: { root: classes.subMenuText },
                  }}
                />
                <ListItemSecondaryAction
                  className={classes.menuSecondaryAction}
                >
                  {((subsub && subsub.length > 0) || canDragMenuItems) &&
                  (routeOpen?.startsWith(subRoute) ||
                    routeOpen === routeAllOpen) ? (
                    <IconButton
                      onClick={() => {
                        setRouteOpen(route);
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  ) : (
                    ((subsub && subsub.length > 0) || canDragMenuItems) && (
                      <IconButton
                        onClick={() => {
                          setRouteOpen(subRoute);
                        }}
                      >
                        <ExpandMore />
                      </IconButton>
                    )
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              {(subsub || canDragMenuItems) && (
                <Collapse
                  in={
                    routeOpen?.startsWith(subRoute) ||
                    routeOpen === routeAllOpen
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <Container>
                    <List component="div">
                      {canDragMenuItems && (
                        <ListItem key="top">
                          <ListItemText>
                            <AccordionDropArea
                              index={0}
                              loading={
                                parentLoading === subId && indexLoading === 0
                              }
                              onDrop={handleDrop(subId!)}
                              size="smaller"
                            />
                          </ListItemText>
                        </ListItem>
                      )}
                      {subsub?.map(
                        (
                          {
                            id: subsubId,
                            route: subsubRoute,
                            title: subsubTitle,
                            warning: subsubWarning,
                          },
                          indexLevel2
                        ) => (
                          <>
                            <ListItem
                              key={subsubId || subsubRoute}
                              button
                              onClick={() => {
                                reflexoHistory.push(subsubRoute);
                                setRouteOpen(subRoute);
                              }}
                            >
                              <ListItemIcon>
                                {subsubWarning ? <Warning /> : <ChevronRight />}
                              </ListItemIcon>
                              <ListItemText
                                draggable={canDragMenuItems}
                                onDragStart={handleDragStart(subsubId!)}
                                onDrag={handleDrag}
                                primary={subsubTitle}
                                primaryTypographyProps={{
                                  classes: { root: classes.subMenuText },
                                }}
                              />
                            </ListItem>
                            {canDragMenuItems && (
                              <ListItem key={`drop-${indexLevel2}`}>
                                <ListItemText>
                                  <AccordionDropArea
                                    index={indexLevel2 + 1}
                                    loading={
                                      parentLoading === subId &&
                                      indexLoading === indexLevel2 + 1
                                    }
                                    onDrop={handleDrop(subId!)}
                                    size="smaller"
                                  />
                                </ListItemText>
                              </ListItem>
                            )}
                          </>
                        )
                      )}
                    </List>
                  </Container>
                </Collapse>
              )}
              <ListItem key={`drop-${indexLevel1}`}>
                <ListItemText>
                  {canDragMenuItems && (
                    <AccordionDropArea
                      index={indexLevel1 + 1}
                      loading={
                        parentLoading === id && indexLoading === indexLevel1 + 1
                      }
                      onDrop={handleDrop(id!)}
                      size="small"
                    />
                  )}
                </ListItemText>
              </ListItem>
            </>
          )
        )}
    </List>
  );
};

export default AccordionMenuContent;
