import { put } from "@redux-saga/core/effects";
import { AsyncSingleValue, SomeOrVoid } from "async-lifecycle-saga";
import {
  AsyncAction,
  Details,
  Success,
} from "async-lifecycle-saga/dist/models";
import { call } from "redux-saga/effects";

import {
  clearTokens,
  setGAKToken,
  setRefreshToken,
} from "../../../../utils/SecurityUtils";
import { LogOutReason, logOutReasonSessionStorageKey } from "../../../models";
import { reflexoHistory } from "../../../router";
import { appCell } from "../app/cells";
import { profileCell } from "../profile/cells";
import { authenticationCell, authenticationPossessionCell } from "./cells";
import { AuthenticationResponseModel, KnowledgeRequestModel } from "./models";
import { authenticationTwoFactorSetupStartCell } from "./twoFactor/cells";

/* General */
export function* authenticationAuthenticated({
  result,
}: AsyncAction<
  unknown,
  AuthenticationResponseModel,
  AsyncSingleValue<AuthenticationResponseModel>
>) {
  const {
    body: {
      jwt: { refreshToken, refreshTokenExpires } = {
        refreshToken: "",
        refreshTokenExpires: new Date(),
      },
    },
  } = result as Success<AuthenticationResponseModel>;
  yield call(setRefreshToken, refreshToken, refreshTokenExpires);
  yield put(profileCell.require());
}

export function* authenticationError({
  result,
}: AsyncAction<unknown, SomeOrVoid, AsyncSingleValue<SomeOrVoid>>) {
  const { status } = result as Details;
  yield call(alert, status);
}

export function* authenticationRequireTwoFactor() {
  yield call(reflexoHistory.push, "/twofactorauth");
}

export function* authenticationSuccess({
  type,
  result,
}: AsyncAction<
  unknown,
  AuthenticationResponseModel,
  AsyncSingleValue<AuthenticationResponseModel>
>): Generator {
  if (!(result as Success<AuthenticationResponseModel>).body) {
    throw new Error("No authentication result.");
  }
  if (
    type === authenticationPossessionCell.events.success &&
    (result as Success<AuthenticationResponseModel>).body.status === "denied"
  ) {
    return; // let the UI warn the user that the TOTP value is invalid by using the callback function
  }
  /*
   * Intermediate Saga because Knowledge Success
   * may not mean authentication has completed. (e.g. 2FA required)
   */
  yield put(
    authenticationCell.authenticated(
      result as Success<AuthenticationResponseModel>
    )
  );
}

export function* authenticationUnauthorized() {
  yield put(appCell.clear());
  reflexoHistory.push("/");
}

/* Knowledge */
export function* authenticationKnowledgeSuccess({
  result,
}: AsyncAction<
  KnowledgeRequestModel,
  AuthenticationResponseModel,
  AsyncSingleValue<AuthenticationResponseModel>
>) {
  const success = result as Success<AuthenticationResponseModel>;
  const { httpStatus, knowledgeToken, status } = {
    httpStatus: success.status,
    knowledgeToken: success.body.googleAuthenticator?.knowledgeToken || "",
    status: success.body.status,
  };
  switch (status) {
    case "authenticated":
      yield put(
        authenticationCell.authenticated(
          result as Success<AuthenticationResponseModel>
        )
      );
      break;
    case "denied":
      yield put(authenticationCell.unauthorized());
      break;
    case "twoFactorAuthenticationNotSetup":
      setGAKToken(knowledgeToken);
      yield put(authenticationTwoFactorSetupStartCell.require());
      break;
    case "twoFactorAuthenticationRequired":
      setGAKToken(knowledgeToken);
      yield put(authenticationCell.twoFactorRequired());
      break;
    case "error":
    default:
      yield put(
        authenticationCell.error({
          title: "Authentication error",
          detail: `Status ${status}`,
          status: httpStatus,
        })
      );
      break;
  }
}

/* Log out */
export function* authenticationLogOut() {
  clearTokens();
  sessionStorage.setItem(
    logOutReasonSessionStorageKey,
    LogOutReason.requestedByUser
  );
  yield put(appCell.clear());
}
