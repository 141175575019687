import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { microsoft365LoginUriCell } from "../../../business/redux/saga/authentication/microsoft365/cells";
import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  tryAgain?: boolean;
}

const Microsoft365LoginButton = (props: Props) => {
  const { tryAgain } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      microsoft365LoginUriCell.require({
        onSuccess: ({ body: { uri } }) => {
          window.location.href = uri;
        },
      })
    );
  };

  return (
    <Button onClick={handleClick} variant="contained" color="primary">
      {!tryAgain && t("Authentication:Inloggen met Microsoft Entra ID")}
      {tryAgain && t("Authentication:Opnieuw proberen")}
    </Button>
  );
};

export default Microsoft365LoginButton;
