import { IconButton, Tooltip } from "@material-ui/core";
import { InsertPhoto as InsertPhotoIcon } from "@material-ui/icons";
import React, { memo, useCallback } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";

interface Props {
  id: number;
  documentName: string;

  onInsert(id: number, documentName: string): void;
}

const ContentNodeDocumentListItemInsertButton = (props: Props) => {
  const { id, documentName, onInsert } = props;
  const [t] = useTranslationStrict();

  const handleInsert = useCallback(() => {
    onInsert(id, documentName);
  }, [id, documentName, onInsert]);

  return (
    <Tooltip title={t("Common:Insert")}>
      <IconButton onClick={handleInsert} size="small">
        <InsertPhotoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(ContentNodeDocumentListItemInsertButton);
