import { Grid, Typography } from "@material-ui/core";

import { useTranslationStrict } from "../../../../globalization/i18n";

const WlzYesNoFieldDisplay = (props: { name: string; value: boolean }) => {
  const { name, value } = props;
  const [t] = useTranslationStrict();

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Typography variant="caption">{name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">
          {value ? t("Common:Yes") : t("Common:No")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WlzYesNoFieldDisplay;
