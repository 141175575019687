import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  RefreshOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@material-ui/icons";
import { Details } from "async-lifecycle-saga/dist/models";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { EmployerModel } from "../../business/models";
import {
  enienlCalculateCell,
  enienlRegisterCell,
} from "../../business/redux/saga/admin/enienl/cells";
import { StoreModel } from "../../business/redux/saga/models";
import { useTranslationStrict } from "../../globalization/i18n";
import { formatEuro, formatInteger } from "../../utils/NumberUtils";
import EuroInput from "../input/EuroInput";

interface Props {
  employers: EmployerModel[];
}

const initialValues = {
  brutoSalaris: 2_000,
  brutoKosten: 60,
  contractFlexibel: false,
  werkgever: "",
  personeelsnummer: "",
  email: "",
  voorletters: "",
  tussenvoegsel: "",
  achternaam: "",
};

const GreenChoice = ({ employers }: Props) => {
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        brutoSalaris: yup.number().required(t("Forms:Required")),
        brutoKosten: yup.string().required(t("Forms:Required")),
        contractFlexibel: yup.boolean().required(t("Forms:Required")),
        werkgever: yup.string().required(t("Forms:Required")),
        personeelsnummer: yup.string().required(t("Forms:Required")),
        email: yup
          .string()
          .required(t("Forms:Required"))
          .matches(/^.+@.+\.\w+$/, t("Admin:EmailInvalid") as string),
        voorletters: yup.string().required(t("Forms:Required")),
        tussenvoegsel: yup.string(),
        achternaam: yup.string().required(t("Forms:Required")),
      }),
    [t]
  );
  const dispatch = useDispatch();
  const { calculate } = useSelector((store: StoreModel) => store.admin.enienl);

  if (employers.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        dispatch(
          enienlRegisterCell.require(
            { choice: values, employee: values },
            {
              onFail: (details: Details) => {
                enqueueSnackbar(details.detail || "NOK", {
                  variant: "error",
                });
                actions.setSubmitting(false);
              },
              onSuccess: () => {
                enqueueSnackbar("OK", {
                  variant: "success",
                });
                actions.resetForm();
                dispatch({ type: enienlCalculateCell.events.clear });
                dispatch({ type: enienlRegisterCell.events.clear });
              },
            }
          )
        );
      }}
      render={({
        handleChange,
        isSubmitting,
        isValid,
        values,
        setValues,
        errors,
        resetForm,
      }) => {
        const handleCalculate = () =>
          dispatch(enienlCalculateCell.require(values));
        const handleReset = () => {
          resetForm();
          dispatch({ type: enienlCalculateCell.events.clear });
        };
        return (
          <Form>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("Admin:GreenChoiceCalculation")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <EuroInput
                  error={"brutoKosten" in errors}
                  name="brutoKosten"
                  value={values.brutoKosten}
                  label={t("Admin:GreenChoiceBrutoKosten")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EuroInput
                  error={"brutoSalaris" in errors}
                  name="brutoSalaris"
                  value={values.brutoSalaris}
                  label={t("Admin:GreenChoiceBrutoSalaris")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <InputLabel>{t("Admin:GreenChoiceContract")}</InputLabel>
                  <Select
                    name="contractFlexibel"
                    value={values.contractFlexibel}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        contractFlexibel: e.target.value === "true",
                      })
                    }
                    style={{ minWidth: 196 }}
                  >
                    <MenuItem value="false" selected={!values.contractFlexibel}>
                      {t("Admin:GreenChoiceContractVast")}
                    </MenuItem>
                    <MenuItem value="true" selected={values.contractFlexibel}>
                      {t("Admin:GreenChoiceContractFlexibel")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <InputLabel>{t("Admin:GreenChoiceWerkgever")}</InputLabel>
                  <Select
                    name="werkgever"
                    value={values.werkgever}
                    onChange={handleChange}
                    style={{ minWidth: 196 }}
                  >
                    {employers.map((e) => (
                      <MenuItem
                        key={e.id}
                        value={e.name}
                        selected={e.name === values.werkgever}
                      >
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={
                    calculate.value?.nettoVoordeel
                      ? formatEuro(calculate.value!.salarisIngehouden)
                      : ""
                  }
                  label={t("Admin:GreenChoiceWithheld")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={
                    calculate.value?.nettoVoordeel
                      ? `${formatInteger(
                          Math.round(
                            (100 * calculate.value!.nettoVoordeel) /
                              calculate.value!.brutoKosten
                          ),
                          ""
                        )}% = ${formatEuro(calculate.value!.nettoVoordeel)}`
                      : ""
                  }
                  label={t("Admin:GreenChoiceBenefit")}
                />
                <Button
                  color="secondary"
                  style={{ marginTop: 8 }}
                  onClick={handleCalculate}
                  type="button"
                >
                  <RefreshOutlined />
                </Button>
              </Grid>
              <Divider
                color="secondary"
                style={{ margin: "16px 0", width: "100%" }}
              />
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("Admin:GreenChoicePersonalia")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={"personeelsnummer" in errors}
                  name="personeelsnummer"
                  value={values.personeelsnummer}
                  label={t("Admin:GreenChoicePersoneelsnummer")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={"email" in errors}
                  name="email"
                  value={values.email}
                  label={t("Admin:Email")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  error={"voorletters" in errors}
                  name="voorletters"
                  value={values.voorletters}
                  label={t("Admin:GreenChoiceVoorletters")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  error={"tussenvoegsel" in errors}
                  name="tussenvoegsel"
                  value={values.tussenvoegsel}
                  label={t("Admin:GreenChoiceTussenvoegsel")}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  error={"achternaam" in errors}
                  name="achternaam"
                  value={values.achternaam}
                  label={t("Admin:GreenChoiceAchternaam")}
                  onChange={handleChange}
                />
              </Grid>
              <Divider
                color="secondary"
                style={{ margin: "16px 0", width: "100%" }}
              />
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  style={{ marginTop: 8 }}
                  type="submit"
                >
                  <SaveOutlined />
                  {t("Admin:GreenChoiceRegister")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button style={{ marginTop: 8 }} onClick={handleReset}>
                  <UndoOutlined />
                  {t("Admin:GreenChoiceReset")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    />
  );
};

export default GreenChoice;
