import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { personalWlzAdviceCasesCell } from "../../../business/redux/saga/personal/wlz/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { WlzAdviceCasesTable } from "./";

interface Props {
  navigationNodeId: number;
}

const WlzAdviceView = (props: Props) => {
  const { navigationNodeId } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(personalWlzAdviceCasesCell.require());
  }, [dispatch]);

  return (
    <section>
      <Typography variant="h5">{t("Wlz:WLZ Adviezen")}</Typography>
      <WlzAdviceCasesTable navigationNodeId={navigationNodeId} />
    </section>
  );
};

export default WlzAdviceView;
