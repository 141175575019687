import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const usePageWrap = makeStyles((theme: Theme) => ({
  outer: {
    position: "relative",
    textAlign: "center",
    width: `calc(100% - ${theme.spacing() * 4}px)`,
    paddingLeft: theme.spacing() * 2,
    paddingRight: theme.spacing() * 2,
  },
  inner: {
    display: "inline-block",
    position: "relative",
    textAlign: "left",
    width: "100%",
    maxWidth: 1370 - theme.spacing() * 20,
  },
}));

export default usePageWrap;
