import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, KeyboardEvent, useState } from "react";

import { useTranslationStrict } from "../../../globalization/i18n";
import useStyles from "./AuthenticationTwoFactorCreateSecret.styles";

interface TwoFactorAuthenticatorCreateSecretProps {
  onSubmit: () => void;
  onTokenToVerifyChanged: (tokenToVerifyValue: string) => void;
  secretDataUrl?: string;
  secretText?: string;
  tokenToVerify?: string;
  tokenToVerifyIsValid?: boolean;
}

const AuthenticationTwoFactorCreateSecret = ({
  onSubmit,
  onTokenToVerifyChanged,
  secretDataUrl,
  secretText,
  tokenToVerify,
  tokenToVerifyIsValid,
}: TwoFactorAuthenticatorCreateSecretProps) => {
  const [t] = useTranslationStrict();
  const [isShowSecretText, setIsShowSecretText] = useState(false);
  const { root } = useStyles();

  const handleTokenToVerifyChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onTokenToVerifyChanged(e.currentTarget.value);
  };

  const handleTokenToVerifyKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Grid classes={{ root }} container direction="column" spacing={8}>
      <Grid item>
        <Typography component="p">
          {t("User:TwoFactorAuthenticationGoogleAuthenticatorSecret")}
        </Typography>
      </Grid>
      <Grid item container direction="row" spacing={8}>
        <Grid
          item
          container
          direction="column"
          spacing={8}
          xs={6}
          sm={6}
          md={6}
          lg={6}
        >
          <Grid item>
            <img alt="QR Code" src={secretDataUrl} height={196} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          item
          spacing={2}
          xs={6}
          sm={6}
          md={6}
          lg={6}
        >
          <Grid item>
            <FormControl>
              <FormLabel>{t("User:Verification")}</FormLabel>
              <TextField
                autoFocus
                error={!tokenToVerifyIsValid}
                placeholder="012345"
                value={tokenToVerify}
                onChange={handleTokenToVerifyChanged}
                onKeyPress={handleTokenToVerifyKeyPress}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              {t("User:Verification")}
            </Button>
          </Grid>
          <Grid item>
            <Grid>
              {isShowSecretText && (
                <>
                  <Typography variant="subtitle2">
                    {t(
                      "User:TwoFactorAuthenticationGoogleAuthenticatorSecretTextInstructions"
                    )}
                  </Typography>
                  <Typography variant="caption">{secretText}</Typography>
                </>
              )}
              {!isShowSecretText && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={(): void => {
                    setIsShowSecretText(true);
                  }}
                >
                  {t(
                    "User:TwoFactorAuthenticationGoogleAuthenticatorShowSecretTextLink"
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthenticationTwoFactorCreateSecret;
