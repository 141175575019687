import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { WlzAdviceCase } from "../../../../business/personal/wlz/models";
import {
  personalWlzAdviceCaseUpsertCell,
  personalWlzAdviceCasesCell,
} from "../../../../business/redux/saga/personal/wlz/cells";
import { useTranslationStrict } from "../../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../../ux";
import { WlzAdviceCaseWizard } from "./";

interface Props {
  adviceCaseId?: number;
  navigationNodeId: number;
  open: boolean;
  onSubmitted(): void;
}

const WlzAdviceCaseDialog = (props: Props) => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { adviceCaseId, navigationNodeId, onSubmitted, open } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

  const handleCancel = () => {
    dispatch(personalWlzAdviceCasesCell.require());
    onSubmitted();
  };
  const handleSubmit = (values: WlzAdviceCase) => {
    dispatch(
      personalWlzAdviceCaseUpsertCell.require(values, {
        onFail: showErrorMessageFromDetails(
          t(
            adviceCaseId
              ? "Wlz:WLZ Advies Casus bijwerken mislukt."
              : "Wlz:WLZ Advies Casus toevoegen mislukt."
          )
        ),
        onSuccess: () => {
          enqueueSnackbar(
            t(
              adviceCaseId
                ? "Wlz:WLZ Advies Casus bijgewerkt."
                : "Wlz:WLZ Advies Casus toegevoegd."
            ),
            {
              variant: "success",
            }
          );
          onSubmitted();
        },
      })
    );
  };

  return (
    <Dialog fullWidth open={open} maxWidth="md">
      <DialogTitle>{t("Wlz:WLZ Advies Casus Wizard")}</DialogTitle>
      <DialogContent>
        <WlzAdviceCaseWizard
          adviceCaseId={adviceCaseId}
          navigationNodeId={navigationNodeId}
          onCancel={handleCancel}
          onFinish={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WlzAdviceCaseDialog;
