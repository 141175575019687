export interface ValidationResult<T> {
  valid: boolean;
  value?: T;
  message?: string;
}

const bsnTest = [9, 8, 7, 6, 5, 4, 3, 2, -1];

export const validationMessages = {
  BsnIncorrectLength: "Bsn.IncorrectLength",
  BsnDigitsOnly: "Bsn.BsnDigitsOnly",
  BsnStartsWithMultipleZeros: "Bsn.StartsWithMultipleZeros",
  BsnIncorrectElevenCheck: "Bsn.IncorrectElevenCheck",
  EmailInvalid: "Email.Invalid",
  ZipcodeInvalid: "Zipcode.Invalid",
  IbanInvalidFormat: "Iban.InvalidFormat",
  IbanInvalidChecksum: "Iban.IbanInvalidChecksum",
  LoginInvalidFormat: "Login.InvalidFormat",
};

export const validateBsn = (value: string): ValidationResult<string> => {
  let bsn;
  switch (value!.length) {
    case 8:
      bsn = `0${value}`;
      break;
    case 9:
      bsn = value;
      break;
    default:
      return {
        valid: false,
        message: validationMessages.BsnIncorrectLength,
      };
  }

  if (!/^(\d+)$/.test(bsn)) {
    return { valid: false, message: validationMessages.BsnDigitsOnly };
  }

  if ([...bsn.slice(0, 2)].every((c) => c === "0")) {
    return {
      valid: false,
      message: validationMessages.BsnStartsWithMultipleZeros,
    };
  }

  if (
    [...bsn]
      .map((c, idx) => Number(c) * bsnTest[idx])
      .reduce((sum, current) => sum + current, 0) %
      11 !==
    0
  ) {
    return {
      valid: false,
      message: validationMessages.BsnIncorrectElevenCheck,
    };
  }

  return { valid: true, value: bsn };
};

export const validateEmail = (value: string): ValidationResult<string> => {
  const validate = (input: string): boolean =>
    /^[^@\s]+@[^@\s]+\.(?<tld>[^@\s]{2,})$/.test(input);
  const splitChars = /(\s|,|;)+/;
  const isList = splitChars.test(value);
  if (isList) {
    if (
      value
        .split(splitChars)
        .filter((s) => !splitChars.test(s))
        .some((e) => !validate(e))
    ) {
      return { valid: false, message: validationMessages.EmailInvalid };
    }

    return { valid: true, value };
  }

  if (!validate(value)) {
    return { valid: false, message: validationMessages.EmailInvalid };
  }

  return { valid: true, value };
};

export const validateZipcode = (value: string): ValidationResult<string> => {
  const validate = (input: string): boolean =>
    /^(?<n>[1-9]\d{3,4})\s*(?<a>[a-z]{2})?$/i.test(input);
  if (!validate(value)) {
    return { valid: false, message: validationMessages.ZipcodeInvalid };
  }

  return { valid: true, value: value.toUpperCase() };
};

export const validateIban = (value: string): ValidationResult<string> => {
  const cleanInput = value.replace(/\s+/g, "");
  const match = cleanInput.match(/^([A-Z]{2}\d{2})([A-Z0-9]{1,30})$/);
  if (!match || match.length === 0) {
    return { valid: false, message: validationMessages.IbanInvalidFormat };
  }

  const number = (match[2] + match[1]).replace(
    /[A-Z]/g,
    (ch: string) => `${ch.charCodeAt(0) - 55}`
  );

  if (BigInt(number) % BigInt(97) !== BigInt(1)) {
    return { valid: false, message: validationMessages.IbanInvalidChecksum };
  }

  return { valid: true, value: cleanInput };
};

export const validateLogin = (value: string): ValidationResult<string> => {
  if (
    value &&
    (!/[a-z]/.test(value.charAt(0)) ||
      ![...value].every(
        (c) =>
          c.charCodeAt(0) < 128 &&
          (c === "_" || /\d/.test(c) || /[a-z]/.test(c))
      ))
  ) {
    return { valid: false, message: validationMessages.LoginInvalidFormat };
  }

  return { valid: true, value };
};
