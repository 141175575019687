import { MuiThemeProvider, Theme } from "@material-ui/core";
import {
  blue,
  blueGrey,
  brown,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  pink,
  purple,
  red,
  teal,
} from "@material-ui/core/colors";
import { createTheme as muiCreateTheme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";
import React, { memo, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { defaultMemoize } from "reselect";

import { emptyOrganization } from "../business/models";
import { StoreModel } from "../business/redux/saga/models";

const createTheme = (palette: PaletteOptions): Theme =>
  muiCreateTheme({
    palette: {
      secondary: blueGrey,
      error: {
        main: red["600"],
      },
      contrastThreshold: 2,
      ...palette,
    },
  });

interface AppTheme {
  name: string;
  palette: PaletteOptions;
}

export const appThemes: AppTheme[] = [
  {
    name: "blue",
    palette: {
      primary: blue,
    },
  },
  {
    name: "green",
    palette: {
      primary: green,
    },
  },
  {
    name: "teal",
    palette: {
      primary: teal,
    },
  },
  {
    name: "indigo",
    palette: {
      primary: indigo,
    },
  },
  {
    name: "brown",
    palette: {
      primary: brown,
    },
  },
  {
    name: "deepPurple",
    palette: {
      primary: deepPurple,
    },
  },
  {
    name: "blueGrey",
    palette: {
      primary: blueGrey,
    },
  },
  {
    name: "red",
    palette: {
      primary: red,
    },
  },
  {
    name: "deepOrange",
    palette: {
      primary: deepOrange,
    },
  },
  {
    name: "purple",
    palette: {
      primary: purple,
    },
  },
  {
    name: "pink",
    palette: {
      primary: pink,
    },
  },
  {
    name: "grey",
    palette: {
      primary: grey,
    },
  },
];

let theme: Theme = createTheme(appThemes[0].palette);

export const getTheme = (): Theme => theme;

export const setTheme = (appTheme: AppTheme, styles?: unknown): void => {
  theme = createTheme({ ...appTheme.palette, ...(styles as PaletteOptions) });
};

export const withThemeProvider = (
  element: React.ReactElement
): React.ReactElement => (
  <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
);

const selectTheme = defaultMemoize(
  (index: number, styles: PaletteOptions): Theme =>
    createTheme({ ...appThemes[index].palette, ...styles })
);

const useStyles = makeStyles(
  ({ palette }: Theme): Record<string, CSSProperties> => ({
    link: {
      "& a": {
        color: palette.primary.main,
        textDecoration: "none",
        fontWeight: 400,
      },
    },
  })
);

const ThemeStyle = memo(
  ({ children }: { children: React.ReactElement }): JSX.Element => {
    const { link } = useStyles();
    return <div className={link}>{children}</div>;
  }
);

interface InnerThemeProps {
  appTheme: number;
  children: React.ReactElement;
  appStyles: PaletteOptions;
}
// eslint-disable-next-line react/no-multi-comp
const InnerThemeProvider = memo(
  ({ appTheme, appStyles, children }: InnerThemeProps): JSX.Element => {
    const muiTheme = useMemo(
      (): Theme => selectTheme(appTheme, appStyles),
      [appTheme, appStyles]
    );
    useEffect((): void => {
      theme = muiTheme;
    }, [muiTheme]);
    return (
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={muiTheme}>
          <ThemeStyle>{children}</ThemeStyle>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
);

export const ConnectedThemeProvider = connect(
  ({
    organization: {
      get: { value: { appTheme, appStyles } = emptyOrganization },
    },
  }: StoreModel): { appTheme: number; appStyles: object } => ({
    appTheme,
    appStyles: appStyles as PaletteOptions,
  })
)(InnerThemeProvider);
