import {
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import {
  DragEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { adminNavigationNodePruneCell } from "../../business/redux/saga/admin/navigation/cells";
import AppBarDropArea from "../admin/navigation/appBar/AppBarDropArea";
import { useSnackbarErrorMessage } from "../ux";
import useStyles from "./AppBarMenuItem.styles";

export interface AppBarMenuItemOptionProps {
  caption: string | JSX.Element;
  draggable?: boolean;
  level: number;
  nodeId: number;
  options?: AppBarMenuItemOptionProps[];
  route: string;

  onClick(route: string): void;
}

const AppBarMenuItemOption = ({
  caption,
  draggable,
  level,
  nodeId,
  onClick,
  options,
  route,
}: AppBarMenuItemOptionProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const history = useHistory();
  const classes = useStyles();
  const menuItemRef = useRef<HTMLLIElement | null>(null);
  const [childrenOpen, setChildrenOpen] = useState(false);
  const [isActiveRoute, setIsActiveRoute] = useState(false);
  const [parentIdLoading, setParentIdLoading] = useState<number | null>(null);
  const [indexLoading, setIndexLoading] = useState<number | null>(null);

  const determineIsActiveRoute = useCallback(
    (pathname: string) => {
      setIsActiveRoute(pathname === route);
    },
    [route]
  );

  history.listen(({ pathname }) => {
    determineIsActiveRoute(pathname);
  });

  const handleDragStart: (childId: number) => DragEventHandler = useCallback(
    (childId) => (event) => {
      event.dataTransfer.setData("text", childId.toString());
    },
    []
  );

  const handleDrag: DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (parentId: number) => (childId: number, index: number) => {
      if (parentId === childId) {
        enqueueSnackbar(
          t("Kan pagina niet onder zichzelf plaatsen.", { ns: "Admin" }),
          { variant: "warning" }
        );
        return;
      }

      setParentIdLoading(parentId);
      setIndexLoading(index);
      dispatch(
        adminNavigationNodePruneCell.require(
          {
            parentIsRoot: false,
            childId,
            parentId,
            index,
          },
          {
            onFail: (details) => {
              setParentIdLoading(null);
              setIndexLoading(null);
              showErrorMessageFromDetails(
                t("Fout bij verplaatsen pagina", { ns: "Admin" })
              )(details);
            },
            onSuccess: () => {
              setParentIdLoading(null);
              setIndexLoading(null);
            },
          }
        )
      );
    },
    [dispatch, enqueueSnackbar, showErrorMessageFromDetails, t]
  );

  const handleMenuItemClick = () => {
    onClick(route);
  };

  useEffect(() => {
    determineIsActiveRoute(window.location.pathname);
  }, [determineIsActiveRoute]);

  return (
    <>
      <ListItem
        key={route}
        className={classes.menuItem}
        onClick={handleMenuItemClick}
        ref={menuItemRef}
        selected={isActiveRoute}
      >
        <ListItemText
          draggable={draggable}
          onDragStart={handleDragStart(nodeId)}
          onDrag={handleDrag}
          primary={caption}
          primaryTypographyProps={{ className: classes.menuItemLink }}
        />
        <ListItemSecondaryAction className={classes.menuItemSecondaryAction}>
          {((options && options.length > 0) || (draggable && level < 2)) &&
            (childrenOpen ? (
              <IconButton
                onClick={() => {
                  setChildrenOpen(false);
                }}
              >
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setChildrenOpen(true);
                }}
              >
                <ExpandMore />
              </IconButton>
            ))}
        </ListItemSecondaryAction>
      </ListItem>
      {childrenOpen &&
        ((options && options.length > 0) || (draggable && level < 2)) && (
          <Collapse in={childrenOpen} timeout="auto" unmountOnExit>
            <Container>
              <List>
                {draggable && level < 2 && (
                  <ListItem>
                    <ListItemText>
                      <AppBarDropArea
                        key="first"
                        index={0}
                        loading={
                          parentIdLoading === nodeId && indexLoading === 0
                        }
                        onDrop={handleDrop(nodeId)}
                        variant="sub"
                      />
                    </ListItemText>
                  </ListItem>
                )}
                {[
                  ...(options?.map((item, subIndex) => (
                    <>
                      <AppBarMenuItemOption
                        key={item.route}
                        {...item}
                        draggable={draggable}
                        level={level + 1}
                        onClick={onClick}
                      />
                      {draggable && (
                        <ListItem>
                          <ListItemText>
                            <AppBarDropArea
                              key={item.nodeId}
                              index={subIndex + 1}
                              loading={
                                parentIdLoading === nodeId &&
                                indexLoading === subIndex + 1
                              }
                              onDrop={handleDrop(nodeId)}
                              variant="sub"
                            />
                          </ListItemText>
                        </ListItem>
                      )}
                    </>
                  )) || []),
                ]}
              </List>
            </Container>
          </Collapse>
        )}
    </>
  );
};

export default AppBarMenuItemOption;
