import { Card, CardContent } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DataDefinitionGroup } from "../../../business/models";
import { datacockpitDataDefinitionsCell } from "../../../business/redux/saga/datacockpit/cells";
import { useEmployeeData } from "../../../business/redux/saga/datacockpit/hooks";
import { StoreModel } from "../../../business/redux/saga/models";
import {
  EmployeeDataFilter,
  EmployeeDataView,
} from "../../datacockpit/employee";
import useStyles from "../admin/AdminPage.styles";

const EmployeePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filter, edit } = useSelector(
    (store: StoreModel) => store.datacockpit.employeeData
  );

  const employeeData = useEmployeeData();

  useEffect(() => {
    dispatch(datacockpitDataDefinitionsCell.require());
  }, [dispatch]);

  return (
    <div className={classes.gridWideContent}>
      <Card>
        <CardContent>
          <EmployeeDataFilter />
        </CardContent>
      </Card>
      {filter.employeeId && filter.group !== DataDefinitionGroup.Geen && (
        <Card classes={{ root: classes.card }}>
          <CardContent>
            <EmployeeDataView
              employeeData={employeeData}
              edit={Boolean(edit)}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default EmployeePage;
