import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { memo, useMemo, useState } from "react";

import { LinkContentNodeModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import ensureValidUrl from "../../../utils/LinkUtils";
import useStyles from "../contentStyles";

interface Props {
  contentNode: LinkContentNodeModel;
  onCancel(): void;
  onSave(link: LinkContentNodeModel, onSuccess: () => void): void;
}

const ContentNodeLinkEditor = (props: Props) => {
  const { contentNode, onCancel, onSave } = props;
  const [t] = useTranslationStrict();
  const classes = useStyles();

  const [linkTitle, setLinkTitle] = useState<string>(contentNode?.title || "");
  const handleLinkTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLinkTitle(event.currentTarget.value);
  };

  const [linkUrl, setLinkUrl] = useState<string>(contentNode?.link || "");
  const handleLinkUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLinkUrl(event.currentTarget.value);
  };

  const getLink = (): LinkContentNodeModel => ({
    position: -1,
    title: linkTitle,
    link: ensureValidUrl(linkUrl),
  });

  const handleSaveClick = () => {
    onSave?.(getLink(), () => {
      setLinkTitle("");
      setLinkUrl("");
    });
  };

  const isDirty = useMemo(
    () => contentNode?.title !== linkTitle || contentNode?.link !== linkUrl,
    [contentNode?.link, contentNode?.title, linkTitle, linkUrl]
  );

  return (
    <Grid container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="h5">
          {contentNode ? t("Common:Edit") : t("Admin:ContentNodeAddLinkHeader")}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={8}>
          <Grid item>
            <FormControl>
              <FormLabel>{t("Admin:ContentNodeTitle")}</FormLabel>
              <TextField value={linkTitle} onChange={handleLinkTitleChange} />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <FormLabel>{t("Admin:ContentNodeLink")}</FormLabel>
              <TextField value={linkUrl} onChange={handleLinkUrlChange} />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            onClick={handleSaveClick}
            className={isDirty ? classes.saveButtonEditing : undefined}
            disabled={!isDirty}
          >
            {contentNode ? t("Common:Save") : t("Common:Add")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {t("Common:Cancel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(ContentNodeLinkEditor);
