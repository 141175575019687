import { createActor, createSingle } from "async-lifecycle-saga";
import { call, takeEvery } from "redux-saga/effects";

import { asyncGet, asyncPost } from "../../../../api";
import { reflexoHistory } from "../../../../router";
import { StoreModel } from "../../models";
import { AuthenticationResponseModel } from "../models";
import {
  AuthenticationTwoFactorAddSecretRequest,
  AuthenticationTwoFactorAddSecretResponse,
  AuthenticationTwoFactorSetupFinishRequest,
  AuthenticationTwoFactorToggleResponse,
  AuthenticationTwoFactorValidateRequest,
  AuthenticationTwoFactorValidateResponse,
} from "./models";

export const authenticationTwoFactorAddSecretCell = createActor<
  AuthenticationTwoFactorAddSecretRequest,
  AuthenticationTwoFactorAddSecretResponse
>({
  path: ["authentication", "twoFactor", "addSecret"],
  api: (request) =>
    asyncPost("/api/authentication/googleAuthenticator/add", request),
});

const authenticationTwoFactorSetupStartRequire =
  "AUTHENTICATION_TWOFACTOR_SETUPSTART_REQUIRE";
export const authenticationTwoFactorSetupStartCell = {
  events: {
    require: authenticationTwoFactorSetupStartRequire,
  },
  require: () => ({
    type: authenticationTwoFactorSetupStartCell.events.require,
  }),
  sagas: [
    takeEvery(authenticationTwoFactorSetupStartRequire, function* () {
      yield call(reflexoHistory.push, "/twofactorauthsetup");
    }),
  ],
};

export const authenticationTwoFactorSetupFinishCell = createActor<
  AuthenticationTwoFactorSetupFinishRequest,
  AuthenticationResponseModel
>({
  path: ["authentication", "twoFactor", "setupFinish"],
  api: (request) =>
    asyncPost("/api/authentication/googleAuthenticator/setup", request, true),
});

export const authenticationTwoFactorDisableCell = createActor<
  void,
  AuthenticationTwoFactorToggleResponse
>({
  path: ["authentication", "twoFactor", "disable"],
  api: () => asyncPost("/api/authentication/googleAuthenticator/disable", {}),
});

export const authenticationTwoFactorEnableCell = createActor<
  void,
  AuthenticationTwoFactorToggleResponse
>({
  path: ["authentication", "twoFactor", "enable"],
  api: () => asyncPost("/api/authentication/googleAuthenticator/enable", {}),
});

export const authenticationTwoFactorSettingsCell = createSingle<
  AuthenticationTwoFactorToggleResponse,
  StoreModel
>({
  path: ["authentication", "twoFactor", "settings"],
  require: {
    api: () => asyncGet("/api/authentication/googleAuthenticator/settings"),
  },
});

export const authenticationTwoFactorValidateCell = createActor<
  AuthenticationTwoFactorValidateRequest,
  AuthenticationTwoFactorValidateResponse
>({
  path: ["authentication", "twoFactor", "validate"],
  api: (request) =>
    asyncPost("/api/authentication/googleAuthenticator/validate", request),
});

export const authenticationTwoFactorSagas = [
  /* Add Secret */
  ...authenticationTwoFactorAddSecretCell.sagas,

  /* Setup */
  ...authenticationTwoFactorSetupStartCell.sagas,
  ...authenticationTwoFactorSetupFinishCell.sagas,

  /* Toggle */
  ...authenticationTwoFactorDisableCell.sagas,
  ...authenticationTwoFactorEnableCell.sagas,

  /* Settings */
  ...authenticationTwoFactorSettingsCell.sagas,

  /* Validate */
  ...authenticationTwoFactorValidateCell.sagas,
];
