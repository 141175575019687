import {
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { EmployerModel, ModuleOption } from "../../../../business/models";
import { adminEmployersListCell } from "../../../../business/redux/saga/admin/employers/cells";
import useConfirm from "../../../../confirm";
import PercentageInput from "../../../input/PercentageInput";

interface SettingsEmployerTabPanelProps {
  employer: EmployerModel;
  index: number;

  onChange(index: number, employer: EmployerModel): void;
  onDelete(): void;
}

const SettingsEmployerTabPanel = (props: SettingsEmployerTabPanelProps) => {
  const {
    employer,
    employer: {
      code,
      government,
      isSmall,
      name,
      whkRate,
      whkRateEmployee,
      fulltimeHours,
      benefitCalculation,
      benefitShared,
      benefitSharedTaxExempt,
      benefitSharedDispensated,
    },
    index,
    onChange,
    onDelete,
  } = props;

  const { confirmAction } = useConfirm();
  const [t] = useTranslation();

  const handleDeleteClick = useCallback(() => {
    confirmAction(
      adminEmployersListCell.delete(employer.id, {
        onSuccess: () => {
          onDelete();
        },
      })
    );
  }, [confirmAction, employer, onDelete]);

  return (
    <TabPanel key={index} value={index.toString()}>
      <Grid container direction="column" spacing={1}>
        <Grid container item direction="row" spacing={2}>
          <Grid item>
            <TextField
              name="code"
              label={t("Admin:Code")}
              value={code ?? t("Admin:CodeDefault")}
              onChange={(e) =>
                onChange(index, {
                  ...employer,
                  code: e.currentTarget.value,
                })
              }
              disabled={code === undefined}
            />
          </Grid>
          <Grid item>
            <TextField
              name="name"
              label={t("Admin:Name")}
              value={name}
              onChange={(e) =>
                onChange(index, {
                  ...employer,
                  name: e.currentTarget.value,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={government}
                onChange={(_, checked): void =>
                  onChange(index, { ...employer, government: checked })
                }
              />
            }
            label={t("Admin:Government")}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isSmall}
                onChange={(_, checked): void =>
                  onChange(index, { ...employer, isSmall: checked })
                }
              />
            }
            label={t("Admin:IsSmall")}
          />
        </Grid>
        <Grid item>
          <PercentageInput
            name="whkRate"
            onChange={({ currentTarget: { value } }) =>
              onChange(index, { ...employer, whkRate: parseInt(value) / 100 })
            }
            value={whkRate}
            label={t("Admin:WhkRate")}
          />
        </Grid>
        <Grid item>
          <PercentageInput
            name="whkRateEmployee"
            onChange={({ currentTarget: { value } }) =>
              onChange(index, {
                ...employer,
                whkRateEmployee: parseInt(value) / 100,
              })
            }
            value={whkRateEmployee}
            label={t("Admin:WhkRateEmployee")}
          />
        </Grid>
        <Grid item>
          <TextField
            name="fulltimeHours"
            value={fulltimeHours}
            label={t("Admin:FulltimeHours")}
            type="number"
            onChange={({ currentTarget: { value } }) =>
              onChange(index, { ...employer, fulltimeHours: parseInt(value) })
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={benefitCalculation === ModuleOption.default}
                onChange={({ currentTarget: { checked } }) =>
                  onChange(index, {
                    ...employer,
                    benefitCalculation: checked
                      ? ModuleOption.default
                      : ModuleOption.off,
                  })
                }
              />
            }
            label={t("Admin:BenefitCalculation")}
          />
        </Grid>
        <Grid item>
          <FormGroup>
            <FormLabel>{t("Admin:BenefitSharedHeader")}</FormLabel>
            <PercentageInput
              name="benefitSharedDispensated"
              onChange={({ currentTarget: { value } }) =>
                onChange(index, {
                  ...employer,
                  benefitSharedDispensated: parseInt(value) / 100,
                })
              }
              value={benefitSharedDispensated}
              label={t("Admin:BenefitSharedDispensated")}
            />
            <PercentageInput
              name="benefitSharedTaxExempt"
              onChange={({ currentTarget: { value } }) =>
                onChange(index, {
                  ...employer,
                  benefitSharedTaxExempt: parseInt(value) / 100,
                })
              }
              value={benefitSharedTaxExempt}
              label={t("Admin:BenefitSharedTaxExempt")}
            />
            <PercentageInput
              name="benefitShared"
              onChange={({ currentTarget: { value } }) =>
                onChange(index, {
                  ...employer,
                  benefitShared: parseInt(value) / 100,
                })
              }
              value={benefitShared}
              label={t("Admin:BenefitShared")}
            />
          </FormGroup>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          {(code ?? "") !== "" && (
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              onClick={handleDeleteClick}
            >
              <Trans ns="Admin" i18nKey="Verwijderen">
                Verwijderen
              </Trans>
            </Button>
          )}
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default SettingsEmployerTabPanel;
