import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { CallSplit, Description } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import { Formik } from "formik";
import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { TotlResponse, cryptOrgplan } from "../../../business/api";
import {
  DocumentContentNodeModel,
  NavigationNodeFormValues,
  NavigationNodeModel,
  NavigationSpecial,
} from "../../../business/models";
import { dmsDocumentDownloadCell } from "../../../business/redux/saga/dms/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { getContentTypeName } from "../../../utils/FileUtils";
import OrgplanChoiceModal, {
  OrgplanChoice,
} from "../../cms/OrgplanChoiceModal";
import useStyles from "../../pages/ContentPage.styles";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";
import { ContentNodeActions } from "./index";

const hiddenInput = (name: string, value: string): Element => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  return input;
};

const openViewer =
  (target: string) =>
  (body: { [name: string]: string }): void => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = `https://www.my-orgchart.com/viewer/viewer_ph.php`;
    form.target = target;

    const entries = Object.entries(body);
    for (let i = 0; i < entries.length; i += 1) {
      form.appendChild(hiddenInput(entries[i][0], entries[i][1]));
    }

    document.body.appendChild(form);
    form.submit();
  };

const openOrgplan = (id: number, code?: string): void => {
  const target = `${id}${code ?? ""}`;
  window.open(
    "https://www.my-orgchart.com/viewer/includes/images/my-o-busy.gif",
    target
  );
  cryptOrgplan(id, code).then(openViewer(target));
};

interface Props {
  contentNode: DocumentContentNodeModel;
  disabled?: boolean;
  navigationNode: NavigationNodeModel;
  isEditing: boolean;
  formRef: RefObject<Formik<NavigationNodeFormValues>>;
  textCurrent: MutableRefObject<string>;
  textCursorPosition: MutableRefObject<number>;
  special?: NavigationSpecial;
}

const ContentNodeDocumentListItem = (props: Props) => {
  const {
    contentNode,
    contentNode: { id, contentType, documentName, title },
    disabled = false,
    navigationNode: { contentNodes, text },
    isEditing,
    formRef,
    textCurrent,
    textCursorPosition,
    special,
  } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const { showErrorMessageFromDetails, showErrorMessageFromError } =
    useSnackbarErrorMessage();
  const dispatch = useDispatch();

  /* OrgPlan */
  const orgplanChoices = useSelector((store: StoreModel): OrgplanChoice[] => {
    const definition = store.personal.definitions.value?.find(
      ({ code }) => code === "ORGPLAN_AFDELING"
    );
    return (
      store.personal.data.value?.find(
        ({ dataDefinitionId: id }) => id === definition?.id
      )?.text ?? ""
    )
      .split(/\s*;\s*/g)
      .filter((s) => s.indexOf("=") >= 0)
      .map((s) => {
        const eq = s.indexOf("=");
        const code = s.substring(0, eq).trim();
        const label = s.substring(eq + 1).trim();
        return { code, label };
      });
  });
  const [choiceOpen, setChoiceOpen] = useState(false);
  const toggleChoice = useCallback((): void => {
    setChoiceOpen(!choiceOpen);
  }, [choiceOpen]);
  const startOrgplan = useCallback(
    (code: string | undefined): void => {
      openOrgplan(id!, code);
    },
    [id]
  );
  const orgplan =
    !isEditing && special === "orgplan" && contentType === "application/json";

  /* Download document */
  const handleDocumentClick =
    (contentNode: DocumentContentNodeModel): (() => void) =>
    () => {
      if (orgplan) {
        if (orgplanChoices.length > 1) {
          toggleChoice();
        } else {
          startOrgplan(orgplanChoices[0]?.code);
        }
      } else {
        dispatch(
          dmsDocumentDownloadCell.require(
            {
              id: contentNode.id || -1,
            },
            {
              onFail: showErrorMessageFromDetails(
                t("Navigation:Document binnenhalen mislukt.")
              ),
              onSuccess: ({ body: { link } }: Success<TotlResponse>) => {
                try {
                  if (!window.open(link, "_blank")) {
                    window.location = link as unknown as Location;
                  }
                } catch (e) {
                  showErrorMessageFromError(
                    t("Navigation:Document binnenhalen mislukt.")
                  )(e);
                }
              },
            }
          )
        );
      }
    };

  return (
    <>
      <ListItem
        button
        onClick={handleDocumentClick(contentNode)}
        classes={{ root: classes.listItem }}
      >
        <ListItemIcon classes={{ root: classes.menuIcon }}>
          {orgplan ? <CallSplit /> : <Description />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            classes: { root: classes.menuText },
          }}
          classes={{ root: classes.listItemText }}
        >
          {title || documentName}
        </ListItemText>
        <ListItemSecondaryAction
          classes={{ root: classes.listItemSecondaryAction }}
        >
          {!isEditing && !orgplan && (
            <Typography component="span">
              {getContentTypeName(t, contentType || "unknown")}
            </Typography>
          )}
          {isEditing && (
            <ContentNodeActions
              contentNode={contentNode}
              disabled={disabled}
              formRef={formRef}
              showShiftDown={Boolean(
                contentNodes && contentNode.position < contentNodes.length - 1
              )}
              showShiftUp={Boolean(contentNodes) && contentNode.position > 0}
              text={text}
              textCurrent={textCurrent}
              textCursorPosition={textCursorPosition}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {choiceOpen && (
        <OrgplanChoiceModal
          onClose={toggleChoice}
          startOrgplan={startOrgplan}
          choices={orgplanChoices}
        />
      )}
    </>
  );
};

export default ContentNodeDocumentListItem;
