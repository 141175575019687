import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { MaterialUiPickersDate } from "material-ui-pickers/typings/date";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useMemo, useState } from "react";

import {
  UploadTypeModel,
  translateAndSortUploadTypes,
} from "../../../business/admin/upload/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import DateInput from "../../input/DateInput";

interface ContentNodeDocumentCreatorProps {
  types: UploadTypeModel[];

  onAdd: (
    uploadTypeId: number,
    effectiveDate: Date,
    file: File,
    onSuccess: () => void
  ) => void;
}

const UploadCreator = (props: ContentNodeDocumentCreatorProps) => {
  const { types: unsorted, onAdd } = props;
  const [t] = useTranslationStrict();

  const types = useMemo(() => {
    const sorted = Array.from(unsorted);
    sorted.sort((a, b) => (a.name < b.name ? -1 : 1));
    return sorted;
  }, [unsorted]);

  const [documentUploadReady, setDocumentUploadReady] =
    useState<boolean>(false);

  // Default 2 = File Transfer: Personeelsbestand
  const [uploadTypeId, setUploadTypeId] = useState(2);
  const handleTypeChange = useCallback(
    (event) => {
      setUploadTypeId(event.target.value);
    },
    [setUploadTypeId]
  );

  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const handleEffectiveDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setEffectiveDate(date || new Date());
    },
    []
  );

  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const handleChange = useCallback(
    (files: File[]) => {
      setCurrentFiles(files);
    },
    [setCurrentFiles]
  );

  const handleAddClick = useCallback(() => {
    if (currentFiles.length !== 1) {
      return;
    }

    onAdd(uploadTypeId, effectiveDate, currentFiles[0], () => {
      setCurrentFiles([]);
      setDocumentUploadReady(true);
    });
  }, [currentFiles, effectiveDate, onAdd, uploadTypeId]);

  const handleNewDocumentClick = () => {
    setDocumentUploadReady(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleDropRejected = (files: File[]) => {
    files.forEach((file) => {
      enqueueSnackbar(`Rejected ${file.name} | ${file.type} | ${file.size}`, {
        variant: "error",
      });
    });
  };

  return (
    <Grid container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="h5">{t("Admin:Nieuwe Upload")}</Typography>
      </Grid>
      <Grid container item direction="row" spacing={4}>
        {!documentUploadReady && (
          <>
            <Grid item>
              <FormControl>
                <FormLabel>{t("Admin:Soort bestand")}</FormLabel>
                <Select
                  value={uploadTypeId}
                  onChange={handleTypeChange}
                  name="type"
                >
                  {translateAndSortUploadTypes(types, t).map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <DateInput
                label={t("Admin:Ingangsdatum")}
                onChange={handleEffectiveDateChange}
                value={effectiveDate}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item>
        {!documentUploadReady && (
          <DropzoneArea
            showAlerts={false}
            acceptedFiles={[]}
            dropzoneText={t("Admin:File")}
            filesLimit={1}
            maxFileSize={33554432}
            onChange={handleChange}
            onDropRejected={handleDropRejected}
          />
        )}
        {documentUploadReady && (
          <>
            <Typography>{t("Common:ThankYou")}</Typography>
            <Button onClick={handleNewDocumentClick}>
              {t("Admin:ContentNodeAddDocumentHeader")}
            </Button>
          </>
        )}
      </Grid>
      <Grid item>
        {!documentUploadReady && (
          <Button
            disabled={currentFiles.length === 0}
            variant="contained"
            color="primary"
            onClick={handleAddClick}
          >
            {t("Common:Add")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(UploadCreator);
