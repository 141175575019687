import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React, { useState } from "react";

import { ChoiceData } from "../../business/alacarte";
import { getChoicePeriodMode } from "../../business/models";
import { useTranslationStrict } from "../../globalization/i18n";
import ChoiceRepetitionSelect from "./choiceRepetitionSelect";
import ChoiceRepetitionView from "./choiceRepetitionView";

interface ChoiceRepetitionProps {
  choice: ChoiceData;
  disabled: boolean;
  disableDirectly: boolean;
  onConfirm(startMonth?: number, endMonth?: number): void;
}

const ChoiceRepetition = (props: ChoiceRepetitionProps) => {
  const [t] = useTranslationStrict();
  const {
    choice: {
      definition: { interval },
      startMonth,
      endMonth,
    },
    disabled,
    disableDirectly,
    onConfirm,
  } = props;

  const [pristine, setPristine] = useState(true);
  const [selectDialogOpen, setSelectDialogOpen] = useState(false);
  const [currentStartMonth, setStartMonth] = useState<number | undefined>(
    startMonth
  );
  const [currentEndMonth, setEndMonth] = useState<number | undefined>(endMonth);

  const handleSelect = (newStartMonth?: number, newEndMonth?: number) => {
    setPristine(false);
    setStartMonth(newStartMonth);
    if (interval === "fourWeekly") {
      if (newStartMonth && newEndMonth && newStartMonth <= newEndMonth) {
        setEndMonth(newStartMonth - 1);
      } else {
        setEndMonth(newEndMonth);
      }
    } else {
      if (newStartMonth && newEndMonth && newStartMonth >= newEndMonth) {
        setEndMonth(newStartMonth + 1);
      } else {
        setEndMonth(newEndMonth);
      }
    }
  };

  const handleSelectClick = () => {
    setSelectDialogOpen(true);
  };

  const handleSelectClose = () => {
    setSelectDialogOpen(false);
  };

  const handleSelectConfirm = () => {
    onConfirm(currentStartMonth, currentEndMonth);
    handleSelectClose();
  };

  return (
    <>
      <Grid container>
        <Grid xs={11}>
          <ChoiceRepetitionView
            startMonth={startMonth}
            endMonth={endMonth}
            periodMode={getChoicePeriodMode(interval)}
            viewMode="Compact"
          />
        </Grid>
        <Grid xs={1}>
          <IconButton
            onClick={handleSelectClick}
            size="small"
            disabled={disabled}
            color={pristine ? "primary" : undefined}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleSelectClose}
        open={selectDialogOpen}
      >
        <DialogTitle>{t("Alacarte:PeriodSelect")}</DialogTitle>
        <DialogContent>
          <ChoiceRepetitionSelect
            onSelect={handleSelect}
            startMonth={currentStartMonth}
            endMonth={currentEndMonth}
            selectionMode={getChoicePeriodMode(interval)}
            disableDirectly={disableDirectly}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleSelectClose}>
            {t("Alacarte:Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSelectConfirm}
          >
            {t("Common:OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChoiceRepetition;
