import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      gridColumn: 4,
      gridRow: 1,
      marginLeft: 0,
      width: "auto",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(),
        width: "auto",
      },
    },
    searchIcon: {
      color: theme.palette.primary.main,
      width: theme.spacing() * 9,
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing() * 8,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    paper: {
      padding: theme.spacing() * 2,
      position: "absolute",
      zIndex: 1015,
      top: 40,
      left: 0,
      minWidth: 260,
    },
    closed: {
      display: "none",
    },
    open: {
      display: "block !important",
    },
  })
);

export default useStyles;
