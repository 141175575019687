import { SomeOrVoid, composeReducers } from "async-lifecycle-saga";
import { AsyncAction, AsyncValue } from "async-lifecycle-saga/dist/models";
import { sortBy } from "lodash";
import { combineReducers } from "redux";

import { FileResponse } from "../../../api";
import {
  DataDefinitionModel,
  EmployeeDataModel,
  MutationsOverviewDataRow,
  VariableDataRow,
} from "../../../models";
import { emptySingleValue } from "../models";
import {
  datacockpitDataDefinitionsCell,
  datacockpitEmployeeDataEditCell,
  datacockpitEmployeeDataFetchCell,
  datacockpitEmployeeDataFilterCell,
  datacockpitEmployeeDataPatchCell,
  datacockpitMutationsApproveCell,
  datacockpitMutationsOverviewCell,
  datacockpitMutationsRejectCell,
  datacockpitVariableDataExportActionCell,
  datacockpitVariableDataTableFetchCell,
} from "./cells";
import {
  DatacockpitStoreModel,
  EmployeeDataFilter,
  EmployeeDataMergedModel,
  EmployeeDataPatch,
  emptyEmployeeDataFilter,
} from "./models";

/* Custom reducers */
const employeeDataChangedReducer = (
  state: boolean | undefined,
  action: AsyncAction<unknown, void, AsyncValue<void>>
) => {
  switch (action.type) {
    case datacockpitEmployeeDataEditCell.events.patch:
      return true;
    case datacockpitEmployeeDataEditCell.events.set:
      return (action.payload as EmployeeDataMergedModel[] | null)
        ? state === undefined
          ? false
          : state
        : false; // if editing is cancelled, there are no changes (anymore)
    case datacockpitEmployeeDataPatchCell.events.success:
      return false;
    default:
      return state === undefined ? false : state;
  }
};

const employeeDataMergedReducer = (
  state?: EmployeeDataMergedModel[]
): EmployeeDataMergedModel[] => {
  return state || [];
};

const employeeDataFilterReducer = (
  state: EmployeeDataFilter | undefined,
  action: AsyncAction<EmployeeDataFilter, void, AsyncValue<void>>
): EmployeeDataFilter => {
  switch (action.type) {
    case datacockpitEmployeeDataFilterCell.events.set:
      return action.payload ?? emptyEmployeeDataFilter;
    default:
      return state ?? emptyEmployeeDataFilter;
  }
};

const employeeDataEditReducer = (
  state: EmployeeDataMergedModel[] | null | undefined,
  action:
    | AsyncAction<EmployeeDataMergedModel[] | null, void, AsyncValue<void>>
    | AsyncAction<EmployeeDataPatch, void, AsyncValue<void>>
): EmployeeDataMergedModel[] | null => {
  switch (action.type) {
    case datacockpitEmployeeDataEditCell.events.set:
      return action.payload as EmployeeDataMergedModel[] | null;
    case datacockpitEmployeeDataEditCell.events.patch:
      if (state === undefined || state === null) {
        throw new Error("Attempt to edit in a non-edit state.");
      }
      const payload = action.payload as EmployeeDataPatch;
      const index = state.findIndex(
        (ed) => ed.dataDefinitionId === payload.dataDefinitionId
      );
      if (index < 0) {
        return sortBy(
          [...state, payload.employeeData],
          (ed) => ed.dataDefinition!.code
        );
      } else {
        return [
          ...state.slice(0, index),
          payload.employeeData,
          ...state.slice(index + 1),
        ];
      }
    default:
      return state === undefined ? null : state;
  }
};

/**
 * A reducer that merges data definitions and employee data.
 * @param state The current state of the datacockpit portion in the Redux Store.
 * @param action The current Redux Saga action.
 */
const datacockpitEmployeeDataMergeReducer = (
  state: DatacockpitStoreModel | undefined,
  action: AsyncAction<unknown, unknown, AsyncValue<SomeOrVoid>>
) => {
  const stateToBeMerged = state || emptyStoreState;
  switch (action.type) {
    case datacockpitDataDefinitionsCell.events.success:
    case datacockpitEmployeeDataFetchCell.events.success:
      const dataDefinitions = state?.dataDefinitions.value;
      const employeeData = state?.employeeData.fetch?.value;
      if (dataDefinitions && employeeData) {
        return {
          ...stateToBeMerged,
          employeeData: {
            ...stateToBeMerged.employeeData,
            merged: mergeData(dataDefinitions, employeeData),
          },
        };
      } else {
        return {
          ...stateToBeMerged,
          employeeData: {
            ...stateToBeMerged.employeeData,
            merged: [],
          },
        };
      }
    case datacockpitEmployeeDataFetchCell.events.clear:
      return {
        ...stateToBeMerged,
        employeeData: {
          ...stateToBeMerged.employeeData,
          merged: [],
        },
      };
    default:
      return stateToBeMerged;
  }
};

/* Utilities */
const emptyStoreState: DatacockpitStoreModel = {
  dataDefinitions: emptySingleValue<DataDefinitionModel[]>(),
  employeeData: {
    fetch: emptySingleValue<EmployeeDataModel[]>(),
    merged: [],
    changed: false,
    filter: emptyEmployeeDataFilter,
    edit: null,
    patch: emptySingleValue<EmployeeDataMergedModel[]>(),
  },
  mutations: {
    overview: emptySingleValue<MutationsOverviewDataRow[]>(),
    reject: emptySingleValue<never>(),
    approve: emptySingleValue<never>(),
  },
  variableData: {
    exportAction: emptySingleValue<FileResponse>(),
    table: emptySingleValue<VariableDataRow[]>(),
  },
};

/**
 * Merges data definitions and employee data.
 * @param dataDefinitions The data definitions.
 * @param employeeData The employee data without data definitions.
 */
const mergeData = (
  dataDefinitions: DataDefinitionModel[],
  employeeData: EmployeeDataModel[]
): EmployeeDataMergedModel[] =>
  employeeData.map((ed) => {
    const dataDefinition = dataDefinitions.find(
      (dd) => dd.id === ed.dataDefinitionId
    );
    return {
      ...ed,
      dataDefinition,
    };
  }) || [];

/* Output */
export default composeReducers(
  combineReducers({
    dataDefinitions: datacockpitDataDefinitionsCell.reducer,
    employeeData: combineReducers({
      fetch: datacockpitEmployeeDataFetchCell.reducer,
      merged: employeeDataMergedReducer,
      changed: employeeDataChangedReducer,
      filter: employeeDataFilterReducer,
      edit: employeeDataEditReducer,
      patch: datacockpitEmployeeDataPatchCell.reducer,
    }),
    mutations: combineReducers({
      overview: datacockpitMutationsOverviewCell.reducer,
      reject: datacockpitMutationsRejectCell.reducer,
      approve: datacockpitMutationsApproveCell.reducer,
    }),
    variableData: combineReducers({
      exportAction: datacockpitVariableDataExportActionCell.reducer,
      table: datacockpitVariableDataTableFetchCell.reducer,
    }),
  }),
  datacockpitEmployeeDataMergeReducer
);
