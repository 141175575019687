import { createActor, createSingle } from "async-lifecycle-saga";
import { AsyncAction, AsyncValue } from "async-lifecycle-saga/dist/models";
import { put, takeEvery } from "redux-saga/effects";

import { asyncDelete, asyncGet, asyncPost } from "../../../../api";
import { UserModel } from "../../../../models";
import { StoreModel } from "../../models";
import { adminEmployeeTypesCell } from "../employee/cells";
import { userAddPoster, userUpdatePutter } from "./api";
import {
  AdminUserDeleteRequestResult,
  AdminUsersInviteAllNewRequestResult,
  AdminUsersInviteRequestResult,
} from "./models";

export const adminUsersListCell = createSingle<
  UserModel[],
  StoreModel,
  boolean
>({
  context: (store: StoreModel) => {
    return store.admin.users.listIncludeArchived || false;
  },
  path: ["admin", "users", "list"],
  require: {
    api: (_, includeArchived) =>
      asyncGet(
        includeArchived
          ? "/api/admin/users?includeArchived=true"
          : "/api/admin/users"
      ),
  },
});

const adminUsersIncludeArchivedEvent = "ADMIN_USERS_LISTINCLUDEARCHIVED";

export const adminUsersListIncludeArchivedCell = {
  events: [adminUsersIncludeArchivedEvent],
  reducer: (
    state: boolean,
    action: AsyncAction<boolean, boolean, AsyncValue<boolean>>
  ) => {
    return action.type === adminUsersIncludeArchivedEvent
      ? action.payload || false
      : state || false;
  },
  require: (
    includeArchived: boolean
  ): AsyncAction<boolean, boolean, AsyncValue<boolean>> => {
    return { type: adminUsersIncludeArchivedEvent, payload: includeArchived };
  },
  sagas: [
    takeEvery(adminUsersIncludeArchivedEvent, function* () {
      yield put({ type: adminUsersListCell.events.invalidate });
      yield put(adminUsersListCell.require());
    }),
  ],
  select: (store: StoreModel) => store.admin.users.listIncludeArchived || false,
};

export const adminUsersAddCell = createActor({
  path: ["admin", "users", "add"],
  api: userAddPoster,
  invalidate: [adminEmployeeTypesCell.events, adminUsersListCell.events],
});

export const adminUsersDeleteCell = createActor<
  string,
  AdminUserDeleteRequestResult
>({
  path: ["admin", "users", "delete"],
  api: (loginName) =>
    asyncDelete(`/api/admin/users/${encodeURIComponent(loginName)}`),
  invalidate: [adminEmployeeTypesCell.events, adminUsersListCell.events],
});

export const adminUsersInviteCell = createActor<
  { culture: string; loginName: string },
  AdminUsersInviteRequestResult
>({
  path: ["admin", "users", "invite"],
  api: ({ culture, loginName }) =>
    asyncPost("/api/admin/users/invite", { culture, loginName }),
  invalidate: [adminUsersListCell.events],
});

export const adminUsersInviteAllNewCell = createActor<
  {},
  AdminUsersInviteAllNewRequestResult
>({
  path: ["admin", "users", "inviteAllNew"],
  api: () => asyncPost("/api/admin/users/inviteAllNew", {}),
  invalidate: [adminUsersListCell.events],
});

export const adminUsersUpdateCell = createActor({
  path: ["admin", "users", "update"],
  api: userUpdatePutter,
  invalidate: [adminEmployeeTypesCell.events, adminUsersListCell.events],
});

export function* adminUsersChanged() {
  yield put(adminEmployeeTypesCell.require());
  yield put(adminUsersListCell.require());
}
