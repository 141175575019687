import { createActor } from "async-lifecycle-saga";

import { TotlResponse, asyncDownloadGet, asyncGet } from "../../../api";
import { DmsDocumentDownloadRequest } from "./models";

export const dmsDocumentDownloadCell = createActor({
  path: ["dms", "document", "download"],
  api: ({ id, path = "dms/document" }: DmsDocumentDownloadRequest) =>
    asyncGet<TotlResponse>(`/api/${path}/${id}`),
});

export const dmsDocumentSaveCell = createActor({
  path: ["dms", "document", "save"],
  api: (href: string) => asyncDownloadGet(href),
});

export const dmsDownloadCell = createActor({
  path: ["dms", "download"],
  api: (href: string) => asyncGet<TotlResponse>(href),
});
