const ensureValidUrl = (urlInput: string): string => {
  if (!urlInput) {
    return urlInput;
  }

  if (/^[\w-]+:\/\//.test(urlInput)) {
    return urlInput;
  }

  return `https://${urlInput}`;
};

export const getAuthority = (link: string): string => {
  try {
    const { protocol, hostname } = new URL(link);
    return `${protocol}//${hostname}`;
  } catch (_) {
    return link;
  }
};

export const getHostname = (link: string): string => {
  try {
    const { hostname } = new URL(link);
    return hostname;
  } catch (_) {
    return link;
  }
};

export const getDomain = (link: string): string => {
  const hostname = getHostname(link);
  return hostname.match(/[^.]+\.[^.]+$/)?.[0] ?? hostname;
};

export const addQueryParam = (link: string, name: string, value: string) => {
  const sep = link.includes("?") ? "&" : "?";
  const arg = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  const hash = link.indexOf("#");
  return hash < 0
    ? `${link}${sep}${arg}`
    : `${link.substring(0, hash)}${sep}${arg}${link.substring(hash)}`;
};

export const isRemoteLink = (link: string): boolean => {
  try {
    const target = new URL(link, window.location.href);
    return target.origin !== new URL(window.location.href).origin;
  } catch {
    return false;
  }
};

export default ensureValidUrl;
