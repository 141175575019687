import { Role } from "../../../models";

export const sessionRoleUpdateCell = {
  events: {
    require: "SESSION_ROLE_UPDATE_REQUIRE",
  },
  require: (role: Role) => ({
    type: sessionRoleUpdateCell.events.require,
    payload: role,
  }),
};
