import { Theme, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    margin: "0.3em",
    width: "38em",
  },
  controls: {
    margin: "0.5em",
  },
  main: {
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
    margin: "0.5em",
  },
  tabs: {
    margin: "0.3em",
  },
}));
