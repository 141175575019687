import { Dialog } from "@material-ui/core";
import { FormikActions } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { EmployerModel, ModuleOption } from "../../../business/models";
import { adminEmployersListSpecialCell } from "../../../business/redux/saga/admin/employers/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import EmployerForm from "./EmployerForm";

const initialValues: EmployerModel = {
  id: 0,
  organizationId: 0,
  code: uuidv4(),
  name: "",
  government: false,
  isSmall: false,
  isSpecial: true,
  whkRate: 0,
  whkRateEmployee: 0,
  wkrBudget: 0,
  fulltimeHours: 40,
  benefitCalculation: ModuleOption.off,
  benefitShared: 0,
  benefitSharedTaxExempt: 0,
  benefitSharedDispensated: 0,
  period: "month",
};

interface EmployerAddModalProps {
  onSubmitted: () => void;
  open: boolean;
}

const EmployerAddDialog = ({ onSubmitted, open }: EmployerAddModalProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useMemo(() => {
    const requiredMessage = t("Forms:Required") as string;
    return Yup.object<EmployerModel>().shape<EmployerModel>({
      id: Yup.number(),
      organizationId: Yup.number(),
      code: Yup.string(),
      name: Yup.string().required(requiredMessage),
      government: Yup.bool().required(requiredMessage),
      isSmall: Yup.bool().required(requiredMessage),
      isSpecial: Yup.bool(),
      whkRate: Yup.number().required(requiredMessage),
      whkRateEmployee: Yup.number().required(requiredMessage),
      wkrBudget: Yup.number().required(requiredMessage),
      fulltimeHours: Yup.number().required(requiredMessage),
      benefitCalculation: Yup.mixed<ModuleOption>()
        .oneOf(Object.values(ModuleOption))
        .required(requiredMessage),
      benefitShared: Yup.number().required(requiredMessage),
      benefitSharedTaxExempt: Yup.number().required(requiredMessage),
      benefitSharedDispensated: Yup.number().required(requiredMessage),
      period: Yup.string(),
    });
  }, [t]);

  const handleSubmit = useCallback(
    (values: EmployerModel, formikActions: FormikActions<EmployerModel>) => {
      dispatch(
        adminEmployersListSpecialCell.create(values, {
          onFail: () => {
            enqueueSnackbar(t("Admin:EmployerCreateFailed"), {
              variant: "error",
            });
            formikActions.setSubmitting(false);
          },
          onSuccess: () => {
            dispatch({ type: adminEmployersListSpecialCell.events.invalidate });
            dispatch(adminEmployersListSpecialCell.require());
            formikActions.setSubmitting(false);
            enqueueSnackbar(t("Admin:EmployerCreateSucceeded"), {
              variant: "success",
            });
            onSubmitted();
          },
        })
      );
    },
    [dispatch, enqueueSnackbar, onSubmitted, t]
  );

  return (
    <Dialog fullWidth open={open}>
      <EmployerForm
        initialValues={initialValues}
        onCancel={onSubmitted}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </Dialog>
  );
};

export default EmployerAddDialog;
