import "react-app-polyfill/stable";
import "react-mde/lib/styles/css/react-mde-editor.css";
import "react-mde/lib/styles/css/react-mde-suggestions.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde.css";

import DateFnsUtils from "@date-io/date-fns";
import { Success } from "async-lifecycle-saga/dist/models";
import enUsLocale from "date-fns/locale/en-US";
import nlLocale from "date-fns/locale/nl";
import i18next from "i18next";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Route } from "react-router-dom";
import { Action, Store } from "redux";

import {
  FrontendOptions,
  OrganizationModel,
  SessionModel,
} from "./business/models";
import { authenticationRefresh } from "./business/redux/saga/authentication/cells";
import { AuthenticationResponseModel } from "./business/redux/saga/authentication/models";
import { StoreModel } from "./business/redux/saga/models";
import { organizationGetter } from "./business/redux/saga/organization/api";
import { emptySession } from "./business/redux/saga/session/reducer";
import { ReflexoRouter } from "./business/router";
import { ConfirmProvider } from "./confirm";
import ErrorBoundary from "./ErrorBoundary";
import { withI18nextProvider } from "./globalization/i18n";
import { withJssProvider } from "./styling/Jss";
import { ConnectedThemeProvider } from "./styling/Theme";
import {
  App,
  React,
  ReactDOM,
  ReduxProvider,
  createReflexoStore,
  serviceWorker,
} from "./utils/appImports";
import { RouterContext } from "./utils/routes";
import { getRefreshToken } from "./utils/SecurityUtils";
import withSnackbarProvider from "./utils/SnackbarUtils";

/* Preserve session with internal links */
export const sessionStoragePreserveKey = "sessionStorage";

if (sessionStorage.length === 0) {
  const sessionStorageDataRaw = localStorage.getItem(sessionStoragePreserveKey);
  if (sessionStorageDataRaw) {
    const sessionStorageData = JSON.parse(sessionStorageDataRaw);
    for (const key in sessionStorageData) {
      sessionStorage.setItem(key, sessionStorageData[key]);
    }
    localStorage.removeItem(sessionStoragePreserveKey);
  }
}
/* Preserve session with internal links */

const localeMap: { [key: string]: Locale } = {
  "en-US": enUsLocale,
  nl: nlLocale,
};

const supportedLanguages = ["en-US", "nl"];
const fallbackLng = ["nl"];

i18next.init({
  fallbackLng,
  preload: supportedLanguages,
  whitelist: supportedLanguages,
});

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
    reflexoOptions?: FrontendOptions;
  }
}

const start = (
  store: Store<StoreModel, Action<string>> & {
    dispatch: {};
  }
): void => {
  ReactDOM.render(
    withJssProvider(
      withI18nextProvider(
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[i18next.language]}
        >
          <ReduxProvider store={store}>
            <ConnectedThemeProvider>
              <ErrorBoundary
                instrumentationKey={window.reflexoOptions?.instrumentationKey}
              >
                <ConfirmProvider>
                  <ReflexoRouter>
                    <Route>
                      {(routeProps): JSX.Element => (
                        <RouterContext.Provider value={routeProps}>
                          {withSnackbarProvider(<App />)}
                        </RouterContext.Provider>
                      )}
                    </Route>
                  </ReflexoRouter>
                </ConfirmProvider>
              </ErrorBoundary>
            </ConnectedThemeProvider>
          </ReduxProvider>
        </MuiPickersUtilsProvider>
      )
    ),
    document.getElementById("root")
  );

  serviceWorker.unregister();
};

const startFromSession = (session: SessionModel) => {
  organizationGetter(true).then((response) => {
    if (response.status !== 200) {
      return;
    }

    const organization = (response as Success<OrganizationModel>).body;
    if (!organization) {
      return;
    }

    document.title = organization.applicationName;
    const store = createReflexoStore(organization, session);
    start(store);
  });
};

const refreshToken = getRefreshToken();
if (refreshToken) {
  authenticationRefresh({ refreshToken }).then(
    (response): void | PromiseLike<void> => {
      const { body } = response as Success<AuthenticationResponseModel>;
      const session = {
        ...emptySession,
        ...(body as unknown as SessionModel),
      };
      startFromSession(session);
    }
  );
} else {
  startFromSession(emptySession);
}
