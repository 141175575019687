import { AsyncSingleValue } from "async-lifecycle-saga";

import { FileResponse } from "../../../../api";
import { DataDefinitionCode } from "../../../../dataDefinitionCode";
import {
  ChoiceBenefitsModel,
  ChoiceInterval,
  ChoicePeriodModel,
  ChoiceSetModel,
  DataDefinitionGroup,
  DataDefinitionModel,
  UserModel,
} from "../../../../models";

export interface ApprovePayload {
  approved: boolean;
  employeeIds: number[];
}

export interface ChoicePeriodDto {
  id: number;
  startDateTime: string;
}

export interface ChoiceSettingsModel {
  openFrom?: Date;
  openTo?: Date;
}

export interface ChoiceDefinitionModel {
  dataDefinitionId: number;
  position: number;
  enabled: boolean;
  upload: boolean;
  label: string;
  interval: ChoiceInterval;
  help?: string;
  earningCode: string;
  userInput: boolean;
}

export type DataChoiceDefinition = ChoiceDefinitionModel & {
  dataDefinition: DataDefinitionModel;
};

export interface EmployeeChoiceSetModel extends ChoiceSetModel {
  employee: UserModel;
}

const emptyDataDefinition = (id: number): DataDefinitionModel => ({
  id,
  code: "" as DataDefinitionCode,
  dataType: { id: 0, dataField: "text", name: "" },
  choiceRole: "none",
  choiceCategory: "none",
  group: DataDefinitionGroup.Geen,
});

export const getDataDefinition = (
  data: DataDefinitionModel[],
  dataDefinitionId: number
): DataDefinitionModel =>
  data.find(({ id }): boolean => id === dataDefinitionId) ||
  emptyDataDefinition(dataDefinitionId);

export const joinChoiceDataDefinitions = (
  choices: ChoiceDefinitionModel[],
  data: DataDefinitionModel[]
): DataChoiceDefinition[] => {
  const result = choices.map(
    (choice): DataChoiceDefinition => ({
      ...choice,
      dataDefinition: getDataDefinition(data, choice.dataDefinitionId),
    })
  );
  result.sort((a, b): number => a.position - b.position);
  return result;
};

export interface AdminChoiceStoreModel {
  benefits: AsyncSingleValue<ChoiceBenefitsModel>;
  definitions: {
    list: AsyncSingleValue<ChoiceDefinitionModel[]>;
  };
  employee: {
    export: AsyncSingleValue<FileResponse>;
    list: AsyncSingleValue<EmployeeChoiceSetModel[]>;
  };
  periods: AsyncSingleValue<ChoicePeriodModel[]>;
  settings: AsyncSingleValue<ChoiceSettingsModel>;
}
