import { AsyncResponse } from "async-lifecycle-saga";

import { asyncDelete, asyncGet, asyncPost, asyncPut } from "../../../../api";
import { EmployerModel } from "../../../../models";

export const employersCreate = (
  employer: EmployerModel
): Promise<AsyncResponse<EmployerModel>> =>
  asyncPost<EmployerModel>("/api/employer", employer);

export const employersDelete = (
  employerId: number
): Promise<AsyncResponse<undefined>> =>
  asyncDelete(`/api/employer/${employerId}`);

export const employersList =
  (isSpecial: boolean) => (): Promise<AsyncResponse<EmployerModel[]>> =>
    asyncGet<EmployerModel[]>(`/api/employer/list?isSpecial=${isSpecial}`);

export const employersUpdate = (
  employer: EmployerModel
): Promise<AsyncResponse<EmployerModel>> =>
  asyncPut<EmployerModel>("/api/employer", employer);
