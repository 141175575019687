// eslint-disable-next-line import/no-unresolved
import {
  Choice,
  Employee,
  Employer,
  Pensioner,
  TaxedOptimalChoice,
} from "@twijg/loonheffing";

import { unreachable } from "../utils/flow";
import { WlzPerson } from "./personal/wlz/models";
import { Taxonomy } from "./taxonomy";

// eslint-disable-next-line import/no-unresolved
export const loonheffing = import("@twijg/loonheffing");

export const period: { maxYear: number } = { maxYear: 2023 };

// eslint-disable-next-line import/no-mutable-exports
let optimizeChoices = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  year: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  employer: Employer,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  employee: Employee,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  choice: Choice
): TaxedOptimalChoice => unreachable("Stub for optimizeChoices");

// eslint-disable-next-line import/no-mutable-exports
export let createEmployer = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  government: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  small: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  whkRate: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  whkRateEmployee: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispensationBenefitShared: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrExemptBenefitShared: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrFinalBenefitShared: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrSpecialMax: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrSpecialRate: number
): Employer => unreachable("Stub for createEmployer");

// eslint-disable-next-line import/no-mutable-exports
export let createEmployee = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  salary: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  permanent: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrExemptMax: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wkrEfficacyMax: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceHoursRate: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceHoursMax: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceSalaryMax: number
): Employee => unreachable("Stub for createEmployee");

// eslint-disable-next-line import/no-mutable-exports
export let createChoice = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalHours: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalDispensated: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalWkr: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalWkrSpecial: number
): Choice => unreachable("Stub for createChoice");

export let calculateWlz = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  year: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  client: WlzPerson,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  partner: WlzPerson | undefined
): Record<string, number> => unreachable("Stub for calculateWlz");

const toCalculations = (variables: Float64Array): Record<string, number> => {
  const calculations: Record<string, number> = {};

  for (const [i, v] of Object.entries(variables)) {
    if (!isNaN(v)) {
      calculations[Taxonomy[Number(i)]] = v;
    }
  }

  return calculations;
};

loonheffing.then(
  ({
    max_year,
    optimizeChoices: ic,
    Choice: WasmChoice,
    Employee: WasmEmployee,
    Employer: WasmEmployer,
    Pensioner: WasmPensioner,
    Income: WasmIncome,
    Home: WasmHome,
    Deductions: WasmDeductions,
    Capital: WasmCapital,
    PreTaxed: WasmPreTaxed,
    lowContributionWlz,
    highContributionSingleWlz,
    highContributionCoupleWlz,
    diffContributionsWlz,
  }): void => {
    period.maxYear = max_year();
    optimizeChoices = ic;
    createChoice = (
      goalHours,
      goalDispensated,
      goalWkr,
      goalWkrSpecial
    ): Choice =>
      new WasmChoice(goalHours, goalDispensated, goalWkr, goalWkrSpecial);
    createEmployee = (
      salary,
      permanent,
      wkrExemptMax,
      wkrEfficacyMax,
      sourceHoursRate,
      sourceHoursMax,
      sourceSalaryMax
    ): Employee =>
      new WasmEmployee(
        salary,
        permanent,
        wkrExemptMax,
        wkrEfficacyMax,
        sourceHoursRate,
        sourceHoursMax,
        sourceSalaryMax
      );
    createEmployer = (
      government,
      small,
      whkRate,
      whkRateEmployee,
      dispensationBenefitShared,
      wkrExemptBenefitShared,
      wkrFinalBenefitShared,
      wkrSpecialMax,
      wkrSpecialRate
    ): Employer =>
      new WasmEmployer(
        government,
        small,
        whkRate,
        whkRateEmployee,
        dispensationBenefitShared,
        wkrExemptBenefitShared,
        wkrFinalBenefitShared,
        wkrSpecialMax,
        wkrSpecialRate
      );

    const toPensioner = (
      person: WlzPerson,
      partner: WlzPerson | undefined
    ): Pensioner =>
      new WasmPensioner(
        !partner,
        person.dateOfBirth.getFullYear(),
        new WasmIncome(
          person.aowBenefit,
          person.pension,
          person.profitEnterprise,
          person.incomeOther
        ),
        new WasmHome(
          person.wozValueResidence,
          person.mortgage,
          person.mortgageInterest
        ),
        new WasmDeductions(person.healthcareDeduction, person.giftsDeduction),
        new WasmCapital(person.savingsCredit, 0, person.investments),
        new WasmPreTaxed(
          person.wageTaxWithholding,
          person.dividendTaxWithheld,
          person.incomeTaxAssessmentProvisional
        )
      );

    calculateWlz = (
      year: number,
      wlzClient: WlzPerson,
      wlzPartner: WlzPerson | undefined
    ): Record<string, number> => {
      const client = toPensioner(wlzClient, wlzPartner);
      const partner = wlzPartner && toPensioner(wlzPartner, wlzClient);
      if (!partner) {
        const high = highContributionSingleWlz(year, client);
        return toCalculations(high);
      }

      const high = highContributionCoupleWlz(year, client, partner);
      const low = lowContributionWlz(year, client, partner);
      return toCalculations(diffContributionsWlz(low, high));
    };
  },
  (e) => {
    // eslint-disable-next-line no-console
    console.warn(`Loading wasm failed: ${e.message ?? e.code ?? e}`);
  }
);

export { optimizeChoices };
