import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, SaveAlt } from "@material-ui/icons";
import { memo, useState } from "react";

import {
  UploadCategory,
  UploadStatusModel,
  UploadTypeId,
} from "../../../business/admin/upload/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { renderDate, renderDateTime } from "../../../utils/DateUtils";
import ReportDialog from "./ReportDialog";

const UploadStats = memo(
  ({
    assigned,
    dangling,
  }: {
    assigned: number;
    dangling: number;
  }): JSX.Element => {
    const [t] = useTranslationStrict();

    return (
      <>
        {assigned} {t("Admin:UploadAssigned")}
        <br />
        {t("Admin:UploadDanglingCount", { count: dangling })}
        <br />
      </>
    );
  }
);

interface UploadTableProps {
  uploads: UploadStatusModel[];
  someBusy: boolean;

  onCheck(id: number): void;

  onDelete(id: number): void;

  onDownload(id: number): void;

  onProcess(id: number, again: boolean, category: UploadCategory): void;
}

const UploadTable = memo(
  ({
    uploads,
    someBusy,
    onCheck,
    onDelete,
    onDownload,
    onProcess,
  }: UploadTableProps) => {
    const [t, { language }] = useTranslationStrict();
    const [report, setReport] = useState<UploadStatusModel | undefined>();

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Admin:UploadName")}</TableCell>
              <TableCell>{t("Admin:UploadType")}</TableCell>
              <TableCell>{t("Admin:UploadDate")}</TableCell>
              <TableCell>{t("Admin:Ingangsdatum")}</TableCell>
              <TableCell>{t("Admin:UploadStatus")}</TableCell>
              <TableCell>{t("Admin:UploadActions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploads.map((upload) => {
              const {
                id,
                documentName,
                uploadDateTime,
                uploadEffectiveDate,
                uploadTypeDto,
                checkDateTime,
                checkWarningLevel,
                processDateTime,
                busy,
              } = upload;
              return (
                <TableRow key={id}>
                  <TableCell>{documentName}</TableCell>
                  <TableCell>
                    {t(`UploadType:${uploadTypeDto.name.replace(":", ";")}`)}
                  </TableCell>
                  <TableCell>
                    {renderDateTime(uploadDateTime, language)}
                  </TableCell>
                  <TableCell>
                    {renderDate(uploadEffectiveDate, language)}
                  </TableCell>
                  <TableCell>
                    {checkDateTime && (
                      <Button
                        type="button"
                        color="default"
                        variant="contained"
                        onClick={(): void => setReport(upload)}
                      >
                        {t(`Admin:UploadStatus${checkWarningLevel}`)}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {processDateTime &&
                      upload.assigned !== undefined &&
                      upload.dangling !== undefined && (
                        <UploadStats
                          assigned={upload.assigned}
                          dangling={upload.dangling}
                        />
                      )}
                    {processDateTime && renderDateTime(processDateTime)}
                    {busy && <CircularProgress />}
                    {!busy &&
                      uploadTypeDto.category !== "scratch" &&
                      uploadTypeDto.id !== UploadTypeId.contractsExport && (
                        <Button
                          onClick={(): void => {
                            if (
                              !checkDateTime ||
                              checkWarningLevel === "blocker"
                            ) {
                              onCheck(id);
                            } else {
                              onProcess(
                                id,
                                processDateTime !== undefined,
                                uploadTypeDto.category
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                          disabled={someBusy}
                        >
                          {
                            // eslint-disable-next-line no-nested-ternary
                            checkDateTime && checkWarningLevel !== "blocker"
                              ? processDateTime === undefined
                                ? t("Admin:UploadProcess")
                                : t("Admin:UploadProcessAgain")
                              : t("Admin:UploadCheck")
                          }
                        </Button>
                      )}
                    {!busy && (
                      <IconButton
                        onClick={(): void => onDownload(id)}
                        type="button"
                        color="secondary"
                        title={t("Common:Download")}
                      >
                        <SaveAlt />
                      </IconButton>
                    )}
                    {!busy && (
                      <IconButton
                        onClick={(): void => onDelete(id)}
                        type="button"
                        color="secondary"
                        title={t("Common:Delete")}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {report && (
          <ReportDialog
            upload={report}
            onClose={(): void => setReport(undefined)}
          />
        )}
      </>
    );
  }
);

export default UploadTable;
