import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
} from "@material-ui/core";
import { Field, Form, Formik, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import React, { memo, useCallback, useMemo } from "react";
import * as Yup from "yup";

import {
  NavigationLocation,
  NavigationNodeModel,
} from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import FormikMaterialUIIconPicker from "./FormikMaterialUIIconPicker";

interface CreateRootNavigationDialogProps {
  open: boolean;
  location: NavigationLocation;
  onClose: () => void;
  onConfirm: (node: NavigationNodeModel) => void;
}

interface CreateRootNavigationDialogFormValues {
  icon?: string;
  title: string;
}

const initialValues: CreateRootNavigationDialogFormValues = {
  icon: "AccessAlarm",
  title: "",
};

const CreateRootNavigationNodeDialog = (
  props: CreateRootNavigationDialogProps
) => {
  const { open, location, onClose, onConfirm } = props;
  const [t] = useTranslationStrict();

  const handleClose = useCallback((): void => {
    onClose();
  }, [onClose]);

  const validationSchema = useMemo(
    (): Yup.ObjectSchema<
      Yup.Shape<
        CreateRootNavigationDialogFormValues,
        CreateRootNavigationDialogFormValues
      >
    > =>
      Yup.object<CreateRootNavigationDialogFormValues>().shape<CreateRootNavigationDialogFormValues>(
        {
          icon: Yup.string().required(),
          title: Yup.string()
            .required(t("Forms:Required"))
            .matches(/^\w.*\S$/, t("Admin:TitleInvalid")),
        }
      ),
    [t]
  );

  const handleSubmit = useCallback(
    (
      { icon, title }: CreateRootNavigationDialogFormValues,
      actions: FormikActions<CreateRootNavigationDialogFormValues>
    ): void => {
      actions.resetForm();
      onConfirm({
        isRootNode: true,
        icon,
        title,
        route: "",
        text: "",
        level: 0,
      });
    },
    [onConfirm]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {t(`Admin:${location}CreateRootNavigationDialogTitle`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Admin:NavigationNodeAddToRootDialogText")}
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({
            isValid,
            isSubmitting,
            resetForm,
            values: { icon, title },
          }): React.ReactElement => (
            <Form>
              <Card>
                <CardHeader title={t("Admin:NewPage")} />
                <CardContent>
                  <Grid
                    container
                    spacing={8}
                    direction="column"
                    style={{ minWidth: "500px" }}
                  >
                    {location === "aside" && (
                      <Grid item>
                        <FormControl>
                          <FormLabel>{t("Admin:NavigationNodeIcon")}</FormLabel>
                          <Field
                            component={FormikMaterialUIIconPicker}
                            id="icon"
                            name="icon"
                            value={icon}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item>
                      <FormControl>
                        <FormLabel>{t("Admin:NavigationNodeTitle")}</FormLabel>
                        <Field
                          component={TextField}
                          name="title"
                          placeholder={t("Admin:TitlePlaceholder")}
                          value={title}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={(): void => {
                      resetForm();
                      handleClose();
                    }}
                  >
                    {t("Common:Cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                  >
                    {t("Common:Add")}
                  </Button>
                </CardActions>
              </Card>
            </Form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateRootNavigationNodeDialog);
