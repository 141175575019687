import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { buttonTextBlack, darkBlack, mobileScreen } from "../../styling/Common";

export const LogoHeight = 36;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: darkBlack,
    height: LogoHeight,
    lineHeight: `${LogoHeight}px`,
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "10% 90%",
    gridTemplateRows: "26px",
  },
  ...mobileScreen({
    container: {
      "& button": {
        minWidth: 2,
      },
    },
  }),
  application: {
    backgroundColor: "inherit",
    float: "left",
    gridColumn: 1,
    gridRow: 1,
  },
  applicationName: {
    display: "inline-block",
    lineHeight: `${LogoHeight}px`,
    verticalAlign: "top",

    "& > button": {
      display: "inline-block",
      lineHeight: `${LogoHeight}px`,
      marginLeft: "1em",
      verticalAlign: "top",
    },
  },
  ...mobileScreen({
    applicationName: {
      display: "none !important",
    },
  }),
  button: {
    ...buttonTextBlack(theme),
    justifyContent: "unset",
    padding: 0,
  },
  logo: {
    gridColumn: 1,
    gridRow: 1,
    height: LogoHeight,
  },
  profileInfo: {
    backgroundColor: "inherit",
    display: "inline-block",
    float: "right",
    gridColumn: 2,
    gridRow: 1,
    position: "absolute",
    right: 5,
    top: 0,
    whiteSpace: "nowrap",
  },
}));

export default useStyles;
