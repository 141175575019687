import { PropsWithChildren, createContext, useMemo, useState } from "react";

const routeAllOpen = "all";

interface NavigationMenuContextState {
  routeOpen: string | null;
  setRouteOpen: (route: string | null) => void;
}

const NavigationMenuContext = createContext<NavigationMenuContextState>({
  routeOpen: "",
  setRouteOpen: () => {},
});

interface NavigationMenuProviderProps extends PropsWithChildren<{}> {}

const NavigationMenuProvider = (props: NavigationMenuProviderProps) => {
  const { children } = props;
  const [routeOpen, setRouteOpen] = useState<string | null>(null);

  const state = useMemo(() => ({ routeOpen, setRouteOpen }), [routeOpen]);

  return (
    <NavigationMenuContext.Provider value={state}>
      {children}
    </NavigationMenuContext.Provider>
  );
};

export { NavigationMenuContext, NavigationMenuProvider, routeAllOpen };
