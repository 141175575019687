import { Theme } from "@material-ui/core";
import { CSSProperties, StyleRules } from "@material-ui/styles/withStyles";

export const darkBlack = "#333";
export const important = (value: string): string => `${value} !important`;

const backgroundBlueHeight = 250;

/**
 * JSS mixin for mobile phone screens.
 * The JSS styles do not apply to larger screens.
 * @param record JSS styles for mobile phone screens.
 */
export const mobileScreen = <
  Props extends object = {},
  ClassKey extends string = string
>(
  record: StyleRules<Props, ClassKey>
): Record<"@media (max-width: 480px)", StyleRules<Props, ClassKey>> => ({
  "@media (max-width: 480px)": {
    ...record,
  },
});

/**
 * JSS mixin for tablet screens.
 * The JSS styles apply to tablet screens and larger.
 * @param record JSS styles for tablet screens.
 */
export const tabletScreen = <
  Props extends object = {},
  ClassKey extends string = string
>(
  record: StyleRules<Props, ClassKey>
): Record<"@media (min-width: 481px)", StyleRules<Props, ClassKey>> => ({
  "@media (min-width: 481px)": {
    ...record,
  },
});

/**
 * JSS mixin for small screens such as laptop computer screens.
 * The JSS styles apply to laptop computer screens and larger.
 * @param record JSS styles for small screens.
 */
export const smallScreen = <
  Props extends object = {},
  ClassKey extends string = string
>(
  record: StyleRules<Props, ClassKey>
): Record<"@media (min-width: 769px)", StyleRules<Props, ClassKey>> => ({
  "@media (min-width: 769px)": {
    ...record,
  },
});

/**
 * JSS mixin for big screens such as desktop computer screens.
 * The JSS styles apply to desktop computer screens and larger.
 * @param record JSS styles for big screens.
 */
export const bigScreen = <
  Props extends object = {},
  ClassKey extends string = string
>(
  record: StyleRules<Props, ClassKey>
): Record<"@media (min-width: 1025px)", StyleRules<Props, ClassKey>> => ({
  "@media (min-width: 1025px)": {
    ...record,
  },
});

/**
 * JSS mixin for huge screens such as large monitors or televisions.
 * These are the largest screens in the application.
 * @param record JSS styles for huge screens.
 */
export const hugeScreen = <
  Props extends object = {},
  ClassKey extends string = string
>(
  record: StyleRules<Props, ClassKey>
): Record<"@media (min-width: 1201px)", StyleRules<Props, ClassKey>> => ({
  "@media (min-width: 1201px)": {
    ...record,
  },
});

export const dangerButton = (theme: Theme): CSSProperties => ({
  color: theme.palette.error.contrastText,
  backgroundColor: theme.palette.error.light,
});

const buttonTextForColor = (theme: Theme, color: string): CSSProperties => ({
  color,
  padding: 3,
  textTransform: "none",

  "&:disabled": {
    color,
  },
});

export const buttonTextBlack = (theme: Theme): CSSProperties =>
  buttonTextForColor(theme, darkBlack);

export const buttonTextWhite = (theme: Theme): CSSProperties =>
  buttonTextForColor(theme, theme.palette.common.white);

export const floatRight = (): CSSProperties => ({
  float: "right",
});

export const heightBlueBackground = (): CSSProperties => ({
  height: `${backgroundBlueHeight}px`,
  maxHeight: "calc(50vh - 50px)",
});

export const positionDisplacementBlueBackground = (): CSSProperties => ({
  position: "relative",
});

export const typographyBold = (): CSSProperties => ({
  fontWeight: 500,
});

export const slider = (): CSSProperties => ({
  background: "red",
});
