import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";
import { memo } from "react";

import { useNavigation } from "../../business/redux/saga/admin/hooks";
import { reflexoHistory } from "../../business/router";
import { useTranslationStrict } from "../../globalization/i18n";
import { useSnackbarErrorMessage } from "../ux/SnackbarErrorMessage";
import useStyles from "./NavigationNodeDeleteConfirmDialog.styles";

interface NavigationNodeDeleteConfirmDialogProps {
  nodeId: number;
  open: boolean;
  onClose: () => void;
}

const NavigationNodeDeleteConfirmDialog = (
  props: NavigationNodeDeleteConfirmDialogProps
) => {
  const { nodeId, open, onClose } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const { getNavigation, deleteNavigationNode } = useNavigation();

  const handleConfirm = () => {
    deleteNavigationNode(
      nodeId,
      showErrorMessageFromDetails(t("Admin:NavigationNodeDeleteError")),
      (success) => {
        enqueueSnackbar(
          `${t("Admin:NavigationNodeDeleteSuccess")} (${success.body.title})`,
          { variant: "success" }
        );
        getNavigation("appbar");
        getNavigation("aside");
        reflexoHistory.push("/");
      }
    );
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmDelete-dialog-title"
      aria-describedby="confirmDelete-dialog-description"
    >
      <DialogTitle id="confirmDelete-dialog-title">
        {t("Admin:NavigationNodeDeleteConfirmDialogTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmDelete-dialog-description">
          {t("Admin:NavigationNodeDeleteConfirmDialogMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} autoFocus>
          {t("Common:No")}
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          className={classes.dangerButton}
        >
          {t("Common:Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(NavigationNodeDeleteConfirmDialog);
