import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import {
  buttonTextWhite,
  mobileScreen,
  smallScreen,
} from "../../styling/Common";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {
      backgroundColor: theme.palette.primary.main,
    },
    appbar: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      boxShadow: "initial",
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 0,
      paddingRight: 0,
      gridColumn: 2,
      gridRow: 1,
    },
    ...mobileScreen({
      appBarLinks: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1vh",
      },
      logo: {
        marginBottom: "2.8vh",
        marginTop: "1vh",
      },
      search: {
        marginBottom: "2.8vh",
        marginTop: "1vh",
      },
    }),
    ...smallScreen({
      toolbar: {
        display: "grid",
        gridGap: theme.spacing() * 2,
        gridTemplateColumns: "7% 55% 8% 25%",
        gridTemplateRows: "60px",
      },

      appBarLinks: {
        display: "inline-block",
      },
    }),
    dashboard: {
      gridColumn: 1,
      gridRow: 1,
    },
    appBarLinks: {
      "& > *": {
        margin: 5,
      },
    },
    appBarNotifications: {
      float: "left",
    },
    appBarNotificationsMenu: {
      marginTop: 50,
    },
    button: { ...buttonTextWhite(theme), textTransform: "uppercase" },
    notifications: {
      flexGrow: 1,
      gridColumn: 3,
      gridRow: 1,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      gridColumn: 4,
      gridRow: 1,
      marginLeft: 0,
      width: "auto",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(),
        width: "auto",
      },
    },
    searchIcon: {
      color: theme.palette.primary.main,
      width: theme.spacing() * 9,
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing() * 8,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  })
);

export default useStyles;
