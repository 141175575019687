import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import formatEmployeeData from "../../business/formatEmployeeData";
import {
  DataDefinitionModel,
  EmployeeDataModel,
  emptyOrganization,
} from "../../business/models";
import { organizationCell } from "../../business/redux/saga/organization";
import { useTranslationStrict } from "../../globalization/i18n";

interface MyDataProps {
  keys: string[];
  data: EmployeeDataModel[];
  definitions: DataDefinitionModel[];
}

interface DataDefinitionMap {
  [code: string]: DataDefinitionModel;
}

interface EmployeeDataMap {
  [id: number]: EmployeeDataModel;
}

const MyData = ({ keys, data, definitions }: MyDataProps): JSX.Element => {
  const definitionByCode = useMemo((): DataDefinitionMap => {
    const empty: DataDefinitionMap = {};
    return definitions.reduce((obj, val): DataDefinitionMap => {
      // eslint-disable-next-line no-param-reassign
      obj[val.code] = val;
      return obj;
    }, empty);
  }, [definitions]);
  const dataById = useMemo((): EmployeeDataMap => {
    const empty: EmployeeDataMap = {};
    return data.reduce((obj, val): EmployeeDataMap => {
      // eslint-disable-next-line no-param-reassign
      obj[val.dataDefinitionId] = val;
      return obj;
    }, empty);
  }, [data]);
  const isB2B = (
    useSelector(organizationCell.select).value ?? emptyOrganization
  ).isB2B;
  const [t, { language }] = useTranslationStrict();
  return (
    <Table>
      <TableBody>
        {keys
          .filter((code) => !isB2B || dataById[definitionByCode[code].id])
          .map((code) => ({
            code,
            value: formatEmployeeData(
              definitionByCode[code],
              dataById[definitionByCode[code].id],
              t,
              language
            ),
          }))
          .filter(
            ({ value }) => value !== null && value !== undefined && value !== ""
          )
          .map(
            (kvp): JSX.Element => (
              <TableRow key={kvp.code}>
                <TableCell scope="row" variant="head">
                  {t(`Variables:${kvp.code}`)}
                </TableCell>
                <TableCell>{kvp.value}</TableCell>
              </TableRow>
            )
          )}
      </TableBody>
    </Table>
  );
};

export default memo(MyData);
