import {
  CircularProgress,
  FormLabel,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { ChevronRight, Search as SearchIcon } from "@material-ui/icons";
import { debounce } from "lodash/fp";
import React, {
  KeyboardEventHandler,
  memo,
  useCallback,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { SearchItemModel } from "../../business/models";
import { StoreModel } from "../../business/redux/saga/models";
import { searchCell } from "../../business/redux/saga/search/cells";
import { useTranslationStrict } from "../../globalization/i18n";
import useStyles from "./Search.styles";

const Search = () => {
  // Local hooks
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const history = useHistory();

  // Redux hooks
  const dispatch = useDispatch();
  const { loading, query, items, totalCount } = useSelector(
    ({
      search: {
        status: { loading },
        value: { query, items, totalCount } = {
          query: "",
          items: [],
          totalCount: 0,
        },
      },
    }: StoreModel) => ({ loading, query, items, totalCount })
  );

  // Handler hooks
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(300, (query: string) => dispatch(searchCell.require({ query }))),
    [dispatch]
  );
  const handleBlur = useCallback(() => {
    window.setTimeout(() => setFocus(false), 100);
  }, [setFocus]);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => debouncedSearch(e.target.value),
    [debouncedSearch]
  );
  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        if (items.length > 0) {
          const item = items[0];
          history.push(item.id);
        }
      }
    },
    [history, items]
  );

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={t("Common:SearchPlaceholder")}
      />
      <Paper
        className={focus && query.length > 2 ? classes.open : classes.closed}
        classes={{
          root: classes.paper,
        }}
      >
        <FormLabel>
          {
            // eslint-disable-next-line no-nested-ternary
            loading
              ? t("Common:FoundBusy")
              : totalCount === 0
              ? t("Common:FoundNothing")
              : t("Common:Found")
          }{" "}
          {query} {loading && <CircularProgress size={16} />}
        </FormLabel>
        <List>
          {items.map(
            (item: SearchItemModel): JSX.Element => (
              <Link key={item.id} to={item.id}>
                <ListItem>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              </Link>
            )
          )}
        </List>
      </Paper>
    </div>
  );
};

export default memo(Search);
