// eslint-disable-next-line import/no-unresolved
import {
  Choice,
  OptimalChoice,
  Source,
  TaxedOptimalChoice,
} from "@twijg/loonheffing";

import {
  firstOfMonth,
  fourWeekPeriodsCompleteAt,
  monthsCompleteAt,
} from "../utils/DateUtils";
import { toAmount } from "../utils/NumberUtils";
import {
  createChoice,
  createEmployee,
  createEmployer,
  optimizeChoices,
  period,
} from "./loonheffing";
import {
  ChoiceCategory,
  ChoiceInterval,
  ChoiceModel,
  ChoicePeriod,
  ChoiceSetModel,
  ChoiceUploadModel,
  ChoiceValueModel,
  DataDefinitionModel,
  EmployerModel,
  OrganizationModel,
} from "./models";
import { ChoiceDefinitionModel } from "./redux/saga/admin/choices/models";

const fromNowUntilYearEnd: ChoicePeriod = {};

export interface ChoiceData extends ChoicePeriod {
  id: number;
  definition: ChoiceDefinitionModel;
  dataDefinition: DataDefinitionModel;
  maxValue: number;
  stringValue?: string;
  currentValue: number;
  hourValue: number;
  repeating: boolean;
  uploads: ChoiceUploadModel[];
}

export interface WkrLimit {
  wkrChosen: number;
  wkrYearMax: number;
  wkrAvailable: number;
}

export interface ChoiceSum {
  alcMoneyUsed: number;
  alcHoursUsed: number;
  wkrBudget: number;
  alcHoursChosen: number;
  alcMoneyChosen: number;
  wkrOnlyChosen: number;
  wkrFirstChosen: number;
  sumSources: number;
  sumWkrGoals: number;
  sumGoals: number;
}

export interface ChoiceTotal {
  differenceAlc: number;
  totalGoalValue: number;
  totalSourceValue: number;
  summaryTotal: SummaryTotal;
  optimal?: OptimalChoice;
  salaryEffect?: SalaryEffect;
  choiceValues: ChoiceValueModel[];
}

export interface SalaryEffect {
  amountOnce: number;
  amountRepeating: number;
}

export interface SummaryTotal {
  goals: number;
  sources: number;
  wkrGoals: number;
  mixedPeriods: boolean;
}

const dummyMeta = {
  mixedPeriods: false,
};

interface ChoiceAssignment {
  values: ChoiceValueModel[];
  salaryEffect: SalaryEffect;
  summaryTotal: SummaryTotal;
}

const noChoiceValues: ChoiceValueModel[] = [];

const wkrChoiceCategories: ChoiceCategory[] = ["wkr", "wkrFirst", "wkrGift"];
const hasWkrCategory = (choice: ChoiceData): boolean =>
  wkrChoiceCategories.includes(choice.dataDefinition.choiceCategory);

const toFourWeekPeriodsComplete = (
  chosenValue: number | undefined
): number | undefined =>
  chosenValue
    ? chosenValue < 0
      ? -chosenValue
      : fourWeekPeriodsCompleteAt(firstOfMonth(chosenValue))
    : undefined;

const getMonthAt = (interval: ChoiceInterval, date: Date): number => {
  switch (interval) {
    case ChoiceInterval.monthly:
      return monthsCompleteAt(date) + 1;
    case ChoiceInterval.fourWeekly:
      return -fourWeekPeriodsCompleteAt(date) - 1;
    default:
      throw new Error(`Argument interval = ${interval} out of range`);
  }
};

const getPeriods = (
  { startMonth, endMonth }: ChoicePeriod,
  interval: ChoiceInterval,
  endDate: Date
): number => {
  switch (interval) {
    case ChoiceInterval.annually:
      return 1;
    case ChoiceInterval.monthly:
      return (
        (endMonth ? monthsCompleteAt(firstOfMonth(endMonth)) : 12) -
        monthsCompleteAt(startMonth ? firstOfMonth(startMonth) : endDate)
      );
    case ChoiceInterval.fourWeekly:
      return (
        (toFourWeekPeriodsComplete(endMonth) ?? 13) -
        (toFourWeekPeriodsComplete(startMonth) ??
          fourWeekPeriodsCompleteAt(endDate))
      );
    default:
      throw new Error(`Argument interval = ${interval} out of range`);
  }
};

const affectsSalary = ({ code }: DataDefinitionModel): boolean => {
  switch (code) {
    case "SALARIS_JAARINKOMEN-EXCL-VAKTS_ALCB":
    case "SALARIS_JAARINKOMEN_ALCB":
    case "SALARIS_PERIODE-EXCL-VAKTS_ALCB":
    case "SALARIS_PERIODE_ALCB":
    case "WKR_BUDGET-EXCL-VAKTS_JR_WKRB":
    case "WKR_BUDGET-EXCL-VAKTS_PER_WKRB":
    case "WKR_BUDGET_JR_WKRB":
    case "WKR_BUDGET_PER_WKRB":
    case "BHV-VERGOEDING_ALCB":
    case "DERTIENDE_MAAND_ALCB":
    case "EINDEJAARSUITKERING_ALCB":
    case "DUURZAAMHEIDSBUDGET_ALCB":
    case "INFLATIEBUDGET_ALCB":
    case "JUBILEUMUITKERING_12-5-JR_ALCB":
    case "JUBILEUMUITKERING_25JR_ALCB":
    case "JUBILEUMUITKERING_40JR_ALCB":
    case "ONKOSTENVERGOEDING_ALGEMEEN_PER_ALCB":
    case "PIZ-UITKERING_ALCB":
    case "TOESLAG_AUTOTERBESCHIKKING_ALCB":
    case "TOESLAG_ARBEIDSMARKT_ALCB":
    case "TOESLAG_CONSIGNATIE_ALCB":
    case "TOESLAG_ONREGELMATIGEDIENST_ALCB":
    case "TOESLAG_ZIEKTEKOSTEN_ALCB":
    case "VAKANTIEGELD_ALCB":
    case "VITALITEITSBUDGET_ALCB":
    case "VRIJHEIDSBUDGET_ALCB":
      return true;
    default:
      return false;
  }
};

const getHolidayFactor = (dataDefinition: DataDefinitionModel): number =>
  affectsSalary(dataDefinition) &&
  /^(SALARIS|WKR_BUDGET)/.test(dataDefinition.code) &&
  !/EXCL-VAKTS/.test(dataDefinition.code)
    ? 1.08
    : 1;

const getChoiceFactor = (
  period: ChoicePeriod,
  { interval }: ChoiceDefinitionModel,
  dataDefinition: DataDefinitionModel,
  endDate: Date
): number =>
  getPeriods(period, interval, endDate) * getHolidayFactor(dataDefinition);

export const getChoiceValue = (
  choiceData: ChoiceData,
  value = choiceData.currentValue,
  endDate: Date
): number =>
  getChoiceFactor(
    choiceData as ChoicePeriod,
    choiceData.definition,
    choiceData.dataDefinition,
    endDate
  ) * value;

export const getSummaryValue = (
  value: ChoiceValueModel,
  choice: ChoiceData,
  periods: number,
  endDate: Date
): number =>
  toAmount(
    getPeriods(choice, choice.definition.interval, endDate) * value.amountOnce +
      value.amountRepeating /
        (choice.definition.interval === ChoiceInterval.annually ? periods : 1)
  );

export const toCurrentValue = (
  period: ChoicePeriod,
  definition: ChoiceDefinitionModel,
  dataDefinition: DataDefinitionModel,
  choiceValue: number,
  endDate: Date
): number =>
  choiceValue / getChoiceFactor(period, definition, dataDefinition, endDate);

export const sumChoices = (cs: ChoiceData[], endDate: Date): ChoiceSum => {
  let alcMoneyUsed = 0;
  let alcHoursUsed = 0;
  let wkrBudget = 0;
  let alcMoneyChosen = 0;
  let alcHoursChosen = 0;
  let wkrOnlyChosen = 0;
  let wkrFirstChosen = 0;
  let sumSources = 0;
  let sumWkrGoals = 0;
  let sumGoals = 0;
  cs.forEach((cur): void => {
    switch (cur.dataDefinition.choiceRole) {
      case "source":
        sumSources += cur.currentValue;
        switch (cur.dataDefinition.choiceCategory) {
          case "none":
            if (cur.dataDefinition.dataType.id === -5) {
              alcMoneyUsed += getChoiceValue(cur, cur.currentValue, endDate);
            } else {
              alcHoursUsed += getChoiceValue(cur, cur.currentValue, endDate);
            }
            break;
          default:
            break;
        }
        break;
      case "destination":
        sumGoals += cur.currentValue;
        if (hasWkrCategory(cur)) {
          sumWkrGoals += getChoiceValue(cur, cur.currentValue, endDate);
        }
        switch (cur.dataDefinition.choiceCategory) {
          case "none":
            if (cur.dataDefinition.dataType.id === -5) {
              alcMoneyChosen += getChoiceValue(cur, cur.currentValue, endDate);
            } else {
              alcHoursChosen += getChoiceValue(cur, cur.currentValue, endDate);
            }
            break;
          case "wkr":
            wkrOnlyChosen += getChoiceValue(cur, cur.currentValue, endDate);
            break;
          case "wkrFirst":
            wkrFirstChosen += getChoiceValue(cur, cur.currentValue, endDate);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  });
  return {
    alcMoneyUsed,
    alcHoursUsed,
    wkrBudget,
    alcHoursChosen,
    alcMoneyChosen,
    wkrOnlyChosen,
    wkrFirstChosen,
    sumSources,
    sumWkrGoals,
    sumGoals,
  };
};

export const toChoiceAlc = ({
  alcHoursChosen,
  alcMoneyChosen,
}: ChoiceSum): Choice => {
  return createChoice(alcHoursChosen, alcMoneyChosen, 0, 0);
};

export const toChoiceWkr = ({
  wkrOnlyChosen,
  wkrFirstChosen,
}: ChoiceSum): Choice => {
  return createChoice(0, 0, 0, wkrOnlyChosen + wkrFirstChosen);
};

export const toChoiceData = (
  choice: ChoiceModel,
  hourValue: number,
  definition: DataDefinitionModel,
  uploads: ChoiceUploadModel[],
  endDate: Date,
  salaryInterval: ChoiceInterval
): ChoiceData => {
  const repeating = choice.definition.interval !== ChoiceInterval.annually;
  return {
    id: definition.id,
    definition: choice.definition,
    dataDefinition: definition,
    hourValue,
    currentValue: choice.amount,
    maxValue: choice.maximumAmount,
    repeating,
    startMonth:
      choice.startMonth ||
      getMonthAt(
        repeating ? choice.definition.interval : salaryInterval,
        endDate
      ),
    endMonth: choice.endMonth,
    uploads: uploads.filter(
      ({ dataDefinitionId }: ChoiceUploadModel) =>
        dataDefinitionId === definition.id
    ),
  };
};

export const toChoiceValueModel = (choice: ChoiceData): ChoiceValueModel => {
  return {
    dataDefinitionId: choice.id,
    amount: toAmount(choice.currentValue),
    amountMax: toAmount(choice.maxValue),
    amountOnce: 0,
    amountRepeating: 0,
    repeating: choice.repeating,
    startMonth: choice.startMonth,
    endMonth: choice.endMonth,
  };
};

const adjustAmount = (
  choice: ChoiceValueModel,
  repeating: boolean,
  value: number,
  factor: number
) => {
  const amount = value / factor;
  if (repeating) {
    choice.amountRepeating = toAmount(choice.amountRepeating + amount);
  } else {
    choice.amountOnce = toAmount(choice.amountOnce + amount);
  }
};

export const assignChoiceRepetition = (
  choices: ChoiceData[],
  optimal: TaxedOptimalChoice,
  endDate: Date
): ChoiceAssignment => {
  const alcOptimalGoal = optimal.dispensated().goal();
  const alcOptimalSource = optimal.dispensated().source().total();
  const wkrExemptGoal = optimal.wkrExempt().goal();
  const wkrExemptSource = optimal.wkrExempt().source().total();
  const wkrFinalGoal = optimal.wkrFinal().goal();
  const wkrFinalSource = optimal.wkrFinal().source().total();
  const goalTotal = alcOptimalGoal + wkrExemptGoal + wkrFinalGoal;
  const sourceTotal = alcOptimalSource + wkrExemptSource + wkrFinalSource;
  const factor = goalTotal ? sourceTotal / goalTotal : 0.0;

  const values = zip(choices.map(toChoiceValueModel), choices);

  if (factor) {
    booleans.forEach((goalWkr) => {
      booleans.forEach((goalRepeating) => {
        booleans.forEach((repetitionMatch) => {
          const sourceRepeating = goalRepeating === repetitionMatch;
          const goals = values.filter(
            ([goalValue, goalChoice]) =>
              goalChoice.dataDefinition.choiceRole === "destination" &&
              goalWkr ===
                (goalChoice.dataDefinition.choiceCategory !== "none") &&
              goalChoice.repeating === goalRepeating &&
              goalValue.amountOnce + goalValue.amountRepeating <
                goalValue.amount
          );
          goals.forEach(([goalValue, goalChoice]) => {
            const sources = values.filter(
              ([sourceValue, sourceChoice]) =>
                sourceChoice.dataDefinition.choiceRole === "source" &&
                sourceChoice.repeating === sourceRepeating &&
                sourceValue.amountOnce + sourceValue.amountRepeating <
                  sourceValue.amount
            );
            sources.forEach(([sourceValue, sourceChoice]) => {
              const goalFactor =
                factor *
                getChoiceFactor(
                  goalChoice as ChoicePeriod,
                  goalChoice.definition,
                  goalChoice.dataDefinition,
                  endDate
                );
              const sourceFactor = getChoiceFactor(
                sourceChoice as ChoicePeriod,
                sourceChoice.definition,
                sourceChoice.dataDefinition,
                endDate
              );
              const goalValueLeft =
                goalFactor *
                (goalValue.amount -
                  goalValue.amountOnce -
                  goalValue.amountRepeating);
              const sourceValueLeft =
                sourceFactor *
                (sourceValue.amount -
                  sourceValue.amountOnce -
                  sourceValue.amountRepeating);
              const value = toAmount(Math.min(goalValueLeft, sourceValueLeft));
              adjustAmount(goalValue, goalRepeating, value, goalFactor);
              adjustAmount(sourceValue, goalRepeating, value, sourceFactor);
            });
          });
        });
      });
    });
  }

  values.forEach(([value]) => {
    if (value.amountOnce >= value.amountRepeating) {
      value.amountOnce = toAmount(value.amount - value.amountRepeating);
    } else {
      value.amountRepeating = toAmount(value.amount - value.amountOnce);
    }
  });

  const periods = getPeriods(
    fromNowUntilYearEnd,
    choices.some(
      (choice) => choice.definition.interval === ChoiceInterval.fourWeekly
    )
      ? ChoiceInterval.fourWeekly
      : ChoiceInterval.monthly,
    endDate
  );
  const salaryEffect = values.reduce(
    (effect, [value, choice]): SalaryEffect => {
      return affectsSalary(choice.dataDefinition)
        ? {
            amountOnce: toAmount(
              effect.amountOnce +
                getChoiceValue(choice, value.amountOnce, endDate) /
                  getHolidayFactor(choice.dataDefinition)
            ),
            amountRepeating: toAmount(
              effect.amountRepeating +
                getChoiceValue(choice, value.amountRepeating, endDate) /
                  getHolidayFactor(choice.dataDefinition) /
                  periods
            ),
          }
        : effect;
    },
    { amountOnce: 0, amountRepeating: 0 }
  );
  let hasOnce = false;
  let hasRepeating = false;
  const { goals, sources, wkrGoals } = values.reduce(
    (total: SummaryTotal, [value, choice]): SummaryTotal => {
      const amount = getSummaryValue(value, choice, periods, endDate);
      if (amount === 0) {
        return total;
      }

      switch (choice.definition.interval) {
        case ChoiceInterval.annually:
          hasOnce = true;
          break;
        case ChoiceInterval.monthly:
        case ChoiceInterval.fourWeekly:
          hasRepeating = true;
          break;
      }

      switch (choice.dataDefinition.choiceRole) {
        case "source":
          return {
            ...total,
            sources: toAmount(total.sources + amount),
          };
        case "destination":
          return hasWkrCategory(choice)
            ? {
                ...total,
                goals: toAmount(total.goals + amount),
                wkrGoals: toAmount(
                  total.wkrGoals +
                    getChoiceValue(choice, choice.currentValue, endDate)
                ),
              }
            : {
                ...total,
                goals: toAmount(total.goals + amount),
              };
        case "none":
        default:
          return total;
      }
    },
    { goals: 0, sources: 0, wkrGoals: 0, ...dummyMeta }
  );
  const summaryTotal: SummaryTotal = {
    goals,
    sources,
    wkrGoals,
    mixedPeriods: hasOnce && hasRepeating,
  };

  return {
    values: values.map((a) => a[0]),
    salaryEffect,
    summaryTotal,
  };
};

const zip = <T, U>(one: T[], two: U[]): [T, U][] => {
  const a: [T, U][] = [];
  const len = Math.min(one.length, two.length);

  for (let i = 0; i < len; i++) {
    a.push([one[i], two[i]]);
  }

  return a;
};

const booleans = [true, false];

export const aggregateChoices = (
  organization: OrganizationModel,
  employer: EmployerModel,
  choiceSet: ChoiceSetModel,
  cs: ChoiceData[],
  endDate: Date
): ChoiceTotal | undefined => {
  const choiceSum = sumChoices(cs, endDate);
  const {
    alcHoursUsed,
    alcMoneyUsed,
    alcHoursChosen,
    alcMoneyChosen,
    wkrOnlyChosen,
    wkrFirstChosen,
    sumSources,
    sumWkrGoals,
    sumGoals,
  } = choiceSum;

  const totalGoalValue = toAmount(
    alcHoursChosen + alcMoneyChosen + wkrOnlyChosen + wkrFirstChosen
  );
  const totalSourceValue = toAmount(alcHoursUsed + alcMoneyUsed);

  if (!choiceSet.amountPerYear) {
    return {
      differenceAlc: toAmount(totalSourceValue - totalGoalValue),
      totalGoalValue,
      totalSourceValue,
      summaryTotal: {
        goals: sumGoals,
        sources: sumSources,
        wkrGoals: sumWkrGoals,
        ...dummyMeta,
      },
      choiceValues: noChoiceValues,
    };
  }

  const fiscalYear = Math.min(
    organization.forcedFiscalYear ?? period.maxYear,
    choiceSet.endDate.getFullYear()
  );
  const employerAggregated: Parameters<typeof createEmployer> = [
    employer.government,
    employer.isSmall,
    employer.whkRate,
    employer.whkRateEmployee,
    employer.benefitSharedDispensated,
    employer.benefitSharedTaxExempt,
    employer.benefitShared,
    0,
    1,
  ];
  const optimalAlc = loptimizeChoices(
    fiscalYear,
    employerAggregated,
    [
      choiceSet.amountPerYear,
      choiceSet.permanent,
      choiceSet.wkrBudget,
      1e9,
      1,
      alcHoursUsed,
      1e9,
    ],
    choiceSum,
    toChoiceAlc
  );

  const optimalWkr = loptimizeChoices(
    fiscalYear,
    employerAggregated,
    [
      choiceSet.amountPerYear - optimalAlc.total().source().salary(),
      choiceSet.permanent,
      choiceSet.wkrBudget,
      1e9,
      1,
      alcHoursUsed - optimalAlc.total().source().hours(),
      1e9,
    ],
    choiceSum,
    toChoiceWkr
  );

  const diff = toAmount(
    totalSourceValue -
      optimalAlc.total().source().total() -
      optimalWkr.total().source().total()
  );
  const optimal = optimalAlc.plus(optimalWkr);

  if (diff !== 0) {
    return {
      differenceAlc: diff,
      totalGoalValue,
      totalSourceValue,
      summaryTotal: {
        goals: sumGoals,
        sources: sumSources,
        wkrGoals: sumWkrGoals,
        ...dummyMeta,
      },
      optimal: optimal.total(),
      choiceValues: noChoiceValues,
    };
  }

  const {
    salaryEffect,
    values: choiceValues,
    summaryTotal,
  } = assignChoiceRepetition(cs, optimal, endDate);
  return {
    differenceAlc: 0,
    totalGoalValue,
    totalSourceValue,
    summaryTotal,
    optimal: optimal.total(),
    salaryEffect,
    choiceValues,
  };
};

const alacarteDebug = <T>(prefix: string, value: T) => {
  if (
    process.env.NODE_ENV !== "production" ||
    (window as unknown as { alacarteDebug: boolean }).alacarteDebug
  ) {
    // console.debug(`alacarte ${prefix}`, value);
  }
};

const loptimizeChoices = (
  year: number,
  employer: Parameters<typeof createEmployer>,
  employee: Parameters<typeof createEmployee>,
  choiceSum: ChoiceSum,
  toChoice: typeof toChoiceAlc
): TaxedOptimalChoice => {
  alacarteDebug("year", year);
  alacarteDebug("employer", employer);
  alacarteDebug("employee", employee);
  alacarteDebug("choiceSum", choiceSum);
  const choice = toChoice(choiceSum);
  alacarteDebug("choice", {
    goalWkr: choice.goalWkr(),
    goalWkrSpecial: choice.goalWkrSpecial(),
    goalDispensated: choice.goalDispensated(),
    goalHours: choice.goalHours(),
  });
  const optimal = optimizeChoices(
    year,
    createEmployer(...employer),
    createEmployee(...employee),
    choice
  );
  const sourceObj = (s: Source) => ({
    hours: s.hours(),
    salary: s.salary(),
    total: s.total(),
  });
  const choiceObj = (c: OptimalChoice) => ({
    goal: c.goal(),
    source: sourceObj(c.source()),
    employerGift: c.employerGift(),
    employerShared: c.employerShared(),
    employerBenefit: c.employerBenefit(),
    employeeBenefit: c.employeeBenefit(),
  });
  alacarteDebug("optimal", {
    wkrFinal: choiceObj(optimal.wkrFinal()),
    wkrExempt: choiceObj(optimal.wkrExempt()),
    total: choiceObj(optimal.total()),
    dispensated: choiceObj(optimal.dispensated()),
    hours: choiceObj(optimal.hours()),
    moneyTotal: choiceObj(optimal.moneyTotal()),
    wkrTotal: choiceObj(optimal.wkrTotal()),
  });

  return optimal;
};
