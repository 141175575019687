import { addHours, startOfDay } from "date-fns";

export const toDate = (raw?: string | null): Date | undefined =>
  raw ? new Date(raw) : undefined;

export const fromDate = (raw?: Date | null): string | undefined =>
  raw ? raw.toISOString() : undefined;

export const minDate = new Date("0001-01-01T00:00:00Z");
const now = new Date();
export const firstOfMonth = (month: number): Date =>
  new Date(now.getFullYear(), month - 1, 1);

const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const lastOfCurrentMonth = (): Date => {
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = days[month - 1];
  return new Date(
    year,
    month - 1,
    month === 2 && year % 4 === 0 ? day + 1 : day
  );
};
export const monthsCompleteAt = (date: Date): number => date.getMonth();
export const monthsCompleteNow = monthsCompleteAt(now);

const ticksPerDay = 1000 * 60 * 60 * 24;
export const fourWeekPeriodsCompleteAt = (date: Date): number =>
  Math.min(
    12,
    Math.floor(
      (date.valueOf() - new Date(date.getFullYear(), 0, 1).valueOf()) /
        ticksPerDay /
        28
    )
  );
export const fourWeekPeriodsCompleteNow = fourWeekPeriodsCompleteAt(now);

const DateDifference = {
  seconds: (date1: Date, date2: Date): number => {
    const time2 = date2.getTime();
    const time1 = date1.getTime();

    return (time2 - time1) / 1000;
  },

  minutes: (date1: Date, date2: Date): number => {
    const time2 = date2.getTime();
    const time1 = date1.getTime();

    return (time2 - time1) / (60 * 1000);
  },

  hours: (date1: Date, date2: Date): number => {
    const time2 = date2.getTime();
    const time1 = date1.getTime();

    return (time2 - time1) / (3600 * 1000);
  },

  days: (date1: Date, date2: Date): number => {
    const time2 = date2.getTime();
    const time1 = date1.getTime();

    return (time2 - time1) / (24 * 3600 * 1000);
  },

  weeks: (date1: Date, date2: Date): number => {
    const time2 = date2.getTime();
    const time1 = date1.getTime();

    return (time2 - time1) / (24 * 3600 * 1000 * 7);
  },

  months: (date1: Date, date2: Date): number => {
    const date1Y = date1.getFullYear();
    const date2Y = date2.getFullYear();
    const date1M = date1.getMonth();
    const date2M = date2.getMonth();

    return date2M + 12 * date2Y - (date1M + 12 * date1Y);
  },

  years: (date1: Date, date2: Date): number => {
    return date2.getFullYear() - date1.getFullYear();
  },
};

export default DateDifference;

export const toNoon = (date: Date): Date => addHours(startOfDay(date), 12);

export const renderDate = (date: Date | null, language = "nl-NL"): string => {
  return date
    ? new Intl.DateTimeFormat(language, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(date))
    : "";
};

export const renderDateTime = (date: Date, language = "nl-NL"): string => {
  return new Intl.DateTimeFormat(language, {
    hour12: false,
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
};
