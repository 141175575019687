import { call, put, takeEvery } from "redux-saga/effects";

import { clearTokens } from "../../../../utils/SecurityUtils";
import { announcementsListPagedCell } from "../announcements/cells";
import {
  authenticationKnowledgeCell,
  authenticationPossessionCell,
  authenticationRefreshCell,
} from "../authentication/cells";
import { navigationMenuCell, navigationTreeCell } from "../navigation/cells";
import { profileCell } from "../profile/cells";

const clear = "APP_CLEAR";
export const appCell = {
  events: {
    clear,
  },
  clear: () => ({ type: appCell.events.clear }),
  sagas: [
    takeEvery(clear, function* () {
      yield call(clearTokens);
      yield put({ type: announcementsListPagedCell.events.clear });
      yield put({ type: authenticationKnowledgeCell.events.clear });
      yield put({ type: authenticationPossessionCell.events.clear });
      yield put({ type: authenticationRefreshCell.events.clear });
      yield put({ type: navigationMenuCell.events.clear });
      yield put({ type: navigationTreeCell.events.clear });
      yield put({ type: profileCell.events.clear });
    }),
  ],
};
