import { AsyncResponse } from "async-lifecycle-saga";

import { asyncPost, asyncPut } from "../../../../api";
import { UserModel } from "../../../../models";

export const userAddPoster = (
  user: UserModel
): Promise<AsyncResponse<UserModel>> =>
  asyncPost<UserModel>("/api/admin/users", user);

export const userUpdatePutter = (
  user: UserModel
): Promise<AsyncResponse<UserModel>> =>
  asyncPut<UserModel>("/api/admin/users", user);
