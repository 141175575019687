import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";

import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import Loading from "../../ux/Loading";
import { WlzAdviceCaseDialog, WlzAdviceCaseEditButton } from "./";

interface Props {
  navigationNodeId: number;
}

const WlzAdviceCasesTable = (props: Props) => {
  const { navigationNodeId } = props;
  const [t] = useTranslationStrict();
  const {
    status: { loading },
    value: adviceCases,
  } = useSelector((store: StoreModel) => store.personal.wlz.adviceCases);

  const [dialogNewOpen, setDialogNewOpen] = useState(false);

  const handleNewClick = () => {
    setDialogNewOpen(true);
  };

  const handleSubmitted = () => {
    setDialogNewOpen(false);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="Title" style={{ width: "80%" }}>
              {t("Wlz:Titel")}
            </TableCell>
            <TableCell key="Actions" style={{ width: "20%" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            adviceCases &&
            Array.isArray(adviceCases) &&
            adviceCases.length === 0 && (
              <TableRow key="empty">
                <TableCell key="empty" colSpan={2}>
                  {t("Wlz:Geen resultaten")}
                </TableCell>
              </TableRow>
            )}
          {!loading &&
            adviceCases &&
            Array.isArray(adviceCases) &&
            adviceCases.map(({ id, title }) => (
              <TableRow key={id}>
                <TableCell key="Title">{title}</TableCell>
                <TableCell key="Actions" style={{ textAlign: "right" }}>
                  {id && (
                    <WlzAdviceCaseEditButton
                      adviceCaseId={id}
                      navigationNodeId={navigationNodeId}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          {loading && !adviceCases && (
            <TableRow key="loading">
              <TableCell key="loading" colSpan={2}>
                <Loading />
              </TableCell>
            </TableRow>
          )}
          <TableRow key="new">
            <TableCell key="newTitle"></TableCell>
            <TableCell key="newActions" style={{ textAlign: "right" }}>
              <Button
                color="primary"
                onClick={handleNewClick}
                variant="contained"
              >
                {t("Wlz:Nieuw")}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {dialogNewOpen && (
        <WlzAdviceCaseDialog
          navigationNodeId={navigationNodeId}
          onSubmitted={handleSubmitted}
          open={dialogNewOpen}
        />
      )}
    </>
  );
};

export default WlzAdviceCasesTable;
