import { Button, CircularProgress } from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import FileSaver from "file-saver";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { VariableDataFilter } from "../../../business/personal/employeeData";
import { datacockpitVariableDataExportActionCell } from "../../../business/redux/saga/datacockpit/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";

interface Props {
  filter: VariableDataFilter;
}

const VariablesExportButton = (props: Props) => {
  const { filter } = props;
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();
  const {
    status: { loading },
  } = useSelector(
    (store: StoreModel) => store.datacockpit.variableData.exportAction
  );

  const handleExport = () => {
    dispatch(
      datacockpitVariableDataExportActionCell.require(filter, {
        onFail: showErrorMessageFromDetails(
          t("Datacockpit:Exporteren mislukt")
        ),
        onSuccess: ({ body: { data } }) => {
          FileSaver.saveAs(data, "Export.xlsx");
        },
      })
    );
  };

  return (
    <Button
      color="primary"
      disabled={loading || filter.variableIds.length === 0}
      onClick={handleExport}
      startIcon={
        loading ? (
          <CircularProgress size={20} />
        ) : (
          <DownloadIcon fontSize="small" />
        )
      }
      variant="contained"
    >
      {t("Datacockpit:Exporteren")}
    </Button>
  );
};

export default VariablesExportButton;
