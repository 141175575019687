import { Typography } from "@material-ui/core";

import { useTranslationStrict } from "../../../globalization/i18n";
import Microsoft365LoginDefault from "./Microsoft365LoginDefault";

const Microsoft365DoesNotMatchMijnHRPlaza = () => {
  const [t] = useTranslationStrict();

  return (
    <>
      <Typography variant="body1">
        {t("Authentication:Microsoft365DoesNotMatchMijnHRPlazaBody1")}
      </Typography>
      <Microsoft365LoginDefault />
    </>
  );
};

export default Microsoft365DoesNotMatchMijnHRPlaza;
