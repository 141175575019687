import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import React from "react";
import { JssProvider } from "react-jss";

export const createJssInsertionPoint = (id = "jss-insertion-point"): void => {
  if (document.getElementById(id)) {
    return;
  }

  const jssInsertionPointElement = document.createElement("noscript");
  jssInsertionPointElement.setAttribute("id", id);
  document.getElementsByTagName("head")[0].prepend(jssInsertionPointElement);
};

export const withJssProvider = (
  element: React.ReactElement
): React.ReactElement => {
  const jssInsertionPoint = document.getElementById("jss-insertion-point");
  const jss = create({
    ...jssPreset(),
    insertionPoint: jssInsertionPoint || undefined,
  });
  const generateClassName = createGenerateClassName();

  return (
    <JssProvider jss={jss} generateId={generateClassName}>
      {element}
    </JssProvider>
  );
};
