import { createActor, createSingle } from "async-lifecycle-saga";
import { Success } from "async-lifecycle-saga/dist/models";
import querystring from "query-string";

import { toDate } from "../../../../../utils/DateUtils";
import {
  UploadStatusModel,
  UploadTypeModel,
} from "../../../../admin/upload/models";
import { asyncDelete, asyncGet, asyncPost } from "../../../../api";
import { PagedList } from "../../../../models";
import { StoreModel } from "../../models";
import { adminPeekDownloadsCell } from "../peek/cells";
import { adminUploadAddFormPoster } from "./api";
import { JsonUploadModel } from "./models";

const uploadStatusModelFromJson = (
  json: JsonUploadModel
): UploadStatusModel => ({
  ...json,
  uploadDateTime: toDate(json.uploadDateTime) as Date,
  uploadEffectiveDate: toDate(json.uploadEffectiveDate) as Date,
  checkDateTime: toDate(json.checkDateTime),
  processDateTime: toDate(json.processDateTime),
});

export const adminUploadListCell = createActor<
  { pageNumber?: number; pageSize?: number; includePreviousPages?: boolean },
  PagedList<UploadStatusModel>,
  StoreModel
>({
  path: ["admin", "upload", "list"],
  api: ({ pageNumber, pageSize = 5, includePreviousPages = false }) =>
    asyncGet(
      `/api/upload?${querystring.stringify({
        pageNumber,
        pageSize,
        includePreviousPages,
      })}`
    ).then((response) =>
      response.status === 200
        ? {
            ...response,
            body: {
              ...(response as Success<PagedList<JsonUploadModel>>).body,
              pageItems: (
                response as Success<PagedList<JsonUploadModel>>
              ).body.pageItems.map(uploadStatusModelFromJson),
            },
          }
        : response
    ),
});

export const adminUploadAddCell = createActor<
  {
    employeeId?: number;
    uploadTypeId: number;
    effectiveDate: Date;
    file: File;
  },
  {}
>({
  path: ["admin", "upload", "add"],
  api: ({ employeeId, uploadTypeId, effectiveDate, file }) =>
    adminUploadAddFormPoster(employeeId, uploadTypeId, effectiveDate, file),
  invalidate: [adminPeekDownloadsCell.events, adminUploadListCell.events],
});

export const adminUploadCheckCell = createActor<number, UploadStatusModel>({
  path: ["admin", "upload", "check"],
  api: (uploadId) =>
    asyncPost<JsonUploadModel>(`/api/upload/${uploadId}/check`, {}).then(
      (response) =>
        response.status === 200
          ? {
              ...response,
              body: uploadStatusModelFromJson(
                (response as Success<JsonUploadModel>).body
              ),
            }
          : response
    ),
  invalidate: [adminUploadListCell.events],
});

export const adminUploadDeleteCell = createActor<number, undefined>({
  path: ["admin", "upload", "delete"],
  api: (uploadId) => asyncDelete<undefined>(`/api/upload/${uploadId}`),
  invalidate: [adminUploadListCell.events],
});

export const adminUploadProcessCell = createActor<
  { id: number; again: boolean; startChoicePeriod: boolean },
  never
>({
  path: ["admin", "upload", "process"],
  api: ({ id, again, startChoicePeriod }) =>
    asyncPost(
      `/api/upload/${id}/process?again=${again}&startChoicePeriod=${startChoicePeriod}`,
      {}
    ),
  invalidate: [adminUploadListCell.events],
});

export const adminUploadTypesCell = createSingle<UploadTypeModel[], StoreModel>(
  {
    path: ["admin", "upload", "types"],
    require: {
      api: () => asyncGet("/api/upload/types"),
    },
  }
);
