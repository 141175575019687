import { createSingle } from "async-lifecycle-saga";

import { asyncGet } from "../../../../api";
import { StoreModel } from "../../models";

export const adminEmployeeTypesCell = createSingle<string[], StoreModel>({
  path: ["admin", "employee", "types"],
  require: {
    api: () => asyncGet<string[]>("/api/admin/roles"),
  },
});
