import { MenuItem } from "@material-ui/core";
import { ClearAll, HowToReg as HowToRegIcon } from "@material-ui/icons";
import KeyIcon from "@mui/icons-material/Key";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserModel } from "../../../business/models";
import { useUserConfig } from "../../../business/redux/saga/admin/hooks";
import { StoreModel } from "../../../business/redux/saga/models";
import { userGenerateTokenCell } from "../../../business/redux/saga/user/cells";
import useConfirm from "../../../confirm";
import { useTranslationStrict } from "../../../globalization/i18n";
import RefreshTokenNotification from "../../notification/RefreshTokenNotification";

interface Props {
  user: UserModel;
}

const UserActionsMenuItem = (props: Props) => {
  const { user } = props;
  const [t, { language }] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { inviteUser, resetChoices } = useUserConfig();

  const { currentUser } = useSelector(({ session: { user } }: StoreModel) => ({
    currentUser: user,
  }));

  const generateToken = useCallback(() => {
    dispatch(
      userGenerateTokenCell.require(user.id, {
        onSuccess: ({ body }) =>
          enqueueSnackbar(
            <RefreshTokenNotification token={body.refreshToken} />,
            {
              ContentProps: {
                style: {
                  backgroundColor: "green",
                  padding: 0,
                  width: "100%",
                },
              },
            }
          ),
      })
    );
  }, [dispatch, enqueueSnackbar, user.id]);

  const handleInvite = useCallback((): void => {
    inviteUser(
      user.loginName,
      language,
      (): void => {
        enqueueSnackbar(
          t("Admin:UserInviteError", { loginName: user.loginName }),
          {
            variant: "error",
          }
        );
      },
      (): void => {
        enqueueSnackbar(
          t("Admin:UserInviteSuccess", { loginName: user.loginName }),
          {
            variant: "success",
          }
        );
      }
    );
  }, [enqueueSnackbar, inviteUser, language, t, user.loginName]);

  const { confirm } = useConfirm();

  const handleReset = useCallback((): void => {
    const doit = () =>
      resetChoices(
        user.id,
        (): void => {
          enqueueSnackbar(
            t("Admin:ResetChoicesError", { loginName: user.loginName }),
            {
              variant: "error",
            }
          );
        },
        (): void => {
          enqueueSnackbar(
            t("Admin:ResetChoicesSuccess", { loginName: user.loginName }),
            {
              variant: "success",
            }
          );
        }
      );

    confirm({
      texts: {
        ns: "Admin",
        title: "Verwijderen",
        content: "ResetChoicesConfirmMessage",
        confirm: "Yes",
        cancel: "No",
      },
      onConfirm: doit,
      onCancel: () => {},
    });
  }, [confirm, enqueueSnackbar, resetChoices, t, user.id, user.loginName]);

  return (
    <>
      {user.hasApiAccess ? (
        currentUser?.isApiAdmin && (
          <MenuItem onClick={generateToken}>
            <KeyIcon /> {t("Admin:GenerateToken")}
          </MenuItem>
        )
      ) : (
        <MenuItem onClick={handleInvite}>
          <HowToRegIcon /> {t("Admin:UserInvite")}
        </MenuItem>
      )}
      <MenuItem onClick={handleReset}>
        <ClearAll /> {t("Admin:ResetChoices")}
      </MenuItem>
    </>
  );
};

export default UserActionsMenuItem;
