import { Button } from "@material-ui/core";
import { Details } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import React, { memo } from "react";

import { useTranslationStrict } from "../../globalization/i18n";

export enum SnackbarErrorActionType {
  primary = "primary",
  secondary = "secondary",
}

export interface SnackbarErrorAction {
  /**
   * Determines what type of button is rendered for the action.
   */
  actionType: SnackbarErrorActionType;

  /**
   * The caption for the button of the action.
   */
  caption: string;

  /**
   * The click event handler.
   */
  onClick: () => void;
}

interface SnackbarErrorMessageProps {
  actions?: SnackbarErrorAction[];
  message: string;
  messageDetail?: string;
  title: string;
}

const SnackbarErrorMessage = ({
  actions,
  message,
  messageDetail,
  title,
}: SnackbarErrorMessageProps) => {
  return (
    <div style={{ display: "block" }}>
      <strong>{title}</strong>
      <br />
      {messageDetail ? `${message} (${messageDetail})` : message}
      <br />
      {actions?.map(({ actionType, caption, onClick }) => (
        <Button color={actionType} onClick={onClick} size="small">
          {caption}
        </Button>
      ))}
    </div>
  );
};

type SnackbarErrorMessageHideFunction = (key?: string | number) => void;
type SnackbarErrorMessageShowFunction = (
  props: SnackbarErrorMessageProps,
  key?: string | number
) => void;
export type SnackbarErrorMessageShowFromDetailsFunction = (
  title: string
) => (details: Details) => void;
type SnackbarErrorMessageShowFromErrorFunction = (
  title: string
) => (error: unknown) => void;

interface SnackbarErrorMessageHook {
  hideErrorMessage: SnackbarErrorMessageHideFunction;
  showErrorMessage: SnackbarErrorMessageShowFunction;
  showErrorMessageFromDetails: SnackbarErrorMessageShowFromDetailsFunction;
  showErrorMessageFromError: SnackbarErrorMessageShowFromErrorFunction;
}

export const useSnackbarErrorMessage = (): SnackbarErrorMessageHook => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [t] = useTranslationStrict();

  const showErrorMessage: SnackbarErrorMessageShowFunction = (props, key) => {
    enqueueSnackbar(<SnackbarErrorMessage {...props} />, {
      key,
      variant: "error",
    });
  };

  const showErrorMessageFromDetails: SnackbarErrorMessageShowFromDetailsFunction =
    (title) => (details) => {
      const props: SnackbarErrorMessageProps = {
        title,
        message: details.title || t(`HttpStatus${details.status}`),
        messageDetail: details.detail
          ? t("Common:TechnicalError", {
              error: details.detail,
            })
          : undefined,
      };
      showErrorMessage(props);
    };

  const showErrorMessageFromError: SnackbarErrorMessageShowFromErrorFunction =
    (title) => (error) => {
      let message: string;
      switch (true) {
        case error instanceof Error:
          message = (error as Error).message;
          break;
        case typeof error === "string":
          message = error as string;
          break;
        default:
          message = (error as object).toString();
          break;
      }
      const props: SnackbarErrorMessageProps = {
        title,
        message,
      };
      showErrorMessage(props);
    };

  return {
    hideErrorMessage: (key?: string | number) => {
      closeSnackbar(key);
    },
    showErrorMessage,
    showErrorMessageFromDetails,
    showErrorMessageFromError,
  };
};

export default memo(SnackbarErrorMessage);
