import { TableCell, TableSortLabel, Tooltip } from "@material-ui/core";
import React, { ReactNode, memo, useCallback } from "react";

import { SortOrder } from "../../../business/models";

export enum SortableColumnVariant {
  default = "default",
  role = "role",
}

interface Props {
  active: boolean;
  index: number;
  label: ReactNode;
  onSort: (sortIndex: number) => void;
  sortOrder: SortOrder;
  tooltipTitle?: string;
  variant?: SortableColumnVariant;
}

const UsersEditorTableHeadSortableColumn = (props: Props) => {
  const {
    active,
    index,
    label,
    onSort,
    sortOrder,
    tooltipTitle,
    variant = SortableColumnVariant.default,
  } = props;
  const handleClick = useCallback(() => {
    onSort(index);
  }, [index, onSort]);

  return (
    <TableCell
      style={{
        textAlign: variant === "role" ? "center" : undefined,
        paddingLeft: variant === "role" ? 30 : undefined,
        textTransform: "uppercase",
      }}
    >
      <TableSortLabel
        active={active}
        direction={sortOrder}
        onClick={handleClick}
      >
        {tooltipTitle ? (
          <Tooltip title={tooltipTitle}>
            <span>{label}</span>
          </Tooltip>
        ) : (
          label
        )}
      </TableSortLabel>
    </TableCell>
  );
};

export default memo(UsersEditorTableHeadSortableColumn);
