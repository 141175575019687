import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 2 * theme.spacing(),
    padding: 24,
  },
  avatar: {
    display: "inline-flex",
  },
  dialog: {
    minWidth: "650px !important",
  },
}));

export default useStyles;
