import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import {
  buttonTextBlack,
  mobileScreen,
  smallScreen,
  tabletScreen,
} from "../../styling/Common";
import { LogoHeight } from "../layout/Ribbon.styles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {
      display: "inline-block",
      float: "right",
      textAlign: "right",

      "& > *": {
        marginLeft: theme.spacing(),
      },
    },
    button: {
      padding: theme.spacing() / 1.25,
    },
    buttonEmployee: buttonTextBlack(theme),
    ...mobileScreen({
      root: {
        "& > *": {
          marginLeft: 2,
        },
      },
      logoutLabel: {
        display: "none",
      },
      myDataLabel: {
        display: "none",
      },
      profileLoginNameLabel: {
        display: "none",
      },
    }),
    ...tabletScreen({
      logoutLabel: {
        display: "none",
      },
      myDataLabel: {
        display: "none",
      },
      profileLoginNameLabel: {
        display: "none",
      },
    }),
    ...smallScreen({
      logoutLabel: {
        display: "inline-block",
      },
      myDataLabel: {
        display: "inline-block",
      },
      profileLoginNameLabel: {
        display: "inline-block",
      },
    }),
    paper: {
      padding: theme.spacing() * 2,
    },
    profileLoginName: {
      float: "right",
      fontWeight: "bold",
      lineHeight: `${LogoHeight}px`,
      marginLeft: 3,
    },
    profileLoginNameEmployee: {
      color: theme.palette.common.black,
    },
  })
);

export default useStyles;
