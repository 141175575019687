import { Card, CardContent } from "@material-ui/core";
import { asyncIsValid } from "async-lifecycle-saga";
import { compact, flow, map } from "lodash/fp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VariableDataFilter } from "../../../business/personal/employeeData";
import { datacockpitVariableDataTableFetchCell } from "../../../business/redux/saga/datacockpit/cells";
import { StoreModel } from "../../../business/redux/saga/models";
import { VariablesOverviewFilter, VariablesOverviewTable } from "./";

const initialValue: VariableDataFilter = {
  variableIds: [],
  referenceDate: new Date(),
};

interface Props {
  isExport: boolean;
}

const VariablesOverview = (props: Props) => {
  const { isExport } = props;
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialValue);

  const variables = useSelector((store: StoreModel) => {
    const dataDefinitions = store.datacockpit.dataDefinitions.value ?? [];
    return flow(
      map((vId) => dataDefinitions.find((dd) => dd.id === vId)),
      compact
    )(filter.variableIds);
  });
  const hasVariables = variables.length > 0;
  const table = useSelector(
    ({
      datacockpit: {
        variableData: { table },
      },
    }: StoreModel) => ({
      value: table.value ?? [],
      valid: asyncIsValid(table),
    })
  );

  const handleChange = (value: VariableDataFilter) => {
    setFilter(value);
    if (!isExport) {
      dispatch(
        value.variableIds.length > 0
          ? datacockpitVariableDataTableFetchCell.require(value)
          : { type: datacockpitVariableDataTableFetchCell.events.clear }
      );
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <VariablesOverviewFilter
            isExport={isExport}
            onChange={handleChange}
            value={filter}
          />
        </CardContent>
      </Card>
      {!isExport && (
        <VariablesOverviewTable
          variables={variables}
          data={hasVariables ? table.value : []}
          loading={filter.variableIds.length > 0 && !table.valid}
        />
      )}
    </>
  );
};

export default VariablesOverview;
