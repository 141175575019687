import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  LogOutReason,
  emptyOrganization,
  logOutReasonSessionStorageKey,
} from "../../business/models";
import { useSession } from "../../business/redux/saga/admin/hooks";
import { organizationCell } from "../../business/redux/saga/organization";
import { clearTokens } from "../../utils/SecurityUtils";

const idleWarningMinutes = 14;
const idleLogoutActionMinutes = 15;

const IdleWarningDialog = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { session } = useSession();
  const applicationName = (
    useSelector(organizationCell.select).value ?? emptyOrganization
  ).applicationName;

  const [open, setOpen] = useState(false);
  const [idleLoggedOutTimer, setIdleLoggedOutTimer] =
    useState<NodeJS.Timeout | null>(null);
  const { reset } = useIdleTimer({
    debounce: 250,
    events: ["click", "dblclick"],
    onIdle:
      session && session.jwt
        ? () => {
            setOpen(true);
            setIdleLoggedOutTimer(
              setTimeout(() => {
                clearTokens();
                sessionStorage.setItem(
                  logOutReasonSessionStorageKey,
                  LogOutReason.idle
                );
                window.location.reload(); // browser won't acknowledge new state of tokens without a refresh
                history.push("/login");
              }, 1000 * 60 * (idleLogoutActionMinutes - idleWarningMinutes))
            );
          }
        : undefined,
    timeout: 1000 * 60 * idleWarningMinutes,
  });
  const handleIdleCancel = useCallback(() => {
    reset();
    if (idleLoggedOutTimer) {
      clearTimeout(idleLoggedOutTimer);
      setIdleLoggedOutTimer(null);
    }
    setOpen(false);
  }, [idleLoggedOutTimer, reset]);

  if (!session.jwt) {
    return <></>;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{t("User:IdleWarningTitle")}</DialogTitle>
      <DialogContent>
        {t("User:IdleWarningText", {
          applicationName,
          idleWarningMinutes,
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleIdleCancel} variant="contained">
          {t("User:IdleWarningContinue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(IdleWarningDialog);
