import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { Details } from "async-lifecycle-saga/dist/models";
import { Form, Formik, FormikActions } from "formik";
import { useSnackbar } from "notistack";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { TwoFactorAuthenticationPolicy } from "../../../business/authentication/models";
import {
  ChoiceExportFormat,
  ChoiceExportFormatExtra,
  EmployerModel,
  ModuleOption,
  OrganizationModel,
  emptyOrganization,
} from "../../../business/models";
import { adminEmployersListCell } from "../../../business/redux/saga/admin/employers/cells";
import { adminSettingsUpdateCell } from "../../../business/redux/saga/admin/settings";
import { StoreModel } from "../../../business/redux/saga/models";
import { organizationCell } from "../../../business/redux/saga/organization";
import { useTranslationStrict } from "../../../globalization/i18n";
import { appThemes } from "../../../styling/Theme";
import { hasSuperRole } from "../../../utils/SecurityUtils";
import { showAsyncFail } from "../../../utils/SnackbarUtils";
import ColorPicker from "../../input/ColorPicker";
import ChoiceExportFormatSelect from "./ChoiceExportFormatSelect";
import ChoiceExportFormatSelectExtra from "./ChoiceExportFormatSelectExtra";
import SettingsEmployerTabs from "./employers/SettingsEmployerTabs";

interface SettingsModel {
  applicationName: string;
  appTheme: number;
  forcedFiscalYear: boolean;
  choiceExportFormat: ChoiceExportFormat;
  choiceExportFormatExtra: ChoiceExportFormatExtra;
  contractManagement: ModuleOption;
  daywizeClientCode?: string;
  employers: EmployerModel[];
  light: string;
  main: string;
  name: string;
  nmbrsDomain?: string;
  portal: ModuleOption;
  twoFactorAuthenticationPolicy: TwoFactorAuthenticationPolicy;
  isB2B: boolean;
  microsoft365Active: boolean;

  isDisabled: boolean;
}

const SettingsCard = () => {
  const [t] = useTranslationStrict();
  const dispatch = useDispatch();
  const organization =
    useSelector(organizationCell.select).value ?? emptyOrganization;
  const [changed, setChanged] = useState(false);
  const {
    status: { loading },
    value: employersFromStore,
  } = useSelector(adminEmployersListCell.select);
  const employersFiltered =
    employersFromStore?.sort((e1, e2) => {
      if (e1.code === undefined) {
        return -1;
      }
      if (e2.code === undefined) {
        return 1;
      }
      return e1.code.localeCompare(e2.code);
    }) ?? [];
  const fiscalYear =
    organization.forcedFiscalYear ?? new Date().getFullYear() - 1;
  const isSuper = useSelector((store: StoreModel): boolean =>
    hasSuperRole(store.session)
  );
  const theme = appThemes[organization.appTheme || 0];
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = useCallback(
    (
      {
        applicationName,
        appTheme,
        forcedFiscalYear,
        choiceExportFormat,
        choiceExportFormatExtra,
        contractManagement,
        daywizeClientCode,
        employers,
        light,
        main,
        name,
        nmbrsDomain,
        portal,
        twoFactorAuthenticationPolicy,
        isB2B,
        microsoft365Active,
        isDisabled,
      }: SettingsModel,
      formikActions: FormikActions<SettingsModel>
    ) => {
      const payload: OrganizationModel = {
        ...organization,
        applicationName,
        appStyles: { primary: { main, light, "400": light } },
        appTheme,
        forcedFiscalYear: forcedFiscalYear ? fiscalYear : undefined,
        choiceExportFormat,
        choiceExportFormatExtra,
        contractManagement,
        daywizeClientCode,
        name,
        nmbrsDomain,
        portal,
        twoFactorAuthenticationPolicy,
        isB2B,
        microsoft365Active,
        isDisabled,
      };
      dispatch(
        adminSettingsUpdateCell.require(payload, {
          onFail: (details: Details) => {
            formikActions.setSubmitting(false);
            showAsyncFail(enqueueSnackbar)(details);
          },
          onSuccess: () => {
            setChanged(true);
            formikActions.setSubmitting(false);
          },
        })
      );
      for (let i = 0; i < employers.length; i++) {
        dispatch(adminEmployersListCell.update(employers[i]));
      }
    },
    [dispatch, enqueueSnackbar, fiscalYear, organization]
  );
  const handleApply = useCallback((): void => {
    window.location.href = "/";
  }, []);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t("Forms:Required")),
        applicationName: yup.string().required(t("Forms:Required")),
        appTheme: yup.number().required(t("Forms:Required")),
        main: yup.string().matches(/^#[a-f0-9]{6}$/i),
        light: yup.string().matches(/^#[a-f0-9]{6}$/i),
        forcedFiscalYear: yup.bool().required(t("Forms:Required")),
        nmbrsDomain: yup.string(),
        twoFactorAuthenticationPolicy: yup
          .string()
          .required(t("Forms:Required")),
        daywizeClientCode: yup.string(),
        isB2B: yup.bool().required(t("Forms:Required")),
        microsoft365Active: yup.bool().required(t("Forms:Required")),
        isDisabled: yup.bool().required(t("Forms:Required")),
      }),
    [t]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const primary: any =
    (organization?.appStyles as { primary: object } | undefined)?.primary || {};

  useEffect(() => {
    dispatch(adminEmployersListCell.require());
  }, [dispatch]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: organization.name,
        applicationName: organization.applicationName,
        appTheme: organization.appTheme,
        main:
          primary.main ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (theme.palette.primary as any)["500"],
        light:
          primary.light ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (theme.palette.primary as any)["400"],
        forcedFiscalYear: Boolean(organization.forcedFiscalYear),
        choiceExportFormat: organization.choiceExportFormat,
        choiceExportFormatExtra: organization.choiceExportFormatExtra,
        contractManagement: organization.contractManagement,
        employers: employersFiltered,
        portal: organization.portal,
        nmbrsDomain: organization.nmbrsDomain,
        daywizeClientCode: organization.daywizeClientCode,
        twoFactorAuthenticationPolicy:
          organization.twoFactorAuthenticationPolicy,
        isB2B: organization.isB2B,
        microsoft365Active: organization.microsoft365Active,
        isDisabled: organization.isDisabled,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={(form): JSX.Element => {
        const {
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          values,
          values: {
            applicationName,
            appTheme,
            forcedFiscalYear,
            choiceExportFormat,
            choiceExportFormatExtra,
            contractManagement,
            daywizeClientCode,
            employers,
            light,
            main,
            name,
            nmbrsDomain,
            portal,
            twoFactorAuthenticationPolicy,
            isB2B,
            microsoft365Active,
            isDisabled,
          },
          errors,
          setValues,
        } = form;

        const handlePortalChecked = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          setValues({
            ...values,
            portal: checked ? ModuleOption.default : ModuleOption.off,
          });
        };

        const handle2FAPolicyChanged: SelectInputProps["onChange"] = (
          event
        ) => {
          setValues({
            ...values,
            twoFactorAuthenticationPolicy: event.target
              .value as TwoFactorAuthenticationPolicy,
          });
        };

        const handleFfyChecked = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          setValues({
            ...values,
            forcedFiscalYear: checked,
          });
        };

        const handleB2BChecked = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          setValues({
            ...values,
            isB2B: checked,
          });
        };

        const handleEmployersChange = (employersNew: EmployerModel[]) => {
          setValues({
            ...values,
            employers: employersNew,
          });
        };

        const handleMicrosoft365ActiveChecked = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          setValues({
            ...values,
            microsoft365Active: checked,
          });
        };

        const handleInactiveChecked = (
          _event: ChangeEvent<HTMLInputElement>,
          checked: boolean
        ) => {
          setValues({
            ...values,
            isDisabled: checked,
          });
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleTheme = (
          event: ChangeEvent<{ name?: string; value: unknown }>
        ): void => {
          const value = Number(event.target.value);
          const next = appThemes[value];
          setValues({
            ...values,
            appTheme: value,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            main: (next.palette.primary as any)["500"],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            light: (next.palette.primary as any)["400"],
          });
        };

        return (
          <Form>
            <Card>
              <CardContent style={{ display: "flex" }}>
                <FormGroup style={{ marginRight: "1em" }}>
                  <FormLabel>{t("Admin:Presentation")}</FormLabel>
                  <div>
                    <TextField
                      error={"name" in errors}
                      name="name"
                      value={name}
                      label={t("Admin:CompanyName")}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <TextField
                      error={"applicationName" in errors}
                      name="applicationName"
                      value={applicationName}
                      label={t("Admin:ApplicationName")}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel>{t("Admin:ApplicationTheme")}</InputLabel>
                      <Select
                        name="appTheme"
                        value={appTheme}
                        onChange={handleTheme}
                        style={{ minWidth: 196 }}
                      >
                        {appThemes.map((a, i) => (
                          <MenuItem key={a.name} value={i}>
                            {a.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <ColorPicker
                      error={"main" in errors}
                      name="main"
                      label={t("Admin:PrimaryColor")}
                      value={main}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <ColorPicker
                      error={"light" in errors}
                      label={t("Admin:HighlightColor")}
                      name="light"
                      value={light}
                      onChange={handleChange}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormLabel>{t("Admin:Alacarte")}</FormLabel>
                  <SettingsEmployerTabs
                    employers={employers}
                    loading={loading}
                    onChange={handleEmployersChange}
                  />
                  <FormGroup>
                    <FormLabel>{t("Admin:ChoiceExportFormat")}</FormLabel>
                    <ChoiceExportFormatSelect
                      form={form}
                      field={{
                        onChange: handleChange,
                        onBlur: handleBlur,
                        value: choiceExportFormat,
                        name: "choiceExportFormat",
                      }}
                      extra={choiceExportFormatExtra}
                    />
                  </FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={forcedFiscalYear}
                        onChange={handleFfyChecked}
                      />
                    }
                    label={t("Admin:ForcedFiscalYear") + ` ${fiscalYear}`}
                  />
                </FormGroup>
                {isSuper && (
                  <div>
                    <FormGroup style={{ marginRight: "1em" }}>
                      <FormLabel>{t("Admin:ContractManagement")}</FormLabel>
                      <Select
                        name="contractManagement"
                        value={contractManagement}
                        onChange={handleChange}
                        style={{ minWidth: 196 }}
                      >
                        <MenuItem value={ModuleOption.default}>
                          {t("Admin:ContractManagementDefault")}
                        </MenuItem>
                        <MenuItem value={ModuleOption.silver}>
                          {t("Admin:ContractManagementSilver")}
                        </MenuItem>
                      </Select>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={portal === ModuleOption.default}
                            onChange={handlePortalChecked}
                          />
                        }
                        label={
                          values.isB2B
                            ? t("Admin:PortalB2B")
                            : t("Admin:Portal")
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:SSO")}</FormLabel>
                      <TextField
                        error={"nmbrsDomain" in errors}
                        name="nmbrsDomain"
                        value={nmbrsDomain}
                        label={t("Admin:NmbrsDomain")}
                        onChange={handleChange}
                      />
                      <Typography variant="caption">.nmbrs.nl</Typography>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:SSO")}</FormLabel>
                      <TextField
                        error={"daywizeClientCode" in errors}
                        name="daywizeClientCode"
                        value={daywizeClientCode}
                        label={t("Admin:DaywizeClientCode")}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:Authentication")}</FormLabel>
                      <FormControlLabel
                        control={
                          <Select
                            onChange={handle2FAPolicyChanged}
                            style={{ marginLeft: "10px" }}
                            value={twoFactorAuthenticationPolicy}
                          >
                            {Object.values(TwoFactorAuthenticationPolicy).map(
                              (policy) => (
                                <MenuItem key={policy} value={policy}>
                                  {t(
                                    `Admin:TwoFactorAuthenticationPolicy_${policy}`
                                  )}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        }
                        label={t("Admin:TwoFactorAuthentication")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:B2B")}</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch checked={isB2B} onChange={handleB2BChecked} />
                        }
                        label={t("Admin:B2B")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:Microsoft Entra ID")}</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={microsoft365Active}
                            onChange={handleMicrosoft365ActiveChecked}
                          />
                        }
                        label={t("Admin:Actief")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Admin:Inactief")}</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isDisabled}
                            onChange={handleInactiveChecked}
                          />
                        }
                        label={t("Admin:Op slot (alleen Super mag inloggen)")}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>
                        {t("Admin:ChoiceExportFormatExtra")}
                      </FormLabel>
                      <ChoiceExportFormatSelectExtra
                        form={form}
                        field={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          value: choiceExportFormatExtra,
                          name: "choiceExportFormatExtra",
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {t("Common:Save")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleApply}
                  disabled={isSubmitting || !changed}
                >
                  {t("Common:Apply")}
                </Button>
              </CardActions>
            </Card>
          </Form>
        );
      }}
    />
  );
};

export default memo(SettingsCard);
