import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { memo } from "react";

import { EmployerModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import EmployerDeleteButton from "./EmployerDeleteButton";
import EmployerEditButton from "./EmployerEditButton";

interface EmployersTableProps {
  employers: EmployerModel[];
}

const EmployersTable = ({ employers }: EmployersTableProps) => {
  const [t] = useTranslationStrict();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell key="Employer">{t("Admin:Employer")}</TableCell>
          <TableCell key="Actions" />
        </TableRow>
      </TableHead>
      <TableBody>
        {employers &&
          Array.isArray(employers) &&
          employers
            .sort((e1, e2) => e1.name.localeCompare(e2.name))
            .map(({ id, name }) => (
              <TableRow key={id}>
                <TableCell key="Name">{name}</TableCell>
                <TableCell key="Actions">
                  <EmployerEditButton id={id} />
                  <EmployerDeleteButton id={id} />
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

export default memo(EmployersTable);
