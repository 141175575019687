import { Alert } from "@material-ui/lab";
import React from "react";
import { useSelector } from "react-redux";

import { LoginPageEvents } from "../../../business/authentication/models";
import {
  LogOutReason,
  logOutReasonSessionStorageKey,
} from "../../../business/models";
import { StoreModel } from "../../../business/redux/saga/models";
import { MijnHRPlazaLoginFormCard } from "../../../components/authentication/mijnhrplaza";
import { useTranslationStrict } from "../../../globalization/i18n";
import { Microsoft365Card } from "../../authentication/microsoft365";
import useStyles from "./LoginPage.styles";

const LoginPage = (props: LoginPageEvents) => {
  const { onLogin } = props;
  const [t] = useTranslationStrict();
  const classes = useStyles();
  const useMicrosoft365 = useSelector(
    (store: StoreModel) => store.organization.get.value!.microsoft365Active
  );
  const logOutReason = sessionStorage.getItem(
    logOutReasonSessionStorageKey
  ) as LogOutReason;

  return (
    <div className={classes.gridMainContent}>
      {logOutReason === LogOutReason.idle && (
        <Alert severity="warning">{t("User:IdleLoggedOut")}</Alert>
      )}
      {!useMicrosoft365 && <MijnHRPlazaLoginFormCard onLogin={onLogin} />}
      {useMicrosoft365 && <Microsoft365Card />}
    </div>
  );
};

export default LoginPage;
