import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as React from "react";

import { useTranslationStrict } from "../globalization/i18n";
import { ConfirmTexts } from "./core";

interface Handlers {
  onAbort?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

type Props = ConfirmTexts & Handlers;

const ConfirmDialog = React.memo(
  ({
    ns,
    title,
    content,
    abort = "Cancel",
    cancel = "Cancel",
    confirm = "Confirm",
    onAbort,
    onCancel,
    onConfirm,
  }: Props): JSX.Element => {
    const [t] = useTranslationStrict();
    const resolve = (given: string | undefined, fallback: string): string =>
      ns && given ? t(`${ns}:${given}`) : given || t(`Common:${fallback}`);

    return (
      <Dialog open onClose={onCancel}>
        <DialogTitle>{resolve(title, "Confirm")}</DialogTitle>
        <DialogContent>
          <Typography>{resolve(content, "AreYouSure")}</Typography>
        </DialogContent>
        <DialogActions>
          {onAbort && (
            <Button variant="outlined" color="secondary" onClick={onAbort}>
              {t(`Common:${abort}`)}
            </Button>
          )}
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {t(`Common:${cancel}`)}
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            {t(`Common:${confirm}`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ConfirmDialog;
