import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { adminSettingsLogoCell } from "../../../business/redux/saga/admin/settings/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { showAsyncFail } from "../../../utils/SnackbarUtils";

const imageFiles = ["image/*"];

const LogoCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslationStrict();

  const [file, setFile] = useState<File | undefined>(undefined);

  const handleChange = useCallback((files: File[]): void => {
    setFile(files[0]);
  }, []);

  const handleSave = useCallback((): void => {
    dispatch(
      adminSettingsLogoCell.require(file!, {
        onFail: showAsyncFail(enqueueSnackbar),
        onSuccess: () => {
          history.push("/");
        },
      })
    );
  }, [dispatch, enqueueSnackbar, file, history]);

  return (
    <Card>
      <CardContent>
        <DropzoneArea
          acceptedFiles={imageFiles}
          dropzoneText={t("Admin:Logo")}
          filesLimit={1}
          maxFileSize={1024 * 1024}
          onChange={handleChange}
          showAlerts={false}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={!file}
          onClick={handleSave}
        >
          {t("Common:Save")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default memo(LogoCard);
