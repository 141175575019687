import { useCallback, useEffect, useState } from "react";

const useInterval = (delay: number): symbol => {
  const [symbol, setState] = useState(Symbol("useInterval"));
  const nextSymbol = useCallback(() => setState(Symbol("useInterval")), []);

  useEffect((): (() => void) => {
    const timer = window.setInterval(nextSymbol, delay);
    return (): void => window.clearInterval(timer);
  }, [delay, nextSymbol]);

  return symbol;
};

export default useInterval;
