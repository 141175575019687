import { composeReducers } from "async-lifecycle-saga";
import { combineReducers } from "redux";

import {
  NavigationRootModel,
  emptyMenu,
  emptyNavigation,
} from "../../../models";
import { appCell } from "../app/cells";
import { authenticationLogOutCell } from "../authentication/cells";
import { emptySingleValue } from "../models";
import {
  navigationAddToHistoryCell,
  navigationGetHistoryCell,
  navigationMenuCell,
  navigationTreeCell,
} from "./cells";
import {
  NavigationHistoryEntry,
  NavigationHistoryResponse,
  NavigationStoreModel,
} from "./models";

export default composeReducers(
  combineReducers({
    addToHistory: navigationAddToHistoryCell.reducer,
    getHistory: navigationGetHistoryCell.reducer,
    menu: navigationMenuCell.reducer,
    tree: navigationTreeCell.reducer,
  }),
  (state, { type }) => {
    switch (type) {
      case authenticationLogOutCell.events.success:
      case authenticationLogOutCell.events.error:
      case appCell.events.clear:
      case navigationMenuCell.events.clear:
        return {
          addToHistory: emptySingleValue<NavigationHistoryResponse>(),
          getHistory: emptySingleValue<NavigationHistoryEntry[]>(),
          menu: {
            ...emptySingleValue<NavigationRootModel>(),
            value: emptyMenu,
          },
          tree: emptySingleValue<NavigationRootModel>(),
        } as NavigationStoreModel;
      case navigationTreeCell.events.clear:
        return {
          addToHistory: emptySingleValue<NavigationHistoryResponse>(),
          getHistory: emptySingleValue<NavigationHistoryEntry[]>(),
          menu: emptySingleValue<NavigationRootModel>(),
          tree: {
            ...emptySingleValue<NavigationRootModel>(),
            value: emptyNavigation,
          },
        };
      default:
        return (
          state || {
            addToHistory: emptySingleValue<NavigationHistoryResponse>(),
            getHistory: emptySingleValue<NavigationHistoryEntry[]>(),
            menu: emptySingleValue<NavigationRootModel>(),
            tree: emptySingleValue<NavigationRootModel>(),
          }
        );
    }
  }
);
