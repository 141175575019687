import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    range: {
      background: "transparent",
      width: "100%",
      height: 24,
      outline: "none",
      // background:'red',
      "-webkit-appearance": "none",
      "&::-webkit-slider-thumb": {
        height: 16,
        width: 16,
        borderRadius: 100,
        background: theme.palette.primary.main,
        cursor: "pointer",
        "-webkit-appearance": "none",
        transform: "translateY(calc(-50% + 2px))",
        "&:hover": {
          background: theme.palette.primary.light,
        },
      },
      "&::-webkit-slider-runnable-track": {
        width: "100%",
        height: "4px",
        cursor: "pointer",
        animate: "0.2s",
        background: theme.palette.grey.A100,
        borderRadius: "100px",
      },
      "&::-moz-range-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        height: 16,
        width: 16,
      },
      "&::-moz-range-track": {
        backgroundColor: theme.palette.primary.main,
      },
      "&::-ms-thumb": {
        marginTop: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        height: 16,
        width: 16,
      },
      "&::-ms-track": {
        backgroundColor: theme.palette.primary.main,
        height: 1,
      },
    },
    iconButton: {
      padding: 5,
    },
    chosen: {
      borderLeft: "1px solid #f3f3f3",
    },
    choiceInputRoot: {
      justifyContent: "space-between",
    },
    choiceInput: {
      textAlign: "right",
      outline: "solid 1px #e3e3e3",
      width: 90,
      "&:focus": {
        outline: "solid 1px #c3c3c3",
      },
    },
  })
);

export default useStyles;
