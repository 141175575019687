import { Box, Button, Step, StepLabel, Stepper } from "@material-ui/core";
import { Formik } from "formik";
import { RefObject, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import {
  WlzAdviceCase,
  WlzPerson,
  wlzAdviceCaseDefault,
  wlzPersonDefault,
} from "../../../../business/personal/wlz/models";
import { StoreModel } from "../../../../business/redux/saga/models";
import {
  TFunctionStrict,
  useTranslationStrict,
} from "../../../../globalization/i18n";
import { WlzAdviceCaseReportDownloadButton } from "../index";
import { WlzAdviceCaseForm, WlzAdviceCaseSummary } from "./";
import WlzAdviceCaseSubjectsForm from "./WlzAdviceCaseSubjectsForm";

const personValidationSchema = (t: TFunctionStrict) => {
  const requiredMessage = t("Forms:Required") as string;
  return Yup.object<WlzPerson>().shape<WlzPerson>({
    id: Yup.number().notRequired(),
    caseId: Yup.number().required(requiredMessage),
    name: Yup.string().required(requiredMessage),
    dateOfBirth: Yup.date().required(requiredMessage),
    aowBenefit: Yup.number().required(requiredMessage),
    pension: Yup.number().required(requiredMessage),
    profitEnterprise: Yup.number().required(requiredMessage),
    incomeOther: Yup.number().required(requiredMessage),
    wozValueResidence: Yup.number().required(requiredMessage),
    mortgage: Yup.number().required(requiredMessage),
    mortgageInterest: Yup.number().required(requiredMessage),
    healthcareDeduction: Yup.number().required(requiredMessage),
    giftsDeduction: Yup.number().required(requiredMessage),
    savingsCredit: Yup.number().required(requiredMessage),
    investments: Yup.number().required(requiredMessage),
    wageTaxWithholding: Yup.number().required(requiredMessage),
    dividendTaxWithheld: Yup.number().required(requiredMessage),
    incomeTaxAssessmentProvisional: Yup.number().required(requiredMessage),
    notes: Yup.string().default(""),
  });
};

const subjectsValidationSchema = (t: TFunctionStrict) =>
  Yup.array()
    .of(personValidationSchema(t))
    .min(1)
    .max(2) as unknown as Yup.Schema<[WlzPerson] | [WlzPerson, WlzPerson]>;

const adviceCaseValidationSchema = (t: TFunctionStrict) => {
  const requiredMessage = t("Forms:Required") as string;
  return Yup.object<WlzAdviceCase>().shape<WlzAdviceCase>({
    id: Yup.number().notRequired(),
    employeeId: Yup.number().required(requiredMessage),
    title: Yup.string().required(requiredMessage),
    yearCalculation: Yup.date().required(requiredMessage),
    couple: Yup.bool().required(requiredMessage),
    note: Yup.string(),
    archive: Yup.bool().required(requiredMessage),
    subjects: subjectsValidationSchema(t),
    calculations: Yup.object<Record<string, number>>(),
  });
};

const adviceCaseSubjectsConstraint = (
  adviceCase: WlzAdviceCase
): WlzAdviceCase => {
  let subjects = adviceCase.subjects;
  const subjectsCount = adviceCase.couple ? 2 : 1;

  switch (true) {
    case subjects === undefined:
      subjects = Array.from({ length: subjectsCount }, () =>
        wlzPersonDefault(adviceCase.id || 0)
      ) as [WlzPerson] | [WlzPerson, WlzPerson];
      break;
    case subjects.length > subjectsCount:
      subjects = subjects.slice(0, subjectsCount) as
        | [WlzPerson]
        | [WlzPerson, WlzPerson];
      break;
    case subjects.length < subjectsCount:
      subjects = Array.from(
        { length: subjectsCount },
        (_, i) => subjects[i] || wlzPersonDefault(adviceCase.id || 0)
      ) as [WlzPerson] | [WlzPerson, WlzPerson];
      break;
  }

  return {
    ...adviceCase,
    subjects,
  };
};

interface WlzWizardStepsContext {
  adviceCase: WlzAdviceCase;
  formRefStep1: RefObject<Formik<WlzAdviceCase>>;
  formRefStep2: RefObject<Formik<WlzAdviceCase>>;
  t: TFunctionStrict;

  onFinish(value: WlzAdviceCase): void;

  setAdviceCase(value: WlzAdviceCase): void;

  setStep(step: number): void;
}

interface WlzWizardStep {
  label: string;
  content: JSX.Element;

  onNext(): void;
}

type WlzWizardSteps = ((context: WlzWizardStepsContext) => WlzWizardStep)[];

const steps: WlzWizardSteps = [
  (context) => ({
    label: context.t("Wlz:Advies Casus"),
    content: (
      <WlzAdviceCaseForm
        formRef={context.formRefStep1}
        initialValues={context.adviceCase}
        onSubmit={(value: WlzAdviceCase) => {
          context.setAdviceCase(adviceCaseSubjectsConstraint(value));
          context.setStep(2);
        }}
        validationSchema={adviceCaseValidationSchema(context.t)}
      />
    ),
    onNext: () => {
      context.formRefStep1.current!.executeSubmit();
    },
  }),
  (context) => ({
    label: context.t("Wlz:Personen"),
    content: (
      <WlzAdviceCaseSubjectsForm
        formRef={context.formRefStep2}
        initialValues={context.adviceCase}
        onSubmit={(value: WlzAdviceCase) => {
          context.setAdviceCase(
            adviceCaseSubjectsConstraint({
              ...context.adviceCase,
              subjects: value.subjects,
            })
          );
          context.setStep(3);
        }}
        validationSchema={adviceCaseValidationSchema(context.t)}
      />
    ),
    onNext: () => {
      context.formRefStep2.current!.executeSubmit();
    },
  }),
  (context) => ({
    label: context.t("Wlz:Overzicht"),
    content: <WlzAdviceCaseSummary adviceCase={context.adviceCase} />,
    onNext: () => {
      context.onFinish(context.adviceCase);
    },
  }),
];

/**
 * The properties of the WlzWizard component.
 */
interface Props {
  /**
   * An optional identifier of a possibly existing advice case.
   * If this is set, the advice case will be updated rather than inserted.
   */
  adviceCaseId?: number;

  navigationNodeId: number;

  onCancel(): void;

  onFinish(value: WlzAdviceCase): void;
}

/**
 * Wizard for creating or editing a WlzAdviceCase.
 * @param props The props.
 * @constructor The constructor.
 */
const WlzAdviceCaseWizard = (props: Props) => {
  const { adviceCaseId, navigationNodeId, onCancel, onFinish } = props;
  const [t] = useTranslationStrict();

  const { user: employee } = useSelector((store: StoreModel) => store.session);
  const { value: adviceCases } = useSelector(
    (store: StoreModel) => store.personal.wlz.adviceCases
  );

  const [step, setStep] = useState(1);
  const [adviceCase, setAdviceCase] = useState<WlzAdviceCase>(
    adviceCases?.find((c) => c.id === adviceCaseId) ??
      wlzAdviceCaseDefault(employee?.id ?? 0)
  );

  const formRefStep1 = useRef<Formik<WlzAdviceCase>>(null);
  const formRefStep2 = useRef<Formik<WlzAdviceCase>>(null);

  const onBack = () => {
    if (step === 1) {
      return;
    }
    setStep(step - 1);
  };

  const stepsContext: WlzWizardStepsContext = {
    adviceCase,
    formRefStep1,
    formRefStep2,
    onFinish,
    setAdviceCase,
    setStep,
    t,
  };

  const stepCurrent = steps[step - 1](stepsContext);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step - 1} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{steps[index](stepsContext).label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {stepCurrent.content}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {step > 1 && <Button onClick={onBack}>{t("Wlz:Terug")}</Button>}
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          color="secondary"
          onClick={onCancel}
          style={{ marginRight: "5px" }}
          variant="outlined"
        >
          {t("Wlz:Annuleren")}
        </Button>
        {step === steps.length && (
          <WlzAdviceCaseReportDownloadButton
            adviceCase={adviceCase}
            navigationNodeId={navigationNodeId}
            style={{ marginRight: "5px" }}
          />
        )}
        <Button
          color="primary"
          onClick={stepCurrent.onNext}
          variant="contained"
        >
          {t(step === steps.length ? "Wlz:Opslaan" : "Wlz:Volgende")}
        </Button>
      </Box>
    </Box>
  );
};

export default WlzAdviceCaseWizard;
