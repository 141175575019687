import { Details, Success } from "async-lifecycle-saga/dist/models";
import { useDispatch, useSelector } from "react-redux";

import { UploadTypeModel } from "../../../admin/upload/models";
import {
  ContentNodeModel,
  NavigationLocation,
  NavigationNodeModel,
  NavigationRootModel,
  SessionModel,
} from "../../../models";
import { StoreModel } from "../models";
import { navigationMenuCell, navigationTreeCell } from "../navigation/cells";
import { adminChoiceEmployeeResetCell } from "./choices/cells";
import {
  adminNavigationContentAddCell,
  adminNavigationContentDeleteCell,
  adminNavigationContentUpdateCell,
  adminNavigationNodeAddToRootCell,
  adminNavigationNodeDeleteCell,
  adminNavigationNodeUpdateCell,
} from "./navigation/cells";
import { adminUploadDeleteCell, adminUploadTypesCell } from "./upload/cells";
import { AdminUploadStoreModel } from "./upload/models";
import { adminUsersInviteCell } from "./users/cells";
import { AdminUsersInviteRequestResult } from "./users/models";

interface UseNavigationHook {
  addNodeToRoot: (
    location: NavigationLocation,
    node: NavigationNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => void;

  getNavigation: (
    location: NavigationLocation,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationRootModel>) => void
  ) => void;

  updateNavigationNode: (
    node: NavigationNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => void;

  deleteNavigationNode: (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => void;

  addContentNode: (
    parentId: number,
    node: ContentNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<ContentNodeModel>) => void
  ) => void;

  deleteContentNode: (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<ContentNodeModel>) => void
  ) => void;

  updateContentNode: (
    contentNode: ContentNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<never>) => void
  ) => void;
}

export const useNavigation = (): UseNavigationHook => {
  const dispatch = useDispatch();

  const addNodeToRoot = (
    location: NavigationLocation,
    node: NavigationNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => {
    dispatch(
      adminNavigationNodeAddToRootCell.require(
        {
          location,
          childNode: node,
        },
        {
          onFail: failCallback,
          onSuccess: successCallback,
        }
      )
    );
  };

  const getNavigation = (
    location: NavigationLocation,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationRootModel>) => void
  ): void => {
    switch (location) {
      case "appbar":
        dispatch(navigationTreeCell.require());
        break;
      case "aside":
        dispatch(
          navigationMenuCell.require({
            onFail: failCallback,
            onSuccess: successCallback,
          })
        );
        break;
      default:
        throw new Error("Unknown navigation root location.");
    }
  };

  const updateNavigationNode = (
    node: NavigationNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => {
    dispatch(
      adminNavigationNodeUpdateCell.require(node, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  const deleteNavigationNode = (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<NavigationNodeModel>) => void
  ) => {
    dispatch(
      adminNavigationNodeDeleteCell.require(id, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  const addContentNode = (
    parentId: number,
    node: ContentNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<ContentNodeModel>) => void
  ): void => {
    dispatch(
      adminNavigationContentAddCell.require(
        { node, parentId },
        { onFail: failCallback, onSuccess: successCallback }
      )
    );
  };

  const deleteContentNode = (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<ContentNodeModel>) => void
  ): void => {
    dispatch(
      adminNavigationContentDeleteCell.require(id, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  const updateContentNode = (
    contentNode: ContentNodeModel,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<never>) => void
  ) => {
    dispatch(
      adminNavigationContentUpdateCell.require(contentNode, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  return {
    addNodeToRoot,
    getNavigation,
    updateNavigationNode,
    deleteNavigationNode,
    addContentNode,
    deleteContentNode,
    updateContentNode,
  };
};

interface UseSessionHook {
  session: SessionModel;
}

export const useSession = (): UseSessionHook => {
  const session = useSelector<StoreModel, SessionModel>(
    (state: StoreModel): SessionModel => state.session
  );

  return {
    session,
  };
};

interface UseUploadsHook {
  upload: AdminUploadStoreModel;

  deleteUpload: (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<undefined>) => void
  ) => void;

  getUploadTypes: (
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<UploadTypeModel[]>) => void
  ) => void;
}

export const useUploads = (): UseUploadsHook => {
  const dispatch = useDispatch();

  const upload = useSelector(
    (state: StoreModel): AdminUploadStoreModel => state.admin.upload
  );

  const deleteUpload = (
    id: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<undefined>) => void
  ): void => {
    dispatch(
      adminUploadDeleteCell.require(id, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  const getUploadTypes = (
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<UploadTypeModel[]>) => void
  ) => {
    dispatch(
      adminUploadTypesCell.require({
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  return {
    upload,
    deleteUpload,
    getUploadTypes,
  };
};

interface UseUserConfigHook {
  inviteUser: (
    loginName: string,
    culture: string,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AdminUsersInviteRequestResult>) => void
  ) => void;
  resetChoices: (
    employeeId: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<void>) => void
  ) => void;
}

export const useUserConfig = (): UseUserConfigHook => {
  const dispatch = useDispatch();

  const inviteUser = (
    loginName: string,
    culture: string,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<AdminUsersInviteRequestResult>) => void
  ): void => {
    dispatch(
      adminUsersInviteCell.require(
        { culture, loginName },
        {
          onFail: failCallback,
          onSuccess: successCallback,
        }
      )
    );
  };

  const resetChoices = (
    employeeId: number,
    failCallback?: (details: Details) => void,
    successCallback?: (success: Success<void>) => void
  ): void => {
    dispatch(
      adminChoiceEmployeeResetCell.require(employeeId, {
        onFail: failCallback,
        onSuccess: successCallback,
      })
    );
  };

  return {
    inviteUser,
    resetChoices,
  };
};
