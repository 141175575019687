import { Id, Some } from "async-lifecycle-saga/dist/models";
import * as React from "react";
import { useMemo } from "react";
import * as yup from "yup";

import { useTranslationStrict } from "../globalization/i18n";
import { UploadTypeModel } from "./admin/upload/models";
import { TwoFactorAuthenticationPolicy } from "./authentication/models";
import { DataDefinitionCode } from "./dataDefinitionCode";
import { ChoiceDefinitionModel } from "./redux/saga/admin/choices/models";

export * from "./datacockpit/mutations/models";
export * from "./personal/employeeData/models";

export interface PagedList<T extends Some> {
  pageItems: T[];
  pageNumber: number;
  pageSize: number;
  total: number;
}

export interface NotificationModel {
  message: string;
}

export type Role =
  | "employee"
  | "organizationAdmin"
  | "datacockpit"
  | "platformAdmin";

export type SortOrder = "asc" | "desc";

interface ProfileModelBase {
  loginName: string;
  fullName: string;
  email?: string;
  role?: string;
  department?: string;
  pictureUrl?: string;
}

export type ProfileModel = ProfileModelBase;

export interface BreadCrumbNodeModel {
  caption: string;
  url: string;
}

export interface DataTypeModel {
  id: number;
  dataField: "boolean" | "date" | "numeric" | "text";
  name: string;
}

export type ChoiceRole = "none" | "source" | "destination";
export type ChoiceCategory = "none" | "wkr" | "wkrFirst" | "wkrGift";

export enum ChoiceExportFormat {
  generic = "generic",
  nmbrs = "nmbrs",
  afas = "afas",
  adp = "adp",
}

export enum ChoiceExportFormatExtra {
  none = "none",
  nmbrs = "nmbrs",
  afas = "afas",
  adp = "adp",
}

export enum ChoiceInterval {
  annually = "annually",
  monthly = "monthly",
  fourWeekly = "fourWeekly",
}

export enum DataDefinitionGroup {
  AvwArbeidsongeschiktheid = "AVW-ARBEIDSONGESCHIKTHEID",
  AvwAuto = "AVW-AUTO",
  AvwFlexibel = "AVW-FLEXIBEL",
  AvwNabestaanden = "AVW-NABESTAANDEN",
  AvwOnkosten = "AVW-ONKOSTEN",
  AvwPensioen = "AVW-PENSIOEN",
  AvwSalaris = "AVW-SALARIS",
  AvwSecundair = "AVW-SECUNDAIR",
  AvwTijd = "AVW-TIJD",
  AvwToeslagen = "AVW-TOESLAGEN",
  Berekening = "BEREKENING",
  Dienstverband = "DIENSTVERBAND",
  ExtraVeldWidus = "EXTRA VELD WIDUS",
  Geen = ":None",
  Gezinsgegevens = "GEZINSGEGEVENS",
  Mutatie = "MUTATIE",
  Persoonsgegevens = "PERSOONSGEGEVENS",
  RetourberichtUitvoerder = "RETOURBERICHT UITVOERDER",
  Uitvoering = "UITVOERING",
  Verzuim = "VERZUIM",
  Werkgeversgegevens = "WERKGEVERSGEGEVENS",
  Werkgeverslasten = "WERKGEVERSLASTEN",
}

export const dataDefinitionGroups = Object.values(DataDefinitionGroup);

export interface DataDefinitionModel {
  id: number;
  code: DataDefinitionCode;
  dataType: DataTypeModel;
  unitBefore?: string;
  unitAfter?: string;
  choiceRole: ChoiceRole;
  choiceCategory: ChoiceCategory;
  group: DataDefinitionGroup;
}

export const emptyDataDefinitions: DataDefinitionModel[] = [];

export interface DownloadModel extends Id<number> {
  documentName: string;
  uploadTypeId: number;
  contentType: string;
  employeeOwned: boolean;
  year?: number;
  period?: number;
}

export interface AnnouncementModel {
  id?: number;
  organizationId: number;
  organization?: OrganizationModel;
  title: string;
  text: string;
  link?: string;
  validFrom: Date;
  validTo?: Date;
  roles?: string[];
  files?: File[];
  contentType?: string;
  documentName?: string;
  adminOnly: boolean;
}

export interface AnnouncementFormValues {
  title: string;
  text: string;
  link?: string;
  roles: string[];
  files: File[];
  notifyUsers: boolean;
}

type AnnouncementValidationSchema = yup.ObjectSchema<
  yup.Shape<AnnouncementFormValues, AnnouncementFormValues>
>;

export interface AnnouncementValidationSchemaHook {
  validationSchema: AnnouncementValidationSchema;
}

export const useAnnouncementValidationSchema =
  (): AnnouncementValidationSchemaHook => {
    const [t] = useTranslationStrict();

    const validationSchema = React.useMemo(
      (): AnnouncementValidationSchema =>
        yup.object<AnnouncementFormValues>().shape<AnnouncementFormValues>({
          title: yup
            .string()
            .required(t("Forms:Required") as string)
            .matches(/^\w.*\S$/, t("Admin:TitleInvalid") as string),
          text: yup.string().required(t("Forms:Required") as string),
          link: yup
            .string()
            .matches(
              /^https:\/\/youtu\.be\/[a-zA-Z0-9_-]{11}$/,
              t("Admin:YoutubeInvalid") as string
            ),
          roles: yup.array(),
          files: yup.array(),
          notifyUsers: yup.bool(),
        }),
      [t]
    );

    return { validationSchema };
  };

export enum ModuleOption {
  off = "off",
  default = "default",
  bronze = "bronze",
  silver = "silver",
  gold = "gold",
  platinum = "platinum",
}

export type SalaryPeriod = "month" | "fourWeeks";

export interface EmployerModel extends Id<number> {
  organizationId: number;
  name: string;
  code?: string;
  government: boolean;
  isSmall: boolean;
  isSpecial: boolean;
  whkRate: number;
  whkRateEmployee: number;
  wkrBudget: number;
  fulltimeHours: number;
  benefitCalculation: ModuleOption;
  benefitShared: number;
  benefitSharedTaxExempt: number;
  benefitSharedDispensated: number;
  period: SalaryPeriod;
}

export const employersEmpty: EmployerModel[] = [];

export interface ModuleOptionMap {
  [key: string]: ModuleOption;
}

export interface ModuleOptions {
  contractManagement: ModuleOption;
  portal: ModuleOption;
}

export interface OrganizationModel extends ModuleOptions, Id<number> {
  name: string;
  prefix: string;
  appTheme: number;
  appStyles: object;
  logoUrl: string;
  applicationName: string;
  twoFactorAuthenticationPolicy: TwoFactorAuthenticationPolicy;
  choiceExportFormat: ChoiceExportFormat;
  choiceExportFormatExtra: ChoiceExportFormatExtra;
  forcedFiscalYear?: number;
  nmbrsDomain?: string;
  daywizeClientCode?: string;
  isB2B: boolean;
  microsoft365Active: boolean;
  isDisabled: boolean;
  privacyStatementExists: boolean;
}

export const emptyOrganization: OrganizationModel = {
  applicationName: "",
  appStyles: {},
  appTheme: 0,
  choiceExportFormat: ChoiceExportFormat.generic,
  choiceExportFormatExtra: ChoiceExportFormatExtra.none,
  contractManagement: ModuleOption.default,
  id: -1,
  isB2B: false,
  logoUrl: "",
  name: "",
  portal: ModuleOption.default,
  prefix: "",
  twoFactorAuthenticationPolicy: TwoFactorAuthenticationPolicy.Optional,
  microsoft365Active: false,
  isDisabled: false,
  privacyStatementExists: false,
};

export interface ContentNodeModel {
  id?: number;
  position: number;
  title: string;
}

export interface DocumentContentNodeModel extends ContentNodeModel {
  contentType?: string;
  documentName: string;
  documentDataUrl?: string;
}

export interface LinkContentNodeModel extends ContentNodeModel {
  link: string;
}

export type NavigationSpecial =
  | "alacarte"
  | "contract"
  | "employers"
  | "greenChoice"
  | "orgplan"
  | "pao"
  | "wlzAdvice"
  | "debitCard";

export interface NavigationNodeModel {
  id?: number;
  isRootNode?: boolean;
  title: string;
  text: string;
  route: string;
  icon?: string;
  children?: NavigationNodeModel[];
  level: number;
  contentNodes?: ContentNodeModel[];
  uploadType?: UploadTypeModel;
  uploadTypeId?: number;
  special?: NavigationSpecial;
  adminOnly?: boolean;
  roles?: string[];
  options?: (keyof ModuleOptions)[];

  alacarteInfo?: string;
  contractPrivate?: boolean;
  contractStart?: Date;
  contractEnd?: Date;
  contractAlert?: Date;
  contractClientName?: string;
  contractClientEmail?: string;
  contractSupplierName?: string;
  contractValue?: string;
  warning?: boolean;
}

export interface NavigationNodeFormValues extends NavigationNodeModel {
  uploadTypeIdSelected: number | "none";
}

type NavigationNodeValidationSchema = yup.ObjectSchema<
  yup.Shape<NavigationNodeModel, NavigationNodeModel>
>;

export interface NavigationNodeValidationSchemaHook {
  validationSchema: NavigationNodeValidationSchema;
}

export const useNavigationNodeValidationSchema =
  (): NavigationNodeValidationSchemaHook => {
    const [t] = useTranslationStrict();

    const validationSchema = useMemo(
      (): NavigationNodeValidationSchema =>
        yup.object<NavigationNodeModel>().shape<NavigationNodeModel>({
          title: yup
            .string()
            .required(t("Forms:Required") as string)
            .matches(/^\w.*\S$/, t("Admin:TitleInvalid") as string),
          route: yup.string().default(""),
          text: yup.string(),
          level: yup.number(),
          contractStart: yup.date(),
          contractEnd: yup.date(),
          contractAlert: yup.date(),
          contractClientName: yup
            .string()
            .required(t("Forms:Required") as string),
          contractClientEmail: yup
            .string()
            .required(t("Forms:Required") as string)
            .matches(/^.+@.+\.\w+$/, t("Admin:EmailInvalid") as string),
          contractSupplierName: yup.string(),
          contractValue: yup.string(),
        }),
      [t]
    );

    return { validationSchema };
  };

export type NavigationLocation = "appbar" | "aside";

export interface NavigationRootModel {
  id?: number;
  items: NavigationNodeModel[];
  location: NavigationLocation;
}

export const emptyMenu = Object.freeze<NavigationRootModel>({
  location: "aside",
  items: [],
});

export const emptyNavigation: NavigationRootModel =
  Object.freeze<NavigationRootModel>({ location: "appbar", items: [] });

export enum UserStatus {
  new = "new",
  invited = "invited",
  active = "active",
  activeExternal = "activeExternal",
}

export interface UserModel {
  id: number;
  loginName: string;
  fullName: string;
  email?: string;
  status?: UserStatus;
  employeeType?: string;
  isEmployee: boolean;
  isOrganizationAdmin: boolean;
  isDatacockpit: boolean;
  isPlatformAdmin: boolean;
  organizationId?: number;
  isApiAdmin: boolean;
  hasApiAccess: boolean;
}

export const emptyUser: UserModel = Object.freeze({
  id: 0,
  loginName: "",
  fullName: "",
  email: "",
  employeeType: undefined,
  isEmployee: false,
  isOrganizationAdmin: false,
  isDatacockpit: false,
  isPlatformAdmin: false,
  organizationId: undefined,
  status: undefined,
  isApiAdmin: false,
  hasApiAccess: false,
});

export interface JwtTokenModel {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpires: Date;
}

export const logOutReasonSessionStorageKey = "logOutReason";

export enum LogOutReason {
  idle = "idle",
  requestedByUser = "requestedByUser",
}

export interface TwoFactorTokenModel {
  knowledgeToken: string;
}

export interface SessionModel {
  applicationName: string;
  appRole: Role;
  googleAuthenticator?: TwoFactorTokenModel;
  jwt?: JwtTokenModel;
  profile?: ProfileModel;
  user?: UserModel;
}

export interface SearchItemModel {
  id: string;
  title: string;
}

export interface SearchQueryModel {
  query: string;
}

export interface SearchResultModel extends SearchQueryModel {
  totalCount: number;
  items: SearchItemModel[];
}

export interface ChoiceUploadModel {
  id: number;
  contentType: string;
  documentName: string;
  dataDefinitionId: number;
  file?: File;
}

export type ChoiceStatus = "initial" | "requested" | "approved";

export type ChoicePeriodMode = "monthly" | "salaryPeriod";
export const getChoicePeriodMode = (interval: ChoiceInterval) => {
  switch (interval) {
    case "monthly":
    case "annually":
    default:
      return "monthly";
    case "fourWeekly":
      return "salaryPeriod";
  }
};

export interface ChoicePeriod {
  startMonth?: number;
  endMonth?: number;
}

export interface ChoiceModel extends ChoicePeriod {
  definition: ChoiceDefinitionModel;
  maximumAmount: number;
  amount: number;
  repeating?: boolean;
}

export interface ChoiceSetModel extends Id<number> {
  choiceStatus?: ChoiceStatus;
  amountPerHour?: number;
  amountPerYear?: number;
  surplus?: number;
  permanent: boolean;
  employeeBenefit: number;
  employerBenefit: number;
  wkrChosen: number;
  wkrYearBudget: number;
  wkrYearMax: number;
  wkrAvailable: number;
  wkrBudget: number;
  endDate: Date;
  choices: ChoiceModel[];
  uploads: ChoiceUploadModel[];
}

export interface ChoiceValueModel {
  dataDefinitionId: number;
  amount: number;
  amountMax: number;
  amountOnce: number;
  amountRepeating: number;
  repeating?: boolean;
  startMonth?: number;
  endMonth?: number;
}

export interface ChoiceBenefitsDetails {
  name: string;
  approved: boolean;
  employeeBenefit: number;
  employerBenefit: number;
  choicePeriodDate?: Date;
}

export interface ChoicePeriodModel {
  id: number;
  startDateTime: Date;
}

export interface ChoiceBenefitsModel {
  choicePeriodId: number;
  details: ChoiceBenefitsDetails[];
  total: ChoiceBenefitsDetails;
}

export interface FrontendOptions {
  copyrightHolder: string;
  instrumentationKey: string;
}
