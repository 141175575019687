import { createContext } from "react";

export interface AdminNavigationContextValue {
  isEditing: boolean;
  setIsEditing(isEditing: boolean): void;
}

export default createContext<AdminNavigationContextValue>({
  isEditing: false,
  setIsEditing: () => {},
});
