import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Field, Form, Formik, FormikActions } from "formik";
import { Switch, TextField } from "formik-material-ui";
import React from "react";
import * as Yup from "yup";

import { EmployerModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import EuroInput from "../../input/EuroInput";
import PercentageInput from "../../input/PercentageInput";

interface EmployerFormProps {
  initialValues: EmployerModel;
  onCancel: () => void;
  onSubmit: (
    values: EmployerModel,
    formikActions: FormikActions<EmployerModel>
  ) => void;
  validationSchema: Yup.ObjectSchema<EmployerModel>;
}

const EmployerForm = ({
  initialValues,
  onCancel,
  onSubmit,
  validationSchema,
}: EmployerFormProps) => {
  const [t] = useTranslationStrict();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({
        handleChange,
        isSubmitting,
        isValid,
        setFieldValue,
        values: {
          name,
          government,
          isSmall,
          whkRate,
          whkRateEmployee,
          wkrBudget,
          period,
        },
      }) => (
        <Form>
          <Card>
            <CardHeader title={t("Admin:Employer")} />
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    label={t("Admin:Employer")}
                    component={TextField}
                    name="name"
                    onChange={handleChange}
                    placeholder={t("Admin:Employer")}
                    value={name}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        name="government"
                        disableRipple
                        onChange={handleChange}
                        value={government}
                      />
                    }
                    label={t("Admin:Government")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        name="isSmall"
                        disableRipple
                        onChange={handleChange}
                        value={isSmall}
                      />
                    }
                    label={t("Admin:IsSmall")}
                  />
                </Grid>
                <Grid item>
                  <PercentageInput
                    label={t("Admin:WhkRate")}
                    name="whkRate"
                    onChange={handleChange}
                    value={whkRate}
                  />
                </Grid>
                <Grid item>
                  <PercentageInput
                    label={t("Admin:WhkRateEmployee")}
                    name="whkRateEmployee"
                    onChange={handleChange}
                    value={whkRateEmployee}
                  />
                </Grid>
                <Grid item>
                  <EuroInput
                    label={t("Admin:WkrBudget")}
                    name="wkrBudget"
                    onChange={handleChange}
                    value={wkrBudget}
                  />
                </Grid>
                <Grid item>
                  <FormControl>
                    <InputLabel>{t("Admin:SalaryPeriod")}</InputLabel>
                    <Select
                      onChange={(
                        e: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        setFieldValue("period", e.target.value);
                      }}
                      style={{ minWidth: 196 }}
                      value={period}
                    >
                      <MenuItem key="Month" value="month">
                        {t("Admin:SalaryPeriodMonth")}
                      </MenuItem>
                      <MenuItem key="FourWeeks" value="fourWeeks">
                        {t("Admin:SalaryPeriodFourWeeks")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={onCancel} type="button">
                {t("Common:Cancel")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {t("Common:Save")}
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    />
  );
};

export default EmployerForm;
