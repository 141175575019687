import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";

import { useTranslationStrict } from "../../globalization/i18n";

export interface OrgplanChoice {
  code: string;
  label: string;
}

interface Props {
  choices: OrgplanChoice[];
  onClose(): void;
  startOrgplan(code: string): void;
}

const OrgplanChoiceModal = ({
  choices,
  onClose,
  startOrgplan,
}: Props): JSX.Element => {
  const [t] = useTranslationStrict();
  const [code, setCode] = useState<string | undefined>();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t("Common:OrgplanChoiceTitle")}</DialogTitle>
      <DialogContent>
        <FormControl>
          <Select
            onChange={(e) => setCode(e.target.value as string)}
            disabled={choices.length === 0}
          >
            {choices.map(({ code, label }) => (
              <MenuItem key={code} value={code}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {choices.length === 0
              ? t("Common:OrgplanChoiceUnauthorized")
              : t("Common:OrgplanChoiceLabel")}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t("Common:Cancel")}
        </Button>
        <Button
          disabled={!code}
          variant="contained"
          color="primary"
          onClick={() => {
            startOrgplan(code!);
            onClose();
          }}
        >
          {t("Common:OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgplanChoiceModal;
