import { createActor, createSingle } from "async-lifecycle-saga";

import {
  ContractsExportCreateRequest,
  ContractsExportFile,
} from "../../../../admin/contracts/models";
import {
  asyncDelete,
  asyncDownloadGet,
  asyncGet,
  asyncPost,
} from "../../../../api";

export const adminContractsExportGetAllCell = createSingle({
  path: ["admin", "contracts", "getAll"],
  require: {
    api: () => asyncGet<ContractsExportFile[]>("/api/admin/contracts/export"),
  },
});

export const adminContractsExportDownloadCell = createActor({
  path: ["admin", "contracts", "download"],
  api: (id: number) => asyncDownloadGet(`/api/admin/contracts/export/${id}`),
  invalidate: [adminContractsExportGetAllCell.events],
});

export const adminContractsExportCreateCell = createActor({
  path: ["admin", "contracts", "create"],
  api: (request: ContractsExportCreateRequest) =>
    asyncPost("/api/admin/contracts/export", request),
  invalidate: [adminContractsExportGetAllCell.events],
});

export const adminContractsExportDeleteCell = createActor({
  path: ["admin", "contracts", "delete"],
  api: (id: number) => asyncDelete(`/api/admin/contracts/export/${id}`),
  invalidate: [adminContractsExportGetAllCell.events],
});
