import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { positionDisplacementBlueBackground } from "../../styling/Common";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing() * 6,
    marginTop: theme.spacing() * 2,
    ...positionDisplacementBlueBackground(),
    textAlign: "center",
  },
}));

export default useStyles;
