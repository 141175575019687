import { MenuItem, Select } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import { MaterialUiPickersDate } from "material-ui-pickers/typings/date";
import React, { useCallback } from "react";

import formatEmployeeData from "../../../business/formatEmployeeData";
import { EmployeeDataMergedModel } from "../../../business/redux/saga/datacockpit/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import InputNumber from "../InputNumber";
import InputText from "../InputText";

export type EmployeeDataValueType = boolean | string | number;
const NoneValue = ":None";

export interface EditEmployeeFieldViewProps<T extends EmployeeDataValueType> {
  data: EmployeeDataMergedModel;
  onChange: (newValue: T | null, isValid: boolean) => void;
}

const tryReadValue = <T extends EmployeeDataValueType>(
  value: unknown
): T | null => {
  switch (typeof value) {
    case "boolean":
      return Boolean(value) as T;
    case "string":
      return value as T;
  }
  return null;
};

const EditEmployeeFieldView = <T extends EmployeeDataValueType>(
  props: EditEmployeeFieldViewProps<T>
) => {
  const { data, onChange } = props;
  const [t, { language }] = useTranslationStrict();

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (event.target.value === NoneValue) {
        onChange(null, true);
        return;
      }

      const newValue = tryReadValue<T>(
        data.dataDefinition?.dataType.dataField === "boolean"
          ? event.target.value === 1
          : event.target.value
      );
      onChange(newValue, true);
    },
    [data.dataDefinition?.dataType.dataField, onChange]
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      // When data is cleared, it will be null
      onChange(date, true);
    },
    [onChange]
  );

  const handleNumberChange = useCallback(
    (numberValue: number | null) => {
      onChange(!numberValue ? null : (numberValue as T), true);
    },
    [onChange]
  );

  const handleTextChange = useCallback(
    (textValue, isValid) => {
      onChange(!textValue ? null : textValue, isValid);
    },
    [onChange]
  );

  switch (data.dataDefinition!.dataType.name) {
    case "Boolean":
      return (
        <Select
          onChange={handleChange}
          value={data.isEmpty ? NoneValue : data.boolean ? 1 : 0}
        >
          <MenuItem value={NoneValue}>{t("Common:None")}</MenuItem>
          <MenuItem value={1}>{t("Common:Yes")}</MenuItem>
          <MenuItem value={0}>{t("Common:No")}</MenuItem>
        </Select>
      );
    case "Geslacht":
      return (
        <Select
          onChange={handleChange}
          value={data.isEmpty ? NoneValue : data.boolean ? 1 : 0}
        >
          <MenuItem value={NoneValue}>{t("Common:None")}</MenuItem>
          <MenuItem value={1}>{t("Common:Male")}</MenuItem>
          <MenuItem value={0}>{t("Common:Female")}</MenuItem>
        </Select>
      );
    case "Datum":
      return (
        <DatePicker
          keyboard
          clearable
          format="dd-MM-yyyy"
          onChange={handleDateChange}
          value={data.isEmpty ? null : data.date}
          maxDateMessage={t("Common:Datum mag niet na de maximale datum zijn")}
          minDateMessage={t(
            "Common:Datum mag niet voor de minimale datum zijn"
          )}
          invalidDateMessage={t("Common:Ongeldige datum")}
        />
      );
    case "Aantal":
      return (
        <InputNumber
          mode="number"
          value={data.isEmpty ? null : data.numeric}
          onChange={handleNumberChange}
        />
      );
    case "Bedrag":
      return (
        <InputNumber
          mode="money"
          value={data.isEmpty ? null : data.numeric}
          onChange={handleNumberChange}
        />
      );
    case "Percentage":
      return (
        <InputNumber
          mode="percentage"
          value={data.isEmpty ? null : data.numeric}
          onChange={handleNumberChange}
        />
      );
    case "Tekst":
      return (
        <InputText
          mode="text"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "BSN":
      return (
        <InputText
          mode="bsn"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "Postcode":
      return (
        <InputText
          mode="zipcode"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "E-mail":
      return (
        <InputText
          mode="email"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "E-maillijst":
      return (
        <InputText
          mode="emaillist"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "IBAN":
      return (
        <InputText
          mode="iban"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    case "Login":
      return (
        <InputText
          mode="login"
          value={data.isEmpty ? null : data.text}
          onChange={handleTextChange}
        />
      );
    default:
      return <>{formatEmployeeData(data.dataDefinition!, data, t, language)}</>;
  }
};

export default EditEmployeeFieldView;
