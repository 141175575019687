export interface LoginPageEvents {
  onLogin: (loginName: string, password: string) => void;
}

export type Microsoft365AuthenticationStatus =
  | "Welcome"
  | "NotConfigured"
  | "Waiting"
  | "MijnHRPlazaMismatch"
  | "Failed"
  | "Success";

export const TwoFactorAuthenticationPolicy = {
  Disabled: "disabled",
  Enforced: "enforced",
  Optional: "optional",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TwoFactorAuthenticationPolicy =
  typeof TwoFactorAuthenticationPolicy[keyof typeof TwoFactorAuthenticationPolicy];
