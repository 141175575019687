import { MenuItem } from "@material-ui/core";
import { Archive as ArchiveIcon } from "@material-ui/icons";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { UserModel } from "../../../business/models";
import { adminUsersUpdateCell } from "../../../business/redux/saga/admin/users/cells";
import useConfirm from "../../../confirm";

interface UserArchiveMenuItemProps {
  user: UserModel;
}

const UserArchiveMenuItem = (props: UserArchiveMenuItemProps) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation("Admin");

  const { confirm } = useConfirm();

  const handleArchiveConfirm = useCallback(() => {
    dispatch(
      adminUsersUpdateCell.require({
        ...user,
        isApiAdmin: false,
        isDatacockpit: false,
        isEmployee: false,
        isOrganizationAdmin: false,
        isPlatformAdmin: false,
      })
    );
  }, [dispatch, user]);

  const handleArchiveClick = useCallback(() => {
    confirm({
      texts: {
        ns: "Admin",
        title: "ArchiveVerb",
        content: t("UserArchiveConfirmMessage"),
      },
      onConfirm: handleArchiveConfirm,
      onCancel: () => {},
    });
  }, [confirm, handleArchiveConfirm, t]);

  return (
    <MenuItem key="Archive" onClick={handleArchiveClick}>
      <ArchiveIcon style={{ color: "orange" }} />
      <Trans ns="Common">Archive</Trans>
    </MenuItem>
  );
};

export default UserArchiveMenuItem;
