import { Grid } from "@material-ui/core";

import { formatEuro } from "../../../../utils/NumberUtils";
import { WlzTextFieldDisplay } from "./index";

interface CurrencyField {
  label: string;
  value: number;
}

const WlzCurrencyPairDisplay = (props: {
  fields: [CurrencyField, CurrencyField];
}) => {
  const { fields } = props;

  return (
    <>
      <Grid item>
        <WlzTextFieldDisplay
          name={fields[0].label}
          value={formatEuro(fields[0].value)}
        />
      </Grid>
      <Grid item>
        <WlzTextFieldDisplay
          name={fields[1].label}
          value={formatEuro(fields[1].value)}
        />
      </Grid>
    </>
  );
};

export default WlzCurrencyPairDisplay;
