import { Grid, Typography } from "@material-ui/core";

import { WlzPerson } from "../../../../business/personal/wlz/models";
import { useTranslationStrict } from "../../../../globalization/i18n";
import { renderDate } from "../../../../utils/DateUtils";
import { WlzCurrencyPairDisplay, WlzTextFieldDisplay } from "./";

interface Props {
  person: WlzPerson;
  index: number;
}

const WlzAdviceCaseSummarySubjectView = (props: Props) => {
  const { person, index } = props;
  const [t, { language }] = useTranslationStrict();

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Typography variant="h6">
          {t("Wlz:" + (index === 0 ? "Client" : "Partner"))}
        </Typography>
      </Grid>
      <Grid item>
        <WlzTextFieldDisplay name={t("Wlz:Naam")} value={person.name} />
      </Grid>
      <Grid item>
        <WlzTextFieldDisplay
          name={t("Wlz:Geboortedatum")}
          value={renderDate(person.dateOfBirth, language)}
        />
      </Grid>
      <div
        style={{
          gap: 4,
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        }}
      >
        <WlzCurrencyPairDisplay
          fields={[
            { label: t("Wlz:AOW uitkering"), value: person.aowBenefit },
            { label: t("Wlz:Pensioen"), value: person.pension },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            {
              label: t("Wlz:Winst uit onderneming"),
              value: person.profitEnterprise,
            },
            { label: t("Wlz:Overig inkomen"), value: person.incomeOther },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            {
              label: t("Wlz:WOZ-waarde woning"),
              value: person.wozValueResidence,
            },
            { label: t("Wlz:Hypotheek"), value: person.mortgage },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            { label: t("Wlz:Hypotheekrente"), value: person.mortgageInterest },
            {
              label: t("Wlz:Aftrek zorgkosten"),
              value: person.healthcareDeduction,
            },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            { label: t("Wlz:Aftrek giften"), value: person.giftsDeduction },
            { label: t("Wlz:Spaartegoed"), value: person.savingsCredit },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            { label: t("Wlz:Beleggingen"), value: person.investments },
            {
              label: t("Wlz:Inhouding loonheffing"),
              value: person.wageTaxWithholding,
            },
          ]}
        />
        <WlzCurrencyPairDisplay
          fields={[
            {
              label: t("Wlz:Ingeh. dividendbelasting"),
              value: person.dividendTaxWithheld,
            },
            {
              label: t("Wlz:Voorlopige aanslag IB"),
              value: person.incomeTaxAssessmentProvisional,
            },
          ]}
        />
      </div>
      <Grid item>
        <WlzTextFieldDisplay name={t("Wlz:Notities")} value={person.notes} />
      </Grid>
    </Grid>
  );
};

export default WlzAdviceCaseSummarySubjectView;
