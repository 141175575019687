import { Table, TableBody, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { drop, flow, take } from "lodash/fp";
import { memo, useCallback, useEffect, useState } from "react";

import { SortOrder, UserModel } from "../../../business/models";
import { useSession } from "../../../business/redux/saga/admin/hooks";
import { UsersEditorTableHead, UsersEditorTableRowNewUser } from "./";
import UsersEditorTableRow from "./UsersEditorTableRow";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

interface Props {
  users: UserModel[];
  onSort: (sortIndex: number | null, sortOrder: SortOrder) => void;
}

const UsersEditor = (props: Props) => {
  const { onSort, users } = props;
  const classes = useStyles();
  const {
    session: {
      user: {
        isPlatformAdmin: showPlatformAdmin = false,
        isApiAdmin: showApiAdmin = false,
      } = {},
    },
  } = useSession();
  const [usersPerPage, setUsersPerPage] = useState<number>(10);
  const [pageSelected, setPageSelected] = useState<number>(1);
  const [orderIndex, setOrderIndex] = useState<number | null>(null);
  const [order, setOrder] = useState<SortOrder>("asc");
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const usersPaginated = flow(
    drop(usersPerPage * (pageSelected - 1)),
    take(usersPerPage)
  )(users);

  const handleItemsPerPageSelected = (usersPerPageNew: number) => {
    setPageSelected(1);
    setUsersPerPage(usersPerPageNew);
  };

  const handleSort = useCallback(
    /**
     * Handles a request to change the sort order.
     * @param sortIndex The index of the column to sort.
     * @param sortOrder The new sort order.
     */
    (sortIndex: number | null, sortOrder: SortOrder) => {
      setOrderIndex(sortIndex);
      setOrder(sortOrder);
      onSort(sortIndex, sortOrder);
    },
    [onSort]
  );

  useEffect(() => {
    if (users) {
      setEditIndex(null);
    }
  }, [users]);

  return (
    <Table classes={{ root: classes.root }}>
      <UsersEditorTableHead
        itemsPerPage={usersPerPage}
        onItemsPerPageSelected={handleItemsPerPageSelected}
        onPageSelected={(page) => setPageSelected(page)}
        onSort={handleSort}
        selectedPage={pageSelected}
        showPlatformAdmin={showPlatformAdmin}
        showApiAdmin={showApiAdmin}
        sortIndex={orderIndex}
        sortOrder={order}
        usersCount={users.length}
      />
      <TableBody>
        {usersPaginated.map((user, index) => (
          <UsersEditorTableRow
            key={user.id}
            isEditing={index === editIndex}
            onEdit={() => setEditIndex(index)}
            showPlatformAdmin={showPlatformAdmin}
            showApiAdmin={showApiAdmin}
            user={user}
          />
        ))}
        <UsersEditorTableRowNewUser
          showPlatformAdmin={showPlatformAdmin}
          showApiAdmin={showApiAdmin}
        />
      </TableBody>
    </Table>
  );
};

export default memo(UsersEditor);
