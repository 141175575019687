export const Taxonomy: string[] = [
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_LAAG_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_LAAG_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_VORIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_LAAG_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_LAAG_AOW_INDEXERING_CLIENT",
  "WLZ_LAAG_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_LAAG_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_LAAG_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_INKOMEN_INDEXERING_CLIENT",
  "WLZ_LAAG_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_LAAG_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_LAAG_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_LAAG_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_LAAG_NETTO_INDEXERING_CLIENT",
  "WLZ_LAAG_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_LAAG_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_LAAG_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_LAAG_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_LAAG_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_LAAG_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_LAAG_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_LAAG_AOW_PROCENT_CLIENT",
  "WLZ_LAAG_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_LAAG_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_LAAG_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_INKOMEN_PROCENT_CLIENT",
  "WLZ_LAAG_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_LAAG_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_LAAG_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_LAAG_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_LAAG_NETTO_PROCENT_CLIENT",
  "WLZ_LAAG_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_PENSIOEN_PROCENT_CLIENT",
  "WLZ_LAAG_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_LAAG_VERMOGEN_PROCENT_CLIENT",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_LAAG_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_LAAG_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_LAAG_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_LAAG_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_LAAG_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_LAAG_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_VORIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_LAAG_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_LAAG_AOW_INDEXERING_PARTNER",
  "WLZ_LAAG_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_LAAG_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_LAAG_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_INKOMEN_INDEXERING_PARTNER",
  "WLZ_LAAG_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_LAAG_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_LAAG_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_LAAG_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_LAAG_NETTO_INDEXERING_PARTNER",
  "WLZ_LAAG_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_LAAG_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_LAAG_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_LAAG_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_LAAG_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_LAAG_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_LAAG_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_LAAG_AOW_PROCENT_PARTNER",
  "WLZ_LAAG_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_LAAG_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_LAAG_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_INKOMEN_PROCENT_PARTNER",
  "WLZ_LAAG_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_LAAG_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_LAAG_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_LAAG_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_LAAG_NETTO_PROCENT_PARTNER",
  "WLZ_LAAG_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_PENSIOEN_PROCENT_PARTNER",
  "WLZ_LAAG_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_LAAG_VERMOGEN_PROCENT_PARTNER",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_LAAG_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_LAAG_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_LAAG_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_LAAG_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_LAAG_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_LAAG_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_VORIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_LAAG_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_LAAG_AOW_INDEXERING_SAMEN",
  "WLZ_LAAG_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_LAAG_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_LAAG_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_INKOMEN_INDEXERING_SAMEN",
  "WLZ_LAAG_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_LAAG_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_LAAG_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_LAAG_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_LAAG_NETTO_INDEXERING_SAMEN",
  "WLZ_LAAG_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_LAAG_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_LAAG_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_LAAG_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_LAAG_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_LAAG_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_LAAG_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_LAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_LAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_LAAG_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_LAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_LAAG_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_LAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_LAAG_AOW_PROCENT_SAMEN",
  "WLZ_LAAG_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_LAAG_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_LAAG_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_LAAG_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_LAAG_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_LAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_LAAG_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_LAAG_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_INKOMEN_PROCENT_SAMEN",
  "WLZ_LAAG_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_LAAG_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_LAAG_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_LAAG_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_LAAG_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_LAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_LAAG_NETTO_PROCENT_SAMEN",
  "WLZ_LAAG_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_LAAG_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_PENSIOEN_PROCENT_SAMEN",
  "WLZ_LAAG_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_LAAG_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_LAAG_VERMOGEN_PROCENT_SAMEN",
  "WLZ_LAAG_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_LAAG_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_LAAG_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_LAAG_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_LAAG_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_LAAG_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_TEMP_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_TEMP_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_VORIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_TEMP_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_TEMP_AOW_INDEXERING_CLIENT",
  "WLZ_TEMP_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_TEMP_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_TEMP_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_INKOMEN_INDEXERING_CLIENT",
  "WLZ_TEMP_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_TEMP_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_TEMP_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_TEMP_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_TEMP_NETTO_INDEXERING_CLIENT",
  "WLZ_TEMP_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_TEMP_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_TEMP_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_TEMP_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_TEMP_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_TEMP_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_TEMP_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_TEMP_AOW_PROCENT_CLIENT",
  "WLZ_TEMP_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_TEMP_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_TEMP_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_INKOMEN_PROCENT_CLIENT",
  "WLZ_TEMP_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_TEMP_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_TEMP_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_TEMP_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_TEMP_NETTO_PROCENT_CLIENT",
  "WLZ_TEMP_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_PENSIOEN_PROCENT_CLIENT",
  "WLZ_TEMP_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_TEMP_VERMOGEN_PROCENT_CLIENT",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_TEMP_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_TEMP_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_TEMP_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_TEMP_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_TEMP_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_TEMP_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_VORIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_TEMP_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_TEMP_AOW_INDEXERING_PARTNER",
  "WLZ_TEMP_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_TEMP_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_TEMP_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_INKOMEN_INDEXERING_PARTNER",
  "WLZ_TEMP_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_TEMP_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_TEMP_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_TEMP_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_TEMP_NETTO_INDEXERING_PARTNER",
  "WLZ_TEMP_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_TEMP_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_TEMP_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_TEMP_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_TEMP_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_TEMP_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_TEMP_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_TEMP_AOW_PROCENT_PARTNER",
  "WLZ_TEMP_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_TEMP_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_TEMP_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_INKOMEN_PROCENT_PARTNER",
  "WLZ_TEMP_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_TEMP_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_TEMP_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_TEMP_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_TEMP_NETTO_PROCENT_PARTNER",
  "WLZ_TEMP_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_PENSIOEN_PROCENT_PARTNER",
  "WLZ_TEMP_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_TEMP_VERMOGEN_PROCENT_PARTNER",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_TEMP_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_TEMP_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_TEMP_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_TEMP_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_TEMP_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_TEMP_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_VORIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_TEMP_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_TEMP_AOW_INDEXERING_SAMEN",
  "WLZ_TEMP_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_TEMP_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_TEMP_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_INKOMEN_INDEXERING_SAMEN",
  "WLZ_TEMP_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_TEMP_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_TEMP_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_TEMP_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_TEMP_NETTO_INDEXERING_SAMEN",
  "WLZ_TEMP_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_TEMP_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_TEMP_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_TEMP_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_TEMP_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_TEMP_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_TEMP_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_TEMP_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_TEMP_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_TEMP_AOW_PROCENT_SAMEN",
  "WLZ_TEMP_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_TEMP_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_TEMP_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_TEMP_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_TEMP_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_TEMP_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_TEMP_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_TEMP_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_INKOMEN_PROCENT_SAMEN",
  "WLZ_TEMP_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_TEMP_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_TEMP_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_TEMP_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_TEMP_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_TEMP_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_TEMP_NETTO_PROCENT_SAMEN",
  "WLZ_TEMP_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_TEMP_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_PENSIOEN_PROCENT_SAMEN",
  "WLZ_TEMP_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_TEMP_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_TEMP_VERMOGEN_PROCENT_SAMEN",
  "WLZ_TEMP_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_TEMP_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_TEMP_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_TEMP_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_TEMP_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_TEMP_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_HOOG_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_HOOG_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_VORIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_HOOG_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_HOOG_AOW_INDEXERING_CLIENT",
  "WLZ_HOOG_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_HOOG_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_HOOG_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_INKOMEN_INDEXERING_CLIENT",
  "WLZ_HOOG_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_HOOG_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_HOOG_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_HOOG_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_HOOG_NETTO_INDEXERING_CLIENT",
  "WLZ_HOOG_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_HOOG_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_HOOG_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_HOOG_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_HOOG_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_HOOG_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_HOOG_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_HOOG_AOW_PROCENT_CLIENT",
  "WLZ_HOOG_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_HOOG_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_HOOG_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_INKOMEN_PROCENT_CLIENT",
  "WLZ_HOOG_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_HOOG_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_HOOG_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_HOOG_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_HOOG_NETTO_PROCENT_CLIENT",
  "WLZ_HOOG_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_PENSIOEN_PROCENT_CLIENT",
  "WLZ_HOOG_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_HOOG_VERMOGEN_PROCENT_CLIENT",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_HOOG_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_HOOG_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_HOOG_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_HOOG_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_HOOG_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_HOOG_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_VORIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_HOOG_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_HOOG_AOW_INDEXERING_PARTNER",
  "WLZ_HOOG_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_HOOG_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_HOOG_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_INKOMEN_INDEXERING_PARTNER",
  "WLZ_HOOG_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_HOOG_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_HOOG_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_HOOG_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_HOOG_NETTO_INDEXERING_PARTNER",
  "WLZ_HOOG_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_HOOG_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_HOOG_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_HOOG_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_HOOG_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_HOOG_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_HOOG_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_HOOG_AOW_PROCENT_PARTNER",
  "WLZ_HOOG_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_HOOG_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_HOOG_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_INKOMEN_PROCENT_PARTNER",
  "WLZ_HOOG_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_HOOG_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_HOOG_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_HOOG_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_HOOG_NETTO_PROCENT_PARTNER",
  "WLZ_HOOG_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_PENSIOEN_PROCENT_PARTNER",
  "WLZ_HOOG_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_HOOG_VERMOGEN_PROCENT_PARTNER",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_HOOG_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_HOOG_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_HOOG_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_HOOG_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_HOOG_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_HOOG_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_VORIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_HOOG_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_HOOG_AOW_INDEXERING_SAMEN",
  "WLZ_HOOG_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_HOOG_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_HOOG_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_INKOMEN_INDEXERING_SAMEN",
  "WLZ_HOOG_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_HOOG_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_HOOG_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_HOOG_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_HOOG_NETTO_INDEXERING_SAMEN",
  "WLZ_HOOG_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_HOOG_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_HOOG_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_HOOG_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_HOOG_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_HOOG_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_HOOG_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_HOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_HOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_HOOG_AOW_PROCENT_SAMEN",
  "WLZ_HOOG_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_HOOG_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_HOOG_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_HOOG_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_HOOG_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_HOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_HOOG_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_HOOG_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_INKOMEN_PROCENT_SAMEN",
  "WLZ_HOOG_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_HOOG_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_HOOG_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_HOOG_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_HOOG_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_HOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_HOOG_NETTO_PROCENT_SAMEN",
  "WLZ_HOOG_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_HOOG_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_PENSIOEN_PROCENT_SAMEN",
  "WLZ_HOOG_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_HOOG_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_HOOG_VERMOGEN_PROCENT_SAMEN",
  "WLZ_HOOG_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_HOOG_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_HOOG_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_HOOG_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_HOOG_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_HOOG_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_DIFF_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_DIFF_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_VORIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_DIFF_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_DIFF_AOW_INDEXERING_CLIENT",
  "WLZ_DIFF_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_DIFF_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_DIFF_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_INKOMEN_INDEXERING_CLIENT",
  "WLZ_DIFF_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_DIFF_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_DIFF_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_DIFF_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_DIFF_NETTO_INDEXERING_CLIENT",
  "WLZ_DIFF_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_DIFF_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_DIFF_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_DIFF_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_DIFF_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_DIFF_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_DIFF_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_DIFF_AOW_PROCENT_CLIENT",
  "WLZ_DIFF_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_DIFF_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_DIFF_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_INKOMEN_PROCENT_CLIENT",
  "WLZ_DIFF_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_DIFF_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_DIFF_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_DIFF_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_DIFF_NETTO_PROCENT_CLIENT",
  "WLZ_DIFF_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_PENSIOEN_PROCENT_CLIENT",
  "WLZ_DIFF_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_DIFF_VERMOGEN_PROCENT_CLIENT",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_DIFF_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_DIFF_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_DIFF_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_DIFF_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_DIFF_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_DIFF_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_VORIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_DIFF_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_DIFF_AOW_INDEXERING_PARTNER",
  "WLZ_DIFF_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_DIFF_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_DIFF_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_INKOMEN_INDEXERING_PARTNER",
  "WLZ_DIFF_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_DIFF_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_DIFF_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_DIFF_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_DIFF_NETTO_INDEXERING_PARTNER",
  "WLZ_DIFF_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_DIFF_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_DIFF_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_DIFF_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_DIFF_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_DIFF_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_DIFF_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_DIFF_AOW_PROCENT_PARTNER",
  "WLZ_DIFF_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_DIFF_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_DIFF_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_INKOMEN_PROCENT_PARTNER",
  "WLZ_DIFF_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_DIFF_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_DIFF_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_DIFF_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_DIFF_NETTO_PROCENT_PARTNER",
  "WLZ_DIFF_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_PENSIOEN_PROCENT_PARTNER",
  "WLZ_DIFF_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_DIFF_VERMOGEN_PROCENT_PARTNER",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_DIFF_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_DIFF_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_DIFF_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_DIFF_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_DIFF_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_DIFF_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_VORIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_DIFF_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_DIFF_AOW_INDEXERING_SAMEN",
  "WLZ_DIFF_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_DIFF_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_DIFF_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_INKOMEN_INDEXERING_SAMEN",
  "WLZ_DIFF_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_DIFF_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_DIFF_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_DIFF_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_DIFF_NETTO_INDEXERING_SAMEN",
  "WLZ_DIFF_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_DIFF_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_DIFF_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_DIFF_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_DIFF_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_DIFF_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_DIFF_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_DIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_DIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_DIFF_AOW_PROCENT_SAMEN",
  "WLZ_DIFF_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_DIFF_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_DIFF_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_DIFF_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_DIFF_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_DIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_DIFF_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_DIFF_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_INKOMEN_PROCENT_SAMEN",
  "WLZ_DIFF_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_DIFF_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_DIFF_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_DIFF_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_DIFF_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_DIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_DIFF_NETTO_PROCENT_SAMEN",
  "WLZ_DIFF_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_DIFF_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_PENSIOEN_PROCENT_SAMEN",
  "WLZ_DIFF_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_DIFF_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_DIFF_VERMOGEN_PROCENT_SAMEN",
  "WLZ_DIFF_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_DIFF_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_DIFF_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_DIFF_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_DIFF_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_DIFF_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AOW_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_NETTO_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AOW_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMLAAG_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_SIMLAAG_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_SIMLAAG_NETTO_PROCENT_CLIENT",
  "WLZ_SIMLAAG_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_PENSIOEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMLAAG_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_SIMLAAG_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AOW_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_NETTO_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AOW_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMLAAG_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_SIMLAAG_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_SIMLAAG_NETTO_PROCENT_PARTNER",
  "WLZ_SIMLAAG_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_PENSIOEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMLAAG_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_SIMLAAG_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AOW_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_NETTO_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_SIMLAAG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_SIMLAAG_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AOW_PROCENT_SAMEN",
  "WLZ_SIMLAAG_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMLAAG_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_SIMLAAG_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_SIMLAAG_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_SIMLAAG_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_SIMLAAG_NETTO_PROCENT_SAMEN",
  "WLZ_SIMLAAG_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMLAAG_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_PENSIOEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_SIMLAAG_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_SIMLAAG_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_SIMLAAG_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_SIMLAAG_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMLAAG_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_SIMLAAG_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AOW_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_NETTO_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AOW_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMHOOG_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_SIMHOOG_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_SIMHOOG_NETTO_PROCENT_CLIENT",
  "WLZ_SIMHOOG_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_PENSIOEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMHOOG_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_SIMHOOG_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AOW_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_NETTO_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AOW_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMHOOG_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_SIMHOOG_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_SIMHOOG_NETTO_PROCENT_PARTNER",
  "WLZ_SIMHOOG_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_PENSIOEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMHOOG_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_SIMHOOG_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AOW_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_NETTO_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_SIMHOOG_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_SIMHOOG_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AOW_PROCENT_SAMEN",
  "WLZ_SIMHOOG_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMHOOG_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_SIMHOOG_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_SIMHOOG_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_SIMHOOG_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_SIMHOOG_NETTO_PROCENT_SAMEN",
  "WLZ_SIMHOOG_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMHOOG_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_PENSIOEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_SIMHOOG_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_SIMHOOG_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_SIMHOOG_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_SIMHOOG_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMHOOG_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_SIMHOOG_ZORGTOESLAG_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ARBEIDSKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_AFTREK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BETALEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOXEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_FORFAIT_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_BOX1_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KLEINE_WONING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_CAK_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_PENSIOEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGPREMIE_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGTOESLAG_EERVORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ARBEIDSKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_AFTREK_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BETALEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX2_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX3_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOXEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_FORFAIT_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_BOX1_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KLEINE_WONING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_CAK_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_PENSIOEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGPREMIE_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGTOESLAG_VORIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ARBEIDSKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_AFTREK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BETALEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOXEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_FORFAIT_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_BOX1_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KLEINE_WONING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_NETTO_CAK_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_PENSIOEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGPREMIE_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_ZORGTOESLAG_HUIDIGJAAR_CLIENT",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AOW_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_ARBEIDSKORTING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_AFTREK_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BETALEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX2_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX3_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOXEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_FORFAIT_WONING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_BOX1_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_WONING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_KLEINE_WONING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_NETTO_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_NETTO_CAK_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_PENSIOEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_ZORGPREMIE_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_ZORGTOESLAG_INDEXERING_CLIENT",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_CLIENT",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AOW_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMDIFF_ARBEIDSKORTING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_AFTREK_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BETALEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX2_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOX3_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_BOXEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_PROCENT_CLIENT",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_CLIENT",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_CLIENT",
  "WLZ_SIMDIFF_FORFAIT_WONING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_PROCENT_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_BOX1_PROCENT_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_INKOMEN_WONING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_KLEINE_WONING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_CLIENT",
  "WLZ_SIMDIFF_NETTO_PROCENT_CLIENT",
  "WLZ_SIMDIFF_NETTO_CAK_PROCENT_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_PROCENT_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_CLIENT",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_PENSIOEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_PROCENT_CLIENT",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_PROCENT_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_PROCENT_CLIENT",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_PROCENT_CLIENT",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_PROCENT_CLIENT",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_PROCENT_CLIENT",
  "WLZ_SIMDIFF_ZORGPREMIE_PROCENT_CLIENT",
  "WLZ_SIMDIFF_ZORGTOESLAG_PROCENT_CLIENT",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ARBEIDSKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_AFTREK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BETALEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOXEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_FORFAIT_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_BOX1_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KLEINE_WONING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_CAK_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_PENSIOEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGPREMIE_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGTOESLAG_EERVORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ARBEIDSKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_AFTREK_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BETALEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX2_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX3_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOXEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_FORFAIT_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_BOX1_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KLEINE_WONING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_CAK_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_PENSIOEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGPREMIE_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGTOESLAG_VORIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ARBEIDSKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_AFTREK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BETALEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOXEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_FORFAIT_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_BOX1_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KLEINE_WONING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_NETTO_CAK_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_PENSIOEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGPREMIE_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_ZORGTOESLAG_HUIDIGJAAR_PARTNER",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AOW_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_ARBEIDSKORTING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_AFTREK_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BETALEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX2_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX3_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOXEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_FORFAIT_WONING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_BOX1_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_WONING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_KLEINE_WONING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_NETTO_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_NETTO_CAK_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_PENSIOEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_ZORGPREMIE_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_ZORGTOESLAG_INDEXERING_PARTNER",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_PARTNER",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AOW_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMDIFF_ARBEIDSKORTING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_AFTREK_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BETALEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX2_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOX3_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_BOXEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_PROCENT_PARTNER",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_PARTNER",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_PARTNER",
  "WLZ_SIMDIFF_FORFAIT_WONING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_PROCENT_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_BOX1_PROCENT_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_INKOMEN_WONING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_KLEINE_WONING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_PARTNER",
  "WLZ_SIMDIFF_NETTO_PROCENT_PARTNER",
  "WLZ_SIMDIFF_NETTO_CAK_PROCENT_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_PROCENT_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_PARTNER",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_PENSIOEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_PROCENT_PARTNER",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_PROCENT_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_PROCENT_PARTNER",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_PROCENT_PARTNER",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_PROCENT_PARTNER",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_PROCENT_PARTNER",
  "WLZ_SIMDIFF_ZORGPREMIE_PROCENT_PARTNER",
  "WLZ_SIMDIFF_ZORGTOESLAG_PROCENT_PARTNER",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ARBEIDSKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_AFTREK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BETALEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOXEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_FORFAIT_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_BOX1_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KLEINE_WONING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_CAK_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_PENSIOEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGPREMIE_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGTOESLAG_EERVORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ARBEIDSKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_AFTREK_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BETALEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX2_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX3_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOXEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_FORFAIT_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_BOX1_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KLEINE_WONING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_CAK_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_PENSIOEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGPREMIE_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGTOESLAG_VORIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ARBEIDSKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_AFTREK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BETALEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOXEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_FORFAIT_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_BOX1_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KLEINE_WONING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_NETTO_CAK_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_PENSIOEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGPREMIE_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_ZORGTOESLAG_HUIDIGJAAR_SAMEN",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AOW_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_ARBEIDSKORTING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_AFTREK_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BETALEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX2_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX3_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOXEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_FORFAIT_WONING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_BOX1_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_WONING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_KLEINE_WONING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_NETTO_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_NETTO_CAK_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_PENSIOEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_ZORGPREMIE_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_ZORGTOESLAG_INDEXERING_SAMEN",
  "WLZ_SIMDIFF_AFTREK_BESCHIKBAAR_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_INKOMSTEN_ARBEID_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_PENSIOENGERECHTIGD_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AFTREK_ZORGVERZEKERINGSWET_PROCENT_SAMEN",
  "WLZ_SIMDIFF_ALGEMENE_HEFFINGSKORTING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AOW_PROCENT_SAMEN",
  "WLZ_SIMDIFF_AOW_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMDIFF_ARBEIDSKORTING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_AFTREK_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BETALEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX1_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX2_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOX3_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_BOXEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_TOTAAL_ZVW_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_INHOUDING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BELASTING_NA_VOORLOPIG_PROCENT_SAMEN",
  "WLZ_SIMDIFF_BIJTELLING_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_COMPENSATIE_OUDEREN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP1_PROCENT_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP2_PROCENT_SAMEN",
  "WLZ_SIMDIFF_EIGEN_BIJDRAGE_STAP3_PROCENT_SAMEN",
  "WLZ_SIMDIFF_FORFAIT_WONING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_GROEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONBENUT_PROCENT_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_ONTVANGEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_HEFFINGSKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_BOX1_PROCENT_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_NA_BELASTING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_INKOMEN_WONING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_KLEINE_WONING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_KORTING_PENSIOENGERECHTIGDE_LEEFTIJD_PROCENT_SAMEN",
  "WLZ_SIMDIFF_NETTO_PROCENT_SAMEN",
  "WLZ_SIMDIFF_NETTO_CAK_PROCENT_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_PROCENT_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_ALLEENSTAAND_PROCENT_SAMEN",
  "WLZ_SIMDIFF_OUDERENKORTING_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_PENSIOEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_REGELINGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_SOCIALE_PREMIES_PROCENT_SAMEN",
  "WLZ_SIMDIFF_TOESLAGEN_TOTAAL_PROCENT_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_GRONDSLAG_PROCENT_SAMEN",
  "WLZ_SIMDIFF_VERMOGEN_SURPLUS_PROCENT_SAMEN",
  "WLZ_SIMDIFF_VERZAMELINKOMEN_PROCENT_SAMEN",
  "WLZ_SIMDIFF_ZAK_EN_KLEEDGELD_PROCENT_SAMEN",
  "WLZ_SIMDIFF_ZORGPREMIE_PROCENT_SAMEN",
  "WLZ_SIMDIFF_ZORGTOESLAG_PROCENT_SAMEN",
  "TAXONOMY_LENGTH",
];
