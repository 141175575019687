import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useDispatch } from "react-redux";

import formatEmployeeData from "../../../business/formatEmployeeData";
import { datacockpitEmployeeDataEditCell } from "../../../business/redux/saga/datacockpit/cells";
import { EmployeeDataMergedModel } from "../../../business/redux/saga/datacockpit/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { minDate } from "../../../utils/DateUtils";
import EditEmployeeFieldView, {
  EmployeeDataValueType,
} from "./EditEmployeeFieldView";
import EmployeeDataAddTableRow from "./EmployeeDataAddTableRow";

const getDefaultValueIfNull = <T extends EmployeeDataValueType>(
  value: T | null,
  dataField: "boolean" | "date" | "numeric" | "text"
) => {
  if (value != null) {
    return value;
  }

  switch (dataField) {
    case "boolean":
      return false;
    case "date":
      return minDate;
    case "numeric":
      return 0;
    case "text":
      return "";
  }
};

const setValue = <T extends EmployeeDataValueType>(
  employeeData: EmployeeDataMergedModel,
  value: T | null
): EmployeeDataMergedModel => {
  return {
    ...employeeData,
    [employeeData.dataDefinition!.dataType.dataField]: getDefaultValueIfNull(
      value,
      employeeData.dataDefinition!.dataType.dataField
    ),
  };
};

const EmployeeDataTableBodyEmpty = () => {
  const [t] = useTranslationStrict();

  return (
    <TableBody>
      <TableRow key="noData">
        <TableCell colSpan={2}>
          <p>
            <em>{t("Datacockpit:Er zijn geen gegevens in deze groep.")}</em>
          </p>
        </TableCell>
      </TableRow>
      <EmployeeDataAddTableRow />
    </TableBody>
  );
};

interface EmployeeDataTableContentsPopulatedProps {
  edit: boolean;
  employeeData: EmployeeDataMergedModel[];
}

const EmployeeDataTableBodyPopulated = (
  props: EmployeeDataTableContentsPopulatedProps
) => {
  const { edit, employeeData } = props;
  const [t, { language }] = useTranslationStrict();
  const dispatch = useDispatch();

  const handleFieldChange =
    <T extends EmployeeDataValueType>(dataDefinitionId: number) =>
    (value: T | null, isValid: boolean) => {
      const editEmployeeData = employeeData.find(
        (ed) => ed.dataDefinitionId === dataDefinitionId
      );
      if (!editEmployeeData) {
        throw new Error(
          `Field with data definition identifier ${dataDefinitionId} not found`
        );
      }
      const newEmployeeData = setValue(
        {
          ...editEmployeeData,
          invalid: !isValid,
          isEmpty:
            value === undefined ||
            value === null ||
            value === "" ||
            value === " ",
        },
        value
      );
      dispatch(
        datacockpitEmployeeDataEditCell.patch({
          dataDefinitionId,
          employeeData: newEmployeeData,
        })
      );
    };

  return (
    <TableBody>
      {employeeData.map((ed) => (
        <TableRow key={ed.dataDefinitionId}>
          <TableCell>
            {t(`Variables:${ed.dataDefinition?.code || ""}`)}
            <br />
            <code style={{ fontSize: "smaller" }}>
              {ed.dataDefinition?.code}
            </code>
          </TableCell>
          <TableCell>
            {ed.dataDefinition && (
              <>
                {edit ? (
                  <EditEmployeeFieldView
                    data={ed}
                    onChange={handleFieldChange(ed.dataDefinitionId)}
                  />
                ) : (
                  formatEmployeeData(ed.dataDefinition, ed, t, language)
                )}
              </>
            )}
          </TableCell>
        </TableRow>
      ))}
      <EmployeeDataAddTableRow />
    </TableBody>
  );
};

interface EmployeeDataViewProps {
  employeeData: EmployeeDataMergedModel[];
  edit: boolean;
}

const EmployeeDataView = (props: EmployeeDataViewProps) => {
  const { employeeData, edit } = props;

  return (
    <Table>
      {employeeData.length === 0 && <EmployeeDataTableBodyEmpty />}
      {employeeData.length > 0 && (
        <EmployeeDataTableBodyPopulated
          edit={edit}
          employeeData={employeeData}
        />
      )}
    </Table>
  );
};

export default EmployeeDataView;
