import React from "react";

import { identity } from "../../utils/ValueConverters";
import CardProps from "../layout/CardProps";

export interface DashboardProps {
  cards?: React.ReactElement<CardProps>[][];
}

const Dashboard = React.memo<DashboardProps>(
  (props: DashboardProps): JSX.Element => {
    const { cards } = props;

    if (!cards || cards.length !== 3) {
      throw new Error("Cards must have exactly 3 columns.");
    }

    return (
      <>
        {cards[1].map(
          (element: React.ReactElement, index: number): React.ReactElement => (
            <div
              key={`column1row${identity(index)}`}
              style={{ gridColumn: 2, gridRow: 2 + index }}
            >
              {element}
            </div>
          )
        )}
        {cards[2].map(
          (element: React.ReactElement, index: number): React.ReactElement => (
            <div
              key={`column2row${identity(index)}`}
              style={{ gridColumn: 3, gridRow: 2 + index }}
            >
              {element}
            </div>
          )
        )}
      </>
    );
  }
);

export default Dashboard;
