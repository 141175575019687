import { combineReducers } from "redux";

import {
  organizationGetter,
  organizationPrivacyStatementDownloadGetter,
} from "./api";
import {
  organizationCell,
  organizationPrivacyStatementDownloadCell,
  organizationPrivacyStatementExistsCell,
} from "./cells";
import { OrganizationModelJson, OrganizationStoreModel } from "./models";

const organizationReducer = combineReducers({
  get: organizationCell.reducer,
  privacyStatementDownload: organizationPrivacyStatementDownloadCell.reducer,
  privacyStatementExists: organizationPrivacyStatementExistsCell.reducer,
});

const organizationSagas = [
  ...organizationCell.sagas,
  ...organizationPrivacyStatementDownloadCell.sagas,
  ...organizationPrivacyStatementExistsCell.sagas,
];

export type { OrganizationModelJson, OrganizationStoreModel };
export {
  organizationCell,
  organizationGetter,
  organizationPrivacyStatementDownloadCell,
  organizationPrivacyStatementDownloadGetter,
  organizationPrivacyStatementExistsCell,
  organizationReducer,
  organizationSagas,
};
