import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import * as React from "react";
import { memo, useCallback, useState } from "react";

import { DocumentContentNodeModel } from "../../../business/models";
import { useTranslationStrict } from "../../../globalization/i18n";
import { getFileNameWithoutExtension } from "../../../utils/FileUtils";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
}));

interface Props {
  acceptedFiles?: string[];
  contentNode: DocumentContentNodeModel;
  onCancel(): void;
  onSave(
    document: DocumentContentNodeModel,
    file: File,
    onSuccess: () => void
  ): void;
}

const ContentNodeDocumentEditor = (props: Props) => {
  const { acceptedFiles, contentNode, onCancel, onSave } = props;
  const classes = useStyles();
  const [t] = useTranslationStrict();

  const [documentUploadReady, setDocumentUploadReady] =
    React.useState<boolean>(false);

  const [currentTitle, setCurrentTitle] = useState<string>(
    contentNode?.title || ""
  );
  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentTitle(event.currentTarget.value);
    },
    [setCurrentTitle]
  );

  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const handleChange = useCallback(
    (files: File[]) => {
      setCurrentFiles(files);
      if (files.length > 0) {
        setCurrentTitle(getFileNameWithoutExtension(files[0].name));
      }
    },
    [setCurrentFiles, setCurrentTitle]
  );

  const handleAddClick = useCallback(() => {
    if (currentFiles.length !== 1) {
      return;
    }

    onSave(
      {
        position: contentNode?.position || -1,
        title: currentTitle,
        documentName: currentFiles[0].name,
      },
      currentFiles[0],
      (): void => {
        setCurrentFiles([]);
        setCurrentTitle("");
        setDocumentUploadReady(true);
      }
    );
  }, [contentNode?.position, currentFiles, currentTitle, onSave]);

  const handleNewDocumentClick = (): void => {
    setDocumentUploadReady(false);
  };

  return (
    <Grid container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="h5">
          {t("Admin:ContentNodeAddDocumentHeader")}
        </Typography>
      </Grid>
      <Grid item>
        {!documentUploadReady && (
          <FormControl classes={{ root: classes.formControl }}>
            <FormLabel>{t("Admin:ContentNodeTitle")}</FormLabel>
            <TextField value={currentTitle} onChange={handleTitleChange} />
          </FormControl>
        )}
      </Grid>
      <Grid item>
        {!documentUploadReady && (
          <DropzoneArea
            acceptedFiles={acceptedFiles}
            dropzoneText={t("Admin:ContentNodeDocumentDropText")}
            filesLimit={1}
            maxFileSize={33554432}
            onChange={handleChange}
          />
        )}
        {documentUploadReady && (
          <>
            <Typography>{t("Common:ThankYou")}</Typography>
            <Button onClick={handleNewDocumentClick}>
              {t("Admin:ContentNodeAddDocumentHeader")}
            </Button>
          </>
        )}
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          {!documentUploadReady && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddClick}
            >
              {t("Common:Add")}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {t("Common:Cancel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(ContentNodeDocumentEditor);
