import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { History as HistoryIcon, Person } from "@material-ui/icons";
import { Success } from "async-lifecycle-saga/dist/models";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { NavigationHistoryEntry } from "../../business/redux/saga/navigation/models";
import useNavigation from "../../business/redux/saga/navigation/useNavigation";
import { useTranslationStrict } from "../../globalization/i18n";
import ContentCard from "../layout/ContentCard";
import useStyles from "./NavigationHistoryCard.styles";

const NavigationHistoryCard = () => {
  const [t] = useTranslationStrict();

  const { getHistory } = useNavigation();

  const [navigationHistory, setNavigationHistory] = useState<
    NavigationHistoryEntry[] | null
  >(null);

  useEffect((): void => {
    if (navigationHistory !== null) {
      return;
    }
    getHistory(
      undefined,
      undefined,
      ({ body }: Success<NavigationHistoryEntry[]>): void => {
        setNavigationHistory(body);
      }
    );
  }, [getHistory, navigationHistory]);

  const classes = useStyles();

  return (
    <ContentCard
      title={t("Navigation:NavigationHistory")}
      info={t("Navigation:NavigationHistoryInfo")}
    >
      <List>
        {navigationHistory && navigationHistory.length < 5 && (
          <ListItem>
            <ListItemIcon>
              <Person className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <Link to="/profile">{t("Common:MyData")}</Link>
            </ListItemText>
          </ListItem>
        )}
        {navigationHistory &&
          navigationHistory.map(
            ({
              navigationNode: { id, route, title } = {
                title: "",
                text: "",
                level: 0,
                route: "",
              },
            }: NavigationHistoryEntry): React.ReactElement => (
              <ListItem key={id}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to={route}>{title}</Link>
                </ListItemText>
              </ListItem>
            )
          )}
      </List>
    </ContentCard>
  );
};

export default memo(NavigationHistoryCard);
