import classNames from "classnames";
import React from "react";

import usePageWrap from "../../styling/PageWrap";
import useStyles from "./Container.styles";

interface ContainerProps {
  children: React.ReactNode;
}

const Container = React.memo<ContainerProps>(
  (props: ContainerProps): React.ReactElement => {
    const containerClasses = useStyles();
    const pageWrapClasses = usePageWrap();
    const { children } = props;

    return (
      <>
        <div className={containerClasses.backgroundBlue} />
        <div
          className={classNames(containerClasses.root, pageWrapClasses.outer)}
        >
          <div
            className={classNames(
              containerClasses.content,
              pageWrapClasses.inner
            )}
          >
            {children}
          </div>
        </div>
      </>
    );
  }
);

export default Container;
