export const roundNumber = (value: number, decimals: number): number => {
  const shift = 10 ** decimals;
  return Math.round(shift * value) / shift;
};

export const toAmount = (value: number): number => {
  return roundNumber(value, 2);
};

interface NumberFormat {
  sep1000: string;
  sep10: string;
  decimals: number;
  showPlus?: boolean;
  prefix?: string;
}

type NumberFormatter = (value: number) => string;
type NumberParser = (value: string) => number;

export const formatInteger = (value: number, sep1000: string): string => {
  if (value === 0 || !Number.isFinite(value)) {
    return value.toString();
  }

  const div = Math.floor(value / 1000);
  const rem = (value % 1000).toString();
  return div === 0
    ? rem
    : `${formatInteger(div, sep1000)}${sep1000}${rem.padStart(3, "0")}`;
};

export const formatNumber =
  ({
    sep1000,
    sep10,
    decimals,
    prefix = "",
    showPlus = false,
  }: NumberFormat): NumberFormatter =>
  (value: number): string => {
    const roundedValue = roundNumber(value, decimals);

    // eslint-disable-next-line no-nested-ternary
    const signString = value < 0 ? "-" : value > 0 && showPlus ? "+" : "";

    const absNumber = Math.abs(roundedValue);
    const intNumber = Math.floor(absNumber);
    const intString = formatInteger(intNumber, sep1000);

    const wholeString = `${prefix}${signString}${intString}`;
    if (decimals === 0) {
      return wholeString;
    }

    const remNumber = Math.round(10 ** decimals * (absNumber - intNumber));
    const remString = remNumber.toString().padStart(decimals, "0");
    return `${wholeString}${sep10}${remString}`;
  };

export const parseNumber =
  ({ sep10, decimals }: NumberFormat): NumberParser =>
  (value: string): number => {
    const pos10 = value.indexOf(sep10) + 1;
    const decimalString =
      pos10 === 0 ? "" : value.substring(pos10).replace(/[^0-9]+/g, "");
    const fraction =
      decimalString.length === 0
        ? 0
        : Number(decimalString) / 10 ** decimalString.length;
    const sign = value.indexOf("-") < 0 ? 1 : -1;
    const wholeString = (
      pos10 === 0 ? value : value.substring(0, pos10)
    ).replace(/[^0-9]+/g, "");
    const whole = wholeString.length === 0 ? 0 : Number(wholeString);
    const roundedValue = roundNumber(whole + fraction, decimals);
    return sign * roundedValue;
  };

const generalFormat = {
  sep1000: ".",
  sep10: ",",
  decimals: 2,
};

const percentageFormat = {
  ...generalFormat,
  decimals: 3,
};

export const formatCurrency: NumberFormatter = formatNumber(generalFormat);

const euroFormat = {
  ...generalFormat,
  prefix: `€${String.fromCharCode(160)}`,
};
export const formatEuro: NumberFormatter = formatNumber(euroFormat);

export const formatEuroPlus: NumberFormatter = formatNumber({
  ...euroFormat,
  showPlus: true,
});

export const formatHours: NumberFormatter = formatNumber(generalFormat);

export const formatPercentage: NumberFormatter = formatNumber(percentageFormat);

export const parseInputNumber: NumberParser = parseNumber(generalFormat);

export const parsePercentage: NumberParser = parseNumber(percentageFormat);
