import { AppBar, Button, Tab, Tabs } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { EmployerModel } from "../../../../business/models";
import { adminEmployersListCell } from "../../../../business/redux/saga/admin/employers/cells";
import { Loading } from "../../../ux";
import SettingsEmployerTabPanel from "./SettingsEmployerTabPanel";
import useStyles from "./SettingsEmployerTabs.styles";

interface SettingsEmployerTabsProps {
  employers: EmployerModel[];
  loading: boolean;

  onChange(employers: EmployerModel[]): void;
}

const SettingsEmployerTabs = (props: SettingsEmployerTabsProps) => {
  const { employers, loading, onChange } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [codeAdded, setCodeAdded] = useState<string | null | undefined>(
    undefined
  );
  const [value, setValue] = useState<number>(0);
  useEffect(() => {
    if (codeAdded !== undefined) {
      setValue(employers.findIndex((e) => e.code === codeAdded));
      setCodeAdded(undefined);
    }
  }, [codeAdded, employers]);

  const mainEmployer = useMemo(
    () => employers.find((e) => !e.code)!,
    [employers]
  );

  const handleCreateClick = useCallback(() => {
    const newCode = uuidv4();
    dispatch(
      adminEmployersListCell.create(
        {
          id: -1,
          code: newCode,
          government: mainEmployer.government,
          isSmall: mainEmployer.isSmall,
          isSpecial: false,
          name: mainEmployer.name,
          organizationId: mainEmployer.organizationId,
          period: "month",
          whkRate: mainEmployer.whkRate,
          whkRateEmployee: mainEmployer.whkRateEmployee,
          fulltimeHours: mainEmployer.fulltimeHours,
          benefitCalculation: mainEmployer.benefitCalculation,
          benefitShared: mainEmployer.benefitShared,
          benefitSharedTaxExempt: mainEmployer.benefitSharedTaxExempt,
          benefitSharedDispensated: mainEmployer.benefitSharedDispensated,
          wkrBudget: 0,
        },
        {
          onSuccess: () => {
            dispatch({ type: adminEmployersListCell.events.invalidate });
            dispatch(
              adminEmployersListCell.require({
                onSuccess: () => {
                  setCodeAdded(newCode);
                },
              })
            );
          },
        }
      )
    );
  }, [dispatch, mainEmployer]);

  const handleEmployerDelete = useCallback(() => {
    setValue(0);
    dispatch({ type: adminEmployersListCell.events.invalidate });
    dispatch(adminEmployersListCell.require());
  }, [dispatch]);

  const handleEmployersChange = useCallback(
    (index: number, employer: EmployerModel) => {
      const employersNew = [...employers];
      employersNew[index] = employer;
      onChange(employersNew);
    },
    [employers, onChange]
  );

  const handleTabChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <TabContext value={value.toString()}>
          <AppBar position="static">
            <Tabs
              aria-label="simple tabs example"
              onChange={handleTabChange}
              scrollButtons="auto"
              value={value.toString()}
              variant="scrollable"
            >
              {employers.map((employer, index) => (
                <Tab label={employer.name ?? ""} value={index.toString()} />
              ))}
            </Tabs>
          </AppBar>
          {loading && <Loading />}
          {!loading &&
            employers.map((employer, index) => (
              <SettingsEmployerTabPanel
                key={index}
                employer={employer}
                index={index}
                onChange={handleEmployersChange}
                onDelete={handleEmployerDelete}
              />
            ))}
        </TabContext>
      </div>
      <div className={classes.controls}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={handleCreateClick}
        >
          <Trans ns="Admin" i18nKey="Werkgeversinstellingen toevoegen">
            Werkgeversinstellingen toevoegen
          </Trans>
        </Button>
      </div>
    </div>
  );
};

export default SettingsEmployerTabs;
