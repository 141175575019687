export enum DataTypeId {
  geslacht = -2,
  bedrag = -5,
}

export interface EmployeeDataModel {
  dataDefinitionId: number;
  isEmpty: boolean;
  isApproved: boolean;
  boolean: boolean;
  date: Date;
  invalid?: boolean;
  numeric: number;
  text?: string;
}

export interface VariableDataFilter {
  variableIds: number[];
  referenceDate: Date | null;
}

export interface VariableDataRow {
  data: EmployeeDataModel[];
}
