import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { adminUsersListIncludeArchivedCell } from "../../business/redux/saga/admin/users/cells";
import { useTranslationStrict } from "../../globalization/i18n";
import Pagination from "./Pagination";

const itemsPerPageOptions = [10, 20, 50, 100];

interface Props {
  colSpan: number;
  itemsCount: number;
  itemsPerPage: number;
  selectedPage: number;

  onItemsPerPageSelected?(itemsPerPage: number): void;

  onPageSelected(page: number): void;
}

const PaginationTableRow = (props: Props) => {
  const {
    colSpan,
    itemsCount,
    itemsPerPage,
    onItemsPerPageSelected,
    onPageSelected,
    selectedPage,
  } = props;

  const [t] = useTranslationStrict();
  const dispatch = useDispatch();

  const handleItemsPerPageSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    _: React.ReactNode
  ) => {
    onItemsPerPageSelected?.(event.target.value as number);
  };

  const includeArchived = useSelector(adminUsersListIncludeArchivedCell.select);
  const handleIncludeArchivedChecked = () => {
    dispatch(adminUsersListIncludeArchivedCell.require(!includeArchived));
  };

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Grid container direction="row" spacing={2} style={{ display: "flex" }}>
          <Grid item>
            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              onPageSelected={onPageSelected}
              selectedPage={selectedPage}
            />
          </Grid>
          {onItemsPerPageSelected && (
            <Grid item>
              <Select onChange={handleItemsPerPageSelect} value={itemsPerPage}>
                {itemsPerPageOptions.map((itemsPerPageValue) => (
                  <MenuItem value={itemsPerPageValue} key={itemsPerPageValue}>
                    {itemsPerPageValue}
                  </MenuItem>
                ))}
              </Select>
              <Typography component="span" style={{ marginLeft: "5px" }}>
                {t("Common:Items per pagina")}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeArchived}
                  onChange={handleIncludeArchivedChecked}
                />
              }
              label={t("Admin:Include archived", {
                defaultValue: "Include archived",
              })}
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default PaginationTableRow;
