import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Details, Success } from "async-lifecycle-saga/dist/models";
import { Field, Form, Formik, FormikActions, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { useSnackbar } from "notistack";
import querystring from "query-string";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
import * as Yup from "yup";

import { useAuthentication } from "../../../business/redux/saga/authentication/hooks";
import { AuthenticationResponseModel } from "../../../business/redux/saga/authentication/models";
import { useUser } from "../../../business/redux/saga/user/hooks";
import { reflexoHistory } from "../../../business/router";
import { useTranslationStrict } from "../../../globalization/i18n";
import Loading from "../../ux/Loading";
import {
  SnackbarErrorActionType,
  useSnackbarErrorMessage,
} from "../../ux/SnackbarErrorMessage";
import useStyles from "./CreatePasswordPage.styles";

interface CreatePasswordRouteQueryString {
  loginName: string;
  token: string;
}

interface CreatePasswordFormValues {
  newPassword: string;
}

const initialValues: CreatePasswordFormValues = {
  newPassword: "",
};

const passwordMinLength = 8;

const CreatePasswordPage = () => {
  const classes = useStyles();
  const [t] = useTranslationStrict();
  const { knowledge } = useAuthentication();
  const { verifyPasswordResetToken, resetPassword } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessage } = useSnackbarErrorMessage();
  const { history, location } =
    useReactRouter<CreatePasswordRouteQueryString>();

  const [isLoading, setIsLoading] = useState(true);
  const [hasVerifiedToken, setHasVerifiedToken] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [queryString] = useState<querystring.ParsedQuery>(
    querystring.parse(location.search)
  );
  const [fullname, setFullname] = useState("");

  useEffect(() => {
    if (!hasVerifiedToken) {
      setIsLoading(true);

      const { loginName, token } = queryString;

      verifyPasswordResetToken(
        { loginName: loginName as string, resetToken: token as string },
        {
          onFail: () => {
            setIsTokenValid(false);
            setIsLoading(false);
            setHasVerifiedToken(true);
          },
          onSuccess: ({
            body: { fullname: resultFullname, isValid: resultIsValid },
          }) => {
            setFullname(resultFullname);
            setIsTokenValid(resultIsValid);
            setIsLoading(false);
            setHasVerifiedToken(true);
          },
        }
      );
    }
    /*
     * Er ontstaat een oneindige lus als de afhankelijkheden worden opgesomd in het volgende argument.
     * Bovendien is het voldoende als dit éénmalig bij de eerste keer laden van de component gebeurt.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    (
      { newPassword }: CreatePasswordFormValues,
      actions: FormikActions<CreatePasswordFormValues>
    ): void => {
      const { loginName, token } = queryString;

      actions.setSubmitting(true);

      resetPassword(
        {
          loginName: loginName as string,
          resetToken: token as string,
          newPassword,
        },
        {
          onFail: ({ detail }) => {
            enqueueSnackbar(detail, { variant: "error" });
            actions.setSubmitting(false);
          },
          onSuccess: ({ body: { status } }) => {
            if (status !== "success") {
              if (status === "employeeNotFound") {
                enqueueSnackbar(t("User:UserNotFound"), { variant: "warning" });
              }
              if (status === "tokenInvalid") {
                showErrorMessage({
                  title: t("User:NewPasswordInvalidResetToken"),
                  message: t("User:ResetPasswordInvalidResetTokenMessage"),
                  actions: [
                    {
                      actionType: SnackbarErrorActionType.primary,
                      caption: t("User:ResetPasswordRequestNewToken"),
                      onClick: (): void => {
                        reflexoHistory.push("/reset");
                      },
                    },
                  ],
                });
              }
              if (status === "passwordInvalid") {
                actions.setFieldError(
                  "newPassword",
                  t("Forms:PasswordInvalid")
                );
              }

              actions.setSubmitting(false);
              return;
            }

            // authenticate immediately with the new password
            knowledge(
              { loginName: loginName as string, password: newPassword },
              {
                onFail: (details: Details) => {
                  enqueueSnackbar(details.title, { variant: "error" });
                  actions.setSubmitting(false);
                },
                onSuccess: ({
                  body: {
                    jwt: { accessToken, refreshToken } = {
                      accessToken: "",
                      refreshToken: "",
                      refreshTokenExpires: new Date(),
                    },
                    googleAuthenticator: { knowledgeToken } = {
                      knowledgeToken: "",
                    },
                  },
                }: Success<AuthenticationResponseModel>) => {
                  if (accessToken && refreshToken) {
                    actions.setSubmitting(false);
                    history.push("/");
                    return;
                  }
                  if (knowledgeToken) {
                    actions.setSubmitting(false);
                    history.push("/twofactorauth");
                  }
                },
              }
            );
          },
        }
      );
    },
    [
      enqueueSnackbar,
      history,
      knowledge,
      queryString,
      resetPassword,
      showErrorMessage,
      t,
    ]
  );

  const validationSchema = useMemo(
    (): Yup.ObjectSchema<
      Yup.Shape<CreatePasswordFormValues, CreatePasswordFormValues>
    > =>
      Yup.object<CreatePasswordFormValues>().shape<CreatePasswordFormValues>({
        newPassword: Yup.string()
          .required(t("Forms:Required"))
          .min(
            passwordMinLength,
            `${t("Forms:MinimumLength")}: ${passwordMinLength}`
          )
          .matches(/[A-Z]/, t("Forms:PasswordUpperCaseLetterRequired"))
          .matches(/[a-z]/, t("Forms:PasswordLowerCaseLetterRequired"))
          .matches(/\d/, t("Forms:PasswordDigitRequired"))
          .matches(/[^a-zA-Z\d]/, t("Forms:PasswordFormat")),
      }),
    [t]
  );

  return (
    <div className="gridMainContent">
      {isLoading && <Loading />}
      {!isLoading && !isTokenValid && (
        <Card>
          <CardHeader title={t("User:NewPasswordInvalidResetToken")} />
          <CardContent>
            <Paper classes={{ root: classes.warning }}>
              <Typography>
                {t("User:NewPasswordInvalidResetTokenMessage")}
              </Typography>
              <Typography>
                <Trans ns="User" i18nKey="ClickForLoginForm">
                  Klik
                  <Link
                    style={{
                      backgroundColor: "#D2FFCD",
                      borderColor: "#CDD2FF",
                      borderRadius: "8px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      color: "#000000",
                      fontWeight: "bold",
                      padding: "5px",
                    }}
                    to="/login"
                  >
                    HIER
                  </Link>
                  om naar het inlogscherm te gaan.
                </Trans>
              </Typography>
            </Paper>
          </CardContent>
        </Card>
      )}
      {!isLoading && isTokenValid && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({
            isValid,
            isSubmitting,
            values: { newPassword },
          }: FormikProps<CreatePasswordFormValues>): React.ReactElement => (
            <Form>
              <Card>
                <CardHeader title={t("User:NewPasswordHeader")} />
                <CardContent>
                  <Typography component="p" variant="subtitle2">
                    {fullname},
                  </Typography>
                  <Typography component="p">
                    {t("User:NewPasswordMessage")}
                  </Typography>
                  <Typography component="p">
                    {t("User:NewPasswordRequirements")}
                  </Typography>
                </CardContent>
                <CardActions style={{ padding: 16 }}>
                  <Grid container direction="column" spacing={10}>
                    <Grid item>
                      <FormControl>
                        <Field
                          component={TextField}
                          label={t("User:NewPassword")}
                          placeholder={t("Forms:NewPasswordPlaceholder")}
                          type="password"
                          name="newPassword"
                          value={newPassword}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!isValid || isSubmitting}
                        >
                          {t("User:NewPasswordButton")}
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Form>
          )}
        />
      )}
    </div>
  );
};

export default memo(CreatePasswordPage);
