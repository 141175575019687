export const getFileNameWithoutExtension = (fileName: string): string => {
  if (!fileName) {
    return fileName;
  }

  if (fileName.length === 0) {
    return fileName;
  }

  const dotIndex = fileName.indexOf(".");

  if (dotIndex < 0) {
    return fileName;
  }

  return fileName.substring(0, dotIndex);
};

export const getFileNameFromContentDisposition = (
  contentDisposition: string | null
): string => {
  if (!contentDisposition) {
    return "";
  }

  if (contentDisposition.length === 0) {
    return contentDisposition;
  }

  const components = contentDisposition.split(";");
  const fileNameComponents = components.filter((value: string): boolean => {
    return value.trim().startsWith("filename=");
  });
  if (!fileNameComponents || fileNameComponents.length === 0) {
    return contentDisposition;
  }

  const fileNameComponent = fileNameComponents[0].trim();
  return decodeURIComponent(
    fileNameComponent
      .substring(fileNameComponent.indexOf("=") + 1, fileNameComponent.length)
      .replace(/"/g, "")
  );
};

export const getContentTypeName = (
  t: (input: string) => string,
  contentType: string
): string => {
  return t(`ContentTypes:${contentType}`);
};
