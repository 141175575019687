import { asyncDelete, asyncUpload } from "../../../../api";

export const organizationPrivacyStatementUploadPoster = (
  organizationId: number,
  formData: FormData
) =>
  asyncUpload(
    `/api/organization/${organizationId}/privacy-statement`,
    formData
  );

export const organizationPrivacyStatementDeleter = (organizationId: number) =>
  asyncDelete(`/api/organization/${organizationId}/privacy-statement`);
