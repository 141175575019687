import {
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Success } from "async-lifecycle-saga/dist/models";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { UserModel, UserStatus, emptyUser } from "../../../business/models";
import { adminUsersUpdateCell } from "../../../business/redux/saga/admin/users/cells";
import { useTranslationStrict } from "../../../globalization/i18n";
import { setPartialStateValue } from "../../../utils/ValueConverters";
import { useSnackbarErrorMessage } from "../../ux/SnackbarErrorMessage";
import {
  EmployeeTypeInput,
  UsersEditorActions,
  UsersEditorStatus,
} from "./index";

interface Props {
  isEditing: boolean;
  onEdit(): void;
  showPlatformAdmin: boolean;
  showApiAdmin: boolean;
  user: UserModel;
}

const UsersEditorTableRow = (props: Props) => {
  const { isEditing, onEdit, showPlatformAdmin, showApiAdmin, user } = props;
  const dispatch = useDispatch();
  const [t] = useTranslationStrict();
  const { enqueueSnackbar } = useSnackbar();
  const { showErrorMessageFromDetails } = useSnackbarErrorMessage();

  const [userEdited, setUserEdited] = useState<UserModel>(emptyUser);

  const handleSave = () => {
    dispatch(
      adminUsersUpdateCell.require(userEdited, {
        onFail: showErrorMessageFromDetails(t("Admin:UpdateUserError")),
        onSuccess: (result: Success<UserModel>) => {
          enqueueSnackbar(
            t("Admin:UpdateUserSuccess") + ` (${result.body.loginName})`,
            { variant: "success" }
          );
        },
      })
    );
  };

  useEffect(() => {
    if (isEditing) {
      setUserEdited({ ...user });
    } else {
      setUserEdited(emptyUser);
    }
  }, [isEditing, user]);

  return (
    <TableRow key={user.loginName}>
      <TableCell>
        <Typography>{user.loginName}</Typography>
      </TableCell>
      <TableCell>
        {!isEditing && <Typography>{user.fullName}</Typography>}
        {isEditing && (
          <TextField
            onChange={setPartialStateValue(
              setUserEdited,
              userEdited,
              "fullName"
            )}
            value={isEditing ? userEdited.fullName : user.fullName}
          />
        )}
      </TableCell>
      <TableCell>
        {!isEditing && <Typography>{user.email}</Typography>}
        {isEditing && (
          <TextField
            onChange={setPartialStateValue(setUserEdited, userEdited, "email")}
            value={isEditing ? userEdited.email : user.email}
          />
        )}
      </TableCell>
      <TableCell>
        {!isEditing && <Typography>{user.employeeType}</Typography>}
        {isEditing && (
          <EmployeeTypeInput
            onEdit={(u) => setUserEdited(u)}
            userEdited={userEdited}
          />
        )}
      </TableCell>
      <TableCell>
        <Checkbox
          checked={isEditing ? userEdited.isEmployee : user.isEmployee}
          disabled={!isEditing}
          onChange={setPartialStateValue(
            setUserEdited,
            userEdited,
            "isEmployee"
          )}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={
            isEditing
              ? userEdited.isOrganizationAdmin
              : user.isOrganizationAdmin
          }
          disabled={!isEditing}
          onChange={setPartialStateValue(
            setUserEdited,
            userEdited,
            "isOrganizationAdmin"
          )}
        />
      </TableCell>
      {showPlatformAdmin && (
        <TableCell>
          <Checkbox
            checked={isEditing ? userEdited.isDatacockpit : user.isDatacockpit}
            disabled={!isEditing}
            onChange={setPartialStateValue(
              setUserEdited,
              userEdited,
              "isDatacockpit"
            )}
          />
        </TableCell>
      )}
      {showPlatformAdmin && (
        <TableCell>
          <Checkbox
            checked={
              isEditing ? userEdited.isPlatformAdmin : user.isPlatformAdmin
            }
            disabled={!isEditing}
            onChange={setPartialStateValue(
              setUserEdited,
              userEdited,
              "isPlatformAdmin"
            )}
          />
        </TableCell>
      )}
      {showApiAdmin && (
        <TableCell>
          <Checkbox
            checked={isEditing ? userEdited.isApiAdmin : user.isApiAdmin}
            disabled={!isEditing}
            onChange={setPartialStateValue(
              setUserEdited,
              userEdited,
              "isApiAdmin"
            )}
          />
        </TableCell>
      )}
      {showApiAdmin && (
        <TableCell>
          <Checkbox
            checked={isEditing ? userEdited.hasApiAccess : user.hasApiAccess}
            disabled={!isEditing}
            onChange={setPartialStateValue(
              setUserEdited,
              userEdited,
              "hasApiAccess"
            )}
          />
        </TableCell>
      )}
      <TableCell>
        <UsersEditorStatus status={user.status || UserStatus.new} />
      </TableCell>
      <TableCell>
        <UsersEditorActions
          isEditing={isEditing}
          onEdit={onEdit}
          onSave={handleSave}
          user={isEditing ? userEdited : user}
        />
      </TableCell>
    </TableRow>
  );
};

export default UsersEditorTableRow;
