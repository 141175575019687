import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    root: {
      marginBottom: 0,
      color: theme.palette.common.white,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    arrow: {
      color: theme.palette.common.white,
      display: "inline",
      fontSize: 20,
      fontWeight: "bolder",
    },
    clear: {
      clear: "both",
    },
    crumb: {
      color: "#fff !important",
      fontSize: 14,
      fontWeight: 400,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: theme.palette.common.white,
      },
    },
    current: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: theme.palette.common.white,
      },
    },
    dashboard: {
      textTransform: "uppercase",
    },
    h4: {
      color: theme.palette.common.white,
      fontSize: "2.125rem",
      fontWeight: 400,
      lineHeight: 1.17,
      letterSpacing: "0.00735em",
    },
  })
);

export default useStyles;
