import { useCallback, useMemo } from "react";

import { reflexoHistory } from "../../business/router";
import { useTranslationStrict } from "../../globalization/i18n";
import {
  SnackbarErrorAction,
  SnackbarErrorActionType,
  useSnackbarErrorMessage,
} from "./SnackbarErrorMessage";

const loginErrorMessageSnackbarKey = "login_error";

interface LoginErrorMessagesHook {
  showLoginError: (loginName: string) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useLoginErrorMessages: () => LoginErrorMessagesHook = () => {
  const { hideErrorMessage, showErrorMessage } = useSnackbarErrorMessage();
  const [t] = useTranslationStrict();

  const getLoginFailedMessage = useCallback(
    (loginName: string) => t("Common:LoginFailedMessage", { loginName }),
    [t]
  );
  const loginFailedActions = useMemo(
    (): SnackbarErrorAction[] => [
      {
        actionType: SnackbarErrorActionType.secondary,
        caption: t("Common:LoginFailedResetPasswordActionCaption"),
        onClick: () => {
          reflexoHistory.push("/reset");
        },
      },
      {
        actionType: SnackbarErrorActionType.primary,
        caption: t("Common:LoginFailedTryAgainActionCaption"),
        onClick: () => {
          hideErrorMessage(loginErrorMessageSnackbarKey);
        },
      },
    ],
    [hideErrorMessage, t]
  );

  return {
    showLoginError: (loginName: string) => {
      showErrorMessage(
        {
          title: t("Common:LoginFailedTitle"),
          message: getLoginFailedMessage(loginName),
          actions: loginFailedActions,
        },
        loginErrorMessageSnackbarKey
      );
    },
  };
};
